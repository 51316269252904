import React, { Component, Fragment } from 'react';
import {
	Container, Card, Table, Button, Row, Col, Input,
	Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Alert, Form, CardHeader, CardBody
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api, constants, helpers } from '../utils';
import makeAnimated from "react-select/animated";
import Select from 'react-select';

const emptyAddress = {
	id: 0,
	ln1: '',
	ln2: '',
	ln3: '',
	ln4: '',
	city: '',
	state: '',
	zip: '',
	addressType: null,
	isPrimary: false,
	deactivatedAt: null,
	editing: true
};

let addId = 0;
let nextNumber = 0;

const createSavePayload = (customerId, addressToSave) => {
	return {
		Id: addressToSave.id,
		Ln1: _.trim(addressToSave.ln1),
		Ln2: _.trim(addressToSave.ln2),
		Ln3: _.trim(addressToSave.ln3),
		Ln4: _.trim(addressToSave.ln4),
		City: _.trim(addressToSave.city),
		State: _.trim(addressToSave.state),
		Zip: _.trim(addressToSave.zip),
		IsPrimary: addressToSave.isPrimary,
		AddressTypeId: addressToSave.addressType ? addressToSave.addressType.value : null,
		CustomerId: customerId
	};
};

export default class CustomerAddress extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: null,
			messageFlavor: null,
			errorMessage: null,
			addressTypeList: [],
			pagedAddressList: [],
			pristineAddress: null,
			addressesToSave: [],
			pendingDuplicate: null
		};
		this.loadAddresses = this.loadAddresses.bind(this);
		this.addNew = this.addNew.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.handleFormSelectionChange = this.handleFormSelectionChange.bind(this);
		this.onIsPrimarySelection = this.onIsPrimarySelection.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onSave = this.onSave.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.toggleMessageVisibility = this.toggleMessageVisibility.bind(this);
	}

	componentDidMount() {
		api.fetch('Reference/GetAddressTypeList')
			.then((response) => {
				this.setState({ addressTypeList: response.data }, ()=>this.loadAddresses());
			});
	}

	loadAddresses() {
		api.fetch(`Customer/GetCustomerAddresses/${this.props.customerId}`).then(response => {
			if (response && response.data) {
				this.setState({
					pagedAddressList: _.map(response.data, a => {
						// a.state = _.find(constants.STATES_LIST, s => { return s.value === a.state })
						a.addressType = _.find(this.state.addressTypeList, t => { return t.value === a.addressTypeId });
						a.editing = false;
						return a;
					})
				});
			}
		});
	}

	toggleMessageVisibility() {
		this.setState({
			message: null,
			messageFlavor: null,
			isMessageVisible: false
    })
	}

	addNew() {
		let addressList = this.state.pagedAddressList.slice();
		addressList.unshift(emptyAddress)
		this.setState({ pagedAddressList: addressList });
	}

	onEdit(index) {
		let list = this.state.pagedAddressList.slice();
		const pristineAddress = Object.assign({}, list[index]);
		list[index].editing = true;
		this.setState({ pagedAddressList: list, pristineAddress: pristineAddress})
	}

	onCancelEdit(index) {
		let list = this.state.pagedAddressList.slice();
		if (list[index].id > 0) {
			list[index] = this.state.pristineAddress;
			list[index].editing = false;
		} else {
			list = _.reject(list, (a, idx) => { return idx === index });
    }
		this.setState({ pagedAddressList: list, pristineAddress: null })
	}

	onInputChange(input, fieldName, index) {
		let list = this.state.pagedAddressList.slice();
		list[index][fieldName] = input.target ? input.target.value : input;
		this.setState({ pagedAddressList: list})
	}

	handleFormSelectionChange(selection, fieldName, index) {
		let list = this.state.pagedAddressList.slice();
		list[index][fieldName] = selection;
		this.setState({ pagedAddressList: list })
	}

	onIsPrimarySelection(index) {
		let list = this.state.pagedAddressList.slice();
		list[index].isPrimary = !list[index].isPrimary;
		this.setState({ pagedAddressList: list })
	} 

	isFormValid(addressToSave) {
		let warnings = [];
		if (!_.trim(addressToSave.ln1)) { warnings.push("Please enter at least one address line."); }
		if (!_.trim(addressToSave.city)) { warnings.push("Please enter a city."); }
		if (!_.trim(addressToSave.state)) { warnings.push("Please enter a state."); }
		if (!_.trim(addressToSave.zip)) { warnings.push("Please enter a zipcode."); }
		if (!addressToSave.addressType) { warnings.push("Please choose a type of address.") }
		if (warnings.length) {
			this.setState({
				message: warnings.join(' '),
				messageFlavor: "danger",
				isMessageVisible: true
			});
		} else {
			this.setState({ message: null, messageFlavor: null, isMessageVisible: false });
		}
		return warnings.length === 0;
	}

	onSave(index) {
		let payload = null;
		let addressToSave = null;
		let pendingDuplicate = null;
		if (this.state.pendingDuplicate) {
			payload = createSavePayload(this.props.customerId, this.state.pendingDuplicate);
		} else {
			addressToSave = _.find(this.state.pagedAddressList, (a, idx) => { return index === idx });
			if (!this.isFormValid(addressToSave)) return;
			payload = createSavePayload(this.props.customerId, addressToSave);
			pendingDuplicate = !addressToSave.id
					&& (addressToSave.addressType.value === constants.ADDRESS_TYPE.BILL_TO)
					&& !_.some(this.state.pagedAddressList, a => 
							a.addressType.value === constants.ADDRESS_TYPE.SHIP_TO
							&& a.ln1 === addressToSave.ln1
							&& a.ln2 === addressToSave.ln2
							&& a.ln3 === addressToSave.ln3
							&& a.ln4 === addressToSave.ln4
							&& a.city === addressToSave.city
							&& a.state === addressToSave.state
							&& a.zip === addressToSave.zip
						)
					? {
						...addressToSave, 
							isPrimary: false,
							addressType: _.find(this.state.addressTypeList, t => t.value === constants.ADDRESS_TYPE.SHIP_TO)
						}
					: null;
		}
		api.post("Customer/SaveCustomerAddress", payload)
			.then(response => {
				if (response.data && response.data.success) {
					this.setState({
						message: "Address saved",
						messageFlavor: "success",
						pendingDuplicate: pendingDuplicate
					}, this.loadAddresses);
				} else {
					this.setState({
						message: "An error occurred, your address could not be saved",
						messageFlavor: "danger"
					});
				}
			});
	}

	onAddressDelete(addressId) {
		api.delete(`Customer/DeleteCustomerAddress/${addressId}`)
				.then(response => {
					if (response && response.data && response.data.success) {
						this.setState({
							messageFlavor: "success",
							message: "Address was deleted."
						}, this.loadAddresses);
					} else {
						this.setState({
							messageFlavor: "danger",
							message: "An error occurred, address cannot be deleted at this time."
						});
					}
				}).catch(helpers.catchHandler);
	}

	onAddressRevive(addressId) {
		api.post(`Customer/ReviveCustomerAddress/${addressId}`)
			.then(response => {
				if (response && response.data && response.data.success) {
					this.setState({
						messageFlavor: "success",
						message: "Address was revived."
					}, this.loadAddresses);
				} else {
					this.setState({
						messageFlavor: "danger",
						message: "An error occurred, address cannot be revived at this time."
					});
				}
			}).catch(helpers.catchHandler);
	}

	render() {
		const isEditingAnything = _.some(this.state.pagedAddressList, x => x.editing);
		return (
			<Container fluid>
				<Card style={{ borderStyle: "none" }}>
					<CardBody>
						{this.state.message && 
							<Row>
								<Col>
									<Alert isOpen={true} className={this.state.messageFlavor} toggle={this.toggleMessageVisibility}>{this.state.message}</Alert>
								</Col>
							</Row>
						}
						<Table>
							<thead>
								<tr>
									<th width="60%">Address</th>
									<th width="20%">Type</th>
									<th>Is Primary</th>
									<th>
										<Button size="sm" className="success float-right" onClick={this.addNew} 
											disabled={isEditingAnything}>
											<FontAwesomeIcon icon="plus" />
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.pagedAddressList && this.state.pagedAddressList.length ? (
									this.state.pagedAddressList.map((address, index) => (
										address.editing ? (
											<tr key={`ca-${index}`}>
												<td>
													<div><Input name="ln1" value={address.ln1} className="mb-2" placeholder="Line 1" onChange={(e) => this.onInputChange(e, "ln1", index) }/></div>
													<div><Input name="ln2" value={address.ln2} className="mb-2" placeholder="Line 2" onChange={(e) => this.onInputChange(e, "ln2", index) }/></div>
													<div><Input name="ln3" value={address.ln3} className="mb-2" placeholder="Line 3" onChange={(e) => this.onInputChange(e, "ln3", index) }/></div>
													<div><Input name="ln4" value={address.ln4} className="mb-2" placeholder="Line 4" onChange={(e) => this.onInputChange(e, "ln4", index) }/></div>
													<div>
														<Row>
															<Col><Input name="city" value={address.city} className="mb-2" placeholder="City" 
																onChange={(e) => this.onInputChange(e, "city", index) }/></Col>
															<Col>
																<Input maxLength="5" type="text" name="state" id="state"
																	onChange={(e) => this.onInputChange(e, "state", index) } placeholder={"Abbr."} value={address.state}
																/>  
															</Col>
															<Col><Input name="zip" value={address.zip} className="mb-2" placeholder="Zip" onChange={(e) => this.onInputChange(e, "zip", index) }/></Col>
															</Row>
													</div>
												</td>
												<td>
													<Select
														closeMenuOnSelect={true}
														value={address.addressType}
														isMulti={false}
														components={makeAnimated()}
														options={this.state.addressTypeList}
														onChange={(e) => this.handleFormSelectionChange(e, "addressType", index)}
														placeholder="Select an Address Type"
													/>
												</td>
												<td>
													<div style={{ textAlign: "center", verticalAlign: "middle" }}>
														<Input
															type="checkbox"
															value="isPrimary"
															onChange={()=>this.onIsPrimarySelection(index)}
															checked={address.isPrimary}
														/>
													</div>
												</td>
												<td>
													<ButtonGroup className="float-right">
														<Button
															size="sm"
															className="primary"
															onClick={() => this.onSave(index)}
														>
															<FontAwesomeIcon icon="save" />
														</Button>
														<Button
															size="sm"
															className="secondary"
															onClick={() => this.onCancelEdit(index)}
														>
															<FontAwesomeIcon icon="times" />
														</Button>
													</ButtonGroup>
												</td>
											</tr>
											) : (
												<tr key={`ca-${index}`}>
													<td>
														<div>{address.ln1}</div>
														<div>{address.ln2}</div>
														<div>{address.ln3}</div>
														<div>{address.ln4}</div>
														<div>{address.city}, {address.state} {address.zip}</div>
													</td>
													<td>{address.addressType && address.addressType.label}</td>
													<td>{address.isPrimary ? <FontAwesomeIcon icon="check" className="text-success" /> : null}</td>
													<td>
														<ButtonGroup className="float-right">
															{address.deactivatedAt ? (
																<Button size="sm" className="info" onClick={() => this.onAddressRevive(address.id)} disabled={isEditingAnything}><FontAwesomeIcon icon="reply"/> Revive</Button>
															) : (
																<Fragment>
																		<Button className="primary" onClick={() => this.onEdit(index)} disabled={isEditingAnything} ><FontAwesomeIcon icon="edit"/></Button>
																		<Button className="danger" onClick={() => this.onAddressDelete(address.id)} disabled={isEditingAnything}><FontAwesomeIcon icon="trash" /></Button>
																</Fragment>
															)}
														</ButtonGroup>
													</td>
												</tr>
											)
									))) : (<tr><td>No addresses found</td></tr>)}
							</tbody>
						</Table>

						<Modal isOpen={this.state.pendingDuplicate ? true : false} toggle={() => this.setState({ pendingDuplicate: null })}>
							<ModalHeader>Copy to new Ship To Address?</ModalHeader>
							<ModalBody>
								<Row>
									<Col>
										Would you like to duplicate the Bill To address details to a new Ship To Address?
									</Col>
								</Row>
								<ModalFooter>
									<ButtonGroup className="float-right">
										<Button size="sm" color="secondary" onClick={() => this.setState({ pendingDuplicate: null })}>No</Button>
										<Button size="sm" color="primary" onClick={() => this.onSave()}>Yes</Button>
									</ButtonGroup>
								</ModalFooter>
							</ModalBody>
						</Modal>
					</CardBody>
				</Card>
			</Container>
		);
  }
}