import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row, Container, Table } from 'reactstrap';
import api from '../utils/api';
import { Link } from 'react-router-dom';
import helpers from '../utils/helpers';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let nextNumber = 0;

const gimmeNextNumber = () => {
    nextNumber++;
    return nextNumber;
};

const DataRow = props => {
    return (
        <Row>
            <Col className="col-2">
                <strong>{props.header}:</strong>
            </Col>
            <Col className="col-10">
                {props.data || ""}
            </Col>
        </Row>
    );
};

export default class ViewSupplier extends Component {
    constructor(props) {
        super(props);

        const activeSupplierId = props.location && props.location.state ? props.location.state.supplierId : null; 

        this.state = {
            contactInfo: [],
            supplierInfo: [],
            supplierContactList: [],
            supplierId: activeSupplierId,
            supplierList: props.supplierList
        }

        this.togglePhoneInfo = this.togglePhoneInfo.bind(this);
    }

    componentDidMount(){
      api.fetch(`Supplier/GetSupplier/${this.state.supplierId}`)
        .then((result) => {
            this.setState({ 
                contactInfo: result.data.contacts,
                supplierInfo: result.data.supplier
            });
        });
    }

    togglePhoneInfo(selectedContactId){
        const crIndex = _.findIndex(this.state.contactInfo, function(x){return x.id === selectedContactId});
        const list = this.state.contactInfo.slice();
        list[crIndex].expanded = !list[crIndex].expanded;
        this.setState({ contactInfo: list});
    }

    render() {
        const s = this.state.supplierInfo;
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        <Row>
                            <Col>
                                <h3 className="page-title">Contact Details for {s.name}</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <DataRow header="Name" data={s.name} />
                                <DataRow header="Account Number" data={s.accountNumber} />
                                {/*<DataRow header="Supplier Group" data={s.supplierGroupName} />*/}
                                <DataRow header="Pay To Name" data={s.payToName} />
                                <DataRow header="Supplier Agent" data={s.millAgentName} />
                                <DataRow header="Supplier Agent Phone" data={s.millAgentPhone} />
                                <DataRow header="Supplier Agent Email" data={s.millAgentEmail} />
                                <DataRow header="Samples E-Mail" data={s.samplesEmail} />
                                <DataRow header="Is Hidden?" data={s.isHidden} />
                                <DataRow header="Comments" data={s.comment} />
                            </Col>
                        </Row>
                {/* <Row className="row-spacing">
                            <Col xs="6">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Contact Name</th>
                                            <th>E-Mail</th>
                                            <th>Type of Contact</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.contactInfo.map(i => (
                                            <React.Fragment key={`ci-${i.id}`}>
                                                <tr key={i.id}>
                                                    <td>{i.firstName} {i.lastName}</td>
                                                    <td>{i.email ? i.email : ""} </td>
                                                    <td>{i.typeOfContactName ? i.typeOfContactName : ""}</td>
                                                    <td>
                                                        <Button
                                                            size="sm"
                                                            className="primary"
                                                            onClick={this.togglePhoneInfo.bind(this, i.id)}
                                                        >
                                                            
                                                            Show More 
                                                        </Button>
                                                    </td>
                                                </tr>
                                                {i.expanded &&
                                                    <React.Fragment key={`ph-${i.id}`}>
                                                        <tr>
                                                            <th>Phone #</th>
                                                            <th>Phone Type</th>
                                                        </tr>
                                                        {i.phoneNumbers.map(p => (
                                                            <tr key={p.id}>
                                                                <td>{p.number ? helpers.formatPhoneNumber(p.number) : ""}</td>
                                                                <td>{p.typeOfPhoneName ? p.typeOfPhoneName : ""}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <th>Address</th>
                                                            <th>Address Type</th>
                                                        </tr>
                                                        {i.addresses.map(a => (
                                                            <tr key={a.id}>
                                                                <td>
                                                                    {helpers.formatAddress(a).map(line => {
                                                                        return <Row key={gimmeNextNumber()}><Col>{line}</Col></Row>;
                                                                    })}
                                                                </td>
                                                                <td>{a.typeOfAddressName ? a.typeOfAddressName : ""}</td>
                                                            </tr>
                                                        ))}

                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>*/}
                        <Row className="row-spacing">
                            <Col>
                                <Button
                                    className="secondary float-right"
                                    size="sm"
                                    tag={Link}
                                    to="/Suppliers"
                                >
                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}