import React, { Component } from 'react';
import {
    Button,
    Col,
    Row,
    Container,
    Table,
    ButtonGroup,
    Alert,
    Input,
    Label,
    Card,
    CardBody,
    Collapse
} from 'reactstrap';
//import api from '../utils/api';
import { api, constants, helpers, filter_helpers } from '../utils';
//import helpers from '../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import filter_helpers from '../utils/filter_helpers';
import _ from 'lodash';
import {
    FilterSet,
    SortHeader,
    FilterBoolean,
    FilterText,
    FilterMultiSelect
} from '../components';
import Select from 'react-select';
import Switch from 'react-switch';

const emptySampleBook = {
    bookType: {
        value: "",
        label: ""
    },
    name: "",
    bookStatus: {
        value: "",
        label: ""
    },
  qty: "",
  sequence: "",
  reportGroupingOnly: false
};

export default class MaintainBooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleBooks: [],
            showAddBook: false,
          filters: [{
            filterName: "ActiveOnly",
            value: true,
            filteredDisplay: "Active Only: Yes"
          },
            {
              filterName: "StatusTypeList",
              value: [constants.SAMPLE_BOOK_STATUS_TYPE.ACTIVE],
              filteredDisplay: "Status Type: Active"
            }],
            newSampleBook: emptySampleBook,
            sort_field: 'BookName',
            sort_dir: 'asc',
            bookTypeList: [],
            message: null,
            messageFlavor: null,
            bookStatusList: []
        };
        this.refreshList = this.refreshList.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.saveNewSampleBook = this.saveNewSampleBook.bind(this);
        this.updateSampleBook = this.updateSampleBook.bind(this);
    }

    componentDidMount() {
        const getBookTypes = api.fetch("Reference/GetSampleBookTypeList")
            .then((response) => {
                return { bookTypeList: response.data };
            })
            .catch(helpers.catchHandler);
        const getBookStatus = api.fetch("Reference/GetSampleBookStatusList")
            .then((response) => {
                return { bookStatusList: response.data };
            })
            .catch(helpers.catchHandler);
        Promise.all([
            getBookTypes,
            getBookStatus,
        ])
            .then((aggregateResults) => {
                const newStatus = {};
                aggregateResults.forEach(r => Object.assign(newStatus, r));
                this.setState(newStatus, () =>
                    this.refreshList());
            })
            .catch(helpers.catchHandler);
    }

    refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            SortField: sortField || this.state.sort_field,
            SortDir: sortDirection || this.state.sort_dir,
        };
    
        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('SampleBook/GetList', payload)
            .then((response) => {
                this.setState({ 
                    sampleBooks: _.map(response.data,  b => {
                        return {
                            ...b,
                            bookType: helpers.resolveValue(null, b.sampleBookTypeId, b.sampleBookTypeName),
                            bookStatus: helpers.resolveValue(null, b.sampleBookStatusId, b.sampleBookStatusName),
                            sequence: helpers.nullableString(b.sequence)
                        }
                    })
                })
            }).catch(error => error);
    }

    doSort(field, direction) {
        this.setState({ sort_dir: direction, sort_field: field });
        this.refreshList(field, direction);
      }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) return filterElement.value;
        return '';
    }

    filterChange(changed_filter) {
        const filters = filter_helpers.get_filters(this.state.filters, changed_filter);
        this.setState( { filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
      }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }    

    toggleAddSampleBookCollapse() {
        this.setState({
            showAddBook: !this.state.showAddBook,
            newSampleBook: emptySampleBook,
            message: null,
            messageFlavor: null
        });
    }

    onEdit(sampleBookId) {
        const indexToEdit = _.findIndex(this.state.sampleBooks, {id: sampleBookId});
        let tempList = this.state.sampleBooks.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
        this.setState({
            sampleBooks: tempList
        });
    }

    onCancelEdit(sampleBookId) {
        const indexToEdit = _.findIndex(this.state.sampleBooks, {id: sampleBookId});
        let tempList = this.state.sampleBooks.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
            sampleBooks: tempList, 
            message: null,
            messageFlavor: null
        });
    }

    updateSampleBookState(fieldName, value, sampleBookId) {
        const sampleBooks = this.state.sampleBooks.slice();
        const sampleBook = _.find(sampleBooks, x => x.id === sampleBookId);
        sampleBook[fieldName] = value;
        this.setState({
            sampleBooks: sampleBooks
        });
    }

    handleSampleBookStateChange(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            newSampleBook: {
                ...prevState.newSampleBook,
                [fieldName]: value
            }
        }));
    }

    getSelectedBookType(bookTypeId) {
        if (!bookTypeId) {
            return null;
        }
        return _.find(this.state.bookTypeList, x => x.id === bookTypeId);
    }

    setSelectedBookType(option) {
        this.setState(prevState => ({
            ...prevState,
            newSampleBook: {
                ...prevState.newSampleBook,
                bookType: {
                    value: option.value,
                    label: option.label
                }
            }
        }));
    }

    updateSelectedBookType(option, sampleBookId) {
        const sampleBooks = this.state.sampleBooks.slice();
        const sampleBook = _.find(sampleBooks, x => x.id === sampleBookId);
        sampleBook.bookType = option;
        this.setState({
            sampleBooks: sampleBooks
        });
    }

    getSelectedBookStatus(bookStatusId) {
        if (!bookStatusId) {
            return null;
        }
        return _.find(this.state.bookStatusList, x => x.id === bookStatusId);
    }

    setSelectedBookStatus(option) {
        this.setState(prevState => ({
            ...prevState,
            newSampleBook: {
                ...prevState.newSampleBook,
                bookStatus: {
                    value: option.value,
                    label: option.label
                }
            }
        }));
    }

    updateSelectedBookStatus(option, sampleBookId) {
        const sampleBooks = this.state.sampleBooks.slice();
        const sampleBook = _.find(sampleBooks, x => x.id === sampleBookId);
        sampleBook.bookStatus = option;
        this.setState({
            sampleBooks: sampleBooks
        });
    }

    isFormValid(item) {
        const warnings = [];
        if (!item.bookType.value) {
            warnings.push("Book Type is required.");
        }
        if (!item.name) {
            warnings.push("Book Name is required.");
        }
        if (!item.qty && item.qty !== 0) {
            warnings.push("Quantity is required.");
        }
        if (item.qty < 0) {
            warnings.push("Quantity must be 0 or greater.");
        }
        if (item.qty > 2147483647)  {
            warnings.push("Quantity is too large.");
        }
        if (!item.bookStatus.value) {
            warnings.push("Book Status is required.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    saveNewSampleBook() {
        if (!this.isFormValid(this.state.newSampleBook)) return;
        const data = {
            Id: null,
            Name: this.state.newSampleBook.name,
            SampleBookTypeId: this.state.newSampleBook.bookType.value,
            SampleBookStatusId: this.state.newSampleBook.bookStatus.value,
            Qty: Number(this.state.newSampleBook.qty),
            Sequence: this.state.newSampleBook.sequence ? Number(this.state.newSampleBook.sequence) : null,
            ReportGroupingOnly: this.state.newSampleBook.reportGroupingOnly
        };

        api.post('SampleBook/SaveNewSampleBook', data)
        .then(response => {
            if (response.data.success) {
            this.setState({
                showAddBook: false,
                newSampleBook: emptySampleBook,
            }, () => this.refreshList());
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            } 
        })
        .catch(helpers.catchHandler);
    }

    updateSampleBook(sampleBook) {
        if (!this.isFormValid(sampleBook)) return;
        const data = {
            Id: sampleBook.id,
            Name: sampleBook.name,
            SampleBookTypeId: sampleBook.bookType.value,
            SampleBookStatusId: sampleBook.bookStatus.value,
            Qty: Number(sampleBook.qty),
            Sequence: sampleBook.sequence ? Number(sampleBook.sequence) : null,
            ReportGroupingOnly: sampleBook.reportGroupingOnly
        };

        

        api.post(`SampleBook/UpdateSampleBook`, data)
        .then(response => {
            if (response.data.success) {
            this.setState({
                showAddBook: false,
                newSampleBook: emptySampleBook,
            }, () => this.refreshList());
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            } 
        })
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api.delete(`SampleBook/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleUnDelete(id) {
        api.post(`SampleBook/Undelete/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
  }

    toggleReportGroup(sampleBookId) {
      let sampleBooks = this.state.sampleBooks.slice();
      const sampleBookIndex = _.findIndex(sampleBooks, x => x.id === sampleBookId);
      sampleBooks[sampleBookIndex].reportGroupingOnly = !sampleBooks[sampleBookIndex].reportGroupingOnly;
      this.setState({
        sampleBooks: sampleBooks
      });
    }

    render() {
        const b = this.state.newSampleBook;
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <h3 className="pull-left page-title">Maintain Samples</h3>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col sm="12">
                                {this.state.showAddBook
                                    ? null
                                    :   <ButtonGroup className="float-right">
                                            <Button
                                                size="sm"
                                                className="success"
                                                onClick={() => this.toggleAddSampleBookCollapse()}
                                                title="Add New Book"
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add Book
                                            </Button>
                                        </ButtonGroup>
                                }
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterMultiSelect
                                                filterName="BookTypeList"
                                                displayName="Book Type"
                                                values={this.currentFilterValue('BookTypeList')}
                                                onChangeCallback={this.filterChange}
                                                options={this.state.bookTypeList}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="BookName"
                                                displayName="Book Name"
                                                value={this.currentFilterValue('BookName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean
                                                filterName="ActiveOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterMultiSelect
                                                filterName="StatusTypeList"
                                                displayName="Status Type"
                                                values={this.currentFilterValue('StatusTypeList')}
                                                onChangeCallback={this.filterChange}
                                                options={this.state.bookStatusList}
                                            />
                                        </Col>
                                        <Col xs="3">
                                          <FilterBoolean
                                            filterName="ReportGroupingOnly"
                                            displayName="Report Grouping Only"
                                            yesOnly={true}
                                            value={this.currentFilterValue('ReportGroupingOnly')}
                                            onChangeCallback={this.filterChange}
                                            clearValue={this.state.clearValue}
                                          />
                                        </Col>

                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.showAddBook} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Book</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col> 
                                            <Label>Book Type {helpers.requiredStar()}</Label>
                                            <Select
                                                closeMenuOnSelect
                                                value={this.getSelectedBookType(b.bookType.value)}
                                                options={this.state.bookTypeList}
                                                onChange={(option) => this.setSelectedBookType(option)}
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Book Name {helpers.requiredStar()}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                onChange={(event) => this.handleSampleBookStateChange(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                value={b.name}
                                                maxLength="100"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Qty {helpers.requiredStar()}</Label>
                                            <Input
                                                type="number"
                                                name="qty"
                                                onChange={(event) => this.handleSampleBookStateChange(
                                                    event.target.name,
                                                    event.target.value)}
                                                value={b.qty}
                                                min="0"
                                                max="2147483647"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Book Status {helpers.requiredStar()}</Label>
                                            <Select
                                                closeMenuOnSelect
                                                value={this.getSelectedBookStatus(b.bookStatus.value)}
                                                options={this.state.bookStatusList}
                                                onChange={(option) => this.setSelectedBookStatus(option)}
                                            />
                                        </Col>
                                        <Col>
                                          <Label>Seq</Label>
                                          <Input
                                            type="number"
                                            name="sequence"
                                            onChange={(event) => this.handleSampleBookStateChange(
                                              event.target.name,
                                              event.target.value)}
                                            value={b.sequence}
                                            min="0"
                                            max="2147483647"
                                          />
                                        </Col>
                                        <Col>
                                          <Label>Report Grouping Only?</Label>
                                          <div>
                                            <Switch
                                              className="ml-2 p-0 m-0"
                                              onChange={(event) => this.handleSampleBookStateChange(
                                                "reportGroupingOnly",
                                                !b.reportGroupingOnly)}
                                              checked={b.reportGroupingOnly}
                                            />
                                          </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    className="btn primary"
                                                    size="sm"
                                                    onClick={() => this.saveNewSampleBook()}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    className="btn secondary"
                                                    size="sm"
                                                    onClick={() => this.toggleAddSampleBookCollapse()}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                            <Col className="col-12">
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader
                                                    displayName="Book Type"
                                                    field="BookType"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'BookType'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Book Name"
                                                    field="BookName"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'BookName'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Qty"
                                                    field="Qty"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'Qty'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th width="15%">
                                                <SortHeader
                                                    displayName="Status"
                                                    field="StatusType"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'StatusType'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                              <SortHeader
                                              displayName="Seq"
                                              field="Sequence"
                                              sortDir={this.state.sort_dir}
                                              sorted={this.state.sort_field === 'Sequence'}
                                              callBack={this.doSort}
                                              />
                                          </th>
                                          <th width="12%">Report Grouping Only?</th>
                                          <th width="15%" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.sampleBooks.map(b => (
                                            <tr key={b.id} className="data-row">
                                                <td>
                                                    {b.isEditing ? (
                                                        <Select
                                                            closeMenuOnSelect
                                                            value={b.bookType}
                                                            options={this.state.bookTypeList}
                                                            onChange={(option) => this.updateSelectedBookType(option, b.id)}
                                                        />
                                                    ) : b.bookType.label}
                                                </td>
                                                <td>
                                                    {b.isEditing ? (
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            value={b.name}
                                                            onChange={(event) => this.updateSampleBookState(
                                                                event.target.name,
                                                                event.target.value,
                                                                b.id
                                                            )}
                                                            maxLength="100"
                                                        />
                                                    ) : b.name}
                                                </td>
                                                <td>
                                                    {b.isEditing ? (
                                                        <Input
                                                            type="number"
                                                            name="qty"
                                                            value={b.qty}
                                                            onChange={(event) => this.updateSampleBookState(
                                                                event.target.name,
                                                                event.target.value,
                                                                b.id
                                                            )}
                                                            min="0"
                                                            max="2147483647"
                                                        />
                                                    ) : b.qty}
                                                </td>
                                                <td>
                                                    {b.isEditing ? (
                                                        <Select
                                                            closeMenuOnSelect
                                                            value={b.bookStatus}
                                                            options={this.state.bookStatusList}
                                                            onChange={(option) => this.updateSelectedBookStatus(option, b.id)}
                                                        />
                                                    ) : b.bookStatus.label}
                                            </td>
                                            <td>
                                              {b.isEditing ? (
                                                <Input
                                                  type="number"
                                                  name="sequence"
                                                  value={b.sequence}
                                                  onChange={(event) => this.updateSampleBookState(
                                                    event.target.name,
                                                    event.target.value,
                                                    b.id
                                                  )}
                                                  min="0"
                                                  max="2147483647"
                                                />
                                              ) : b.sequence}
                                            </td>
                                            <td className={!b.isEditing ? "centerText boldText" : ""}>
                                              {b.isEditing ? (
                                                <div>
                                                  <Switch
                                                    className="ml-2 p-0 m-0"
                                                    onChange={(event) => this.updateSampleBookState(
                                                      "reportGroupingOnly",
                                                      !b.reportGroupingOnly, b.id)}
                                                    checked={b.reportGroupingOnly}
                                                  />
                                                </div>
                                              ) : ( b.reportGroupingOnly ? <FontAwesomeIcon icon="check" className="text-success" /> : null )}
                                            </td>

                                                <td className="text-right">
                                                    <ButtonGroup className="float-right">
                                                        {b.isEditing ? (
                                                            <div>
                                                                <Button
                                                                    className="btn primary"
                                                                    onClick={() => this.updateSampleBook(b)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="btn secondary"
                                                                    onClick={() => this.onCancelEdit(b.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {b.deactivatedAt === null ? (
                                                                    <React.Fragment>
                                                                        <Button
                                                                            className="primary btn-outline-secondary"
                                                                            onClick={() => this.onEdit(b.id)}
                                                                            size="sm"
                                                                        >
                                                                            <FontAwesomeIcon icon="edit" />
                                                                        </Button>
                                                                        <Button
                                                                            className="danger btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.handleDelete.bind(this, b.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash" />
                                                                        </Button>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Button
                                                                        className="info btn"
                                                                        size="sm"
                                                                        onClick={this.handleUnDelete.bind(this, b.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="recycle" /> Revive
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}