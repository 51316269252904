import React from 'react';
import { constants } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortHeader = (props) => {
  if (!props.callBack) {
    console.warn('Add a callBack function property on the sort header that accepts field and direction params');
  }
  if (!props.field) {
    console.warn('Add a field property on the sort header that specifies the field');
  }
  if (!props.displayName) {
    console.warn('Add a displayName property on the sort header that specifies the field');
  }

  const dir = props.sortDir || 'desc';
  const callBackDir = dir === 'desc' || dir == constants.SORT_DIRECTION.descending ? 'asc' : 'desc';

  return (
    <span onClick={() => props.callBack(props.field, callBackDir)} className="sort-handle">
      <span style={{display: "inline-block", paddingRight: "3px"}}>{props.displayName}</span>
      {props.sorted && (dir === 'asc' || dir == constants.SORT_DIRECTION.ascending) && <FontAwesomeIcon icon="angle-up" />}
      {props.sorted && (dir === 'desc' || dir == constants.SORT_DIRECTION.descending) && <FontAwesomeIcon icon="angle-down" />}
    </span>
  );
};

export default SortHeader;
