import React, { Fragment } from 'react';
import {
  Container,
  Table,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
  Input,
  ButtonGroup,
  Collapse,
  Alert
} from 'reactstrap';
//import Alert from "react-s-alert";
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ToggleButton from 'react-toggle-button';
import moment from 'moment';
import _ from 'lodash';
import {
    api,
    helpers,
    date_helpers
} from '../utils';

export default class CustomerMoreDetails extends React.Component {
    constructor(props) {
        super(props);
      this.state = {
        message: null,
        messageFlavor: null,
        isMessageVisible: false,
        customerId: props.customerId,
        isBookOpen: false,
        isPricingOpen: false,
        isCreditOpen: false,
        isCreditEditing: false,
        pastCustomerSampleBooks: [],
        customerSpecialPricing: [],
        customerOutstandingCredits: [],
        creditPristine: null,
        creditCount: 0,
        pricingCount: 0,
        bookCount: 0
      };
      this.addNewCredit = this.addNewCredit.bind(this);
      this.loadCreditLine = this.loadCreditLine.bind(this);
      this.onDismiss = this.onDismiss.bind(this);
      this.isCreditFormValid = this.isCreditFormValid.bind(this);
    }

    componentDidMount() {
      api.fetch(`Customer/GetPastCustomerSamples/${this.state.customerId}`).then((response) => {
        this.setState({ pastCustomerSampleBooks: response.data }, this.loadCreditLine);
      }).catch(helpers.catchHandler);
    }

  onDismiss() {
    this.setState({
      isMessageVisible: !this.state.isMessageVisible,
      message: null,
      messageFlavor: null
    });
  }

  loadCreditLine() {
    api.fetch(`Customer/GetCustomerCreditLine/${this.state.customerId}`)
      .then(response => {
        if (response && response.data) {
          this.setState({
            customerOutstandingCredits: _.map(response.data, c => {
              c.amount = _.round(c.amount).toFixed(2);
              c.notes = c.comment;
              c.isEditing = false;
              c.initialAmount = _.round(c.initialAmount).toFixed(2);
              return c;
            }),
            isCreditEditing: false
          });
        }
      }).catch(helpers.catchHandler);
  }

  addNewCredit() {
    let list = this.state.customerOutstandingCredits.slice();
    let count = this.state.creditCount + 1;
    let newcredit = {
      id: (-1 * count),
      customerId: this.state.customerId,
      notes: '',
      amount: '0.00',
      givenOn: null,
      isEditing: true,
      applied: false
    };
    list.unshift(newcredit);
    this.setState({
      customerOutstandingCredits: list,
      creditCount: count,
      creditPristine: newcredit
    });
  }

  onTextChange(e, index, fieldName) {
    let list = this.state.customerOutstandingCredits.slice();
    list[index][fieldName] = e.target.value;
    this.setState({
      customerOutstandingCredits: list,
      isCreditEditing: true
    });
  }

  isCreditFormValid(credit) {
    let warnings = [];
    if (!credit.amount) {
      warnings.push("Credit amount is required")
    }
    if (warnings.length) {
      this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }
  onCreditSave(creditId) {
    let list = this.state.customerOutstandingCredits.slice();
    const credit = _.find(list, l => {
      return l.id === creditId;
    });
    if (!this.isCreditFormValid(credit)) return;
    const payload = {
      Id: credit.id > 0 ? credit.id : 0,
      Amount: Number(credit.amount),
      Comment: credit.notes,
      CustomerId: credit.customerId
    }

    api.post("Customer/SaveCustomerCreditLine", payload)
      .then(response => {
        if (response && response.data && response.data.success) {
          this.setState({
            message: "Credit Line Item has been saved",
            messageFlavor: "success",
            isMessageVisible: true
          }, () => this.loadCreditLine());
        } else {
          this.setState({
            message: response.data.message || "An error occurred. Credit Line Item cannot be saved at this time.",
            messageFlavor: "danger",
            isMessageVisible: true
          })
        }
      }).catch(helpers.catchHandler);
  }

  onCreditDelete(creditId) {
    if (!window.confirm(`Are you sure you'd like to delete this credit line item?`)) return;
    api.delete(`Customer/DeleteCustomerCreditLine/${creditId}`)
      .then(response => {
        if (response.data.success) {
          this.setState({
            message: "Credit Line Item has been deleted",
            messageFlavor: "success",
            isMessageVisible: true
          }, ()=>this.loadCreditLine());
        } else {
          this.setState({
            message: response.data.message || "An error occurred. Credit Line Item cannot be deleted at this time.",
            messageFlavor: "danger",
            isMessageVisible: true
          })
        }
      }).catch(helpers.catchHandler);
  }

  onCreditRevive(creditId) {
    api.post(`Customer/ReviveCustomerCreditLine/${creditId}`)
      .then(response => {
        if (response.data.success) {
          this.setState({
            message: "Credit Line Item has been revived",
            messageFlavor: "success",
            isMessageVisible: true
          }, () => this.loadCreditLine());
        } else {
          this.setState({
            message: response.data.message || "An error occurred. Credit Line Item cannot be revived at this time.",
            messageFlavor: "danger",
            isMessageVisible: true
          })
        }
      }).catch(helpers.catchHandler);

  }

  onCreditEdit(creditId) {
    let list = this.state.customerOutstandingCredits.slice();
    const indexToEdit = _.findIndex(list, l => {
      return l.id === creditId;
    })
    list[indexToEdit].isEditing = true;
    this.setState({
      customerOutstandingCredits: list,
      creditPristine: list[indexToEdit],
      isCreditEditing: true
    });
  }

  onCreditCancel(creditId) {
    let list = this.state.customerOutstandingCredits.slice();
    if (creditId > 0) {
      const indexToEdit = _.findIndex(list, l => {
        return l.id === creditId;
      });

      list[indexToEdit].isEditing = false;
      list[indexToEdit] = this.state.creditPristine;
    } else {
      list = _.reject(list, l => { return l.id === creditId });
    }
    this.setState({
      customerOutstandingCredits: list,
      creditPristine: null,
      isCreditEditing: false
    });
  }

  render() {
    return (
      <Container fluid className="p-2">
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {this.state.message !== null && (
              <Row className="mb-2">
                <Col>
                  <Alert className={this.state.messageFlavor} isOpen={this.state.isMessageVisible } toggle={ this.onDismiss}>{this.state.message}</Alert>
                </Col>
              </Row>
            )}
            <Card className="p-2 mb-2">
              <CardHeader>
                <Row className="expand-md mt-2 mb-2 pb-0">
                  <Col>
                    <h4>Books</h4>
                  </Col>
                  <Col>
                    <Button size="sm" className={`${this.state.isBookOpen ? "secondary" : "primary"} float-right`} onClick={() => this.setState({ isBookOpen: !this.state.isBookOpen })}>Show {this.state.isBookOpen ? "Less" : "More"}</Button>
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={this.state.isBookOpen}>
                <Row className="mb-2">
                  <Col>
                    <Table striped hover size="sm">
                      <thead>
                          <tr>
                              <th>Book Name</th>
                              <th>Status</th>
                              <th>Status Due</th>
                              {/* <th>Management Shipment</th> */}
                          </tr>
                      </thead>
                      <tbody>
                          {this.state.pastCustomerSampleBooks.map(csb => (
                              <tr key={csb.id}>
                                  <td>{csb.sampleBookName}</td>
                                  <td>{csb.customerSampleBookStatusName}</td>
                                  <td>{date_helpers.formatDateToShortDate(csb.statusDate)}</td>
                                  {/* <td>{csb.isManagementShipment ? 'Y' : 'N'}</td> */}
                              </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Collapse>
            </Card>

            <Card className="p-2">
              <CardHeader>
                <Row className="expand-md mt-2 mb-2 pb-0">
                  <Col>
                      <h4>Outstanding Credits Due</h4>
                  </Col>

                  <Col>
                    <Button size="sm" className={`${this.state.isCreditOpen ? "secondary" : "primary"} float-right`} onClick={() => this.setState({ isCreditOpen: !this.state.isCreditOpen })}>Show {this.state.isCreditOpen ? "Less" : "More"}</Button>
                  </Col>
                </Row>
              </CardHeader>
                    <Collapse isOpen={this.state.isCreditOpen}>
                      <Row className="mb-2">
                        <Col>
                          <Table striped hover size="sm">
                            <thead>
                              <tr>
                                <th>Notes</th>
                                <th>Amount</th>
                                <th>Remaining Balance</th>
                                <th>Given On</th>
                                <th>Applied?</th>
                                <th><Button size="sm" className="success float-right" disabled={this.state.isCreditEditing } onClick={this.addNewCredit}>Add Credit</Button></th>
                              </tr>
                            </thead>
                            <tbody>
                          {this.state.customerOutstandingCredits && this.state.customerOutstandingCredits.length > 0 ?
                              this.state.customerOutstandingCredits.map((coc, index) => (
                                <tr key={coc.id} className={classnames({"bg-secondary": coc.applied})}>
                                  {coc.isEditing ? (
                                    <Fragment>
                                      <td colSpan="2"><span  className="boldText">Notes</span> <div><Input type="textarea" name="notes" placeholder="Notes" value={coc.notes || ""} onChange={(e) => this.onTextChange(e, index, "notes")} /></div></td>
                                      <td><span className="boldText">Amount</span> <div><Input type="number" name="amount" value={coc.amount} onChange={(e) => this.onTextChange(e, index, "amount")} /></div></td>
                                      <td>
                                        <ButtonGroup className="float-right">
                                          <Button size="sm" className="primary" onClick={() => this.onCreditSave(coc.id)}><FontAwesomeIcon icon="save" title="Save" /></Button>
                                          <Button size="sm" className="secondary" onClick={() => this.onCreditCancel(coc.id)}><FontAwesomeIcon icon="times" title="Cancel" /></Button>
                                        </ButtonGroup>
                                      </td>
                                    </Fragment>
                                  ) : (
                                      <Fragment>
                                        <td>{coc.notes}</td>
                                        <td>{coc.displayInitialAmount}</td>
                                        <td>{coc.displayAmount}</td>
                                        <td>{coc.givenOn ? moment(coc.givenOn).format("MM/DD/YYYY") : ""}</td>
                                        <td>
                                          {coc.applied ? <FontAwesomeIcon icon='check' /> : null}
                                        </td>
                                        <td>
                                          <ButtonGroup className="float-right">
                                            {coc.deactivatedAt ? (
                                              <Button size="sm" className="info" onClick={() => this.onCreditRevive(coc.id)} disabled={this.state.isCreditEditing}>Revive</Button>
                                            ) : (
                                                <Fragment>
                                                  <Button size="sm" className="primary" onClick={() => this.onCreditEdit(coc.id)} disabled={ this.state.isCreditEditing}><FontAwesomeIcon icon="edit" title="Edit" /></Button>
                                                  <Button size="sm" className="danger" onClick={() => this.onCreditDelete(coc.id)} disabled={this.state.isCreditEditing}><FontAwesomeIcon icon="times" title="Delete" /></Button>
                                                </Fragment>
                                              )}
                                          </ButtonGroup>
                                        </td>
                                      </Fragment>
                                    )}
                                </tr>
                              )) : <tr><td colSpan="4" className="detailTextNoSize">No records found</td></tr>}
                            </tbody>
                          </Table>
                      </Col>
                  </Row>
                </Collapse>
            </Card>

            {/*Hiding Special Pricing*/}
            {/*<Card className="p-2 mb-2">
              <CardHeader>
                <Row className="expand-md mt-2 mb-2 pb-0">
                  <Col>
                    <h4>Special Pricing</h4>
                  </Col>
                  <Col>
                    <Button size="sm" className={`${this.state.isPricingOpen ? "secondary" : "primary"} float-right`} onClick={() => this.setState({ isPricingOpen: !this.state.isPricingOpen })}>Show {this.state.isPricingOpen ? "Less" : "More"}</Button>
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={this.state.isPricingOpen}>
                <Row className="mb-2">
                  <Col>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Roll Size</th>
                          <th>Roll</th>
                          <th>Cut</th>
                          <th>Cmsn %</th>
                          <th>Given On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.customerSpecialPricing.map(csp => (
                          <tr key={csp.id}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Collapse>
            </Card>*/}

          </CardBody>
        </Card>
      </Container>
    );
  }
}