import React from 'react';
import api from '../utils/api';
import {
	Button,
	Row,
	Col,
	Card,
	CardBody,
    Table,
	Container,
	Collapse,
	CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';
import helpers from '../utils/helpers';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let nextNumber = 0;
const gimmeNextNumber = () => {nextNumber++; return nextNumber}

const DataRow = props => {
    return (
        <Row>
			<Col className="col-4">
				<strong>{props.header}:</strong>
			</Col>
			<Col className="col-8">
				{props.data || ""}
			</Col>
		</Row>
    );
};
         
export default class CustomerDetailsView extends React.Component {
    constructor(props) {
        super(props);

        const activeCustomerId = props.location && props.location.state ? props.location.state.customerId : null;

        this.state = {
            customerId: activeCustomerId,
            contactInfo: [],
            customerInfo: '',
            showPhone: false,
            selectedContact: null,
						customerAddressList: [],
        };
    }

    componentDidMount() {
        api.fetch(`Customer/GetActiveContacts/${this.state.customerId}`)
				.then((response) => {
						let customerInfo = response.data.customer;
						let customerContacts = response.data.contacts;
						customerContacts.map(c => {
								// remove deactivated phones and addresses for each contact
								const addresses = _.filter(c.addresses, a => a.deactivatedAt === null);
								const phoneNumbers = _.filter(c.phoneNumbers, p => p.deactivatedAt === null);
								c.expanded = true;
								c.addresses = addresses;
								c.phoneNumbers = phoneNumbers;
								return c;
						});
					this.setState({ contactInfo: customerContacts, customerInfo: customerInfo, customerAddressList: response.data.customerAddresses });
				})
				.catch(helpers.catchHandler);
    }

    togglePhoneInfo(selectedContactId) {
        const crIndex = _.findIndex(this.state.contactInfo, function (x) { return x.id === selectedContactId });
        const list = this.state.contactInfo.slice();
        list[crIndex].expanded = !list[crIndex].expanded;
        this.setState({ contactInfo: list });
    }

    render(){ 
	    const c = this.state.customerInfo;  
	    return (
		    <Container fluid>
			    <Card style={{ borderStyle: "none" }}>
				    <CardBody>
					    <Row>
						    <Col>
									<h3 className="page-title">Account Details For {c.name}</h3>
						    </Col>
					    </Row>
					    <Row className="row-spacing mb-2">
						    <Col xs="5">
									<DataRow header="Account Number" data={c.number} />
									<DataRow header="Name" data={c.name} />
									<DataRow header="Agent" data={c.agentName} />
									<DataRow header="Area" data={c.areaName} />
									<DataRow header="Account Type" data={c.accountTypeName} />
									<DataRow header="Credit Line" data={c.creditLine} />
									<DataRow header="Website URL" data={c.websiteURL} />
								</Col>
								<Col xs="5">
									<DataRow header="Invoice Terms" data={c.invoiceTermsName} />
									<DataRow header="Freight Terms" data={c.freightTermsName} />
									<DataRow header="Account Status" data={c.customerStatusName} />
									<DataRow header="Contact Preference Type" data={c.contactPreferenceTypeName} />
									<DataRow header="Tax No." data={c.taxNo} />
									<DataRow header="Auto Send?" data={c.autoSendSamples} />
									<DataRow header="Comments" data={c.comment} />
								</Col>
							</Row>
							<CardDeck className="mt-2">
							<Card className="outline-primary mb-2">
							<Row>
								<Col>
									<Table>
										<thead>
											<tr>
												<th>Account Address</th>
												<th>Type</th>
												<th>Is Primary</th>
											</tr>
										</thead>
										<tbody>
											{this.state.customerAddressList && this.state.customerAddressList.length ? (
												this.state.customerAddressList.map((address, index) => (
													<tr key={`ca-${index}`}>
														<td>
															<div>{address.ln1}</div>
															<div>{address.ln2}</div>
															<div>{address.ln3}</div>
															<div>{address.ln4}</div>
															<div>{address.city} {address.state}, {address.zip}</div>
														</td>
														<td>{address.addressTypeName}</td>
														<td>{address.isPrimary ? <FontAwesomeIcon icon="check" className="text-success" /> : null}</td>
													</tr>
											))) : (<tr><td>No addresses found</td></tr>)}
											</tbody>
									</Table>
								</Col>
								</Row>
								</Card>

							<Card>
					    <Row>
						    <Col>
									<Table>
										<thead>
											<tr>
													<th>Contact Name</th>
													<th>E-Mail</th>
													<th>Type of Contact</th>
													<th></th>
											</tr>
										</thead>
										<tbody>
											{this.state.contactInfo.map(i => (
												<React.Fragment key={`ci-${i.id}`}>
													<tr key={i.id}>
														<td>{i.firstName} {i.lastName}</td>
														<td>{i.email ? i.email : ""} </td>
														<td>{i.typeOfContactName ? i.typeOfContactName : ""}</td>
														<td>
															<Button
																	size="sm"
																	className="primary float-right"
																	onClick={this.togglePhoneInfo.bind(this, i.id)}
															>
																{/* <FontAwesomeIcon icon="grip-lines" /> */}
																{i.expanded ? (<span>Hide Details</span>) : (<span>Show More</span>)}
															</Button>
														</td>
													</tr>
													{i.expanded
														? <React.Fragment key={`ph-${i.id}`}>
															<tr>
																<th>Phone #</th>
																<th>Phone Type</th>
															</tr>
															{i.phoneNumbers.map(p => (
																<tr key={p.id}>
																	<td>{p.number ? helpers.formatPhoneNumber(p.number) : ""}</td>
																	<td>{p.typeOfPhoneName ? p.typeOfPhoneName : ""}</td>
																</tr>
															))}
															{/*
															<tr>
																<th>Address</th>
																<th>Address Type</th>
															</tr>
															{i.addresses.map(a => (
																<tr key={a.id}>
																	<td>
																		{helpers.formatAddress(a).map(line => {
																			return <Row key={gimmeNextNumber()}><Col>{line}</Col></Row>;
																		})}
																	</td>
																	<td>{a.typeOfAddressName ? a.typeOfAddressName : ""}</td>
																</tr>
															))}
															*/}
														</React.Fragment>
														: null
													}
												</React.Fragment>
											))}
										</tbody>
									</Table>
						    </Col>
								</Row>
								</Card>
							</CardDeck>
					    <Row className="row-spacing">
						    <Col>
							    <Button
								    className="secondary float-right"
								    size="sm"
								    tag={Link}
								    to="/CustomerAdmin"
							    >
								    <FontAwesomeIcon icon="times-circle" /> Cancel
									</Button>
						    </Col>
					    </Row>
				    </CardBody>
			    </Card>
		    </Container>
		);
	}
}