import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Table,
    Container,
    Form,
    Card,
    Alert as Alert2,
    CardBody,
    Input
} from 'reactstrap';
import { Alert } from "react-s-alert";
import api from '../utils/api';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import helpers from '../utils/helpers';
import filterHelpers from '../utils/filter_helpers';
import { ContactList } from './';
import {
    FilterSet,
    FilterText,
    FilterBoolean,
    SortHeader
} from '../components';


export default class Suppliers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suppliers: [], 
            messageFlavor: null,
            message: null,
            isMessageVisible: false,
            sortField: 'Name',
            sortDir: 'asc',
            filters: [{
              filterName: "ActiveOnly",
                value: true,
                filteredDisplay: "Active Only: Yes"
            }],
            clearValue: false,
            // supplierGroupList: [],
            contactTypeList: [],
            contactAddressTypeList: [],
            contacts: [],
            contactList: [],
        };

        this.refreshList = this.refreshList.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        // this.setPage = this.setPage.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
    }

    componentDidMount() {
        // const getSupplierGroups = api.fetch("Reference/GetSupplierGroupList")
        //     .then((response) => {
        //         return { supplierGroupList: response.data };
        //     })
        //     .catch(helpers.catchHandler);

        // Promise.all([
        //     getSupplierGroups
        // ])
        //     .then((aggregateResults) => {
        //         const newStatus = {};
        //         aggregateResults.forEach(r => Object.assign(newStatus, r));
        //         this.setState(newStatus, () =>
        //             this.refreshList());
        //     })
        //     .catch(helpers.catchHandler);
      this.refreshList();
    }

  refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir,
        };

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

    api.post('Supplier/GetPaginatedList', payload)
            .then((response) => {
                this.setState({
                    suppliers: response.data.list
                });
            }).catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filterHelpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    // setPage(page) {
    //     const newPagedList = Object.assign({}, this.state.pagedList);
    //     newPagedList.pageNumber = page;
    //     this.setState({ pagedList: newPagedList }, () => this.refreshList());
    // }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) return filterElement.value;
        return '';
    }

    handleDelete(id) {
        api.delete(`Supplier/${id}`)
        .then(response => {
          if (response.data.success) { 
            this.setState({ messageFlavor: "success", message: "Supplier was deleted", isMessageVisible: true }, ()=>this.refreshList());
                
            } else {
            this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleUnDelete(id) {
        api.post(`Supplier/undelete/${id}`)
        .then(response => {
          if (response.data.success) { 
            //Alert.success("Supplier was revived")
            this.setState({ messageFlavor: "success", message: "Supplier was revived", isMessageVisible: true }, () => this.refreshList());
                
            } else {
            this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

  onDismiss() {
    this.setState({
      isMessageVisible: !this.state.isMessageVisible,
      message: null,
      messageColor: null
    });
  }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                      <Row>
                          <Col>
                              {this.state.message !== null && (
                                  <Row className="mb-2">
                                      <Col>
                                        <Alert2 className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={() => this.onDismiss()}>
                                          {this.state.message}
                                        </Alert2>
                                      </Col>
                                  </Row>
                              )}
                              <Row>
                                  <Col>
                                      <h3 className="pull-left page-title">Manage Suppliers</h3>
                                  </Col>
                              </Row>
                              <Row className="expand-md mt-2 mb-2 pb-0">
                                  <Col sm="12">
                                      <ButtonGroup className="float-right">
                                          <Button
                                              className="btn-sm success"
                                              tag={Link}
                                              to={`/editSupplier/new`}
                                          >
                                              <FontAwesomeIcon icon="plus" /> Add Supplier
                                          </Button>
                                      </ButtonGroup>
                                      <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                          <Row>
                                              <Col xs="3">
                                                  <FilterText
                                                      filterName="Name"
                                                      displayName="Company Name"
                                                      value={this.currentFilterValue('Name')}
                                                      onChangeCallback={this.filterChange}
                                                  />
                                              </Col>
                                              <Col xs="3">
                                                  <FilterText
                                                      filterName="AccountNumber"
                                                      displayName="Account Number"
                                                      value={this.currentFilterValue('AccountNumber')}
                                                      onChangeCallback={this.filterChange}
                                                  />
                                              </Col>
                                              <Col xs="3">
                                                  <FilterText
                                                      filterName="SamplesEmail"
                                                      displayName="Sample Email"
                                                      value={this.currentFilterValue('SamplesEmail')}
                                                      onChangeCallback={this.filterChange}
                                                  />
                                              </Col>
                                              <Col xs="3">
                                                  <FilterBoolean
                                                      filterName="ActiveOnly"
                                                      displayName="Active Only"
                                                      yesOnly={true}
                                                      value={this.currentFilterValue('ActiveOnly')}
                                                      onChangeCallback={this.filterChange}
                                                      clearValue={this.state.clearValue} />
                                              </Col>
                                          </Row>
                                      </FilterSet>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      <Table striped hover size="sm">
                                          <thead>
                                              <tr>
                                                  <th>
                                                      <SortHeader
                                                          displayName="Supplier Name"
                                                          field="Name"
                                                          sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'Name'}
                                                          callBack={this.doSort}
                                                      />
                                                  </th>
                                                  <th>
                                                      <SortHeader
                                                          displayName="Account #"
                                                          field="AccountNumber"
                                                          sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'AccountNumber'}
                                                          callBack={this.doSort}
                                                      />
                                                  </th>
                                                  <th>
                                                      <SortHeader
                                                          displayName="Samples Email"
                                                          field="SamplesEmail"
                                                          sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'SamplesEmail'}
                                                          callBack={this.doSort}
                                                      />
                                                  </th>
                                                  <th />
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {this.state.suppliers.map(s => (
                                                  <tr key={s.id} className="data-row">
                                                      <td>{s.name}</td>
                                                      <td>{s.accountNumber}</td>
                                                      <td>{s.samplesEmail}</td>
                                                      <td className="text-right">
                                                          <ButtonGroup>
                                                              {s.deactivatedAt === null
                                                                  ? (
                                                                      <React.Fragment>
                                                                          <Button
                                                                              className="primary btn-outline-secondary"
                                                                              tag={Link}
                                                                              to={`/editSupplier/${s.id}`}
                                                                              size="sm"
                                                                          >
                                                                              <FontAwesomeIcon icon="edit" />
                                                                          </Button>
                                                                          <Button
                                                                              tag={Link}
                                                                              to={{
                                                                                  pathname: '/viewSupplier',
                                                                                  state: { supplierId: s.id },
                                                                                  push: true
                                                                              }}
                                                                              className="info btn-outline-secondary" size="sm"
                                                                          >
                                                                              <FontAwesomeIcon icon="eye" />
                                                                          </Button>
                                                                          <Button
                                                                              className="danger btn-outline-secondary"
                                                                              size="sm"
                                                                              onClick={this.handleDelete.bind(this, s.id)}
                                                                          >
                                                                              <FontAwesomeIcon icon="trash" />
                                                                          </Button>
                                                                      </React.Fragment>
                                                                  ) : (
                                                                      <Button
                                                                          className="info" size="sm"
                                                                          onClick={this.handleUnDelete.bind(this, s.id)}
                                                                      >
                                                                          <FontAwesomeIcon icon="recycle" /> Revive
                                                                      </Button>
                                                                  )}
                                                          </ButtonGroup>
                                                      </td>
                                                  </tr>
                                              ))}
                                          </tbody>
                                      </Table>
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
