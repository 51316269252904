import React from 'react';
import {
  Container, TabContent, TabPane, Nav, NavItem, NavLink,
  Card, Row, Col, CardBody
} from 'reactstrap';
import classnames from 'classnames';
import AgentSampleBookInventory from './AgentSampleBookInventory';
import EditAgent from './EditAgent';

export default class AgentAdminContainer extends React.Component {
  constructor(props) {
    super(props);
    const activeAgentId = props.location && props.location.state ? props.location.state.agentId : null;
    this.state = {
      activeTab: '1',
      agentId: activeAgentId
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <Container fluid className="p-2">
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            <Row>
              <Col>
                <h3 className="pull-left page-title">Agent Administration</h3>
              </Col>
            </Row>
            <Row className="expand-md mt-2 mb-0 pb-0">
              <Col sm="2">
                <Nav tabs className="flex-column nav-pills">
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '1', primary: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }} >Agent Details
                    </NavLink>
                  </NavItem>
                  {this.state.agentId > 0 && (
                      <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2', primary: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }} >Sample Book Inventory
                        </NavLink>
                      </NavItem>
                  )}
                </Nav>
              </Col>
              <Col sm="10">
                <TabContent activeTab={this.state.activeTab} className="mt-2">
                  <TabPane tabId="1">
                    {this.state.activeTab === "1" &&
                      <EditAgent
                        agentId={this.state.agentId}
                      />
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.activeTab === "2" &&
                      <AgentSampleBookInventory
                        agentId={this.state.agentId}
                      />
                    }
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}