import React, { Component } from 'react';
// import { basename } from 'path';
// const base_url = 'api/Area/';



export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
    }

          

    handleNextClick(pageNumbers) {
        const currentPage = parseInt(this.props.currentPage, 10);

            if (currentPage < pageNumbers.length) {
                const nextPage = currentPage + 1;
                this.props.onPageChange(nextPage);
        }
    }

    handlePreviousClick() {
        if (this.props.currentPage > 1) {
            this.props.onPageChange(this.props.currentPage - 1);
        }
    }

    handlePageClick(e){
        this.props.onPageChange(e.target.id);
        }


    render() {
        const pageNumbers = [];
        const list = this.props.listOfItems;
        const perPage = this.props.itemsPerPage;

        for (let i = 1; i <= Math.ceil(list.length / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                //conditionally styling page number for current page
                 this.props.currentPage !== number ?
                <li key={number} id={number} onClick={this.handlePageClick.bind(number)}>
                    {number} </li>
                    :
                <li key={number} id={number} style={{ color: "black", fontWeight: "bold"}} onClick={this.handlePageClick.bind(number)}>
                    {number}
                </li>
            );

        });

        return (
            <div id="page-list">

                <ul id="page-number">
                    <li id="page-number-title">
                        Pages:
                    </li>
                    {renderPageNumbers}
                     </ul>  
                
                {this.props.currentPage > 1 &&
                    <button className="btn btn-primary btn-sm" onClick={this.handlePreviousClick}> &lt; Previous</button>}
                    &nbsp;
                {this.props.currentPage < pageNumbers.length &&
                    <button className="btn btn-primary btn-sm" onClick={this.handleNextClick.bind(this, pageNumbers)}>Next &gt;</button>}
             </div>
        );
    }
}

//export default Pagination;
