const getYearList = () => {
  let results = [];
  let yr = 2000;
  while (yr <= 2040) {
    results.push({
      value: yr,
      label: yr + "",
    });
    yr++;
  }
  return results;
};

const RENDER_TYPES = {
  CSV: 1,
  PDF: 2,
  Excel: 3,
};

const REPORT_PARAMETER_TYPES = {
  Text: 0,
  DateRange: 1,
  Date: 2,
  ApiList: 3,
  Integer: 4,
  Boolean: 5,
};

const MITERED_PRODUCT_NAME = "Applied Binding (Hidden Stitch Mitered Corners)";

const FINISHED_RUG_SIZES = [
  {
    value: 1,
    label: "Custom",
    rugWidthFeet: "0",
    rugWidthInches: "0",
    rugLengthFeet: "0",
    rugLengthInches: "0",
  },
  {
    value: 2,
    label: `2' X 3'`,
    rugWidthFeet: "2",
    rugWidthInches: "0",
    rugLengthFeet: "3",
    rugLengthInches: "0",
  },
  {
    value: 3,
    label: `18' X 18'`,
    rugWidthFeet: "18",
    rugWidthInches: "0",
    rugLengthFeet: "18",
    rugLengthInches: "0",
  },
  {
    value: 4,
    label: `9' X 9'`,
    rugWidthFeet: "9",
    rugWidthInches: "0",
    rugLengthFeet: "9",
    rugLengthInches: "0",
  },
  {
    value: 5,
    label: `2' X 4'`,
    rugWidthFeet: "2",
    rugWidthInches: "0",
    rugLengthFeet: "4",
    rugLengthInches: "0",
  },
  {
    value: 6,
    label: `3' X 5'`,
    rugWidthFeet: "3",
    rugWidthInches: "0",
    rugLengthFeet: "5",
    rugLengthInches: "0",
  },
  {
    value: 7,
    label: `4' X 6'`,
    rugWidthFeet: "4",
    rugWidthInches: "0",
    rugLengthFeet: "6",
    rugLengthInches: "0",
  },
  {
    value: 8,
    label: `5' X 8'`,
    rugWidthFeet: "5",
    rugWidthInches: "0",
    rugLengthFeet: "8",
    rugLengthInches: "0",
  },
  {
    value: 9,
    label: `6' X 9'`,
    rugWidthFeet: "6",
    rugWidthInches: "0",
    rugLengthFeet: "9",
    rugLengthInches: "0",
  },
  {
    value: 10,
    label: `8' X 10'`,
    rugWidthFeet: "8",
    rugWidthInches: "0",
    rugLengthFeet: "10",
    rugLengthInches: "0",
  },
  {
    value: 11,
    label: `8' X 11'`,
    rugWidthFeet: "8",
    rugWidthInches: "0",
    rugLengthFeet: "11",
    rugLengthInches: "0",
  },
  {
    value: 12,
    label: `9' X 12'`,
    rugWidthFeet: "9",
    rugWidthInches: "0",
    rugLengthFeet: "12",
    rugLengthInches: "0",
  },
  {
    value: 13,
    label: `10' X 14'`,
    rugWidthFeet: "10",
    rugWidthInches: "0",
    rugLengthFeet: "14",
    rugLengthInches: "0",
  },
  {
    value: 14,
    label: `12' X 15'`,
    rugWidthFeet: "12",
    rugWidthInches: "0",
    rugLengthFeet: "15",
    rugLengthInches: "0",
  },
  {
    value: 15,
    label: `12' X 18'`,
    rugWidthFeet: "12",
    rugWidthInches: "0",
    rugLengthFeet: "18",
    rugLengthInches: "0",
  },
];

const CUSTOM_RUG_SHAPES = {
  ROUND: "Round",
  RECTANGULAR: "Rectangular",
  SQUARE: "Square",
};

const CUSTOM_RUG_SHAPE_LIST = {
  ROUND: { label: CUSTOM_RUG_SHAPES.ROUND, value: 1 },
  RECTANGULAR: { label: CUSTOM_RUG_SHAPES.RECTANGULAR, value: 2 },
  ODD_SHAPES: { label: "Odd Shapes", value: 3 },
  NOT_APPLICABLE: { label: "N/A", value: 4 },
  SQUARE: { lable: CUSTOM_RUG_SHAPES.SQUARE, value: 5 },
};

const PRICING_TYPES = [
  { value: 1, label: "Net Sales" },
  { value: 2, label: "Area Specific" },
];

export default {
  NONE_INTERNATIONAL_RUG_COLLECTION: ".NONE",
  monthList: [
    { value: 0, label: "JANUARY" },
    { value: 1, label: "FEBRUARY" },
    { value: 2, label: "MARCH" },
    { value: 3, label: "APRIL" },
    { value: 4, label: "MAY" },
    { value: 5, label: "JUNE" },
    { value: 6, label: "JULY" },
    { value: 7, label: "AUGUST" },
    { value: 8, label: "SEPTEMBER" },
    { value: 9, label: "OCTOBER" },
    { value: 10, label: "NOVEMBER" },
    { value: 11, label: "DECEMBER" },
  ],
  yearList: getYearList(),
  USER_ROLES: [
    { id: 1, name: "Administrator", value: 1, label: "Administrator" },
    { id: 2, name: "Data Entry", value: 2, label: "Data Entry" },
    { id: 3, name: "Reporting", value: 3, label: "Reporting" },
    { id: 4, name: "Finance Clerk", value: 4, label: "Finance Clerk" },
    { id: 5, name: "Agent", value: 5, label: "Agent" },
  ],
  PRICING_TYPES: PRICING_TYPES,
  RENDER_TYPES: RENDER_TYPES,
  MITERED_PRODUCT_NAME: MITERED_PRODUCT_NAME,
  FINISHED_RUG_SIZES: FINISHED_RUG_SIZES,
  CUSTOM_RUG_SHAPES: CUSTOM_RUG_SHAPES,
  CUSTOM_RUG_SHAPE_LIST: CUSTOM_RUG_SHAPE_LIST,
  RENDER_TYPE_LIST: [
    { value: 1, label: "CSV" },
    { value: 2, label: "Adobe PDF" },
    { value: 3, label: "Excel" },
  ],
  REPORT_PARAMETER_TYPES: REPORT_PARAMETER_TYPES,
  ORDER_STATUS: {
    CANCEL: { value: 1, label: "CANCEL" },
    CLOSED: { value: 2, label: "CLOSED" },
    MEMO_BLD: { value: 3, label: "MEMO BLD" },
    OPEN: { value: 4, label: "OPEN" },
    QUOTE: { value: 5, label: "QUOTE" },
  },
  PRODUCT_STATUS: {
    CURRENT: { value: 1, label: "CURRENT" },
    KEY_PRODUCT: { value: 2, label: "KEY_PRODUCT" },
    INACTIVE: { value: 3, label: "INACTIVE" },
    DROPPED: { value: 4, label: "DROPPED" },
  },
  ORDER_LINE_TYPE: {
    CARPET: { value: 1, label: "CARPET" },
    FREIGHT: { value: 2, label: "FREIGHT" },
    MISCELLANEOUS: { value: 3, label: "MISCELLANEOUS" },
    RUG_PAD: { value: 4, label: "RUG PAD" },
    SALES_TAX: { value: 5, label: "SALES TAX" },
    CUSTOM_RUG: { value: 6, label: "CUSTOM RUG" },
    INTL_COLL_RUG: { value: 7, label: "INTL COLL RUG" },
    HARD_SURFACE: { value: 8, label: "HARD_SURFACE" },
    CREDIT_CARD_SURCHARGE: { value: 9, label: "CREDIT CARD SURCHARGE" },
    ROLL_RUNNER: { value: 10, label: "ROLL RUNNER" },
    BINDING: { value: 11, label: "BINDING" },
    CARPET_TILE: { value: 12, label: "CARPET TILE" },
    SAMPLE: { value: 13, label: "SAMPLE" },
    RUG_FIELD: { value: 21, label: "RUG:FIELD" },
    RUG_BORDER: { value: 22, label: "RUG:BORDER" },
    RUG_MISCELLANEOUS: { value: 23, label: "RUG:MISCELLANEOUS" },
  },
  PHONE_TYPE: {
    HOME: 1,
    CELL: 2,
    FAX: 3,
    OFFICE: 4,
    SHIP_TO: 9,
    TOLL_FREE: 11,
  },
  ADDRESS_TYPE: {
    BILL_TO: 1,
    SHIP_TO: 2,
    PO_BOX: 3,
    HOME: 4,
    SAMPLES_ONLY: 8,
  },
  PAYMENT_TYPE: {
    CASH: { value: 1, label: "CASH" },
    EXTENDED_CREDIT: { value: 2, label: "EXTENDED CREDIT" },
    CREDIT_CARD: { value: 3, label: "CREDIT CARD" },
    REBATE: { value: 4, label: "REBATE" },
    CHECK: { value: 5, label: "CHECK" },
    CREDIT: { value: 6, label: "CREDIT" },
  },
  PRODUCT_TYPE: {
    CARPET: { value: 1, label: "CARPET" },
    RUG: { value: 2, label: "RUG" },
    MISCELLANEOUS: { value: 3, label: "MISCELLANEOUS" },
    RUG_PAD: { value: 4, label: "RUG PAD" },
    RUG_COMPONENT: { value: 6, label: "RUG COMPONENT" },
    INTL_RUG: { value: 7, label: "INTL COLL RUG" },
    HARD_SURFACE: { value: 8, label: "HARD_SURFACE" },
    ROLL_RUNNER: { value: 9, label: "ROLL_RUNNER" },
    BORDER_OR_BINDING: { value: 10, label: "BORDER OR BINDING" },
    CARPET_TILE: { value: 11, label: "CARPET TILE" },
    SAMPLE: { value: 12, label: "SAMPLE" },
    SQUARE_FOOT_RUG: { value: 13, label: "SQUARE FOOT RUG" },
  },
  ATTACHMENT_TYPE: {
    PRODUCT_IMAGE_B: { value: 1, label: "Product Image - Size B" },
    PRODUCT_IMAGE_C: { value: 2, label: "Product Image - Size C" },
    PRODUCT_IMAGE_D: { value: 3, label: "Product Image - Size D" },
    PRODUCT_IMAGE_ROOM_SCENE: { value: 4, label: "Product Image - Room Scene" },
    PRODUCT_IMAGE_CLOSE_UP: { value: 5, label: "Product Image - CU" },
    DETAILED_SPECS: { value: 6, label: "Detailed Specs" },
    PRODUCT_COLOR_IMAGE_B: { value: 7, label: "Product Color Image - Size B" },
    PRODUCT_COLOR_IMAGE_C: { value: 8, label: "Product Color Image - Size C" },
    PRODUCT_COLOR_IMAGE_D: { value: 9, label: "Product Color Image - Size D" },
    CUSTOMER_TAX_CERTIFICATE: { value: 10, label: "Customer Tax Certificate" },
    MEMO_BILLED_SAMPLE_FORM: { value: 11, label: "Memo Billed Sample Form" },
    CLAIM_IMAGE: { value: 12, label: "Claim Image" },
  },
  PRODUCT_COLOR_EXCLUDED_FILTERS: [
    { value: 1, label: "Product Image - Size B" },
    { value: 2, label: "Product Image - Size C" },
    { value: 3, label: "Product Image - Size D" },
    { value: 4, label: "Product Image - Room Scene" },
    { value: 5, label: "Product Image - CU" },
    { value: 6, label: "Detailed Specs" },
    { value: 10, label: "Customer Tax Certificate" },
    { value: 11, label: "Memo Billed Sample Form" },
    { value: 12, label: "Claim Image" },
  ],
  PRODUCT_DETAILS_EXCLUDED_FILTERS: [
    { value: 1, label: "Product Image - Size B" },
    { value: 2, label: "Product Image - Size C" },
    { value: 3, label: "Product Image - Size D" },
    { value: 5, label: "Product Image - CU" },
    { value: 7, label: "Product Color Image - Size B" },
    { value: 8, label: "Product Color Image - Size C" },
    { value: 9, label: "Product Color Image - Size D" },
    { value: 10, label: "Customer Tax Certificate" },
    { value: 11, label: "Memo Billed Sample Form" },
    { value: 12, label: "Claim Image" },
  ],
  PRICE_UNIT_TYPE: {
    SQ_YARDS: { value: 1, label: "Sq Yds" },
    SQ_FT: { value: 2, label: "Sq Ft" },
    LIN_FT: { value: 3, label: "Lin Ft" },
    EACH: { value: 4, label: "Each" },
    CARTON: { value: 5, label: "Carton" },
  },
  BACKING_TYPE: {
    COTTON: 1,
    LATEX: 2,
    OTHER: 3,
  },
  ORDER_LINE_STATUS: {
    IN_STOCK: { value: 1, label: "IN_STOCK" },
    BACKORDER: { value: 2, label: "BACKORDER" },
    SHIPPED: { value: 3, label: "SHIPPED" },
    STK_DYLOT: { value: 4, label: "STK_DYLOT" },
    IN_PROCESS: { value: 5, label: "IN_PROCESS" },
    SHIP_TO_GA: { value: 6, label: "SHIP_TO_GA" },
    RESERVE: { value: 7, label: "RESERVE" },
    DELIVERED: { value: 8, label: "DELIVERED" },
    DELAY_SHIP: { value: 9, label: "DELAY_SHIP" },
    NA: { value: 10, label: "N/A" },
    MILL_PICKUP: { value: 11, label: "MILL_PICKUP" },
    IN_STK_CA: { value: 12, label: "IN_STK_CA" },
    MAT_EMERY_PARK: { value: 13, label: "MAT_EMERY_PARK" },
    MAT_STAR: { value: 14, label: "MAT_STAR" },
    MAT_MCC: { value: 15, label: "MAT_MCC" },
    MAT_DCR: { value: 16, label: "MAT_DCR" },
    MAT_DERNIER: { value: 17, label: "MAT_DERNIER" },
    MAT_GE: { value: 18, label: "MAT_GE" },
  },
  CARPET_PRODUCT_TYPE: {
    UNKNOWN: { value: 1, label: "UNKNOWN" },
    INTERNATIONAL: { value: 2, label: "International" },
    DOMESTIC: { value: 3, label: "Domestic" },
  },
  CUSTOMER_SAMPLE_BOOK_STATUS_IDS: {
    shipped: 2,
    faxed: 3,
    rejected: 4,
    backorder: 5,
    agent: 7,
  },
  CUSTOMER_SAMPLE_BOOK_STATUS_COLORS: {
    shipped: "backgroundSuccess",
    faxed: "backgroundPrimary",
    rejected: "backgroundDanger",
    backorder: "backgroundWarning",
    agent: "backgroundPrimaryDarker",
  },
  CUSTOMER_SAMPLE_BOOK_STATUSES: {
    shipped: "Shipped",
    faxed: "Faxed",
    rejected: "Rejected",
    backorder: "Backorder",
    agent: "Agent",
  },
  SORT_DIRECTION: {
    ascending: 1,
    descending: 2,
  },
  MEMO_ORDERLINE_STATUS: {
    PENDING: 1,
    BACKORDER: 2,
    SHIPPED: 3,
  },
  MEMO_SHIP_METHOD: {
    FEDEX_GROUND: { value: 1, label: "FedEx Ground" },
    FEDEX_EXPRESS: { value: 2, label: "FedEx Express" },
    USPS_PRIORITY: { value: 3, label: "USPS Priority" },
  },
  CUSTOMER_ATTACHMENT_TYPE: {
    TAX_CERTIFICATE: 1,
    MEMO_BILLED_SAMPLE_FORM: 2,
  },
  INVOICE_TERMS: {
    CBD: 1,
    NET30: 2,
  },
  MIME_XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml",
  MIME_CSV: "text/csv",
  MIME_PDF: "application/pdf",
  TYPE_OF_INVOICE: {
    Acknowledgement: 1,
    Invoice: 2,
    Proforma: 3,
    Quote: 4,
  },
  SAMPLE_BOOK_STATUS_TYPE: {
    INACTIVE: 1,
    ACTIVE: 2,
    DROPPED: 3,
  },
  STATES_LIST: [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AR", label: "Arkansas" },
    { value: "AZ", label: "Arizona" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "Ohio", label: "OH" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ],
  CUSTOM_RUG_DEFAULT_PRICES: {
    RUG_BACKING_PRICE_PER: "0.85",
    RUG_BACKING_COST_PER: "0.50",
    PAD_CUT_COST: "22.00",
    PAD_CUT_PRICE: "30.00",
  },
  SERGING_LIST: [
    {
      id: 1135,
      dcProductId: 193937,
      name: "3 Wool Serging Charge",
      costPer: 2.7,
      pricePer: 4.5,
    }, // Legacy product Id = 1393
    {
      id: 3835,
      dcProductId: 199289,
      name: "3 Premium Wool Serging Charge",
      costPer: 7.5,
      pricePer: 12.5,
    }, // Legacy product Id = 4568
    {
      id: 4231,
      dcProductId: 204044,
      name: "3 Wool Serging Charge (N)",
      costPer: 2.75,
      pricePer: 4.6,
    }, // Legacy product Id = 4997
  ],
  MACHINE_SERGING_LIST: [
    {
      id: 2177,
      dcProductId: 193938,
      name: "4 Applied Borders  (Machine) 2-3/4",
      costPer: 2.75,
      pricePer: 4.75,
    },
  ], // Legacy product Id = 2668
  MITERED_LIST: [
    {
      id: 2563,
      dcProductId: 193945,
      name: MITERED_PRODUCT_NAME,
      costPer: 4.75,
      pricePer: 7.95, // Legacy product Id = 3105
    },
  ],
  CUSTOM_RUG_CONFIG_NAMES: {
    DefaultCutChargeProduct: "DefaultCutChargeProduct",
    DefaultRugEndArmorProduct: "DefaultRugEndArmorProduct",
    DefaultInboundFreightProduct: "DefaultInboundFreightProduct",
    DefaultBackingProduct: "DefaultBackingProduct",
    DefaultPadCutChargeProduct: "DefaultPadCutChargeProduct",
    Under30SFPadCutChargeProduct: "Under30SFPadCutChargeProduct",
    DefaultClippedCornersProduct: "DefaultClippedCornersProduct",
    DefaultLaborCutoutProduct: "DefaultLaborCutoutProduct",
    DefaultLaborRoundProduct: "DefaultLaborRoundProduct",
    DefaultFabricationChargeProduct: "DefaultFabricationChargeProduct",
    DefaultUVSergingChargeProduct: "DefaultUVSergingChargeProduct",
    DefaultWoolSergingChargeProduct: "DefaultWoolSergingChargeProduct",
    DefaultPremiumWoolSergingChargeProduct:
      "DefaultPremiumWoolSergingChargeProduct",
    DefaultWoolSergingChargeNProduct: "DefaultWoolSergingChargeNProduct",
    DefaultAppliedMachineBordersProduct: "DefaultAppliedMachineBordersProduct",
    DefaultMiteredProduct: "DefaultMiteredProduct",
    DefaultRugPadLuxFeltCustomSizeProduct:
      "DefaultRugPadLuxFeltCustomSizeProduct",
    DefaultSevenSeamPadPerLFFeeProduct: "DefaultSevenSeamPadPerLFFeeProduct",
    BindingCottonSmallProduct: "BindingCottonSmallProduct",
    BindingSolidSmallProduct: "BindingSolidSmallProduct",
  },
  MIN_REPEAT: "3.0",
  COMMON_SUPPLIER_IDS: {
    NOURISON: 77,
    EMERY_PARK: 53,
    DESIGN_MATERIAL: 62,
  },
  CONSTRUCTION_TYPE_IDS: {
    INDOOR_OUTDOOR: 17,
  },
};
