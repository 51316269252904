import React, { Component, Fragment } from 'react';
import {
    Container, TabContent, TabPane, Nav, NavItem, NavLink,
    Card, Row, Col, CardBody, Button
} from 'reactstrap';
import classnames from 'classnames';
import { CustomerProductLookUp } from '.';
import AddCustomersAndContacts from './AddCustomersAndContacts';
import CustomerMoreDetails from './CustomerMoreDetails';
import CustomerOrderHistory from './CustomerOrderHistory';
import CustomerAddress from './CustomerAddress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default class CustomerAdminContainer extends Component {
  constructor(props) {
    super(props);
    let activeCustomerId = props.location && props.location.state && props.location.state.customerId 
      ? parseInt(props.location.state.customerId, 10)
      : null;
    const activeTab = props.computedMatch && props.computedMatch.params && props.computedMatch.params.activeTab
      ? props.computedMatch.params.activeTab
      : '1';
    if (!activeCustomerId && props.computedMatch && props.computedMatch.params && props.computedMatch.params.customerId) {
      activeCustomerId = parseInt(props.computedMatch.params.customerId, 10);
    }
    this.state = {
      activeTab: activeTab,
      customerId: activeCustomerId
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    let prevCustomerId = prevProps.location && prevProps.location.state && prevProps.location.state.customerId;
    let currentCustomerId = this.props.location && this.props.location.state && this.props.location.state.customerId;
    let prevCustomerIdFromURL = prevProps.location && prevProps.location.state && prevProps.computedMatch.params.customerId;
    let currentCustomerIdFromURL = this.props.location && this.props.location.state && this.props.computedMatch.params.customerId;
    let prevActiveTab = prevProps.location && prevProps.location.state && prevProps.location.state.activeTab;
    let currentActiveTab = this.props.location && this.props.location.state && this.props.location.state.activeTab;
    if (prevCustomerId !== currentCustomerId && currentCustomerId) {
      this.setState({ customerId: parseInt(currentCustomerId, 10)})
    }
    if (prevCustomerIdFromURL !== currentCustomerIdFromURL && currentCustomerIdFromURL) {
      this.setState({ customerId: parseInt(currentCustomerIdFromURL, 10)})
    }
    if (prevActiveTab !== currentActiveTab) {
      this.setState({ activeTab: currentActiveTab})
    }
  }

  toggle(tab) {
      if (this.state.activeTab !== tab) {
          this.setState({
              activeTab: tab
          });
      }
  }


    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        <Row>
                            <Col>
                                <h3 className="pull-left page-title">Account Administration</h3>
                            </Col>
                  <Col><Button size="sm" className="secondary float-right" tag={Link} to={"/customeradmin"}><FontAwesomeIcon icon="reply" className="mr-2" />Back to Accounts</Button></Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col sm="2">
                                <Nav tabs className="flex-column nav-pills">
                                    <NavItem>
                                      <NavLink className={classnames({ active: this.state.activeTab === '1', primary: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }} >Account Details
                                      </NavLink>
                                    </NavItem>
                                  {this.state.customerId
                                    ? (<Fragment>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.activeTab === '2', primary: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >More Details
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.activeTab === '3', primary: this.state.activeTab === '3' })}
                                            onClick={() => { this.toggle('3'); }} >Order Information
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.activeTab === '4', primary: this.state.activeTab === '4' })}
                                            onClick={() => { this.toggle('4'); }} >Addresses
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.activeTab === '5', primary: this.state.activeTab === '5' })}
                                            onClick={() => { this.toggle('5'); }} >Product Lookup
                                          </NavLink>
                                        </NavItem>
                                        </Fragment>)
                                    : null
                                  }
                                </Nav>
                            </Col>
                            <Col sm="10">
                                <TabContent activeTab={this.state.activeTab} className="mt-2">
                                  {this.state.activeTab === "1" &&
                                    <TabPane tabId="1">
                                      <AddCustomersAndContacts
                                        currentUser={this.props.currentUser}
                                        customerId={this.state.customerId}
                                        setActiveTab={tab => this.setState({activeTab: tab})}
                                        setActiveCustomer={id => this.setState({customerId: id})}
                                      />
                                    </TabPane>
                                  }
                                  {this.state.activeTab === "2" &&
                                    <TabPane tabId="2">
                                      <CustomerMoreDetails customerId={this.state.customerId} />
                                    </TabPane>
                                  }
                                  {this.state.activeTab === "3" &&
                                    <TabPane tabId="3">
                                      <CustomerOrderHistory customerId={this.state.customerId} />
                                    </TabPane>
                                  }
                                  {this.state.activeTab === "4" &&
                                    <TabPane tabId="4">
                                      <CustomerAddress customerId={this.state.customerId} />
                                    </TabPane>
                                  }
                                  {this.state.activeTab === "5" &&
                                    <TabPane tabId="5">
                                      <CustomerProductLookUp customerId={this.state.customerId} />
                                    </TabPane>
                                  }
                                </TabContent>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}