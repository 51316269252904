import React, { Component } from 'react';

export class ErrorMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    componentDidUpdate(prevProps) {
        this.setState({ errors: this.props.errors });
    }

    render() {
        return (
            <div className="errorMessage">
                {
                    this.state.errors.map(e => (<div>{e}</div>))
                }
            </div>
        );
    }
}

export default ErrorMessage;
