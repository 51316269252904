import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Collapse, CardBody, Button, ButtonGroup, Alert, Label, Row, Col, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import api from '../utils/api';
import helpers from '../utils/helpers';
import filterHelpers from '../utils/filter_helpers';
import Switch from "react-switch";
import { FilterBoolean, FilterSet, FilterText, SortHeader } from '../components/';

const emptyArea = {
    id: null,
    name: '',
    number: '',
    pricingDiscount: null,
    pricingDiscountActive: false
};

class AreaAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areas: [],
            messageFlavor: 'danger',
            message: null,
            editItem: emptyArea,
            showAdd: false,
            sortField: 'Name',
            sortDir: 'asc',
            filters: [{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Yes" }],
            clearValue: false
        };
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onIsActive = this.onIsActive.bind(this);
        this.onEditIsActiveChange = this.onEditIsActiveChange.bind(this);
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList(sortField, sortDirection, filters) {
        let filterList = filters || this.state.filters;
        let payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir
        }

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('Area/GetList', payload)
            .then((response) => {
                this.setState({
                    areas: response.data
                });
            })
            .catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    onIsActive() {
        this.setState({
          editItem: {
            ...this.state.editItem,
            pricingDiscountActive: !this.state.editItem.pricingDiscountActive
          }
        });
      }

    filterChange(changedFilter) {
        const filters = filterHelpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    onAdd() {
        this.setState({
            editItem: emptyArea,
            showAdd: true
        });
    }

    onCancel() {
        this.setState({
            editItem: emptyArea,
            showAdd: false, 
            message: null, 
            messageFlavor: null
        }, () => {this.refreshList()});
    }

    onTextChange(fieldName, value) {
        this.setState({ 
            editItem: {
                ...this.state.editItem,
                [fieldName]: value 
            }
        });
    }

    isFormValid(item) {
        let warnings = [];
        if (!item.Name) {
            warnings.push("Please provide a valid area name.");
        }
        if (!item.PricingDiscount && item.PricingDiscountActive) {
            warnings.push("If you wish to apply discount please provide a discount percentage.")
        }
        if (parseFloat(item.PricingDiscount) < -99) {
            warnings.push("You may not discount more than 99%")
        }
        if (warnings.length) {
            this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onSubmit() {
        window.scroll(0, 0);
        const payload = {
            Name: this.state.editItem.name,
            Number: this.state.editItem.number,
            PricingDiscount: this.state.editItem.pricingDiscount,
            PricingDiscountActive: this.state.editItem.pricingDiscountActive
        };
        if (!this.isFormValid(payload)) return;
        let verb = "post";
        api.upload(verb, 'Area/', payload)
        .then(response => {
            if (response.data.success) {
                this.setState({ 
                    messageFlavor: "success", 
                    message: "The area record was updated." 
                }, () => this.onCancel());
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }
        })
        .catch(helpers.catchHandler);
    }

    onUpdateSubmit(e, item) {
        window.scroll(0, 0);
        const payload = {
            Id: item.id,
            Name: item.name,
            Number: item.number,
            PricingDiscount: item.pricingDiscount,
            PricingDiscountActive: item.pricingDiscountActive
        };

        if (!this.isFormValid(payload)) return;

        let verb = "put";
        api.upload(verb, 'Area/', payload)
        .then(response => {
            if (response.data.success) {
                this.setState({ 
                    messageFlavor: "success", 
                    message: "The area record was updated." 
                }, () => this.onCancel());
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }
        })
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api.delete(`Area/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleRevive(id) {
        api.post(`Area/Revive/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    onEditTextChange(fieldName, value, areaId) {
        const tempList = this.state.areas.slice();
        const area = _.find(tempList, x => x.id === areaId);
        area[fieldName] = value;
        this.setState({
            areas: tempList
        });
    }

    onEditIsActiveChange(fieldName, areaId) {
        const tempList = this.state.areas.slice();
        const area = _.find(tempList, x => x.id === areaId);
        area[fieldName] = !area[fieldName];
        this.setState({
            areas: tempList
        });
    }


    onEdit(areaId) {
        const indexToEdit = _.findIndex(this.state.areas, {id: areaId});
        let tempList = this.state.areas.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
        this.setState({
            areas: tempList
        });
    }

    onCancelEdit(areaId) {
        const indexToEdit = _.findIndex(this.state.areas, {id: areaId});
        let tempList = this.state.areas.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
            areas: tempList,
            message: null,
            messageFlavor: null
        });
    }

    render() {
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="pull-left page-title">Area Administration</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <ButtonGroup className="float-right btn-group-sm btn-sm">
                                    <Button className="btn-group-sm btn-sm success" onClick={() => this.onAdd()}>
                                        <FontAwesomeIcon icon="plus-circle" /> Add Area
                                    </Button>
                                </ButtonGroup>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterText filterName="Name" displayName="Area Name"
                                                value={this.currentFilterValue('Name')} onChangeCallback={this.filterChange} />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText filterName="Number" displayName="Area Number"
                                                value={this.currentFilterValue('Number')} onChangeCallback={this.filterChange} />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean filterName="ActiveOnly" displayName="Active Only" yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')} onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.showAdd} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Area</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Name {helpers.requiredStar()}</Label>
                                            <Input
                                                type="input"
                                                name="name"
                                                id="name"
                                                onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                                                value={this.state.editItem.name || ""}
                                                maxLength="50"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Number {helpers.requiredStar()}</Label>
                                            <Input
                                                type="input"
                                                name="number"
                                                id="number"
                                                onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                                                value={this.state.editItem.number || ""}
                                                maxLength="20"
                                            />
                                        </Col>
                                        <Col>
                                        <Row><Col><Label>{`Special Pricing (%)`}</Label></Col></Row>
                                            <Row>
                                                <Col>
                                                <input
                                                    style={{height: "37px"}}
                                                    type="number"
                                                    name="pricingDiscount"
                                                    id="pricingDiscount"
                                                    onChange={(event) => this.onTextChange(
                                                        event.target.name, 
                                                        parseFloat(event.target.value),
                                                        )}
                                                    value={this.state.editItem.pricingDiscount || ""}
                                                    maxLength="10"
                                                />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row><Col><Label>Apply Discount</Label></Col></Row>
                                            <Row><Col> 
                                            <Switch
                                                className=""
                                                // "ml-2 p-0 m-0"
                                                onChange={() => this.onIsActive()}
                                                checked={this.state.editItem.pricingDiscountActive}
                                            /></Col></Row>
                                        </Col>
                                    </Row>
                                    <Row className="row-spacing">
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    className="primary"
                                                    size="sm"
                                                    onClick={this.onSubmit}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    className="secondary"
                                                    size="sm"
                                                    onClick={this.onCancel}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                            <Col className="col-12">
                                <Table striped hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader displayName="Name" field="Name" sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'Name'} callBack={this.doSort} />
                                            </th>
                                            <th>
                                                <SortHeader displayName="Number" field="Number" sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'Number'} callBack={this.doSort} />
                                            </th>
                                            <th>
                                            {`Special Pricing (%)`}
                                            </th>
                                            <th>
                                                Apply Discount
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(this.state.areas, area =>
                                            <Fragment key={area.id}>
                                                {area.isEditing
                                                    ? <tr>
                                                        <td>
                                                            <Input
                                                                type="input"
                                                                name="name"
                                                                onChange={(event) => this.onEditTextChange(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    area.id
                                                                )}
                                                                value={area.name}
                                                                maxLength="50"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="input"
                                                                name="number"
                                                                onChange={(event) => this.onEditTextChange(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    area.id
                                                                )}
                                                                value={area.number}
                                                                maxLength="20"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                style={{height: "37px"}}
                                                                name="pricingDiscount"
                                                                onChange={(event) => this.onEditTextChange(
                                                                    event.target.name,
                                                                    parseFloat(event.target.value),
                                                                    area.id
                                                                )}
                                                                value={!area.pricingDiscount ? "" : area.pricingDiscount}
                                                                maxLength="10"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Switch
                                                                className=""
                                                                name="pricingDiscountActive"
                                                                // "ml-2 p-0 m-0"
                                                                onChange={() => this.onEditIsActiveChange(
                                                                    "pricingDiscountActive",
                                                                    area.id
                                                                )}
                                                                checked={area.pricingDiscountActive}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                <Button
                                                                    className="primary btn"
                                                                    onClick={(e) => this.onUpdateSubmit(e, area)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary btn"
                                                                    onClick={() => this.onCancelEdit(area.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    : <tr>
                                                        <td>{area.name}</td>
                                                        <td>{area.number}</td>
                                                        <td>{area.pricingDiscount ?
                                                            `${area.pricingDiscount}%`
                                                            : ""
                                                            }</td>
                                                        <td>{area.pricingDiscountActive ?
                                                            "Included"
                                                            : "Not-Included"
                                                            }</td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                {!area.deactivatedAt ? (
                                                                    <React.Fragment>
                                                                        <Button
                                                                            className="primary btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.onEdit.bind(this, area.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="edit" />
                                                                        </Button>
                                                                        <Button
                                                                            className="danger btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.handleDelete.bind(this, area.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash" />
                                                                        </Button>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Button
                                                                        className="info"
                                                                        size="sm"
                                                                        onClick={this.handleRevive.bind(this, area.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="recycle" /> Revive
                                                                    </Button>
                                                                )}
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

export default AreaAdmin;
