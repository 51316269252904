import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
    Label, Form, Button, ButtonGroup, Alert, Table, Input, Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody,
    Container, Collapse
} from 'reactstrap';
import filter_helpers from '../utils/filter_helpers';
import api from '../utils/api';
import helpers from '../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import {
    FilterSet,
    FilterBoolean,
    FilterText,
    SortHeader
} from '../components';

const emptyYarnSupplier = {
    id: null,
    name: "",
    includeInReport: false
}

class YarnSupplierBrand extends Component {
    constructor(props) {
      super(props);
      this.state = {
        yarnSuppliers: [],
        sortField: 'Name',
        sortDir: 'asc',
        filters: [{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Yes" }],
        message: null,
        messageFlavor: null,
        editYarnBrandForSupplier: null,
        yarnSupplier: emptyYarnSupplier,
        addNewYarnSupplier: false
      };
      this.onAdd = this.onAdd.bind(this);
      this.onCancel = this.onCancel.bind(this);
      this.refreshList = this.refreshList.bind(this);
      this.filterChange = this.filterChange.bind(this);
      this.clearFilters = this.clearFilters.bind(this);
      this.currentFilterValue = this.currentFilterValue.bind(this);
      this.doSort = this.doSort.bind(this);
      // this.setPage = this.setPage.bind(this);
      this.onSupplierNameChange = this.onSupplierNameChange.bind(this);
      this.onIncludeInReportChange = this.onIncludeInReportChange.bind(this);
      this.isFormValid = this.isFormValid.bind(this);
      this.onSubmitAdd = this.onSubmitAdd.bind(this);
    }

    componentDidMount() {
      this.refreshList();
    }

    refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir,
        };

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('YarnSupplier/GetList', payload)
            .then((response) => {
                this.setState({
                    yarnSuppliers: response.data,
                    yarnSupplier: null,
                    editYarnBrandForSupplier: null
                });
            }).catch(error => error);
    }

    onAdd() {
        this.setState({
            yarnSupplier: emptyYarnSupplier,
            addNewYarnSupplier: true
        });
    }

    onAddOrEditBrands(item) {
        this.setState({ editYarnBrandForSupplier: item });
    }

    onCancel() {
        this.setState({
            addNewYarnSupplier: false,
            yarnSupplier: emptyYarnSupplier,
            editYarnBrandForSupplier: null,
            message: null, 
            messageFlavor: null
        });
    }

    handleDelete(id) {
        api.delete(`YarnSupplier/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleRevive(id) {
        api.post(`YarnSupplier/Undelete/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    filterChange(changed_filter) {
        const filters = filter_helpers.get_filters(this.state.filters, changed_filter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    // setPage(page) {
    //     const newPagedList = Object.assign({}, this.state.pagedList);
    //     newPagedList.pageNumber = page;
    //     this.setState({ pagedList: newPagedList }, () => this.refreshList());
    // }

    isFormValid(item) {
        let warnings = [];
        if (!item.Name) {
            warnings.push("Yarn Supplier Name Required.");
        }
        if (item.IncludeInReport === undefined) {
            warnings.push("Include in Report Required.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onSubmitAdd() {
        window.scroll(0,0);
        // e.preventDefault();
        const payload = {
            Name: this.state.yarnSupplier.name,
            IncludeInReport: this.state.yarnSupplier.includeInReport
        };
        if (!this.isFormValid(payload)) return;
        api.upload('post', 'YarnSupplier', payload)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        addNewYarnSupplier: false,
                        yarnSupplier: emptyYarnSupplier
                    }, () => this.refreshList());
                }
                else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            }).catch(helpers.catchHandler);
    }

    onSubmitEdit(e, yarnSupplier) {
        window.scroll(0,0);
        e.preventDefault();
        const payload = {
            Name: yarnSupplier.name,
            IncludeInReport: yarnSupplier.includeInReport,
            Id: yarnSupplier.id
        };
        if (!this.isFormValid(payload)) return;
        api.upload('put',`YarnSupplier/${yarnSupplier.id}`, payload)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        addNewYarnSupplier: false,
                        yarnSupplier: emptyYarnSupplier
                    }, () => this.refreshList());
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            }).catch(helpers.catchHandler);
    }

    onSupplierNameChange(e) {
        this.setState({ yarnSupplier: { ...this.state.yarnSupplier, name: e.target.value } });
    }

    onIncludeInReportChange(newInclude) {
        this.setState({ yarnSupplier: { ...this.state.yarnSupplier, includeInReport: newInclude.value } });
    }

    onEdit(yarnSupplierId) {
        const indexToEdit = _.findIndex(this.state.yarnSuppliers, {id: yarnSupplierId});
        let tempList = this.state.yarnSuppliers.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
        this.setState({
            yarnSuppliers: tempList
        });
    }

    onCancelEdit(yarnSupplierId) {
        const indexToEdit = _.findIndex(this.state.yarnSuppliers, {id: yarnSupplierId});
        let tempList = this.state.yarnSuppliers.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
            yarnSuppliers: tempList,
            message: null,
            messageFlavor: null
        });
    }

    updateYarnSupplierState(fieldName, value, yarnSupplierId) {
        const tempList = this.state.yarnSuppliers.slice();
        const yarnSupplier = _.find(tempList, x => x.id === yarnSupplierId);
        yarnSupplier[fieldName] = value;
        this.setState({
            yarnSuppliers: tempList
        });
    }

    editIncludeInReportChange(option, yarnSupplierId) {
        const tempList = this.state.yarnSuppliers.slice();
        const yarnSupplier = _.find(tempList, x => x.id === yarnSupplierId);
        yarnSupplier.includeInReport = option.value;
        this.setState({
            yarnSuppliers: tempList
        });
    }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="pull-left page-title">Fiber Brand Suppliers &amp; Descriptions</h3>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col sm="12">
                                <ButtonGroup className="float-right">
                                    <Button
                                        className="success"
                                        size="sm"
                                        onClick={() => this.onAdd()}
                                    >
                                        <FontAwesomeIcon icon="plus" /> Add Supplier
                                    </Button>
                                </ButtonGroup>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterText filterName="name" displayName="Yarn Supplier"
                                                        value={this.currentFilterValue('name')} onChangeCallback={this.filterChange} />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean filterName="ActiveOnly" displayName="Active Only" yesOnly={true}
                                                           value={this.currentFilterValue('ActiveOnly')} onChangeCallback={this.filterChange}
                                                           clearValue={this.state.clearValue} />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.addNewYarnSupplier} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Fiber Brand Supplier</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Name {helpers.requiredStar()}</Label>
                                            <Input
                                                type="text"
                                               name="name"
                                                value={this.state.yarnSupplier ? this.state.yarnSupplier.name : ""}
                                               onChange={this.onSupplierNameChange}
                                               autoFocus={true}
                                               placeholder="Enter Yarn Supplier Name"
                                               maxLength="50"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Include in Report? {helpers.requiredStar()}</Label>
                                            <Select
                                                closeMenuOnSelect
                                                value={helpers.booleanListEntry(this.state.yarnSupplier ? this.state.yarnSupplier.includeInReport : null)}
                                                isMulti={false}
                                                options={helpers.yesNoOptions()}
                                                onChange={this.onIncludeInReportChange}
                                                defaultValues={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-spacing">
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    type="submit"
                                                    size="sm"
                                                    className="primary"
                                                    onClick={this.onSubmitAdd}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    className="secondary"
                                                    size="sm"
                                                    onClick={this.onCancel}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader
                                                    displayName="Name"
                                                    field="Name"
                                                    sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'Name'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th className="text-center">
                                                Include in Report?
                                            </th>
                                            <th>Brands</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.yarnSuppliers.map(item => (
                                            <Fragment key={item.id}>
                                                {item.isEditing
                                                    ? <tr>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={item.name}
                                                                onChange={(event) => this.updateYarnSupplierState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    item.id
                                                                )}
                                                                autoFocus={true}
                                                                placeholder="Enter Yarn Supplier Name"
                                                                maxLength="50"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                closeMenuOnSelect
                                                                value={helpers.booleanListEntry(item.includeInReport)}
                                                                isMulti={false}
                                                                options={helpers.yesNoOptions()}
                                                                onChange={(option) => this.editIncludeInReportChange(option, item.id)}
                                                                defaultValues={true}
                                                            />
                                                        </td>
                                                          <td>
                                                              <Button
                                                                  size="sm"
                                                                  className="primary"
                                                                  onClick={this.onAddOrEditBrands.bind(this, item)}
                                                              >
                                                                  <FontAwesomeIcon icon="plus" /> Brands
                                                              </Button>
                                                          </td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                <Button
                                                                    className="primary btn"
                                                                    onClick={(e) => this.onSubmitEdit(e, item)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary btn"
                                                                    onClick={() => this.onCancelEdit(item.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    : <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td className="text-center">
                                                            {item.includeInReport ?
                                                                <span className="text-success">
                                                                    <FontAwesomeIcon icon="check-circle" />
                                                                </span>
                                                                : null}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                className="primary"
                                                                onClick={this.onAddOrEditBrands.bind(this, item)}
                                                            >
                                                                <FontAwesomeIcon icon="plus" /> Brands
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <ButtonGroup className="float-right">
                                                                {item.deactivatedAt === null ? (
                                                                    <React.Fragment>
                                                                        <Button
                                                                            size="sm"
                                                                            className="primary btn-outline-secondary"
                                                                            onClick={() => this.onEdit(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="edit" />
                                                                        </Button>
                                                                        <Button
                                                                            size="sm"
                                                                            className="danger btn-outline-secondary"
                                                                            onClick={this.handleDelete.bind(this, item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash" />
                                                                        </Button>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Button
                                                                        size="sm"
                                                                        className="info"
                                                                        onClick={this.handleRevive.bind(this, item.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="recycle" /> Revive
                                                                    </Button>
                                                                )}
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {this.state.editYarnBrandForSupplier
                            ? (<AddOrEditBrands
                                relatedSupplier={this.state.editYarnBrandForSupplier}
                                onSuccess={this.refreshList}
                                onCancel={this.onCancel}
                            />)
                            : null
                        }
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

class AddOrEditBrands extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            relatedSupplier: props.relatedSupplier,
            newBrandName: '',
            list: [],
            editBrandName: '',
            id: 0
        };
        this.refreshBrandList = this.refreshBrandList.bind(this);
        this.onNewBrandNameChange = this.onNewBrandNameChange.bind(this);
        this.saveNewBrand = this.saveNewBrand.bind(this);
    }

    componentDidMount() {
        this.refreshBrandList();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.relatedSupplier !== this.props.relatedSupplier) {
            this.setState({ relatedSupplier: this.props.relatedSupplier },
                () => this.refreshBrandList());
        }
    }

    refreshBrandList() {
        api.fetch(`YarnSupplier/YarnBrands/${this.state.relatedSupplier.id}`)
            .then((response) => {
                const tempList = _.map(response.data.list, function (item) {
                    item.editing = false;
                    return item;
                });
                this.setState({ list: tempList });
            }).catch(helpers.catchHandler);
    }

    isFormValid(brandName) {
        if (brandName.length > 2) {
            return true;
        }
        else {
            this.setState({ messageFlavor: "danger", errorMessage: "Please Provide a Valid Brand Name" });
            return false;
        }
    }

    saveNewBrand(e) {
        e.preventDefault();
        if (this.isFormValid(this.state.newBrandName)) {
            api.post('YarnSupplier/AddBrand', {
                Id: 0, // 0 means new one
                Name: this.state.newBrandName,
                YarnSupplierId: this.state.relatedSupplier.id
            }).then(response => {
                if (response.data.success) {
                    this.refreshBrandList();
                    this.setState({ newBrandName: '', errorMessage: null });
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            }).catch(helpers.catchHandler);
        }
    }

    saveEditedBrand(id, name) {
        this.setState({ id: id, editBrandName: name }, () => {
            if (this.isFormValid(this.state.editBrandName)) {
                const payload = {
                    Id: this.state.id, // 0 means new one
                    Name: this.state.editBrandName,
                    YarnSupplierId: this.state.relatedSupplier.id
                };
                api.put(`YarnSupplier/UpdateBrand/${id}`, payload)
                    .then(response => {
                        if (response.data.success) {
                            //this.refreshBrandList();
                            let tempList = this.state.list.slice();
                            const indexToEdit = _.findIndex(tempList, { id: id });
                            tempList[indexToEdit].name = this.state.editBrandName;
                            tempList[indexToEdit].editing = false;
                            this.setState({ list: tempList });
                            this.setState({ editBrandName: '', errorMessage: null });
                        }
                        else {
                            this.setState({ messageFlavor: "danger", message: response.data.message });
                        }
                    })
                    .catch(helpers.catchHandler);
            }
        });
    }

    saveTempBrandName(id) {
        let tempList = this.state.list.slice();
        const indexToEdit = _.findIndex(this.state.list, { id: id });
        tempList[indexToEdit].oldBrandName = tempList[indexToEdit].name;
        tempList[indexToEdit].name = this.state.editBrandName;
        this.setState({ list: tempList });
    }

    showEdit(id, name) {
        this.setState({ errorMessage: null, id: id, editBrandName: name });
        let tempList = this.state.list.slice();
        const indexToEdit = _.findIndex(this.state.list, { id: id });
        tempList[indexToEdit].editing = true;
        this.setState({ list: tempList });
    }

    onNewBrandNameChange(e) {
        this.setState({ newBrandName: e.target.value });
    }

    onEditBrandNameChange(id, e) {
        this.setState({ editBrandName: e.target.value, id: id });
    }

    cancel(id) {
        this.setState({ errorMessage: null });
        let tempList = this.state.list.slice();
        const indexToEdit = _.findIndex(tempList, { id: id });
        tempList[indexToEdit].editing = false;
        tempList[indexToEdit].name = tempList[indexToEdit].oldBrandName;
        this.setState({ list: tempList });
    }

    handleDelete(id) {
        this.setState({ errorMessage: null });
        api.delete(`YarnSupplier/DeleteBrand/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshBrandList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleRevive(id) {
        this.setState({ errorMessage: null });
        api.post(`YarnSupplier/UndeleteBrand/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshBrandList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true} autoFocus={false}>
                    <ModalHeader>Fiber Brands for {this.state.relatedSupplier.name}</ModalHeader>
                    <ModalBody className="ml-4 mr-4">
                        {this.state.errorMessage &&
                            <Alert className={this.state.messageFlavor}>{this.state.errorMessage}</Alert>
                        }
                        <Form className="m-0 p-0">
                            <Row>
                                <Col sm="10">
                                    Add Brand
                                    <Input type="text" name="Brand" id="Brand" autoFocus={true} maxLength="50"
                                        onChange={this.onNewBrandNameChange} value={this.state.newBrandName}
                                    />
                                </Col>
                                <Col sm="2" className="pt-4">
                                    <Button type="submit" className="success" onClick={this.saveNewBrand} size="sm" title="Add">
                                        <FontAwesomeIcon icon="plus" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col>
                                <Table striped hover responsive size="sm" className="m-0">
                                    <thead>
                                        <tr>
                                            <th className="pb-3" style={{ borderBottom: 0, borderTop: 0 }}></th>
                                            <th className="pb-3" style={{ borderBottom: 0, borderTop: 0 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.list.map(i =>
                                            <tr key={i.id}>
                                                {i.editing
                                                    ? (<React.Fragment>
                                                        <td>
                                                            <Input type="text" name="editBrand" id="editBrand" autoFocus={true} onClick={this.showEdit.bind(this, i.id, i.name)} maxLength="50"
                                                                onChange={this.onEditBrandNameChange.bind(this, i.id)} defaultValue={i.name} onBlur={this.saveTempBrandName.bind(this, i.id)}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                <Button
                                                                    className="primary"
                                                                    onClick={this.saveEditedBrand.bind(this, i.id, i.name)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary"
                                                                    size="sm"
                                                                    onClick={this.cancel.bind(this, i.id, i.name)}
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </React.Fragment>)
                                                    : (<React.Fragment>
                                                        <td>{i.name}</td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                <Button
                                                                    className="primary btn-outline-secondary"
                                                                    onClick={this.showEdit.bind(this, i.id, i.name)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="edit" />
                                                                </Button>
                                                                {i.deactivatedAt === null ? (
                                                                    <Button
                                                                        className="danger btn-outline-secondary"
                                                                        size="sm"
                                                                        onClick={this.handleDelete.bind(this, i.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="trash" />
                                                                    </Button>
                                                                ) : (
                                                                        <Button
                                                                            className="info"
                                                                            size="sm"
                                                                            onClick={this.handleRevive.bind(this, i.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="recycle" /> Revive
                                                                        </Button>
                                                                    )}
                                                            </ButtonGroup>
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup className="text-right">
                            <Button className="btn secondary" onClick={this.props.onCancel}>
                                Close
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default YarnSupplierBrand;
