import React, { Fragment } from "react";
import _ from "lodash";

const paramArray = [
  `agentId`,
  `areaId`,
  "agentName",
  "DCCustomerOnly",
  "excludeNewCustomerAccounts",
  "excludeOldCustomerAccounts",
  "onlyNewInLastYear",
  "twelveMonths",
  "twentyFourMonths",
  "accountTypeName",
  "accountTypeId",
  "filterByAccountTypeId",
  "filterByAgentId",
  "filterByAreaId",
  "onlyNewInThisCalendarYear",
  "newAcctMonth",
  "anyActiveOrdersThisYear",
  "lessThan2000ThisYear",
  "anyActiveOrdersLastYear",
  "lessThan2000LastYear",
  "noOrdersSinceLastYear",
  "lessThan2000SinceLastYear",
  "activeOrdersThisYear",
  "activeOrdersSinceLastYear",
  "positiveNetOrdersSinceLastYear",
  "sortByZip",
];
const upperCaseString = (x) => _.toUpper(_.snakeCase(x));
const start = (x) =>
  _.replace(
    _.replace(
      _.replace(
        _.replace(_.replace(_.startCase(x), " ", ""), " ", ""),
        " ",
        ""
      ),
      " ",
      ""
    ),
    " ",
    ""
  );
const startCaseVar = _.chain(paramArray)
  .map((x) => _.startCase(x))
  .value();
const startCase = _.chain(startCaseVar)
  .map((x) => start(x))
  .value();
const startCaseWithAt = _.chain(startCase)
  .map((x) => `@${x}`)
  .value();
const titleBlock = _.chain(startCaseVar)
  .map((x) => upperCaseString(x))
  .value();
// public const string DC_CUSTOMER_ONLY = "DCCustomerOnly";
const fullParams = _.chain(startCaseVar)
  .map((x) => `public const string ${upperCaseString(x)} = "${start(x)}";`)
  .value();
// isDCCustomer = parseParameter(dto.Parameters, AppConstants.REPORT_PARAMETER_NAMES.DC_CUSTOMER_ONLY);
const reportParams = _.chain(paramArray)
  .map(
    (x) =>
      `${x} = parseParameter(dto.Parameters, AppConstants.REPORT_PARAMETER_NAMES.${upperCaseString(
        x
      )});`
  )
  .value();

//   public const string AGENT_ID_LIST = "@AgentIdList";
const sqlParams = _.chain(paramArray)
  .map((x) => `{public const string ${upperCaseString(x)} = "@${start(x)}";`)
  .value();
const sqlized = _.chain(paramArray)
  .map((x) => `{ Parameters.${upperCaseString(x)}, ${x} },`)
  .value();
const stringSet = _.chain(paramArray)
  .map((x) => `string ${x}`)
  .value();
// { ParameterNames.AGENT_ID_LIST, agentId }
const passedSqlParams = _.chain(paramArray)
  .map((x) => `public const string ${upperCaseString(x)} = "@${start(x)}";`)
  .value();
const wholeShebang = [
  paramArray,
  startCaseVar,
  startCase,
  startCaseWithAt,
  titleBlock,
  fullParams,
  reportParams,
  stringSet,
  sqlParams,
  sqlized,
];
const printParams = () => {
  return _.chain(wholeShebang)
    .map((section) => {
      return (
        <div className="d-block mb-5">
          {section.map((param) => (
            <Fragment>
              <div className="d-block">{param}</div>
            </Fragment>
          ))}
        </div>
      );
    })
    .value();
};

const report_helpers = {
  printParams,
};

export default report_helpers;
