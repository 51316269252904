import React, { Component } from "react";
import {
  Container,
  Button,
  Col,
  Row,
  Table,
  ButtonGroup,
  Card,
  Alert,
  CardBody,
} from "reactstrap";
import {
  FilterSet,
  SortHeader,
  FilterBoolean,
  FilterText,
  FilterSelect,
  Pager,
} from "../components";
import api from "../utils/api";
import helpers from "../utils/helpers";
import filterHelpers from "../utils/filter_helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";

const addAnyOption = (list) => {
  let newList = list.slice();
  newList.unshift({ label: "(Any)", value: null });
  return newList;
};

const formatAssociatedPhone = (phone) => {
  if (phone.substring(0, 1) != "(" && phone.length > 7) {
    return (
      "(" +
      phone.substring(0, 3) +
      ")" +
      phone.substring(3, 6) +
      "-" +
      phone.substring(6)
    );
  }
  return phone;
};

const baseUrl = "Customer/";

export default class CustomerAdmin extends Component {
  constructor(props) {
    super(props);
    let lastAccountFilters = null;
    try {
      lastAccountFilters = localStorage.getItem("lastAccountFilters");
    } catch {}
    const defaultFilters = lastAccountFilters
      ? JSON.parse(lastAccountFilters)
      : [
          {
            filterName: "ActiveOnly",
            value: true,
            filteredDisplay: "Active Only: Yes",
          },
        ];
    this.state = {
      pagedList: {
        list: [],
        pageSize: 100,
        pageNumber: 1,
      },
      customerStatusList: [],
      list: [],
      currentList: [],
      customerList: [],
      currentPageList: [],
      currentPage: 1,
      sort_dir: "asc",
      sort_field: "Name",
      filters: defaultFilters,
      editView: false,
      customer: {
        id: null,
        Name: "",
        FamiliarName: "",
        Agent: null,
        Area: null,
        InvoiceTerms: null,
        FreightTerms: null,
        CustomerCreditType: "",
        AccountType: null,
        CustomerStatus: null,
        ContactPreferenceType: null,
        Number: "",
        CreditLine: "",
        TaxNo: "",
        FedId: "",
        WebsiteUrl: "",
        IsDCCustomer: null,
        AutoSendSamples: null,
        Comment: "",
        contactList: [],
        WebEmail: "",
        WebFirstName: "",
        WebLastName: "",
      },
      customerIdCaptured: false,
      messageFlavor: "danger",
      message: null,
      areaList: [],
      agentList: [],
      usedByCustomerSearchModal: props.usedByCustomerSearchModal,
    };
    this.onCancel = this.onCancel.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.setPage = this.setPage.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.currentFilterValue = this.currentFilterValue.bind(this);
    this.onViewCustomer = this.onViewCustomer.bind(this);
  }

  componentDidMount() {
    const getCustomerStatus = api
      .fetch("Reference/GetCustomerStatusList")
      .then((result) => {
        return { customerStatusList: result.data };
      })
      .catch(helpers.catchHandler);
    const getAgents = api
      .fetch("Reference/GetAgentList")
      .then((result) => {
        return { agentList: result.data };
      })
      .catch(helpers.catchHandler);
    const getAreas = api
      .fetch("Reference/GetAreaList")
      .then((result) => {
        return { areaList: result.data };
      })
      .catch(helpers.catchHandler);

    Promise.all([getAgents, getAreas, getCustomerStatus])
      .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach((r) => Object.assign(newStatus, r));
        this.setState(newStatus, () => {
          this.refreshList();
        });
      })
      .catch(helpers.catchHandler);
  }

  refreshList(sort_field, sort_direction, filters) {
    let filterList = filters || this.state.filters;
    const payload = {
      Page: this.state.pagedList.pageNumber,
      PageSize: this.state.pagedList.pageSize,
      SortField: sort_field || this.state.sort_field,
      SortDir: sort_direction || this.state.sort_dir,
    };

    _.each(filterList, (filter) => (payload[filter.filterName] = filter.value));

    api
      .post(baseUrl + "GetPaginatedList", payload)
      .then((response) => {
        this.setState({
          pagedList: response.data,
        });
      })
      .catch((error) => error);
  }

  doSort(field, direction) {
    this.setState({ sort_dir: direction, sort_field: field });
    this.refreshList(field, direction);
  }

  filterChange(changedFilter) {
    const filters = filterHelpers.get_filters(
      this.state.filters,
      changedFilter
    );
    try {
      localStorage.setItem("lastAccountFilters", JSON.stringify(filters));
    } catch {}
    this.setState({ filters: filters, clearValue: false });
    this.refreshList(null, null, filters);
  }

  clearFilters() {
    const filters = [];
    try {
      localStorage.setItem("lastAccountFilters", JSON.stringify(filters));
    } catch {}
    this.setState({ filters: filters, clearValue: true });
    this.refreshList(null, null, filters);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find((f) => f.filterName === name);
    if (filterElement) {
      if (
        filterElement.value &&
        filterElement.filterName === "AssociatedPhone"
      ) {
        filterElement.value = formatAssociatedPhone(filterElement.value);
        return filterElement.value;
      }
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  setPage(page) {
    const newPagedList = Object.assign({}, this.state.pagedList);
    newPagedList.pageNumber = page;
    this.setState({ pagedList: newPagedList }, () => this.refreshList());
  }

  onCancel() {
    this.refreshList();
  }

  onClose() {
    this.setState({ editView: false });
  }

  onDelete(id) {
    api
      .delete(baseUrl + `DeleteCustomer/${id}`)
      .then((response) => this.refreshList())
      .catch(helpers.catchHandler);
  }

  onRevive(id) {
    api
      .delete(baseUrl + `ReviveCustomer/${id}`)
      .then((response) => this.refreshList())
      .catch(helpers.catchHandler);
  }

  onViewCustomer() {
    if (this.state.customerIdCaptured) {
      return (
        <Redirect
          to={{
            pathname: "/customerdetailsview",
            state: { customer: this.props.location.state.customer },
            push: true,
          }}
        />
      );
    }
  }

  render() {
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {this.state.message && (
              <Row className="mb-2">
                <Col>
                  <Alert className={this.state.messageFlavor}>
                    {this.state.message}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="row-spacing">
              <Col>
                <h3 className="pull-left page-title">
                  {this.state.usedByCustomerSearchModal
                    ? "Account Search"
                    : "Accounts Administration"}
                </h3>
              </Col>
            </Row>
            <Row className="row-spacing">
              <Col sm="12">
                {!this.state.usedByCustomerSearchModal && (
                  <ButtonGroup className="float-right">
                    <Button
                      className="success"
                      size="sm"
                      tag={Link}
                      to={{
                        pathname: "/customeradmincontainer",
                        state: { customerId: null },
                        push: true,
                      }}
                    >
                      <FontAwesomeIcon icon="plus" /> Add Account
                    </Button>
                  </ButtonGroup>
                )}
                <FilterSet
                  filters={this.state.filters}
                  clearFilters={this.clearFilters}
                  open={this.state.usedByCustomerSearchModal ? true : undefined}
                >
                  <Row>
                    <Col xs="4">
                      <FilterText
                        filterName="Number"
                        displayName="Account Number"
                        value={this.currentFilterValue("Number")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="CustomerName"
                        displayName="Account Name"
                        value={this.currentFilterValue("CustomerName")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="ContactName"
                        displayName="Contact Name"
                        value={this.currentFilterValue("ContactName")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterSelect
                        filterName="AreaId"
                        displayName="Area"
                        options={addAnyOption(this.state.areaList)}
                        value={this.currentFilterValue("AreaId")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterSelect
                        filterName="AgentId"
                        displayName="Agent"
                        options={addAnyOption(this.state.agentList)}
                        value={this.currentFilterValue("AgentId")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterBoolean
                        filterName="ActiveOnly"
                        displayName="Active Only"
                        yesOnly={true}
                        value={this.currentFilterValue("ActiveOnly")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="City"
                        displayName="City"
                        value={this.currentFilterValue("City")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="State"
                        displayName="State"
                        value={this.currentFilterValue("State")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="Zip"
                        displayName="Zip"
                        value={this.currentFilterValue("Zip")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="AssociatedAddress"
                        displayName="Associated Address"
                        value={this.currentFilterValue("AssociatedAddress")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="AssociatedPhone"
                        displayName="Associated Phone"
                        value={this.currentFilterValue("AssociatedPhone")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterText
                        filterName="AssociatedEmail"
                        displayName="Associated Email"
                        value={this.currentFilterValue("AssociatedEmail")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterBoolean
                        filterName="HasCreditAvailable"
                        displayName="Has Credit Available"
                        yesOnly={true}
                        value={this.currentFilterValue("HasCreditAvailable")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterBoolean
                        filterName="hasPendingReplacementOrder"
                        displayName="Has Pending Replacement Orders"
                        yesOnly={true}
                        value={this.currentFilterValue(
                          "HasPendingReplacementOrder"
                        )}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterBoolean
                        filterName="hasPendingCreditOrder"
                        displayName="Has Pending Credit Order"
                        yesOnly={true}
                        value={this.currentFilterValue("HasPendingCreditOrder")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                    <Col xs="4">
                      <FilterSelect
                        filterName="ActiveCustomer"
                        displayName="Customer Status"
                        options={this.state.customerStatusList}
                        value={this.currentFilterValue("ActiveCustomer")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                  </Row>
                </FilterSet>
              </Col>
            </Row>
            <Row className="row-spacing">
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th width="25%">
                        <SortHeader
                          displayName="Name"
                          field="Name"
                          sortDir={this.state.sort_dir}
                          sorted={this.state.sort_field === "Name"}
                          callBack={this.doSort}
                        />
                      </th>
                      <th width="7%">
                        <SortHeader
                          displayName="Account #"
                          field="Number"
                          sortDir={this.state.sort_dir}
                          sorted={this.state.sort_field === "Number"}
                          callBack={this.doSort}
                        />
                      </th>
                      {!this.state.usedByCustomerSearchModal && (
                        <React.Fragment>
                          <th width="25%">
                            <SortHeader
                              displayName="City, State"
                              field="CityState"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === "CityState"}
                              callBack={this.doSort}
                            />
                          </th>
                          <th width="22%">
                            <SortHeader
                              displayName="Area"
                              field="Area"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === "Area"}
                              callBack={this.doSort}
                            />
                          </th>
                          <th width="22%">
                            <SortHeader
                              displayName="Agent"
                              field="Agent"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === "Agent"}
                              callBack={this.doSort}
                            />
                          </th>
                          <th width="10%">Web Registration</th>
                          {/* <th width="50%" >
                                                    <SortHeader
                                                        displayName="Comments"
                                                        field="Comments"
                                                        sortDir={this.state.sort_dir}
                                                        sorted={this.state.sort_field === 'Comments'}
                                                        callBack={this.doSort}
                                                    />
                                                </th> */}
                          <th />
                        </React.Fragment>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pagedList.list.map((c) => (
                      <tr key={c.id} className="data-row">
                        <td>
                          {this.state.usedByCustomerSearchModal ? (
                            <Button
                              className="m-0 p-0"
                              color="link"
                              onClick={() =>
                                this.props.onCustomerSelected(c.id, c.name)
                              }
                            >
                              {c.name}
                            </Button>
                          ) : (
                            c.name
                          )}
                        </td>
                        <td>{c.number}</td>
                        {!this.state.usedByCustomerSearchModal && (
                          <React.Fragment>
                            <td>{c.primaryBillToAddressCityState}</td>
                            <td>{c.areaName}</td>
                            <td>{c.agentName}</td>
                            <td>
                              {c.isWebRegistration ? (
                                <FontAwesomeIcon
                                  icon={"check-circle"}
                                  className={"text-success"}
                                />
                              ) : null}
                            </td>
                            <td>
                              <ButtonGroup className="float-right">
                                {c.deactivatedAt === null ? (
                                  <React.Fragment>
                                    <Button
                                      className="primary btn-outline-secondary"
                                      size="sm"
                                      tag={Link}
                                      to={{
                                        pathname: "/customeradmincontainer",
                                        state: { customerId: c.id },
                                        push: true,
                                      }}
                                    >
                                      <FontAwesomeIcon icon="edit" />
                                    </Button>
                                    <Button
                                      className="info btn-outline-secondary"
                                      size="sm"
                                      tag={Link}
                                      to={{
                                        pathname: "/customerdetailsview",
                                        state: { customerId: c.id },
                                        push: true,
                                      }}
                                    >
                                      <FontAwesomeIcon icon="eye" />
                                    </Button>
                                    <Button
                                      className="danger btn-outline-secondary"
                                      size="sm"
                                      onClick={this.onDelete.bind(this, c.id)}
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <Button
                                    className="info"
                                    size="sm"
                                    onClick={this.onRevive.bind(this, c.id)}
                                  >
                                    <FontAwesomeIcon icon="recycle" /> Revive
                                  </Button>
                                )}
                              </ButtonGroup>
                            </td>
                          </React.Fragment>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pager {...this.state.pagedList} callBack={this.setPage} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
