import React, { Component } from 'react';
import {
    Container,
    Card,
    CardBody,
    Table,
    Button,
    ButtonGroup,
    Label,
    Alert,
    Row,
    Col,
    Input,
    Collapse
} from 'reactstrap';
import {
    api,
    helpers,
    date_helpers,
    filter_helpers
} from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import _ from "lodash";
import moment from 'moment';
import {
    FilterSet,
    SortHeader,
    FilterBoolean,
    FilterText
} from '../components';

const emptyWidth = {
    name: "",
    numericFeet: "",
    // tradeSizeType: {
    //     value: "",
    //     label: ""
    // }
};

class Width extends Component {
    constructor(props) {
        super(props);

        this.state = {
            widths: [],
            newWidth: emptyWidth,
            message: null,
            messageFlavor: null,
            // tradeSizeList: [],
            addNewWidth: false,
            filters: [{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Active"}],
            sort_field: 'Name',
            sort_dir: 'asc',
        };

        this.addNewWidth = this.addNewWidth.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
    }

    componentDidMount() {
        // api
        //     .fetch("Reference/GetTradeSizeList")
        //     .then((result) => {
        //         this.setState({
        //             tradeSizeList: result.data
        //         });
        //         this.refreshList();
        //     })
        //     .catch(helpers.catchHandler);
        this.refreshList();
    }

    refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            SortField: sortField || this.state.sort_field,
            SortDir: sortDirection || this.state.sort_dir,
        };
        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('Width/GetList', payload)
          .then((response) => {
            this.setState({
                widths: response.data,
            });
          })
          .catch(helpers.catchHandler);
    }

    addNewWidth() {
        this.setState({
            addNewWidth: !this.state.addNewWidth,
            newWidth: emptyWidth,
            message: null,
            messageFlavor: null
        });
    }

    handleNewWidthStateChange(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            newWidth: {
                ...prevState.newWidth,
                [fieldName]: value
            }
        }));
    }

    onBlurNewNumericFeetField(fieldName, value) {
        if (value) {
            this.setState(prevState => ({
                ...prevState,
                newWidth: {
                    ...prevState.newWidth,
                    [fieldName]: _.round(value, 4)
                }
            }));
        }
    }

    // getSelectedTradeSize(tradeSizeId) {
    //     if (!tradeSizeId) {
    //         return null;
    //     }
    //     return _.find(this.state.tradeSizeList, x => x.id === tradeSizeId);
    // }

    // setSelectedTradeSize(option) {
    //     this.setState(prevState => ({
    //         ...prevState,
    //         newWidth: {
    //             ...prevState.newWidth,
    //             tradeSizeType: {
    //                 value: option.value,
    //                 label: option.label
    //             }
    //         }
    //     }));
    // }

    onEdit(widthId) {
        const indexToEdit = _.findIndex(this.state.widths, {id: widthId});
        let tempList = this.state.widths.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
        this.setState({
            fullList: tempList
        });
    }

    onCancelEdit(widthId) {
        const indexToEdit = _.findIndex(this.state.widths, {id: widthId});
        let tempList = this.state.widths.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
            widths: tempList, 
            message: null,
            messageFlavor: null
        });
    }
    

    updateWidthState(fieldName, value, widthId) {
        const widths = this.state.widths.slice();
        const width = _.find(widths, x => x.id === widthId);
        width[fieldName] = value;
        this.setState({
            widths: widths
        });
    }

    onBlurUpdatedNumericFeetField(fieldName, value, widthId) {
        if (value) {
            const widths = this.state.widths.slice();
            const width = _.find(widths, x => x.id === widthId);
            width[fieldName] = _.round(value, 4)
            this.setState({
                widths: widths
            });
        }
    }

    // editSelectedTradeSize(option, widthId) {
    //     const widths = this.state.widths.slice();
    //     const width = _.find(widths, x => x.id === widthId);
    //     width.tradeSizeId = option.value;
    //     width.tradeSizeDsc = option.label;
    //     this.setState({
    //         widths: widths
    //     });
    // }

    isFormValid(item) {
        const warnings = [];
        if (!item.Name || item.Name < 2) {
            warnings.push("Description is required.");
        }
        if (!item.NumericFeet)  {
            warnings.push("Numeric Feet is required.");
        }
        if (item.NumericFeet < 0) {
            warnings.push("Numeric feet must be greater than zero.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    saveNewWidth() {
        const width = this.state.newWidth;
        const data = {
            Id: 0,
            Name: width.name,
            NumericFeet: width.numericFeet,
            // TradeSizeId: width.tradeSizeType.value,
            CreatedByUserId: this.props.currentUser.id,
            CreatedAt: moment.utc().format()
        };
        if (!this.isFormValid(data)) return;

        api.post("Width/SaveNewWidth", data).then(response => {
            if (response.data.success) { 
                this.addNewWidth()
                this.refreshList();
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    updateWidth(width) {
        const data = {
            Id: width.id,
            Name: width.name,
            NumericFeet: width.numericFeet,
            // TradeSizeId: width.tradeSizeId,
            CreatedByUserId: this.props.currentUser.id,
            CreatedAt: date_helpers.formatDateForServer(moment())
        };
        if (!this.isFormValid(data)) return;

        api.post("Width/UpdateWidth", data)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api
            .delete(`Width/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.refreshList()
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    handleUnDelete(id) {
        api
            .post(`Width/Undelete/${id}`)
            .then(response => {
                if (response.data.success) { 
                    this.refreshList()
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }  
            }) 
            .catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sort_dir: direction, sort_field: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
            return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }


    render() {
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message !== null && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>
                                        {this.state.message}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col>
                                <h3 className="pull-left page-title">Maintain Widths</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <Button
                                    className="success float-right"
                                    size="sm"
                                    onClick={() => this.addNewWidth()}
                                >
                                    <FontAwesomeIcon icon="plus" /> Add Width
                                </Button>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="6" md="6">
                                            <FilterText
                                                filterName="Name"
                                                displayName="Description"
                                                value={this.currentFilterValue('Name')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="6" md="3">
                                            <FilterBoolean
                                                filterName="ActiveOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.addNewWidth} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Width</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Description {helpers.requiredStar()}</Label>
                                            <Input
                                                type="text"
                                                value={this.state.newWidth.name}
                                                onChange={(event) => this.handleNewWidthStateChange(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                name="name"
                                                maxLength="20"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Numeric Feet {helpers.requiredStar()}</Label>
                                            <Input
                                                name="numericFeet"  
                                                type="number"
                                                step="0.0001"
                                                value={this.state.newWidth.numericFeet}
                                                onChange={(event) => this.handleNewWidthStateChange(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                onBlur={(event) => this.onBlurNewNumericFeetField(
                                                    event.target.name,
                                                    event.target.value)}
                                                min="0"
                                            />
                                        </Col>
                                        {/*
                                        <Col>
                                            <Label>Trade Size</Label>
                                            <Select
                                                closeMenuOnSelect
                                                value={this.getSelectedTradeSize(
                                                    this.state.newWidth.tradeSizeType.value)}
                                                options={this.state.tradeSizeList}
                                                onChange={(option) => this.setSelectedTradeSize(option)}
                                            />
                                        </Col>
                                    */}
                                    </Row>
                                    <Row className="row-spacing">
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    className="primary"
                                                    size="sm"
                                                    onClick={() => this.saveNewWidth()}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    className="secondary"
                                                    size="sm"
                                                    onClick={() => this.addNewWidth()}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader
                                                    displayName="Description"
                                                    field="Name"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'Name'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Numeric Feet"
                                                    field="NumericFeet"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'NumericFeet'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            {/*
                                            <th>
                                                <SortHeader
                                                    displayName="Trade Size"
                                                    field="TradeSize"
                                                    sortDir={this.state.sort_dir}
                                                    sorted={this.state.sort_field === 'TradeSize'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            */}
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.widths.map(w => (
                                            <tr key={w.id}>
                                                <td>
                                                    {w.isEditing ? (
                                                        <Input
                                                            type="text"
                                                            value={w.name}
                                                            onChange={(event) => this.updateWidthState(
                                                                event.target.name,
                                                                event.target.value,
                                                                w.id
                                                            )}
                                                            name="name"
                                                            maxLength="20"
                                                        />
                                                    ) : w.name}
                                                </td>
                                                <td>
                                                    {w.isEditing ? (
                                                        <Input
                                                            name="numericFeet"
                                                            type="number"
                                                            step="0.0001"
                                                            value={w.numericFeet}
                                                            onChange={(event) => this.updateWidthState(
                                                                event.target.name,
                                                                event.target.value,
                                                                w.id
                                                            )}
                                                            onBlur={(event) => this.onBlurUpdatedNumericFeetField(
                                                                event.target.name,
                                                                event.target.value, 
                                                                w.id
                                                            )}
                                                            min="0"
                                                        />
                                                    ) : w.numericFeet}
                                                </td>
                                                {/*
                                                <td>
                                                    {w.isEditing ? (
                                                        <Select
                                                            closeMenuOnSelect
                                                            value={helpers.resolveValue(null, w.tradeSizeId, w.tradeSizeDsc)}
                                                            options={this.state.tradeSizeList}
                                                            onChange={(option) => this.editSelectedTradeSize(option, w.id)}
                                                        />
                                                    ) : w.tradeSizeDsc}
                                                </td>
                                                */}
                                                <td className="text-right">
                                                    <ButtonGroup>
                                                        {w.isEditing ? (
                                                            <ButtonGroup className="float-right">
                                                                <Button
                                                                    className="primary"
                                                                    onClick={() => this.updateWidth(w)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                        </Button>
                                                                <Button
                                                                    className="secondary"
                                                                    onClick={() => this.onCancelEdit(w.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        ) : (
                                                            <div>
                                                                {w.deactivatedAt === null ? (
                                                                    <React.Fragment>
                                                                        <Button
                                                                            className="primary btn-outline-secondary"
                                                                            onClick={() => this.onEdit(w.id)}
                                                                            size="sm"
                                                                        >
                                                                            <FontAwesomeIcon icon="edit" />
                                                                        </Button>
                                                                        <Button
                                                                            className="danger btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.handleDelete.bind(this, w.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash" />
                                                                        </Button>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Button
                                                                        className="info"
                                                                        size="sm"
                                                                        onClick={this.handleUnDelete.bind(this, w.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="recycle" /> Revive
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

export default Width;
