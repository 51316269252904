import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Table,
    Card,
    Row,
    Col,
    CardBody
} from 'reactstrap';
import {
    api,
    helpers,
    date_helpers
} from '../utils';
import _ from "lodash";


const MemoAndSamplesTable = props => {
    return (
        <React.Fragment>
            <Row className="expand-md mt-2 mb-0 pb-0">
                <Col>
                    <h4>{`${props.isForMemoTable ? "Memo" : "Sample"} Order History`}</h4>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <th>Memo Order #</th>
                                <th>Placed On</th>
                                <th>{props.isForMemoTable ? "Size" : null}</th>
                                <th>Status</th>
                                <th>{props.isForMemoTable ? "Supplier Product" : "Sample"}</th>
                                <th>{props.isForMemoTable ? "DC Product" : null}</th>
                                <th>{props.isForMemoTable ? "Color" : null}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.pastOrders.map(cpmo => (
                                <tr key={cpmo.id}>
                                    <td>
                                        <Link 
                                            to={{
                                                pathname: `/memoorders/`,
                                                state: {
                                                    memoOrderId: cpmo.memoOrderId
                                                }
                                            }}
                                        >
                                            {cpmo.memoOrderId}
                                        </Link>
                                    </td>
                                    <td>{date_helpers.formatDateToShortDate(cpmo.createdAt)}</td>
                                    <td>{props.isForMemoTable ? cpmo.memoSizeName : null}</td>
                                    <td>{cpmo.typeOfMemoOrderLineStatusName}</td>
                                    <td>{props.isForMemoTable ? cpmo.supplierName : cpmo.sampleBookName}</td>
                                    <td>{props.isForMemoTable ? cpmo.dcName : null}{}</td>
                                    <td>{props.isForMemoTable ? cpmo.productColorName : null}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default class CustomerOrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          customerId: props.customerId,
          customerPastOrdersBySupplier: [],
          customerPastOrders: [],
          customerPastMemoOrders: [],
          customerPastOrderPayments: [],
          year1DateRange: "",
          year2DateRange: "",
          year3DateRange: ""
        };
    }

    componentDidMount() {
        const payload = {
            CustomerId: this.state.customerId,
            SortField: 'LastStatusAt',
            SortDir: 'desc'
        };

        // const getCustomers = api.fetch("Reference/GetCustomerList")
        //     .then((response) => {
        //         return { customerList: response.data };
        //     })
        //     .catch(helpers.catchHandler);

        // Promise.all([
        //     getCustomers
        // ])
        //     .then((aggregateResults) => {
        //         const newStatus = {};
        //         aggregateResults.forEach(r => Object.assign(newStatus, r));
        //         this.setState(newStatus, () =>
        //             this.refreshList());
        //     })
        //     .catch(helpers.catchHandler);

        api.post('MemoOrder/GetList', payload)
        .then((response) => {
            this.setState({
                customerPastMemoOrders: response.data
            });
        }).catch(helpers.catchHandler);

        api.fetch(`Customer/GetCustomerPastOrderInfo/${this.state.customerId}`)
        .then((response) => {
            this.setState({
              customerPastOrdersBySupplier: _.filter(response.data.customerPastOrdersBySupplier, x => x.supplierName !== 'DEFAULT'),
              customerPastOrders: response.data.customerPastOrders,
              customerPastOrderPayments: response.data.customerPastOrderPayments,
              year1DateRange: response.data.year1DateRange,
              year2DateRange: response.data.year2DateRange,
              year3DateRange: response.data.year3DateRange
            });
        }).catch(helpers.catchHandler);
    }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col>
                                <h4>Order History By Supplier</h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                      <tr>
                                        <th>Supplier</th>
                                      <th className="centerText">{this.state.year1DateRange}</th>
                                        <th className="centerText">{this.state.year2DateRange}</th>
                                        <th className="centerText">{this.state.year3DateRange}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.customerPastOrdersBySupplier.map((cpobs, idx) => (
                                        <tr key={idx}>
                                          <td>{cpobs.supplierName}</td>
                                          <td className="centerText">{helpers.formatCurrency(cpobs.decimalYear1Total)}</td>
                                          <td className="centerText">{helpers.formatCurrency(cpobs.decimalYear2Total)}</td>
                                          <td className="centerText">{helpers.formatCurrency(cpobs.decimalYear3Total)}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col>
                                <h4>Order History</h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>DC Order #</th>
                                            <th>Placed On</th>
                                            <th>Agent</th>
                                            <th>Status</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.customerPastOrders.map(o => (
                                            <tr key={o.id}>
                                                <td>
                                                    <Link 
                                                        to={{
                                                            pathname: '/OrderEntry',
                                                            state: { activeOrderId: o.id },
                                                            push: true
                                                        }}
                                                    >
                                                        {o.id}
                                                    </Link>
                                                </td>
                                                <td>{date_helpers.formatDateToShortDate(o.createdAt)}</td>
                                                <td>{o.agentIdTag}</td>
                                                <td>{o.orderStatusName}</td>
                                                <td>{helpers.formatCurrency(o.decimalOrderTotal)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <MemoAndSamplesTable isForMemoTable={true} pastOrders={_.filter(this.state.customerPastMemoOrders, (o) => o.supplierName != null)} />
                        <MemoAndSamplesTable isForMemoTable={false} pastOrders={_.filter(this.state.customerPastMemoOrders, (o) => o.sampleBookName != null)} />
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col>
                                <h4>Payment History</h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>DC Order #</th>
                                            <th>Amount</th>
                                            <th>Balance Due</th>
                                            <th>Invoice Terms</th>
                                            <th>Invoice Date</th>
                                            <th>Payment Type</th>
                                            <th>Last Payment Date</th>
                                            <th>Days To Pay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.customerPastOrderPayments.map(p => {
                                        return (<tr key={p.id}>
                                                <td>
                                                    <Link 
                                                        to={{
                                                            pathname: '/OrderEntry',
                                                            state: { activeOrderId: p.orderId },
                                                            push: true
                                                        }}
                                                    >
                                                        {p.orderId}
                                                    </Link>
                                                </td>
                                                <td>{helpers.formatCurrency(p.decimalAmountPaid)}</td>
                                                <td>{helpers.formatCurrency(p.decimalAmountDue)}</td>
                                                <td>{p.invoiceTermsName}</td>
                                                <td>{p.invoicedOn}</td>
                                                <td>{p.paymentTypeName}</td>
                                                <td>{p.lastPaidOn}</td>
                                                <td>{p.daysToPay >= 0 ? p.daysToPay : "-"}</td>
                                            </tr>
                                        )
                                    } )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}