import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import _ from 'lodash';

export default class FilterSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      options: props.options,
      value: props.value,
      onChangeCallback: props.onChangeCallback,
    };
    this.onChange = this.onChange.bind(this);
    this.getValues = this.getValues.bind(this);
  }

  onChange(selection) {
    const value = selection === null ? null : selection.value;
    const label = selection === null ? null : selection.label;
    const filteredDisplay = value === null
        ? ''
        : `${this.state.displayName}: ${label}`;

    this.state.onChangeCallback({
      filterName: this.state.filterName,
      value,
      label,
      filteredDisplay,
    });
  }

  getValues(value) {
    if (!value) return null;
    return _.find(this.state.options, x => x.value === value);
  }

  render() {
    if (!this.props.options) {
      return null;
    }

    return (
      <span className="filter-definition-caption">
        {this.state.displayName}
        <Select
          closeMenuOnSelect={!this.state.multiSelect}
          isMulti={false}
          components={makeAnimated()}
          options={this.props.options || this.state.options}
          onChange={this.onChange}
          value={this.getValues(this.props.value)}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          onCloseResetsInput={false}
          classNamePrefix="react-select"
          isClearable={this.props.isClearable}
        />
      </span>
    );
  }
}
