import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

export default class InputDebounce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      onChangeCallback: props.onChangeCallback,
      maxLength: props.maxLength || 255
    };
    // this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value});
    }
    // if (prevProps.id !== this.props.id) {
    //   this.setState({id: this.props.id});
    // }
  }

  onChange(value) {
    let resolvedValue = value;
    if (this.props.forceInteger) {
      resolvedValue = parseInt(value);
      if (isNaN(resolvedValue)) {
        resolvedValue = "";
      }
    }
    this.state.onChangeCallback({
      id: this.props.id,
      value: resolvedValue
    });
  }

  render() {
    return (
      <DebounceInput
        className="form-control"
        minLength={2}
        id={this.props.id}
        value={this.state.value}
        debounceTimeout={this.props.debounceTimeout || 700}
        onChange={event => this.onChange(event.target.value)}
        element={this.props.type}
        maxLength={this.state.maxLength}
      />
    );
  }
}