import React, { Component, Fragment } from 'react';
import {
    Button,
    Col,
    Row,
    Container,
    Table,
    ButtonGroup,
    Alert,
    Input,
    Label,
    Card,
    CardBody,
    Collapse
} from 'reactstrap';
import api from '../utils/api';
import {helpers, constants} from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'react-select';
import ToggleButton from 'react-toggle-button';
import { Redirect } from 'react-router-dom';
import {
  SortHeader
} from '../components';

const emptyProductMemoSize = {
    memoSize: {
        value: "",
        label: ""
    },
    productId: null,
    productColor: {
        value: "",
        label: ""
    },
    qty: "",
    shelfNumber: ""
};

const filterMemoSizes = (list, showAll, defaultMemoSizeName) => {
  if (showAll) return list;
  return _.filter(list, d => {
    return d.qty || (d.memoSizeName === defaultMemoSizeName);
  });
};

export default class EditProductMemoInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          productMemoSizes: filterMemoSizes(props.productMemoSizes, false, props.defaultMemoSizeName),
          addNewProductMemoSize: false,
          newProductMemoSize: emptyProductMemoSize,
          message: null,
          messageFlavor: null,
          memoSizeList: props.memoSizeList,
          productColors: props.productColors,
          memoShelfNumber: props.memoShelfNumber,
          pristineProductMemoSize: null,
          productId: props.productId,
          showAll: false,
          sortField: 'ProductColorName',
          sortDir: 'asc'
        };
        this.downloadLabel = this.downloadLabel.bind(this);
        this.saveNewProductMemoSize = this.saveNewProductMemoSize.bind(this);
        this.updateProductMemoSize = this.updateProductMemoSize.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.doSort = this.doSort.bind(this);
    }

    //componentDidUpdate(prevProps) {
    //  this.updateProps(prevProps);
    //}

    //updateProps(prevProps) {
    //  if (prevProps.memoSizeList !== this.state.memoSizeList
    //      || prevProps.productId !== this.state.productId
    //      || prevProps.memoShelfNumber !== this.state.memoShelfNumber
    //      || prevProps.productColors !== this.state.productColors
    //      || prevProps.productMemoSizes !== this.state.productMemoSizes) {
    //      this.setState({
    //          memoSizeList: this.props.memoSizeList,
    //          productId: this.props.productId,
    //          memoShelfNumber: this.props.memoShelfNumber,
    //          productColors: this.props.productColors,
    //        productMemoSizes: this.props.productMemoSizes
    //      });
    //  }
    //}

    getProductColors(productId) {
        api
            .fetch(`Product/GetProductColors/${productId}`)
            .then(response => {
                this.setState({
                    productColors: helpers.resolveValues(response.data)
                });
            })
            .catch(helpers.catchHandler);
    }

    getProductMemoSizes(productId) {
      api.post('Product/GetProductMemoSizes', {productId: productId, sortDir: this.state.sortDir, sortField: this.state.sortField}).then(response => {
        const list = _.map(response.data, d => { 
          d.isEditing = false; 
          return d;
        });
        this.setState({
          productMemoSizes: filterMemoSizes(list, this.state.showAll)
        });
      }).catch(helpers.catchHandler);
    }

    addNewProductMemoSize() {
        this.setState({
            addNewProductMemoSize: !this.state.addNewProductMemoSize,
            newProductMemoSize: emptyProductMemoSize,
            message: null,
            messageFlavor: null
        });
    }

    onEdit(productMemoSizeId) {
        const indexToEdit = _.findIndex(this.state.productMemoSizes, {id: productMemoSizeId});
        let tempList = this.state.productMemoSizes.slice();
        let snapshotItem = Object.assign({}, tempList[indexToEdit]);
        snapshotItem.isEditing = false;
        //tempList[indexToEdit].pristine = snapshotItem;
        tempList[indexToEdit].isEditing = true;
        this.setState({
          productMemoSizes: tempList,
          pristineProductMemoSize: snapshotItem
        });
    }

    onDelete(productMemoSizeId) {
      const indexToEdit = _.findIndex(this.state.productMemoSizes, {id: productMemoSizeId});
      let tempList = this.state.productMemoSizes.slice();
      if (!window.confirm('Are you sure you\'d like to permanently delete this memo size and related inventory?')) return;
      api.delete('Product/DeleteProductMemoSize/' + productMemoSizeId).then(response => {
        if (response.data.success) {
          tempList.splice(indexToEdit, 1);
          this.setState({ productMemoSizes: tempList });
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      }).catch(helpers.catchHandler);
    }

    onCancelEdit(productMemoSizeId) {
      let indexToEdit = _.findIndex(this.state.productMemoSizes, { id: productMemoSizeId });
      let tempList = this.state.productMemoSizes.slice();
        tempList[indexToEdit] = this.state.pristineProductMemoSize;
        this.setState({
          productMemoSizes: tempList, 
          pristineProductMemoSize: null,
          message: null,
          messageFlavor: null
        });
    }

    updateProductMemoSizeState(fieldName, value, productMemoSizeId) {
        const productMemoSizes = this.state.productMemoSizes.slice();
        const productMemoSize = _.find(productMemoSizes, x => x.id === productMemoSizeId);
        productMemoSize[fieldName] = value;
        this.setState({
          productMemoSizes: productMemoSizes
        });
    }

    handleProductMemoSizeStateChange(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            newProductMemoSize: {
                ...prevState.newProductMemoSize,
                [fieldName]: value
            }
        }));
    }

    setSelectedMemoSize(option) {
        this.setState(prevState => ({
            ...prevState,
            newProductMemoSize: {
                ...prevState.newProductMemoSize,
                memoSize: {
                    value: option.value,
                    label: option.label
                }
            }
        }));
    }

    setSelectedProductColor(option) {
        this.setState(prevState => ({
            ...prevState,
            newProductMemoSize: {
                ...prevState.newProductMemoSize,
                productColor: {
                    value: option.value,
                    label: option.label
                }
            }
        }));
    }

    isFormValid(item) {
        const warnings = [];

        if (!item.ProductColorId) {
            warnings.push("Product Color required.");
        }
        if (!item.MemoSizeId) {
            warnings.push("Memo Size required.");
        }
        if (Number(item.Qty) < 0)  {
            warnings.push("Quantity must be greater than zero.");
        }
        if (Number(item.Qty) > 2147483647)  {
            warnings.push("Quantity must be less than 2147483647.");
        }
        if (warnings.length) {
            this.setState({ 
                message: warnings.join(' '),
                messageFlavor: "danger" 
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    saveNewProductMemoSize() {
      const data = {
        Id: 0,
        MemoSizeId: this.state.newProductMemoSize.memoSize.value,
        ProductId: this.state.productId,
        ProductColorId: this.state.newProductMemoSize.productColor.value,
        Qty: Number(this.state.newProductMemoSize.qty)
      };
      if (!this.isFormValid(data)) return;
      api.post('Product/SaveNewProductMemoSize', data).then(response => {
        if (response.data.success) {
          this.setState({
            newProductMemoSize: emptyProductMemoSize,
            addNewProductMemoSize: false
          }, () => {
            this.getProductMemoSizes(this.state.productId);
            this.props.refresh(this.state.productId)
          });
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      }).catch(helpers.catchHandler);
    }

    updateProductMemoSize(productMemoSize) {
        const data = {
            Id: productMemoSize.id,
            ProductId: this.state.productId,
            MemoSizeId: productMemoSize.memoSizeId,
            ProductColorId: productMemoSize.productColorId,
            Qty: Number(productMemoSize.qty)
        };
        
        if (!this.isFormValid(data)) return;
        
        api.post(`Product/UpdateProductMemoSize`, data)
        .then(response => {
          if (response.data.success) {
            this.props.refresh(this.state.productId);
            this.getProductMemoSizes(this.state.productId);
          } else {
              this.setState({ messageFlavor: "danger", message: response.data.message });
          }  
        })
        .catch(helpers.catchHandler);
    }

  onCancel() {
    this.setState({ redirect: true });
  }

  doSort(field, direction) {
    this.setState({ sortDir: direction, sortField: field }, () => this.getProductMemoSizes(this.state.productId));
  }

  downloadLabel(productMemoSizeId) {
    api.post(`MemoSize/DownloadShippingLabel/${productMemoSizeId}`,{}, "blob").then(response => {
        if (response.status === 200) {
          helpers.downloadExport(response.data, 'Shipping_Label.xlsx', constants.MIME_XLSX);
        } else {
          throw new Error("Error downloading shipping label");
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
        if (this.state.redirect === true) {
            return <Redirect to="/maintainproduct" />;
        };
        const b = this.state.newProductMemoSize;
        return (
            <Container fluid className="p-2">
                {this.state.message !== null && (
                    <Row className="mb-2">
                        <Col>
                            <Alert
                                className={this.state.messageFlavor}
                                isOpen={this.state.isMessageVisible}
                                toggle={this.onMessageDismiss}
                            >
                                {this.state.message}
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row className="expand-md mt-2 mb-0 pb-0">
                    <Col sm="4">
                        <h4>Product Memo Inventory</h4>
                    </Col>
                    <Col sm="2">
                      <div style={{float: "left", width: "60px"}} className="pt-1">
                        <ToggleButton
                          value={this.state.showAll}
                          activeLabel={"Yes"}
                          inactiveLabel={"No"}
                          onToggle={(value) => {
                            this.setState(prevState => ({
                              ...prevState,
                              showAll: !value
                            }), () => this.getProductMemoSizes(this.state.productId));
                          }}
                        />
                      </div>
                      <span className="pl-1">Show All</span>
                    </Col>
                    <Col xs="6">
                        <Button
                            className="float-right success btn-sm"
                            onClick={() => this.addNewProductMemoSize()}
                        >
                            <FontAwesomeIcon icon="plus" /> Add Product Memo Size
                        </Button>
                        <h4 className="float-right pr-2">{`Shelf #${this.state.memoShelfNumber}`}</h4>
                    </Col>
                </Row>
                <Collapse isOpen={this.state.addNewProductMemoSize} className="row-spacing">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">
                                <Col>
                                    <Label>Product Color</Label>
                                    <Select
                                        closeMenuOnSelect
                                        value={b.productColor}
                                        options={helpers.resolveColorValues(this.state.productColors)}
                                        onChange={(option) => this.setSelectedProductColor(option)}
                                    />
                                </Col>
                                <Col>
                                    <Label>Memo Size</Label>
                                    <Select
                                        closeMenuOnSelect
                                        value={b.memoSize}
                                        options={this.state.memoSizeList}
                                        onChange={(option) => this.setSelectedMemoSize(option)}
                                    />
                                </Col>
                                <Col>
                                    <Label>Qty</Label>
                                    <Input
                                        type="number"
                                        name="qty"
                                        onChange={(event) => this.handleProductMemoSizeStateChange(
                                            event.target.name,
                                            event.target.value)}
                                        value={b.qty}
                                        min="0"
                                        max="2147483647"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ButtonGroup className="btn-group btn-access float-right">
                                        <Button
                                            className="btn primary"
                                            size="sm"
                                            onClick={() => this.saveNewProductMemoSize()}
                                        >
                                            <FontAwesomeIcon icon="save" /> Save
                                        </Button>
                                        <Button
                                            className="btn secondary"
                                            size="sm"
                                            onClick={() => this.addNewProductMemoSize()}
                                        >
                                            <FontAwesomeIcon icon="times-circle" /> Cancel
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Collapse>
                <Row className="row-spacing">
                    <Col className="col-12">
                        <Table striped hover size="sm">
                            <thead>
                                <tr>
                                    <th>
                                      <SortHeader
                                          displayName="Product Color"
                                          field="ProductColorName"
                                          sortDir={this.state.sortDir}
                                          sorted={this.state.sortField === 'ProductColorName'}
                                          callBack={this.doSort}
                                      />
                                    </th>
                                    <th>
                                      <SortHeader
                                          displayName="Color #"
                                          field="ProductColorNumber"
                                          sortDir={this.state.sortDir}
                                          sorted={this.state.sortField === 'ProductColorNumber'}
                                          callBack={this.doSort}
                                      />
                                    </th>
                                    <th>
                                      <SortHeader
                                          displayName="Memo Size"
                                          field="MemoSizeName"
                                          sortDir={this.state.sortDir}
                                          sorted={this.state.sortField === 'MemoSizeName'}
                                          callBack={this.doSort}
                                      />
                                    </th>
                                    <th>
                                      <SortHeader
                                          displayName="Quantity"
                                          field="Quantity"
                                          sortDir={this.state.sortDir}
                                          sorted={this.state.sortField === 'Quantity'}
                                          callBack={this.doSort}
                                      />
                                    </th>
                                    <th width="15%" />
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.productMemoSizes.map(b => (
                                    <tr key={b.id} className="data-row">
                                        <td>
                                            {b.productColorName}
                                        </td>
                                        <td>
                                            {b.productColorNumber}
                                        </td>
                                        <td>
                                            {b.memoSizeName}
                                        </td>
                                        <td>
                                            {b.isEditing ? (
                                                <Input
                                                    type="number"
                                                    name="qty"
                                                    value={helpers.nullableString(b.qty)}
                                                    onChange={(event) => this.updateProductMemoSizeState(
                                                        event.target.name,
                                                        event.target.value,
                                                        b.id
                                                    )}
                                                    min="0"
                                                    max="2147483647"
                                                />
                                            ) : b.qty}
                                        </td>
                                        <td className="text-right">
                                            <ButtonGroup className="float-right btn-group-sm btn-sm">
                                              <Button
                                                  className="secondary"
                                                  onClick={() => this.downloadLabel(b.id)}
                                                  size="sm"
                                              >
                                                  Shipping Label
                                              </Button>
                                                {b.isEditing ? (
                                                    <Fragment>
                                                        <Button
                                                            className="success"
                                                            onClick={() => this.updateProductMemoSize(b)}
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon icon="save" /> Save
                                                        </Button>
                                                        <Button
                                                            className="secondary"
                                                            onClick={() => this.onCancelEdit(b.id)}
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon icon="times-circle" /> Cancel
                                                        </Button>
                                                    </Fragment>
                                                ) : (<Fragment>
                                                        <Button
                                                            className="primary btn-outline-secondary"
                                                            onClick={() => this.onEdit(b.id)}
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon icon="edit" />
                                                        </Button>
                                                        <Button
                                                            className="danger"
                                                            onClick={() => this.onDelete(b.id)}
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon icon="times-circle" />
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="expand-md row-spacing">  
                    <Col className="text-right">
                            <Button
                                className="secondary"
                                size="sm"
                                onClick={this.onCancel}
                            >
                                <FontAwesomeIcon icon="times-circle" /> Back to Products
                            </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
