import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { api } from "../utils";

const resolveValues = (customer) => {
  if (!customer) return;
  return {
    customerId: customer.value,
    customer: customer,
    inputValue: customer.label
  };
};

export default class CustomerTypeahead extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      { options: [] },
      resolveValues(props.customer)
    );
    this.onSelect = this.onSelect.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
  }

  componentDidMount() {
    if (!this.props.onChange) {
      console.warn("set onChange for customer typeahead");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clearValue !== this.props.clearValue && this.props.clearValue) {
      this.clearSelection();
    } else if (this.props.customer !== undefined 
      && this.props.customer !== null
      && this.props.customer.value
      && (!this.state.customer || (this.state.customer && (this.state.customer.value !== this.props.customer.value)))) {
      this.setState(resolveValues(this.props.customer));
    }
  }

  clearSelection() {
    this.setState({
      customerName: null,
      inputValue: "",
      customerId: null
    });
    if (this.props.onChange) {
      this.props.onChange({filterName: (this.props.name), value: null});
    }
  }

  onSelect(selection) {
    if (selection) {
      if (this.props.onChange) {
        this.props.onChange(selection);
      }
      this.setState({
        customer: selection
      });
    } else {
      this.clearSelection();
    }
  }

  loadOptions(inputValue, callBack) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    api
      .fetch(`Customer/CustomerTypeahead/${inputValue}`)
      .then(response => {
        const options = _.map(response.data, x => {
          return {value: x.value, label: x.label}
        })
        callBack(options);
      })
      .catch(api.catchHanlder);
  }

  onInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }

  render() {
    return (
      <AsyncSelect
        loadOptions={_.debounce(this.loadOptions, 500)}
        placeholder="Type Name"
        // isClearable={true}
        options={this.state.options}
        isMulti={this.props.isMulti}
        onChange={this.onSelect}
        inputValue={this.state.inputValue}
        onInputChange={this.onInputChange}
        value={this.state.expert}
        isDisabled={this.props.disabled}
      />
    );
  }
}
