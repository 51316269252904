import React from "react";
import { Alert, Form, Row, Col, Container } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
// import UserAdmin from '../pages/UserAdmin';
// import DCLogo from "../assets/img/dc-logo.jpg";
import DCLogo from '../assets/img/logo_with_rugs.jpg';
import { api, helpers } from "../utils";

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);
    let lastUsername = "";
    try {
      const tmp = localStorage.getItem('lastUsername');
      if (tmp) {
        lastUsername = tmp;
      }
    } catch {}
    this.state = {
      username: lastUsername,
      password: "",
      //emailCaptured: false,
      message: null,
      messageFlavor: null,
      // touched: {
      //     username: false,
      //     password: false
      // }
      redirectTo: null
    };
    this.handlesBlur = this.handlesBlur.bind(this);
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handlesBlur = (field) => (e) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    api.post("public/authenticate", {
        Username: this.state.username,
        Password: this.state.password,
      }).then((response) => {
        if (response.data.success) {
          try {
            localStorage.setItem('lastUsername', this.state.username);
          } catch {}
          this.setState({
            messageFlavor: "success",
            message: "Log-In Successful!",
          });
          this.props.setCurrentUser(
            response.data.user,
            response.data.user.token
          );
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    const {
      redirectTo
    } = this.state;
    if (redirectTo) {
			return <Redirect to={redirectTo} />;
    }
    return (
      <Container>
        <Row>
          <Col sm="12" className="text-center">
            <img id="DCLogo" src={DCLogo} alt="DC Logo" width="500" />
          </Col>
        </Row>
        {this.state.message !== null && (
          <Row className="mb-2">
            <Col>
              <Alert className={this.state.messageFlavor} fade>
                {this.state.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col md={{ size: 4, offset: 4 }} sm="12">
            <Form id="sign_in_form" onSubmit={this.handleSubmit.bind(this)}>
              <div className="form-group">
                <label>Username</label>
                <input
                  className="form-control"
                  autoFocus
                  type="text"
                  style={{ marginTop: "0" }}
                  value={this.state.username}
                  onChange={this.handleUsername.bind(this)}
                  onBlur={this.handlesBlur("username")}
                  placeholder="Enter username"
                  maxLength="150"
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  className="form-control"
                  type="password"
                  style={{ marginTop: "0" }}
                  value={this.state.password}
                  onChange={this.handlePassword.bind(this)}
                  onBlur={this.handlesBlur("password")}
                  placeholder="Enter password"
                  maxLength="64"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="btn primary"
                  style={{ marginTop: "0" }}
                >
                  Sign In
                </button>
              </div>
              <div className="text-right mt-2">
                <Link
                  to={{
                    pathname: "/forgot_password",
                    state: { email: this.state.email },
                    push: true,
                  }}
                >
                  Misplaced your password?
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
