import React, { Component } from 'react';
import { Card, CardBody, CardTitle }from 'reactstrap';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialState = () => ({
    files: [],
});

export default class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.onDrop = this.onDrop.bind(this);
    }

    onDrop(acceptedFiles, rejectedFiles) {
        this.setState({ files: acceptedFiles });
    }

    render() {
        return (
            <div>
                <Card className="text-center">
                    <CardBody>
                        <CardTitle>Drop files or click on the icon below</CardTitle>
                        <Dropzone
                            className="col-xs-4"
                            onDrop={this.onDrop}
                            accept="image/png"
                        >
                            <div style={{ textAlign: 'center' }}>
                                <FontAwesomeIcon icon="file-upload" size="6x" />
                            </div>
                        </Dropzone>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
 