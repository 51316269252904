import React, { Component, Fragment } from 'react';
import { Table, Container, Button, CardBody, Col, Row, Alert, Collapse, ButtonGroup, Card, Input } from 'reactstrap';
import api from '../utils/api';
import helpers from '../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import _ from 'lodash';
import { FilterSet, InputDebounce, SortHeader, FilterText, FilterBoolean, FilterMultiSelect, Pager} from '../components';
import filterHelpers from '../utils/filter_helpers';

const emptyCategory = {
    id: null,
    name: '',
    categoryType: {
        value: "",
        label: ""
    }, 
    seq: 0
};

class ExpenseCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortField: 'Id',
            sortDir: 'asc',
            filters: [{ filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Yes" }],
            pagedList: null,
            expenseCategory: emptyCategory,
            categoryTypes: [],
            expenseCategoryList: [],
            message: null,
            messageFlavor: null,
            addExpenseCategory: false
        };
        this.onAdd = this.onAdd.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUnDelete = this.handleUnDelete.bind(this);
         this.setPage = this.setPage.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.onSubmitNew = this.onSubmitNew.bind(this);
        this.onCategoryTypeChange = this.onCategoryTypeChange.bind(this);
    }

    componentDidMount() {
        const getCategoryTypes = api.fetch("Reference/GetExpenseCategoryTypeList")
            .then((response) => {
                return {expenseCategoryList: response.data};
            })
            .catch(helpers.catchHandler);
        Promise.all([
            getCategoryTypes
        ])
            .then((aggregateResults) => {
                const newStatus = {};
                aggregateResults.forEach(r => Object.assign(newStatus, r));
                this.setState(newStatus, () =>
                  this.refreshList());
            })
            .catch(helpers.catchHandler);
    }

    refreshList(sortField, sortDirection, filters) {
        let filterList = filters || this.state.filters;
        let payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir,
        }

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

      api.post("ExpenseCategory/GetPaginatedList", payload)
            .then((response) => {
                this.setState({
                  pagedList: response.data
                });
            }).catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filterHelpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

     setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, () => this.refreshList());
     }

    onCancel() {
        this.setState({
            addExpenseCategory: !this.state.addExpenseCategory,
            expenseCategory: emptyCategory,
            message: null, 
            messageFlavor: null
        });
    }

    onAdd() {
        this.setState({
            addExpenseCategory: !this.state.addExpenseCategory,
            expenseCategory: emptyCategory
        });
    }

    handleUnDelete(id) {
        api.post(`ExpenseCategory/Undelete/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api.delete(`ExpenseCategory/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    onTextChange(fieldName, event) {
        let expenseCategory = Object.assign({}, this.state.expenseCategory)
        expenseCategory[fieldName] = event.value;
        this.setState({
            expenseCategory: expenseCategory
        });
    }

    onCategoryTypeChange(selection) {
        this.setState({
            expenseCategory: {
                ...this.state.expenseCategory,
                categoryType: selection
            }
        });
    }

    isFormValid(item) {
        let warnings = [];
        if (!item.Name) {
            warnings.push("Expese Category Name is required.");
        }
        if (!item.ExpenseCategoryTypeId) {
            warnings.push("Expense Category Type is required.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }


    onSubmitNew() {
        window.scroll(0,0);
        const payload = {
            Name: this.state.expenseCategory.name,
            ExpenseCategoryTypeId: parseInt(this.state.expenseCategory.categoryType.value, 10)
        };
        if (!this.isFormValid(payload)) return;
        api.upload('post', 'ExpenseCategory', payload)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        addExpenseCategory: false,
                        expenseCategory: emptyCategory,
                    }, () => this.refreshList());
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            })
            .catch(helpers.catchHandler);
    }

    onSubmitEdit(e, expenseCategory) {
        window.scroll(0,0);
        e.preventDefault();
        const payload = {
            Name: expenseCategory.name,
            ExpenseCategoryTypeId: parseInt(expenseCategory.expenseCategoryTypeId, 10),
            Id: expenseCategory.id
        };
        if (!this.isFormValid(payload)) return;
        api.upload('put',`ExpenseCategory/${expenseCategory.id}`, payload)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        addExpenseCategory: false,
                        expenseCategory: emptyCategory,
                        messageFlavor: "success",
                        message: "Expense Category saved."
                    }, () => this.refreshList());
                } else {
                    this.setState({ messageFlavor: "danger", message: response.data.message });
                }
            })
            .catch(helpers.catchHandler);
    }

    onEdit(categoryId) {
      const indexToEdit = _.findIndex(this.state.pagedList.list, {id: categoryId});
      let tempList = this.state.pagedList.list.slice();
        const snapshotItem = Object.assign({}, tempList[indexToEdit]);
        tempList[indexToEdit].isEditing = true;
        tempList[indexToEdit].pristine = snapshotItem;
      this.setState({
        pagedList: { ...this.state.pagedList, list: tempList }
        });
    }

    onCancelEdit(categoryId) {
      const indexToEdit = _.findIndex(this.state.pagedList.list, {id: categoryId});
      let tempList = this.state.pagedList.list.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
          pagedList: { ...this.state.pagedList, list: tempList },
            message: null,
            messageFlavor: null
        });
    }

    updateEditCategoryState(fieldName, value, categoryId) {
      const tempList = this.state.pagedList.list.slice();
        const category = _.find(tempList, x => x.id === categoryId);
        category[fieldName] = value;
        this.setState({
          pagedList: { ...this.state.pagedList, list: tempList }
        });
    }

    editSelectedCategoryType(option, categoryId) {
      const tempList = this.state.pagedList.list.slice();
        const category = _.find(tempList, x => x.id === categoryId);
        category.expenseCategoryTypeId = option.value;
        category.expenseCategoryTypeName = option.label;
        this.setState({
          pagedList: { ...this.state.pagedList, list: tempList }
        });
    }

  render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="pull-left page-title">Maintain Expense Categories</h3>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col sm="12">
                                <ButtonGroup className="float-right btn-group-sm btn-sm">
                                    <Button className="float-right success btn-sm" onClick={() => this.onAdd()}>
                                        <FontAwesomeIcon icon="plus" /> Add Expense Category
                                    </Button>
                                </ButtonGroup>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="name"
                                                displayName="Expense Category Name"
                                                value={this.currentFilterValue('name')}
                                                onChangeCallback={this.filterChange} />
                                        </Col>
                                        <Col xs="3">
                                            <FilterMultiSelect
                                                filterName="ExpenseCategoryTypeIdList"
                                                displayName="Expense Category Type"
                                                options={this.state.expenseCategoryList}
                                                values={this.currentFilterValue('ExpenseCategoryTypeIdList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean
                                                filterName="ActiveOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.addExpenseCategory} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">Add New Expense Category</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>Name {helpers.requiredStar()}
                                            <InputDebounce
                                                type="input"
                                                name="name"
                                                onChangeCallback={this.onTextChange.bind(this, 'name')}
                                                value={this.state.expenseCategory.name}
                                                maxLength="25"
                                            />
                                        </Col>
                                        <Col>Category Type {helpers.requiredStar()}
                                            <Select
                                                closeMenuOnSelect
                                                value={this.state.expenseCategory.categoryType}
                                                isMulti={false}
                                                options={this.state.expenseCategoryList}
                                                onChange={this.onCategoryTypeChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    className="primary"
                                                    size="sm"
                                                    onClick={this.onSubmitNew}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    className="secondary"
                                                    size="sm"
                                                    onClick={this.onCancel}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Row className="row-spacing">
                          <Col>
                            <Table striped hover size="sm">
                                <thead>
                                    <tr>
                                        <th>
                                            <SortHeader displayName="Name" field="Name" sortDir={this.state.sortDir}
                                                sorted={this.state.sortField === 'Name'} callBack={this.doSort} />

                                        </th>
                                        <th>
                                            <SortHeader displayName="Expense Category Type" field="ExpenseCategoryType" sortDir={this.state.sortDir}
                                                sorted={this.state.sortField === 'ExpenseCategoryType'} callBack={this.doSort} />

                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                        {this.state.pagedList && this.state.pagedList.list.length > 0 ?
                          this.state.pagedList.list.map(cat => (
                                            <Fragment key={cat.id}>
                                                {cat.isEditing
                                                    ? <tr>
                                                        <td>
                                                            <Input
                                                                type="input"
                                                                name="name"
                                                                onChange={(event) => this.updateEditCategoryState(
                                                                    event.target.name,
                                                                    event.target.value,
                                                                    cat.id
                                                                )}
                                                                value={cat.name}
                                                                maxLength="25"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                closeMenuOnSelect
                                                                value={helpers.resolveValue(null, cat.expenseCategoryTypeId, cat.expenseCategoryTypeName)}
                                                                options={this.state.expenseCategoryList}
                                                                onChange={(option) => this.editSelectedCategoryType(option, cat.id)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <ButtonGroup className="float-right">
                                                                <Button
                                                                    className="primary btn"
                                                                    onClick={(e) => this.onSubmitEdit(e, cat)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary btn"
                                                                    onClick={() => this.onCancelEdit(cat.id)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    : <tr>
                                                        <td>{cat.name}</td>
                                                        <td>{cat.expenseCategoryTypeName}</td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                {cat.deactivatedAt === null ? (
                                                                    <React.Fragment>
                                                                        <Button className="primary btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.onEdit.bind(this, cat.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="edit" />
                                                                        </Button>
                                                                        <Button
                                                                            className="danger btn-outline-secondary"
                                                                            size="sm"
                                                                            onClick={this.handleDelete.bind(this, cat.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash" />
                                                                        </Button>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Button
                                                                        className="info"
                                                                        size="sm"
                                                                        onClick={this.handleUnDelete.bind(this, cat.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon="recycle" /> Revive
                                                                    </Button>
                                                                )}
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        )) : null}
                                    </tbody>
                                </Table>
                              <Pager {...this.state.pagedList} callBack={this.setPage} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

export default ExpenseCategory;
