import React from "react";
import {
  Alert,
  Form,
  Row,
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import classnames from "classnames";
import { api, helpers } from "../utils";
//import _ from "lodash";

const tabs = {
  login: "Log In",
  register: "Register",
};

export default class SqSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNo: "",
      password: "",
      webFirstName: "",
      webLastName: "",
      existingAccountNo: "",
      webEmail: "",
      newPassword: "",
      newPasswordAgain: "",
      message: null,
      messageFlavor: null,
      redirectTo: null,
      activeTab: tabs.login,
      accountVerified: false,
      accountAlreadyRegistered: false,
      registering: false,
      emailAlreadyRegistered: false,
      emailVerified: false
    };
    this.handlesBlur = this.handlesBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateLoginForm = this.validateLoginForm.bind(this);
    this.redirectToScreen = this.redirectToScreen.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname === '/sq/logout') {
      this.props.SquarespaceLogout()
    }
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  validateLoginForm() {
    return this.state.accountNo.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handlesBlur = (field) => (e) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (!this.validateLoginForm()) return;
    api
      .post("web/CustomerAuthenticate", {
        Number: parseInt(this.state.accountNo, 10),
        Password: this.state.password,
      })
      .then((response) => {
        if (response.data.success) {
          this.setState(
            {
              messageFlavor: "success",
              message: "Log-In Successful!",
            },
            () => {
              this.props.setCurrentCustomer(
                response.data.customer,
                response.data.customer.token
              );
            }
          );
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleRegister(e) {
    e.preventDefault();
    let {
      webFirstName,
      webLastName,
      webEmail,
      newPassword,
      newPasswordAgain,
      existingAccountNo,
      accountAlreadyRegistered,
    } = this.state;
    this.setState({
      registering: true
    }, () => {
      let warnings = [];
      let accountVerified = null;
      api
        .post('web/checkAccountNumber', {
          AccountNo: parseInt(existingAccountNo, 10),
          WebEmail: webEmail
        })
        .then((response) => {
          if (response.data.success) {
            accountAlreadyRegistered = false;
          } else {
            accountAlreadyRegistered = true;
          }
          accountVerified = true;
        })
        .catch(helpers.catchHandler)
        .finally(() => {
          if (accountAlreadyRegistered) {
            warnings.push("This account has already been registered.");
          }
          if (
            !accountVerified ||
            !existingAccountNo ||
            existingAccountNo.length < 2
          ) {
            warnings.push("Please enter your customer account number to proceed.");
          }
          if (webFirstName === "" || webFirstName.length < 2) {
            warnings.push("Please enter your first name.");
          }
          if (webLastName === "" || webLastName.length < 2) {
            warnings.push("Please enter your last name.");
          }
          if (
            newPassword === "" ||
            newPassword.length < 8 ||
            newPassword === "password"
          ) {
            warnings.push("Please enter a secure password of 8 characters or more.");
          }
          if (
            newPasswordAgain === "" ||
            newPasswordAgain.length < 8 ||
            newPasswordAgain === "password" ||
            newPasswordAgain !== newPassword
          ) {
            warnings.push(
              "The second password doesn't match the first. Please re-type."
            );
          }
          if (
            !webEmail ||
            webEmail === "" ||
            webEmail.length < 2 ||
            !helpers.emailIsValid(webEmail)
          ) {
            warnings.push("Please enter a valid email address.");
          }
          const isValid = warnings.length === 0;
          if (isValid) {
            api
              .post("web/register", {
                WebFirstName: webFirstName,
                WebLastName: webLastName,
                WebEmail: webEmail,
                Password: newPassword,
                Number: parseInt(existingAccountNo, 10),
              })
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    messageFlavor: "success",
                    message: "Registration was Successful!",
                  });
                  this.props.setCurrentCustomer(
                    response.data.customer,
                    response.data.customer.token
                  );
                } else {
                  this.setState({
                    messageFlavor: "danger",
                    message: response.data.message,
                  });
                }
              })
              .catch(helpers.catchHandler);
          }
          this.setState({
            messageFlavor: "warning",
            message: warnings.join(", "),
          });
        })
        .finally(() => this.setState({ registering: false }))
    })
  }

  redirectToScreen() {
    let redirectUrl;
    if (this.props
      && this.props.location
      && this.props.location.state
      && this.props.location.state.successRedirect) {
      redirectUrl = this.props.location.state.successRedirect;
    } else {
      redirectUrl = '/all-carpet';
    }
    api.redirectToSquarespacePage(redirectUrl);
  }

  render() {
    const { redirectTo } = this.state;
    let customer = null;
    let cookieToken = null;
    let cookieCustomer = null;
    try {
      customer = localStorage.getItem('currentCustomer');
      cookieToken = api.getCookie("SQ_TOKEN");
      cookieCustomer = api.getCookie("SQ_CUSTOMER");
    } catch (e) {
      console.log('probably just in development mode', e)
    }
    if (cookieToken && cookieCustomer && customer) {
      this.redirectToScreen();
    }
    return (
      <Container className="squarespaceContainer">
        <Row>
          <Col sm="12" className="text-center">
            <Nav tabs>
              <NavItem className="width50Percent">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === tabs.login
                  })}
                  onClick={() => {
                    this.toggleTab(tabs.login);
                  }}
                >
                  <h4 className="squarespaceText">{tabs.login}</h4>
                </NavLink>
              </NavItem>
              <NavItem className="width50Percent">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === tabs.register
                  })}
                  onClick={() => {
                    this.toggleTab(tabs.register);
                  }}
                >
                  <h4 className="squarespaceText">{tabs.register}</h4>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {this.state.message !== null && (
          <Row className="mb-2">
            <Col>
              <Alert className={this.state.messageFlavor} fade>
                {this.state.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col sm="12">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={tabs.login}>
                <Form id="sign_in_form" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-group">
                    <label>Account #</label>
                    <input
                      className="form-control"
                      autoFocus
                      type="number"
                      id="accountNo"
                      style={{ marginTop: "0" }}
                      value={this.state.accountNo}
                      onChange={this.handleChange.bind(this)}
                      onBlur={this.handlesBlur("accountNo")}
                      placeholder="Enter your account number"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      id="password"
                      style={{ marginTop: "0" }}
                      value={this.state.password}
                      onChange={this.handleChange.bind(this)}
                      onBlur={this.handlesBlur("password")}
                      placeholder="Enter password"
                      autoComplete="current-password"
                    />
                  </div>
                  <div>
                    <button
                      className="squarespaceButton"
                      style={{ marginTop: "0" }}
                      onClick={this.handleSubmit}
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="text-right mt-2">
                    <Link
                      to={{
                        pathname: "/forgot_account_password",
                        state: { email: this.state.email },
                        push: true,
                      }}
                    >
                      Misplaced your password?
                    </Link>
                  </div>
                </Form>
              </TabPane>
              <TabPane tabId={tabs.register}>
                <Form id="register_form">
                  <Row>
                    <Col xs="12" md="6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          autoFocus
                          type="text"
                          id="webFirstName"
                          style={{ marginTop: "0" }}
                          value={this.state.webFirstName}
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("webFirstName")}
                          placeholder="Enter first name"
                          autoComplete="given-name"
                          maxLength="25"
                        />
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          autoFocus
                          type="text"
                          id="webLastName"
                          style={{ marginTop: "0" }}
                          value={this.state.webLastName}
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("webLastName")}
                          placeholder="Enter last name"
                          autoComplete="family-name"
                          maxLength="25"
                        />
                      </div>
                      <div className="form-group">
                        <label>Account #</label>
                        <input
                          className="form-control"
                          type="number"
                          style={{ marginTop: "0" }}
                          value={this.state.existingAccountNo}
                          id="existingAccountNo"
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("existingAccountNo")}
                          placeholder="Enter your account number"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="text"
                          style={{ marginTop: "0" }}
                          id="webEmail"
                          value={this.state.webEmail}
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("webEmail")}
                          placeholder="Enter email"
                          autoComplete="email"
                          maxLength="150"
                        />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className="form-control"
                          type="password"
                          style={{ marginTop: "0" }}
                          value={this.state.newPassword}
                          id="newPassword"
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("newPassword")}
                          placeholder="Enter password"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="form-group">
                        <label>Retype Password</label>
                        <input
                          className="form-control"
                          type="password"
                          style={{ marginTop: "0" }}
                          value={this.state.newPasswordAgain}
                          id="newPasswordAgain"
                          onChange={this.handleChange.bind(this)}
                          onBlur={this.handlesBlur("newPasswordAgain")}
                          placeholder="Enter password again"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <button
                          className="squarespaceButton"
                          style={{ marginTop: "0" }}
                          onClick={this.handleRegister}
                          disabled={this.state.registering}
                        >
                          Register
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }
}
