import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Alert,
  Button,
  Label,
  Input
} from "reactstrap";
import helpers from "../utils/helpers";
import api from "../utils/api";

export default class SqResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const resetToken =
      props.match && props.match.params && props.match.params.resetToken
        ? props.match.params.resetToken
        : null;
    this.state = {
      message: null,
      messageFlavor: null,
      redirect: false,
      resetToken: resetToken,
      password: "",
      password_again: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordAgain = this.handlePasswordAgain.bind(this);
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handlePasswordAgain(event) {
    this.setState({ password_again: event.target.value });
  }

  isFormValid() {
    let warnings = [];
    if (!this.state.password || this.state.password.length < 8) {
      warnings.push(
        "Password is required and must be at least 8 characters long."
      );
    }
    if (this.state.password !== this.state.password_again) {
      warnings.push("The password and password confirmation do not match.");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(" "),
        messageFlavor: "danger",
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  handleSubmit() {
    if (!this.isFormValid()) return;
    api
      .post("web/setAccountPassword", {
        token: this.state.resetToken,
        oldPassword: this.state.password,
        newPassword: this.state.password_again,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            messageFlavor: "success",
            message:
              "You have successfully reset your password. We're now redirecting you to the home screen.",
          });
          setTimeout(() => {
            api.redirectToSquarespacePage("/log-in-sign-up")
          }, 3000);
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    return (
      <Container className="mt-5 squarespaceContainer">
        <Row>
          <Col className="ml-3">
            <Card>
              <CardBody>
                <div>
                  <Link to="/sq/sign_in_or_register">Return to Login Screen</Link>
                </div>
                <div>
                  {this.state.message !== null ? (
                    <Alert className={this.state.messageFlavor}>
                      {this.state.message}
                    </Alert>
                  ) : null}
                </div>
                <div>
                  <form>
                    <Row className="mt-2 mb-2">
                      <Col>
                        <Label className="label"> New Password </Label>
                        <Input
                          type="password"
                          value={this.state.password}
                          onChange={this.handlePassword}
                          placeholder="Password"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label className="label"> Confirm Password </Label>
                        <Input
                          type="password"
                          value={this.state.password_again}
                          onChange={this.handlePasswordAgain}
                          placeholder="Re-Type Password"
                        />
                      </Col>
                    </Row>
                    <Button
                      className="sqLoginButton float-right text-white"
                      onClick={this.handleSubmit}
                    >
                      Confirm Password Reset
                    </Button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
