import React, { Component, Fragment  } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Row,
  Table,
  Container,
  Form,
  Card,
  Alert,
  CardBody,
  Input
} from 'reactstrap';
import api from '../utils/api';
import Select from 'react-select';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link,Redirect } from 'react-router-dom';
import _ from 'lodash';
import helpers from '../utils/helpers';
import { ContactList } from './';

const emptySupplier = {
  id: 0,
  name: '',
  accountNumber: '',
  comment: '',
  supplierGroupId: null,
  payToName: '',
  samplesEmail: '',
  isActive: true, 
  contactTypeId: null,
  firstName: '',
  lastName: '',
  email: '',
  millAgentName: '',
  millAgentPhone: '',
  millAgentEmail: '', 
  supplierPhoneNo: ''
};

const cleanBadIds = (contactList) => {
  // this poorly named function also sets up the data so the API likes it
  return _.map(contactList, function (c) {
    c.id = c.id > 0 ? c.id : 0; // clean any 'new' ids
    c.typeOfContactName = c.typeOfContact.label;
    c.typeOfContact = c.typeOfContact.value;
    c.phoneNumbers = _.map(c.phoneNumbers, function (p) {
      p.id = p.id > 0 ? p.id : 0; // clean any 'new' ids
      p.typeOfPhoneName = p.typeOfPhone.label;
      p.typeOfPhone = p.typeOfPhone.value;
      return p;
    });
    c.addresses = _.map(c.addresses, function (a) {
      a.id = a.id > 0 ? a.id : 0; // clean any 'new' ids
      a.typeOfAddressName = a.typeOfAddress.label;
      a.typeOfAddress = a.typeOfAddress.value;
      return a;
    });
    return c;
  });
};

export default class EditSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      messageFlavor: null,
      message: null,
      isMessageVisible: false,
      editItem: null,
      sortField: 'id',
      sortDir: 'asc',
      filters: [],
      clearValue: false,
      showEdit: false,
      added_count: 0,
      added_order_count: 0,
      editSupplier: Object.assign({}, emptySupplier),
      supplierGroupList: [],
      contactTypeList: [],
      contactAddressTypeList: [],
      contacts: [],
      supplierIdCaptured: false,
      supplier: null,
      isContactEditing: false,
      redirect: false
    };

    this.loadSupplier = this.loadSupplier.bind(this);
    // this.onContactSave = this.onContactSave.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSupplierGroupSelected = this.onSupplierGroupSelected.bind(this);
    this.onIsActive = this.onIsActive.bind(this);
    this.onContactListChanged = this.onContactListChanged.bind(this);
    this.toggleContactEditing = this.toggleContactEditing.bind(this);
  }

  componentDidMount() {
    const getSupplierGroups = api.fetch("Reference/GetSupplierGroupList")
      .then((response) => {
        return { supplierGroupList: response.data };
      })
      .catch(helpers.catchHandler);

    Promise.all([
      getSupplierGroups
    ])
      .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus, () =>
          this.loadSupplier());
      })
      .catch(helpers.catchHandler);
  }

  loadSupplier(newSupplierId) {
    let supplierId = newSupplierId ? newSupplierId : this.props.computedMatch.params.id;
    if (supplierId === "new") {
      this.setState({ supplier: Object.assign({}, emptySupplier) });
    } else if (supplierId > 0) {
      api.fetch(`Supplier/GetSupplier/${supplierId}`)
        .then(response => {
          if (response.data) {
            let supplier = response.data.supplier;
            supplier.supplierGroup = helpers.resolveValue(null, supplier.supplierGroupId, supplier.supplierGroupName);
            supplier.isActive = !supplier.deactivatedAt;
            const contactList = _.map(_.filter(response.data.contacts, c => !c.deactivatedAt),
              contact => {
                return {
                  addresses: _.map(contact.addresses,
                    address => {
                      return {
                        city: address.city,
                        id: address.id,
                        isPrimary: address.isPrimary,
                        ln1: address.ln1,
                        ln2: address.ln2,
                        ln3: address.ln3,
                        ln4: address.ln4,
                        state: address.state,
                        typeOfAddress: {
                          label: address.typeOfAddressName,
                          value: address.typeOfAddress
                        },
                        zip: address.zip,
                        deactivatedAt: address.deactivatedAt
                      };
                    }),
                  email: contact.email,
                  firstName: contact.firstName,
                  id: contact.id,
                  isPrimary: contact.isPrimary,
                  lastName: contact.lastName,
                  phoneNumbers: _.map(contact.phoneNumbers,
                    phone => {
                      return {
                        id: phone.id,
                        number: phone.number,
                        typeOfPhone: {
                          label: phone.typeOfPhoneName,
                          value: phone.typeOfPhone
                        },
                        deactivatedAt: phone.deactivatedAt,
                        isPrimary: phone.isPrimary
                      };
                    }),
                  typeOfContact: { label: contact.typeOfContactName, value: contact.typeOfContact },
                  deactivatedAt: contact.deactivatedAt
                };
              });
            this.setState({
              supplier: supplier,
              contacts: contactList
            })
          }
        }).catch(helpers.catchHandler);
    }
  }

  onTextChange(fieldName, e) {
    const supplier = this.state.supplier;
    supplier[fieldName] = e.target.value;
    this.setState({
      supplier: supplier
    });
  }

  onSupplierGroupSelected(newSupplierGroup) {
    this.setState({
      supplier: {
        ...this.state.supplier,
        supplierGroup: newSupplierGroup
      }
    });
  }

  onIsActive() {
    this.setState({
      supplier: {
        ...this.state.supplier,
        isActive: !this.state.supplier.isActive
      }
    });
  }

  onContactListChanged(contactList) {
    this.setState({
      contacts: contactList
    });
  }

  toggleContactEditing(isEditing) {
    this.setState({
      isContactEditing: isEditing
    });
  }

  isFormValid() {
    const warnings = [];
    const supplier = Object.assign({}, this.state.supplier);
    if (!supplier.name) {
      warnings.push("Supplier Name is required.");
    }
    if (!supplier.payToName) {
      warnings.push("Pay To Name is required.");
    }
    if (supplier.id === 0 && !supplier.millAgentName) {
      warnings.push("Supplier Agent Name is required.");
    }
    if (supplier.id === 0 && (!supplier.millAgentEmail || !helpers.emailIsValid(supplier.millAgentEmail))) {
      warnings.push("Valid Supplier Agent Email is required.");
    }
    if (supplier.id === 0 && !supplier.millAgentPhone) {
      warnings.push("Supplier Agent Phone Number is required.");
    }
    //if (supplier.id === 0 && contactList.length === 0) {
    //  warnings.push("Contact information is required");
    //}
    //if (supplier.id === 0 && contactList.length > 0){
    //  let hasMillRep = _.find(contactList, l => {
    //    return l.typeOfContact.label === "Mill Representative"
    //  }) !== undefined;
    //  if (supplier.id === 0 && !hasMillRep) {
    //    warnings.push("Mill Representative contact is required")
    //  }
    //  let hasCustomerServiceRep = _.find(contactList, l => {
    //    return l.typeOfContact.label === "Customer Service"
    //  }) !== undefined;
    //  if (supplier.id === 0 && !hasCustomerServiceRep) {
    //    warnings.push("Customer Service contact is required")
    //  }
    //}
    if (warnings.length) {
      this.setState({
        message: warnings.join(' '),
        messageFlavor: "danger",
        isMessageVisible: true
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  onSubmit(event) {
    if (!this.isFormValid()) return;
    const exorcisedContacts = cleanBadIds(this.state.contacts.slice());
    event.preventDefault();

    let supplierDto = {
      Name: this.state.supplier.name,
      AccountNumber: this.state.supplier.accountNumber,
      Comment: this.state.supplier.comment,
      SupplierGroupId: this.state.supplier.supplierGroupId
        ? this.state.supplier.supplierGroupId
        : _.find(this.state.supplierGroupList, g => { return g.label === "Default"; }).value,
      PayToName: this.state.supplier.payToName,
      SamplesEmail: this.state.supplier.samplesEmail,
      IsHidden: this.state.supplier.isActive, //terrible name needs to change 
      SupplierId: this.state.supplier.id,
      ContactTypeId: this.state.supplier.contactType,
      FirstName: this.state.supplier.firstName,
      LastName: this.state.supplier.lastName,
      Email: this.state.supplier.email,
      MillAgentEmail: this.state.supplier.millAgentEmail,
      MillAgentName: this.state.supplier.millAgentName,
      MillagentPhone: this.state.supplier.millAgentPhone,
      SupplierPhoneNo: this.state.supplier.supplierPhoneNo
    };
    if (this.state.supplier.id > 0) {
      supplierDto.Id = this.state.supplier.id;
    }
    api.upload('post', 'Supplier', { Supplier: supplierDto, Contacts: exorcisedContacts })
      .then(response => {
        if (response.data.success) {
          if (this.state.supplier.id > 0) {
            this.setState({
              messageFlavor: "success",
              message: "Supplier was updated",
              //supplier: emptySupplier,
              //editSupplier: emptySupplier,
              showEdit: false
            }, () => this.loadSupplier());
          } else {
            this.setState({ messageFlavor: "success", message: "Supplier was saved. You will be redirected momentarily.", isMessageVisible: true },
              () => {
                setTimeout(() => {
                  this.setState({ redirect: true })
                }, 3000);
              });
          }
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
            isMessageVisible: true
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleDelete(id) {
    api.delete(`Supplier/${id}`)
      .then(response => {
        if (response.data.success) {
          this.loadSupplier()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleUnDelete(id) {
    api.post(`Supplier/undelete/${id}`)
      .then(response => {
        if (response.data.success) {
          this.loadSupplier()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirect) { return <Redirect to='/suppliers' /> }
    const s = this.state.supplier;
    const GroupedButtons = () => {
      return (
        <ButtonGroup className="float-right">
          <Button
            className="btn primary buttonGroupSpacing"
            size="sm"
            onClick={this.onSubmit}
            disabled={this.state.isContactEditing}
          >
            <FontAwesomeIcon icon="save" /> Save Supplier
          </Button>
          <Button
            className="btn secondary buttonGroupSpacing"
            size="sm"
            onClick={this.onCancel}
            tag={Link}
            to={`/suppliers`}
          >
            <FontAwesomeIcon icon="times-circle" /> Cancel
          </Button>
        </ButtonGroup>
    )}
    return (
      <Container fluid className="p-2">
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
              <Row>
                <Col>
                  {this.state.message !== null && (
                    <Row className="mb-2">
                      <Col>
                        <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                      </Col>
                    </Row>
                  )}
                  {s && 
                    <Fragment>
                      <Row className="row-spacing">
                        <Col>
                          <h4>{s && s.id ? 'Edit' : 'Add New'} Supplier</h4>
                    </Col>
                    <Col>{GroupedButtons()}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col >
                          <Form>
                            <Row className="mb-2">
                              <Col sm="4">Supplier Name {helpers.requiredStar()}
                                <Input
                                  name="name"
                                  onChange={this.onTextChange.bind(this, 'name')}
                                  value={s.name}
                                  // placeholder="Enter Supplier Name"
                                  maxLength="50"
                                />
                              </Col>
                              <Col sm="4">Pay To Name {helpers.requiredStar()}
                                <Input
                                  name="payToName"
                                  value={s.payToName}
                                  onChange={this.onTextChange.bind(this, 'payToName')}
                                  // placeholder="Enter Pay To Name"
                                  maxLength="100"
                                />
                              </Col>

                              <Col sm="4">Account Number
                                <Input
                                  name="accountNumber"
                                  onChange={this.onTextChange.bind(this, 'accountNumber')}
                                  value={s.accountNumber}
                                  // placeholder="Enter Account #"
                                  maxLength="20"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              {/*<Col sm="6">Supplier Group {helpers.requiredStar()}
                                  <Select
                                      closeMenuOnSelect
                                      value={s.supplierGroup ? s.supplierGroup : s.supplierGroupId}
                                      isMulti={false}
                                      options={this.state.supplierGroupList}
                                      onChange={this.onSupplierGroupSelected}
                                      placeholder="Select a Group"
                                  />
                              </Col>*/}
                              <Col>Supplier Agent Name {this.state.supplier.id === 0 ? helpers.requiredStar() : null}
                                <Input
                                  name="millAgentName"
                                  onChange={this.onTextChange.bind(this, 'millAgentName')}
                                  value={s.millAgentName || ""}
                                  // placeholder="Enter Supplier Agent's Name"
                                  maxLength="60"
                                />
                              </Col>
                              <Col>Supplier Agent Email  {this.state.supplier.id === 0 ? helpers.requiredStar() : null}
                                <Input
                                  name="millAgentEmail"
                                  onChange={this.onTextChange.bind(this, 'millAgentEmail')}
                                  value={s.millAgentEmail || ""}
                                  // placeholder="Enter Supplier Agent's Email"
                                  maxLength="50"
                                />
                              </Col>
                              <Col>Supplier Agent Phone  {this.state.supplier.id === 0 ? helpers.requiredStar() : null}
                                    <Input
                                  name="millAgentPhone"
                                  onChange={this.onTextChange.bind(this, 'millAgentPhone')}
                                  value={s.millAgentPhone}
                                  // placeholder="Enter Supplier Agent's Phone"
                                  maxLength="20"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>Supplier Phone    
                                  <Input
                                    name="supplierPhoneNo"
                                    onChange={this.onTextChange.bind(this, 'supplierPhoneNo')}
                                    value={s.supplierPhoneNo}
                                    // placeholder="Enter Supplier Agent's Phone"
                                    maxLength="20"
                                  />
                              </Col>
                              <Col>Samples Email
                                <Input
                                  name="samplesEmail"
                                  onChange={this.onTextChange.bind(this, 'samplesEmail')}
                                  value={s.samplesEmail}
                                  // placeholder="Enter Samples Email"
                                  maxLength="150"
                                />
                              </Col>
                              <Col>Active? {helpers.requiredStar()}
                                <div>
                                  <Switch
                                      className="ml-2 p-0 m-0"
                                      onChange={() => this.onIsActive()}
                                      checked={s.isActive}
                                    />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-2">
                              <Col>Comments
                                <Input
                                  type="textarea"
                                  name="comment"
                                  onChange={this.onTextChange.bind(this, 'comment')}
                                  value={s.comment}
                                  placeholder="Enter A Comment"
                                  maxLength="300"
                                />
                              </Col>
                            </Row>
                          </Form>
                          {this.state.contacts && (
                            <Fragment>
                              <ContactList
                                removeContactOnDeactivate
                                contactList={this.state.contacts}
                                onListChanged={this.onContactListChanged}
                                toggleContactEditing={this.toggleContactEditing}
                                supplierGroupList={this.state.supplierGroupList}
                                apiPath={"Supplier/"}
                              />
                              {GroupedButtons()}
                            </Fragment>
                          )}     
                        </Col>
                      </Row>
                    </Fragment>
                  }
                </Col>
              </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
