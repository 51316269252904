import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Label,
  ButtonGroup,
  Button,
  Alert as Alert2,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
} from "reactstrap";
import Alert from "react-s-alert";
import { api, helpers, constants, date_helpers, calc } from "../utils";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import moment from "moment";

const EDGE_TYPES = {
  MITERED: "Hidden Stitch Mitered Corners",
  MACHINED: "Applied Machine Border",
};
const FINISHED_RUG_SIZES = constants.FINISHED_RUG_SIZES;

const COMPONENT_ORDER_LINE_TYPES = [
  constants.ORDER_LINE_TYPE.RUG_FIELD.value,
  constants.ORDER_LINE_TYPE.RUG_BORDER.value,
  constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
];

const rugComponentTypeList = [
  constants.ORDER_LINE_TYPE.RUG_FIELD,
  constants.ORDER_LINE_TYPE.RUG_BORDER,
  constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS,
];

const {
  RUG_BACKING_PRICE_PER,
  RUG_BACKING_COST_PER,
  PAD_CUT_COST,
  PAD_CUT_PRICE,
} = constants.CUSTOM_RUG_DEFAULT_PRICES;

const emptyOrder = {
  id: null,
  orderNumber: null,
  orderStatus: {
    // default value
    value: constants.ORDER_STATUS.OPEN.value,
    label: constants.ORDER_STATUS.OPEN.label,
  },
  mth: new Date(),
  isDistinctiveOrder: true,
  createdAt: new Date(),
  placedBy: "",
  customer: null,
  agent: null,
  isReplacementOrder: false,
  isCreditOrder: false,
  referencesOrder: "",
  // billing info
  btContactFirstName: "",
  btContactLastName: "",
  btContactEmail: "",
  btAddressLine1: "",
  btAddressLine2: "",
  btAddressLine3: "",
  btAddressLine4: "",
  btCity: "",
  btState: "",
  btZip: "",
  btPhone: "",
  btFax: "",
  invoiceTerms: null,
  customerPONumber: "",
  relatedReplacementOrders: "",
};

const emptyOrderLine = {
  id: 0,
  orderId: null,
  parentOrderLineId: null,
  orderLineType: {
    // default value
    value: constants.ORDER_LINE_TYPE.CARPET.value,
    label: constants.ORDER_LINE_TYPE.CARPET.label,
  },
  dsc: "",
  unitCost: "",
  cost: "",
  unitPrice: "",
  price: "",
  cmsnRate: "10.00",
  cmsn: "",
  isHidden: false,
  product: null,
  productColor: null,
  width: null,
  lengthFeet: "",
  orderLineStatus: {
    // default value
    value: constants.ORDER_LINE_STATUS.NA.value,
    label: constants.ORDER_LINE_STATUS.NA.label,
  },
  priceUnitType: "Sq Yds",
  squareFeetRequired: "",
  creditCardSurchargeRate: "0",
  taxRate: "0", // not nullable in db
  rugSize: "",
  finishedSize: { value: 1, label: "Custom" },
  rugLengthFeet: "0",
  rugLengthInches: "0",
  rugWidthFeet: "8",
  rugWidthInches: "0",
  rugType: null,
  rugIntlCollection: null,
  rugIntlSize: null,
  qty: "",
  cartonCount: "",
  squareFeetPerCarton: "", // does not belong to orderLine schema, but set for wood and carpet tile product calculations
  // freight:
  freightCarrier: null,
  freightSidemark: "",
  freightInvoiceNumber: "",
  freightBolNumber: "",
  freightDsc: "Freight Charges",
  freightTerms: null,
  freightCost: "0.00",
  freightPrice: "0.00",
  stContactFirstName: "",
  stContactLastName: "",
  stContactEmail: "",
  stAddressLine1: "",
  stAddressLine2: "",
  stAddressLine3: "",
  stAddressLine4: "",
  stCity: "",
  stState: "",
  stZip: "",
  stPhone: "",
  createdAt: null,
  createdByUserId: null,
  width: { widthNumericFeet: 0 },
  commentCount: 0, // for temporary use only
  productAllowCommission: null,

  // custom rug component specific
  rugWidthFeet: "8",
  rugWidthInches: "0",
  rugLengthFeet: "10",
  rugLengthInches: "0",
  rugDsc: "",
  sqFt: "0.0",
  finishedEdgeFt: "0.0",
  selectedField: null,
  appliedFieldRepeat: "0.0",
  selectedFieldColor: null,
  selectedFieldWidth: null,
  fieldWidthList: [],
  bindingList: [],
  selectedBinding: null,
  fieldRepeatWidth: "0.0",
  fieldRepeatLength: "0.0",
  repeatDsc: "",
  fieldLengthFeet: "0",
  fieldLengthInches: "0",
  fieldCostPer: "0.0",
  totalFieldCost: "0.00",
  fieldPricePer: "0.00",
  totalFieldUnits: "0.0",
  totalFieldPrice: "0.00",
  fieldCmsnRate: "10.00",
  fieldCmsn: "0.00",
  fieldPriceUnitType: "",
  selectedBorder: null,
  selectedBorderColor: null,
  selectedBorderWidth: null,
  borderWidthList: [],
  bindingList: [],
  borderLengthFeet: "",
  borderLengthInches: "",
  borderCostPer: "0.0",
  totalBorderCost: "0.00",
  borderPricePer: "0.00",
  totalBorderUnits: "0.0",
  totalBorderPrice: "0.00",
  borderCmsnRate: "10.00",
  borderCmsn: "0.00",
  borderPriceUnitType: "",
};

const emptyOrderLineComment = {
  id: 0,
  dsc: "",
  commentType: null,
  isPrivate: false,
  markedForDeletion: false,
};

const emptyRugOrderLine = (customRugComponentProducts) => {
  return {
    // for custom rug quotes only (from angular app)
    ...emptyOrderLine,
    rugEditsValid: false,
    rugHasClippedCorners: false,
    rugClippedCornersCost:
      customRugComponentProducts.DefaultClippedCornersProduct
        ? _.round(
            customRugComponentProducts.DefaultClippedCornersProduct.unitCost,
            2
          ).toFixed(2)
        : "5.00",
    rugClippedCornersPrice:
      customRugComponentProducts.DefaultClippedCornersProduct
        ? _.round(
            customRugComponentProducts.DefaultClippedCornersProduct.unitPrice,
            2
          ).toFixed(2)
        : "15.00",
    rugHasLaborCutout: false,
    rugLaborCutoutCost: customRugComponentProducts.DefaultLaborCutoutProduct
      ? _.round(
          customRugComponentProducts.DefaultLaborCutoutProduct.unitCost,
          2
        ).toFixed(2)
      : "75.00",
    rugLaborCutoutPrice: customRugComponentProducts.DefaultLaborCutoutProduct
      ? _.round(
          customRugComponentProducts.DefaultLaborCutoutProduct.unitPrice,
          2
        ).toFixed(2)
      : "100.00",
    rugHasLaborRound: false,
    rugLaborRoundCost: customRugComponentProducts.DefaultLaborRoundProduct
      ? _.round(
          customRugComponentProducts.DefaultLaborRoundProduct.unitCost,
          2
        ).toFixed(2)
      : "25.00",
    rugLaborRoundPrice: customRugComponentProducts.DefaultLaborRoundProduct
      ? _.round(
          customRugComponentProducts.DefaultLaborRoundProduct.unitPrice,
          2
        ).toFixed(2)
      : "30.00",
    rugHasCutCharge: false,
    rugCutChargeCost: customRugComponentProducts.DefaultCutChargeProduct
      ? _.round(
          customRugComponentProducts.DefaultCutChargeProduct.unitCost,
          2
        ).toFixed(2)
      : "0.00",
    rugCutChargePrice: customRugComponentProducts.DefaultCutChargeProduct
      ? _.round(
          customRugComponentProducts.DefaultCutChargeProduct.unitPrice,
          2
        ).toFixed(2)
      : "25.00",
    rugHasEndArmor: false,
    rugArmorCost: customRugComponentProducts.DefaultRugEndArmorProduct
      ? _.round(
          customRugComponentProducts.DefaultRugEndArmorProduct.unitCost,
          2
        ).toFixed(2)
      : "8.00",
    rugArmorPrice: customRugComponentProducts.DefaultRugEndArmorProduct
      ? _.round(
          customRugComponentProducts.DefaultRugEndArmorProduct.unitPrice,
          2
        ).toFixed(2)
      : "10.00",
    rugHasInboundFreight: false,
    selectedInboundFreight:
      customRugComponentProducts.DefaultInboundFreightProduct
        ? customRugComponentProducts.DefaultInboundFreightProduct
        : null,
    rugInboundFreightCost:
      customRugComponentProducts.DefaultInboundFreightProduct
        ? _.round(
            customRugComponentProducts.DefaultInboundFreightProduct.unitCost,
            2
          ).toFixed(2)
        : "45.00",
    rugInboundFreightPrice:
      customRugComponentProducts.DefaultInboundFreightProduct
        ? _.round(
            customRugComponentProducts.DefaultInboundFreightProduct.unitPrice,
            2
          ).toFixed(2)
        : "45.00",
    rugHasBacking: false,
    rugHasPad: false,
    rugSerged: true,
    rugMachineSerged: true,
    rugHiddenStitch: true,
    rugWidthFeet: "8",
    rugWidthInches: "0",
    rugLengthFeet: "10",
    rugLengthInches: "0",
    rugDsc: "",
    finishedSize: { value: 1, label: "Custom" },
    // selectedRugEdge: "Serging",
    sqFt: "0.0",
    finishedEdgeFt: "0.0",
    orderLines: [],
    selectedField: null,
    searchingProduct: false,
    searchProductFailed: false,
    // hideSearchingProductWhenUnsubscribed: new Observable(() => () => this.searchingProduct = false),
    appliedFieldRepeat: "0.0",
    selectedFieldColor: null,
    selectedFieldWidth: null,
    fieldWidthList: [],
    bindingList: [],
    selectedBinding: null,
    fieldRepeatWidth: "0.0",
    fieldRepeatLength: "0.0",
    repeatDsc: "",
    fieldLengthFeet: "0.0",
    fieldLengthInches: "0.0",
    fieldCostPer: "0.0",
    totalFieldCost: "0.00",
    fieldPricePer: "0.00",
    totalFieldUnits: "0.0",
    totalFieldPrice: "0.00",
    fieldCmsnRate: "10.00",
    fieldCmsn: "0.00",
    fieldPriceUnitType: "",
    // fieldDsc: "",

    totalRugBackingPrice: "0.00",
    rugBackingPricePer: customRugComponentProducts.DefaultBackingProduct
      ? _.round(
          customRugComponentProducts.DefaultBackingProduct.unitPrice,
          2
        ).toFixed(2)
      : RUG_BACKING_PRICE_PER,
    totalRugBackingUnits: "0.0",
    totalRugBackingCost: "0.00",
    rugBackingCostPer: customRugComponentProducts.DefaultBackingProduct
      ? _.round(
          customRugComponentProducts.DefaultBackingProduct.unitCost,
          2
        ).toFixed(2)
      : RUG_BACKING_COST_PER,
    rugBackingCmsn: "0.00",
    rugBackingCmsnRate: "10.00",

    selectedPad:
      customRugComponentProducts.DefaultRugPadLuxFeltCustomSizeProduct
        ? customRugComponentProducts.DefaultRugPadLuxFeltCustomSizeProduct
        : null,
    padList: [],
    hasPadCutCharge: true,
    padCutCost: customRugComponentProducts.DefaultPadCutChargeProduct
      ? _.round(
          customRugComponentProducts.DefaultPadCutChargeProduct.unitCost,
          2
        ).toFixed(2)
      : PAD_CUT_COST,
    padCutPrice: customRugComponentProducts.DefaultPadCutChargeProduct
      ? _.round(
          customRugComponentProducts.DefaultPadCutChargeProduct.unitPrice,
          2
        ).toFixed(2)
      : PAD_CUT_PRICE,
    selectedPadWidth: null,
    padWidthList: [],
    totalPadUnits: "0.0",
    padLengthFeet: "0.0",
    padCostPer: "0.00",
    totalPadCost: "0.00",
    padPricePer: "0.00",
    totalPadPrice: "0.00",
    padCmsnRate: "10.00",
    padCmsn: "0.00",
    padPriceUnitType: "",

    selectedSerging: null,

    sergingCostPer: "0.00",
    totalSergingCost: "0.00",
    sergingPricePer: "0.00",
    totalSergingPrice: "0.00",
    sergingCmsnRate: "10.00",
    sergingCmsn: "0.00",

    selectedMSerging: null,
    mSergingCostPer: "0.00",
    totalMSergingCost: "0.00",
    mSergingPricePer: "0.00",
    totalMSergingPrice: "0.00",
    mSergingCmsnRate: "10.00",
    mSergingCmsn: "0.00",

    selectedHSSerging: null,
    hsSergingCostPer: "0.00",
    totalHSSergingCost: "0.00",
    hsSergingPricePer: "0.00",
    totalHSSergingPrice: "0.00",
    hsSergingCmsnRate: "10.00",
    hsSergingCmsn: "0.00",

    selectedRugBindingStyle: null,
    rugBindingList: [],
    selectedRugBindingColor: null,
    rugBindingColorList: [],
    rugBindingCostPer: "0.00",
    totalRugBindingCost: "0.00",
    rugBindingPricePer: "0.00",
    totalRugBindingPrice: "0.00",
    rugBindingCmsnRate: "10.00",
    rugBindingCmsn: "0.00",
  };
};

const emptyOrderPayment = {
  id: null,
  paymentType: {
    // default value
    value: constants.PAYMENT_TYPE.EXTENDED_CREDIT.value,
    label: constants.PAYMENT_TYPE.EXTENDED_CREDIT.label,
  },
  customerPaymentStatus: null,
  referenceNumber: "",
  payToName: "",
  checkNumber: "",
  amountDue: "", // remove this not nullable field eventually?
  amountPaid: "",
  billOn: "",
  dueOn: "",
  paidOn: "",
  forWhat: "", // remove this not nullable field eventually?
  invoiceNumber: "",
  comment: "",
  dateReceived: new Date(),
  expDate: "",
  cardNumber: "",
  billingName: "",
  billingZip: "",
  creditCardType: null,
  creditCardSurchargeRate: "",
  taxRate: "",
};

const emptyOrderFulfillment = {
  id: 0,
  orderFulfillmentStatus: null,
  supplier: null,
  freightCarrier: null,
  referenceNumber: "",
  invoiceNumber: "",
  checkNumber: "",
  amountDue: "",
  amountPosted: "0",
  notes: "",
  billDate: new Date(),
  dueDate: new Date(),
  payDate: new Date(),
  isPayToSupplier: true,
};

const invoiceOptions = _.map(constants.TYPE_OF_INVOICE, (v, k) => {
  return { label: k, value: v };
});

// const CUSTOM_DSC =
// 	`Please inspect this merchandise for style, size and color to insure you have received proper product.
// 	Mill will not honor any complaints on visible defects after merchandise has been cut or installed. By
// 	accepting shipment of this material the bill to agrees to pay all attorney, court, and collection fees if
// 	placed for collections on this invoice. Freight Claims: Title of Carpet transfers to the purchaser when
// 	accepted by Freight carrier. You have 14 days to report concealed Damage. ALL CARPETS MUST BE
// 	PROFESSIONALLY CLEANED EVERY 18 MONTHS TO MAINTAIN YOUR WARRANTY! IF YOU USE A
// 	CYCLONIC ACTION VACUUM ON ANY OF OUR CARPETS IT COULD VOID YOUR WARRANTY.`;

const CUSTOM_DSC2 = ``;

let CustomRugProducts;

const TABS = {
  GENERAL_INFORMATION: 1,
  LINE_ITEMS: 2,
  PAYMENT: 3,
  FULLFILLMENT: 4,
  FINAL_REVIEW: 5,
  PRINT: 6,
};

export default class OrderEntry extends Component {
  constructor(props) {
    const date = new Date();

    super(props);
    this.state = {
      order: {
        ...emptyOrder,
        createdByUser: {
          value: props.customerId,
          label: "",
        },
      },
      customerId: props.customerId,
      rugShapeList: [],
      message: null,
      messageFlavor: null,
      monthList: constants.monthList,
      yearList: constants.yearList,
      orderStatusList: [],
      userList: [],
      agentList: [],
      productsList: [],
      // collectionProductsList: [], // used for Intl Rug order lines
      widthsList: [],
      freightCarrierList: [],
      commentTypeList: [],
      freightTermsList: [],
      customerPaymentStatusList: [],
      credtiCardTypeList: [],
      orderFulfillmentStatusList: [],
      supplierList: [],
      totalOrderCost: "",
      totalOrderPrice: "",
      orderLines: [],
      orderFulfillments: [],
      orderPayments: [],
      verifications: [],
      orderId: props.orderId,
      orderFulfillment: emptyOrderFulfillment,
      addNewFulfillment: false,
      orderPayment: emptyOrderPayment,
      paymentTypeList: [],
      selectedCustomer: [],
      creditCardTypeList: [],
      sumOfPayments: "",
      balance: "",
      orderLine: emptyOrderLine,
      newOrderLineComment: emptyOrderLineComment,
      orderLineCommentList: [],
      showOrderLineForm: false,
      orderLineCount: 0,
      editingFreight: false,
      editingNotes: false,
      notesList: null,
      orderLineTypeList: [],
      orderLineStatusList: [],
      colorFamilyList: [],
      areaList: [],
      addUpdatePayment: false,
      orderFulfillmentsTotalDue: "",
      orderFulfillmentsTotalPosted: "",
      orderFulfillmentsBalance: "",
      customRugToViewEdit: null,
      confirmationModalOrderLineId: null,
      confirmationModalType: null,
      confirmationModalComponentLines: null,
      showCustomerSearchModal: false,
      colorOptOut: false,
      generatingReplacement: false,
      showOrderLineStatusModal: false,
      backOrderOrShippedDate: null,
      hasSalesTaxOrSurcharge: false,
      // rug related
      selectedRugEdge: "Serging",
      sergingList: [],
      mSergingList: [],
      hsSergingList: [],
      invoiceDate: date,
      dueDate: date,
      customDescription: "",
      customDescription2: CUSTOM_DSC2,
      invoiceType: invoiceOptions[0],
      customerAddressList: [],
      customerShipToAddresses: [],
      productPatternMatch: ""
    };
    this.getCustomer = this.getCustomer.bind(this);
    this.setSelectedBillingAddress = this.setSelectedBillingAddress.bind(this);
    this.transformItemToRugComponentOrderLine =
      this.transformItemToRugComponentOrderLine.bind(this);

    // rug component stuff
    this.toggleCutCharge = this.toggleCutCharge.bind(this);
    this.toggleArmor = this.toggleArmor.bind(this);
    this.toggleInboundFreight = this.toggleInboundFreight.bind(this);
    this.toggleBacking = this.toggleBacking.bind(this);
    this.togglePad = this.togglePad.bind(this);
    this.toggleClippedCorners = this.toggleClippedCorners.bind(this);
    this.toggleLaborCutout = this.toggleLaborCutout.bind(this);
    this.toggleLaborRound = this.toggleLaborRound.bind(this);
    this.toggleEdgeOptions = this.toggleEdgeOptions.bind(this);
    this.initializeRugOrder = this.initializeRugOrder.bind(this);
    this.refreshRugDescription = this.refreshRugDescription.bind(this);
    this.rugSizeChange = this.rugSizeChange.bind(this);
    this.fieldSizeChange = this.fieldSizeChange.bind(this);
    this.setRugEdge = this.setRugEdge.bind(this);
    this.onHasPadCutChargeChange = this.onHasPadCutChargeChange.bind(this);
    this.borderSizeChange = this.borderSizeChange.bind(this);

    this.applyVolumePricingIfNecessary =
      this.applyVolumePricingIfNecessary.bind(this);
    this.calculateFigures = this.calculateFigures.bind(this);
    this.renderWorkorderForm = this.renderWorkorderForm.bind(this);
  }

  async componentDidMount() {
    this.getOrderSelectsData();
    let CUSTOM_DSC = "";
    await api
      .fetch("Order/GetInvoiceBoilerPlate")
      .then((response) => {
        _.forEach(response.data, (x) => {
          CUSTOM_DSC = CUSTOM_DSC + x.dsc + " ";
        });
        this.setState({ customDescription: CUSTOM_DSC });
      })
      .catch(helpers.catchHandler);
    this.getCustomer(this.state.customerId);
    await api
      .fetch(
        `Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.CARPET.value}`
      )
      .then((response) => {
        this.setState({productsList: response.data});
      });
  }

  getOrderSelectsData() {
    api
      .fetch("Order/GetOrderSelectsData")
      .then((response) => {
        CustomRugProducts = response.data.customRugProducts;
        this.setState({
          orderStatusTypeList: response.data.orderStatusTypeList,
          orderLineTypeList: this.formatOrderLineTypeList(
            response.data.orderLineTypeList
          ),
          orderLineStatusList: response.data.orderLineStatusList,
          paymentTypeList: response.data.paymentTypeList,
          colorFamilyList: response.data.colorFamilyList,
          agentList: response.data.agentList,
          areaList: response.data.areaList,
          orderStatusList: response.data.orderStatusList,
          userList: response.data.userList,
          freightCarrierList: response.data.freightCarrierList,
          commentTypeList: response.data.commentTypeList,
          freightTermsList: response.data.freightTermsList,
          customerPaymentStatusList: response.data.customerPaymentStatusList,
          creditCardTypeList: response.data.creditCardTypeList,
          orderFulfillmentStatusList: response.data.orderFulfillmentStatusList,
          supplierList: response.data.supplierList,
          rugShapeList: response.data.rugShapeList,
        });
      })
      .catch(helpers.catchHandler);
  }

  async getCustomer(customerId) {
    api
      .fetch(`Customer/GetActiveContacts/${customerId}`)
      .then((response) => {
        let customerInfo = response.data.customer;
        let customerContacts = response.data.contacts;
        let customerAddresses = _.chain(response.data.customerAddresses)
          .filter((ca) => {
            return ca.addressTypeId === constants.ADDRESS_TYPE.BILL_TO;
          })
          .map((a) => {
            a.value = a.id;
            a.label = helpers.formatAddressLabel(a);
            return a;
          })
          .value();
        const customerShipToAddresses = _.chain(response.data.customerAddresses)
          .filter((ca) => {
            return ca.addressTypeId === constants.ADDRESS_TYPE.SHIP_TO;
          })
          .map((a) => {
            a.value = a.id;
            a.label = helpers.formatAddressLabel(a, "* ");
            return a;
          })
          .value();
        customerContacts.map((c) => {
          // remove deactivated phones and addresses for each contact
          const addresses = _.filter(
            c.addresses,
            (a) => a.deactivatedAt === null
          );
          const phoneNumbers = _.filter(
            c.phoneNumbers,
            (p) => p.deactivatedAt === null
          );
          c.expanded = false;
          c.addresses = addresses;
          c.phoneNumbers = phoneNumbers;

          // set value and label for select
          c.label = `${c.firstName} ${c.lastName}`;
          c.value = c.id;
          return c;
        });
        let customerCredits = _.map(response.data.customerCredits, (c) => {
          c.value = c.id;
          c.label = `${c.displayAmount} ${c.comment} - Given on ${moment(
            c.givenOn
          ).format("MM/DD/YYYY")}`;
          // c.amount = c.amount !== 0 && c.amount !== '' ? c.amount / 100 : null;
          c.amount = c.amount;
          return c;
        });
        this.setState(
          {
            customerInfo: customerInfo,
            customerContacts: customerContacts,
            customerOutstandingCredits: customerCredits,
            customerAddressList: customerAddresses,
            customerShipToAddresses: customerShipToAddresses,
          },
          () => {
            if (customerAddresses.length == 1) {
              this.setSelectedBillingAddress(customerAddresses[0]);
            }
          }
        );
      })
      .catch(helpers.catchHandler);
  }

  formatOrderLineTypeList(orderLineTypeList) {
    const formattedOrderLineTypeList = _.reject(orderLineTypeList, (item) => {
      return (
        item.value === constants.ORDER_LINE_TYPE.FREIGHT.value ||
        item.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value ||
        item.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value ||
        item.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value ||
        item.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value ||
        item.value === constants.ORDER_LINE_TYPE.MISCELLANEOUS.value ||
        item.value === constants.ORDER_LINE_TYPE.SALES_TAX.value || 
        item.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value ||
        item.value === constants.ORDER_LINE_TYPE.SAMPLE.value
      );
    });
    return formattedOrderLineTypeList;
  }

  getOrderLineCorrespondingProductType(value) {
    let productType;
    switch (value.toString()) {
      case constants.ORDER_LINE_TYPE.CARPET.value.toString():
      case constants.ORDER_LINE_TYPE.RUG_FIELD.value.toString():
      case constants.ORDER_LINE_TYPE.RUG_BORDER.value.toString():
        productType = constants.PRODUCT_TYPE.CARPET.value;
        break;
      case constants.ORDER_LINE_TYPE.MISCELLANEOUS.value.toString():
        productType = constants.PRODUCT_TYPE.MISCELLANEOUS.value;
        break;
      case constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value.toString():
        productType = constants.PRODUCT_TYPE.RUG_COMPONENT.value;
        break;
      case constants.ORDER_LINE_TYPE.RUG_PAD.value.toString():
        productType = constants.PRODUCT_TYPE.RUG_PAD.value;
        break;
      case constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value.toString():
        productType = constants.PRODUCT_TYPE.INTL_RUG.value;
        break;
      case constants.ORDER_LINE_TYPE.HARD_SURFACE.value.toString():
        productType = constants.PRODUCT_TYPE.HARD_SURFACE.value;
        break;
      case constants.ORDER_LINE_TYPE.ROLL_RUNNER.value.toString():
        productType = constants.PRODUCT_TYPE.ROLL_RUNNER.value;
        break;
      case constants.ORDER_LINE_TYPE.BINDING.value.toString():
        productType = constants.PRODUCT_TYPE.BORDER_OR_BINDING.value;
        break;
      case constants.ORDER_LINE_TYPE.CARPET_TILE.value.toString():
        productType = constants.PRODUCT_TYPE.CARPET_TILE.value;
        break;
      case constants.ORDER_LINE_TYPE.SAMPLE.value.toString():
        productType = constants.PRODUCT_TYPE.SAMPLE.value;
        break;
    }
    return productType;
  }

  onAddNewOrderLine(orderLineType = null) {
    let productType;
    if (orderLineType) {
      productType = this.getOrderLineCorrespondingProductType(
        orderLineType.value
      );
    } else {
      productType = constants.PRODUCT_TYPE.CARPET.value;
    }

    // Fetch products for default new rug component line type first
    // api.fetch(`Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.CARPET.value}`)
    api
      .fetch(`Order/GetProductsOfProductType/${productType}`)
      .then((response) => {
        //if response success
        if (this.state.customRugToViewEdit) {
          let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
            this.state.customRugToViewEdit.rugLengthFeet,
            this.state.customRugToViewEdit.rugLengthInches,
            this.state.customRugToViewEdit.rugWidthFeet,
            this.state.customRugToViewEdit.rugWidthInches
          );

          this.setState({
            showOrderLineForm: true,
            message: null,
            messageFlavor: null,
            productsList: response.data,
            orderLine: {
              ...emptyOrderLine,
              parentOrderLineId: this.state.customRugToViewEdit.id,
              orderLineType: orderLineType
                ? orderLineType
                : constants.ORDER_LINE_TYPE.RUG_FIELD,
              sqFt: sqFt,
              finishedEdgeFt: finishedEdgeFt,
              rugLengthFeet: this.state.customRugToViewEdit.rugLengthFeet, // needed for local fuctions to work
              rugLengthInches: this.state.customRugToViewEdit.rugLengthInches, // needed for local fuctions to work
              rugWidthFeet: this.state.customRugToViewEdit.rugWidthFeet, // needed for local fuctions to work
              rugWidthInches: this.state.customRugToViewEdit.rugWidthInches, // needed for local fuctions to work
              rugDsc: this.state.customRugToViewEdit.dsc, // needed for local fuctions to work
              shippingAddresses: this.state.customerShipToAddresses,
            },
          });
        } else {
          this.setState({
            showOrderLineForm: true,
            message: null,
            messageFlavor: null,
            productsList: response.data,
            orderLine: {
              ...emptyOrderLine,
              orderLineType: constants.ORDER_LINE_TYPE.CARPET,
              shippingAddresses: this.state.customerShipToAddresses,
            },
          });
        }
      });
  }

  async handleOrderLineTypeChange(selection) {
    if (
      selection.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value ||
      selection.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value
    ) {
      this.onAddNewOrderLine(selection);
    } else if (selection.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value) {
      let padProductsList;
      let rugBindingList;
      let inboundFreightProductList;
      let customRugComponentProducts;
      await api
        .fetch(
          `Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.RUG_PAD.value}`
        )
        .then((response) => {
          padProductsList = response.data;
        });
      await api
        .fetch(
          `Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.BORDER_OR_BINDING.value}`
        )
        .then((response) => {
          rugBindingList = response.data;
        });
      await api
        .fetch(
          `Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.RUG_COMPONENT.value}`
        )
        .then((response) => {
          inboundFreightProductList = _.filter(response.data, (p) =>
            _.includes(p.label, "Inbound")
          );
        });
      await api
        .fetch(`Order/GetCustomRugComponentProducts/`)
        .then((response) => {
          customRugComponentProducts = {
            DefaultCutChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultCutChargeProduct
            ),
            DefaultRugEndArmorProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultRugEndArmorProduct
            ),
            DefaultInboundFreightProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultInboundFreightProduct
            ),
            DefaultBackingProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultBackingProduct
            ),
            DefaultPadCutChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultPadCutChargeProduct
            ),
            Under30SFPadCutChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.Under30SFPadCutChargeProduct
            ),
            DefaultClippedCornersProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultClippedCornersProduct
            ),
            DefaultLaborCutoutProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultLaborCutoutProduct
            ),
            DefaultLaborRoundProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultLaborRoundProduct
            ),
            DefaultFabricationChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultFabricationChargeProduct
            ),
            DefaultUVSergingChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultUVSergingChargeProduct
            ),
            DefaultWoolSergingChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultWoolSergingChargeProduct
            ),
            DefaultPremiumWoolSergingChargeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultPremiumWoolSergingChargeProduct
            ),
            DefaultWoolSergingChargeNProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultWoolSergingChargeNProduct
            ),
            DefaultAppliedMachineBordersProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultAppliedMachineBordersProduct
            ),
            DefaultMiteredProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES.DefaultMiteredProduct
            ),
            DefaultRugPadLuxFeltCustomSizeProduct: _.find(
              response.data,
              (x) =>
                x.configName ===
                constants.CUSTOM_RUG_CONFIG_NAMES
                  .DefaultRugPadLuxFeltCustomSizeProduct
            ),
          };
        });
      const emptyRugLine = emptyRugOrderLine(customRugComponentProducts);
      const sergingList = [
        customRugComponentProducts.DefaultWoolSergingChargeProduct,
        customRugComponentProducts.DefaultPremiumWoolSergingChargeProduct,
        customRugComponentProducts.DefaultWoolSergingChargeNProduct,
        customRugComponentProducts.DefaultUVSergingChargeProduct,
      ];
      const mSergingList = [
        customRugComponentProducts.DefaultAppliedMachineBordersProduct,
      ];
      const hsSergingList = [customRugComponentProducts.DefaultMiteredProduct];
      this.setState(
        {
          orderLine: {
            ...emptyRugLine,
            orderLineType: selection,
            id: this.state.orderLine.id,
            //freight:
            freightCarrier: this.state.orderLine.freightCarrier,
            freightSidemark: this.state.orderLine.freightSidemark,
            freightInvoiceNumber: this.state.orderLine.freightInvoiceNumber,
            freightBolNumber: this.state.orderLine.freightBolNumber,
            freightDsc: this.state.orderLine.freightDsc,
            freightTerms: this.state.orderLine.freightTerms,
            freightCost: this.state.orderLine.freightCost,
            freightPrice: this.state.orderLine.freightPrice,
            stContactFirstName: this.state.orderLine.stContactFirstName,
            stContactLastName: this.state.orderLine.stContactLastName,
            stContactEmail: this.state.orderLine.stContactEmail,
            stAddressLine1: this.state.orderLine.stAddressLine1,
            stAddressLine2: this.state.orderLine.stAddressLine2,
            stAddressLine3: this.state.orderLine.stAddressLine3,
            stAddressLine4: this.state.orderLine.stAddressLine4,
            stCity: this.state.orderLine.stCity,
            stState: this.state.orderLine.stState,
            stZip: this.state.orderLine.stZip,
            stPhone: this.state.orderLine.stPhone,
          },
          padProductsList: padProductsList,
          rugBindingList: rugBindingList,
          inboundFreightProductList: inboundFreightProductList,
          customRugComponentProducts: customRugComponentProducts,
          sergingList: sergingList,
          mSergingList: mSergingList,
          hsSergingList: hsSergingList,
        },
        () => {
          this.initializeRugOrder();
        }
      );
    } else {
      let productType = this.getOrderLineCorrespondingProductType(
        selection.value
      );
      let productsList = [];
      // let collectionList = [];
      // if (selection.value !== constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
      await api
        .fetch(`Order/GetProductsOfProductType/${productType}`)
        .then((response) => {
          productsList = response.data;
        });
      // } else {
      // 	// For Intl Coll rugs, collection must be selected before product
      // 	await api.fetch("Order/GetRugIntlCollectionList")
      // 		.then(response => {
      // 			collectionList = response.data;
      // 		});
      // }

      this.setState({
        orderLine: {
          ...emptyOrderLine,
          orderLineType: selection,
          id: this.state.orderLine.id,
          //freight:
          freightCarrier: this.state.orderLine.freightCarrier,
          freightSidemark: this.state.orderLine.freightSidemark,
          freightInvoiceNumber: this.state.orderLine.freightInvoiceNumber,
          freightBolNumber: this.state.orderLine.freightBolNumber,
          freightDsc: this.state.orderLine.freightDsc,
          freightTerms: this.state.orderLine.freightTerms,
          freightCost: this.state.orderLine.freightCost,
          freightPrice: this.state.orderLine.freightPrice,
          stContactFirstName: this.state.orderLine.stContactFirstName,
          stContactLastName: this.state.orderLine.stContactLastName,
          stContactEmail: this.state.orderLine.stContactEmail,
          stAddressLine1: this.state.orderLine.stAddressLine1,
          stAddressLine2: this.state.orderLine.stAddressLine2,
          stAddressLine3: this.state.orderLine.stAddressLine3,
          stAddressLine4: this.state.orderLine.stAddressLine4,
          stCity: this.state.orderLine.stCity,
          stState: this.state.orderLine.stState,
          stZip: this.state.orderLine.stZip,
          stPhone: this.state.orderLine.stPhone,
        },
        // rugIntlCollectionList: collectionList,
        productsList: productsList,
      });
    }
  }

  async setSelectedProduct(option) {
    await api
      .fetch(`Order/GetActiveProductColors/${option.value}`)
      .then((response) => {
        this.setState({
          productColorsList: response.data,
          orderLine: {
            ...this.state.orderLine,
            product: option,
            productColor: response.data.length > 0 ? response.data[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    await api
      .fetch(`Product/GetProductWidths/${option.value}`)
      .then((response) => {
        const widths = _.map(response.data, (w) => {
          return {
            value: w.widthId,
            label: w.widthName,
            widthNumericFeet: w.widthNumericFeet,
          };
        });
        this.setState({
          //widthsList: widths,
          orderLine: {
            ...this.state.orderLine,
            widthsList: widths,
            width: widths.length > 0 ? widths[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    if (
      this.state.orderLine.orderLineType.value ===
      constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value
    ) {
      await api
        .fetch(`Product/GetRugIntlSizes/${option.value}`)
        .then((response) => {
          const rugSizes = _.map(response.data, (sz) => {
            return {
              value: sz.id,
              label: sz.dsc,
              cost: _.round(sz.cost, 2).toFixed(2),
              price: _.round(sz.price, 2).toFixed(2),
            };
          });
          this.setState({
            rugIntlSizeList: rugSizes,
            orderLine: {
              ...this.state.orderLine,
              rugIntlSize: rugSizes.length > 0 ? rugSizes[0] : null,
              unitCost:
                rugSizes.length > 0
                  ? rugSizes[0].cost
                  : this.state.orderLine.unitCost,
              unitPrice:
                rugSizes.length > 0
                  ? rugSizes[0].price
                  : this.state.orderLine.unitPrice,
              // unitCost: response.data.length > 0 ? _.round(response.data[0].cost, 2).toFixed(2) : this.state.orderLine.unitCost,
              // unitPrice: response.data.length > 0 ? _.round(response.data[0].price, 2).toFixed(2) : this.state.orderLine.unitPrice,
            },
          });
        })
        .catch(helpers.catchHandler);
    }
    await api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState(
          {
            orderLine: {
              ...this.state.orderLine,
              unitCost:
                this.state.orderLine.orderLineType.value !==
                constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value
                  ? _.round(response.data.unitCost, 2).toFixed(2)
                  : this.state.orderLine.unitCost,
              unitPrice:
                this.state.orderLine.orderLineType.value !==
                constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value
                  ? _.round(response.data.unitPrice, 2).toFixed(2)
                  : this.state.orderLine.unitPrice,
              volumeCost: _.round(response.data.volumeCost, 2).toFixed(2), // not saved on orderline, only used for volume calculations
              volumePrice: _.round(response.data.volumePrice, 2).toFixed(2), // not saved on orderline, only used for volume calculations
              rollSize: response.data.rollSize, // not saved on orderline, only used for volume calculations
              priceUnitType: helpers.resolveValue(
                null,
                response.data.priceUnitTypeId,
                response.data.priceUnitTypeName
              ),
              perContainer: helpers.nullableString(response.data.perContainer),
              squareFeetPerCarton: helpers.nullableString(
                response.data.squareFeetPerCarton
              ),
              productAllowCommission: response.data.allowCommission,
            },
            nonVolumeUnitCost: _.round(response.data.unitCost, 2).toFixed(2),
            nonVolumeUnitPrice: _.round(response.data.unitPrice, 2).toFixed(2),
            productPatternMatch: response.data.repeat
          },
          () => {
            this.calculateFigures();
          }
        );
      })
      .catch(helpers.catchHandler);
  }

  handleFormSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
        showOrderLineStatusModal:
          selection.label === constants.ORDER_LINE_STATUS.BACKORDER.label ||
          selection.label === constants.ORDER_LINE_STATUS.SHIPPED.label
            ? true
            : false,
      },
      () => {
        if (elementName === "productColor") {
          this.calculateFigures();
        }
      }
    );
  }
  handleRugShapeSelectChange(elementName, selection) {
    let newRugDsc = this.state.orderLine.rugDsc;
    if (selection.label === constants.CUSTOM_RUG_SHAPE_LIST.ROUND.label) {
      newRugDsc = newRugDsc.replace("Custom Rug", "Round Custom Rug");
    } else {
      newRugDsc = newRugDsc.replace("Round Custom Rug", "Custom Rug");
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
          rugDsc: newRugDsc,
        },
        showOrderLineStatusModal:
          selection.label === constants.ORDER_LINE_STATUS.BACKORDER.label ||
          selection.label === constants.ORDER_LINE_STATUS.SHIPPED.label
            ? true
            : false,
      },
      () => {
        if (elementName === "productColor") {
          this.calculateFigures();
        }
      }
    );
  }

  handleWidthSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
      },
      () => {
        this.calculateFigures();
      }
    );
  }

  handleRugIntlSizeChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
          unitCost: selection.cost,
          unitPrice: selection.price,
        },
      },
      () => {
        this.calculateFigures();
      }
    );
  }

  handleOrderLineStateChange(fieldName, value) {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        [fieldName]: value,
      },
    });
  }

  updateOrderLineRateOrCurrencyField(fieldName, value) {
    if (value) {
      this.setState({
        orderLine: {
          ...this.state.orderLine,
          [fieldName]: _.round(value, 2).toFixed(2),
        },
      });
    }
  }

  mergeOrderLine(item, parentItem) {
    const mergedOrderLine = {
      id: item.parentOrderLineId,
      freightOrderLineId: item.id,
      orderId: parentItem.orderId,
      // parentOrderLineId: parentItem.orderLineType.value == constants.ORDER_LINE_TYPE.RUG_FIELD.value ? parentItem.parentOrderLineId : parentItem.id,
      parentOrderLineId: COMPONENT_ORDER_LINE_TYPES.includes(
        parentItem.orderLineType.value
      )
        ? parentItem.parentOrderLineId
        : parentItem.id,
      // parentOrderLineId: parentItem.id,
      orderLineType: parentItem.orderLineType,
      dsc: parentItem.dsc,
      unitCost: parentItem.unitCost,
      cost: parentItem.cost,
      unitPrice: parentItem.unitPrice,
      price: parentItem.price,
      cmsnRate: parentItem.cmsnRate,
      cmsn: parentItem.cmsn,
      isHidden: parentItem.isHidden,
      product: parentItem.product,
      productColor: parentItem.productColor,
      lengthFeet: parentItem.lengthFeet,
      orderLineStatus: parentItem.orderLineStatus,
      priceUnitType: parentItem.priceUnitType,
      squareFeetRequired: parentItem.squareFeetRequired,
      creditCardSurchargeRate: null,
      taxRate: parentItem.taxRate,
      rugIntlCollection: parentItem.rugIntlCollection,
      rugIntlSize: parentItem.rugIntlSize,
      createdAt: parentItem.createdAt,
      createdByUserId: parentItem.createdByUserId,
      updatedByUserId: parentItem.updatedByUserId,
      width: parentItem.width,
      qty: parentItem.qty,
      cartonCount: parentItem.cartonCount,
      squareFeetPerCarton: parentItem.squareFeetPerCarton,
      perContainer: parentItem.perContainer,
      // custom rug stuff:
      rugSize: parentItem.rugSize,
      finishedSize: parentItem.finishedSize,
      rugLengthFeet: parentItem.rugLengthFeet,
      rugLengthInches: parentItem.rugLengthInches,
      rugWidthFeet: parentItem.rugWidthFeet,
      rugWidthInches: parentItem.rugWidthInches,
      rugDsc: parentItem.rugDsc,
      sqFt: parentItem.sqFt,
      finishedEdgeFt: parentItem.finishedEdgeFt,
      rugType: parentItem.rugType,
      // field stuff (used for local calculations):
      fieldLengthFeet: parentItem.fieldLengthFeet,
      fieldLengthInches: parentItem.fieldLengthInches,
      selectedField: parentItem.selectedField,
      selectedFieldColor: parentItem.selectedFieldColor,
      selectedFieldWidth: parentItem.selectedFieldWidth,
      // fieldDsc: parentItem.dsc,
      fieldCmsnRate: parentItem.cmsnRate,
      fieldCmsn: parentItem.cmsn,
      fieldCostPer: parentItem.unitCost,
      totalFieldCost: parentItem.cost,
      fieldPricePer: parentItem.unitPrice,
      totalFieldPrice: parentItem.price,
      fieldPriceUnitType: parentItem.priceUnitType,
      totalFieldUnits: parentItem.qty,
      fieldRun: parentItem.run,
      appliedFieldRepeat: parentItem.repeatNumeric,
      repeatDsc: parentItem.repeatDsc,
      //freight:
      freightCarrier: item.freightCarrier,
      freightSidemark: item.freightSidemark,
      freightInvoiceNumber: item.freightInvoiceNumber,
      freightBolNumber: item.freightBolNumber,
      freightDsc: item.dsc,
      freightTerms: item.freightTerms,
      freightCost: item.cost,
      freightPrice: item.price,
      stContactFirstName: item.stContactFirstName,
      stContactLastName: item.stContactLastName,
      stContactEmail: item.stContactEmail,
      stAddressLine1: item.stAddressLine1,
      stAddressLine2: item.stAddressLine2,
      stAddressLine3: item.stAddressLine3,
      stAddressLine4: item.stAddressLine4,
      stCity: item.stCity,
      stState: item.stState,
      stZip: item.stZip,
      stPhone: item.stPhone,
      commentCount: parentItem.commentCount,
      rugShape: parentItem.rugShape,
    };
    return mergedOrderLine;
  }

  updateOrderLine(item) {
    window.scroll(0, 0);
    if (this.state.customRugToViewEdit) {
      // orderLine to update is a custom rug component
      if (
        item.orderLineType.value !== constants.ORDER_LINE_TYPE.FREIGHT.value
      ) {
        item = this.transformItemToRugComponentOrderLine(item);
      }

      let mergedOrderLine;
      let orderLineCommentList = item.orderLineComments.slice();

      // if item is the parent to a freightOrderLine
      const childItem = _.find(
        this.state.customRugToViewEdit.componentLines,
        function (x) {
          return x.parentOrderLineId === item.id && x.deactivatedAt === null;
        }
      );
      if (childItem) {
        const parentItem = Object.assign({}, item);
        mergedOrderLine = Object.assign(
          {},
          this.mergeOrderLine(childItem, parentItem)
        );
      }

      // if item is a freightOrderLine and has a parent orderLine associated with it
      if (
        item.parentOrderLineId &&
        item.orderLineType.value === constants.ORDER_LINE_TYPE.FREIGHT.value
      ) {
        let parentItem = _.find(
          this.state.customRugToViewEdit.componentLines,
          function (x) {
            return x.id === item.parentOrderLineId;
          }
        );
        if (parentItem) {
          parentItem = this.transformItemToRugComponentOrderLine(parentItem);
          mergedOrderLine = Object.assign(
            {},
            this.mergeOrderLine(item, parentItem)
          );
          orderLineCommentList = parentItem.orderLineComments.slice();
        }
      }
      this.setState(
        {
          orderLine: mergedOrderLine ? mergedOrderLine : item,
          orderLineCommentList: orderLineCommentList,
          showOrderLineForm: true,
          editingFreight:
            (item.parentOrderLineId &&
              item.orderLineType.value ===
                constants.ORDER_LINE_TYPE.FREIGHT.value) ||
            childItem
              ? true
              : false, // display Freight section if order line has freight info saved
          editingNotes: orderLineCommentList.length > 0 ? true : false, // display Notes section if order line has notes saved
        },
        () => {
          this.fetchAppropiateDataForSelects(
            mergedOrderLine ? mergedOrderLine : item
          );
        }
      );
    } else {
      // orderLine to update does not belong to a custom rug
      let mergedOrderLine;
      let orderLineCommentList = item.orderLineComments.slice();

      // if item is the parent to a freightOrderLine
      const childItem = _.find(this.state.orderLines, function (x) {
        return x.parentOrderLineId === item.id && x.deactivatedAt === null;
      });
      if (childItem) {
        const parentItem = Object.assign({}, item);
        mergedOrderLine = Object.assign(
          {},
          this.mergeOrderLine(childItem, parentItem)
        );
      }

      // if item is a freightOrderLine and has a parent orderLine associated with it
      if (item.parentOrderLineId) {
        const parentItem = _.find(this.state.orderLines, function (x) {
          return x.id === item.parentOrderLineId;
        });
        mergedOrderLine = Object.assign(
          {},
          this.mergeOrderLine(item, parentItem)
        );
        orderLineCommentList = parentItem.orderLineComments.slice();
      }

      this.setState(
        {
          orderLine: mergedOrderLine ? mergedOrderLine : item,
          orderLineCommentList: orderLineCommentList,
          showOrderLineForm: true,
          editingFreight: item.parentOrderLineId || childItem ? true : false, // display Freight section if order line has freight info saved
          editingNotes: orderLineCommentList.length > 0 ? true : false, // display Notes section if order line has notes saved
        },
        () => {
          this.fetchAppropiateDataForSelects(
            mergedOrderLine ? mergedOrderLine : item
          );
        }
      );
    }
  }

  transformItemToRugComponentOrderLine(item) {
    let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
      this.state.customRugToViewEdit.rugLengthFeet,
      this.state.customRugToViewEdit.rugLengthInches,
      this.state.customRugToViewEdit.rugWidthFeet,
      this.state.customRugToViewEdit.rugWidthInches
    );
    item.sqFt = sqFt;
    item.finishedEdgeFt = finishedEdgeFt;
    item.rugLengthFeet =
      this.state.customRugToViewEdit.rugLengthFeet.toString(); // needed for local fuctions to work
    item.rugLengthInches =
      this.state.customRugToViewEdit.rugLengthInches.toString(); // needed for local fuctions to work
    item.rugWidthFeet = this.state.customRugToViewEdit.rugWidthFeet.toString(); // needed for local fuctions to work
    item.rugWidthInches =
      this.state.customRugToViewEdit.rugWidthInches.toString(); // needed for local fuctions to work
    item.rugShape = this.state.customRugToViewEdit.rugShape;
    item.rugDsc = this.state.customRugToViewEdit.dsc; // needed for local fuctions to work

    switch (item.orderLineType.value) {
      case constants.ORDER_LINE_TYPE.RUG_FIELD.value:
      case constants.ORDER_LINE_TYPE.FREIGHT.value:
        // item.fieldLengthFeet = Math.floor(item.lengthFeet);
        // item.fieldLengthInches = Math.round((item.fieldLengthInches ? item.fieldLengthInches : 0 * 12) % 1) // this is calculated elsewhere but not on orderline load so

        item.fieldLengthFeet = Math.floor(item.lengthFeet);
        let inchesDec = item.lengthFeet - item.fieldLengthFeet;
        item.fieldLengthInches = (inchesDec * 12).toFixed(0); // this is calculated elsewhere but not on orderline load so
        item.selectedField = Object.assign({}, item.product);
        item.selectedFieldColor = item.productColor
          ? Object.assign({}, item.productColor)
          : null;
        item.selectedFieldWidth = Object.assign({}, item.width);
        // item.fieldDsc = helpers.nullableString(item.dsc);
        item.fieldCmsnRate = item.cmsnRate;
        item.fieldCmsn = item.cmsn;
        item.fieldCostPer = item.unitCost;
        item.totalFieldCost = item.cost;
        item.fieldPricePer = item.unitPrice;
        item.totalFieldPrice = item.price;
        item.fieldPriceUnitType = item.priceUnitType
          ? Object.assign({}, item.priceUnitType)
          : null;
        item.totalFieldUnits = item.qty;
        item.fieldRun = item.run;
        item.appliedFieldRepeat = item.repeatNumeric;
      case constants.ORDER_LINE_TYPE.RUG_BORDER.value:
      case constants.ORDER_LINE_TYPE.FREIGHT.value:
        item.borderLengthFeet = Math.floor(item.lengthFeet);
        let inchesDec2 = item.lengthFeet - item.borderLengthFeet;
        item.borderLengthInches = (inchesDec2 * 12).toFixed(0); // this is calculated elsewhere but not on orderline load so
        item.selectedBorder = Object.assign({}, item.product);
        item.selectedBorderColor = Object.assign({}, item.productColor);
        item.selectedBorderWidth = Object.assign({}, item.width);
        // item.borderDsc = helpers.nullableString(item.dsc);
        item.borderCmsnRate = item.cmsnRate;
        item.borderCmsn = item.cmsn;
        item.borderCostPer = item.unitCost;
        item.totalBorderCost = item.cost;
        item.borderPricePer = item.unitPrice;
        item.totalBorderPrice = item.price;
        item.borderPriceUnitType = item.priceUnitType
          ? Object.assign({}, item.priceUnitType)
          : null;
        item.totalBorderUnits = item.qty;
        // item.borderRun = item.run;
        // item.appliedBorderRepeat = item.repeatNumeric;
        break;
      case constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value:
      // break;
      default:
      // code block
    }
    return item;
  }

  async fetchAppropiateDataForSelects(orderLine) {
    // fetch appropriate data for selects for product to be updated
    let productAllowCommission;
    let volumeCost;
    let volumePrice;
    let nonVolumeUnitCost;
    let nonVolumeUnitPrice;
    let rollSize;
    let productsList = [];
    let productColorsList = [];
    let widthsList = [];
    // let collectionList = [];
    // let collectionProductsList = [];
    let rugIntlSizeList = [];
    let repeatLength = 0;
    let repeatWidth = 0;
    let repeatDsc = "";
    let productPatternMatch = "";
    let productType = this.getOrderLineCorrespondingProductType(
      orderLine.orderLineType.value
    );
    if (orderLine.product) {
      await api
        .fetch(
          `Product/GetProductWithOrderWithCustomerId/${orderLine.product.value}/${this.state.customerId}`
        ) // TODO: make new api to fetch only this property
        .then((response) => {
          productAllowCommission = response.data.allowCommission;
          volumeCost = _.round(response.data.volumeCost, 2).toFixed(2); // not saved on orderline, only used for volume calculations
          volumePrice = _.round(response.data.volumePrice, 2).toFixed(2);
          nonVolumeUnitCost = _.round(response.data.unitCost, 2).toFixed(2);
          nonVolumeUnitPrice = _.round(response.data.unitPrice, 2).toFixed(2);
          nonVolumeUnitCost = _.round(response.data.unitCost, 2).toFixed(2);
          nonVolumeUnitPrice = _.round(response.data.unitPrice, 2).toFixed(2);
          repeatLength = _.round(response.data.repeatLength, 2).toFixed(2);
          repeatWidth = _.round(response.data.repeatWidth, 2).toFixed(2);
          rollSize = response.data.rollSize;
          repeatDsc = response.data.repeat;
          productPatternMatch = response.data.repeat;
        })
        .catch(helpers.catchHandler);
    }
    if (
      orderLine.orderLineType.value !==
      constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value
    ) {
      await api
        .fetch(`Order/GetProductsOfProductType/${productType}`)
        .then((response) => {
          productsList = response.data;
        });

      if (orderLine.product) {
        // some orderLines don't have a product apparently
        await api
          .fetch(`Product/GetProductWidths/${orderLine.product.value}`)
          .then((response) => {
            widthsList = _.map(response.data, (w) => {
              return {
                value: w.widthId,
                label: w.widthName,
                widthNumericFeet: w.widthNumericFeet,
              };
            });
          })
          .catch(helpers.catchHandler);

        await api
          .fetch(`Order/GetActiveProductColors/${orderLine.product.value}`)
          .then((response) => {
            productColorsList = response.data;
          })
          .catch(helpers.catchHandler);
      }
      this.setState({
        productsList: productsList,
        productColorsList: productColorsList ? productColorsList : null,
        orderLine: {
          ...orderLine,
          widthsList: widthsList ? widthsList : null,
          productAllowCommission: productAllowCommission,
          volumeCost: volumeCost,
          volumePrice: volumePrice,
          rollSize: rollSize,
        },
        nonVolumeUnitCost: nonVolumeUnitCost,
        nonVolumeUnitPrice: nonVolumeUnitPrice,
        productPatternMatch: productPatternMatch
        //widthsList: widthsList
      });
    } else {
      // await api.fetch("Order/GetRugIntlCollectionList")
      // 	.then(response => {
      // 		collectionList = response.data;
      // 	})
      // 	.catch(helpers.catchHandler);;

      // await api.fetch(`Order/GetActiveCollectionProducts/${orderLine.rugIntlCollection.value}`)
      // 	.then(response => {
      // 		collectionProductsList = response.data;
      // 	})
      // 	.catch(helpers.catchHandler);

      await api
        .fetch(`Order/GetProductsOfProductType/${productType}`)
        .then((response) => {
          productsList = response.data;
        });

      await api
        .fetch(`Order/GetActiveProductColors/${orderLine.product.value}`)
        .then((response) => {
          productColorsList = response.data;
        })
        .catch(helpers.catchHandler);

      await api
        .fetch(`Product/GetRugIntlSizes/${orderLine.product.value}`)
        .then((response) => {
          rugIntlSizeList = _.map(response.data, (sz) => {
            return {
              value: sz.id,
              label: sz.dsc,
              cost: sz.cost,
              price: sz.price,
            };
          });
        })
        .catch(helpers.catchHandler);
      productColorsList.unshift({ label: "No color selected", value: null });
      this.setState({
        // rugIntlCollectionList: collectionList,
        // collectionProductsList: collectionProductsList,
        productsList: productsList,
        productColorsList: productColorsList,
        rugIntlSizeList: rugIntlSizeList,
        orderLine: {
          ...orderLine,
          productAllowCommission: productAllowCommission,
        },
      });
    }
  }

  applyVolumePricingIfNecessary() {
    let unitCost = _.round(this.state.orderLine.unitCost, 2).toFixed(2);
    let unitPrice = _.round(this.state.orderLine.unitPrice, 2).toFixed(2);
    let volumePricingApplied = this.state.orderLine.volumePricingApplied;
    const lengthFeet = this.state.orderLine.lengthFeet
      ? _.round(this.state.orderLine.lengthFeet, 2).toFixed(2)
      : "";
    const squareFeetRequired = this.state.orderLine.squareFeetRequired
      ? _.round(this.state.orderLine.squareFeetRequired, 2).toFixed(2)
      : "";
    if (lengthFeet || squareFeetRequired) {
      // lengthFeet for carpets and rug pads. squareFeetRequired for Wood items
      let hasQualifyingVolume =
        Number(this.state.orderLine.rollSize) > 0 &&
        (Number(lengthFeet) > Number(this.state.orderLine.rollSize) ||
          Number(squareFeetRequired) > Number(this.state.orderLine.rollSize))
          ? true
          : false;
      if (hasQualifyingVolume) {
        if (window.confirm("Apply volume pricing?")) {
          unitCost = _.round(this.state.orderLine.volumeCost, 2).toFixed(2);
          unitPrice = _.round(this.state.orderLine.volumePrice, 2).toFixed(2);
          volumePricingApplied = true;
        }
      } else {
        if (
          volumePricingApplied &&
          window.confirm("Apply non volume pricing?")
        ) {
          unitCost = _.round(this.state.nonVolumeUnitCost, 2).toFixed(2);
          unitPrice = _.round(this.state.nonVolumeUnitPrice, 2).toFixed(2);
          volumePricingApplied = false;
        }
      }
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          unitCost: unitCost,
          unitPrice: unitPrice,
          volumePricingApplied: volumePricingApplied,
        },
      },
      () => this.calculateFigures()
    );
  }

  async calculateFigures(isSaving = false) {
    const orderLine = this.state.orderLine;
    if (!orderLine.product) return;

    const orderLineType = orderLine.orderLineType.value;
    if (
      orderLineType === constants.ORDER_LINE_TYPE.CARPET.value ||
      orderLineType === constants.ORDER_LINE_TYPE.RUG_PAD.value
    ) {
      let updatedOrderLine = Object.assign({}, orderLine);
      updatedOrderLine.unitCost = _.round(orderLine.unitCost, 2).toFixed(2); // round entered value even if no calculations are made
      updatedOrderLine.unitPrice = _.round(orderLine.unitPrice, 2).toFixed(2); // round entered value even if no calculations are made
      updatedOrderLine.cmsnRate = _.round(orderLine.cmsnRate, 2).toFixed(2); // round entered value even if no calculations are made

      const lengthFeet = orderLine.lengthFeet
        ? _.round(orderLine.lengthFeet, 2).toFixed(2)
        : "";
      const widthNumericFeet =
        orderLine.width && orderLine.width.widthNumericFeet;
      let payload = {
        orderLineTypeId: orderLine.orderLineType.value,
        lengthFeet: lengthFeet ? Number(lengthFeet) : null,
        widthNumericFeet: Number(widthNumericFeet),
        qty: Number(orderLine.qty),
        unitCost: Number(updatedOrderLine.unitCost),
        unitPrice: Number(updatedOrderLine.unitPrice),
        cmsnRate: Number(_.round(orderLine.cmsnRate, 2).toFixed(2)),
        priceUnitTypeName: orderLine.priceUnitType
          ? orderLine.priceUnitType.label
          : "", // should send Id,
      };
      if (
        payload.widthNumericFeet &&
        (payload.lengthFeet || payload.qty) &&
        payload.unitCost &&
        payload.unitPrice
      ) {
        await api
          .post("Order/CalculateFigures", payload)
          .then((response) => {
            updatedOrderLine.unitCost = _.round(
              response.data.unitCost,
              2
            ).toFixed(2);
            updatedOrderLine.unitPrice = _.round(
              response.data.unitPrice,
              2
            ).toFixed(2);
            updatedOrderLine.cmsnRate = _.round(
              response.data.cmsnRate,
              2
            ).toFixed(2);
            updatedOrderLine.lengthFeet = lengthFeet;
            updatedOrderLine.widthNumericFeet = widthNumericFeet;
            updatedOrderLine.qty = _.round(response.data.qty, 2).toFixed(2);
            updatedOrderLine.cost = _.round(response.data.cost, 2).toFixed(2);
            updatedOrderLine.price = _.round(response.data.price, 2).toFixed(2);
            updatedOrderLine.cmsn = _.round(response.data.cmsn, 2).toFixed(2);
          })
          .catch(helpers.catchHandler);
      } else {
        updatedOrderLine.qty = "";
        updatedOrderLine.cost = "";
        updatedOrderLine.price = "";
        updatedOrderLine.cmsn = "";
        updatedOrderLine.dsc = "";
      }

      const productDsc = orderLine.product.label;
      const productColorDsc = orderLine.productColor
        ? orderLine.productColor.label
        : "";
      const widthDsc = orderLine.width ? orderLine.width.label : "";
      const lengthDsc =
        orderLine.lengthFeet || updatedOrderLine.qty || orderLine.qty;
      const qtyDsc = updatedOrderLine.qty || orderLine.qty;
      const priceUnitDsc = orderLine.priceUnitType
        ? orderLine.priceUnitType.label
        : "";

      // before was blank if api call didn't go through, now still displays dsc
      if (
        !isSaving &&
        orderLine.width &&
        (orderLine.lengthFeet || updatedOrderLine.qty || orderLine.qty)
      ) {
        updatedOrderLine.dsc = `${productDsc}, ${productColorDsc} (${widthDsc} X ${lengthDsc}) = ${qtyDsc} ${priceUnitDsc}`;
      }
      this.setState({
        orderLine: updatedOrderLine,
      });
    }
    if (
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.MISCELLANEOUS.value ||
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.SAMPLE.value ||
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value
    ) {
      let updatedOrderLine = Object.assign({}, this.state.orderLine);

      updatedOrderLine.cmsnRate = _.round(
        this.state.orderLine.cmsnRate,
        2
      ).toFixed(2);
      if (
        !isSaving &&
        this.state.orderLine.qty &&
        this.state.orderLine.product
      ) {
        let dsc;
        if (
          this.state.orderLine.orderLineType.value ===
            constants.ORDER_LINE_TYPE.SAMPLE.value &&
          this.state.orderLine.productColor
        ) {
          dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}, ${this.state.orderLine.productColor.label}`;
        } else {
          dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}`;
        }
        updatedOrderLine.dsc = dsc;
      }
      if (
        this.state.orderLine.qty &&
        this.state.orderLine.unitCost &&
        this.state.orderLine.unitPrice &&
        this.state.orderLine.cmsnRate
      ) {
        const payload = {
          orderLineTypeId: this.state.orderLine.orderLineType.value,
          qty: Number(this.state.orderLine.qty),
          unitCost: Number(
            _.round(this.state.orderLine.unitCost, 2).toFixed(2)
          ),
          unitPrice: Number(
            _.round(this.state.orderLine.unitPrice, 2).toFixed(2)
          ),
          cmsnRate: Number(
            _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)
          ),
        };
        await api
          .post("Order/CalculateFigures", payload)
          .then((response) => {
            // if (response.data.success) {
            updatedOrderLine = {
              ...updatedOrderLine,
              cost: _.round(response.data.cost, 2).toFixed(2),
              price: _.round(response.data.price, 2).toFixed(2),
              cmsn: _.round(response.data.cmsn, 2).toFixed(2),
            };
            // }
          })
          .catch(helpers.catchHandler);
      } else {
        updatedOrderLine = {
          ...this.state.orderLine,
          cost: "",
          price: "",
          cmsn: "",
          dsc: "",
        };
      }
      this.setState({
        orderLine: updatedOrderLine,
      });
    }
    if (
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.BINDING.value ||
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.ROLL_RUNNER.value
    ) {
      let updatedOrderLine = Object.assign({}, this.state.orderLine);
      updatedOrderLine = {
        ...updatedOrderLine,
        unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2), // round entered value even if no calculations are made
        unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2), // round entered value even if no calculations are made
        cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2), // round entered value even if no calculations are made
      };
      if (
        !isSaving &&
        this.state.orderLine.product &&
        this.state.orderLine.lengthFeet
      ) {
        updatedOrderLine = {
          ...updatedOrderLine,
          dsc: `${this.state.orderLine.product.label}, ${
            this.state.orderLine.productColor
              ? this.state.orderLine.productColor.label
              : ""
          } - ${this.state.orderLine.lengthFeet} Lin Ft`,
        };
      }
      if (
        this.state.orderLine.lengthFeet &&
        this.state.orderLine.unitCost &&
        this.state.orderLine.unitPrice &&
        this.state.orderLine.cmsnRate
      ) {
        const lengthFeet = this.state.orderLine.lengthFeet
          ? _.round(this.state.orderLine.lengthFeet, 2).toFixed(2)
          : "";
        const payload = {
          orderLineTypeId: this.state.orderLine.orderLineType.value,
          lengthFeet: lengthFeet ? Number(lengthFeet) : null,
          unitCost: Number(
            _.round(this.state.orderLine.unitCost, 2).toFixed(2)
          ),
          unitPrice: Number(
            _.round(this.state.orderLine.unitPrice, 2).toFixed(2)
          ),
          cmsnRate: Number(
            _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)
          ),
        };
        await api
          .post("Order/CalculateFigures", payload)
          .then((response) => {
            // if (response.data.success) {
            updatedOrderLine = {
              ...updatedOrderLine,
              lengthFeet: lengthFeet,
              qty: _.round(response.data.qty, 2).toFixed(2),
              cost: _.round(response.data.cost, 2).toFixed(2),
              price: _.round(response.data.price, 2).toFixed(2),
              cmsn: _.round(response.data.cmsn, 2).toFixed(2),
            };
            // }
          })
          .catch(helpers.catchHandler);
      } else {
        updatedOrderLine = {
          ...this.state.orderLine,
          qty: "",
          cost: "",
          price: "",
          cmsn: "",
          dsc: "",
        };
      }
      this.setState({
        orderLine: updatedOrderLine,
      });
    }
    if (
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.HARD_SURFACE.value ||
      this.state.orderLine.orderLineType.value ===
        constants.ORDER_LINE_TYPE.CARPET_TILE.value
    ) {
      let updatedOrderLine = Object.assign({}, this.state.orderLine);
      updatedOrderLine = {
        ...updatedOrderLine,
        unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2), // round entered value even if no calculations are made
        unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2), // round entered value even if no calculations are made
        cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2), // round entered value even if no calculations are made
      };

      if (
        this.state.orderLine.squareFeetRequired &&
        this.state.orderLine.unitCost &&
        this.state.orderLine.unitPrice &&
        this.state.orderLine.cmsnRate &&
        this.state.orderLine.squareFeetPerCarton
      ) {
        const squareFeetRequired = _.round(
          this.state.orderLine.squareFeetRequired,
          2
        ).toFixed(2);
        const payload = {
          orderLineTypeId: this.state.orderLine.orderLineType.value,
          squareFeetRequired: Number(squareFeetRequired),
          squareFeetPerCarton: Number(
            _.round(this.state.orderLine.squareFeetPerCarton, 2).toFixed(2)
          ),
          unitCost: Number(updatedOrderLine.unitCost),
          unitPrice: Number(updatedOrderLine.unitPrice),
          cmsnRate: Number(
            _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)
          ),
        };
        await api
          .post("Order/CalculateFigures", payload)
          .then((response) => {
            // if (response.data.success) {
            let dsc;
            let cartonOrCartonsString =
              response.data.cartonCount > 1 ? "Cartons" : "Carton";
            if (
              !isSaving &&
              this.state.orderLine.product &&
              this.state.orderLine.squareFeetRequired
            ) {
              dsc =
                `${this.state.orderLine.product.label}, ${
                  this.state.orderLine.productColor
                    ? this.state.orderLine.productColor.label
                    : ""
                } ` +
                `${response.data.cartonCount} ${cartonOrCartonsString} ` +
                `(${_.round(response.data.qty, 2).toFixed(2)} ${
                  this.state.orderLine.priceUnitType.label
                })`;
            }
            updatedOrderLine = {
              ...updatedOrderLine,
              squareFeetRequired: squareFeetRequired,
              cartonCount: response.data.cartonCount,
              qty: _.round(response.data.qty, 2).toFixed(2),
              cost: _.round(response.data.cost, 2).toFixed(2),
              price: _.round(response.data.price, 2).toFixed(2),
              cmsn: _.round(response.data.cmsn, 2).toFixed(2),
              dsc: dsc ? dsc : this.state.orderLine.dsc,
            };
            // }
          })
          .catch(helpers.catchHandler);
      } else {
        updatedOrderLine = {
          ...this.state.orderLine,
          cartonCount: "",
          qty: "",
          cost: "",
          price: "",
          cmsn: "",
          dsc: "",
        };
      }
      this.setState({
        orderLine: updatedOrderLine,
      });
    }
    if (
      this.state.orderLine.orderLineType.value ===
      constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value
    ) {
      let updatedOrderLine = Object.assign({}, this.state.orderLine);
      if (this.state.orderLine.rugIntlSize) {
        updatedOrderLine = {
          ...updatedOrderLine,
          unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2),
          unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2),
          cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2),
        };
      }
      if (
        !isSaving &&
        this.state.orderLine.qty &&
        this.state.orderLine.product &&
        this.state.orderLine.rugIntlSize
      ) {
        let dsc;
        if (this.state.orderLine.productColor) {
          dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}, ${this.state.orderLine.productColor.label} (${this.state.orderLine.rugIntlSize.label})`;
        } else {
          dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label} (${this.state.orderLine.rugIntlSize.label})`;
        }
        updatedOrderLine = {
          ...updatedOrderLine,
          dsc: dsc,
        };
      }
      if (
        this.state.orderLine.qty &&
        this.state.orderLine.unitCost &&
        this.state.orderLine.unitPrice &&
        this.state.orderLine.cmsnRate
      ) {
        const payload = {
          orderLineTypeId: this.state.orderLine.orderLineType.value,
          qty: Number(this.state.orderLine.qty),
          unitCost: Number(
            _.round(this.state.orderLine.unitCost, 2).toFixed(2)
          ),
          unitPrice: Number(
            _.round(this.state.orderLine.unitPrice, 2).toFixed(2)
          ),
          cmsnRate: Number(
            _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)
          ),
        };
        await api
          .post("Order/CalculateFigures", payload)
          .then((response) => {
            // if (response.data.success) {
            updatedOrderLine = {
              ...updatedOrderLine,
              cost: _.round(response.data.cost, 2).toFixed(2),
              price: _.round(response.data.price, 2).toFixed(2),
              cmsn: _.round(response.data.cmsn, 2).toFixed(2),
            };
            // }
          })
          .catch(helpers.catchHandler);
      } else {
        updatedOrderLine = {
          ...this.state.orderLine,
          cost: "",
          price: "",
          cmsn: "",
          dsc: "",
        };
      }
      this.setState({
        orderLine: updatedOrderLine,
      });
    }
    if (
      this.state.orderLine.orderLineType.value ===
      constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value
    ) {
      const creditCardSurchargeRate = _.round(
        this.state.orderLine.creditCardSurchargeRate,
        2
      ).toFixed(2);
      const payload = {
        orderLineTypeId: this.state.orderLine.orderLineType.value,
        creditCardSurchargeRate: Number(creditCardSurchargeRate),
        totalOrderCost: Number(this.state.totalOrderCost),
        totalWithoutCreditCardSurcharge: Number(
          this.state.totalWithoutCreditCardSurcharge
        ),
      };
      api
        .post("Order/CalculateFigures", payload)
        .then((response) => {
          // if (response.data.success) {
          this.setState((prevState) => ({
            ...prevState,
            orderLine: {
              ...prevState.orderLine,
              creditCardSurchargeRate: creditCardSurchargeRate,
              price: _.round(response.data.price, 2).toFixed(2),
            },
          }));
          // }
        })
        .catch(helpers.catchHandler);
    }

    if (
      this.state.orderLine.orderLineType.value ===
      constants.ORDER_LINE_TYPE.SALES_TAX.value
    ) {
      const taxRate = _.round(this.state.orderLine.taxRate, 2).toFixed(2);
      const payload = {
        orderLineTypeId: this.state.orderLine.orderLineType.value,
        taxRate: Number(taxRate),
        totalOrderCost: Number(this.state.totalOrderCost),
        sumOfTaxableItems: Number(this.state.sumOfTaxableItems),
      };
      api
        .post("Order/CalculateFigures", payload)
        .then((response) => {
          // if (response.data.success) {
          this.setState((prevState) => ({
            ...prevState,
            orderLine: {
              ...prevState.orderLine,
              taxRate: taxRate,
              price: _.round(response.data.price, 2).toFixed(2),
            },
          }));
          // }
        })
        .catch(helpers.catchHandler);
    }
  }

  setSelectedBillingAddress(option) {
    this.setState({
      order: {
        ...this.state.order,
        btAddressLine1: helpers.nullableString(option.ln1),
        btAddressLine2: helpers.nullableString(option.ln2),
        btAddressLine3: helpers.nullableString(option.ln3),
        btAddressLine4: helpers.nullableString(option.ln4),
        btCity: helpers.nullableString(option.city),
        btState: helpers.nullableString(option.state),
        btZip: helpers.nullableString(option.zip),
      },
      selectedBillingAddress: option,
    });
  }

  // rug component stuff
  toggleCutCharge() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasCutCharge: !this.state.orderLine.rugHasCutCharge,
      },
    });
  }

  toggleArmor() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasEndArmor: !this.state.orderLine.rugHasEndArmor,
      },
    });
  }

  toggleInboundFreight() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasInboundFreight: !this.state.orderLine.rugHasInboundFreight,
      },
    });
  }

  toggleBacking() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasBacking: !this.state.orderLine.rugHasBacking,
      },
    });
  }

  togglePad() {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          rugHasPad: !this.state.orderLine.rugHasPad,
        },
      },
      () => {
        this.setRugValidity();
      }
    );
  }

  toggleClippedCorners() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasClippedCorners: !this.state.orderLine.rugHasClippedCorners,
      },
    });
  }

  toggleLaborCutout() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasLaborCutout: !this.state.orderLine.rugHasLaborCutout,
      },
    });
  }

  toggleLaborRound() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugHasLaborRound: !this.state.orderLine.rugHasLaborRound,
      },
    });
  }

  toggleEdgeOptions() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        edgeOptionsOpen: !this.state.orderLine.edgeOptionsOpen,
      },
    });
  }

  setRugEdge(rugEdgeName) {
    // refreshRugDescription
    let edgeSuffix;
    if (rugEdgeName === EDGE_TYPES.MITERED) {
      edgeSuffix = "Serged to Match Background";
    } else {
      edgeSuffix = "Serged";
    }
    let rugDescription =
      this.state.orderLine.rugWidthFeet +
      "' " +
      this.state.orderLine.rugWidthInches +
      '" x ' +
      this.state.orderLine.rugLengthFeet +
      "' " +
      this.state.orderLine.rugLengthInches +
      '" Custom Rug-' +
      edgeSuffix;
    this.setState(
      {
        selectedRugEdge: rugEdgeName,
        orderLine: {
          ...this.state.orderLine,
          rugDsc: rugDescription, // Doesn't work
        },
      },
      () => {
        this.setRugValidity();
      }
    );
  }

  // Set rug properties
  async initializeRugOrder() {
    await this.rugSizeChange();
    await this.setSelectedPad(this.state.orderLine.selectedPad); // Already set to default pad
    const selectedSerging = this.state.sergingList[0];
    const selectedMSerging = this.state.mSergingList[0];
    const selectedHSSerging = this.state.hsSergingList[0];
    this.sergingSelected(selectedSerging);
    this.mSergingSelected(selectedMSerging);
    this.hsSergingSelected(selectedHSSerging);
  }

  async sergingSelected(selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          selectedSerging: selection,
          sergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
          sergingPricePer: _.round(selection.unitPrice, 2).toFixed(2),
        },
      },
      () => {
        this.updateSergingTotals();
      }
    );
  }

  async updateSergingTotals() {
    const totalSergingPrice =
      this.state.orderLine.sergingPricePer *
      this.state.orderLine.finishedEdgeFt;
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        sergingCostPer: _.round(this.state.orderLine.sergingCostPer, 2).toFixed(
          2
        ),
        sergingPricePer: _.round(
          this.state.orderLine.sergingPricePer,
          2
        ).toFixed(2),
        sergingCmsnRate: _.round(
          this.state.orderLine.sergingCmsnRate,
          2
        ).toFixed(2),
        totalSergingPrice: _.round(totalSergingPrice, 2).toFixed(2),
        totalSergingCost: _.round(
          this.state.orderLine.sergingCostPer *
            this.state.orderLine.finishedEdgeFt,
          2
        ).toFixed(2),
        sergingCmsn: _.round(
          (this.state.orderLine.sergingCmsnRate / 100.0) * totalSergingPrice,
          2
        ).toFixed(2),
      },
    });
  }

  async mSergingSelected(selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          selectedMSerging: selection,
          mSergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
          mSergingPricePer: _.round(selection.unitPrice, 2).toFixed(2),
        },
      },
      () => {
        this.updateMSergingTotals();
      }
    );
  }

  async updateMSergingTotals() {
    const totalMSergingPrice =
      this.state.orderLine.mSergingPricePer *
      this.state.orderLine.finishedEdgeFt;
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        mSergingCostPer: _.round(
          this.state.orderLine.mSergingCostPer,
          2
        ).toFixed(2),
        mSergingPricePer: _.round(
          this.state.orderLine.mSergingPricePer,
          2
        ).toFixed(2),
        mSergingCmsnRate: _.round(
          this.state.orderLine.mSergingCmsnRate,
          2
        ).toFixed(2),
        totalMSergingPrice: _.round(totalMSergingPrice, 2).toFixed(2),
        totalMSergingCost: _.round(
          this.state.orderLine.mSergingCostPer *
            this.state.orderLine.finishedEdgeFt,
          2
        ).toFixed(2),
        mSergingCmsn: _.round(
          (this.state.orderLine.mSergingCmsnRate / 100.0) * totalMSergingPrice,
          2
        ).toFixed(2),
      },
    });
  }

  async hsSergingSelected(selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          selectedHSSerging: selection,
          hsSergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
          hsSergingPricePer: _.round(selection.unitPrice, 2).toFixed(2),
        },
      },
      () => {
        this.updateHSSergingTotals();
      }
    );
  }

  updateHSSergingTotals() {
    const totalHSSergingPrice =
      this.state.orderLine.hsSergingPricePer *
      this.state.orderLine.finishedEdgeFt;
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        hsSergingCostPer: _.round(
          this.state.orderLine.hsSergingCostPer,
          2
        ).toFixed(2),
        hsSergingPricePer: _.round(
          this.state.orderLine.hsSergingPricePer,
          2
        ).toFixed(2),
        hsSergingCmsnRate: _.round(
          this.state.orderLine.hsSergingCmsnRate,
          2
        ).toFixed(2),
        totalHSSergingPrice: _.round(totalHSSergingPrice, 2).toFixed(2),
        totalHSSergingCost: _.round(
          this.state.orderLine.hsSergingCostPer *
            this.state.orderLine.finishedEdgeFt,
          2
        ).toFixed(2),
        hsSergingCmsn: _.round(
          (this.state.orderLine.hsSergingCmsnRate / 100.0) *
            totalHSSergingPrice,
          2
        ).toFixed(2),
      },
    });
  }

  refreshRugDescription() {
    let edgeSuffix;
    if (this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
      edgeSuffix = "Serged to Match Background";
    } else {
      edgeSuffix = "Serged";
    }
    const rugDsc =
      this.state.orderLine.rugWidthFeet +
      "' " +
      this.state.orderLine.rugWidthInches +
      '" x ' +
      this.state.orderLine.rugLengthFeet +
      "' " +
      this.state.orderLine.rugLengthInches +
      '" Custom Rug-' +
      edgeSuffix;
    return rugDsc;
  }

  calculateFieldDsc() {
    let dsc;
    if (this.state.orderLine.selectedField) {
      dsc = calc.getFieldDescription(
        this.state.orderLine.selectedField.label,
        this.state.orderLine.selectedFieldColor
      ); // ${this.state.orderLine.totalFieldUnits ? this.state.orderLine.totalFieldUnits : ''} ${this.state.orderLine.fieldPriceUnitType ? this.state.orderLine.fieldPriceUnitType.label : ''}
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          dsc: dsc,
        },
      },
      () => this.setRugValidity()
    );
  }

  setFinishedRugSize(option) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          finishedSize: option,
          rugWidthFeet: option.rugWidthFeet,
          rugWidthInches: option.rugWidthInches,
          rugLengthFeet: option.rugLengthFeet,
          rugLengthInches: option.rugLengthInches,
        },
      },
      () => this.rugSizeChange()
    );
  }

  renderWorkorderForm(lineId) {
    if (!this.state.orderId) {
      console.warn("orderId is empty");
      return;
    }
    this.setState({ printing: true }, () => {
      const pdfWindow = window.open();
      api
        .post_get_blob(
          `order/GetRugWorkOrderPDF/${this.state.orderId}/${lineId}`,
          {}
        )
        .then((r) => {
          if (r.status !== 200) {
            throw new Error("Error rendering document");
          }
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          pdfWindow.location.href = fileURL;
        })
        .catch((error) => {
          console.log(error);
          Alert.error("There was an error downloading the document");
          pdfWindow.close();
        })
        .finally(() => this.setState({ printing: false }));
    });
  }

  async rugSizeChange() {
    let rugDsc = this.refreshRugDescription();
    let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
      this.state.orderLine.rugLengthFeet,
      this.state.orderLine.rugLengthInches,
      this.state.orderLine.rugWidthFeet,
      this.state.orderLine.rugWidthInches
    );
    let {
      totalRugBackingPrice,
      totalRugBackingCost,
      rugBackingCmsn,
      totalSergingPrice,
      totalSergingCost,
      sergingCmsn,
      totalMSergingPrice,
      totalMSergingCost,
      mSergingCmsn,
      totalHSSergingPrice,
      totalHSSergingCost,
      hsSergingCmsn,
    } = calc.calculateRugExtraCosts(this.state.orderLine, sqFt, finishedEdgeFt);
    await this.setFieldDefaultSize();
    await this.defaultPadRequired();
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        sqFt: sqFt,
        rugDsc: rugDsc,
        finishedEdgeFt: finishedEdgeFt,
        totalRugBackingUnits: sqFt,
        totalRugBackingPrice: _.round(totalRugBackingPrice, 2).toFixed(2),
        totalRugBackingCost: _.round(totalRugBackingCost, 2).toFixed(2),
        rugBackingCmsn: _.round(rugBackingCmsn, 2).toFixed(2),
        totalSergingPrice: _.round(totalSergingPrice, 2).toFixed(2),
        totalSergingCost: _.round(totalSergingCost, 2).toFixed(2),
        sergingCmsn: _.round(sergingCmsn, 2).toFixed(2),
        totalMSergingPrice: _.round(totalMSergingPrice, 2).toFixed(2),
        totalMSergingCost: _.round(totalMSergingCost, 2).toFixed(2),
        mSergingCmsn: _.round(mSergingCmsn, 2).toFixed(2),
        totalHSSergingPrice: _.round(totalHSSergingPrice, 2).toFixed(2),
        totalHSSergingCost: _.round(totalHSSergingCost, 2).toFixed(2),
        hsSergingCmsn: _.round(hsSergingCmsn, 2).toFixed(2),
      },
    });
  }

  async setSelectedField(option) {
    await api
      .fetch(`Order/GetActiveProductColors/${option.value}`)
      .then((response) => {
        this.setState({
          productColorsList: response.data,
          orderLine: {
            ...this.state.orderLine,
            selectedFieldColor:
              response.data.length > 0 ? response.data[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    await api
      .fetch(`Product/GetProductWidths/${option.value}`)
      .then((response) => {
        const widths = _.map(response.data, (w) => {
          return {
            value: w.widthId,
            label: w.widthName,
            widthNumericFeet: w.widthNumericFeet,
          };
        });
        this.setState({
          orderLine: {
            ...this.state.orderLine,
            widthsList: widths,
            selectedFieldWidth: widths.length > 0 ? widths[0] : null,
            //width: widths.length > 0 ? widths[0] : null
          },
        });
      })
      .catch(helpers.catchHandler);

    await api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState(
          {
            orderLine: {
              ...this.state.orderLine,
              selectedField: option,
              fieldCostPer: _.round(response.data.unitCost, 2).toFixed(2),
              fieldPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
              fieldRepeatWidth: _.round(response.data.repeatWidth, 3).toFixed(
                3
              ),
              fieldRepeatLength: _.round(response.data.repeatLength, 3).toFixed(
                3
              ),
              fieldPriceUnitType: helpers.resolveValue(
                null,
                response.data.priceUnitTypeId,
                response.data.priceUnitTypeName
              ),
              repeatDsc:
                _.round(response.data.repeatWidth, 3).toFixed(3) +
                " x " +
                _.round(response.data.repeatLength, 3).toFixed(3),
              fieldRun: response.data.run,
              productAllowCommission: response.data.allowCommission,
            },
          },
          this.setFieldDefaultSize
        );
      })
      .catch(helpers.catchHandler);
  }

  async setFieldDefaultSize() {
    let orderLine = Object.assign({}, this.state.orderLine);
    const { selectedField, fieldRepeatLength, fieldRepeatWidth } = orderLine;
    if (orderLine && orderLine.selectedField) {
      const payload = {
        CustomWidth: Number(
          parseFloat(orderLine.rugWidthFeet) +
            parseFloat(orderLine.rugWidthInches / 12.0)
        ),
        CustomLength: Number(
          parseFloat(orderLine.rugLengthFeet) +
            parseFloat(orderLine.rugLengthInches / 12.0)
        ),
        ProductId: Number(orderLine.selectedField.value),
      };
      let resp = await calc.getDefaultFieldSize(
        payload,
        selectedField,
        fieldRepeatLength,
        fieldRepeatWidth
      );
      this.applyDefaultSizes(resp);
    }
  }

  applyDefaultSizes(results) {
    const {
      appliedFieldRepeat,
      fieldLengthFeet,
      fieldLengthInches,
      widthFeetName,
    } = results;
    var width = _.find(this.state.orderLine.widthsList, (w) => {
      return w.label === widthFeetName;
    });
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          appliedFieldRepeat: appliedFieldRepeat,
          fieldLengthFeet: fieldLengthFeet,
          fieldLengthInches: fieldLengthInches ? fieldLengthInches : 0,
          selectedFieldWidth: width,
        },
      },
      this.fieldSizeChange
    );
  }

  fieldSizeChange() {
    let totalFieldUnits;
    if (this.state.orderLine && this.state.orderLine.selectedFieldWidth) {
      totalFieldUnits = calc.calculateFieldUnits(
        this.state.orderLine.selectedFieldWidth.widthNumericFeet,
        this.state.orderLine.fieldLengthFeet,
        this.state.orderLine.fieldLengthInches,
        this.state.orderLine.fieldPriceUnitType &&
          this.state.orderLine.fieldPriceUnitType.label
      );
    }
    if (totalFieldUnits) {
      this.setState(
        {
          orderLine: {
            ...this.state.orderLine,
            totalFieldUnits: totalFieldUnits,
          },
        },
        this.updateFieldTotals
      );
    }
  }

  updateFieldTotals() {
    let totalFieldPrice =
      this.state.orderLine.fieldPricePer * this.state.orderLine.totalFieldUnits;
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          fieldCostPer: _.round(this.state.orderLine.fieldCostPer, 2).toFixed(
            2
          ),
          fieldPricePer: _.round(this.state.orderLine.fieldPricePer, 2).toFixed(
            2
          ),
          fieldCmsnRate: _.round(this.state.orderLine.fieldCmsnRate, 2).toFixed(
            2
          ),
          totalFieldPrice: _.round(totalFieldPrice, 2).toFixed(2),
          totalFieldCost: _.round(
            this.state.orderLine.fieldCostPer *
              this.state.orderLine.totalFieldUnits,
            2
          ).toFixed(2),
          fieldCmsn: _.round(
            (this.state.orderLine.fieldCmsnRate / 100.0) * totalFieldPrice,
            2
          ).toFixed(2),
        },
      },
      this.calculateFieldDsc
    );
  }

  async updateRugBackingTotals() {
    let totalRugBackingPrice =
      this.state.orderLine.rugBackingPricePer *
      this.state.orderLine.totalRugBackingUnits;
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugBackingCostPer: _.round(
          this.state.orderLine.rugBackingCostPer,
          2
        ).toFixed(2),
        rugBackingPricePer: _.round(
          this.state.orderLine.rugBackingPricePer,
          2
        ).toFixed(2),
        rugBackingCmsnRate: _.round(
          this.state.orderLine.rugBackingCmsnRate,
          2
        ).toFixed(2),
        totalRugBackingPrice: _.round(totalRugBackingPrice, 2).toFixed(2),
        totalRugBackingCost: _.round(
          this.state.orderLine.rugBackingCostPer *
            this.state.orderLine.totalRugBackingUnits,
          2
        ).toFixed(2),
        rugBackingCmsn: _.round(
          (this.state.orderLine.rugBackingCmsnRate / 100.0) *
            totalRugBackingPrice,
          2
        ).toFixed(2),
      },
    });
  }

  setSelectedPad(option) {
    api
      .fetch(`Product/GetProductWidths/${option.value}`)
      .then((response) => {
        const widths = _.map(response.data, (w) => {
          return {
            value: w.widthId,
            label: w.widthName,
            widthNumericFeet: w.widthNumericFeet,
          };
        });
        this.setState({
          padWidthList: widths,
          orderLine: {
            ...this.state.orderLine,
            selectedPadWidth: widths.length > 0 ? widths[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState(
          {
            orderLine: {
              ...this.state.orderLine,
              selectedPad: option,
              padCostPer: _.round(response.data.unitCost, 2).toFixed(2),
              padPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
              padPriceUnitType: helpers.resolveValue(
                null,
                response.data.priceUnitTypeId,
                response.data.priceUnitTypeName
              ),
            },
          },
          () => {
            this.defaultPadRequired();
          }
        );
      })
      .catch(helpers.catchHandler);
  }

  async defaultPadRequired() {
    // default pad length to meet sq ft requirements
    let padLengthFeet = "0";
    if (this.state.orderLine.selectedPadWidth) {
      let dimA =
        this.state.orderLine.rugWidthInches / 12.0 +
        parseFloat(this.state.orderLine.rugWidthFeet.toString());
      let dimB =
        this.state.orderLine.rugLengthInches / 12.0 +
        parseFloat(this.state.orderLine.rugLengthFeet.toString());
      let longer = dimA > dimB ? dimA : dimB;
      let shorter = dimA > dimB ? dimB : dimA;
      // let minLen = this.sqFt / this.selectedPadWidth.widthNumericFeet;
      if (longer <= this.state.orderLine.selectedPadWidth.widthNumericFeet) {
        padLengthFeet = Math.ceil(shorter);
      } else {
        padLengthFeet = Math.ceil(longer);
      }
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          padLengthFeet: padLengthFeet,
        },
      },
      () => this.padSizeChange()
    );
  }

  padSizeChange() {
    let totalPadUnits;
    let message;
    let messageFlavor;
    if (
      !this.state.orderLine.selectedPadWidth ||
      !this.state.orderLine.padLengthFeet
    )
      return;
    let lngth = parseFloat(this.state.orderLine.padLengthFeet.toString());
    if (isNaN(lngth)) return;
    let widthNumericFeet = parseFloat(
      this.state.orderLine.selectedPadWidth.widthNumericFeet.toString()
    );
    if (this.state.orderLine.padPriceUnitType.label === "Sq Yds") {
      let sqYds = ((widthNumericFeet / 3.0) * lngth) / 3.0;
      if (isNaN(sqYds)) return;
      totalPadUnits = sqYds;
    } else if (this.state.orderLine.padPriceUnitType.label === "Sq Ft") {
      totalPadUnits = widthNumericFeet * lngth;
    } else {
      totalPadUnits = 0.0;
      message =
        "Cannot calculate required material for this pad's price unit: " +
        this.state.orderLine.padPriceUnitType.label +
        ". Unknown Price Units";
      messageFlavor = "danger";
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          totalPadUnits: _.round(totalPadUnits, 2).toFixed(2),
        },
        message: message,
        messageFlavor: messageFlavor,
      },
      () => this.updatePadTotals()
    );
  }

  updatePadTotals() {
    let totalPadPrice =
      this.state.orderLine.padPricePer * this.state.orderLine.totalPadUnits;
    let padCutCost;
    let padCutPrice;

    if (
      (parseFloat(this.state.orderLine.totalPadUnits) <= 30 &&
        this.state.orderLine.padPriceUnitType.value ==
          constants.PRICE_UNIT_TYPE.SQ_FT.value) ||
      (parseFloat(this.state.orderLine.totalPadUnits) <= 30 / 9 &&
        this.state.orderLine.padPriceUnitType.value ==
          constants.PRICE_UNIT_TYPE.SQ_YARDS.value)
    ) {
      padCutCost =
        this.state.customRugComponentProducts.Under30SFPadCutChargeProduct
          .unitCost;
      padCutPrice =
        this.state.customRugComponentProducts.Under30SFPadCutChargeProduct
          .unitPrice;
    } else {
      padCutCost =
        this.state.customRugComponentProducts.DefaultPadCutChargeProduct
          .unitCost;
      padCutPrice =
        this.state.customRugComponentProducts.DefaultPadCutChargeProduct
          .unitPrice;
    }

    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          padCostPer: _.round(this.state.orderLine.padCostPer, 2).toFixed(2),
          padPricePer: _.round(this.state.orderLine.padPricePer, 2).toFixed(2),
          padCmsnRate: _.round(this.state.orderLine.padCmsnRate, 2).toFixed(2),
          totalPadPrice: _.round(totalPadPrice, 2).toFixed(2),
          totalPadCost: _.round(
            this.state.orderLine.padCostPer *
              this.state.orderLine.totalPadUnits,
            2
          ).toFixed(2),
          padCmsn: _.round(
            (this.state.orderLine.padCmsnRate / 100.0) * totalPadPrice,
            2
          ).toFixed(2),
          padCutCost: _.round(padCutCost, 2).toFixed(2),
          padCutPrice: _.round(padCutPrice, 2).toFixed(2),
        },
      },
      () => {
        this.setRugValidity();
      }
    );
  }

  setSelectedInboundFreight(option) {
    api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState({
          orderLine: {
            ...this.state.orderLine,
            selectedInboundFreight: option,
            rugInboundFreightCost: _.round(response.data.unitCost, 2).toFixed(
              2
            ),
            rugInboundFreightPrice: _.round(response.data.unitPrice, 2).toFixed(
              2
            ),
          },
        });
      })
      .catch(helpers.catchHandler);
  }

  // rugBindingStyleSelected in original app
  setSelectedRugBindingStyle(option) {
    api
      .fetch(`Order/GetActiveProductColors/${option.value}`)
      .then((response) => {
        this.setState({
          rugBindingColorList: response.data,
          orderLine: {
            ...this.state.orderLine,
            selectedRugBindingColor:
              response.data.length > 0 ? response.data[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState(
          {
            orderLine: {
              ...this.state.orderLine,
              selectedRugBindingStyle: option,
              rugBindingCostPer: _.round(response.data.unitCost, 2).toFixed(2),
              rugBindingPricePer: _.round(response.data.unitPrice, 2).toFixed(
                2
              ),
            },
          },
          () => {
            this.updateRugBindingTotals();
          }
        );
      })
      .catch(helpers.catchHandler);
  }

  updateRugBindingTotals() {
    let totalRugBindingPrice =
      this.state.orderLine.rugBindingPricePer *
      this.state.orderLine.finishedEdgeFt;
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          rugBindingCostPer: _.round(
            this.state.orderLine.rugBindingCostPer,
            2
          ).toFixed(2),
          rugBindingPricePer: _.round(
            this.state.orderLine.rugBindingPricePer,
            2
          ).toFixed(2),
          rugBindingCmsnRate: _.round(
            this.state.orderLine.rugBindingCmsnRate,
            2
          ).toFixed(2),
          totalRugBindingPrice: _.round(totalRugBindingPrice, 2).toFixed(2),
          totalRugBindingCost: _.round(
            this.state.orderLine.rugBindingCostPer *
              this.state.orderLine.finishedEdgeFt,
            2
          ).toFixed(2),
          rugBindingCmsn: _.round(
            (this.state.orderLine.rugBindingCmsnRate / 100.0) *
              totalRugBindingPrice,
            2
          ).toFixed(2),
        },
      },
      () => {
        this.setRugValidity();
      }
    );
  }

  handleRugOrderLineStateChange(elementName, value) {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        [elementName]: value,
      },
    });
  }

  handleRugFormSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
      },
      () => {
        if (elementName === "selectedFieldColor") {
          this.calculateFieldDsc();
        }
        if (elementName === "selectedBorderColor") {
          this.calculateBorderDsc();
        }
      }
    );
  }

  handleFieldWidthSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
      },
      () => {
        this.fieldSizeChange();
      }
    );
  }

  handlePadWidthSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
      },
      () => {
        this.defaultPadRequired();
      }
    );
  }

  onHasPadCutChargeChange() {
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        hasPadCutCharge: !this.state.orderLine.hasPadCutCharge,
      },
    });
  }

  async setSelectedBorder(option) {
    await api
      .fetch(`Order/GetActiveProductColors/${option.value}`)
      .then((response) => {
        this.setState({
          borderColorList: response.data,
          orderLine: {
            ...this.state.orderLine,
            selectedBorderColor:
              response.data.length > 0 ? response.data[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    await api
      .fetch(`Product/GetProductWidths/${option.value}`)
      .then((response) => {
        const widths = _.map(response.data, (w) => {
          return {
            value: w.widthId,
            label: w.widthName,
            widthNumericFeet: w.widthNumericFeet,
          };
        });
        this.setState({
          orderLine: {
            ...this.state.orderLine,
            widthsList: widths,
            selectedBorderWidth: widths.length > 0 ? widths[0] : null,
          },
        });
      })
      .catch(helpers.catchHandler);

    await api
      .fetch(
        `Product/GetProductWithOrderWithCustomerId/${option.value}/${this.state.customerId}`
      )
      .then((response) => {
        this.setState(
          {
            orderLine: {
              ...this.state.orderLine,
              selectedBorder: option,
              borderCostPer: _.round(response.data.unitCost, 2).toFixed(2),
              borderPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
              // borderRepeatWidth: _.round(response.data.repeatWidth, 3).toFixed(3),
              // borderRepeatLength: _.round(response.data.repeatLength, 3).toFixed(3),
              borderPriceUnitType: helpers.resolveValue(
                null,
                response.data.priceUnitTypeId,
                response.data.priceUnitTypeName
              ),
              // repeatDsc: _.round(response.data.repeatWidth, 3).toFixed(3) + ' x ' + _.round(response.data.repeatLength, 3).toFixed(3),
              // fieldRun: response.data.run,
              productAllowCommission: response.data.allowCommission,
            },
          },
          () => {
            this.borderSizeChange();
          }
        );
      })
      .catch(helpers.catchHandler);
  }

  calculateBorderDsc() {
    let dsc;
    if (this.state.orderLine.selectedBorder) {
      // dsc = `Field (A): ${this.state.orderLine.totalFieldUnits ? this.state.orderLine.totalFieldUnits : ''} ${this.state.orderLine.fieldPriceUnitType ? this.state.orderLine.fieldPriceUnitType.label : ''} ${this.state.orderLine.selectedField ? this.state.orderLine.selectedField.label : ''} ${this.state.orderLine.selectedFieldColor ? this.state.orderLine.selectedFieldColor.label : ''}`
      dsc = `${
        this.state.orderLine.borderLetter
          ? this.state.orderLine.borderLetter + ":"
          : ""
      } ${
        this.state.orderLine.selectedBorder
          ? this.state.orderLine.selectedBorder.label
          : ""
      } ${
        this.state.orderLine.selectedBorderColor
          ? ", " + this.state.orderLine.selectedBorderColor.label
          : ""
      }`;
    }
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          dsc: dsc,
        },
      },
      () => this.setRugValidity()
    );
  }

  handleBorderWidthSelectChange(elementName, selection) {
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          [elementName]: selection,
        },
      },
      () => {
        this.borderSizeChange();
      }
    );
  }

  borderSizeChange() {
    let totalBorderUnits;
    if (this.state.orderLine && this.state.orderLine.selectedBorderWidth) {
      let lngth = 0;
      if (
        (this.state.orderLine.borderLengthFeet !== "" ||
          this.state.orderLine.borderLengthFeet !== null) &&
        (this.state.orderLine.borderLengthInches !== "" ||
          this.state.orderLine.borderLengthInches !== null)
      ) {
        lngth =
          parseFloat(this.state.orderLine.borderLengthFeet.toString()) +
          parseFloat(this.state.orderLine.borderLengthInches.toString()) / 12.0;
      }
      if (lngth === 0) {
        this.calculateBorderDsc();
      }
      let widthNumericFeet = 0;
      //if (this.state.orderLine && this.state.orderLine.selectedBorderWidth) {
      widthNumericFeet =
        this.state.orderLine.selectedBorderWidth.widthNumericFeet;
      //}
      if (
        this.state.orderLine.borderPriceUnitType &&
        this.state.orderLine.borderPriceUnitType.label === "Sq Yds"
      ) {
        let sqYds = ((widthNumericFeet / 3.0) * lngth) / 3.0;
        if (isNaN(sqYds)) {
          this.calculateBorderDsc();
        }
        totalBorderUnits = sqYds;
      } else if (
        this.state.orderLine.borderPriceUnitType &&
        this.state.orderLine.borderPriceUnitType.label === "Sq Ft"
      ) {
        totalBorderUnits = widthNumericFeet * lngth;
      } else {
        totalBorderUnits = "0.0";
        // message = "Cannot calculate required material for this price unit: " + this.state.orderLine.priceUnitType.label + ". Unknown Price Units",
        // messageFlavor = "danger"
      }
    }

    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          totalBorderUnits: _.round(totalBorderUnits, 3).toFixed(3),
        },
      },
      () => {
        this.updateBorderTotals();
      }
    );
  }

  updateBorderTotals() {
    let totalBorderPrice =
      this.state.orderLine.borderPricePer *
      this.state.orderLine.totalBorderUnits;
    this.setState(
      {
        orderLine: {
          ...this.state.orderLine,
          borderCostPer: _.round(this.state.orderLine.borderCostPer, 2).toFixed(
            2
          ),
          borderPricePer: _.round(
            this.state.orderLine.borderPricePer,
            2
          ).toFixed(2),
          borderCmsnRate: _.round(
            this.state.orderLine.borderCmsnRate,
            2
          ).toFixed(2),
          totalBorderPrice: _.round(totalBorderPrice, 2).toFixed(2),
          totalBorderCost: _.round(
            this.state.orderLine.borderCostPer *
              this.state.orderLine.totalBorderUnits,
            2
          ).toFixed(2),
          borderCmsn: _.round(
            (this.state.orderLine.borderCmsnRate / 100.0) * totalBorderPrice,
            2
          ).toFixed(2),
        },
      },
      () => {
        this.calculateBorderDsc();
      }
    );
  }

  setRugValidity() {
    let isValid = this.state.orderLine.selectedField !== null;
    if (isValid && this.state.orderLine.rugHasPad) {
      isValid =
        this.state.orderLine.selectedPad !== null &&
        this.state.orderLine.selectedPadWidth !== null;
    }
    if (
      isValid &&
      (this.state.selectedRugEdge === EDGE_TYPES.MACHINED ||
        this.state.selectedRugEdge === EDGE_TYPES.MITERED)
    ) {
      isValid =
        this.state.orderLine.selectedRugBindingStyle !== null &&
        this.state.orderLine.selectedRugBindingColor !== null;
    }
    this.setState({
      orderLine: {
        ...this.state.orderLine,
        rugEditsValid: isValid,
      },
    });
  }

  onNoColorSelected() {
    let lineOrder = Object.assign({}, this.state.orderLine);
    if (lineOrder !== null) {
      lineOrder.productColor = null;
    }
    this.setState({
      orderLine: lineOrder,
      colorOptOut: !this.state.colorOptOut,
    });
  }

  render() {
    const { squarespace } = this.props;
    let orderLinesToMapOver = this.state.customRugToViewEdit
      ? this.state.customRugToViewEdit.componentLines
      : this.state.orderLines;
    if (this.state.redirectToOrders) {
      return <Redirect to="/orders" />;
    }
    return (
      <Container fluid>
        <Row className="mb-3">
          <Col>
            {this.state.message && (
              <Alert2 className={this.state.messageFlavor}>
                {this.state.message}
              </Alert2>
            )}
            <Card>
              <CardBody>
                {this.state.orderLine.orderLineType.value !==
                  constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value &&
                  this.state.orderLine.orderLineType.value !==
                    constants.ORDER_LINE_TYPE.SALES_TAX.value && (
                    <React.Fragment>
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.CARPET.value && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="3">
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            <Col xs="3">
                              Product Color{" "}
                              <span className="ml-5 detailTextNoSize float-right">
                                <Input
                                  type="checkbox"
                                  checked={this.state.colorOptOut}
                                  onChange={() => this.onNoColorSelected()}
                                />
                                No Color Selected
                              </span>
                              <Select
                                options={this.state.productColorsList}
                                value={this.state.orderLine.productColor}
                                onChange={this.handleFormSelectChange.bind(
                                  this,
                                  "productColor"
                                )}
                                isDisabled={this.state.colorOptOut}
                              />
                            </Col>
                            <Col xs="2">
                              Width {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLine.widthsList}
                                value={this.state.orderLine.width}
                                onChange={this.handleWidthSelectChange.bind(
                                  this,
                                  "width"
                                )}
                              />
                            </Col>
                            {this.state.orderLine.priceUnitType &&
                            this.state.orderLine.priceUnitType.value ===
                              constants.PRICE_UNIT_TYPE.EACH.value ? (
                              <Col md="2">
                                {" "}
                                Qty
                                <Input
                                  type="number"
                                  step="1"
                                  name="qty"
                                  value={this.state.orderLine.qty}
                                  onChange={(event) =>
                                    this.handleOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  onBlur={() => this.calculateFigures()}
                                />
                              </Col>
                            ) : (
                              <Col xs="2">
                                Length &#40;ft.&#41; {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  step="0.0001"
                                  name="lengthFeet"
                                  value={this.state.orderLine.lengthFeet}
                                  onChange={(event) =>
                                    this.handleOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  onBlur={() =>
                                    this.applyVolumePricingIfNecessary()
                                  }
                                />
                              </Col>
                            )}
                            <Col xs="2">
                              {this.state.orderLine.priceUnitType &&
                              this.state.orderLine.priceUnitType.value !==
                                constants.PRICE_UNIT_TYPE.EACH.value &&
                              this.state.orderLine.priceUnitType.value !==
                                constants.PRICE_UNIT_TYPE.LIN_FT.value ? (
                                <React.Fragment>
                                  {this.state.orderLine.priceUnitType &&
                                  (this.state.orderLine.priceUnitType.value ===
                                    constants.PRICE_UNIT_TYPE.SQ_YARDS.value ||
                                    this.state.orderLine.priceUnitType.value ===
                                      constants.PRICE_UNIT_TYPE.LIN_FT.value)
                                    ? `Sq. Yds.`
                                    : `Sq. Ft`}
                                  <Input
                                    type="number"
                                    step="0.0001"
                                    name="qty"
                                    value={this.state.orderLine.qty}
                                    readOnly
                                  />
                                </React.Fragment>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.RUG_PAD.value && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            <Col>
                              Width {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLine.widthsList}
                                value={this.state.orderLine.width}
                                onChange={this.handleWidthSelectChange.bind(
                                  this,
                                  "width"
                                )}
                              />
                            </Col>
                            <Col>
                              Length &#40;ft.&#41; {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.0001"
                                name="lengthFeet"
                                value={this.state.orderLine.lengthFeet}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                // onBlur={() => this.calculateFigures()}
                                onBlur={() =>
                                  this.applyVolumePricingIfNecessary()
                                }
                              />
                            </Col>
                            <Col>
                              Sq. Ft.
                              <Input
                                type="number"
                                step="0.0001"
                                name="qty"
                                value={this.state.orderLine.qty}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {(this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.MISCELLANEOUS.value ||
                        this.state.orderLine.orderLineType.value ===
                          constants.ORDER_LINE_TYPE.SAMPLE.value) && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            {this.state.orderLine.orderLineType.value ===
                              constants.ORDER_LINE_TYPE.SAMPLE.value && (
                              <Col>
                                Product Color
                                <Select
                                  options={this.state.productColorsList}
                                  value={this.state.orderLine.productColor}
                                  onChange={this.handleFormSelectChange.bind(
                                    this,
                                    "productColor"
                                  )}
                                />
                              </Col>
                            )}
                            <Col xs="3">
                              Qty
                              <Input
                                type="number"
                                step="1"
                                name="qty"
                                value={this.state.orderLine.qty}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {(this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.HARD_SURFACE.value ||
                        this.state.orderLine.orderLineType.value ===
                          constants.ORDER_LINE_TYPE.CARPET_TILE.value) && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            <Col>
                              Product Color
                              <Select
                                options={this.state.productColorsList}
                                value={this.state.orderLine.productColor}
                                onChange={this.handleFormSelectChange.bind(
                                  this,
                                  "productColor"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Sq. Units Required {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="squareFeetRequired"
                                value={this.state.orderLine.squareFeetRequired}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() =>
                                  this.applyVolumePricingIfNecessary()
                                }
                              />
                            </Col>
                            <Col>
                              # Items {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="1"
                                name="cartonCount"
                                value={this.state.orderLine.cartonCount}
                                readOnly
                              />
                            </Col>
                            <Col>
                              <div className="total-text-left mt-3">
                                {this.state.orderLine.squareFeetPerCarton
                                  ? `${this.state.orderLine.squareFeetPerCarton} ${this.state.orderLine.priceUnitType.label}`
                                  : null}
                              </div>
                              <div className="total-text-left">
                                {this.state.orderLine.perContainer
                                  ? `${this.state.orderLine.perContainer} per`
                                  : null}
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Sq. Ft.
                              <Input
                                type="number"
                                step="0.0001"
                                name="qty"
                                value={this.state.orderLine.qty}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {(this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.BINDING.value ||
                        this.state.orderLine.orderLineType.value ===
                          constants.ORDER_LINE_TYPE.ROLL_RUNNER.value) && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            <Col>
                              Product Color
                              <Select
                                options={this.state.productColorsList}
                                value={this.state.orderLine.productColor}
                                onChange={this.handleFormSelectChange.bind(
                                  this,
                                  "productColor"
                                )}
                              />
                            </Col>
                            <Col>
                              Length &#40;ft.&#41; {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.0001"
                                name="lengthFeet"
                                value={this.state.orderLine.lengthFeet}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value && (
                        <div>
                          <Row>
                            <Col xs="6">
                              Line Type {helpers.requiredStar()}
                              <Select
                                options={this.state.orderLineTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col xs="2">
                              Pattern Match
                              <Input
                                type="text"
                                name="Pattern Match"
                                value={this.state.productPatternMatch}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col>Collection {helpers.requiredStar()}
																	<Select
																		options={this.state.rugIntlCollectionList}
																		value={this.state.orderLine.rugIntlCollection}
																		onChange={(option) => this.setSelectedCollection(option)}
																	/>
																</Col> */}
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                // options={this.state.collectionProductsList}
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                            <Col>
                              Product Color
                              <Select
                                options={this.state.productColorsList}
                                value={this.state.orderLine.productColor}
                                onChange={this.handleFormSelectChange.bind(
                                  this,
                                  "productColor"
                                )}
                              />
                            </Col>
                            <Col>
                              Size
                              <Select
                                options={this.state.rugIntlSizeList}
                                value={this.state.orderLine.rugIntlSize}
                                onChange={this.handleRugIntlSizeChange.bind(
                                  this,
                                  "rugIntlSize"
                                )}
                              />
                            </Col>
                            <Col xs="2">
                              Qty
                              <Input
                                type="number"
                                step="1"
                                name="qty"
                                value={this.state.orderLine.qty}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsnRate
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={
                                  this.state.orderLine.productAllowCommission
                                    ? this.state.orderLine.cmsn
                                    : "0.0"
                                }
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                disabled={
                                  !this.state.orderLine.productAllowCommission
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                      {COMPONENT_ORDER_LINE_TYPES.includes(
                        this.state.orderLine.orderLineType.value
                      ) && (
                        <Row className="mb-1">
                          <Col xs="2">
                            <Label className="m-0 p-0">Finished Size</Label>
                            <Select
                              options={FINISHED_RUG_SIZES}
                              value={this.state.orderLine.finishedSize}
                              onChange={(option) =>
                                this.setFinishedRugSize(option)
                              }
                            />
                          </Col>
                          <Col className="mr-3">
                            <Row>
                              <Col>
                                <Label className="m-0 p-0">Width</Label>
                              </Col>
                              <Col>
                                <Label className="m-0 p-0">Length</Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-0">
                                <InputGroupAddon
                                  addonType="append"
                                  className="mr-1"
                                >
                                  <Input
                                    type="text"
                                    name="rugWidthFeet"
                                    placeholder="W'"
                                    required
                                    value={this.state.orderLine.rugWidthFeet}
                                    onChange={(event) =>
                                      this.handleRugOrderLineStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                    onBlur={this.rugSizeChange}
                                  />
                                  <span
                                    className="ml-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    '{" "}
                                  </span>
                                </InputGroupAddon>
                              </Col>
                              <Col className="pr-0">
                                <InputGroupAddon
                                  addonType="append"
                                  className="mr-1"
                                >
                                  <Input
                                    type="text"
                                    name="rugWidthInches"
                                    placeholder='W"'
                                    required
                                    value={this.state.orderLine.rugWidthInches}
                                    onChange={(event) =>
                                      this.handleRugOrderLineStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                    onBlur={this.rugSizeChange}
                                  />
                                  <span
                                    className="ml-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    "
                                  </span>
                                  <span
                                    className="ml-3 mt-2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    X{" "}
                                  </span>
                                </InputGroupAddon>
                              </Col>
                              <Col className="pr-0">
                                <InputGroupAddon
                                  addonType="append"
                                  className="mr-1"
                                >
                                  <Input
                                    type="text"
                                    name="rugLengthFeet"
                                    id="rugLengthFeet"
                                    required
                                    placeholder="L'"
                                    value={this.state.orderLine.rugLengthFeet}
                                    onChange={(event) =>
                                      this.handleRugOrderLineStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                    onBlur={this.rugSizeChange}
                                  />
                                  <span
                                    className="ml-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    '{" "}
                                  </span>
                                </InputGroupAddon>
                              </Col>
                              <Col className="pr-0">
                                <InputGroupAddon
                                  addonType="append"
                                  className="mr-1"
                                >
                                  <Input
                                    type="text"
                                    name="rugLengthInches"
                                    required
                                    placeholder='L"'
                                    value={this.state.orderLine.rugLengthInches}
                                    onChange={(event) =>
                                      this.handleRugOrderLineStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                    onBlur={this.rugSizeChange}
                                  />
                                  <span
                                    className="ml-1"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    "{" "}
                                  </span>
                                </InputGroupAddon>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="4">
                            <Label className="m-0 p-0">Rug Description</Label>
                            <Input
                              type="text"
                              name="rugDsc"
                              value={this.state.orderLine.rugDsc}
                              required
                              onChange={(event) =>
                                this.handleRugOrderLineStateChange(
                                  event.target.name,
                                  event.target.value
                                )
                              }
                              maxLength="250"
                            />
                          </Col>
                          <Col xs="1">
                            <Label className="m-0 p-0">Sq Ft</Label>
                            <Input
                              type="text"
                              name="sqFt"
                              required
                              value={this.state.orderLine.sqFt || ""}
                              readOnly
                            />
                          </Col>
                          <Col xs="1">
                            <Label className="m-0 p-0">Fin Edge Ft</Label>
                            <Input
                              type="text"
                              name="finishedEdgeFt"
                              required
                              value={this.state.orderLine.finishedEdgeFt || ""}
                              readOnly
                            />
                          </Col>
                        </Row>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.RUG_FIELD.value && (
                        <div>
                          <Row className="mb-1">
                            <Col xs="2">
                              <Label className="m-0 p-0">Component Type</Label>
                              <Select
                                options={rugComponentTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                            <Col xs="2">
                              Rug Shape
                              <Select
                                options={this.state.rugShapeList}
                                value={this.state.orderLine.rugShape}
                                onChange={this.handleRugShapeSelectChange.bind(
                                  this,
                                  "rugShape"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col xs="4">
                              <Label className="m-0 p-0">Field Style</Label>
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.selectedField}
                                onChange={(option) =>
                                  this.setSelectedField(option)
                                }
                              />
                              <Input
                                type="text"
                                readOnly
                                className="total-text"
                                value={
                                  this.state.orderLine.selectedField !== null
                                    ? `Run: ${
                                        this.state.orderLine.fieldRun
                                          ? this.state.orderLine.fieldRun
                                          : ""
                                      }`
                                    : ""
                                }
                              />
                            </Col>
                            <Col>
                              <Label className="m-0 p-0">Color</Label>
                              <Select
                                closeMenuOnSelect
                                options={this.state.productColorsList}
                                value={this.state.orderLine.selectedFieldColor}
                                onChange={this.handleRugFormSelectChange.bind(
                                  this,
                                  "selectedFieldColor"
                                )}
                                name="selectedFieldColor"
                                isClearable
                              />
                              <Input
                                type="text"
                                readOnly
                                className="total-text"
                                value={
                                  this.state.orderLine.selectedField !== null
                                    ? `Repeat: ${this.state.orderLine.repeatDsc}`
                                    : ""
                                }
                              />
                            </Col>
                            <Col>
                              <Label className="m-0 p-0">Width</Label>
                              <Select
                                options={this.state.orderLine.widthsList}
                                value={this.state.orderLine.selectedFieldWidth}
                                onChange={this.handleFieldWidthSelectChange.bind(
                                  this,
                                  "selectedFieldWidth"
                                )}
                              />
                              <Input
                                type="text"
                                className="total-text"
                                name="totalFieldUnits"
                                value={this.state.orderLine.totalFieldUnits}
                                readOnly
                              />
                            </Col>
                            <Col>
                              <Row>
                                <Col>
                                  <Label className="m-0 p-0">Length</Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-0">
                                  <InputGroupAddon
                                    addonType="append"
                                    className="mr-1"
                                  >
                                    <Input
                                      type="text"
                                      name="fieldLengthFeet"
                                      id="fieldLengthFeet"
                                      value={
                                        this.state.orderLine.fieldLengthFeet
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      required
                                      placeholder="L'"
                                      onBlur={this.fieldSizeChange}
                                    />
                                    <span
                                      className="ml-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      '{" "}
                                    </span>
                                  </InputGroupAddon>
                                </Col>
                                <Col>
                                  <InputGroupAddon
                                    addonType="append"
                                    className="mr-1"
                                  >
                                    <Input
                                      type="text"
                                      name="fieldLengthInches"
                                      value={
                                        this.state.orderLine.fieldLengthInches
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      required
                                      placeholder='L"'
                                      onBlur={this.fieldSizeChange}
                                    />
                                    <span
                                      className="ml-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      "{" "}
                                    </span>
                                  </InputGroupAddon>
                                </Col>
                              </Row>
                              <Input
                                type="text"
                                className="total-text"
                                style={{ textAlign: "left" }}
                                value={
                                  this.state.orderLine.fieldPriceUnitType
                                    ? this.state.orderLine.fieldPriceUnitType
                                        .label
                                    : ""
                                }
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Cost</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="fieldCostPer"
                                value={this.state.orderLine.fieldCostPer}
                                required
                                // readOnly   // readOnly in angular app for some reason
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.updateFieldTotals()}
                                required
                              />
                              <Input
                                type="number"
                                className="total-text"
                                step="0.01"
                                name="totalFieldCost"
                                value={
                                  this.state.orderLine.totalFieldCost || ""
                                }
                                required
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Price</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="fieldPricePer"
                                value={this.state.orderLine.fieldPricePer || ""}
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.updateFieldTotals()}
                                required
                              />
                              <Input
                                type="number"
                                step="0.01"
                                className="total-text"
                                name="totalFieldPrice"
                                value={
                                  this.state.orderLine.totalFieldPrice || ""
                                }
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Cmsn %</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="fieldCmsnRate"
                                value={this.state.orderLine.fieldCmsnRate || ""}
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.updateFieldTotals()}
                                required
                              />
                              <Input
                                type="number"
                                className="total-text"
                                step="0.01"
                                name="fieldCmsn"
                                value={this.state.orderLine.fieldCmsn || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.RUG_BORDER.value && (
                        <div>
                          <Row className="mb-1">
                            <Col xs="2">
                              <Label className="m-0 p-0">Component Type</Label>
                              <Select
                                options={rugComponentTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="4">
                              <Label className="m-0 p-0">Border Style</Label>
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.selectedBorder}
                                onChange={(option) =>
                                  this.setSelectedBorder(option)
                                }
                              />
                            </Col>
                            <Col>
                              <Label className="m-0 p-0">Color</Label>
                              <Select
                                options={this.state.borderColorList}
                                value={this.state.orderLine.selectedBorderColor}
                                onChange={this.handleRugFormSelectChange.bind(
                                  this,
                                  "selectedBorderColor"
                                )}
                                name="selectedBorderColor"
                              />
                            </Col>
                            <Col>
                              <Label className="m-0 p-0">Width</Label>
                              <Select
                                options={this.state.orderLine.widthsList}
                                value={this.state.orderLine.selectedBorderWidth}
                                onChange={this.handleBorderWidthSelectChange.bind(
                                  this,
                                  "selectedBorderWidth"
                                )}
                              />
                              <Input
                                type="text"
                                className="total-text"
                                name="totalBorderUnits"
                                value={this.state.orderLine.totalBorderUnits}
                                readOnly
                              />
                            </Col>
                            <Col>
                              <Row>
                                <Col>
                                  <Label className="m-0 p-0">
                                    Length Required
                                  </Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-0">
                                  <InputGroupAddon
                                    addonType="append"
                                    className="mr-1"
                                  >
                                    <Input
                                      type="text"
                                      name="borderLengthFeet"
                                      id="borderLengthFeet"
                                      value={
                                        this.state.orderLine.borderLengthFeet
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      required
                                      placeholder="L'"
                                      onBlur={this.borderSizeChange}
                                    />
                                    <span
                                      className="ml-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      '{" "}
                                    </span>
                                  </InputGroupAddon>
                                </Col>
                                <Col>
                                  <InputGroupAddon
                                    addonType="append"
                                    className="mr-1"
                                  >
                                    <Input
                                      type="text"
                                      name="borderLengthInches"
                                      value={
                                        this.state.orderLine.borderLengthInches
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      required
                                      placeholder='L"'
                                      onBlur={this.borderSizeChange}
                                    />
                                    <span
                                      className="ml-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      "{" "}
                                    </span>
                                  </InputGroupAddon>
                                </Col>
                              </Row>
                              <Input
                                type="text"
                                className="total-text"
                                style={{ textAlign: "left" }}
                                value={
                                  this.state.orderLine.selectedBorder === null
                                    ? ""
                                    : this.state.orderLine.borderPriceUnitType
                                    ? this.state.orderLine.borderPriceUnitType
                                        .label
                                    : ""
                                }
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Cost</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="borderCostPer"
                                value={this.state.orderLine.borderCostPer}
                                required
                                // readOnly   // readOnly in angular app for some reason
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={(event) =>
                                  this.updateOrderLineRateOrCurrencyField(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              />
                              <Input
                                type="number"
                                className="total-text"
                                step="0.01"
                                name="totalBorderCost"
                                value={
                                  this.state.orderLine.totalBorderCost || ""
                                }
                                required
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Price</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="borderPricePer"
                                value={
                                  this.state.orderLine.borderPricePer || ""
                                }
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.updateBorderTotals()}
                                required
                              />
                              <Input
                                type="number"
                                step="0.01"
                                className="total-text"
                                name="totalBorderPrice"
                                value={
                                  this.state.orderLine.totalBorderPrice || ""
                                }
                                readOnly
                              />
                            </Col>
                            <Col xs="1">
                              <Label className="m-0 p-0">Cmsn %</Label>
                              <Input
                                type="number"
                                className="rt"
                                step="0.01"
                                name="borderCmsnRate"
                                value={
                                  this.state.orderLine.borderCmsnRate || ""
                                }
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.updateBorderTotals()}
                                required
                              />
                              <Input
                                type="number"
                                className="total-text"
                                step="0.01"
                                name="borderCmsn"
                                value={this.state.orderLine.borderCmsn || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xs="2">
                              <Label className="m-0 p-0">Letter</Label>
                              <Input
                                type="text"
                                name="borderLetter"
                                value={this.state.orderLine.borderLetter || ""}
                                onChange={(event) =>
                                  this.handleRugOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateBorderDsc()}
                              />
                            </Col>
                            <Col xs="2">
                              <Label className="m-0 p-0">Border Width</Label>
                              <Input
                                type="text"
                                readOnly
                                className="total-text"
                                value={this.state.orderLine.borderWidth || ""}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value && (
                        <div>
                          <Row className="mb-3">
                            <Col xs="4">
                              <Label className="m-0 p-0">Component Type</Label>
                              <Select
                                options={rugComponentTypeList}
                                value={this.state.orderLine.orderLineType}
                                onChange={this.handleOrderLineTypeChange.bind(
                                  this
                                )}
                                isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
                              />
                            </Col>
                            <Col>
                              Description {helpers.requiredStar()}
                              <Input
                                type="text"
                                name="dsc"
                                value={this.state.orderLine.dsc}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                maxLength="250"
                              />
                            </Col>
                          </Row>
                          {/* {this.state.orderLine.orderLineType.label !== "MISCELLANEOUS (RUG COMPONENT)" && */}
                          <Row>
                            <Col>
                              Product {helpers.requiredStar()}
                              <Select
                                options={this.state.productsList}
                                value={this.state.orderLine.product}
                                onChange={(option) =>
                                  this.setSelectedProduct(option)
                                }
                              />
                            </Col>
                          </Row>
                          {/* } */}
                          <Row className="mb-4">
                            <Col>
                              Qty
                              <Input
                                type="number"
                                step="1"
                                name="qty"
                                value={this.state.orderLine.qty}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Cost/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitCost"
                                value={this.state.orderLine.unitCost}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Total Cost {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cost"
                                value={this.state.orderLine.cost}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Price/Unit {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={this.state.orderLine.unitPrice}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Price {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="price"
                                value={this.state.orderLine.price}
                                readOnly
                              />
                            </Col>
                            <Col>
                              Cmsn% {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsnRate"
                                value={this.state.orderLine.cmsnRate}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                onBlur={() => this.calculateFigures()}
                              />
                            </Col>
                            <Col>
                              Commission {helpers.requiredStar()}
                              <Input
                                type="number"
                                step="0.01"
                                name="cmsn"
                                value={this.state.orderLine.cmsn}
                                onChange={(event) =>
                                  this.handleOrderLineStateChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {this.state.orderLine.orderLineType.value ===
                        constants.ORDER_LINE_TYPE.CUSTOM_RUG.value && (
                        <Row className="mb-2">
                          <Col xs="12">
                            <Row>
                              <Col xs="2">
                                <Label className="m-0 p-0">Finished Size</Label>
                                <Select
                                  options={FINISHED_RUG_SIZES}
                                  value={this.state.orderLine.finishedSize}
                                  onChange={(option) =>
                                    this.setFinishedRugSize(option)
                                  }
                                />
                              </Col>
                              <Col className="mr-3">
                                <Row>
                                  <Col>
                                    <Label className="m-0 p-0">Width</Label>
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Length</Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pr-0">
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="rugWidthFeet"
                                        placeholder="W'"
                                        required
                                        value={
                                          this.state.orderLine.rugWidthFeet
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        onBlur={this.rugSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        '{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                  <Col className="pr-0">
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="rugWidthInches"
                                        placeholder='W"'
                                        required
                                        value={
                                          this.state.orderLine.rugWidthInches
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        onBlur={this.rugSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        "
                                      </span>
                                      <span
                                        className="ml-3 mt-2"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        X{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                  <Col className="pr-0">
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="rugLengthFeet"
                                        id="rugLengthFeet"
                                        required
                                        placeholder="L'"
                                        value={
                                          this.state.orderLine.rugLengthFeet
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        onBlur={this.rugSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        '{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                  <Col className="pr-1">
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="rugLengthInches"
                                        required
                                        placeholder='L"'
                                        value={
                                          this.state.orderLine.rugLengthInches
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        onBlur={this.rugSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        "{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="4">
                                <Label className="m-0 p-0">
                                  Rug Description
                                </Label>
                                <Input
                                  type="text"
                                  name="rugDsc"
                                  value={this.state.orderLine.rugDsc}
                                  required
                                  onChange={(event) =>
                                    this.handleRugOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  maxLength="250"
                                />
                              </Col>
                              <Col xs="1" className="px-1">
                                <Label className="m-0 p-0">Sq Ft</Label>
                                <Input
                                  type="text"
                                  name="sqFt"
                                  required
                                  value={this.state.orderLine.sqFt}
                                  readOnly
                                />
                              </Col>
                              <Col xs="1" className="px-1">
                                <Label className="m-0 p-0">Fin Edge Ft</Label>
                                <Input
                                  type="text"
                                  name="finishedEdgeFt"
                                  required
                                  value={this.state.orderLine.finishedEdgeFt}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="4">
                                <Label className="m-0 p-0">Field</Label>
                                <Select
                                  options={this.state.productsList}
                                  value={this.state.orderLine.selectedField}
                                  onChange={(option) =>
                                    this.setSelectedField(option)
                                  }
                                />
                                <Input
                                  type="text"
                                  readOnly
                                  className="total-text"
                                  value={
                                    this.state.orderLine.selectedField !== null
                                      ? `Run: ${
                                          this.state.orderLine.fieldRun
                                            ? this.state.orderLine.fieldRun
                                            : ""
                                        }`
                                      : ""
                                  }
                                />
                              </Col>
                              <Col>
                                <Label className="m-0 p-0">Color</Label>
                                <Select
                                  closeMenuOnSelect
                                  options={this.state.productColorsList}
                                  value={
                                    this.state.orderLine.selectedFieldColor
                                  }
                                  onChange={this.handleRugFormSelectChange.bind(
                                    this,
                                    "selectedFieldColor"
                                  )}
                                  name="selectedFieldColor"
                                  isClearable
                                />
                                <Input
                                  type="text"
                                  readOnly
                                  className="total-text"
                                  // value={this.state.orderLine.selectedField !== null
                                  // 		? `Repeat: ${this.state.orderLine.fieldRepeatWidth} x ${this.state.orderLine.fieldRepeatLength}`
                                  // 		: ''
                                  // 	}
                                  value={`Repeat: ${this.state.orderLine.repeatDsc}`}
                                />
                              </Col>
                              <Col>
                                <Label className="m-0 p-0">Width</Label>
                                <Select
                                  options={this.state.orderLine.widthsList}
                                  value={
                                    this.state.orderLine.selectedFieldWidth
                                  }
                                  onChange={this.handleFieldWidthSelectChange.bind(
                                    this,
                                    "selectedFieldWidth"
                                  )}
                                />
                                <Input
                                  type="text"
                                  className="total-text"
                                  name="totalFieldUnits"
                                  value={this.state.orderLine.totalFieldUnits}
                                  readOnly
                                />
                              </Col>
                              <Col>
                                <Row>
                                  <Col>
                                    <Label className="m-0 p-0">Length</Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pr-0">
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="fieldLengthFeet"
                                        id="fieldLengthFeet"
                                        value={
                                          this.state.orderLine.fieldLengthFeet
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        required
                                        placeholder="L'"
                                        onBlur={this.fieldSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        '{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                  <Col>
                                    <InputGroupAddon
                                      addonType="append"
                                      className="mr-1"
                                    >
                                      <Input
                                        type="text"
                                        name="fieldLengthInches"
                                        value={
                                          this.state.orderLine.fieldLengthInches
                                        }
                                        onChange={(event) =>
                                          this.handleRugOrderLineStateChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        required
                                        placeholder='L"'
                                        onBlur={this.fieldSizeChange}
                                      />
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        "{" "}
                                      </span>
                                    </InputGroupAddon>
                                  </Col>
                                </Row>
                                <Input
                                  type="text"
                                  className="total-text"
                                  style={{ textAlign: "left" }}
                                  value={
                                    this.state.orderLine.selectedField === null
                                      ? ""
                                      : this.state.orderLine.fieldPriceUnitType
                                      ? this.state.orderLine.fieldPriceUnitType
                                          .label
                                      : ""
                                  }
                                  readOnly
                                />
                              </Col>
                              <Col xs="1">
                                <Label className="m-0 p-0">Cost</Label>
                                <Input
                                  type="number"
                                  className="rt"
                                  step="0.01"
                                  name="fieldCostPer"
                                  value={this.state.orderLine.fieldCostPer}
                                  required
                                  readOnly
                                  onChange={(event) =>
                                    this.handleRugOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  onBlur={(event) =>
                                    this.updateOrderLineRateOrCurrencyField(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                                <Input
                                  type="number"
                                  className="total-text"
                                  step="0.01"
                                  name="totalFieldCost"
                                  value={this.state.orderLine.totalFieldCost}
                                  required
                                  readOnly
                                />
                              </Col>
                              <Col xs="1">
                                <Label className="m-0 p-0">Price</Label>
                                <Input
                                  type="number"
                                  className="rt"
                                  step="0.01"
                                  name="fieldPricePer"
                                  value={this.state.orderLine.fieldPricePer}
                                  onChange={(event) =>
                                    this.handleRugOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  onBlur={() => this.updateFieldTotals()}
                                  required
                                />
                                <Input
                                  type="number"
                                  step="0.01"
                                  className="total-text"
                                  name="totalFieldPrice"
                                  value={this.state.orderLine.totalFieldPrice}
                                  readOnly
                                />
                              </Col>
                              <Col xs="1">
                                <Label className="m-0 p-0">Cmsn %</Label>
                                <Input
                                  type="number"
                                  className="rt"
                                  step="0.01"
                                  name="fieldCmsnRate"
                                  value={this.state.orderLine.fieldCmsnRate}
                                  onChange={(event) =>
                                    this.handleRugOrderLineStateChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  onBlur={() => this.updateFieldTotals()}
                                  required
                                />
                                <Input
                                  type="number"
                                  className="total-text"
                                  step="0.01"
                                  name="fieldCmsn"
                                  value={this.state.orderLine.fieldCmsn}
                                  readOnly
                                />
                              </Col>
                              <Col xs="1">
                                Rug Shape
                                <Select
                                  options={this.state.rugShapeList}
                                  value={this.state.orderLine.rugShape}
                                  onChange={this.handleRugShapeSelectChange.bind(
                                    this,
                                    "rugShape"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs="12">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Options :</InputGroupText>
                                  </InputGroupAddon>
                                  <ButtonGroup>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleCutCharge}
                                      className={
                                        this.state.orderLine.rugHasCutCharge
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Cut Charge
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleArmor}
                                      className={
                                        this.state.orderLine.rugHasEndArmor
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      End Armor
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleInboundFreight}
                                      className={
                                        this.state.orderLine
                                          .rugHasInboundFreight
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Inbound Freight
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleBacking}
                                      className={
                                        this.state.orderLine.rugHasBacking
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Backing
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.togglePad}
                                      className={
                                        this.state.orderLine.rugHasPad
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Pad
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleClippedCorners}
                                      className={
                                        this.state.orderLine
                                          .rugHasClippedCorners
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Clipped Corners
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleLaborCutout}
                                      className={
                                        this.state.orderLine.rugHasLaborCutout
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Cutout
                                    </Button>
                                    <Button
                                      className="btn btn-sm"
                                      onClick={this.toggleLaborRound}
                                      className={
                                        this.state.orderLine.rugHasLaborRound
                                          ? "success"
                                          : "secondary"
                                      }
                                    >
                                      Round/Oval
                                    </Button>
                                  </ButtonGroup>
                                  <InputGroupButtonDropdown
                                    addonType="append"
                                    isOpen={
                                      this.state.orderLine.edgeOptionsOpen
                                    }
                                    toggle={this.toggleEdgeOptions}
                                    id="selectedRugEdgeOption"
                                  >
                                    <DropdownToggle caret>
                                      Edge: {this.state.selectedRugEdge}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() =>
                                          this.setRugEdge("Serging")
                                        }
                                      >
                                        Serging
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          this.setRugEdge(
                                            "Applied Machine Border"
                                          )
                                        }
                                      >
                                        Applied Machine Border
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          this.setRugEdge(
                                            "Hidden Stitch Mitered Corners"
                                          )
                                        }
                                      >
                                        Hidden Stitch Mitered Corners
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </InputGroupButtonDropdown>
                                </InputGroup>
                              </Col>
                            </Row>
                            {this.state.orderLine.rugHasCutCharge && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="10" className="rug-option-header">
                                    Cut Charge
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugCutChargeCost"
                                      value={
                                        this.state.orderLine.rugCutChargeCost
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugCutChargePrice"
                                      value={
                                        this.state.orderLine.rugCutChargePrice
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasEndArmor && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="10" className="rug-option-header">
                                    End Armor
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugArmorCost"
                                      value={this.state.orderLine.rugArmorCost}
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugArmorPrice"
                                      value={this.state.orderLine.rugArmorPrice}
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasInboundFreight && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="6" className="rug-option-header">
                                    Inbound Freight
                                  </Col>
                                  <Col xs="3" xl="4">
                                    <Label className="m-0 p-0">Product</Label>
                                    <Select
                                      options={
                                        this.state.inboundFreightProductList
                                      }
                                      value={
                                        this.state.orderLine
                                          .selectedInboundFreight
                                      }
                                      onChange={(option) =>
                                        this.setSelectedInboundFreight(option)
                                      }
                                      name="selectedPad"
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugInboundFreightCost"
                                      value={
                                        this.state.orderLine
                                          .rugInboundFreightCost
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugInboundFreightPrice"
                                      value={
                                        this.state.orderLine
                                          .rugInboundFreightPrice
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasBacking && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="8" className="rug-option-header">
                                    Backing
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Sq Ft</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="totalRugBackingUnits"
                                      value={
                                        this.state.orderLine
                                          .totalRugBackingUnits
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateRugBackingTotals()
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBackingCostPer"
                                      required
                                      value={
                                        this.state.orderLine.rugBackingCostPer
                                      }
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalRugBackingCost"
                                      required
                                      value={
                                        this.state.orderLine.totalRugBackingCost
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBackingPricePer"
                                      required
                                      value={
                                        this.state.orderLine.rugBackingPricePer
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateRugBackingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalRugBackingPrice"
                                      value={
                                        this.state.orderLine
                                          .totalRugBackingPrice
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBackingCmsnRate"
                                      required
                                      value={
                                        this.state.orderLine.rugBackingCmsnRate
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateRugBackingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="rugBackingCmsn"
                                      value={
                                        this.state.orderLine.rugBackingCmsn
                                      }
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasPad && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="1" className="rug-option-header">
                                    Pad
                                  </Col>
                                  <Col xs="3" xl="4">
                                    <Label className="m-0 p-0">Pad</Label>
                                    <Select
                                      options={this.state.padProductsList}
                                      value={this.state.orderLine.selectedPad}
                                      onChange={(option) =>
                                        this.setSelectedPad(option)
                                      }
                                      name="selectedPad"
                                    />
                                  </Col>
                                  <Col xs="3" xl="2">
                                    <Label>
                                      <input
                                        type="checkbox"
                                        name="hasPadCutCharge"
                                        value={
                                          this.state.orderLine.hasPadCutCharge
                                        }
                                        onChange={this.onHasPadCutChargeChange}
                                        checked={
                                          this.state.orderLine.hasPadCutCharge
                                        }
                                      />{" "}
                                      Pad Cut Charge
                                    </Label>
                                    {this.state.orderLine.hasPadCutCharge && (
                                      <Row>
                                        <Col xs="6">
                                          <Label className="m-0 p-0">
                                            Cut Cost
                                          </Label>
                                          <Input
                                            type="text"
                                            className="m-0 p-0"
                                            name="padCutCost"
                                            value={
                                              this.state.orderLine.padCutCost
                                            }
                                            readOnly
                                          />
                                        </Col>
                                        <Col xs="6">
                                          <Label className="m-0 p-0">
                                            Cut Price
                                          </Label>
                                          <Input
                                            type="text"
                                            className="m-0 p-0"
                                            name="padCutPrice"
                                            value={
                                              this.state.orderLine.padCutPrice
                                            }
                                            onChange={(event) =>
                                              this.handleRugOrderLineStateChange(
                                                event.target.name,
                                                event.target.value
                                              )
                                            }
                                            onBlur={(event) =>
                                              this.updateOrderLineRateOrCurrencyField(
                                                event.target.name,
                                                event.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Width</Label>
                                    <Select
                                      options={this.state.padWidthList}
                                      value={
                                        this.state.orderLine.selectedPadWidth
                                      }
                                      onChange={this.handlePadWidthSelectChange.bind(
                                        this,
                                        "selectedPadWidth"
                                      )}
                                    />
                                    <Input
                                      type="text"
                                      className="form-control total-text"
                                      name="totalPadUnits"
                                      value={this.state.orderLine.totalPadUnits}
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Length</Label>
                                    <Input
                                      type="text"
                                      name="padLengthFeet"
                                      id="padLengthFeet"
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.padSizeChange()}
                                      required
                                      value={this.state.orderLine.padLengthFeet}
                                      placeholder="L'"
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      value={
                                        this.state.orderLine.selectedPad ===
                                        null
                                          ? ""
                                          : this.state.orderLine
                                              .padPriceUnitType
                                          ? this.state.orderLine
                                              .padPriceUnitType.label
                                          : ""
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="padCostPer"
                                      required
                                      value={this.state.orderLine.padCostPer}
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalPadCost"
                                      required
                                      value={this.state.orderLine.totalPadCost}
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="padPricePer"
                                      required
                                      value={this.state.orderLine.padPricePer}
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updatePadTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalPadPrice"
                                      value={this.state.orderLine.totalPadPrice}
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="padCmsnRate"
                                      required
                                      value={this.state.orderLine.padCmsnRate}
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updatePadTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="padCmsn"
                                      value={this.state.orderLine.padCmsn}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasClippedCorners && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="10" className="rug-option-header">
                                    Clipped Corners
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugClippedCornersCost"
                                      value={
                                        this.state.orderLine
                                          .rugClippedCornersCost
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugClippedCornersPrice"
                                      value={
                                        this.state.orderLine
                                          .rugClippedCornersPrice
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasLaborCutout && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="10" className="rug-option-header">
                                    Cutout Labor
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugLaborCutoutCost"
                                      value={
                                        this.state.orderLine.rugLaborCutoutCost
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugLaborCutoutPrice"
                                      value={
                                        this.state.orderLine.rugLaborCutoutPrice
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.orderLine.rugHasLaborRound && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="10" className="rug-option-header">
                                    Round/Oval Labor
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugLaborRoundCost"
                                      value={
                                        this.state.orderLine.rugLaborRoundCost
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugLaborRoundPrice"
                                      value={
                                        this.state.orderLine.rugLaborRoundPrice
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.updateOrderLineRateOrCurrencyField(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.selectedRugEdge === "Serging" && (
                              <React.Fragment>
                                <Row className="mb-4">
                                  <Col xs="5" className="rug-option-header">
                                    Edge: {this.state.selectedRugEdge}
                                  </Col>
                                  <Col xs="3">
                                    <Label className="m-0 p-0">Style</Label>
                                    <Select
                                      options={this.state.sergingList}
                                      value={
                                        this.state.orderLine.selectedSerging
                                      }
                                      onChange={this.sergingSelected.bind(this)}
                                      name="selectedSerging"
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">
                                      Fin Edge Ft
                                    </Label>
                                    <Input
                                      type="text"
                                      name="finishedEdgeFt"
                                      required
                                      value={
                                        this.state.orderLine.finishedEdgeFt
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="sergingCostPer"
                                      required
                                      value={
                                        this.state.orderLine.sergingCostPer
                                      }
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalSergingCost"
                                      required
                                      value={
                                        this.state.orderLine.totalSergingCost
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="sergingPricePer"
                                      required
                                      value={
                                        this.state.orderLine.sergingPricePer
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updateSergingTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalSergingPrice"
                                      value={
                                        this.state.orderLine.totalSergingPrice
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="sergingCmsnRate"
                                      required
                                      value={
                                        this.state.orderLine.sergingCmsnRate
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updateSergingTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="sergingCmsn"
                                      value={this.state.orderLine.sergingCmsn}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.selectedRugEdge ===
                              "Applied Machine Border" && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="5" className="rug-option-header">
                                    Edge: {this.state.selectedRugEdge}
                                  </Col>
                                  <Col xs="3">
                                    <Label className="m-0 p-0">Style</Label>
                                    <Select
                                      options={this.state.mSergingList}
                                      value={
                                        this.state.orderLine.selectedMSerging
                                      }
                                      onChange={this.mSergingSelected.bind(
                                        this
                                      )}
                                      name="selectedMSerging"
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">
                                      Fin Edge Ft
                                    </Label>
                                    <Input
                                      type="text"
                                      // className="feet-text"
                                      name="finishedEdgeFt"
                                      required
                                      value={
                                        this.state.orderLine.finishedEdgeFt
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="mSergingCostPer"
                                      required
                                      value={
                                        this.state.orderLine.mSergingCostPer
                                      }
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalMSergingCost"
                                      required
                                      value={
                                        this.state.orderLine.totalMSergingCost
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="mSergingPricePer"
                                      required
                                      value={
                                        this.state.orderLine.mSergingPricePer
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updateMSergingTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalMSergingPrice"
                                      value={
                                        this.state.orderLine.totalMSergingPrice
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="mSergingCmsnRate"
                                      required
                                      value={
                                        this.state.orderLine.mSergingCmsnRate
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() => this.updateMSergingTotals()}
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="mSergingCmsn"
                                      value={this.state.orderLine.mSergingCmsn}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {this.state.selectedRugEdge ===
                              "Hidden Stitch Mitered Corners" && (
                              <React.Fragment>
                                <Row>
                                  <Col xs="5" className="rug-option-header">
                                    Edge: {this.state.selectedRugEdge}
                                  </Col>
                                  <Col xs="3">
                                    <Label className="m-0 p-0">Style</Label>
                                    <Select
                                      options={this.state.hsSergingList}
                                      value={
                                        this.state.orderLine.selectedHSSerging
                                      }
                                      onChange={this.hsSergingSelected.bind(
                                        this
                                      )}
                                      name="selectedHSSerging"
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">
                                      Fin Edge Ft
                                    </Label>
                                    <Input
                                      type="text"
                                      name="finishedEdgeFt"
                                      required
                                      value={
                                        this.state.orderLine.finishedEdgeFt
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="hsSergingCostPer"
                                      required
                                      value={
                                        this.state.orderLine.hsSergingCostPer
                                      }
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalHSSergingCost"
                                      required
                                      value={
                                        this.state.orderLine.totalHSSergingCost
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="hsSergingPricePer"
                                      required
                                      value={
                                        this.state.orderLine.hsSergingPricePer
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateHSSergingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalHSSergingPrice"
                                      value={
                                        this.state.orderLine.totalHSSergingPrice
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="hsSergingCmsnRate"
                                      required
                                      value={
                                        this.state.orderLine.hsSergingCmsnRate
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateHSSergingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="hsSergingCmsn"
                                      value={this.state.orderLine.hsSergingCmsn}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                            {(this.state.selectedRugEdge ===
                              "Hidden Stitch Mitered Corners" ||
                              this.state.selectedRugEdge ===
                                "Applied Machine Border") && (
                              <React.Fragment>
                                <Row className="mb-4">
                                  {/* <Col xs="1"></Col> */}
                                  <Col xs="4">
                                    <Label className="m-0 p-0">
                                      Binding Style
                                    </Label>
                                    <Select
                                      options={this.state.rugBindingList} // should be a rugBindingList
                                      value={
                                        this.state.orderLine
                                          .selectedRugBindingStyle
                                      }
                                      onChange={(option) =>
                                        this.setSelectedRugBindingStyle(option)
                                      }
                                      name="selectedRugBindingStyle"
                                    />
                                    {this.state.selectedRugEdge ===
                                      "Hidden Stitch Mitered Corners" && (
                                      <span style={{ fontWeight: "bold" }}>
                                        Remember to choose a binding wide
                                        enough!
                                      </span>
                                    )}
                                  </Col>
                                  <Col xs="3">
                                    <Label className="m-0 p-0">Color</Label>
                                    <Select
                                      options={this.state.rugBindingColorList}
                                      value={
                                        this.state.orderLine
                                          .selectedRugBindingColor
                                      }
                                      onChange={this.handleRugFormSelectChange.bind(
                                        this,
                                        "selectedRugBindingColor"
                                      )}
                                      name="selectedRugBindingColor"
                                      // (change)="setRugValidity()"
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">
                                      Fin Edge Ft
                                    </Label>
                                    <Input
                                      type="text"
                                      name="finishedEdgeFt"
                                      required
                                      value={
                                        this.state.orderLine.finishedEdgeFt
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cost</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBindingCostPer"
                                      required
                                      value={
                                        this.state.orderLine.rugBindingCostPer
                                      }
                                      readOnly
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalRugBindingCost"
                                      required
                                      value={
                                        this.state.orderLine.totalRugBindingCost
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Price</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBindingPricePer"
                                      required
                                      value={
                                        this.state.orderLine.rugBindingPricePer
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateRugBindingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="totalRugBindingPrice"
                                      value={
                                        this.state.orderLine
                                          .totalRugBindingPrice
                                      }
                                      readOnly
                                    />
                                  </Col>
                                  <Col>
                                    <Label className="m-0 p-0">Cmsn %</Label>
                                    <Input
                                      type="text"
                                      className="rt"
                                      name="rugBindingCmsnRate"
                                      required
                                      value={
                                        this.state.orderLine.rugBindingCmsnRate
                                      }
                                      onChange={(event) =>
                                        this.handleRugOrderLineStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        this.updateRugBindingTotals()
                                      }
                                    />
                                    <Input
                                      type="text"
                                      className="total-text"
                                      name="rugBindingCmsn"
                                      value={
                                        this.state.orderLine.rugBindingCmsn
                                      }
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
