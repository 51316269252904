import React from 'react';
import helpers from '../utils/helpers';
//import _ from 'lodash';
import { Container, Col, Row, Card, CardBody, Alert, Button, Label, Input } from 'reactstrap';
import api from '../utils/api';
import DCLogo from '../assets/img/dc-logo.jpg';
import { Link, Redirect } from 'react-router-dom';


export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            messageFlavor: null,
            redirect: false,
            email: props.location.state.email
        };
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    handleEmail(event) {
        this.setState({ email: event.target.value });
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.email) {
            warnings.push("Email address required");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(','),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    handleSubmit() {
        if (!this.isFormValid()) return;
        //const payload = { email: this.state.email };
        api.fetch('Public/ForgotPassword/' + this.state.email)
            .then((response) => {
                if (response.data.success === true) {
                    this.setState({ messageFlavor: "success", message: "If we have a user with that address, we've just sent a reset link!" })
                    setTimeout(() => {
                        this.setState({ Redirect: true });
                    }, 5000);
                } else {
                    this.setState({ message: response.data.message });
                }
            }).catch(helpers.catchHandler);
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to='/' />;
        }
        return (
            <Container className="mt-5">
                <Row>
                    <Col>
                        <a href='/'><img id="DCLogo" src={DCLogo} alt="DC Logo" width="500" /></a>
                    </Col>
                    <Col className="ml-3">
                        <Card>
                            <CardBody>
                                <div>
                                    <Link to='/'> Wait, I already have an account - take me to log in!</Link>
                                </div>
                                <div>
                                    {this.state.message !== null
                                        ? <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                        : null
                                    }
                                </div>
                                <div>
                                    <form>
                                        <Row className="mt-2 mb-2">
                                            <Col>
                                                <Label className="Label">Enter your e-mail address and we will send you a reset link.</Label>
                                                <Input type="text"
                                                    value={this.state.email}
                                                    onChange={this.handleEmail}
                                                    placeholder="Email"
                                                    maxLength="150"
                                                />
                                            </Col>
                                        </Row>
                                        <Button className="primary float-right" onClick={this.handleSubmit}>
                                            Reset Password
                                        </Button>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ForgotPassword;