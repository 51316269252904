import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Link, Redirect, NavLink as DomNavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "react-s-alert";
import makeAnimated from "react-select/animated";
import { DebounceInput } from "react-debounce-input";
import Sidebar from "react-sidebar";
import Switch from "react-switch";
import moment from "moment";
import axios from "axios";
// will implement this when I change to all the product colors being single elements on the page
import { PinterestShareButton } from "react-simple-share";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardImg,
  CardColumns,
  CardSubtitle,
  Alert as AlertStrap,
  Input,
  Modal,
  FormGroup,
  Form,
  Nav,
  Navbar,
  NavItem,
  Spinner,
  Table,
  Carousel,
  CarouselCaption,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  NavLink,
  TabContent,
  TabPane,
  CardDeck,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  api,
  helpers,
  filter_helpers,
  constants,
  date_helpers,
  calc,
} from "../utils";
import { FilterText, FilterMultiSelect, SortHeader, FilterSet } from "../components";
import Select from "react-select";
import { data } from "jquery";
import { object } from "underscore";

export default class SqOrders extends Component {
    constructor(props){
        super(props);
        const token = api.customerToken();
        let lastOrderFilters = null;
        try {
          lastOrderFilters = localStorage.getItem('lastOrderFilters');
        } catch {}
        const defaultFilters = lastOrderFilters
          ? JSON.parse(lastOrderFilters)
          : [{filterName: "createdAfter", value: moment().add(-90, 'days').valueOf(), filteredDisplay: "Created in the last 90 days"}];
        _.each(defaultFilters, f => {
          if ((f.filterName === 'createdAfter' || f.filterName === 'createdBefore' || f.filterName === 'createdAt')) {
            f.value = date_helpers.getMomentFromString(f.value).valueOf();
          }
        });
        this.state = {
            pagedList: true, //change back to null later!!!
            sortColumn: 'CreatedAt',
            sortDirection: constants.SORT_DIRECTION.descending,
            filters: defaultFilters,
            errorMessage: "",
            ordersList: null,
            orderId: null,
            fetching: false,
            orderStatusList: [],
            deletingRevivingId: null // for loading indicator because this operation is slow with a lot of rows
        };
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.doSort = this.doSort.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    componentDidMount(){
      api.fetch('Reference/GetOrderStatusList')
      .then(response => {
        this.setState({
          orderStatusList: response.data
        });
      })
      this.refreshList();
    }

    refreshList(){
      let payload = {
        page: this.state.pagedList && this.state.pagedList.pageNumber
          ? this.state.pagedList.pageNumber
          : 1,
        pageSize: this.state.pagedList && this.state.pagedList.pageSize
          ? this.state.pagedList.pageSize
          : 30,
          sortColumn: this.state.sortColumn,
          sortDirection: this.state.sortDirection 
      };
      _.each(this.state.filters, filter => {
        if(filter.value){
          switch(filter.filterName){
            case 'createdAfter':
            case 'createdBefore':
            case 'createdAt':
              payload[filter.filterName] = date_helpers.getMomentFromString(filter.value).format(date_helpers.YMD);
              break;
            default:
              payload[filter.filterName] = filter.value;
              break;
          }
        }
      });
      this.setState({fetching: true}, ()=>{
        api.post('Order/GetPublicOrderList', payload).then((response) =>{
          let pagedList = response.data;
          pagedList.list = _.map(pagedList.list, i => ({
            ...i,
            totalPrice: i.totalPriceCurrency
          }));
          this.setState({pagedList: pagedList, deletingRevivingId: null});
        })
        .catch(helpers.catchHandler)
        .finally(() => this.setState({fetching: false}));
      });
    }

    render(){
      return(
        <Container>
          <div>Public Order Page</div>
          <Card>
            <CardBody>
              {this.state.errorMessage &&(
                <Row>
                  <Col>
                    <Alert className="danger">{this.state.errorMessage}</Alert>
                  </Col>
                </Row>
              )}
              <Row className="row-spacing">
                <Col>
                  <FilterSet filter={this.state.filter} clearFilters={this.clearFilters}>
                    <Row>
                      <Col xs="3">
                        <FilterText 
                          filterName="OrderNumber"
                          displayName="Order Number"
                          value={this.currentFilterValue('OrderNumber')}
                          onChangeCallback={this.filterChange}
                        />
                        <FilterText 
                          filterName="createdAt"
                          displayName="Created On"
                          value={this.currentFilterValue('createdAt')}
                          onChangeCallback={this.filterChange}
                        />
                        <FilterText 
                          filterName="CustomerName"
                          displayName="Account name"
                          value={this.currentFilterValue('CustomerName')}
                          onChangeCallback={this.filterChange}
                        />
                        <FilterText 
                          filterName="OrderStatusList"
                          displayName="Order Status"
                          value={this.currentFilterValue('OrderStatusList')}
                          onChangeCallback={this.filterChange}
                        />
                        <FilterText 
                          filterName="account"
                          displayName=""
                          value={this.currentFilterValue('')}
                          onChangeCallback={this.filterChange}
                        />
                      </Col>
                    </Row>
                  </FilterSet>
                </Col>
              </Row>
              <Row className="row-spacing">
                <Col>
                {this.state.pagedList ?
                (<Fragment>
                  <Table>
                    <thead>
                      <tr>
                      <th>
                                                <SortHeader
                                                    displayName="Order #"
                                                    field="orderNumber"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'orderNumber'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Created"
                                                    field="createdAt"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'createdAt'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Status"
                                                    field="orderStatusName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'orderStatusName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Account"
                                                    field="customerName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'customerName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>Total</th>
                                              <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pagedList && this.state.pagedList.list && this.state.pagedList.list.map(orderItem => (
                        <tr key={orderItem.id}>
                          <td>{orderItem.orderNumber}</td>
                          <td>{moment(orderItem.createdAt).format(date_helpers.MDY)}</td>
                          <td>{orderItem.orderStatusName}</td>
                          <td>{orderItem.customerName}</td>
                          <td>{orderItem.totalPrice}</td>
                          <td className="text-right">
                            <ButtonGroup>
                              {!orderItem.deactivatedAt ? (
                                <React.Fragment>
                                  <Button
                                    //TODO: delete order record button
                                    className="danger btn-outline-secondary"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon="trash"/>
                                  </Button>
                                </React.Fragment>
                              ): null}
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Fragment>)
                :null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      );
    }
}