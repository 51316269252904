import React, { Component, Fragment } from 'react';
import { Collapse, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MobileFilterSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: (props.open !== undefined ? props.open : false),
            clearFilters: props.clearFilters
        };
        // this.toggleFilter = this.toggleFilter.bind(this);
        this.getFilterDisplay = this.getFilterDisplay.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.open !== this.props.open) {
        // if (this.props.clearValue === true ) {
          this.setState({ collapse:  this.props.open});
        // }
      }
    }

    getFilterDisplay(filters) {
        if (!filters || !filters.length || filters.length === 0) {
            return null;
        }
        const filterDisplay = filters.map(x => (((x.value !== null && x.value !== undefined)
                || (x.values && x.values.length && x.values.length > 0) 
                || x.startDate
                || x.endDate)
            ? x.filteredDisplay
            : null)).join(' | ');
        if (!filterDisplay) {
            return '';
        }
        return [<span className='title' key='applied-filter-title'>Applied Filters: </span>, filterDisplay];
    }

    // toggleFilter() {
    //     this.setState({ collapse: !this.state.collapse });
    // }

    render() {
        const filterDisplayText = this.getFilterDisplay(this.props.filters);
        return (
          <React.Fragment>
            <Row>
              <Col>
                {/* <div style={{borderTop: "2px solid", borderBottom: "2px solid"}}> */}
                  <span
                      title="Click here to toggle filter display"
                      className="filter"
                      onClick={this.props.toggleMobileFilters}
                      size="sm"
                  >
                      <FontAwesomeIcon icon="filter" /> Filters
                  </span>
                  {this.state.collapse &&
                    <Button
                        className="secondary btn-outline-secondary float-right"
                        size="sm"
                        onClick={this.props.hideMobileFilters}
                    >
                        Done
                    </Button>
                  }
                {/* </div> */}
              </Col>
            </Row>
            <Collapse 
              isOpen={this.state.collapse}
              className="row-spacing filter-definition-row"
            >
              <Row>
                  <Col>
                      {this.props.children}
                  </Col>
              </Row>
            </Collapse>
          </React.Fragment>
        );
    }
}

export default MobileFilterSet;