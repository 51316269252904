import React, { Component, Fragment } from 'react';
import {
    Alert,
    Container,
    Table,
    Button,
    ButtonGroup,
    CardBody,
    Row,
    Col,
    Card,
    Input,
    Label,
    Collapse
} from 'reactstrap';
import {
    api,
    helpers,
    filter_helpers
} from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    FilterBoolean,
    FilterSet,
    FilterText,
    SortHeader
} from '../components';
import _ from 'lodash';

const apiUrl = 'Reference/';

const emptyFC = {
    id: 0,
    name: ""
};

export default class FreightCarrierAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            item: emptyFC,
            newItem: emptyFC,
            filters: [{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Yes" }],
            message: null,
            messageFlavor: null,
            showAddNew: false,
            sortField: "Name",
            sortDir: "asc"
        };
        this.refreshList = this.refreshList.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.handleNewItemFormInput = this.handleNewItemFormInput.bind(this);
        this.handleFormInput = this.handleFormInput.bind(this);
        this.toggleAddNewCollapse = this.toggleAddNewCollapse.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleUnDelete = this.handleUnDelete.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
      this.refreshList();
    }

    refreshList(sortField, sortDirection, filters) {
        let filterList = filters || this.state.filters;
        let payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir
        }

        _.each(filterList, filter => payload[filter.filterName] = filter.value);
        
        api.post(apiUrl + 'GetFreightCarrierPaginatedList', payload)
            .then((response) => {
                this.setState({
                    list: response.data.list
                })
            })
            .catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    toggleAddNewCollapse() {
      this.setState({
          showAddNew: !this.state.showAddNew,
          newItem: emptyFC,
          message: null,
          messageFlavor: null
      });
  }

    handleNewItemFormInput(event) {
        this.setState({ newItem: {...this.state.newItem, [event.target.name]: event.target.value } });
    }

    handleFormInput(event) {
        this.setState({ item: {...this.state.item, [event.target.name]: event.target.value } });
    }

    onEdit(item) {
        this.setState({item: item})
    }

    onCancelEdit() {
      this.setState({item: emptyFC})
    }

    isFormValid(item) {
        let warnings = [];
        if (!item.name || item.name.length < 1) {
            warnings.push("Please provide a valid name entry.");
        }
        if (warnings.length) {
            this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
        }
        else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onSubmit(e, item) {
        if (!this.isFormValid(item)) return;
        const payload = {
            id: item.id,
            name: item.name,
        };
        api.post("Reference/SaveFreightCarrier", payload)
				.then(response => {
					if (response.data.success) {
            this.setState({
              showAddNew: item.id ? this.state.showAddNew : !this.state.showAddNew,
              newItem: emptyFC,
              item: emptyFC,
              message: null,
              messageFlavor: null
            }, () => this.refreshList());
					} else {
						this.setState({ messageFlavor: "danger", message: response.data.message });
					}
				})
				.catch(helpers.catchHandler);
    }

    handleUnDelete(id) {
        api.post(`Reference/ReviveFreightCarrierItem/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api.post(`Reference/DeleteFreightCarrierItem/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    render() {
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message !== null && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col sm="12">
                                <h3 className="pull-left page-title">Freight Carriers</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                              {this.state.showAddNew
                                ? null
                                :   <ButtonGroup className="float-right">
                                        <Button
                                            size="sm"
                                            className="success"
                                            onClick={() => this.toggleAddNewCollapse()}
                                            title="Add New Freight Carrier"
                                        >
                                            <FontAwesomeIcon icon="plus" /> Add
                                        </Button>
                                    </ButtonGroup>
                                }


                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="6" md="6">
                                            <FilterText
                                                filterName="Name"
                                                displayName="Description"
                                                value={this.currentFilterValue('Name')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="6" md="3">
                                            <FilterBoolean
                                                filterName="ActiveOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                          <Collapse isOpen={this.state.showAddNew} className="row-spacing">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h5 className="page-title">New Freight Carrier</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col xs="4">
                                        <Label>Name {helpers.requiredStar()} </Label>
                                        <Input
                                            type="text"
                                            onChange={this.handleNewItemFormInput}
                                            value={this.state.newItem.name}
                                            name="name"
                                            maxLength="20"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup className="btn-group btn-access float-right">
                                                <Button
                                                    className="btn primary float-right"
                                                    size="sm"
                                                    onClick={(e) => this.onSubmit(e, this.state.newItem)}
                                                >
                                                    <FontAwesomeIcon icon="save" /> Save
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    className="btn secondary"
                                                    onClick={() => this.toggleAddNewCollapse()}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                          </Collapse>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                          <th>
                                            <SortHeader
                                                displayName="Name"
                                                field="Name"
                                                sortDir={this.state.sortDir}
                                                sorted={this.state.sortField === 'Name'}
                                                callBack={this.doSort}
                                            />
                                          </th>
                                          <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.list.length > 0 &&  this.state.list.map(item => (
                                            <Fragment key={item.id}>
                                                {this.state.item.id !== null && item.id === this.state.item.id
                                                    ? <tr>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={this.state.item.name}
                                                                onChange={this.handleFormInput}
                                                                maxLength="20"
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                <Button
                                                                    className="primary btn"
                                                                    onClick={(e) => this.onSubmit(e, this.state.item)}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="save" /> Save
                                                                </Button>
                                                                <Button
                                                                    className="secondary btn"
                                                                    onClick={() => this.onCancelEdit()}
                                                                    size="sm"
                                                                >
                                                                    <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    : <tr>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td className="text-right">
                                                            <ButtonGroup>
                                                                {item.deactivatedAt
                                                                    ?   <Button
                                                                            className="info"
                                                                            size="sm"
                                                                            onClick={() => this.handleUnDelete(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="recycle" /> Revive
                                                                        </Button>
                                                                    :   <React.Fragment>
                                                                            <Button
                                                                                className="primary btn-outline-secondary"
                                                                                onClick={() => this.onEdit(item)}
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon icon="edit" />
                                                                            </Button>
                                                                            <Button
                                                                                className="danger btn-outline-secondary"
                                                                                size="sm"
                                                                                onClick={() => this.handleDelete(item.id)}
                                                                            >
                                                                                <FontAwesomeIcon icon="trash" />
                                                                            </Button>
                                                                        </React.Fragment>
                                                                }
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
