import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardBody,
    Alert,
    ButtonGroup,
    Button
} from 'reactstrap';
import {
    api,
    helpers,
    date_helpers,
    constants,
    filter_helpers
} from '../utils';
import { FilterSet, SortHeader, FilterBoolean, FilterText, Pager, FilterSelect, FilterDate } from '../components';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            messageFlavor: null,
            isMessageVisible: false,
            //ordersList: [],
            pagedList: {
              list: [],
              pageSize: 100,
              pageNumber: 1
            },
          filters: [{
            filterName: "ActiveOnly",
            value: true,
            filteredDisplay: "Active Only: Yes"
          },
            {
              filterName: "OrderStatusId",
              value: constants.ORDER_STATUS.OPEN.value,
              filteredDisplay: `Order Status: ${constants.ORDER_STATUS.OPEN.label}`
            }],
            sortColumn: "CreatedAt",
            sortDirection: constants.SORT_DIRECTION.descending,
            activeOnly: true
      };
      this.refreshList = this.refreshList.bind(this);
      this.setPage = this.setPage.bind(this);
      this.doSort = this.doSort.bind(this);
      this.filterChange = this.filterChange.bind(this);
      this.clearFilters = this.clearFilters.bind(this);
      this.currentFilterValue = this.currentFilterValue.bind(this);

    }

    componentDidMount() {
        this.refreshList();
    }

    //refreshList() {
    //    api
    //      .post('Order/FilterOrders', {
    //          PageSize: 50,
    //          SortColumn: this.state.sortColumn,
    //          SortDirection: this.state.sortDirection,
    //          activeOnly: this.state.activeOnly
    //      })
    //      .then((response) => {
    //          this.setState({
    //              ordersList: response.data
    //          });
    //      })
    //      .catch(helpers.catchHandler);
    //}

    onEdit(order) {
        this.setState({
            editOrder: order
        });
    }

  refreshList(sortField, sortDirection, filters) {
    let filterList = filters || this.state.filters;
    let payload = {
      page: this.state.pagedList.pageNumber,
      pageSize: this.state.pagedList.pageSize,
      SortField: sortField || this.state.sortField,
      SortDir: sortDirection || this.state.sortDir,
    }

    _.each(filterList, filter => payload[filter.filterName] = filter.value);

    api.post('Order/GetPaginatedList', payload).then((response) => {
      let pagedList = response.data;
      pagedList.list = _.map(pagedList.list, i => ({
        ...i,
        totalPrice: i.totalPriceCurrency
      }));
      this.setState({ pagedList: pagedList });
    }).catch(helpers.catchHandler);
  }

  doSort(field, direction) {
    this.setState({ sortDir: direction, sortField: field });
    this.refreshList(field, direction);
  }

  filterChange(changedFilter) {
    const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
    this.setState({ filters: filters, clearValue: false });
    this.refreshList(null, null, filters);
  }

  clearFilters() {
    const filters = [];
    this.setState({ filters: filters, clearValue: true });
    this.refreshList(null, null, filters);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find(f => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return '';
  }

  setPage(page) {
    const newPagedList = Object.assign({}, this.state.pagedList);
    newPagedList.pageNumber = page;
    this.setState({ pagedList: newPagedList }, () => this.refreshList());
  }

  onMessageDismiss() {
    this.setState({ isMessageVisible: !this.state.isMessageVisible });
  }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message && (
                            <Row>
                                <Col>
                                    <Alert className="danger">{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="page-title">Recent Orders</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Order #</th>
                                            <th>Created</th>
                                            <th>Status</th>
                                            <th>Account</th>
                                            <th>Agent</th>
                                            <th>Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.pagedList && this.state.pagedList.list && this.state.pagedList.list.length > 0
                                        && this.state.pagedList.list.map(order => (
                                            <tr key={order.id}>
                                                <td>{order.id}</td>
                                                <td>{date_helpers.dateTimeFormat(order.createdAt, "MM/DD/YYYY hh:mm A")}</td>
                                                <td>{order.orderStatusName}</td>
                                                <td>{order.customerName}</td>
                                                <td>{order.agentName}</td>
                                                <td>{order.totalPrice}</td>
                                                <td className="text-right">
                                                    <ButtonGroup>
                                                        <Link
                                                            to={{
                                                                pathname: '/OrderEntry',
                                                                state: { activeOrderId: order.id },
                                                                push: true
                                                            }}
                                                        >
                                                            <Button
                                                                className="primary btn-outline-secondary"
                                                                size="sm"
                                                            >
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button>
                                                        </Link>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
