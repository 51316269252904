import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Row,
    Table,
    Alert,
    Collapse,
    CardBody,
    Input
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
    api,
    helpers,
    date_helpers,
    constants
} from '../utils';
import {
    SortHeader
} from '../components';

const emptyMemoStockOrder = {
    product: null,
    productColor: null,
    memoSize: null,
    qty: '',
    comment: ''
};

class MemoStockOrders extends Component {
    constructor(props) {
      super(props);
      this.state = {
        memoStockOrders: [],
        filters: [],
        sort_field: 'CreatedAt',
        sort_dir: 'Desc',
        message: null,
        messageFlavor: null,
        memoStockOrderToAddEdit: emptyMemoStockOrder,
        showMemoStockOrderForm: false,
        memoStockReceived: '1',
        editingReceiving: false,
        editingMemoOrder: false
      };
      this.refreshList = this.refreshList.bind(this);
      this.doSort = this.doSort.bind(this);
      this.setSelectedProduct = this.setSelectedProduct.bind(this);
      this.fetchProductMemoSizes = this.fetchProductMemoSizes.bind(this);
      this.downloadLabel = this.downloadLabel.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        api.fetch(`Product/GetAllActiveProducts`)
        .then(response => {
          this.setState({
            productsList: response.data
          })
        })
        .catch(helpers.catchHandler);

        this.refreshList();
    }

    refreshList(sortField, sortDirection) {
      const payload = {
        SortField: sortField || this.state.sort_field,
        SortDir: sortDirection || this.state.sort_dir,
      };
      api.post(`MemoOrder/GetMemoStockOrders`, payload)
      .then(response => {
        this.setState({
          memoStockOrders: _.map(response.data, sto => {
            return {
              id: sto.id,
              createdAt: date_helpers.formatDateToShortDate(sto.createdAt),
              memoSize: helpers.resolveValue(null, sto.memoSizeId, sto.memoSizeName),
              qty: helpers.nullableString(sto.qty),
              product: helpers.resolveValue(null, sto.productId, sto.productDCName),
              productDCName: helpers.nullableString(sto.productDCName),
              productDCNumber: helpers.nullableString(sto.productDCNumber),
              productSupplierName: helpers.nullableString(sto.productSupplierName),
              productSupplierNumber: helpers.nullableString(sto.productSupplierNumber),
              productColor: helpers.resolveValue(null, sto.productColorId, sto.productColorName),
              productColorName: helpers.nullableString(sto.productColorName),
              productColorNumber: helpers.nullableString(sto.productColorNumber),
              supplier:helpers.nullableString(sto.supplier),
              comment: helpers.nullableString(sto.comment),
              fiber: helpers.nullableString(sto.fiber),
              deactivatedAt: null,
              productMemoSize: sto.productMemoSize,
              productMemoShelfNumber: helpers.nullableString(sto.productMemoShelfNumber),
              inventory: sto.productMemoSize ? sto.productMemoSize.qty : '0',
              qtyReceived: sto.qtyReceived
            }
          }),
          editingReceiving: false,
          memoStockReceived: null,
          memoStockOrderToAddEdit: emptyMemoStockOrder,
        });
      })
      .catch(helpers.catchHandler);
    }   

    doSort(field, direction) {
      this.setState({ sort_dir: direction, sort_field: field });
      this.refreshList(field, direction);
    }

    toggleAddMemoStockOrderCollapse() {
      this.setState({
        showMemoStockOrderForm: !this.state.showMemoStockOrderForm,
        memoStockOrderToAddEdit: emptyMemoStockOrder,
        message: null,
        messageFlavor: null,
        productColorsList: [],
        selectedColorMemoSizeList: [],
        editingMemoOrder: !this.state.editingMemoOrder,
        allProductMemoSizes: []
      });
    }

    setSelectedProduct(option) {
      api.fetch(`Order/GetActiveProductColors/${option.value}`)
      .then(response => {
        this.setState({
          productColorsList: response.data,
          memoStockOrderToAddEdit: {
            ...this.state.memoStockOrderToAddEdit,
            product: option, 
            productColor: response.data.length > 0 ? response.data[0] : null
          }
        }, () => this.fetchProductMemoSizes(option));
      })
      .catch(helpers.catchHandler);
    }

    fetchProductMemoSizes(option) {
      api.fetch(`Product/GetProduct/${option.value}`)
      .then(response => {
          let selectedProductColorId = this.state.memoStockOrderToAddEdit.productColor ? this.state.memoStockOrderToAddEdit.productColor.value : null;
          let selectedColorMemoSizeList = _.filter(response.data.productMemoSizes, function(x) {
              return x.productColorId === selectedProductColorId
          } )
          this.setState({
            selectedColorMemoSizeList: _.map(selectedColorMemoSizeList, ms => {
                return {
                ...ms,
                value: ms.memoSizeId,
                label: ms.memoSizeName
                }
            }),
            memoStockOrderToAddEdit: {
                ...this.state.memoStockOrderToAddEdit,
                memoSize: selectedColorMemoSizeList.length > 0 ? helpers.resolveValue(null, selectedColorMemoSizeList[0].memoSizeId, selectedColorMemoSizeList[0].memoSizeName) : null
            },
            allProductMemoSizes: response.data.productMemoSizes
          });
      })
      .catch(helpers.catchHandler);
    }

    setSelectedProductColor(option) {
      let selectedProductColorId = option.value;
      let selectedColorMemoSizeList = _.filter(this.state.allProductMemoSizes, function(x) {
          return x.productColorId === selectedProductColorId
      });
      this.setState(prevState => ({
        ...prevState,
        memoStockOrderToAddEdit: {
            ...prevState.memoStockOrderToAddEdit,
            productColor: option,
            memoSize: selectedColorMemoSizeList.length > 0 ? helpers.resolveValue(null, selectedColorMemoSizeList[0].memoSizeId, selectedColorMemoSizeList[0].memoSizeName) : null
        },
        selectedColorMemoSizeList: _.map(selectedColorMemoSizeList, ms => {
          return {
            ...ms,
            value: ms.memoSizeId,
            label: ms.memoSizeName
          }
        }),
      }));
    }

    setSelectedMemoSize(option) {
      this.setState(prevState => ({
          ...prevState,
          memoStockOrderToAddEdit: {
              ...prevState.memoStockOrderToAddEdit,
              memoSize: option
          }
      }));
    }

    handleEdit(stockId){
      let memoStock = _.find(
        this.state.memoStockOrders.slice(),
        ms => {
          return  ms.id === stockId
        }
      );
      this.setState({
        memoStockOrderToAddEdit: memoStock,
        showMemoStockOrderForm: true,
        editingMemoOrder: true
      });
      document.getElementById("addEditMemoStockOrder").scrollIntoView();
    }

    handleMemoStockOrderStateChange(fieldName, value) {
      this.setState({
        memoStockOrderToAddEdit: {
              ...this.state.memoStockOrderToAddEdit,
              [fieldName]: value
          }
      });
    }

    isFormValid(item) {
      let warnings = [];
      if (!item.product) {
          warnings.push("Product is required.");
      }
      if(!item.productColor) {
          warnings.push("Product color is required.");
      }
      if(!item.memoSize) {
          warnings.push("Memo size is required.");
      }
      if(!item.qty) {
        warnings.push("Quantity is required.");
      }
      if (warnings.length) {
          this.setState({
              message: warnings.join(' '),
              messageFlavor: "danger"
          });
      } else {
          this.setState({ message: null });
      }
      return warnings.length === 0;
    }

    saveNewMemoStockOrder() {
      if (!this.isFormValid(this.state.memoStockOrderToAddEdit)) return;
      const memoStockOrderToAddEdit = Object.assign({}, this.state.memoStockOrderToAddEdit);
      const data = {
        Id: memoStockOrderToAddEdit.id > 0 ? memoStockOrderToAddEdit.id : 0, //change
        ProductId: memoStockOrderToAddEdit.product.value,
        ProductColorId: memoStockOrderToAddEdit.productColor.value,
        MemoSizeId: memoStockOrderToAddEdit.memoSize.value,
        Qty: Number(memoStockOrderToAddEdit.qty),
        Comment: memoStockOrderToAddEdit.comment
      };

      api.post("MemoOrder/SaveMemoStockOrder", data)
      .then(response => {
          if (response.data.success) {
              this.setState({
                showMemoStockOrderForm: false,
                memoStockOrderToAddEdit: emptyMemoStockOrder,
                editingMemoOrder: false
              }, () => this.refreshList());
          } else {
              this.setState({
                  messageFlavor: "danger",
                  message: response.data.message
              });
          } 
      })
      .catch(helpers.catchHandler);
    }

    saveAndReceiveNewMemoStockOrder() {
      if (!this.isFormValid(this.state.memoStockOrderToAddEdit)) return;
      const memoStockOrderToAddEdit = Object.assign({}, this.state.memoStockOrderToAddEdit);
      const data = {
        Id: memoStockOrderToAddEdit.id > 0 ? memoStockOrderToAddEdit.id : 0,
        ProductId: memoStockOrderToAddEdit.product.value,
        ProductColorId: memoStockOrderToAddEdit.productColor.value,
        MemoSizeId: memoStockOrderToAddEdit.memoSize.value,
        Qty: Number(memoStockOrderToAddEdit.qty),
        Comment: memoStockOrderToAddEdit.comment
      };

      api.post("MemoOrder/SaveAndReceiveMemoStockOrder", data)
      .then(response => {
          if (response.data.success) {
              this.setState({
                showMemoStockOrderForm: false,
                memoStockOrderToAddEdit: emptyMemoStockOrder,
                editingMemoOrder: false
              }, () => this.refreshList());
          } else {
              this.setState({
                  messageFlavor: "danger",
                  message: response.data.message
              });
          } 
      })
      .catch(helpers.catchHandler);
    }

    markMemoStockLineAsReceiving(memoStockOrderId) {
      const indexToEdit = _.findIndex(this.state.memoStockOrders, {id: memoStockOrderId});
      let tempList = this.state.memoStockOrders.slice();
      const snapshotItem = Object.assign({}, tempList[indexToEdit]);
      tempList[indexToEdit].isEditing = true;
      tempList[indexToEdit].pristine = snapshotItem;
      this.setState({
        memoStockOrders: tempList,
        editingReceiving: true
      });
    }

    cancelReceiveMemoStockOrder(memoStockOrderId) {
      const indexToEdit = _.findIndex(this.state.memoStockOrders, {id: memoStockOrderId});
      let tempList = this.state.memoStockOrders.slice();
        tempList[indexToEdit] = tempList[indexToEdit].pristine;
        this.setState({
          memoStockOrders: tempList,
          memoStockReceived: '',
          message: null,
          messageFlavor: null,
          editingReceiving: false
        });
    }

    isReceiveFormValid(memoStockOrderQty) {
      let warnings = [];
      if (!this.state.memoStockReceived || this.state.memoStockReceived < 1) {
        warnings.push("Number to receive is required and must be greater than 0.");
      }
      // if (this.state.memoStockReceived > memoStockOrderQty) {
      //   warnings.push("Number to receive must be less than quantity ordered.");
      // }
      if (warnings.length) {
        this.setState({
          message: warnings.join(' '),
          messageFlavor: "danger"
        });
      } else {
        this.setState({ message: null });
      }
      return warnings.length === 0;
    }

    receiveMemoStockOrder(memoStockOrderId, memoStockOrderQty, productMemoSize) {
      if (!this.isReceiveFormValid(memoStockOrderQty)) return;
      const data = {
        Id: productMemoSize.id,
        ProductId: productMemoSize.productId,
        MemoSizeId: productMemoSize.memoSizeId,
        ProductColorId: productMemoSize.productColorId,
        Qty: Number(productMemoSize.qty),
        MemoStockReceived: Number(this.state.memoStockReceived),
        MemoStockOrderId: memoStockOrderId
      };
      api.post(`MemoOrder/UpdateProductMemoSizeInventory`, data).then(response => {
        if (response.data.success) {
          this.refreshList()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }  
      }).catch(helpers.catchHandler);
    }

    handleDelete(id) {  // hard delete
      api.post(`MemoOrder/HardDeleteMemoStockOrder/${id}`)
      .then(response => {
          if (response.data.success) { 
            this.refreshList()
            this.setState({
              messageFlavor: "success",
              message: "Memo Stock Order deleted."
            }, () => {
                setTimeout(() => {
                  this.setState({ messageFlavor: null, message: null })
                }, 400)
            })
            
          } else {
              this.setState({ messageFlavor: "danger", message: response.data.message });
          }  
      }) 
      .catch(helpers.catchHandler);
    }

  downloadLabel(memoStockOrderId) {
    api.post(`MemoOrder/DownloadShippingLabel/${memoStockOrderId}`, {}, "blob").then(response => {
        if (response.status === 200) {
          helpers.downloadExport(response.data, 'Shipping_Label.xlsx', constants.MIME_XLSX);
        } else {
          throw new Error("Error downloading shipping label");
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
        return (
            <div>
              {this.state.message && (
                <Row className="mb-2">
                  <Col>
                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                  </Col>
                </Row>
              )}
              <Row className="row-spacing">
                <Col>
                  {this.state.showMemoStockOrderForm
                      ? null
                      :   <ButtonGroup className="float-right">
                            <Button
                              size="sm"
                              className="success"
                              onClick={() => this.toggleAddMemoStockOrderCollapse()}
                              title="Add New OrderLine"
                            >
                              <FontAwesomeIcon icon="plus" /> Add
                            </Button>
                          </ButtonGroup>
                  }
                  <h4>Memo Orders for Stock Awaiting Delivery</h4>
                </Col>
              </Row>
              <Collapse isOpen={this.state.showMemoStockOrderForm} className="row-spacing">
                <Card id="addEditMemoStockOrder">
                  <CardBody>
                    <Row>
                        <Col>
                            <h5 className="page-title">Add New Stock Order</h5>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>Product {helpers.requiredStar()}
                          <Select
                              closeMenuOnSelect
                              value={this.state.memoStockOrderToAddEdit.product}
                              options={this.state.productsList}
                              onChange={(option) => this.setSelectedProduct(option)}
                          />
                      </Col>
                      <Col>Color {helpers.requiredStar()}
                          <Select
                              closeMenuOnSelect
                              value={this.state.memoStockOrderToAddEdit.productColor}
                              options={this.state.productColorsList}
                              onChange={(option) => this.setSelectedProductColor(option)}
                          />
                      </Col>
                      <Col>Size {helpers.requiredStar()}
                          <Select
                              closeMenuOnSelect
                              value={this.state.memoStockOrderToAddEdit.memoSize}
                              options={this.state.selectedColorMemoSizeList}
                              onChange={(option) => this.setSelectedMemoSize(option)}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">Comment
                        <Input
                            type="textarea"
                            name="comment"
                            onChange={(event) => this.handleMemoStockOrderStateChange(
                                event.target.name,
                                event.target.value
                            )}
                            value={this.state.memoStockOrderToAddEdit.comment}
                            placeholder="Enter A Comment"
                            maxLength="500"
                            style={{height: "105px"}}
                        />
                      </Col>
                      <Col>Quantity {helpers.requiredStar()}
                        <Input
                            type="number"
                            step="1"
                            name="qty"
                            onChange={(event) => this.handleMemoStockOrderStateChange(
                                event.target.name,
                                event.target.value
                            )}
                            value={this.state.memoStockOrderToAddEdit.qty}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ButtonGroup className="mt-3 float-right">
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() =>  this.saveNewMemoStockOrder()}
                          >
                            <FontAwesomeIcon icon="save" /> Save
                          </Button>
                        {this.state.memoStockOrderToAddEdit.id > 0 ? null :
                          <Button
                            size="sm"
                            color="info"
                            onClick={() =>  this.saveAndReceiveNewMemoStockOrder()}
                          >
                            <FontAwesomeIcon icon="save" /> Save &amp; Receive 
                          </Button>}
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => this.toggleAddMemoStockOrderCollapse()}
                          >
                            <FontAwesomeIcon icon="times-circle" /> Cancel
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
              <Row className="row-spacing">
                  <Col>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th style={{width:"8%"}}>
                            <SortHeader
                              displayName="Placed On"
                              field="CreatedAt"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'CreatedAt'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th style={{width:"7%"}}>
                            <SortHeader
                              displayName="Size"
                              field="MemoSize"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'MemoSize'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th style={{width:"5%"}}>
                            <SortHeader                                      
                              displayName="Shelf #"
                              field="productMemoShelfNumber"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'productMemoShelfNumber'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th style={{width:"5%"}}>
                            <SortHeader
                              displayName="Qty"
                              field="Qty"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'Qty'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th>
                            <SortHeader
                              displayName="Supplier Product"
                              field="ProductSupplierName"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'ProductSupplierName'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th>
                            <SortHeader
                              displayName="DC Product"
                              field="ProductDCName"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'ProductDCName'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th style={{width:"10%"}}>
                            <SortHeader
                              displayName="Color"
                              field="ProductColor"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'ProductColor'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th style={{width:"5%"}}>
                            Inventory
                          </th>
                          <th style={{width:"15%"}}>
                            <SortHeader
                              displayName="Comment"
                              field="Comment"
                              sortDir={this.state.sort_dir}
                              sorted={this.state.sort_field === 'Comment'}
                              callBack={this.doSort}
                            />
                          </th>
                          <th></th>
                          <th width="15%" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.memoStockOrders.map((sto, index) => (
                          <tr key={`ms${index}-${sto.id}`} className="data-row">
                            <td>{sto.createdAt}</td>
                            <td>{sto.memoSize.label}</td>
                            <td>{sto.productMemoShelfNumber}</td>
                            <td>{sto.qty - sto.qtyReceived}</td>
                            <td>
                              <div>
                                {sto.supplier}
                              </div>
                              <div>
                                {`${sto.productSupplierName} ${sto.productSupplierNumber}`} 
                              </div>
                            </td>
                            <td>
                              {`${sto.productDCName} ${sto.productDCNumber}`} 
                            </td>
                            <td>{sto.productColor.label} {sto.productColorNumber}</td>
                            <td>{sto.inventory}</td>  
                            <td>{sto.comment}</td>
                            <td>
                              {sto.isEditing
                                ? <React.Fragment>
                                    <div>
                                      <Input
                                        type="number"
                                        step="1"
                                        name="memoStockReceived"
                                        onChange={(event) => this.setState({ memoStockReceived: event.target.value})}
                                        value={this.state.memoStockReceived ? this.state.memoStockReceived : ""}
                                      />
                                    </div>
                                    <div>
                                      <ButtonGroup>
                                        <Button
                                          outline
                                          color="primary"
                                          onClick={() => this.receiveMemoStockOrder(sto.id, sto.qty, sto.productMemoSize)}
                                          size="sm"
                                        >
                                          OK
                                        </Button>
                                        <Button
                                          color="secondary"
                                          onClick={() => this.cancelReceiveMemoStockOrder(sto.id)}
                                          size="sm"
                                        >
                                          Cancel
                                        </Button>
                                      </ButtonGroup>
                                    </div>
                                  </React.Fragment>
                                : <Button
                                    outline
                                    className="primary"
                                    onClick={() => this.markMemoStockLineAsReceiving(sto.id)}
                                    size="sm"
                                    disabled={this.state.editingReceiving || this.state.editingMemoOrder}
                                  >
                                    Receive
                                  </Button>
                              }
                            </td>                       
                            <td className="text-right">
                              <ButtonGroup>
                                {sto.deactivatedAt === null ? (
                                  <React.Fragment>
                                    <Button 
                                      color="primary"
                                      size="sm"
                                      onClick={() => this.handleEdit(sto.id)}
                                      disabled={this.state.editingReceiving || this.state.editingMemoOrder}
                                    >
                                      <FontAwesomeIcon icon="edit" />
                                    </Button>
                                    <Button
                                      color="secondary"
                                      onClick={() => this.downloadLabel(sto.id)}
                                      size="sm"
                                      disabled={this.state.editingReceiving || this.state.editingMemoOrder}
                                    >
                                      Shipping Label
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      size="sm"
                                      disabled={this.state.editingReceiving || this.state.editingMemoOrder}
                                      onClick={() => this.handleDelete(sto.id)}
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <Button
                                    className="info"
                                    size="sm"
                                    onClick={() => this.handleUnDelete(sto.id)}
                                    disabled={this.state.editingReceiving || this.state.editingMemoOrder}
                                  >
                                    <FontAwesomeIcon icon="recycle" /> Revive
                                  </Button>
                                )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
              </Row>
            </div>
        );
    }
}

export default MemoStockOrders;
