import React, { Component, Fragment } from "react";
import {
  Card,
  Button,
  CardBody,
  Row,
  Col,
  Label,
  ButtonGroup,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import _ from "underscore";
import { DropzoneUploader } from '../components';
import DatePicker from "react-datepicker";
import { api, helpers, date_helpers, constants } from "../utils";
import Alert from "react-s-alert";

const renderExtension = (typeOfRender) => {
  switch(typeOfRender) {
    case constants.RENDER_TYPES.Excel:
      return ".xlsx";
    case constants.RENDER_TYPES.CSV:
      return ".csv";
    case constants.RENDER_TYPES.PDF:
      return ".pdf";
    default:
      return "";
  }
};

const renderMimeType = (typeOfRender) => {
  switch(typeOfRender) {
    case constants.RENDER_TYPES.Excel:
      return constants.MIME_XLSX;
    case constants.RENDER_TYPES.CSV:
      return constants.MIME_CSV;
    case constants.RENDER_TYPES.PDF:
      return constants.MIME_PDF;
    default:
      return "application/octet-stream";
  }
};

const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
};

export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportGroups: [],
      flatReports: [],
      parameters: [],
      displayTemplateModal: false,
      templateFile: null,
      // defaultParameters: [],
      selectedReport: null
    };

    this.saveReportTemplate = this.saveReportTemplate.bind(this);
    this.showTemplateModal = this.showTemplateModal.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.onReportSelect = this.onReportSelect.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.parseParameters = this.parseParameters.bind(this);
    this.setParameterValue = this.setParameterValue.bind(this);
    this.loadApiListOptions = this.loadApiListOptions.bind(this);
    this.onDropdownSelection = this.onDropdownSelection.bind(this);
  }

  componentDidMount() {
    api.fetch("report/reports").then(response => {
      const reportGroups = _.map(response.data, rg => {
         return {
           ...rg,
           reports: _.map(_.filter(rg.reports, r => r.enabled), r => {
             return {
               ...r,
               groupName: rg.name,
               label: r.name, // ${r.description}`,
               value: r.name
             };
           }),
           label: rg.name,
           value: rg.name
         };
      });
      let flatReports = [];
      _.each(reportGroups, rg => {
        _.each(rg.reports, rpt => {
          flatReports.push(rpt);
        });
      });
      this.setState({ reportGroups: reportGroups, flatReports: _.sortBy(flatReports, r => r.label)});
    }).catch((error) => console.error(error));
  }

  isFormValid(report, params) {
    let warnings = [];
    _.each(report.parameters, rp => {
      if (!rp.hidden && rp.required) {
        switch(rp.parameterType) {
          case constants.REPORT_PARAMETER_TYPES.Text:
            if (!rp.value) {
              warnings.push(`Enter a ${rp.prompts[0]} value`);
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.DateRange:
            if (!rp.value || !rp.value.length) {
              warnings.push('Select valid date from and date through values');
            // } else if (!moment.isMoment(rp.value[0].value) || !moment.isMoment(rp.value[1].value)) {
            //   warnings.push('Date from and date through must be valid dates');
            } else if (date_helpers.getMomentFromString(rp.value[0]).isAfter(date_helpers.getMomentFromString(rp.value[1]))) {
              warnings.push('Date from cannot be after date through');
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Date:
            if (!rp.value) {
              warnings.push(`Select a valid ${rp.prompts[0]} value`);
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.ApiList:
            if (rp.multi) {
              if (!rp.value || !rp.value.length) {
                warnings.push(`Select one or more ${rp.prompts[0]} values`);
              }
            } else {
              if (!rp.value || !rp.value.value) {
                warnings.push(`Select a ${rp.prompts[0]} value`);
              }
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Integer:
            if (!rp.value || isNaN(parseInt(rp.value.value, 10))) {
              warnings.push(`Provide a valid ${rp.prompts[0]} value`);
            }
            break;
          default:
            console.error(`UNKNOWN parameter type [${rp.parameterType}] !!!!`)
            break;
        }
      }
    });
    if (warnings.length) {
      //TODO: something better here
      alert(warnings.join("\n"));
    }
    return warnings.length === 0;
  }

  parseParameters(report, params) {
    let rawParameters = {};
    _.each(report.parameters, rp => {
      if (!rp.hidden) {
        switch(rp.parameterType) {
          case constants.REPORT_PARAMETER_TYPES.Text:
            rawParameters[rp.names[0]] = {label: "", value: (rp.value || "")};
            break;
          case constants.REPORT_PARAMETER_TYPES.DateRange:
            if (rp.value) {
              // due to validation we know that these are valid moment values now
              rawParameters.date_range = {
                label: `${rp.value[0]} - ${rp.value[1]}`,
                value: [
                  {label: "Date From", value: rp.value[0]}, 
                  {label: "Date Through", value: rp.value[1]}
                ] 
                  // date_helpers.formatDateForServer(rp.value) + "|" + date_helpers.formatDateForServer(params[rp.names[1]])
              };
            } else {
              rawParameters.date_range = {
                label: 'Date Range not set',
                value: [null, null] 
              };
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Date:
            rawParameters[rp.names[0]] = {label: "", value: rp.value};
            break;
          case constants.REPORT_PARAMETER_TYPES.ApiList:
            if (rp.value) {
              if (rp.multi) {
                if (rp.value.length) 
                {
                  rawParameters[rp.names[0]] = {
                    label: _.map(rp.value, x => x[rp.apiValueAttribute]).join(','), 
                    value: _.map(rp.value, x => ({label: x[rp.apiValueAttribute], value: x[rp.apiKeyAttribute]}))
                    
                  };
                }
              } else {
                rawParameters[rp.names[0]] = {label: rp.value[rp.apiValueAttribute], value: rp.value[rp.apiKeyAttribute]};
              }
            } else {
              rawParameters[rp.names[0]] = null; // {label: "", value: null};
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Integer:
            if (rp.value) {
              rawParameters[rp.names[0]] = {label: "", value: parseInt(rp.value, 10)};
            } else {
              rawParameters[rp.names[0]] = {label: "", value: null};
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Boolean:
            rawParameters[rp.names[0]] = {label: "", value: (rp.value === true)};
            break;
          default:
            console.error(`UNKNOWN parameter type [${rp.parameterType}] !!!!`)
            break;
        }
      }
    });
    return rawParameters;
  }

  showTemplateModal() {
    this.setState({displayTemplateModal: true});
  }

  saveReportTemplate() {
    if (!this.state.templateFile) return;
    let payload = new FormData();
    payload.append('reportName', this.state.selectedReport.name);
    payload.append('templateFile', this.state.templateFile);
    api.post("report/SetTemplate", payload).then(response => {
      if (response.data.success) {
        Alert.success(`The new template for ${this.state.selectedReport.name} has been applied.`);
        this.setState({displayTemplateModal: false, templateFile: null});
      } else {
        Alert.error(response.data.message);        
      }
    }).catch((e) => {
      console.error(e);
      Alert.error("There was an error applying the report template");
    });
  }

  generateReport() {
    if (!this.isFormValid(this.state.selectedReport, this.state.parameters)) return;
    const payload = {
      reportName: this.state.selectedReport.value, 
      parameters: this.parseParameters(this.state.selectedReport, this.state.parameters),
      // TODO: allow user to select this...
      typeOfRender: constants.RENDER_TYPES.Excel
    };
    // const xlsWindow = window.open();
    //if (payload.typeOfRender === constants.RENDER_TYPES.CSV) {
    api.post("report/execute", payload, "blob").then(response => {
      const ext = renderExtension(payload.typeOfRender);
      const fileName = `report_${new Date().getTime()}.${ext}`;
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(response.data, fileName);
      // } else {
        const file = new Blob([response.data], { type: constants.MIME_XLSX });
        blobToDataURL(file, (dataurl) => {
          var link = document.createElement("a");
          link.style = "visibility:hidden";
          link.download = fileName;
          link.href = dataurl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // xlsWindow.location.name = fileName;
          // xlsWindow.location.href = dataurl; 
        });
      // }
    }).catch((e) => {
      console.error(e);
      Alert.error("There was an error generating the report");
      // xlsWindow.close();
    });
    // } else {
    //   api.post_form_data_get_blob("report/execute", payload).then(response => {
    //     helpers.downloadExport(response.data, `report_${new Date().getTime()}.${renderExtension(payload.typeOfRender)}`, renderMimeType(payload.typeOfRender));
    //   }).catch((error) => console.error(error));
    // }
  }

  cancel() {
    this.setState({
      parameters: this.state.defaultParameters,
      selectedReport: null
    });
  }

  onReportSelect(report) {
    let fetches = [];
    _.each(_.filter(report.parameters, p => p.parameterType === constants.REPORT_PARAMETER_TYPES.ApiList && !p.typeahead), p => {
      fetches.push(() => {
        return api.fetch_raw(p.apiRoute, {}).then(response => {
          return {
            [`${p.names[0]}_options`]: _.map(response.data, x => ({
              ...x,
              value: x[p.apiKeyAttribute],
              label: x[p.apiValueAttribute]
            }))
          };
        }).catch((error) => console.error(error));
      });
    });
    if (fetches.length) {
      Promise.all(_.map(fetches, f => (f()))).then((aggregateResults) => {
        let newStatus = { selectedReport: report };
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus);
      }).catch((error) => console.error(error));
    } else {
      this.setState({ selectedReport: report });
    }
  }

  // loadOptions={_.debounce((inputValue, callBack) => 
  //   this.loadApiListOptions(inputValue, callBack, p.apiRoute, p.apiKeyAttribute, p.apiValueAttribute), 
  //     500)}
  loadApiListOptions(inputValue, callBack, apiRoute, valueAttribute, labelAttribute) {
    //if (!inputValue || !inputValue.length || inputValue.length < 2) return;
    let sampleBooks = [];
    
    api.fetch_raw(apiRoute, { inputValue }).then(response => {
      callBack(_.map(response.data, x => ({
        ...x,
        value: x[valueAttribute],
        label: x[labelAttribute],
      })));
    }).catch((error) => console.error(error));
  }

  setParameterValue(parameterName, value, parameterIndex = null) {
    let r = Object.assign({}, this.state.selectedReport);
    const paramToChangeIndex = _.findIndex(r.parameters, p => p.names.includes(parameterName));
    let paramToChange = r.parameters[paramToChangeIndex];
    if (parameterIndex !== null) 
    {
      if (!paramToChange.value || paramToChange.value.constructor !== Array) 
      {
        paramToChange.value = [null, null];
      }
      paramToChange.value[parameterIndex] = value;
    } else {
      paramToChange.value = value;
    }
    this.setState({selectedReport: r});
  }

  onDropdownSelection(parameterName, selection) {
    let r = Object.assign({}, this.state.selectedReport);
    const paramToChangeIndex = _.findIndex(r.parameters, p => p.names.includes(parameterName));
    let paramToChange = r.parameters[paramToChangeIndex];
    let newState = {};
    if (selection) 
    {
      paramToChange.value = selection;
    } 
    else 
    {
      paramToChange.value = null;
      newState[`${parameterName}_input_value`] = "";
    }
    newState.selectedReport = r;
    this.setState(newState);
  }

  renderParameters = (paramList) => {
    let segments = [];
    _.each(paramList, p => {
      const segmentKey = `${p.names[0]}_param`;
      switch(p.parameterType) {
        case constants.REPORT_PARAMETER_TYPES.Boolean:
          segments.push((<Row className="mb-2" key={segmentKey}>
                <Col sm="4" className="pt-2">
                  <Label>
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <Switch
                    className="ml-2 float-right p-0 m-0"
                    onChange={(e) => this.setParameterValue(p.names[0], e)}
                    checked={p.value || false}
                  />
                </Col>
              </Row>));        
          break;
        case constants.REPORT_PARAMETER_TYPES.Integer:
        case constants.REPORT_PARAMETER_TYPES.Text:
          segments.push((<Row className="mb-2" key={segmentKey}>
                <Col sm="4" className="pt-2">
                  <Label>
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <Input 
                    type="text"
                    value={p.value || ""}
                    onChange={(e) => this.setParameterValue(p.names[0], e.target.value)}
                    className="form-control"
                    maxLength="50"
                  />
                </Col>
              </Row>));        
          break;
        case constants.REPORT_PARAMETER_TYPES.Date:
          segments.push((<Row className="mb-2" key={segmentKey}>
                <Col sm="4" className="pt-2">
                  <Label for="startDate">
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={p.value ? date_helpers.getMomentFromString(p.value, date_helpers.YMD).valueOf() : null}
                    onChange={(e) => this.setParameterValue(p.names[0], date_helpers.formatDateForServer(e))}
                    className="form-control"
                    // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[0], e))}
                  />
                </Col>
              </Row>));        
          break;
        case constants.REPORT_PARAMETER_TYPES.DateRange:
          segments.push((<Fragment key={segmentKey}>
              <Row className="mb-2">
                <Col sm="4" className="pt-2">
                  <Label for="startDate">
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={p.value && p.value.length && p.value[0] ? date_helpers.getMomentFromString(p.value[0], date_helpers.YMD).valueOf() : null}
                    onChange={(e) => this.setParameterValue(p.names[0], date_helpers.formatDateForServer(e), 0)}
                    className="form-control"
                    // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[0], e, 0))}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="4" className="pt-2">
                  <Label for="endDate">
                    {p.prompts[1]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={p.value && p.value.length && p.value[1] ? date_helpers.getMomentFromString(p.value[1], date_helpers.YMD).valueOf() : null}
                    onChange={(e) => this.setParameterValue(p.names[1], date_helpers.formatDateForServer(e), 1)}
                    className="form-control"
                    // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[1], e, 1))}
                  />
                </Col>
              </Row>
            </Fragment>));        
          break;
        case constants.REPORT_PARAMETER_TYPES.ApiList:
          if (p.typeahead) {
            segments.push((<Row className="mb-2" key={segmentKey}>
                <Col xs="4">
                  <Label>
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <AsyncSelect
                    loadOptions={_.debounce((inputValue, callBack) => 
                      this.loadApiListOptions(inputValue, callBack, p.apiRoute, p.apiKeyAttribute, p.apiValueAttribute), 
                        500)}
                    placeholder="Type Search Value"
                    defaultOptions={true}
                    //options={this.state[`${p.names[0]}_options`]}
                    onChange={(e) => this.onDropdownSelection(p.names[0], e)}
                    inputValue={this.state[`${p.names[0]}_input_value`]}
                    onInputChange={(e) => this.setState({ [`${p.names[0]}_input_value`]: e })}
                    value={this.state[`${p.names[0]}_selection`]}
                    isMulti={p.multi}
                    isDisabled={false}
                    isClearable={true}
                  />
                </Col>
              </Row>));
          } else {
            segments.push((<Row className="mb-2" key={segmentKey}>
              <Col xs="4">
                <Label>
                  {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                </Label>
              </Col>
              <Col>
                <Select
                  value={p.value}
                  onChange={(e) => this.onDropdownSelection(p.names[0], e)}
                  options={this.state[`${p.names[0]}_options`]}
                  isMulti={p.multi}
                  isDisabled={false}
                  isClearable={true}
                />
              </Col>
            </Row>));
          }
          break;
        default: 
          console.error('unknown param type to render: ', p);
          break;
      }
    });
    return (<Fragment>
      {segments.map(x => (x))}
      </Fragment>); 
  }

  render() {
    return (
      <div>
        <Row style={{ paddingTop: "15px" }}>
          <Col xs={{size: 8, offset: 2}}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs="2" className="pt-2">
                    <Label>Report</Label>
                  </Col>
                  <Col xs="9">
                    <Select
                      value={this.state.selectedReport}
                      onChange={(e) => this.onReportSelect(e)}
                      options={this.state.flatReports}
                    />
                  </Col>
                </Row>
                {this.state.selectedReport && (
                  <React.Fragment>
                    <Row style={{ paddingTop: "15px" }}>
                      <Col className="report-description">
                        {this.state.selectedReport.description}
                      </Col>
                    </Row>
                    <hr />
                    {this.renderParameters(this.state.selectedReport.parameters)}
                    <Row>
                      <Col>
                        <Button color="dark" 
                          style={{ marginTop: "15px" }}
                          onClick={() => this.showTemplateModal()}>
                          Set Template
                        </Button>
                      </Col>
                      <Col>
                        <ButtonGroup
                          className="float-right"
                          style={{ paddingTop: "15px" }}
                        >
                          <Button color="secondary" onClick={() => this.cancel()}>
                            Close
                          </Button>
                          <Button color="success" onClick={() => this.generateReport()}>
                            Run
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.displayTemplateModal
          ? (<Modal
              isOpen={true}
              toggle={() => this.setState({displayTemplateModal: false})}
            >
              <ModalBody>
                <DropzoneUploader
                  fileUploadOnly
                  accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                  filesCallback={(files) => this.setState({ templateFile: files[0] })}
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup className="float-right">
                  <Button color="secondary" onClick={() => this.setState({displayTemplateModal: false})}>
                    Cancel
                  </Button>
                  <Button className="primary" 
                    disabled={!this.state.templateFile}
                    onClick={() => this.saveReportTemplate()}>
                    Upload
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>)
          : null
        }
      </div>
    );
  }
}
