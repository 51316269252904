import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { api } from "../utils";

const resolveValues = (supplier) => {
  if (!supplier) return;
  return {
    supplierId: supplier.value,
    supplier: supplier,
    inputValue: supplier.label
  };
};

export default class SupplierTypeahead extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      { options: [] },
      resolveValues(props.supplier)
    );
    this.onSelect = this.onSelect.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
  }

  componentDidMount() {
    if (!this.props.onChange) {
      console.warn("set onChange for supplier typeahead");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clearValue !== this.props.clearValue && this.props.clearValue) {
      this.clearSelection();
    } else if (this.props.supplier !== undefined
      && this.props.supplier !== null
      && this.props.supplier.value
      && (!this.state.supplier || (this.state.supplier && (this.state.supplier.value !== this.props.supplier.value)))) {
      this.setState(resolveValues(this.props.supplier));
    }
  }

  clearSelection() {
    this.setState({
      supplierName: null,
      inputValue: "",
      supplierId: null
    });
    if (this.props.onChange) {
      this.props.onChange({ filterName: (this.props.name), value: null });
    }
  }

  onSelect(selection) {
    if (selection) {
      if (this.props.onChange) {
        this.props.onChange(selection);
      }
      this.setState({
        supplier: selection
      });
    } else {
      this.clearSelection();
    }
  }

  loadOptions(inputValue, callBack) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    api
      .fetch(`Supplier/SupplierTypeahead/${inputValue}`)
      .then(response => {
        const options = _.map(response.data, x => {
          return { value: x.value, label: x.label }
        })
        callBack(options);
      })
      .catch(api.catchHanlder);
  }

  onInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }

  render() {
    return (
      <AsyncSelect
        loadOptions={_.debounce(this.loadOptions, 500)}
        placeholder="Type Supplier Name"
        isClearable={this.props.isClearable}
        options={this.state.options}
        isMulti={this.props.isMulti}
        onChange={this.onSelect}
        inputValue={this.state.inputValue}
        onInputChange={this.onInputChange}
        value={this.state.expert}
        isDisabled={this.props.disabled}
      />
    );
  }
}
