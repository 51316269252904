import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Alert, Table, Input, ButtonGroup, Button, InputGroup, InputGroupAddon   } from 'reactstrap';
import DatePicker from "react-datepicker";
import Select from "react-select";
import _ from "lodash";
import { api, helpers, date_helpers} from '../utils';
import { SortHeader } from '../components';

export default class MultiOrderPayable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      isMessageVisible: false,
      supplierList: [],
      ordersList: [],
      orderId: null,
      selectedSupplier: null,
      selectAll: false,
      checkNumber: null,
      datePaid: new Date(),
      sortField: '', // use default sort - invoice date, then invoice number
      sortDir: 'asc',
    };
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.getAccountOrders = this.getAccountOrders.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDatePaidChange = this.onDatePaidChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.refresh = this.refresh.bind(this);
    this.doSort = this.doSort.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  onDismiss() {
    this.setState({
      isMessageVisible: !this.state.isMessageVisible,
      message: null,
      messageFlavor: null
    });
  }

  refresh() {
    api.fetch("Order/GetOrderFulfillmentSuppliers")
      .then(response => {
        if (response && response.data) {
          this.setState({
            supplierList: _.map(response.data, d => {
              d.label = `${d.name} ${d.type}`;
              d.value = d.id;
              return d;
            })
          })
        }
    });
  }

  getAccountOrders() {
    let payload = {
      // Id: this.state.selectedSupplier.value,
      // IsSupplier: this.state.selectedSupplier.isSupplier
      SupplierName: this.state.selectedSupplier.name,
      SortDir: this.state.sortDir, 
      SortField: this.state.sortField
    }
    api.post(`Order/GetOrderPayables`, payload)
      .then(response => {
        if (response && response.data) {
          this.setState({
            ordersList: _.map(response.data, d => {
              d.paymentAmount = null;
              d.amountPosted = parseFloat(d.amountDue, 10).toFixed(2);
              return d;
            })
          })
        }
      }).catch(helpers.catchHandler);
  }

  doSort(field, direction) {
    this.setState({ 
      sortDir: direction, 
      sortField: field 
    }, () => this.getAccountOrders());
    // this.refreshList(field, direction);
}

  onSelectionChange(selection) {
    this.setState({
      selectedSupplier: selection,
      sortDir: "asc",
      sortField: "",  // use default sort - invoice date, then invoice number
    }, () => {this.getAccountOrders(selection.value) });
  }

  onTextChange(fieldName, e, index) {
    let list = this.state.ordersList.slice();
    if (fieldName === "isChecked") {
      list[index][fieldName] = !list[index][fieldName]
    } else {
      list[index][fieldName] = e.target.value;
    }
    this.setState({ ordersList: list });
  }

  onCancel() {
    this.setState({
      ordersList: [],
      selectedSupplier: null,
      selectAll: false,
      checkNumber: null
    })
  }

  isFormValid() {
    let warnings = [];
    let list = _.filter(this.state.ordersList.slice(), l => l.isChecked);

    if (_.find(list, l => { return !l.amountPosted }) !== undefined) {
      warnings.push("Amount is required.");
    }
    if (_.find(list, l => { return !l.checkNumber }) !== undefined) {
      warnings.push("Check Number is required.");
    }
    // if (_.find(list, l => { return !l.referenceNumber }) !== undefined) {
    //   warnings.push("Supplier reference number is required.");
    // }
    if (_.find(list, l => { return !l.invoiceNumber }) !== undefined) {
      warnings.push("Supplier invoice number is required.");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(' '),
        messageFlavor: "danger",
        isMessageVisible: true
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  onSave() {
    if (!this.isFormValid()) return;

    let list = this.state.ordersList.slice();
    const payload = {
      OrderPayables: _.map(_.filter(list, l => l.isChecked), l => {
        return {
          Id:  l.id,
          OrderId: l.orderId,
          ReferenceNumber: l.referenceNumber || "",
          InvoiceNumber: l.invoiceNumber,
          CheckNumber: l.checkNumber,
          PayDate: l.datePaid,
          AmountDue: Number(l.amountDue),
          AmountPosted: Number(l.amountPosted)
        };
      })
    };
    api.post("Order/SaveOrderFullfillmentPayable", payload).then(response => {
      if (response && response.data.success) {
        this.setState({
          message: "Order fulfillment saved",
          messageFlavor: "success",
          isMessageVisible: true,
          selectedSupplier: null,
          ordersList: [],
          checkNumber: null
        }, this.refresh);
      }
    }).catch(helpers.catchHandler);
  }

  onDatePaidChange(e) {
    this.setState({
      datePaid: e
    });
  }

  onCheckNumberChange(e) {
    this.setState({
      checkNumber: e.target.value
    });
  }

  onApplyCheckNumber() {
    let list = this.state.ordersList.slice();
    list = _.map(list, l => {
      if (l.isChecked) {
        l.checkNumber = this.state.checkNumber;
        l.datePaid = this.state.datePaid;
      }
      return l;
    });
    this.setState({
      ordersList: list
    });
  }

  onSelectAll() {
    let list = this.state.ordersList.slice();
    list = _.map(list, l => {
      l.isChecked = !this.state.selectAll;
      return l;
    });
    this.setState({
      ordersList: list,
      selectAll: !this.state.selectAll
    });
  }

  render() {
    const { selectedSupplier, ordersList, selectAll, checkNumber, supplierList, message, messageFlavor, isMessageVisible} = this.state;
    const selectedTotalDue = ordersList && ordersList.length
      ? _.chain(ordersList).filter(o => o.isChecked).sumBy(o => parseFloat(o.amountDue)).value()
      : 0; 
    const selectedTotalAmount = ordersList && ordersList.length
      ? _.chain(ordersList).filter(o => o.isChecked).sumBy(o => parseFloat(o.amountPosted)).value()
      : 0; 
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {message && (
              <Row className="p-2 mb-2">
                <Col>
                  <Alert className={messageFlavor} isOpen={isMessageVisible} toggle={() => this.onDismiss()}>{message}</Alert>
                </Col>
              </Row>
            )}
            <Row className="row-spacing mb-2">
              <Col>
                <h3 className="pull-left page-title">Multi Order Payables</h3>
              </Col>
              <Col>
                <Select
                  bsSize="sm"
                  closeMenuOnSelect={true}
                  value={selectedSupplier}
                  options={supplierList}
                  onChange={this.onSelectionChange}
                  placeholder="Select Supplier"
                />

                {ordersList && ordersList.length > 0 &&
                  <Row className="mt-2">
                    <Col sm={{offset: 3, size: 4}}>
                      <InputGroup size="sm">
                        <span className="bg-info text-light float-left" style={{padding: "6px 10px 0 10px", margin:"0", height: "38px"}}>Date Paid</span>
                        <div style={{width: "140px"}}>
                          <DatePicker
                            selected={this.state.datePaid ? this.state.datePaid : ""}
                            onChange={(e) => this.onDatePaidChange(e)}
                            className="form-control"
                          />
                        </div>
                      </InputGroup>
                    </Col>
                    <Col sm="5">
                      <InputGroup>
                        <Input type="text" maxLength="255" style={{ "width": "50px", height: "38px" }} value={checkNumber || ""} onChange={(e) => this.onCheckNumberChange(e)} />
                        <InputGroupAddon addonType="prepend" style={{zIndex: 0}}>
                          <Button className="info buttonGroupSpacing" onClick={() => this.onApplyCheckNumber()} title="Apply check number to select rows">Apply Check Number</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>

            <Table striped size="sm">
              <thead>
                <tr>
                  <th> 
                    {ordersList && ordersList.length > 0 &&
                      <Button size="sm" className={selectAll ? "secondary" : "primary"} onClick={() => this.onSelectAll()} >{selectAll ? "Unselect all" : "Select all"}</Button>
                    }
                  </th>
                  <th>
                    <SortHeader
                      displayName="Order #"
                      field="OrderId"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'OrderId'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Account"
                      field="CustomerName"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'CustomerName'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Supplier Inv. Date"
                      field="BillDate"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'BillDate'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Supplier Ref # "
                      field="ReferenceNumber"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'ReferenceNumber'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Supplier Inv. #"
                      field="InvoiceNumber"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'InvoiceNumber'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Check #"
                      field="CheckNumber"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'CheckNumber'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th style={{width: "160px"}}>
                    {/* Using AmountPosted here to sort will bring unexpected results, as value is automatically changed to amount due*/}
                    <SortHeader
                      displayName="Amount"
                      field="AmountDue"     
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'AmountDue'}
                      callBack={this.doSort}
                    />
                  </th>
                  <th>
                    <SortHeader
                      displayName="Balance Due"
                      field="AmountDue"
                      sortDir={this.state.sortDir}
                      sorted={this.state.sortField === 'AmountDue'}
                      callBack={this.doSort}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersList && ordersList.length > 0 &&
                  ordersList.map((o, index) => (
                    <tr key={index}>
                      <td><Input type="checkbox" name="isChecked" checked={o.isChecked} value={o.isChecked} className="ml-2" style={{ "padding": "0px" }} onClick={(e)=>this.onTextChange("isChecked", e, index) } /></td>
                      <td>{o.orderId}</td>
                      <td>{o.customerName}</td>
                      <td>{date_helpers.formatDateToShortDate(o.billDate)}</td>
                      <td><Input type="text" maxLength="255" name="referenceNumber" value={o.referenceNumber || ""} onChange={(e) => this.onTextChange("referenceNumber", e, index)} /></td>
                      <td><Input type="text" maxLength="255" name="invoiceNumber" value={o.invoiceNumber || ""} onChange={(e) => this.onTextChange("invoiceNumber", e, index)} /></td>
                      <td><Input type="text" maxLength="255" name="checkNumber" value={o.checkNumber || ""} onChange={(e) => this.onTextChange("checkNumber", e, index)}/></td>
                      <td><Input type="number" className="currency" name="amountPosted" value={o.amountPosted || ""} onChange={(e) => this.onTextChange("amountPosted", e, index)} /></td>
                      <td className="pt-2 pr-4" style={{textAlign: "right"}}>{helpers.formatCurrency(o.amountDue)}</td>
                    </tr>
                  ))
                }
                {selectedTotalAmount
                  ? (<tr>
                      <td colSpan="6"></td>
                      <td className="pt-2 pr-4" style={{textAlign: "right", fontWeight: "bold"}}>{helpers.formatCurrency(selectedTotalAmount)}</td>
                      <td className="pt-2 pr-4" style={{textAlign: "right", fontWeight: "bold"}}>{helpers.formatCurrency(selectedTotalDue)}</td>
                    </tr>)
                  : null
                }
              </tbody>
            </Table>
            {ordersList && ordersList.length > 0 &&
              <ButtonGroup className="float-right">
              <Button size="sm" className="secondary buttonGroupSpacing"  onClick={this.onCancel}>Cancel</Button>
              <Button size="sm" className="primary buttonGroupSpacing" onClick={this.onSave}>Save</Button>
              </ButtonGroup>
            }
          </CardBody>
        </Card>
      </Container>
    );
  }
}