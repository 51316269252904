import React, { Component } from 'react';
import {
  Col,
  Row,
  Container,
  Table,
  Alert as RSAlert,
  Input,
  Label,
  Card,
  CardBody,
  Spinner,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _, { constant } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Alert from "react-s-alert";
import AsyncSelect from "react-select/async";

import {
  FilterSet,
  FilterBoolean,
  FilterText,
  FilterMultiSelect
} from '../components';
import { api, date_helpers, filter_helpers, helpers, constants } from '../utils';

const columnCountOptions = _.map(_.range(1, 101), n => { return { label: `${n}`, value: n }; });
//const rowCountOptions = _.map(_.range(1, 500), n => { return { label: `${n}`, value: n }; });
const PAGE_SIZE = 25;

const statusOptions = [
  {
    name: constants.CUSTOMER_SAMPLE_BOOK_STATUSES.agent,
    id: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.agent,
    colorClass: constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.agent
  },
  {
    name: constants.CUSTOMER_SAMPLE_BOOK_STATUSES.backorder,
    id: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.backorder,
    colorClass: constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.backorder
  },
  // {
  //   name: constants.CUSTOMER_SAMPLE_BOOK_STATUSES.faxed,
  //   id: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.faxed,
  //   colorClass: constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.faxed
  // },
  {
    name: constants.CUSTOMER_SAMPLE_BOOK_STATUSES.rejected,
    id: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.rejected,
    colorClass: constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.rejected
  },
  {
    name: constants.CUSTOMER_SAMPLE_BOOK_STATUSES.shipped,
    id: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.shipped,
    colorClass: constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.shipped
  }
];

const COLUMN_COUNT = 8;

const activeOnlyFilter = {
  filterName: "ActiveOnly",
  filteredDisplay: "Active Only: Active",
  value: true
}
export default class MaintainSamples extends Component {
  constructor(props) {
    document.getElementById("dcNetSiteBody").classList.remove('hidden-scrollbar');
    super(props);
    this.state = {
      bookList: [],
      customerList: [],
      sampleList: [],
      filters: [activeOnlyFilter],
      sort_field: 'Sequence',
      sort_dir: 'asc',
      message: null,
      messageFlavor: null,
      bookTypeList: [],
      columnCount: { label: 8, value: 8 },
      // rowCount: { label: 20, value: 20 },
      loading: false,
      currentStatusId: constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.shipped,
      updatingIds: { customerId: 0, sampleBookId: 0 },
      statusDate: moment().format("YYYY-MM-DD"),
      checkAllForCustomerId: 0,
      checkAllForBookId: 0,
      selectedSampleBooks: [],
      sampleBookList: [],
      selectedCustomers: [],
      selectedCustomerNumbers: []
    };
    this.refreshList = this.refreshList.bind(this);
    this.resolveSampleGrid = this.resolveSampleGrid.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.currentFilterValue = this.currentFilterValue.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onSelectSample = this.onSelectSample.bind(this);
    this.clearSpinnerTimeout = this.clearSpinnerTimeout.bind(this);
    this.checkAllForCustomer = this.checkAllForCustomer.bind(this);
    // this.getBookLiteList = this.getBookLiteList.bind(this);
    this.getSampleBookTypeAhead = this.getSampleBookTypeAhead.bind(this);
    this.handleBookFilterChange = this.handleBookFilterChange.bind(this);
    this.getCustomerTypeAhead = this.getCustomerTypeAhead.bind(this);
    this.handleCustomerFilterChange = this.handleCustomerFilterChange.bind(this);
    this.getCustomerNumberTypeAhead = this.getCustomerNumberTypeAhead.bind(this);
    this.handleCustomerNumberFilterChange = this.handleCustomerNumberFilterChange.bind(this);
  }

  componentDidMount() {

    const getBookTypes = api.fetch("Reference/GetSampleBookTypeList")
      .then((response) => {
        return { bookTypeList: response.data };
      })
      .catch(helpers.catchHandler);

    this.setState({ loading: true }, () => {
      Promise.all([
        getBookTypes,
        this.getAgentList(),
        this.getSampleBookOptions()
      ])
        .then((aggregateResults) => {
          const newStatus = {};
          aggregateResults.forEach(r => Object.assign(newStatus, r));
          this.setState(newStatus, this.refreshList);
        })
        .catch(helpers.catchHandler)
        .finally(() => this.setState({ loading: false }))
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.columnCount.value !== this.state.columnCount.value) {
      this.refreshList();
    }
    // if (prevState.rowCount.value !== this.state.rowCount.value) {
    //   this.refreshList();
    // }
    if (!_.isEqual(prevState.selectedSampleBooks, this.state.selectedSampleBooks)) {
      this.refreshList();
    }
    if (!_.isEqual(prevState.selectedCustomers, this.state.selectedCustomers)) {
      this.refreshList();
    }
    if (!_.isEqual(prevState.selectedCustomerNumbers, this.state.selectedCustomerNumbers)) {
      this.refreshList();
    }
  }

  componentWillUnmount() {
    document.getElementById("dcNetSiteBody").classList.add('hidden-scrollbar');
  }

  checkAllForCustomer(id) {
    this.setState({ checkingAllForCustomerId: 0 }, () => {
      const payload = {
        sampleBookIds: _.map(this.state.bookList, b => b.id),
        statusDate: this.state.statusDate,
        customerId: id,
        customerSampleBookStatusId: this.state.currentStatusId,
        backorderDate: this.state.backorderDate,
        isManagementShipment: this.state.isManagementShipment
      }

      api.post("sample/bulkSampleBookForCustomer", payload).then(r => {
        if (!r || !r.data || !r.data.success) {
          Alert.error("Error saving sample books");
        } else {
          this.refreshList();
        }
      }).catch(error => Alert.error(error))
        .finally(() => this.setState({ checkingAllForCustomerId: 0 }))
    })
  }

  getAgentList() {
    let payload = {
      SortField: "FirstName",
      SortDir: 'asc',
      activeOnly: true
    }

    api.post("Agent/GetList", payload)
      .then(response => {
        if (response && response.data) {
          this.setState({
            agents: _.map(response.data, a => {
              return {
                label: `${a.firstName} ${a.lastName}`, // label: `${a.lastName}, ${a.firstName}`,
                value: a.id
              }
            })
          })

        } else {
          throw new Error('Unable to load agent list');
        }
      })
      .catch(helpers.catchHandler);
  }

  getSampleBookOptions(name = "") {
    let payload = {
      Page: 1,
      PageSize: 100,
      SortField: "Sequence",
      SortDir: "asc",
      ActiveOnly: true,
      StatusTypeList: [constants.SAMPLE_BOOK_STATUS_TYPE.ACTIVE], 
      BookName: name
    }

    api.post("SampleBook/GetSelectList", payload)
    .then(response => {
      if (response && response.data) {
        this.setState({
          sampleBookList: response.data
        })
      } else {
        throw new Error('Unable to load sample book list');
      }
    })
    .catch(helpers.catchHandler);
  }


  getSampleBookTypeAhead(inputValue, callBack) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    api
      .fetch(`SampleBook/SampleBookTypeahead/${inputValue}`)
      .then(response => {
        if (response && response.data) {
          callBack(response.data);
        } else {
          throw new Error("Error loading sample book list")
        }
      })
      .catch(api.catchHanlder);
  }

  getCustomerTypeAhead(inputValue, callBack) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    api
      .fetch(`Customer/CustomerTypeahead/${inputValue}`)
      .then(response => {
        if (response && response.data) {
          const options = _.map(response.data, x => {
            return {value: x.value, label: x.label}
          })
          callBack(options);
        } else {
          throw new Error("Error loading customer list")
        }
      })
      .catch(api.catchHanlder);

  }

  getCustomerNumberTypeAhead(inputValue, callBack) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    api
      .fetch(`Customer/CustomerNumberTypeahead/${inputValue}`)
      .then(response => {
        if (response && response.data) {
          const options = _.map(response.data, x => {
            return {value: x, label: x}
          })
          callBack(options);
        } else {
          throw new Error("Error loading customer list")
        }
      })
      .catch(api.catchHanlder);

  }

  async refreshList(sortField, sortDirection, filters) {
    try {
      const filterList = filters || this.state.filters;
      let bookPayload = {
        SortField: sortField || this.state.sort_field,
        SortDir: sortDirection || this.state.sort_dir,
        PageSize: this.state.columnCount ? this.state.columnCount.value : COLUMN_COUNT
      };
      let customerPayload = {
        SortField: sortField || this.state.sort_field,
        SortDir: sortDirection || this.state.sort_dir,
        PageSize: PAGE_SIZE
      };

      _.each(filterList, filter => bookPayload[filter.filterName] = filter.value);
      _.each(filterList, filter => customerPayload[filter.filterName] = filter.value);

      if (this.state.selectedSampleBooks && this.state.selectedSampleBooks.length && this.state.selectedSampleBooks.length > 0) {
        bookPayload.SampleBookIdList = _.map(this.state.selectedSampleBooks, s => s.value);
      } else {
        bookPayload.StatusTypeList = [constants.SAMPLE_BOOK_STATUS_TYPE.ACTIVE];
      }
      if (this.state.selectedCustomers && this.state.selectedCustomers.length && this.state.selectedCustomers.length > 0) {
        customerPayload.IdList = _.map(this.state.selectedCustomers, s => s.value);
      }
      if (this.state.selectedCustomerNumbers && this.state.selectedCustomerNumbers.length && this.state.selectedCustomerNumbers.length > 0) {
        customerPayload.AccountNumberList = _.map(this.state.selectedCustomerNumbers, s => s.value);
      }

      const newState = {}

      const results = await Promise.all([
        api.post('SampleBook/GetPaginatedList', bookPayload),
        api.post('Customer/GetPaginatedList', customerPayload)
      ])

      if (results
        && results.length > 1
        && results[0].data
        && results[0].data.list
        && results[1].data
        && results[1].data.list
      ) {
        newState.bookList = results[0].data.list;
        newState.customerList = _.sortBy(results[1].data.list, c => { return c.name});

        const samplePayload = {
          CustomerIdList: _.map(newState.customerList, c => c.id),
          SampleBookIdList: _.map(newState.bookList, b => b.id),
          // ActiveOnly: //true
        }

        const sampleResults = await api.post('Sample/GetList', samplePayload)

        if (sampleResults && sampleResults.data) {
          newState.sampleList = sampleResults.data;
        }

        this.setState(newState, this.resolveSampleGrid);

      } else {
        throw "Error loading sample books and customers";
      }

    } catch (error) {
      console.error(error);
      Alert.error("Error loading customer and/or  book data")
    }
  }

  resolveSampleGrid() {
    let tempCustomerList = this.state.customerList.slice();
    tempCustomerList = _.map(tempCustomerList, customer => {
      let customerSampleList = [];
      _.each(this.state.bookList, book => {
        customerSampleList.push(this.determineSampleToPush(customer.id, book.id))
      })
      return {
        ...customer,
        customerSampleList
      }
    });
    this.setState({
      customerList: tempCustomerList
    })
  }

  resolveColor(statusId) {
    switch (statusId) {
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.agent:
        return constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.agent
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.backorder:
        return constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.backorder
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.rejected:
        return constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.rejected
      // case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.faxed:
      //   return constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.faxed
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.shipped:
        return constants.CUSTOMER_SAMPLE_BOOK_STATUS_COLORS.shipped
      default:
        return ""
    }
  }

  determineSampleToPush(customerId, bookId) {
    let sampleToPush = _.find(this.state.sampleList, (sample) => {  // should be find?
      return sample.customerId === customerId && sample.sampleBookId === bookId
    })
    if (sampleToPush) {
      sampleToPush = {
        ...sampleToPush,
        isSelected: true
      }
      return sampleToPush;
    } else {
      return {   // empty sample        
        name: "",
        sampleBookId: bookId,
        customerId: customerId,
        customerSampleBookStatusId: 0,
        statusDate: null,
        isManagementShipment: false,
        enteredAt: null,
        isSelected: false,
        backorderDate: null
      }
    }
  }

  clearSpinnerTimeout() {
    if (this.spinnerTimeout) {
      this.spinnerTimeout && clearTimeout(this.spinnerTimeout);
      this.spinnerTimeout = null;
      this.setState({ updatingIds: { customerId: 0, sampleBookId: 0 } });
    }
  }

  onSelectSample(sample) {
    let tempCustomerList = this.state.customerList.slice();
    const indexToEdit1 = _.findIndex(tempCustomerList, { id: sample.customerId });

    let tempCustomerSampleList = tempCustomerList[indexToEdit1].customerSampleList.slice();
    const indexToEdit2 = _.findIndex(tempCustomerSampleList, { sampleBookId: sample.sampleBookId });

    const sampleToEdit = tempCustomerList[indexToEdit1].customerSampleList[indexToEdit2];

    this.spinnerTimeout = setTimeout(() => this.setState({ updatingIds: { customerId: sample.customerId, sampleBookId: sample.sampleBookId } }), 500);

    if (!sample.id) {
      sample.statusDate = this.state.statusDate;
      sample.customerSampleBookStatusId = this.state.currentStatusId;
      sample.backorderDate = this.state.backorderDate;
      api.post("Sample/CustomerSampleBook", sample).then(r => {
        if (r && r.data && r.data.id) {
          this.refreshList();
        }
      }).catch(error => {
        console.error(error);
        Alert.error("Error loading book data");
      })
        .finally(this.clearSpinnerTimeout)
    }
    else if (sampleToEdit.isSelected &&
      sampleToEdit.customerSampleBookStatusId === this.state.currentStatusId &&
      moment(sampleToEdit.statusDate).isSame(this.state.statusDate, "day")
    ) {
      sampleToEdit.customerSampleBookStatusId = 0;
      sampleToEdit.backorderDate = null;
      api.delete(`Sample/CustomerSampleBook/${sampleToEdit.id}`).then(r => {
        if (r && r.data && r.data.success) {
          this.refreshList();
        } else {
          throw r.data.message || "Error deactivating sample";
        }
      }).catch(error => {
        Alert.error("Error loading book data");
        console.log(error);
      })
        .finally(this.clearSpinnerTimeout)
    } else {
      sampleToEdit.customerSampleBookStatusId = this.state.currentStatusId;
      sampleToEdit.backorderDate = this.state.backorderDate;
      sampleToEdit.statusDate = this.state.statusDate
      api.post("Sample/CustomerSampleBook", sampleToEdit).then(r => {
        if (r && r.data && r.data.id) {
          this.refreshList();
        }
      }).catch(error => {
        Alert.error("Error loading book data");
        console.log(error);
      })
        .finally(this.clearSpinnerTimeout)
    }

  }

  doSort(field, direction) {
    this.setState({ sort_dir: direction, sort_field: field });
    this.refreshList(field, direction);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find(f => f.filterName === name);
    if (filterElement) return filterElement.value;
    return '';
  }

  handleBookFilterChange(e) {
    let display = "";
    let labels = [];
    let values = [];
    let filters = _.filter(this.state.filters, x => x.filterName !== "SampleBookIdList");
    let selectedSampleBooks = [];
    if (e && e.length && e.length > 0) {
      labels = _.map(e, b => b.label);
      values = _.map(e, b => b.value);
      display = ": " + labels.join(", ");
      selectedSampleBooks = e;

      filters = [
        ...filters,
        {
          filterName: "SampleBookIdList",
          filteredDisplay: "Books" + display,
          labels: labels,
          value: values
        }
      ]
    }
    this.setState({ filters, clearFilters: false, selectedSampleBooks })
  }

  handleCustomerFilterChange(e) {
    let display = "";
    let labels = [];
    let values = [];
    let filters = _.filter(this.state.filters, x => x.filterName !== "CustomerIdList");
    let selectedCustomers = [];
    if (e && e.length && e.length > 0) {
      labels = _.map(e, b => b.label);
      values = _.map(e, b => b.value);
      display = ": " + labels.join(", ");
      selectedCustomers = e;

      filters = [
        ...filters,
        {
          filterName: "CustomerIdList",
          filteredDisplay: "Customers" + display,
          labels: labels,
          value: values
        }
      ]
    }
    this.setState({ filters, clearFilters: false, selectedCustomers })
  }

  handleCustomerNumberFilterChange(e) {
    let display = "";
    let labels = [];
    let values = [];
    let filters = _.filter(this.state.filters, x => x.filterName !== "AccountNumberList");
    let selectedCustomerNumbers = [];
    if (e && e.length && e.length > 0) {
      labels = _.map(e, b => b.label);
      values = _.map(e, b => b.value);
      display = ": " + labels.join(", ");
      selectedCustomerNumbers = e;

      filters = [
        ...filters,
        {
          filterName: "AccountNumberList",
          filteredDisplay: "Account Numbers" + display,
          labels: labels,
          value: values
        }
      ]
    }
    this.setState({ filters, clearFilters: false, selectedCustomerNumbers })
  }

  filterChange(changed_filter) {
    const filters = filter_helpers.get_filters(this.state.filters, changed_filter);
    this.setState({ filters: filters, clearValue: false }, this.refreshList);
  }

  clearFilters() {
    const filters = [];
    this.setState({ filters: filters, selectedSampleBooks: [], selectedCustomers: [], selectedCustomerNumbers: [], clearValue: true }, this.refreshList);
  }

  resolveTitle(sample) {
    let title = "";

    switch (sample.customerSampleBookStatusId) {
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.agent:
        title = "Agent\n";
        break;
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.shipped:
        title = "Shipped\n";
        break;
      // case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.faxed:
      //   title = "Faxed\n";
      //   break;
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.rejected:
        title = "Rejected\n";
        break;
      case constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.backorder:
        title = "Backorder\n";
        break;
      default:

    }

    if (sample.statusDate) {
      title = `${title}Status Date: ${moment(sample.statusDate).format("MMM DD, YYYY")}`;
    }
    if (sample.backorderDate) {
      title = title + `\n Backorder Date: ${moment(sample.backorderDate).format("MMM DD, YYYY")}`
    }

    return title ? title : "N/A";
  }

  render() {
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {this.state.message && (
              <Row className="mb-2">
                <Col>
                  <RSAlert className={this.state.messageFlavor}>{this.state.message}</RSAlert>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <h3 className="pull-left page-title">Maintain Samples</h3>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              {_.map(statusOptions, ({ name, id, colorClass }) => (
                <Col xs={1} key={id}>
                  <div
                    onClick={() => this.setState({ currentStatusId: id })}
                    className={colorClass}
                    style={{
                      color: "white",
                      textAlign: "center",
                      borderRadius: 4,
                      padding: ".25em",
                      borderStyle: id === this.state.currentStatusId ? "dotted" : "",
                      borderColor: "#86b1cc",
                      borderWidth: 5
                    }}
                  >
                    {name}
                  </div>
                </Col>
              )
              )}
            </Row>
            {this.state.currentStatusId === constants.CUSTOMER_SAMPLE_BOOK_STATUS_IDS.backorder ? (
              <Row className="mb-4">
                <Col xs={3}>
                  <Label for="backorderDate" className="mb-1">Backorder Date</Label>
                  <Row>
                    <Col xs={2}>
                      <DatePicker
                        onChange={e => this.setState({ backorderDate: e })}
                        selected={date_helpers.dateFormatForClient(this.state.backorderDate)}
                      />
                      {this.state.backorderDate ?
                        <span style={{ position: "absolute", left: "11rem", top: ".2rem" }} onClick={() => this.setState({ backorderDate: null })}>
                          <FontAwesomeIcon icon="times" color="red" />
                        </span> : null}
                    </Col>
                  </Row>
                </Col>
              </Row>) : null}
            <Row className="expand-md mt-2 mb-0 pb-0">
              <Col sm="12">
                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                  <Row>
                    {/* <Col xs="3">
                      <span className="filter-definition-caption">
                        Books
                      <AsyncSelect
                          loadOptions={_.debounce(this.getSampleBookTypeAhead, 500)}
                          placeholder="Books"
                          isClearable

                          options={this.state.bookSelectList}
                          isMulti
                          onChange={this.handleBookFilterChange}
                          value={this.state.selectedSampleBooks}
                        />
                      </span>
                    </Col> */}
                    <Col xs="3">
                      <FilterMultiSelect
                        filterName="SampleBookIdList"
                        displayName="Books"
                        values={this.currentFilterValue('SampleBookIdList')}
                        onChangeCallback={this.filterChange}
                        options={this.state.sampleBookList}
                      />
                    </Col>
                    <Col xs="3">
                      <FilterMultiSelect
                        filterName="BookTypeList"
                        displayName="Book Type"
                        values={this.currentFilterValue('BookTypeList')}
                        onChangeCallback={this.filterChange}
                        options={this.state.bookTypeList}
                      />
                    </Col>
                    <Col xs="3">
                      <FilterBoolean
                        filterName="ActiveOnly"
                        displayName="Active Only"
                        yesOnly={true}
                        value={this.currentFilterValue('ActiveOnly')}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col xs="3">
                      <FilterText
                        filterName="CustomerName"
                        displayName="Account Name"
                        value={this.currentFilterValue('CustomerName')}
                        // onChangeCallback={this.filterChange}
                        onChangeCallback={_.debounce(this.filterChange, 250)}
                      />
                    </Col> */}
                    <Col xs="3">
                      <span className="filter-definition-caption">
                        Customer
                      <AsyncSelect
                          loadOptions={_.debounce(this.getCustomerTypeAhead, 500)}
                          placeholder="Customer"
                          isClearable
                          // options={this.state.customerSelectList}
                          isMulti
                          onChange={this.handleCustomerFilterChange}
                          value={this.state.selectedCustomers}
                        />
                      </span>
                    </Col>
                    {/* <Col xs="2">
                      <FilterText
                        filterName="Number"
                        displayName="Account #"
                        value={this.currentFilterValue('Number')}
                        // onChangeCallback={this.filterChange}
                        onChangeCallback={_.debounce(this.filterChange, 250)}
                      />
                    </Col> */}
                    <Col xs="3">
                      <span className="filter-definition-caption">
                        Account #
                      <AsyncSelect
                          loadOptions={_.debounce(this.getCustomerNumberTypeAhead, 500)}
                          placeholder="Account #"
                          isClearable
                          // options={this.state.customerSelectList}
                          isMulti
                          onChange={this.handleCustomerNumberFilterChange}
                          value={this.state.selectedCustomerNumbers}
                        />
                      </span>
                    </Col>
                    <Col xs="3">
                      <FilterMultiSelect
                        filterName="Agents"
                        displayName="Agents"
                        values={this.currentFilterValue('Agents')}
                        onChangeCallback={this.filterChange}
                        options={this.state.agents}
                      />
                    </Col>
                  </Row>
                </FilterSet>
              </Col>
            </Row>
            <Row>
              <Col xs="1" className="mt-2">
                Book Count
                <Select
                  value={this.state.columnCount}
                  onChange={e => this.setState({ columnCount: e })}
                  options={columnCountOptions}
                />
              </Col>
              <Col xs="1" className="mt-2">
                Date
                <input
                  type="date"
                  value={this.state.statusDate}
                  onChange={e => this.setState({ statusDate: moment(e.target.value).format("YYYY-MM-DD") })}
                />
              </Col>
            </Row>
            {this.state.loading ? <div>Loading...</div> :
              <Row className="row-spacing">
                <Col className="col-12">
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th width="5%" style={{maxWidth: "250px"}}></th>
                        {this.state.bookList.map(book => (
                          <th key={book.id} style={{ verticalAlign: "middle", textAlign: "center"}} width="2%">
                            {book.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.customerList.map(customer => (
                        <tr key={customer.id} className="data-row">
                          <td>
                            <React.Fragment>
                              <div className="minWidth200 maxWidth250">
                                {customer.name + ` (${customer.number})`}
                              </div>
                              <div className="minWidth200 maxWidth250">
                                {this.state.checkingAllForCustomerId ?
                                  <Spinner color="primary" />
                                  :
                                  <Button color="primary" onClick={() => this.checkAllForCustomer(customer.id)}>
                                    Select ALL
                              </Button>
                                }
                              </div>
                            </React.Fragment>
                          </td>
                          {_.map(customer.customerSampleList, sample => {
                            return (
                              <td style={{ verticalAlign: "middle", maxWidth: "3vw" }} key={`${sample.customerId} ${sample.sampleBookId}`}>
                                {this.state.updatingIds &&
                                  this.state.updatingIds.customerId === sample.customerId &&
                                  this.state.updatingIds.sampleBookId === sample.sampleBookId ?
                                  <div
                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                  >
                                    <div
                                      style={{ margin: "auto", borderRadius: 5 }}
                                    >
                                      <Spinner color="primary" />
                                    </div>
                                  </div>
                                  :
                                  <div
                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                    title={this.resolveTitle(sample)}
                                  >
                                    <div
                                      style={{ margin: "auto", borderRadius: 5, textAlign: "center", }}
                                      className={"maintainSampleCheckboxContainer " + this.resolveColor(sample.customerSampleBookStatusId)}
                                    >
                                      <Input
                                        className="maintainSampleCheckbox"
                                        type="checkbox"
                                        checked={sample.isSelected}
                                        onChange={() => this.onSelectSample(sample)}
                                      />
                                    </div>
                                  </div>
                                }
                              </td>
                            )
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>}
          </CardBody>
        </Card>
      </Container>
    );
  }
}
