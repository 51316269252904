import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Link, Redirect, NavLink as DomNavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "react-s-alert";
import makeAnimated from "react-select/animated";
import { DebounceInput } from "react-debounce-input";
import Sidebar from "react-sidebar";
import Switch from "react-switch";
import moment from "moment";
import axios from "axios";
// will implement this when I change to all the product colors being single elements on the page
import { PinterestShareButton } from "react-simple-share";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardImg,
  CardColumns,
  CardSubtitle,
  Alert as AlertStrap,
  Input,
  Modal,
  FormGroup,
  Form,
  Nav,
  Navbar,
  NavItem,
  Spinner,
  Table,
  Carousel,
  CarouselCaption,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  NavLink,
  TabContent,
  TabPane,
  CardDeck,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  api,
  helpers,
  filter_helpers,
  constants,
  date_helpers,
  calc,
} from "../utils";
import { MobileFilterSet, FilterText, FilterMultiSelect } from "../components";
import Select from "react-select";
import { data } from "jquery";
import { object } from "underscore";

const emptyCustomer = {
  id: 0,
  useDefaultShipTo: true,
};

const activeFilter = {
  filterName: "ActiveOnly",
  value: true,
  filteredDisplay: "Active Only: Yes",
};

const publicFilter = {
  filterName: "PublicOnly",
  value: true,
};

const initialFilters = [activeFilter, publicFilter];

const pendingMemoOrderStatus = { value: 1, label: "Pending" };
const quoteOrderStatus = constants.ORDER_STATUS.QUOTE;

const MITERED_PRODUCT_NAME = constants.MITERED_PRODUCT_NAME;
const FINISHED_RUG_SIZES = constants.FINISHED_RUG_SIZES;
const DISPLAY_FINISHED_RUG_SIZES = _.map(FINISHED_RUG_SIZES, (rs) => {
  if (rs.label === "Custom") {
    return Object.assign({}, rs, { label: "Enter Custom Size" });
  }
  return rs;
});

const { ROUND, RECTANGULAR, SQUARE } = constants.CUSTOM_RUG_SHAPES;

const configNames = constants.CUSTOM_RUG_CONFIG_NAMES;

const params = {
  fieldSelection: "fieldSelection",
  fieldSelectionColor: "fieldSelectionColor",
  bindingSelection: "bindingSelection",
  bindingSelectionColor: "bindingSelectionColor",
  shapeSelection: "shapeSelection",
  finishedSize: "finishedSize",
  serging: "serging",
  customRug: "customRug",
  sampleCartItems: "sampleCartItems",
  orderCartItems: "orderCartItems",
  rugs: "Rugs",
  wood: "Wood",
  carpet: "Carpet",
  squareFeet: "Sq Ft",
  linearFeet: "Lin Ft",
  seagrassAndSisal: "Seagrass & Sisal",
  bordersAndBindings: "Borders & Bindings",
  borderOrBinding: "Border or Binding",
  hardSurface: "Hard Surface",
  none: "none",
  contractTileString: "Contract Tile",
  runners: "Runners",
  customRugTitle: "Custom Rug",
};

// leaving the full URLS in this list as well for ease of testing
// not using squarespaceUrl or iframeUrl for anything in this code
const SQUARESPACE_URLS = [
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/all-rugs",
    squarespacePath: "/all-rugs",
    iframeUrl: "https://app.distinctivecarpets.com/sq/rugs/intlRug/all/none",
    iframePath: "/sq/rugs/intlRug/all/none",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/new-rugs",
    squarespacePath: "/new-rugs",
    iframeUrl: "https://app.distinctivecarpets.com/sq/rugs/intlRug/new/newRugs",
    iframePath: "/sq/rugs/intlRug/new/newRugs",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/contemporary-rugs",
    squarespacePath: "/contemporary-rugs",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/intlRug/contemporary/contemporaryRug",
    iframePath: "/sq/rugs/intlRug/contemporary/contemporaryRug",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/traditional-rugs",
    squarespacePath: "/traditional-rugs",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/intlRug/traditional/traditionalRugs",
    iframePath: "/sq/rugs/intlRug/traditional/traditionalRugs",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/transitional-rugs",
    squarespacePath: "/transitional-rugs",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/intlRug/transitional/transitionalRug",
    iframePath: "/sq/rugs/intlRug/transitional/transitionalRug",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/outdoor-rugs",
    squarespacePath: "/outdoor-rugs",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/intlRug/outdoor/outdoorRug",
    iframePath: "/sq/rugs/intlRug/outdoor/outdoorRug",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/runners",
    squarespacePath: "/runners",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/rollRunner/runners/runners",
    iframePath: "/sq/rugs/rollRunner/runners/runners",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/western",
    squarespacePath: "/western",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/rugs/intlRug/western/western",
    iframePath: "/sq/rugs/intlRug/western/western",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/all-carpet",
    squarespacePath: "/all-carpet",
    iframeUrl: "https://app.distinctivecarpets.com/sq/carpets/carpet/all/none",
    iframePath: "/sq/carpets/carpet/all/none",
  },
  //     // NOT SURE WHAT TO FILTER ON THIS ONE
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/new-carpet-styles",
    squarespacePath: "/new-carpet-styles",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/new/newCarpet",
    iframePath: "/sq/carpets/carpet/new/newCarpet",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/patterned-carpet",
    squarespacePath: "/patterned-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/patterned/patternedCarpet",
    iframePath: "/sq/carpets/carpet/patterned/patternedCarpet",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/loop-carpet",
    squarespacePath: "/loop-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/loop/loopCarpet",
    iframePath: "/sq/carpets/carpet/loop/loopCarpet",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/textured-carpet",
    squarespacePath: "textured-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/textured/texturedCarpet",
    iframePath: "/sq/carpets/carpet/textured/texturedCarpet",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/luxury-wool",
    squarespacePath: "luxury-wool",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/luxuryWool/luxuryWool",
    iframePath: "/sq/carpets/carpet/luxuryWool/luxuryWool",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/seagrass-sisal",
    squarespacePath: "/seagrass-sisal",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/seagrass/seagrass",
    iframePath: "/sq/carpets/carpet/seagrass/seagrass",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/wool-carpet",
    squarespacePath: "/wool-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/wool/allWool",
    iframePath: "/sq/carpets/carpet/wool/allWool",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/commercial-carpet",
    squarespacePath: "/commercial-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/commercial/commercial",
    iframePath: "/sq/carpets/carpet/commercial/commercial",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/magic-colors-carpet",
    squarespacePath: "/magic-colors-carpet",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/magicColors/magicColors",
    iframePath: "/sq/carpets/carpet/magicColors/magicColors",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/outdoor",
    squarespacePath: "/outdoor",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/outdoor/outdoorCarpet",
    iframePath: "/sq/carpets/carpet/outdoor/outdoorCarpet",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/custom-rug",
    squarespacePath: "/custom-rug",
    iframeUrl: "https://app.distinctivecarpets.com/sq/customRug",
    iframePath: "/sq/customRug",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/borders-bindings",
    squarespacePath: "/borders-bindings",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/borders/borders/none/none",
    iframePath: "/sq/borders/borders/none/none",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/hard-surface",
    squarespacePath: "/hard-surface",
    iframeUrl: "https://app.distinctivecarpets.com/sq/wood/wood/none/none",
    iframePath: "/sq/wood/wood/none/none",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/skins-pattern",
    squarespacePath: "/skins-pattern",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/skinsPattern/skinsPattern",
    iframePath: "/sq/carpets/carpet/skinsPattern/skinsPattern",
  },
  {
    squarespaceUrl: "https://www.distinctivecarpets.com/pet-friendly",
    squarespacePath: "/pet-friendly",
    iframeUrl:
      "https://app.distinctivecarpets.com/sq/carpets/carpet/petFriendly/petFriendly",
    iframePath: "/sq/carpets/carpet/petFriendly/petFriendly",
  },
];

const findRedirectUrl = (pathname) => {
  const pathToRedirect = _.find(SQUARESPACE_URLS, (item) => {
    return item.iframePath === pathname;
  });
  if (pathToRedirect && pathToRedirect.squarespacePath) {
    return pathToRedirect.squarespacePath;
  }
  return "/all-carpet";
};

const getDirectProductLink = (baseUrl, productId, colorNumber) => {
  // baseUrl = 'localhost:44377/sq/carpets/carpet/all/none/';
  return `${baseUrl}?productId=${productId}&color=${colorNumber}`;
};
const parseURL = (props) => {
  // i.e. https://app.distinctiveccarpets.com/sq/rugs/newRugs/new/newRugs
  let [
    _ignore, // ==> app.distinctiveccarpets.com
    page, // ==> /sq
    productType, // ==> /rugs
    filter, // ==> /newRugs
    section, // ==> /new
    subFilters, // ==> /newRugs
  ] = _.split(props.location.pathname, "/");
  let shareLinkBase = "";
  let sort_field = "DCName";
  let title = _.startCase(productType);
  let filterType = _.startCase(filter);
  let subTypes = _.chain(subFilters)
    .split("-")
    .map((ft) => _.startCase(ft))
    .filter((ft) => {
      return ft !== "None";
    })
    .toArray()
    .value();
  if (section === params.none) {
    section = null;
  } else {
    section = _.startCase(section);
  }
  if (title === params.wood) {
    shareLinkBase = "https://www.distinctivecarpets.com/hard-surface";
    title = params.hardSurface;
  }
  if (title === "Carpets") {
    shareLinkBase = "https://www.distinctivecarpets.com/all-carpet";
    title = params.carpet;
  }
  if (title === "Rugs") {
    shareLinkBase = "https://www.distinctivecarpets.com/all-rugs";
  }
  // cannot set ampersand in url params so overwriting them here
  if (section === "Seagrass") {
    title = params.seagrassAndSisal;
    subTypes = [params.seagrassAndSisal];
  }
  if (title === "Borders") {
    shareLinkBase = "https://www.distinctivecarpets.com/borders-bindings";
    title = params.bordersAndBindings;
    filterType = "Border or Binding";
    subTypes = [params.bordersAndBindings];
  }
  const { color, productId } =
    props.location.search && queryString.parse(props.location.search);
  return {
    page: page,
    productType: productType,
    section: section,
    sort_field: sort_field,
    title: title,
    filterType: filterType,
    subTypes: subTypes,
    queryStringDCNumber: productId || null, //productId,
    queryStringProductColor: color || null, //color,
    shareLinkBase: shareLinkBase,
  };
};

const formatPrice = (unitPrice) => {
  if (unitPrice) {
    const money = Number(unitPrice / 100.0)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return `$${money}`;
  }
  return null;
};

const { ATTACHMENT_TYPE } = constants;

const {
  //PRODUCT_IMAGE_B,
  //PRODUCT_IMAGE_C,
  PRODUCT_IMAGE_D,
  PRODUCT_IMAGE_ROOM_SCENE,
  PRODUCT_IMAGE_CLOSE_UP,
  DETAILED_SPECS,
  //PRODUCT_COLOR_IMAGE_B,
  //PRODUCT_COLOR_IMAGE_C,
  PRODUCT_COLOR_IMAGE_D,
  //CUSTOMER_TAX_CERTIFICATE,
} = ATTACHMENT_TYPE;

const findMainColorImage = (colorImages) => {
  return _.chain(colorImages)
    .find(
      (col) => col.productAttachmentTypeName === PRODUCT_COLOR_IMAGE_D.label
    )
    .value();
};

const getImageUrl = (image) => {
  let imageToUse = image;
  if (image && image.url) {
    return imageToUse.url;
    // if (_.endsWith(imageToUse.url, ".png")) {
    //   return imageToUse.url;
    // }
    // return imageToUse.url + ".png";
  }
  return "Coming_Soon.png";
};

const CUSTOM_STEPS = {
  field: "Select Field Design",
  size_shape: "Select Size/Shape",
  binding: "Select Finish",
  customer: "Customer Details",
  review: "Review & Submit",
};

const orderLineTypes = constants.ORDER_LINE_TYPE;

const resolveOrderLineType = (product) => {
  const productType = _.toUpper(product.productTypeName);
  const foundType = _.find(orderLineTypes, (x) => {
    return x.label === productType;
  });
  if (foundType && foundType.value) {
    return foundType.value;
  }
  if (productType === "INTL RUG") {
    return orderLineTypes.INTL_COLL_RUG.value;
  }
  if (productType === "WOOD") {
    return orderLineTypes.HARD_SURFACE.value;
  }
  if (productType === "RUG COMPONENT") {
    return orderLineTypes.RUG_MISCELLANEOUS.value;
  }
  console.error("order line type NOT FOUND", productType);
};

const getMemoSizeOptionsByColor = (productMemoSizes, colorId) => {
  return _.chain(productMemoSizes, colorId)
    .filter((memoSize) => {
      return memoSize.productColorId == colorId;
    })
    .map((opt) => {
      return {
        value: opt.memoSizeId,
        label: opt.memoSizeName,
      };
    })
    .value();
};

function validateCustomerInfo(customer, productType) {
  let warningsList = [];
  // if(!customer.requestedBy || customer.requestedBy.length < 2) {
  //   warningsList.push("Please enter a full name on step 4.");
  // }
  if (
    !customer.requestedByFirstName ||
    customer.requestedByFirstName.length < 1
  ) {
    warningsList.push("Please enter a valid first name on step 4.");
  }
  if (
    !customer.requestedByLastName ||
    customer.requestedByLastName.length < 1
  ) {
    warningsList.push("Please enter a valid last name on step 4.");
  }
  if (!customer.webEmail || customer.webEmail.length < 4) {
    warningsList.push("Please enter a full email address on step 4.");
  }
  if (productType !== params.customRug) {
    if (!customer.customerPONumber || customer.customerPONumber.length < 4) {
      warningsList.push("Please enter a purchase order number on step 4.");
    }
    if (!customer.stAddressLine1 || customer.stAddressLine1.length < 4) {
      warningsList.push(
        "Please enter a full name on line 1 in the Ship To section on step 4."
      );
    }
    if (!customer.stAddressLine3 || customer.stAddressLine3.length < 4) {
      warningsList.push(
        "Please enter a full street address on line 3 in the Ship To section on step 4."
      );
    }
    if (!customer.stCity || customer.stCity.length < 4) {
      warningsList.push(
        "Please enter a city in the Ship To section on step 4."
      );
    }
    if (!customer.stState || customer.stState.length < 2) {
      warningsList.push(
        "Please enter a state in the Ship To section on step 4."
      );
    }
    if (!customer.stZip || customer.stZip.length < 4) {
      warningsList.push(
        "Please enter a zip code in the Ship To section on step 4."
      );
    }
  }
  return warningsList;
}

function shapeCommonOrderPayload(orderLines, customer, productType) {
  const placedBy = `${customer.requestedByFirstName} ${customer.requestedByLastName}`;
  const payload = {
    Id: 0,
    CustomerId: customer.id,
    AgentId: customer.agentId,
    OrderStatusId: quoteOrderStatus.value,
    InvoiceTermsId: customer.invoiceTermsId,
    // only 50 characters allowed
    PlacedBy: placedBy.substring(0, 50),
    CreatedAt: moment(),
    CreatedByUserId: 1, // admin user
    Mth: date_helpers.formatDateForServer(new Date()),
    IsReplacementOrder: false,
    IsDistinctiveOrder: false,
    IsCreditOrder: false,
    ReferencesOrder: null,
    // CustomerPONumber:
    //   productType === params.customRug ? "n/a" : customer.customerPONumber,
    CustomerPONumber: customer.customerPONumber || "",
    // all of the bill contact info will be added in C#
    BTContactFirstName: "",
    BTContactLastName: "",
    BTContactEmail: "",
    BTAddressLine1: "",
    BTAddressLine2: "",
    BTAddressLine3: "",
    BTAddressLine4: "",
    BTCity: "",
    BTState: "",
    BTZip: "",
    BTPhone: "",
    // ST and freight fields will be persisted in backend on individual orderlines, not orders
    STContactFirstName: customer.requestedByFirstName,
    STContactLastName: customer.requestedByLastName,
    STContactEmail: customer.webEmail,
    STAddressLine1: customer.stAddressLine1,
    STAddressLine2: customer.stAddressLine2,
    STAddressLine3: customer.stAddressLine3,
    STAddressLine4: customer.stAddressLine4,
    STCity: customer.stCity,
    STState: customer.stState,
    STZip: customer.stZip,
    STPhone: customer.stPhone,
    FreightSidemark: customer.freightSidemark,
    OrderLines: orderLines,
    AreaId: customer.areaId,
  };
  return payload;
}

const PagerLink = (props) => (
  <PaginationItem disabled={props.disabled}>
    <PaginationLink onClick={() => props.callBack()}>
      {props.linkText}
    </PaginationLink>
  </PaginationItem>
);

const Pager = (props) => {
  if (!props.callBack) {
    console.warn(
      "Add a callBack function property on the pager that accepts a page param"
    );
  }

  const showPager = props.totalPages > 0;
  const previous = props.pageNumber > 1;
  const next = props.pageNumber !== props.totalPages && props.totalPages > 1;
  let firstText = (
    <FontAwesomeIcon icon="angle-double-left" className="squarespaceText" />
  );
  let prevText = (
    <FontAwesomeIcon icon="angle-left" className="squarespaceText" />
  );
  let nextText = (
    <FontAwesomeIcon icon="angle-right" className="squarespaceText" />
  );
  let lastText = (
    <FontAwesomeIcon icon="angle-double-right" className="squarespaceText" />
  );
  let pageOptions = [];
  for (let i = 1; i <= props.totalPages; i++) {
    const opt = {
      value: i,
      label: `Page ${i} of ${props.totalPages}`,
    };
    pageOptions.push(opt);
  }
  const findCurrentPage = _.find(pageOptions, (pgOpt) => {
    return pgOpt.value === props.pageNumber;
  });
  return (
    <div
      className={
        props.smallScreen ? "squarespacePager" : "squarespacePager float-right"
      }
    >
      {showPager && (
        <Pagination size="sm">
          <PagerLink
            linkText={firstText}
            callBack={() => props.callBack(1)}
            refPage={props.refPage}
            disabled={!previous}
          />
          <PagerLink
            linkText={prevText}
            callBack={() => props.callBack(props.pageNumber - 1)}
            refPage={props.refPage}
            disabled={!previous}
          />
          <span className="mr-2 ml-2 pt-2">
            Page {props.pageNumber} of {props.totalPages}
          </span>
          {/* needs more tweaking than I have time for right now: Melissa */}
          {/* <PaginationItem>
            <Select
              bsSize="sm"
              closeMenuOnSelect
              options={pageOptions}
              value={findCurrentPage}
              onChange={(opt) =>  props.callBack(opt.value)}
              className="paginationSelector"
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            />
          </PaginationItem> */}
          <PagerLink
            linkText={nextText}
            callBack={() => props.callBack(props.pageNumber + 1)}
            refPage={props.refPage}
            disabled={!next}
          />
          <PagerLink
            linkText={lastText}
            callBack={() => props.callBack(props.totalPages)}
            refPage={props.refPage}
            disabled={!next}
          />
        </Pagination>
      )}
    </div>
  );
};

const emptyCustomRug = {
  fieldSelection: null,
  fieldSelectionColor: null,
  fieldSelectionColorList: [],
  shapeSelection: null,
  sizeRadius: {
    feet: 0,
    inches: 0,
  },
  sizeWidth: {
    feet: 0,
    inches: 0,
  },
  sizeLength: {
    feet: 0,
    inches: 0,
  },
  bindingSelection: null,
  bindingSelectionColor: null,
  bindingSelectionColorList: [],
  padSelection: null,
  bindingStitch: null,
  serging: true,
  includeRugPad: true,
  productAttachments: [],
  finishedSize: null,
  productWidth: null,
  widthNumericFeet: 0,
  unitPrice: 0,
  priceUnitTypeName: "Sq Yds",
  fieldPrice: 0,
  padPrice: 0,
  padCutFeeOver31SF: 0,
  cutChargePrice: 0,
  bindingPrice: 0,
  appliedBindingPrice: 0,
  sergingPrice: 0,
  estimatedPrice: 0,
  totalRugPrice: 0,
  freightQuoteDesired: false,
  sendScrapsDesired: false,
  customRugComment: "",
  forOutdoorUse: false,
};

const getItemFromStorage = (itemType, defaultValue = null) => {
  // check before using since it is CORS
  let itemInStorage;
  try {
    itemInStorage =
      localStorage && localStorage.getItem && localStorage.getItem(itemType);
  } catch (e) {
    console.log("e", e);
  }
  if (itemInStorage) {
    try {
      return JSON.parse(localStorage.getItem(itemType));
    } catch {
      console.log(
        `error parsing the JSON from ${itemType} local storage`,
        itemInStorage
      );
      return defaultValue;
    }
  }
  return defaultValue;
};

const setItemToStorage = (itemType, itemValue) => {
  // check before using since it is CORS
  try {
    if (localStorage && localStorage.setItem) {
      localStorage.setItem(itemType, JSON.stringify(itemValue));
    }
  } catch (e) {
    console.log("e", e);
  }
};

const removeItemFromStorage = (itemType) => {
  // check before using since it is CORS
  try {
    if (localStorage && localStorage.removeItem) {
      localStorage.removeItem(itemType);
    }
  } catch (e) {
    console.log("e", e);
  }
};

function shapeEachProduct(
  item,
  index,
  showPrices,
  title,
  section,
  showThisProduct,
  queryStringDCNumber,
  queryStringProductColor
) {
  const {
    dcName, //: "Accents Corolla (Four Seasons UV Rated)"
    dcNumber, //: "W5110"
    productColorId, //: 24258
    productAttachments, //: [{id: 2475, productId: 2046, productAttachmentTypeId: 4,…},…]
    productColors, //: [{id: 19832, productId: 2046, productName: null, colorFamilyId: 2, colorFamilyName: "Black/Gray",…},…]
    unitPrice, //: null
    rugIntlSizes,
  } = item;
  let filteredProductColors = [];
  if (productColors && productColors.length > 0) {
    filteredProductColors = _.chain(productColors)
      .filter((col) => {
        return (
          !_.includes(col.name, "Dropped") &&
          !_.includes(col.name, "DROPPED") &&
          !_.includes(col.name, "No Color")
        );
      })
      .filter((col) => {
        return col.productColorImages && col.productColorImages.length > 0;
      })
      .orderBy((col) => [col.seq, col.updatedAt], ["asc", "desc"])
      .value();
  }
  let rugSizes = [];
  let customRugSQPrice = null;
  let availableAsCustomRug = false;
  let showRugOptions = false;
  item.selectedRugSize = null;
  item.selectedRugPrice = null;
  if (rugIntlSizes && rugIntlSizes.length > 0) {
    showRugOptions = true;
    _.map(rugIntlSizes, (sz) => {
      if (!sz.deactivatedAt && !_.includes(sz.dsc, "Rug Sample")) {
        if (_.includes(sz.dsc, "Custom Rug PSF")) {
          customRugSQPrice = sz.price;
          availableAsCustomRug = true;
        } else {
          rugSizes.push({
            value: sz.id,
            label: sz.dsc,
            price: sz.price,
            seq: sz.seq,
          });
        }
      }
    });
    rugSizes = _.sortBy(rugSizes, (opt) => {
      return opt.seq;
    });
    item.selectedRugSize = rugSizes[0] ? rugSizes[0] : null;
    item.selectedRugPrice = rugSizes[0] ? rugSizes[0].price : null;
  }
  item.showRugOptions = showRugOptions;
  item.availableAsCustomRug = availableAsCustomRug;
  item.rugSizes = rugSizes;
  item.customRugSQPrice = customRugSQPrice;
  //shaping product colors for detail view screen carousels and option selectors
  let colorOptions = [];
  let carouselItems = [];
  let cardDeckItems = [];
  const pushItemToCarousel = (photo, index) => {
    photo.key = `carousel${key}${index}`;
    photo.alt = photo.productAttachmentTypeName;
    const checkForExisting = _.find(carouselItems, (x) => {
      return x.url === photo.url;
    });
    if (!checkForExisting) {
      carouselItems.push(photo);
    }
  };
  const pushItemToCardDeck = (photo, index, title) => {
    photo.key = `cardDeck${key}${index}`;
    photo.alt = photo.productAttachmentTypeName;
    photo.title = title;
    const checkForExisting = _.find(cardDeckItems, (x) => {
      return x.url === photo.url;
    });
    if (!checkForExisting) {
      cardDeckItems.push(photo);
    }
  };
  let mainColorImage = null;
  // get main image to display for list view
  let mainProductImage = null;
  try {
    const bigImage = findMainColorImage(item.productColorImages);
    if (bigImage) {
      bigImage.activeOption = true;
      // no longer adding these to carousel when first on image
      // since the colors are on the right now
      if (title === params.rugs) {
        pushItemToCarousel(bigImage, 0, bigImage.name);
      }
      // pushItemToCardDeck(bigImage, 0, mainColor.name);
      mainColorImage = bigImage;
    } else {
      if (item.url) {
        const imageShape = {
          url: item.url,
          label:
            `${item.colorName}` +
            `${item.colorNumber ? `(${item.colorNumber})` : ""}`,
          activeOption: true,
          title:
            `${item.colorName}` +
            `${item.colorNumber ? `(${item.colorNumber})` : ""}`,
        };
        mainColorImage = imageShape;
        mainProductImage = imageShape;
      }
    }
  } catch {
    console.log("error trying set main color image");
  }
  const key = `ItemCard${_.kebabCase(
    dcName
  )}${productColorId}${index}${dcNumber}`;
  item.key = key;
  // let roomScenePhoto = null;
  let roomScenePhotos = [];
  let closeUpPhoto = null;
  let detailedSpecs = null;
  if (productAttachments && productAttachments.length > 0) {
    _.map(productAttachments, (photo, index) => {
      switch (photo.productAttachmentTypeName) {
        case PRODUCT_IMAGE_ROOM_SCENE.label:
          // roomScenePhoto = photo;
          roomScenePhotos.push(photo);
          pushItemToCarousel(photo, index);
          break;
        case PRODUCT_IMAGE_CLOSE_UP.label:
          closeUpPhoto = photo;
          pushItemToCarousel(photo, index);
          break;
        case DETAILED_SPECS.label:
          detailedSpecs = photo;
          break;
        case PRODUCT_IMAGE_D.label:
          mainProductImage = photo;
          if (title === params.rugs) {
            pushItemToCarousel(photo, index);
          }
          break;
        default:
          break;
      }
    });
  }
  if (filteredProductColors && filteredProductColors.length > 0) {
    _.chain(filteredProductColors)
      .map((pc, index) => {
        let optImage = {
          value: pc.id,
          label: `${pc.name} (${pc.number})`,
          seq: pc.seq,
        };
        colorOptions.push(optImage);
        if (pc.productColorImages && pc.productColorImages.length > 0) {
          const colorImage = findMainColorImage(pc.productColorImages);
          pushItemToCardDeck(colorImage, index, `${pc.name} (${pc.number})`);
        }
      })
      .value();
  }
  if (detailedSpecs) {
    item.detailedSpecsPdf = detailedSpecs;
  }
  const fourSeasonsTitle = " (Four Seasons UV Rated)";
  const petProtect = " (Pet Protect)";
  let cleanerName = _.chain(dcName)
    .replace(fourSeasonsTitle, "")
    .replace(petProtect, "")
    .value();
  item.cleanerName = cleanerName;
  const imageId = `imgKey${key}`;
  // only main images first, so we can get main display photo
  // let productImages = [closeUpPhoto, roomScenePhoto];
  let productImages = roomScenePhotos.unshift(closeUpPhoto);
  let detailedMainPhoto = _.find(productImages, (image) => {
    return image && image.url && image.url.length > 10;
  });
  if (title === params.rugs && mainProductImage) {
    detailedMainPhoto = mainProductImage;
  }
  if (!detailedMainPhoto) {
    detailedMainPhoto = mainColorImage || this.state.preloadedComingSoonImage;
  }
  item.activeColor = _.find(colorOptions, (col) => {
    if (item.productColorId || queryStringProductColor) {
      return col.value === item.productColorId;
    } else {
      return col.seq === 1;
    }
  });
  item.colorOptions = _.sortBy(colorOptions, (col) => {
    return col.label;
  });
  item.carouselItems = carouselItems;
  item.cardDeckItems = _.sortBy(cardDeckItems, (col) => {
    return col.title;
  });
  // rugs only moving imagery to right side since rug main image is tall.
  // except runners which will be displayed like other carpets
  // if (title === params.rugs && section !== params.runners) {
  //   item.cardDeckItems = item.carouselItems;
  //   item.carouselItems = [];
  // }
  item.detailedMainPhoto = detailedMainPhoto;
  item.activePhoto = detailedMainPhoto;
  if (!mainColorImage) {
    mainColorImage = detailedMainPhoto;
  }
  if (!showThisProduct) {
    return item;
  }
  item.listRenderType = (
    <Col
      key={key}
      className={"fifthColStyle"}
      onClick={(e) => {
        showThisProduct(item.productId, item.productColorId);
      }}
    >
      <Row className="ml-0 mr-3">
        <Col xs="12" className="m-0 p-0">
          <Card>
            <CardImg
              top
              width="100%"
              src={
                mainColorImage && mainColorImage.data
                  ? mainColorImage.data
                  : getImageUrl(mainColorImage)
              }
              alt={`Main Image ${cleanerName}`}
              id={imageId}
              className="imgKeyItemCard"
            />
          </Card>
        </Col>
        <Col
          xs={showPrices && title !== params.rugs ? "8" : "12"}
          className="pl-1 pr-0"
        >
          {cleanerName}
          {title === params.rugs && item.colorName && ` (${item.colorName})`}
        </Col>
        {showPrices && title !== params.rugs ? (
          <Col xs="4" className="text-right px-0">
            {formatPrice(unitPrice)}
          </Col>
        ) : null}
      </Row>
    </Col>
  );
  return item;
}

function shapeCustomer(customer, title) {
  customer.webEmail = "";
  const { shipToAddress } = customer;

  if (shipToAddress) {
    customer.stAddressLine1 =
      shipToAddress.addressLine1 || shipToAddress.AddressLine1;
    customer.stAddressLine2 =
      shipToAddress.addressLine2 || shipToAddress.AddressLine2;
    customer.stAddressLine3 =
      shipToAddress.addressLine3 || shipToAddress.AddressLine3;
    customer.stAddressLine4 =
      shipToAddress.addressLine4 || shipToAddress.AddressLine4;
    customer.stState = shipToAddress.state || shipToAddress.State;
    customer.stCity = shipToAddress.city || shipToAddress.City;
    customer.stZip = shipToAddress.zip || shipToAddress.Zip;
  }
  return customer;
}

export default class Squarespace extends Component {
  constructor(props) {
    super(props);
    const token = api.customerToken();
    const showPrices = token ? true : false;
    const sampleCart = getItemFromStorage(params.sampleCartItems, []);
    const orderCart = getItemFromStorage(params.orderCartItems, []);
    const rugQuote = getItemFromStorage(
      params.customRug,
      Object.assign({}, emptyCustomRug)
    );
    const {
      productType,
      section,
      sort_field,
      title,
      filterType,
      subTypes,
      queryStringDCNumber,
      queryStringProductColor,
      shareLinkBase,
    } = parseURL(props);

    let customer = {};
    if (props.currentCustomer) {
      customer = shapeCustomer(props.currentCustomer, title);
    }
    if (title === "Custom Rug") {
      customer.useDefaultShipTo = false;
    } else {
      customer.useDefaultShipTo = true;
    }
    let showSteps = customer && customer.id > 0;
    let incomingProduct = {};
    if (queryStringDCNumber) {
      incomingProduct.productId = queryStringDCNumber;
    }
    if (queryStringProductColor) {
      incomingProduct.productColorId = queryStringProductColor;
    }
    this.state = {
      customer: customer,
      useProductsDisplay: false,
      useCartDisplay: false,
      setLayoutReady: false,
      sampleCartMessage: null,
      sampleCartMessageFlavor: "success",
      orderCartMessage: null,
      orderCartMessageFlavor: "success",
      productType: productType,
      queryStringDCNumber: queryStringDCNumber,
      queryStringProductColor: queryStringProductColor,
      shareLinkBase: shareLinkBase,
      section: section,
      sort_field: sort_field,
      title: title,
      filterType: filterType,
      subTypes: subTypes,
      showPrices: showPrices,
      showCustomRugSteps: showSteps,
      redirect: false,
      redirectTo: "/sq/carpets/carpet/all/none",
      carpetProductTypeList: [],
      colorAndGrainTypeList: [],
      colorFamilyList: [],
      constructionTypeList: [],
      installGradeList: [],
      memoSizeList: [],
      pileTypeList: [],
      productStatusList: [],
      productTypeList: [],
      rugComplexityList: [],
      rugIntlCollectionList: [],
      rugShapeList: [],
      suppliersList: [],
      tradeSizesList: [],
      widthsList: [],
      yarnBrandList: [],
      webCategoryList: [],
      freightTermsList: [],
      padOptionsList: [],
      miscOptionsList: [],
      rugComponentsList: [],
      imageHeight: 253,
      thinking: true,
      // customer cart fields
      productLengthFeet: 0,
      productQuantity: 0,
      activeCustomRugStep: CUSTOM_STEPS.field,
      sidebarOpen: false,
      customRug: rugQuote,
      fieldProductsList: [],
      fieldProductColorsList: [],
      fieldWidthsList: [],
      bindingOptionsList: [],
      filterFieldColorSelection: null,
      quoteOrderSubmitting: false,
      fetchingFieldColors: false,
      productsRowMaxHeight: 500,
      customRugWarnings: null,
      sampleCartWarnings: null,
      orderCartWarnings: null,
      sampleCartItems: sampleCart,
      orderCartItems: orderCart,
      activeCart: params.sampleCartItems,
      orderCartSubmitting: false,
      orderComment: "",
      freightTerms: null,
      sampleComment: "",
      sampleCartSubmitting: false,
      pagedList: {
        list: [],
        pageSize: 20,
        pageNumber: 1,
        totalPages: 1,
      },
      redirect: false,
      redirectTo: "/sq/carpets/carpet/all/none",
      filters: initialFilters,
      sort_dir: "asc",
      imageHeight: 253,
      thinking: true,
      preloadedComingSoonImage: {
        src: "Coming_Soon.png",
        data: "",
        alt: "No image for this item yet",
      },
      fetchingProduct: null,
      singleProductDisplay:
        incomingProduct && incomingProduct.productId ? incomingProduct : null,
      mobileFilterCollapse: false,
      showSortingDropDown: false,
    };
    this.initializeScreenType = this.initializeScreenType.bind(this);
    this.initializeFilters = this.initializeFilters.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setPage = this.setPage.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onCustomerChangeHandler = this.onCustomerChangeHandler.bind(this);
    this.onCustomerSwitch = this.onCustomerSwitch.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.customRugFormComplete = this.customRugFormComplete.bind(this);
    this.setActiveCustomRugStep = this.setActiveCustomRugStep.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.selectRugSize = this.selectRugSize.bind(this);
    this.selectProductWidth = this.selectProductWidth.bind(this);
    this.onChangeCustomRugHandler = this.onChangeCustomRugHandler.bind(this);
    this.onChangeCustomRugInput = this.onChangeCustomRugInput.bind(this);
    this.customRugQuoteIsValid = this.customRugQuoteIsValid.bind(this);
    this.calculateCustomRugEstimate =
      this.calculateCustomRugEstimate.bind(this);
    this.submitCustomRugQuote = this.submitCustomRugQuote.bind(this);
    this.getColorSelectionsForCustomRugField =
      this.getColorSelectionsForCustomRugField.bind(this);
    this.shapeOrderLine = this.shapeOrderLine.bind(this);
    this.shapeRugComponentOrderLine =
      this.shapeRugComponentOrderLine.bind(this);
    this.resetCustomRug = this.resetCustomRug.bind(this);
    this.downloadDetailedSpecs = this.downloadDetailedSpecs.bind(this);
    this.downloadExport = this.downloadExport.bind(this);
    this.calculateFigures = this.calculateFigures.bind(this);
    this.toggleCustomRugOption = this.toggleCustomRugOption.bind(this);
    this.validateWidthSelection = this.validateWidthSelection.bind(this);
    this.node = null;
    this.showThisProduct = this.showThisProduct.bind(this);
    this.goBackToList = this.goBackToList.bind(this);
    this.SidebarSection = this.SidebarSection.bind(this);
    this.CustomerDisplay = this.CustomerDisplay.bind(this);
    this.CartDisplay = this.CartDisplay.bind(this);
    this.CustomRugDisplay = this.CustomRugDisplay.bind(this);
    this.toggleActiveCart = this.toggleActiveCart.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addSampleToCart = this.addSampleToCart.bind(this);
    this.removeSampleFromCart = this.removeSampleFromCart.bind(this);
    this.deleteSampleFromCart = this.deleteSampleFromCart.bind(this);
    this.addToOrderCart = this.addToOrderCart.bind(this);
    this.deleteFromOrderCart = this.deleteFromOrderCart.bind(this);
    this.purchaseOrderFormValid = this.purchaseOrderFormValid.bind(this);
    this.submitPurchaseOrder = this.submitPurchaseOrder.bind(this);
    this.sampleOrderFormValid = this.sampleOrderFormValid.bind(this);
    this.submitSampleOrder = this.submitSampleOrder.bind(this);
    this.selectTerms = this.selectTerms.bind(this);
    this.selectColorOption = this.selectColorOption.bind(this);
    this.selectColorOptionByPhoto = this.selectColorOptionByPhoto.bind(this);
    this.fetchProductData = this.fetchProductData.bind(this);
    this.getPublicProductInfo = this.getPublicProductInfo.bind(this);
    this.saveCartData = this.saveCartData.bind(this);
    this.GetCartData = this.GetCartData.bind(this);
    this.clearCustomerEmailandRequestedBy =
      this.clearCustomerEmailandRequestedBy.bind(this);
    this.formatPhoneField = this.formatPhoneField.bind(this);
    this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
    this.hideMobileFilters = this.hideMobileFilters.bind(this);
  }

  formatPhoneField(v) {
    let cleaned = ("" + v).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      v = "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    this.setState({
      customer: {
        ...this.state.customer,
        stPhone: v,
      },
    });
  }

  toggleMobileFilters() {
    this.setState({ mobileFilterCollapse: !this.state.mobileFilterCollapse });
  }

  hideMobileFilters() {
    this.setState({ mobileFilterCollapse: false });
  }

  saveCartData() {
    let uniqueCartData = {};
    if (this.state.customer && this.state.customer.id) {
      //this.setState({thinking : true});
      // let customerID = String(this.state.customer.id);
      // let customerCartObject = {
      //   sampleCartItems: this.state.sampleCartItems,
      //   orderCartItems: this.state.orderCartItems,
      //   CustomRug: this.state.customRug
      // }
      uniqueCartData = {
        CustomerID: String(this.state.customer.id),

        CustomerCartObject: JSON.stringify({
          sampleCartItems: this.state.sampleCartItems || [],
          orderCartItems: this.state.orderCartItems || [],
          CustomRug: this.state.customRug || object.assign({}, emptyCustomRug),
        }),

        ExpiryMinutes: null,
      };
    }
    //let payload = JSON.stringify(uniqueCartData);
    console.log("UCD: ", uniqueCartData);
    api
      .post(`Web/SaveCartData`, uniqueCartData)
      .then((response) => {
        if (response && response.data) {
          console.log("User: ", this.state.customer);
          console.log("API succesfully sent");
          console.log("response: ", response.data);
        }
      })
      .catch((error) => error)
      .finally(() => this.setState({ thinking: false }));
  }
  GetCartData() {
    //this.setState({thinking : true});
    let CustomerCartObject = {};
    api
      .fetch(`Web/GetCartData/${this.state.customer.id}`)
      .then((result) => {
        if (result && result.data && result.data.success) {
          let parsedResult = JSON.parse(result);

          CustomerCartObject.sampleCartItems =
            parsedResult.data.sampleCartItems;
          CustomerCartObject.orderCartItems = parsedResult.data.orderCartItems;
          CustomerCartObject.customRug = parsedResult.data.customRug;
        }
        // console.log("Cache results", result);
        if (CustomerCartObject) {
          if (CustomerCartObject.sampleCartItems) {
            setItemToStorage(
              params.sampleCartItems,
              CustomerCartObject.sampleCartItems
            );
          }
          if (CustomerCartObject.orderCartItems) {
            setItemToStorage(
              params.orderCartItems,
              CustomerCartObject.orderCartItems
            );
          }
          if (CustomerCartObject.customRug) {
            setItemToStorage(params.customRug, CustomerCartObject.customRug);
          }
        }
      })
      .catch((error) => error)
      .finally(() => this.setState({ thinking: false }));
  }

  componentDidMount() {
    this.initializeScreenType();
    this.node = ReactDOM.findDOMNode(this);
    let comingSoonImage = Object.assign(
      {},
      this.state.preloadedComingSoonImage
    );
    const getImageComingSoon = () => {
      return axios.get(comingSoonImage.src, {
        responseType: "arraybuffer",
      });
    };
    Promise.all([getImageComingSoon()])
      .then((response) => {
        if (response && response.length) {
          let imageData = Buffer.from(response[0].data, "binary").toString(
            "base64"
          );
          let newState = Object.assign({}, this.state);
          comingSoonImage.data = `data:image/png;base64, ${imageData}`;
          newState.preloadedComingSoonImage = comingSoonImage;
          this.setState(newState);
        }
        //this.saveCartData();
        this.clearCustomerEmailandRequestedBy();
        this.GetCartData();
      })
      .catch(helpers.catchHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.initializeScreenType();
    }

    if (
      this.props.currentCustomer &&
      !_.isEqual(prevProps.currentCustomer, this.props.currentCustomer)
    ) {
      const newCustomerShape = shapeCustomer(
        this.props.currentCustomer,
        this.state.title
      );
      if (
        !_.isEqual(this.state.customer, newCustomerShape) &&
        !this.state.customer.tainted
      ) {
        this.setState({ customer: newCustomerShape });
      }
    }
  }

  componentWillUnmount() {
    this.saveCartData();

    setItemToStorage(params.sampleCartItems, this.state.sampleCartItems);
    setItemToStorage(params.orderCartItems, this.state.orderCartItems);
    setItemToStorage(params.customRug, this.state.customRug);
  }

  initializeScreenType() {
    const parsedParams = parseURL(this.props);
    let {
      page,
      productType,
      section,
      sort_field,
      title,
      filterType,
      subTypes,
      queryStringDCNumber,
      queryStringProductColor,
      shareLinkBase,
    } = parsedParams;
    const newState = Object.assign(parsedParams, this.state);
    if (page === "log-in-sign-up") {
      this.setState({
        redirect: true,
        redirectTo: "/sq/sign_in_or_register",
      });
      return;
    }
    if (page === "shopping-cart" || productType === "cart") {
      newState.useCartDisplay = true;
      newState.setLayoutReady = true;
      this.setState(newState);
      return;
    }
    if (page === "all-carpet") {
      productType = "Carpets";
      title = params.carpet;
      filterType = title;
    }
    newState.useProductsDisplay = true;
    const getProductSelectData = api
      .fetch("Product/GetProductSelectsData")
      .then((response) => {
        if (response && response.data) {
          let results = {};
          results.carpetProductTypeList = response.data.carpetProductTypeList;
          results.colorAndGrainTypeList = response.data.colorAndGrainTypeList;
          results.colorFamilyList = response.data.colorFamilyList;
          results.constructionTypeList = response.data.constructionTypeList;
          results.installGradeList = response.data.installGradeList;
          results.memoSizeList = response.data.memoSizeList;
          results.pileTypeList = _.sortBy(response.data.pileTypeList, (opt) => {
            return opt.label;
          });
          results.fiberGroupList = _.sortBy(
            response.data.fiberGroupList,
            (opt) => {
              return opt.label;
            }
          );
          results.productStatusList = response.data.productStatusList;
          results.productTypeList = response.data.productTypeList;
          results.rugComplexityList = response.data.rugComplexityList;
          results.rugIntlCollectionList = response.data.rugIntlCollectionList;
          results.rugShapeList = response.data.rugShapeList;
          results.suppliersList = response.data.suppliersList;
          results.tradeSizesList = response.data.tradeSizesList;
          results.widthsList = response.data.widthsList;
          results.yarnBrandList = _.sortBy(
            response.data.yarnBrandList,
            (opt) => {
              return opt.label;
            }
          );
          results.webCategoryList = response.data.webCategoryList;
          return results;
        }
      });
    const getFreightTermsSelectList = api
      .fetch("Web/GetFreightTermList")
      .then((result) => {
        if (result && result.data) {
          return { freightTermsList: result.data };
        }
      })
      .catch(helpers.catchHandler);
    const customRugOptions = api
      .fetch(`Product/GetCustomRugSelectsData`)
      .then((response) => {
        if (response && response.data) {
          // border no longer an option in the custom rug section
          const [borderList, bindingList] = _.partition(
            response.data.bindingOptionsList,
            (opt) => {
              return _.includes(opt.label, "Border");
            }
          );
          return {
            fieldProductsList: response.data.fieldProductsList,
            fieldProductColorsList: response.data.fieldProductColorsList,
            fieldWidthsList: response.data.fieldWidthsList,
            bindingOptionsList: bindingList,
            padOptionsList: response.data.padOptionsList,
            miscOptionsList: response.data.miscOptionsList,
            rugComponentsList: response.data.rugComponentsList,
          };
        }
      })
      .catch(helpers.catchHandler);
    Promise.all([
      getProductSelectData,
      getFreightTermsSelectList,
      customRugOptions,
    ])
      .then((aggregateResults) => {
        const newStatus = Object.assign({}, newState);
        aggregateResults.forEach((r) => Object.assign(newStatus, r));
        if (productType === params.customRug) {
          newStatus.useCustomRugDisplay = true;
          newStatus.setLayoutReady = true;
          this.setState(newStatus);
          return;
        }
        newStatus.setLayoutReady = true;
        this.setState(newStatus, () => {
          if (
            this.state.useProductsDisplay &&
            !this.state.singleProductDisplay
          ) {
            this.initializeFilters();
            return;
          }
          if (
            this.state.singleProductDisplay &&
            this.state.singleProductDisplay.productId
          ) {
            this.fetchProductData();
            return;
          }
        });
      })
      .catch(helpers.catchHandler);
  }

  initializeFilters() {
    let newStatus = {
      filters: initialFilters,
    };
    let filterList = newStatus.filters;
    // setting product type filters
    const typeFilter = _.find(
      this.state.productTypeList,
      (option) => option.label === this.state.filterType
    );
    if (typeFilter) {
      let productTypes = [typeFilter.value];
      if (this.state.section === "Commercial") {
        const carpetTile = _.find(
          this.state.productTypeList,
          (option) => option.label === "Carpet Tile"
        );
        productTypes.push(carpetTile.value);
      }
      const typeShape = {
        filterName: "ProductTypeList",
        value: productTypes,
      };
      filterList.push(typeShape);
    }
    // setting web category filters
    if (this.state.subTypes && this.state.subTypes.length > 0) {
      const subtypeFilters = _.filter(this.state.webCategoryList, (option) =>
        _.includes(this.state.subTypes, option.label)
      );
      if (subtypeFilters) {
        const typeShape = {
          filterName: "WebCategoryList",
          value: _.map(subtypeFilters, (opt) => opt.value),
        };
        filterList.push(typeShape);
      }
    }
    newStatus.filters = filterList;
    this.setState(newStatus, () => {
      this.refreshList(
        this.state.sort_field,
        this.state.sort_dir,
        filterList,
        1
      );
    });
  }

  refreshList(sort_field, sort_direction, filters, pageNumber) {
    const { pagedList, showPrices, queryStringDCNumber } = this.state;
    let filterList = Object.assign([], filters || this.state.filters);
    let payload = {
      Page: pageNumber || pagedList.pageNumber,
      PageSize: pagedList.pageSize,
      SortField: sort_field || this.state.sort_field,
      SortDir: sort_direction || this.state.sort_dir,
      // filters: filter_helpers.transform_filters(filters || this.state.filters)
    };
    this.setState({ thinking: true }, () => {
      // always put active filter
      filterList.push(activeFilter);
      // always put publicFacing so the shape of the data is scrubbed for the public API
      filterList.push(publicFilter);
      const showPricesShape = {
        filterName: "ShowPrices",
        value: showPrices,
      };
      // send back whether or not to show pricing
      filterList.push(showPricesShape);
      if (queryStringDCNumber) {
        filterList.push({
          filterName: "DCNumber",
          value: queryStringDCNumber,
        });
      }
      _.each(
        filterList,
        (filter) => (payload[filter.filterName] = filter.value)
      );
      api
        .post("Product/GetPublicPaginatedList", payload)
        .then((response) => {
          if (response && response.data) {
            const pagedList = response.data;
            pagedList.list = _.map(pagedList.list, (x, index) => {
              return shapeEachProduct(
                x,
                index,
                this.state.showPrices,
                this.state.title,
                this.state.section,
                this.showThisProduct,
                this.state.queryStringDCNumber,
                this.state.queryStringProductColor
              );
            });
            let newState = Object.assign({}, this.state);
            newState.pagedList = pagedList;
            this.setState(newState, this.setImageHeight);
          }
        })
        .catch((error) => error)
        .finally(() => this.setState({ thinking: false }));
    });
  }

  setImageHeight() {
    const { pagedList } = this.state;
    const { list } = pagedList;
    if (pagedList && list && list.length > 0) {
      if (this.node && this.node instanceof HTMLElement) {
        const parent = this.node.parentElement;
        if (parent) {
          const childG = parent.querySelector(`.imgKeyItemCard`);
          const gWidth = childG
            ? childG.clientWidth
              ? childG.clientWidth
              : 253
            : 253;
          if (childG) {
            this.setState({ imageHeight: gWidth });
          }
        }
      }
      var parentIFrameHeight = window.innerHeight;
      if (parentIFrameHeight) {
        // 90 is the height of search bar and pager bar combined
        const maxHeight = _.min([parentIFrameHeight - 50, 500]);
        this.setState({ productsRowMaxHeight: maxHeight });
      }
    }
  }

  filterChange(changedFilter) {
    const filters = filter_helpers.get_filters(
      this.state.filters,
      changedFilter
    );
    this.setState({ filters: filters, clearValue: false }, () =>
      this.refreshList(null, null, filters, 1)
    );
  }

  clearFilters() {
    const filters = [];
    this.setState({ filters: filters, clearValue: true }, () =>
      this.refreshList(null, null, filters, 1)
    );
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find((f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
  }

  doSort(field, direction) {
    this.setState({ sort_dir: direction, sort_field: field }, () =>
      this.refreshList(field, direction)
    );
  }

  setPage(page) {
    const newPagedList = Object.assign({}, this.state.pagedList);
    newPagedList.pageNumber = page;
    this.setState({ pagedList: newPagedList }, this.refreshList);
  }

  showThisProduct(productId, productColorNumber) {
    this.setState(
      {
        singleProductDisplay: {
          productId: productId,
          productColorId: productColorNumber,
        },
      },
      () => this.fetchProductData()
    );
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  resetCustomRug() {
    const customer = Object.assign({}, this.state.customer);
    customer.CustomerPONumber = null;
    customer.tainted = true;
    this.setState(
      {
        customer: customer,
        customRug: Object.assign({}, emptyCustomRug),
      },
      () => {
        setItemToStorage(params.customRug, this.state.customRug);
      }
    );
  }

  onChangeHandler(event) {
    this.setState({
      editItem: {
        ...this.state.editItem,
        [event.target.name]: event.target.value,
      },
    });
  }

  onCustomerChangeHandler(event) {
    let item = Object.assign({}, this.state.customer);
    item[event.target.name] = event.target.value;
    item.tainted = true;
    this.setState({
      customer: item,
    });
  }

  clearCustomerEmailandRequestedBy() {
    let item = Object.assign({}, this.state.customer);
    // item["requestedBy"] = null;
    item["requestedByFirstName"] = null;
    item["requestedByLastName"] = null;
    item["webEmail"] = null;
    item.tainted = true;
    this.setState({
      customer: item,
    });
  }

  onCustomerSwitch(event, field) {
    let item = Object.assign({}, this.state.customer);
    item[field] = event;
    item.tainted = true;
    this.setState({
      customer: item,
    });
  }

  onCancel() {
    setTimeout(() => {
      this.setState({ redirect: true });
    }, 1000);
  }

  handleCommentChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  selectRugSize(option, showProduct) {
    let selectedItem = Object.assign({}, showProduct);
    selectedItem.selectedRugSize = option;
    selectedItem.selectedRugPrice = option.price;
    selectedItem.unitPrice = option.price;
    this.setState(
      {
        showProduct: selectedItem,
      },
      () => setItemToStorage(params.customRug, this.state.customRug)
    );
  }

  selectProductWidth(option) {
    this.setState({
      selectedProductWidth: option,
    });
  }

  async fetchProductData() {
    if (!this.state.singleProductDisplay) return;
    const { productId, productColorId } = this.state.singleProductDisplay;
    this.setState({ fetchingProduct: true }, async () => {
      let aggResults = await Promise.all([
        this.getPublicProductInfo(productId, productColorId),
      ]);
      try {
        let results = {};
        aggResults.forEach((r) => Object.assign(results, r));
        if (results && results.productShape) {
          let productShape = shapeEachProduct(
            results.productShape,
            0,
            this.state.showPrices,
            this.state.title,
            this.state.section,
            null,
            this.state.queryStringDCNumber,
            this.state.queryStringProductColor
          );
          const productWidthOptions = _.map(productShape.productWidths, (w) => {
            return {
              value: w.widthId,
              label: w.widthName,
              widthNumericFeet: w.widthNumericFeet,
            };
          });
          let newState = Object.assign({}, this.state);
          newState.fetchingProduct = false;
          newState.productWidthOptions = productWidthOptions;
          newState.selectedProductWidth = productWidthOptions[0];
          if (productColorId) {
            if (!productShape.activeColor) {
              productShape.activeColor = _.find(
                productShape.colorOptions,
                (opt) => {
                  return opt.value === productColorId;
                }
              );
            }
            if (!productShape.activePhoto) {
              let activePhoto = null;
              productShape.cardDeckItems = _.map(
                productShape.cardDeckItems,
                (ci) => {
                  const active = ci.productColorId === productColorId;
                  ci.activeOption = active;
                  if (active) {
                    activePhoto = ci;
                  }
                  return ci;
                }
              );
              productShape.activePhoto = activePhoto;
            }
          }
          newState.showProduct = productShape;
          this.setState(newState);
        }
      } catch {
        console.log("error fetching product info", aggResults);
      }
    });
  }

  getPublicProductInfo = (productId, colorNumber) => {
    return api
      .fetch(`Product/GetPublicProduct/${productId}/${colorNumber}`)
      .then((response) => {
        if (response && response.data) {
          return { productShape: response.data };
        }
      })
      .catch(helpers.catchHandler);
  };

  selectColorOption(option) {
    const productCopy = Object.assign({}, this.state.showProduct);
    let { carouselItems, cardDeckItems } = productCopy;
    productCopy.activeColor = option;
    let activePhoto = null;
    productCopy.cardDeckItems = _.map(cardDeckItems, (ci) => {
      const active = option.value === ci.productColorId;
      ci.activeOption = active;
      if (active) {
        activePhoto = ci;
      }
      return ci;
    });
    productCopy.carouselItems = _.map(carouselItems, (ci) => {
      const active = option.value === ci.productColorId;
      ci.activeOption = active;
      if (active) {
        activePhoto = ci;
      }
      return ci;
    });
    productCopy.activePhoto = activePhoto;
    this.setState({
      showProduct: productCopy,
    });
  }

  selectColorOptionByPhoto(photo) {
    const productCopy = Object.assign({}, this.state.showProduct);
    let { carouselItems, cardDeckItems, colorOptions } = productCopy;
    productCopy.activePhoto = photo;
    let activeColorOption = productCopy.activeColor;
    if (photo.productColorId) {
      const optionMatch = _.find(colorOptions, (opt) => {
        return opt.value === photo.productColorId;
      });
      activeColorOption = optionMatch;
      productCopy.carouselItems = _.map(carouselItems, (ci) => {
        ci.activeOption = optionMatch.value === ci.productColorId;
        return ci;
      });
      productCopy.cardDeckItems = _.map(cardDeckItems, (ci) => {
        ci.activeOption = optionMatch.value === ci.productColorId;
        return ci;
      });
    }
    productCopy.activeColor = activeColorOption;
    this.setState({
      showProduct: productCopy,
    });
  }

  calculateFigures(product) {
    const { priceUnitTypeName } = product;
    let productQuantity;
    if (priceUnitTypeName === "Sq Yds") {
      productQuantity = _.round(
        (this.state.selectedProductWidth.widthNumericFeet *
          this.state.productLengthFeet) /
          9,
        2
      ).toFixed(2);
    }
    if (priceUnitTypeName === params.squareFeet) {
      if (product.productTypeName !== params.wood) {
        productQuantity = _.round(
          this.state.selectedProductWidth.widthNumericFeet *
            this.state.productLengthFeet,
          2
        ).toFixed(2);
      }
    }
    if (priceUnitTypeName === params.squareFeet) {
      productQuantity = _.round(this.state.productLengthFeet, 2).toFixed(2);
    }
    this.setState({ productQuantity: productQuantity });
  }

  shapeOrderLine(item, comment) {
    const { customer, customRug } = this.state;
    let dsc = "";
    switch (item.productTypeName) {
      case params.carpet:
        dsc = `${item.cleanerName}, ${
          item.selectedColor ? item.selectedColor.label : ""
        } (${item.selectedProductWidth.label} X ${item.lengthFeet}) = ${
          item.qty || "0"
        } ${item.priceUnitTypeName}`;
        break;
      case "Intl Rug":
        dsc = `${item.qty || "0"} X ${item.cleanerName} ${
          item.selectedColor ? item.selectedColor.label : ""
        } (${item.selectedRugSize.label})`;
        break;
      default:
        if (item.selectedColor) {
          dsc = `${item.qty} X ${item.cleanerName}, ${item.selectedColor.label}`;
        } else {
          dsc = `${item.qty} X ${item.cleanerName}`;
        }
    }
    const useCarton =
      item.priceUnitTypeId === constants.PRICE_UNIT_TYPE.CARTON.value;
    const data = {
      Id: 0,
      OrderId: 0,
      ParentOrderLineId: null,
      LastStatusAt: new Date(),
      ProductId: item.id,
      ProductColorId: item.selectedColor ? item.selectedColor.value : null,
      LengthFeet: item.lengthFeet ? Number(item.lengthFeet) : null,
      Qty: item.qty ? Number(item.qty) : 0,
      CartonCount: useCarton && item.qty ? Number(item.qty) : null,
      PriceUnitTypeId: item.priceUnitTypeId ? item.priceUnitTypeId : null,
      OrderLineTypeId: resolveOrderLineType(item),
      Dsc: dsc,
      Price: Number(item.price / 100.0),
      UnitPrice: Number(item.unitPrice / 100.0),
      WidthId: item.selectedProductWidth
        ? Number(item.selectedProductWidth.value)
        : null,
      SendScrapsDesired: false,
      CreatedAt: moment(),
    };
    if (item.selectedRugSize) {
      data.Price = Number(item.selectedRugSize.price / 100.0);
      data.UnitPrice = Number(item.selectedRugSize.price / 100.0);
      data.RugIntlSizeId = item.selectedRugSize.value;
    }
    return {
      OrderLine: data,
      FreightOrderLine: null,
      OrderLineComments:
        comment && comment !== ""
          ? [
              {
                Dsc: comment,
              },
            ]
          : [],
    };
  }

  shapeRugComponentOrderLine(
    product,
    componentType,
    selectedColor,
    comment,
    typeId,
    price,
    qty,
    selectedWidth,
    feetLength,
    inchesLength,
    feetWidth,
    inchesWidth,
    repeatLength,
    repeatWidth
  ) {
    const productName = product.label;
    const productId = product.value;
    let priceUnitTypeId = product.priceUnitTypeId;
    let repeatNumeric = product.repeatNumeric ? product.repeatNumeric : 0;
    if (!priceUnitTypeId && product.priceUnitTypeName) {
      const unitType = _.find(constants.PRICE_UNIT_TYPE, (t) => {
        return t.label === product.priceUnitTypeName;
      });
      if (unitType) {
        priceUnitTypeId = unitType.value;
      }
    }
    let repeatLengthValue = 0;
    let repeatWidthvalue = 0;
    const { customer, customRug } = this.state;
    let dsc = "";
    if (componentType === "Custom Rug") {
      dsc = `${feetWidth}' ${inchesWidth}" x ${feetLength}' ${inchesLength}" ${
        customRug.shapeSelection.value ===
        constants.CUSTOM_RUG_SHAPE_LIST.ROUND.value
          ? "Round"
          : ""
      } Custom Rug`;
      repeatLengthValue = repeatLength ? Number(repeatLength) : 0;
      repeatWidthvalue = repeatWidth ? Number(repeatWidth) : 0;
    } else if (componentType === "Rug Pad") {
      dsc = calc.getRugPadDescription(productName, null);
    } else if (componentType === "Field") {
      dsc = `${calc.getFieldDescription(
        productName,
        selectedColor
      )} - (Custom Size)`;
      repeatLengthValue = repeatLength ? Number(repeatLength) : 0;
      repeatWidthvalue = repeatWidth ? Number(repeatWidth) : 0;
    } else {
      if (selectedColor) {
        if (componentType == "Binding") {
          dsc = `${productName}, ${selectedColor.label}`;
        } else {
          dsc = `${componentType}: ${productName}, ${selectedColor.label}`;
        }
      } else {
        dsc = `${componentType} X ${productName}`;
      }
    }

    if (selectedWidth && selectedWidth.widthId) {
      selectedWidth = selectedWidth.widthId;
    }
    const data = {
      Id: 0,
      OrderId: 0,
      ParentOrderLineId: null,
      LastStatusAt: new Date(),
      ProductId: productId,
      ProductColorId: selectedColor ? selectedColor.value : null,
      Qty: qty ? Number(qty) : 0,
      CartonCount: null,
      PriceUnitTypeId: priceUnitTypeId ? priceUnitTypeId : null,
      OrderLineTypeId: typeId,
      Dsc: dsc,
      Price: price && price > 0 ? Number(price / 100.0) : 0,
      UnitPrice: 0,
      WidthId: selectedWidth,
      RepeatWidth: repeatWidthvalue,
      RepeatLength: repeatLengthValue,
      RugLengthFeet: Number(feetLength),
      RugLengthInches: Number(inchesLength),
      RugWidthFeet: Number(feetWidth),
      RugWidthInches: Number(inchesWidth),
      ForOutdoorUse: customRug.forOutdoorUse,
      FreightQuoteDesired:
        _.isBoolean(customRug.freightQuoteDesired) &&
        componentType === "Custom Rug"
          ? customRug.freightQuoteDesired
          : false,
      SendScrapsDesired:
        _.isBoolean(customRug.sendScrapsDesired) &&
        componentType === "Custom Rug"
          ? customRug.sendScrapsDesired
          : false,
      RepeatNumeric: Number(repeatNumeric),
    };
    if (componentType === "Rug Pad" || componentType === "Field") {
      data.LengthFeet =
        Number(data.RugLengthFeet) + Number(data.RugLengthInches) / 12.0;
    }
    return {
      OrderLine: data,
      OrderLineComments:
        comment && comment !== ""
          ? [
              {
                Dsc: comment,
              },
            ]
          : [],
    };
  }

  async toggleCustomRugOption(bool, type) {
    let customRug = Object.assign({}, this.state.customRug);
    customRug[type] = !bool;
    if (type === params.serging && customRug.serging) {
      // clearing out binding selection if serging is selected as true again
      customRug.bindingSelection = null;
      customRug.bindingSelectionColor = null;
      customRug.bindingSelectionColorList = [];
    }
    let calculations = await this.calculateCustomRugEstimate(customRug);
    customRug = Object.assign(customRug, calculations);
    this.setState(
      {
        customRug: customRug,
      },
      () => setItemToStorage(params.customRug, this.state.customRug)
    );
  }

  // this function should only check if width is less than product with, not if all fields are entered
  validateWidthSelection(shapeLabel) {
    const { customRug } = this.state;
    const { widthNumericFeet, sizeRadius, finishedSize, sizeWidth } = customRug;
    let validity = true;
    if (widthNumericFeet) {
      if ((shapeLabel === ROUND || shapeLabel === SQUARE) && sizeRadius) {
        const widthFeet =
          Number(sizeRadius.feet) + Number(sizeRadius.inches) / 12.0;
        validity = widthFeet <= widthNumericFeet;
      }
      // if (shapeLabel === RECTANGULAR && finishedSize && finishedSize.label) {
      //   if (finishedSize.label === "Custom") {
      //     const widthFeet =
      //       Number(sizeWidth.feet) + Number(sizeWidth.inches) / 12.0;
      //     validity = widthFeet <= widthNumericFeet;
      //   } else {
      //     const widthFeet =
      //       Number(finishedSize.rugWidthFeet) +
      //       Number(finishedSize.rugWidthInches) / 12.0;
      //     validity = widthFeet <= widthNumericFeet;
      //   }
      // }
      if (shapeLabel === RECTANGULAR && sizeWidth) {
        const widthFeet =
          Number(sizeWidth.feet) + Number(sizeWidth.inches) / 12.0;
        validity = widthFeet <= widthNumericFeet;
      }
    }
    return validity;
  }

  customRugQuoteIsValid() {
    const { customRug } = this.state;
    const {
      fieldSelection,
      fieldSelectionColorList,
      fieldSelectionColor,
      shapeSelection,
      sizeRadius,
      finishedSize,
      sizeWidth,
      sizeLength,
      serging,
      bindingSelection,
      bindingSelectionColor,
    } = customRug;
    let warningsList = [];
    if (!fieldSelection || !fieldSelection.value) {
      warningsList.push("Please select a field carpet on step 1.");
    }
    if (
      fieldSelection &&
      fieldSelection.value &&
      !(
        fieldSelectionColorList.length === 0 ||
        (fieldSelectionColor && fieldSelectionColor.value)
      )
    ) {
      warningsList.push("Please select a field color on step 1.");
    }
    if (
      !(
        shapeSelection &&
        shapeSelection.value &&
        (((shapeSelection.label === ROUND || shapeSelection.label === SQUARE) &&
          sizeRadius &&
          sizeRadius.feet > 0 &&
          this.validateWidthSelection(customRug.shapeSelection.label)) ||
          (shapeSelection.label === RECTANGULAR &&
            // (finishedSize && finishedSize.value && finishedSize.value > 1) ||
            sizeWidth &&
            sizeWidth.feet > 0 &&
            sizeLength &&
            sizeLength.feet > 0 &&
            this.validateWidthSelection(customRug.shapeSelection.label)))
      )
    ) {
      warningsList.push("Please select a shape and dimensions on step 2.");
    }
    if (
      !(
        serging ||
        (bindingSelection &&
          bindingSelection.value &&
          bindingSelectionColor &&
          bindingSelectionColor.value)
      )
    ) {
      warningsList.push(
        "Please choose either default serging or a binding with color on step 3."
      );
    }
    return warningsList;
  }

  getSizes(customRug) {
    const { finishedSize, sizeLength, sizeWidth, sizeRadius, shapeSelection } =
      customRug;

    let feetLength = 0;
    let inchesLength = 0;
    let feetWidth = 0;
    let inchesWidth = 0;

    if (shapeSelection.label === "Rectangular") {
      //&& finishedSize
      // if (finishedSize.label === "Custom" && sizeLength && sizeWidth) {
      feetLength = sizeLength.feet;
      inchesLength = sizeLength.inches;
      feetWidth = sizeWidth.feet;
      inchesWidth = sizeWidth.inches;
      // } else {
      //   feetLength = finishedSize.rugLengthFeet;
      //   inchesLength = finishedSize.rugLengthInches;
      //   feetWidth = finishedSize.rugWidthFeet;
      //   inchesWidth = finishedSize.rugWidthInches;
      // }
    }
    if (shapeSelection.label === "Round" || shapeSelection.label === "Square") {
      feetLength = sizeRadius.feet;
      inchesLength = sizeRadius.inches;
      feetWidth = sizeRadius.feet;
      inchesWidth = sizeRadius.inches;
    }
    return {
      feetLength: feetLength,
      inchesLength: inchesLength,
      feetWidth: feetWidth,
      inchesWidth: inchesWidth,
    };
  }

  async calculateCustomRugEstimate(customRug) {
    let fieldPayload = null;
    let padPayload = null;
    let bindingPayload = null;
    let appliedBindingPayload = null;
    let sergingPayload = null;
    let cutChargePayload = null;
    let padCutFeeOver31SFPayload = null;
    let inboundFreightPayload = null;
    let armorPayload = null;
    let backingPayload = null;
    let seamPayload = null;
    let laborRoundProductPayload = null;
    let priceList = [];
    let totalFieldUnits;
    const { miscOptionsList, rugComponentsList, padOptionsList } = this.state;

    // default backing charge
    const backingCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultBackingProduct;
    });
    const cutCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultCutChargeProduct;
    });
    const defaultPad = _.find(rugComponentsList, (ch) => {
      return (
        ch.configName === configNames.DefaultRugPadLuxFeltCustomSizeProduct
      );
    });
    // const defaultPad = _.find(padOptionsList, (ch) => {
    //   return _.includes(ch.label, "Rug Pad Lux Felt");
    // });
    const over31SFPadCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultPadCutChargeProduct;
    });
    const under30SFPadCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.Under30SFPadCutChargeProduct;
    });
    const hiddenStitchBindingCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultMiteredProduct;
    });
    // const defaultSergeCharge = _.find(rugComponentsList, (ch) => {
    //   return ch.configName === configNames.DefaultWoolSergingChargeProduct;
    // });
    let defaultSergeCharge;
    if (
      customRug.fieldSelectionSupplierId ==
      constants.COMMON_SUPPLIER_IDS.NOURISON
    ) {
      defaultSergeCharge = _.find(rugComponentsList, (ch) => {
        return ch.configName === configNames.DefaultWoolSergingChargeNProduct;
      });
    } else if (customRug.forOutdoorUse) {
      defaultSergeCharge = _.find(rugComponentsList, (ch) => {
        return ch.configName === configNames.DefaultUVSergingChargeProduct;
      });
    } else {
      defaultSergeCharge = _.find(rugComponentsList, (ch) => {
        return ch.configName === configNames.DefaultWoolSergingChargeProduct;
      });
    }
    const machineAppliedCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultAppliedMachineBordersProduct;
    });
    const seamCharge = _.find(rugComponentsList, (ch) => {
      return ch.configName === configNames.DefaultSevenSeamPadPerLFFeeProduct;
    });

    customRug.rugBackingPricePer =
      backingCharge && _.round(backingCharge.unitPrice, 2).toFixed(2);
    customRug.rugBackingCostPer =
      backingCharge && _.round(backingCharge.unitCost, 2).toFixed(2);
    customRug.sergingPricePer =
      defaultSergeCharge && _.round(defaultSergeCharge.unitPrice, 2).toFixed(2);
    customRug.sergingCostPer =
      defaultSergeCharge && _.round(defaultSergeCharge.unitCost, 2).toFixed(2);
    customRug.mSergingPricePer =
      machineAppliedCharge &&
      _.round(machineAppliedCharge.unitCost, 2).toFixed(2);
    customRug.mSergingCostPer =
      machineAppliedCharge &&
      _.round(machineAppliedCharge.unitCost, 2).toFixed(2);
    customRug.hsSergingPricePer =
      hiddenStitchBindingCharge &&
      _.round(hiddenStitchBindingCharge.unitCost, 2).toFixed(2);
    customRug.hsSergingCostPer =
      hiddenStitchBindingCharge &&
      _.round(hiddenStitchBindingCharge.unitCost, 2).toFixed(2);
    customRug.seamChargePricePer =
      seamCharge && _.round(seamCharge.unitPrice, 2).toFixed(2);
    customRug.seamChargeCostPer =
      seamCharge && _.round(seamCharge.unitCost, 2).toFixed(2);

    if (
      customRug &&
      customRug.fieldSelection &&
      customRug.fieldSelection.value
    ) {
      const {
        widthNumericFeet,
        shapeSelection,
        unitPrice,
        priceUnitTypeName,
        serging,
        bindingSelection,
        includeRugPad,
        padSelection,
        fieldPrice,
        fieldSelection,
        repeatLength,
        repeatWidth,
        productWidths,
      } = customRug;
      if (widthNumericFeet && shapeSelection) {
        let { feetLength, inchesLength, feetWidth, inchesWidth } =
          this.getSizes(customRug);
        let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
          feetLength,
          inchesLength,
          feetWidth,
          inchesWidth
        );
        // field will add additional for pattern Match
        // but pad will stay original dimension
        let originalLengthFeet = feetLength;
        let originalLengthInches = inchesLength;
        let {
          totalRugBackingPrice,
          totalRugBackingCost,
          rugBackingCmsn,
          totalSergingPrice,
          totalSergingCost,
          sergingCmsn,
          totalMSergingPrice,
          totalMSergingCost,
          mSergingCmsn,
          totalHSSergingPrice,
          totalHSSergingCost,
          hsSergingCmsn,
        } = calc.calculateRugExtraCosts(customRug, sqFt, finishedEdgeFt);
        /////////////////////////////////////
        let results = {
          padSelection: null,
          rugOverview: null,
          priceList: null,
          estimatedPrice: null,
          fieldQty: null,
          fieldPayload: null,
          cutChargePayload: null,
          bindingPayload: null,
          appliedBindingPayload: null,
          sergingPayload: null,
          padPayload: null,
          padCutFeeOver31SFPayload: null,
          inboundFreightPayload: null,
          armorPayload: null,
          backingPayload: null,
          seamPayload: null,
          laborRoundProductPayload: null,
        };
        if (widthNumericFeet && feetWidth > 0 && feetLength > 0) {
          const widthFeetAndInches = Number(
            parseFloat(feetWidth) + parseFloat(inchesWidth / 12.0)
          );
          const lengthFeetAndInches = Number(
            parseFloat(feetLength) + parseFloat(inchesLength / 12.0)
          );
          let payload = {
            CustomWidth: widthFeetAndInches,
            CustomLength: lengthFeetAndInches,
            ProductId: Number(fieldSelection.value),
          };
          let defaultFieldCalc = await calc.getDefaultFieldSize(
            payload,
            fieldSelection,
            repeatLength,
            repeatWidth,
            null
          );
          const {
            appliedFieldRepeat,
            fieldLengthFeet,
            fieldLengthInches,
            widthFeetName,
          } = defaultFieldCalc;
          feetLength = fieldLengthFeet;
          inchesLength = fieldLengthInches;
          customRug.repeatNumeric = appliedFieldRepeat || 0;
          var width = _.find(customRug.productWidths, (w) => {
            return w.widthName === widthFeetName;
          });
          if (width) {
            results.selectedWidth = width;
            results.widthNumericFeet = width.widthNumericFeet;
            results.productWidth = width;
          }
          totalFieldUnits = calc.calculateFieldUnits(
            widthNumericFeet,
            feetLength,
            inchesLength,
            priceUnitTypeName
          );
          results.fieldQty = totalFieldUnits;

          if (totalFieldUnits) {
            const fieldPrice = {
              price: totalFieldUnits * unitPrice * 100,
              label: fieldSelection.label,
            };
            priceList.push(fieldPrice);
            customRug.fieldSelection.repeatNumeric = appliedFieldRepeat;
            // SHAPING field first
            // null below is the comment field
            fieldPayload = this.shapeRugComponentOrderLine(
              customRug.fieldSelection,
              "Field",
              customRug.fieldSelectionColor,
              null,
              constants.ORDER_LINE_TYPE.RUG_FIELD.value,
              fieldPrice.price,
              totalFieldUnits,
              width,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.fieldPayload = fieldPayload;
          }
          if (
            cutCharge &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            const cutChargeItem = {
              price: cutCharge.unitPrice, // $25.00
              label: cutCharge.label,
            };
            priceList.push(cutChargeItem);
            // SHAPING field first
            // null below is the comment field
            cutChargePayload = this.shapeRugComponentOrderLine(
              cutCharge,
              "Misc",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              cutChargeItem.price,
              1,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.cutChargePayload = cutChargePayload;
          }
          if (serging && defaultSergeCharge) {
            const sergingCharge = {
              price: Number(totalSergingPrice),
              label: "Serged",
            };
            priceList.push(sergingCharge);
            sergingPayload = this.shapeRugComponentOrderLine(
              defaultSergeCharge,
              "Serging",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              Number(sergingCharge.price).toFixed(2),
              finishedEdgeFt,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.sergingPayload = sergingPayload;
          } else if (
            bindingSelection &&
            bindingSelection.value &&
            customRug.bindingSelectionColor &&
            customRug.bindingSelectionColor.value
          ) {
            let BindingCottonSmallProduct = _.find(rugComponentsList, (ch) => {
              return ch.configName === configNames.BindingCottonSmallProduct;
            });
            let BindingSolidSmallProduct = _.find(rugComponentsList, (ch) => {
              return ch.configName === configNames.BindingSolidSmallProduct;
            });
            let bindingLengthWithAllowance;
            if (
              customRug.bindingSelection.value ==
                BindingCottonSmallProduct.value ||
              customRug.bindingSelection.value == BindingSolidSmallProduct.value
            ) {
              bindingLengthWithAllowance = finishedEdgeFt + 2;
            } else {
              bindingLengthWithAllowance = finishedEdgeFt + 4;
            }
            const bindingCost = {
              price:
                Number(bindingSelection.unitPrice * 100).toFixed(2) *
                bindingLengthWithAllowance,
              label: bindingSelection.label,
            };
            // adding this later instead
            // priceList.push(bindingCost);

            // SHAPING binding component
            // null below is the comment field
            bindingPayload = this.shapeRugComponentOrderLine(
              customRug.bindingSelection,
              "Binding",
              customRug.bindingSelectionColor,
              null,
              constants.ORDER_LINE_TYPE.BINDING.value,
              bindingCost.price,
              bindingLengthWithAllowance,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.bindingPayload = bindingPayload;
            if (hiddenStitchBindingCharge || machineAppliedCharge) {
              let bindingApplicationCharge;
              if (
                customRug.bindingSelection.value ==
                  BindingCottonSmallProduct.value ||
                customRug.bindingSelection.value ==
                  BindingSolidSmallProduct.value
              ) {
                bindingApplicationCharge = {
                  price:
                    Number(machineAppliedCharge.unitPrice).toFixed(2) *
                    finishedEdgeFt,
                  label: machineAppliedCharge.label,
                };
                appliedBindingPayload = this.shapeRugComponentOrderLine(
                  machineAppliedCharge,
                  "Misc",
                  null,
                  null,
                  constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
                  bindingApplicationCharge.price,
                  finishedEdgeFt,
                  null,
                  feetLength,
                  inchesLength,
                  feetWidth,
                  inchesWidth,
                  repeatLength,
                  repeatWidth
                );
              } else {
                bindingApplicationCharge = {
                  price:
                    Number(hiddenStitchBindingCharge.unitPrice).toFixed(2) *
                    finishedEdgeFt,
                  label: hiddenStitchBindingCharge.label,
                };
                appliedBindingPayload = this.shapeRugComponentOrderLine(
                  hiddenStitchBindingCharge,
                  "Misc",
                  null,
                  null,
                  constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
                  bindingApplicationCharge.price,
                  finishedEdgeFt,
                  null,
                  feetLength,
                  inchesLength,
                  feetWidth,
                  inchesWidth,
                  repeatLength,
                  repeatWidth
                );
              }
              priceList.push(bindingApplicationCharge);
              results.appliedBindingPayload = appliedBindingPayload;
            }
          }
          if (
            backingCharge &&
            customRug.forOutdoorUse == false &&
            customRug.backingTypeId != constants.BACKING_TYPE.COTTON &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            const backingCost = {
              price: Number(backingCharge.unitPrice).toFixed(2) * sqFt,
              label: backingCharge.label,
            };
            priceList.push(backingCost);
            backingPayload = this.shapeRugComponentOrderLine(
              backingCharge,
              "Backing",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              backingCost.price,
              sqFt,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.backingPayload = backingPayload;
          }
          if (
            seamCharge &&
            widthFeetAndInches > 12 &&
            lengthFeetAndInches > 12 &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            let seamQty;
            if (lengthFeetAndInches > widthFeetAndInches) {
              seamQty = lengthFeetAndInches;
            } else {
              seamQty = widthFeetAndInches;
            }
            const seamCost = {
              price: Number(seamCharge.unitPrice).toFixed(2) * seamQty,
              label: seamCharge.label,
            };
            priceList.push(seamCost);
            seamPayload = this.shapeRugComponentOrderLine(
              seamCharge,
              "Seam",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              seamCost.price,
              seamQty,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.seamPayload = seamPayload;
          }
          if (
            includeRugPad &&
            defaultPad &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            let widths = await api.fetch(
              `Product/GetProductWidths/${defaultPad.value}`
            );
            let defaultPaddingWidth = _.chain(widths.data)
              .sortBy((w) => {
                return w.widthNumericFeet;
              })
              .find((w, index) => {
                if (index + 1 < widths.data.length) {
                  return w.widthNumericFeet >= widthFeetAndInches;
                }
                return true;
              })
              .value();
            let paddingSqFt =
              (sqFt / widthFeetAndInches) *
              defaultPaddingWidth.widthNumericFeet;
            const padCost = {
              price:
                Number(defaultPad.unitPrice * 100).toFixed(2) * paddingSqFt,
              label: defaultPad.label,
            };
            // priceList.push(padCost);
            // adding this later instead
            padPayload = this.shapeRugComponentOrderLine(
              defaultPad,
              "Rug Pad",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_PAD.value,
              padCost.price,
              paddingSqFt,
              defaultPaddingWidth,
              originalLengthFeet,
              originalLengthInches,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.padSelection = defaultPad;
            results.padPayload = padPayload;
            if (sqFt > 31 && over31SFPadCharge) {
              const rugPadCutCost = {
                price: over31SFPadCharge.unitPrice, // need to change this to be dynamic, not hard coded
                label: `Rug Pad Cut Fee Over 31 Sq Ft`,
              };
              priceList.push(rugPadCutCost);
              // SHAPING padding component
              // null below is the comment field
              padCutFeeOver31SFPayload = this.shapeRugComponentOrderLine(
                over31SFPadCharge,
                "Misc",
                null,
                null,
                constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
                rugPadCutCost.price,
                1,
                null,
                originalLengthFeet,
                originalLengthInches,
                feetWidth,
                inchesWidth,
                repeatLength,
                repeatWidth
              );
              results.padCutFeeOver31SFPayload = padCutFeeOver31SFPayload;
            } else {
              if (under30SFPadCharge) {
                const rugPadCutCost = {
                  price: under30SFPadCharge.unitPrice, // need to change this to be dynamic, not hard coded
                  label: `Rug Pad Cut Fee under 31 Sq Ft`,
                };
                priceList.push(rugPadCutCost);
                // SHAPING padding component
                // null below is the comment field
                padCutFeeOver31SFPayload = this.shapeRugComponentOrderLine(
                  under30SFPadCharge,
                  "Misc",
                  null,
                  null,
                  constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
                  rugPadCutCost.price,
                  1,
                  null,
                  originalLengthFeet,
                  originalLengthInches,
                  feetWidth,
                  inchesWidth,
                  repeatLength,
                  repeatWidth
                );
                results.padCutFeeOver31SFPayload = padCutFeeOver31SFPayload;
              }
            }
          }
          // default charges for every custom ordered rug quote
          // armor and inbound freight
          let inboundFreightCharge = _.find(rugComponentsList, (ch) => {
            return ch.configName === configNames.DefaultInboundFreightProduct;
          });
          if (
            inboundFreightCharge &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            inboundFreightCharge.price = inboundFreightCharge.unitPrice;
            priceList.push(inboundFreightCharge);
            inboundFreightPayload = this.shapeRugComponentOrderLine(
              inboundFreightCharge,
              "Misc",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              inboundFreightCharge.unitPrice,
              1,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.inboundFreightPayload = inboundFreightPayload;
          }
          let armorCharge = _.find(rugComponentsList, (ch) => {
            return ch.configName === configNames.DefaultRugEndArmorProduct;
          });
          if (
            armorCharge &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            armorCharge.price = armorCharge.unitPrice;
            priceList.push(armorCharge);
            armorPayload = this.shapeRugComponentOrderLine(
              armorCharge,
              "Misc",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              armorCharge.unitPrice,
              1,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth
            );
            results.armorPayload = armorPayload;
          }
          let laborRoundProductCharge = _.find(rugComponentsList, (ch) => {
            return ch.configName === configNames.DefaultLaborRoundProduct;
          });
          if (
            laborRoundProductCharge &&
            customRug.shapeSelection.value ===
              constants.CUSTOM_RUG_SHAPE_LIST.ROUND.value &&
            customRug.fieldSelectionSupplierId !=
              constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            laborRoundProductCharge.price = laborRoundProductCharge.unitPrice;
            priceList.push(laborRoundProductCharge);
            laborRoundProductPayload = this.shapeRugComponentOrderLine(
              laborRoundProductCharge,
              "Misc",
              null,
              null,
              constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
              laborRoundProductCharge.unitPrice,
              1,
              null,
              feetLength,
              inchesLength,
              feetWidth,
              inchesWidth,
              repeatLength,
              repeatWidth
            );
            results.laborRoundProductPayload = laborRoundProductPayload;
          }

          let total = _.sumBy(priceList, (p) => {
            return p.price;
          });
          let rugOverview = [];
          if (
            fieldPayload &&
            fieldPayload.OrderLine &&
            fieldSelection &&
            fieldSelection.label
          ) {
            const mainRug = {
              price: total,
              label: `${fieldSelection.label} Custom Rug`,
            };
            rugOverview.push(mainRug);
          }
          if (
            bindingPayload &&
            bindingPayload.OrderLine &&
            bindingPayload.OrderLine.Price &&
            bindingSelection &&
            bindingSelection.label
          ) {
            const item = {
              price: bindingPayload.OrderLine.Price,
              label: bindingSelection.label,
            };
            rugOverview.push(item);
            total = total + item.price;
          }
          if (
            padPayload &&
            padPayload.OrderLine &&
            padPayload.OrderLine.Price
          ) {
            const item = {
              price: padPayload.OrderLine.Price,
              label: "Rug Pad Lux Felt",
            };
            rugOverview.push(item);
            total = total + item.price;
          }
          results.priceList = priceList;
          results.rugOverview = rugOverview;
          results.estimatedPrice = total;
          return results;
        }
        return results;
      }
    }
  }

  submitCustomRugQuote() {
    let validForm = this.customRugFormComplete();
    if (!validForm) return;
    const { customRug } = this.state;
    this.setState(
      {
        quoteOrderSubmitting: true,
      },
      () => {
        let componentLines = [];
        componentLines.push(customRug.fieldPayload);

        if (customRug.cutChargePayload) {
          componentLines.push(customRug.cutChargePayload);
        }

        if (customRug.appliedBindingPayload) {
          componentLines.push(customRug.appliedBindingPayload);
        }

        if (customRug.sergingPayload) {
          componentLines.push(customRug.sergingPayload);
        }
        if (customRug.inboundFreightPayload) {
          componentLines.push(customRug.inboundFreightPayload);
        }
        if (customRug.armorPayload) {
          componentLines.push(customRug.armorPayload);
        }
        if (customRug.backingPayload) {
          componentLines.push(customRug.backingPayload);
        }
        if (customRug.seamPayload) {
          componentLines.push(customRug.seamPayload);
        }
        if (customRug.padCutFeeOver31SFPayload) {
          componentLines.push(customRug.padCutFeeOver31SFPayload);
        }
        if (customRug.laborRoundProductPayload) {
          componentLines.push(customRug.laborRoundProductPayload);
        }
        let lengthFeet = 0;
        let lengthInches = 0;
        let widthFeet = 0;
        let widthInches = 0;
        let finishedSize = "";
        let shapeId = customRug.shapeSelection.value;
        if (
          customRug.shapeSelection.label === ROUND ||
          customRug.shapeSelection.label === SQUARE
        ) {
          lengthFeet = customRug.sizeRadius.feet;
          lengthInches = customRug.sizeRadius.inches;
          widthFeet = customRug.sizeRadius.feet;
          widthInches = customRug.sizeRadius.inches;
        }
        if (customRug.shapeSelection.label === RECTANGULAR) {
          // if (
          //   customRug.finishedSize &&
          //   customRug.finishedSize.label === "Custom"
          // ) {
          lengthFeet = customRug.sizeLength.feet;
          lengthInches = customRug.sizeLength.inches;
          widthFeet = customRug.sizeWidth.feet;
          widthInches = customRug.sizeWidth.inches;
          finishedSize = "Custom";
          // } else {
          //   lengthFeet = Number(customRug.finishedSize.rugLengthFeet);
          //   lengthInches = Number(customRug.finishedSize.rugLengthInches);
          //   widthFeet = Number(customRug.finishedSize.rugWidthFeet);
          //   widthInches = Number(customRug.finishedSize.rugWidthInches);
          //   finishedSize = customRug.finishedSize.label;
          // }
        }
        // SHAPING custom rug parent component
        let rugOrderLine = this.shapeRugComponentOrderLine(
          customRug.fieldSelection,
          "Custom Rug",
          customRug.fieldSelectionColor,
          customRug.customRugComment,
          constants.ORDER_LINE_TYPE.CUSTOM_RUG.value,
          0,
          1,
          null, // selected width (not applicable)
          lengthFeet,
          lengthInches,
          widthFeet,
          widthInches,
          customRug.sergingPayload,
          customRug.appliedBindingPayload
        );
        rugOrderLine.OrderLine.PriceUnitTypeId =
          constants.PRICE_UNIT_TYPE.EACH.value;
        rugOrderLine.OrderLine.RugLengthFeet = Number(lengthFeet);
        rugOrderLine.OrderLine.RugLengthInches = Number(lengthInches);
        rugOrderLine.OrderLine.RugWidthFeet = Number(widthFeet);
        rugOrderLine.OrderLine.RugWidthInches = Number(widthInches);
        rugOrderLine.OrderLine.FinishedSize = finishedSize;
        rugOrderLine.OrderLine.SendScrapsDesired = customRug.sendScrapsDesired
          ? customRug.sendScrapsDesired
          : false;
        rugOrderLine.OrderLine.RugShapeId = Number(
          customRug.shapeSelection.value
        );
        rugOrderLine.OrderLine.ForOutdoorUse = customRug.forOutdoorUse;

        let rugComponents = {
          RugOrderLine: rugOrderLine,
          ComponentLines: componentLines,
          PadOrderLine: customRug.padPayload,
          BindingOrderLine: customRug.bindingPayload,
        };
        let order = shapeCommonOrderPayload(
          null, // no need to pass rug component lines here
          this.state.customer,
          this.state.productType
        );
        order.Dsc = rugOrderLine.OrderLine.Dsc;
        order.WebRugQuoteEstimatedPrice = _.toInteger(customRug.estimatedPrice);
        order.FreightQuoteDesired = customRug.freightQuoteDesired
          ? customRug.freightQuoteDesired
          : false;
        let payload = {
          Order: order,
          RugComponents: rugComponents,
        };
        api
          .post("Web/CustomerSaveRugQuote", payload)
          .then((response) => {
            window.scroll(0, 0);
            if (response && response.data && response.data.success) {
              console.log("customer submitted", this.state.customer);
              this.setState(
                {
                  customRug: Object.assign({}, emptyCustomRug),
                  activeCustomRugStep: CUSTOM_STEPS.field,
                },
                () => {
                  Alert.success(
                    "Your custom rug quote request has been submitted!"
                  );
                  this.resetCustomRug();
                }
              );
            } else {
              Alert.warning(
                "There was a problem saving your rug quote. If this persists, please contact Distinctive."
              );
            }
          })
          .catch(helpers.catchHandler)
          .finally(() => this.setState({ quoteOrderSubmitting: false }));
      }
    );
  }

  setActiveCustomRugStep(step) {
    this.setState({ activeCustomRugStep: step });
  }

  customRugFormComplete() {
    let warningsList = [];
    warningsList = _.concat(warningsList, this.customRugQuoteIsValid());
    warningsList = _.concat(
      warningsList,
      validateCustomerInfo(this.state.customer, this.state.productType)
    );
    const readyToSubmit = warningsList.length < 1;
    if (!_.isEqual(this.state.customRugWarnings, warningsList)) {
      this.setState({ customRugWarnings: warningsList }, () => {
        return readyToSubmit;
      });
    } else {
      return readyToSubmit;
    }
    return readyToSubmit;
  }

  toggleSidebar() {
    this.setState((prevState) => {
      return {
        sidebarOpen: !prevState.sidebarOpen,
      };
    });
  }

  downloadDetailedSpecs(pdf_url, productName) {
    const getPDF = () => {
      return axios.get(pdf_url, {
        responseType: "arraybuffer",
      });
    };
    Promise.all([getPDF()])
      .then((response) => {
        if (response && response.length) {
          let imageData = Buffer.from(response[0].data, "binary").toString(
            "base64"
          );
          this.downloadExport(
            imageData,
            `detailed_specs_${productName}.pdf`,
            "application/pdf"
          );
        }
      })
      .catch(helpers.catchHandler);
  }

  downloadExport = (data, fileName, mime) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const file = new Blob([data], { type: mime });
      var anchor = window.document.createElement("a");
      anchor.download = fileName;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(file);
      anchor.dataset.downloadurl = [mime, anchor.download, anchor.href].join(
        ":"
      );
      anchor.click();
    }
  };

  goBackToList() {
    this.setState({
      singleProductDisplay: null,
      showProduct: null,
      queryStringDCNumber: null,
      queryStringProductColor: null,
    });
  }

  async onChangeCustomRugHandler(selection, componentType) {
    // if(selection.label === "Enter Custom Size") {
    //   selection = _.find(FINISHED_RUG_SIZES, (frs) => {
    //     return frs.value === selection.value;
    //   })
    // }
    let customRug = Object.assign({}, this.state.customRug);
    customRug[componentType] = selection;
    let newState = Object.assign({}, this.state);
    // binding or serging so setting serging to false
    if (componentType === params.bindingSelection) {
      customRug.serging = false;
    }
    let calculations = await this.calculateCustomRugEstimate(customRug);
    customRug = Object.assign(customRug, calculations);
    newState.customRug = customRug;
    this.setState(newState, () => {
      setItemToStorage(params.customRug, this.state.customRug);
      if (
        componentType === params.fieldSelection ||
        componentType === params.bindingSelection
      ) {
        this.getColorSelectionsForCustomRugField(
          selection.value,
          componentType
        );
      }
    });
  }

  getDetailedProductInfo = (productId, componentType) => {
    return api
      .fetch(`Product/GetProductWithOrder/${productId}/-1`)
      .then((response) => {
        if (response && response.data) {
          const {
            productColors,
            productWidths,
            unitPrice,
            priceUnitTypeName,
            patternMatch,
            repeat,
            repeatLength,
            repeatWidth,
            supplierId,
            supplierLabel,
            isRugQuotable,
            backingTypeId,
            backingTypeName,
            constructionTypeId,
            constructionTypeName,
          } = response.data;
          let colorOptions = [];
          let customRugSelectionWidth = null;
          if (productColors && productColors.length > 0) {
            colorOptions = _.chain(productColors)
              .filter((col) => {
                return (
                  !_.includes(col.name, "Dropped") &&
                  !_.includes(col.name, "DROPPED") &&
                  !_.includes(col.name, "No Color")
                );
              })
              .filter((col) => {
                return (
                  col.productColorImages && col.productColorImages.length > 0
                );
              })
              .map((col) => {
                let option = Object.assign({}, col);
                option.value = col.id;
                option.label = col.name;
                return option;
              })
              .orderBy((col) => col.label)
              // .orderBy((col) => [col.seq, col.updatedAt], ["asc", "desc"])
              .value();
          }
          let results = {};
          results[`${componentType}ColorList`] = colorOptions;
          results[`${componentType}SupplierId`] = supplierId;
          results[`${componentType}SupplierLabel`] = supplierLabel;
          results.isRugQuotable = isRugQuotable;
          if (componentType === params.fieldSelection) {
            if (productWidths && productWidths.length > 0) {
              customRugSelectionWidth = productWidths[0];
            } else {
              console.warn("NO Widths listed for this item", response.data);
            }
            results.repeat = repeat;
            results.repeatLength = repeatLength;
            results.repeatWidth = repeatWidth;
            results.patternMatch = patternMatch;
            results.productWidth = customRugSelectionWidth;
            results.widthNumericFeet = customRugSelectionWidth.widthNumericFeet;
            results.repeatNumeric = calc.getMinRepeat(
              this.state.customRug.fieldSelection,
              repeatLength,
              repeatWidth
            );
            results.productAttachments = response.data.productAttachments;
            results.selectedWidth =
              productWidths && productWidths.length > 0
                ? productWidths[0]
                : null;
            results.productWidths = productWidths;
            results.unitPrice = unitPrice;
            results.priceUnitTypeName = priceUnitTypeName;
            results.backingTypeId = backingTypeId;
            results.backingTypeName = backingTypeName;
            results.constructionTypeId = constructionTypeId;
            results.constructionTypeName = constructionTypeName;
          }
          return results;
        }
      })
      .catch(helpers.catchHandler);
  };

  async getColorSelectionsForCustomRugField(productId, componentType) {
    const newStatus = Object.assign({}, this.state);
    const modifyingRug = Object.assign({}, newStatus.customRug);
    modifyingRug[`${componentType}Color`] = null;
    modifyingRug[`${componentType}ColorList`] = [];
    this.setState(
      { fetchingFieldColors: true, customRug: modifyingRug },
      async () => {
        let aggResults = await Promise.all([
          this.getDetailedProductInfo(productId, componentType),
        ]);
        try {
          let customRug = Object.assign({}, modifyingRug);
          aggResults.forEach((r) => Object.assign(customRug, r));
          if (
            customRug.fieldSelectionSupplierId ==
            constants.COMMON_SUPPLIER_IDS.NOURISON
          ) {
            // if new field is Noursion product, require default serging
            customRug.serging = true;
            // clearing out binding selection if serging is selected as true again
            customRug.bindingSelection = null;
            customRug.bindingSelectionColor = null;
            customRug.bindingSelectionColorList = [];
          }
          let calculations = await this.calculateCustomRugEstimate(customRug);
          customRug = Object.assign(customRug, calculations);
          newStatus.customRug = customRug;
          this.setState(newStatus, () => {
            setItemToStorage(params.customRug, this.state.customRug);
            this.setState({ fetchingFieldColors: false });
          });
        } catch {
          console.log("error on custom rug color selection", aggResults);
        }
      }
    );
  }

  async onChangeCustomRugInput(type, event) {
    let customRug = Object.assign({}, this.state.customRug);
    const eventAction = event.target;
    let newValue = eventAction.value;
    if (type) {
      if (newValue === "") {
        newValue = 0;
      }
      // let validMeasurement = newValue !== "" && newValue >= 0;
      // if (validMeasurement) {
      if (type === "inches" && newValue > 12) {
        Alert.warning("Value entered here must be 12 or less");
        customRug[eventAction.name][type] = 12;
      } else {
        customRug[eventAction.name][type] = newValue;
      }
      let calculations = await this.calculateCustomRugEstimate(customRug);
      customRug = Object.assign(customRug, calculations);
      this.setState({ customRug: customRug }, () => {
        setItemToStorage(params.customRug, this.state.customRug);
      });
      // } else {
      //   Alert.warning("Value entered here must be 0 or greater");
      //   return;
      // }
    } else {
      // i.e. customRug.customRugComment
      customRug[eventAction.name] = newValue;
      this.setState({ customRug: customRug }, () => {
        setItemToStorage(params.customRug, this.state.customRug);
      });
    }
  }

  ColumnStep = ({ colName, activeContent, stepNo }) => {
    const isActiveColumn = colName === this.state.activeCustomRugStep;
    const columnTitle = (
      <h4
        className={
          this.state.customRug.isRugQuotable
            ? "minWidth200"
            : "minWidth200 squarespaceText"
        }
      >
        {colName}
      </h4>
    );
    if (isActiveColumn) {
      return (
        <Col lg="8">
          <Row>
            <Col className="squarespaceText">
              <h4>
                <span className="fontSizeTurnItUpTo11">STEP {stepNo}: </span>
                {colName}
              </h4>
            </Col>
          </Row>
          {activeContent}
        </Col>
      );
    }
    return (
      <Col
        xs="1"
        onClick={() => {
          if (this.state.customRug.isRugQuotable) {
            this.setActiveCustomRugStep(colName);
          }
        }}
        className="rugStepper d-none d-lg-block" // do not display on small screens
      >
        <div className={`turn90 rugStep`}>{columnTitle}</div>
      </Col>
    );
  };

  CustomRugDisplay = () => {
    const {
      fieldProductsList,
      customRug,
      bindingOptionsList,
      padOptionsList,
      showCustomRugSteps,
    } = this.state;
    const shapesList = _.chain(this.state.rugShapeList)
      .filter((x) => x.label !== "N/A" && x.label !== "Odd Shapes")
      .map((x) => {
        if (x.label === "Round & Oval") {
          return {
            value: x.value,
            label: "Round",
          };
        } else {
          return x;
        }
      })
      .value();
    const cardImageShape = (
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          {/* <Row className="p-0 m-0 imageBindingRow">
            <Col className="p-0 m-0 pl-5 greenBinding">BINDING section</Col> */}
          {/* {customRug.bindingSelection ? (
              <Col className="p-0 m-0">IMAGGGGGGGGGGGGGGGGGE</Col>
            ) : (
              <Col className="p-0 m-0 pl-5 greenBinding">BINDING section</Col>
            )} */}
          {/* </Row>
          <Row className="p-0 m-0 image2ndSection">
            <Col className="p-0 m-0 greenBinding imageBindingCol" /> */}
          {/* {customRug.bindingSelection ? (
              <Col className="p-0 m-0 imageBindingCol">IMAGGGGGGGGGGGGGGGGGE</Col>
            ) : (
              <Col className="p-0 m-0 greenBinding imageBindingCol" />
            )} */}
          {/* <Col className="p-0 m-0 grayBorder borderCol" /> */}
          {/* {customRug.borderSelection ? (
              <Col className="p-0 m-0 borderCol">IMAGGGGGGGGGGGGGGGGGE</Col>
            ) : (
              <Col className="p-0 m-0 grayBorder borderCol" />
            )} */}
          {/* <Col className="p-0 m-0 fieldCol">
              <Row className="p-0 m-0 borderRow">
                <Col className="p-0 m-0 grayBorder">Optional Border</Col> */}
          {/* {customRug.bindingSelection ? (
                  <Col className="p-0 m-0">IMAGGGGGGGGGGGGGGGGGE</Col>
                ) : (
                  <Col className="p-0 m-0 grayBorder">Optional Border</Col>
                )} */}
          {/* </Row> */}
          {customRug.fieldSelectionColor ? (
            <CardImg
              src={`${
                findMainColorImage(
                  customRug.fieldSelectionColor.productColorImages
                ).url
              }`}
              alt="Selected Field"
              className="fieldImage"
            />
          ) : (
            <div className="pl-5 pt-5 squarespaceText">
              Select a Field to Display Image...
            </div>
          )}
          {/* </Col>
          </Row> */}
        </Col>
      </Row>
    );
    return (
      <Container className="squarespaceContainer">
        <Row className="mb-2">
          <Col>
            <h2 className="squarespaceText">Custom Rug Preview</h2>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg="5">
            <Card className="minHeight250 maxHeight300">{cardImageShape}</Card>
          </Col>
          <Col lg="7">
            <Card className="minHeight250 maxHeight300">
              <CardHeader>
                <h5>
                  Custom Rug Details
                  {customRug &&
                  customRug.estimatedPrice &&
                  this.validateWidthSelection(
                    customRug.shapeSelection.label
                  ) ? (
                    <span className="ml-2 squarespaceText">{`${formatPrice(
                      customRug.estimatedPrice
                    )}`}</span>
                  ) : null}
                  <Button
                    className="productButton float-right btn-sm text-white"
                    onClick={() => this.resetCustomRug()}
                  >
                    Reset Quote
                  </Button>
                </h5>
              </CardHeader>
              <CardBody className="p-1 fontSize85 pb-0">
                <Table style={{ borderTop: params.none }}>
                  <tbody style={{ borderTop: params.none }}>
                    <tr style={{ borderTop: params.none }}>
                      <td width="20%" style={{ borderTop: params.none }}>
                        <strong>Field</strong>
                      </td>
                      <td style={{ borderTop: params.none }} colSpan="3">
                        {customRug &&
                          customRug.fieldSelection &&
                          customRug.fieldSelection.label}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Color</strong>
                      </td>
                      <td width="30%">
                        {customRug &&
                          customRug.fieldSelectionColor &&
                          customRug.fieldSelectionColor.label}
                      </td>
                      <td width="20%">
                        <strong>Shape</strong>
                      </td>
                      {customRug &&
                        customRug.shapeSelection &&
                        customRug.shapeSelection.label && (
                          <Fragment>
                            <td width="30%">
                              {customRug.shapeSelection.label}
                            </td>
                          </Fragment>
                        )}
                    </tr>
                    {customRug &&
                      customRug.shapeSelection &&
                      customRug.shapeSelection.label &&
                      customRug.shapeSelection.label === ROUND && (
                        <tr>
                          <td width="20%">
                            <strong>Diameter</strong>
                          </td>
                          <td colSpan="3">
                            {customRug &&
                              customRug.sizeRadius &&
                              customRug.sizeRadius.feet &&
                              `${customRug.sizeRadius.feet} Ft, ${
                                customRug.sizeRadius.inches || 0
                              } Inches Wide`}
                          </td>
                        </tr>
                      )}
                    {customRug &&
                      customRug.shapeSelection &&
                      customRug.shapeSelection.label &&
                      customRug.shapeSelection.label === SQUARE && (
                        <tr>
                          <td width="20%">
                            <strong>Size</strong>
                          </td>
                          <td colSpan="3">
                            {customRug &&
                              customRug.sizeRadius &&
                              customRug.sizeRadius.feet &&
                              `${customRug.sizeRadius.feet} Ft, ${
                                customRug.sizeRadius.inches || 0
                              } Inches Wide X ${
                                customRug.sizeRadius.feet
                              } Ft, ${
                                customRug.sizeRadius.inches || 0
                              } Inches Long`}
                          </td>
                        </tr>
                      )}
                    {customRug &&
                      customRug.shapeSelection &&
                      customRug.shapeSelection.label &&
                      customRug.shapeSelection.label === RECTANGULAR && (
                        <tr>
                          <td width="20%">
                            <strong>Size</strong>
                          </td>
                          <td colSpan="3">
                            <Fragment>
                              {/* {customRug.finishedSize &&
                              customRug.finishedSize.value &&
                              customRug.finishedSize.value > 1 ? (
                                `${customRug.finishedSize.rugWidthFeet} Ft Wide X ${customRug.finishedSize.rugLengthFeet} Ft Long`
                              ) : ( */}
                              <Fragment>
                                {customRug.sizeWidth &&
                                customRug.sizeWidth.feet &&
                                customRug.sizeLength &&
                                customRug.sizeLength.feet
                                  ? `${customRug.sizeWidth.feet} Ft, ${
                                      customRug.sizeWidth.inches || 0
                                    } Inches Wide X ${
                                      customRug.sizeLength.feet
                                    } Ft, ${
                                      customRug.sizeLength.inches || 0
                                    } Inches Long`
                                  : "Please enter the feet and inches for both width and length."}
                              </Fragment>
                              {/* )} */}
                            </Fragment>
                          </td>
                        </tr>
                      )}
                    {/* Border no longer an option in Custom Rug */}
                    {/* {customRug &&
                      customRug.borderSelection &&
                      customRug.borderSelection.label && (
                        <tr>
                          <td width="25%">
                            <strong>Border</strong>
                          </td>
                          <td>
                            {customRug.borderSelection &&
                              customRug.borderSelection.label}
                          </td>
                        </tr>
                      )} */}
                    {customRug &&
                      customRug.bindingSelection &&
                      customRug.bindingSelection.label &&
                      customRug.fieldSelectionSupplierId !=
                        constants.COMMON_SUPPLIER_IDS.NOURISON && (
                        <tr>
                          <td width="20%" className="pb-1">
                            <strong>Binding</strong>
                          </td>
                          <td colSpan="3" className="pb-1">
                            {customRug.bindingSelection &&
                              customRug.bindingSelection.label}
                            {customRug.bindingSelectionColor &&
                              customRug.bindingSelectionColor.label && (
                                <span className="fontSize85">
                                  {` - ${customRug.bindingSelectionColor.label}`}
                                </span>
                              )}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showCustomRugSteps ? (
          <Row className="minHeight400">
            <this.ColumnStep
              colName={CUSTOM_STEPS.field}
              activeContent={
                <Row>
                  <Col>
                    <Form>
                      <Card className="customRugCard">
                        <CardBody>
                          <Select
                            bsSize="sm"
                            closeMenuOnSelect
                            options={fieldProductsList}
                            value={customRug.fieldSelection}
                            onChange={(option) =>
                              this.onChangeCustomRugHandler(
                                option,
                                params.fieldSelection
                              )
                            }
                            placeholder="Choose main carpet field design"
                            className="mb-2"
                          />
                          {this.state.fetchingFieldColors ? (
                            <div className="squarespaceText">
                              Loading color options....
                            </div>
                          ) : customRug.fieldSelectionColorList &&
                            customRug.fieldSelectionColorList.length > 0 &&
                            customRug.isRugQuotable ? (
                            <Select
                              bsSize="sm"
                              closeMenuOnSelect
                              options={customRug.fieldSelectionColorList}
                              value={customRug.fieldSelectionColor}
                              onChange={(option) =>
                                this.onChangeCustomRugHandler(
                                  option,
                                  params.fieldSelectionColor
                                )
                              }
                              placeholder="Choose color"
                              className="mb-2"
                            />
                          ) : (
                            customRug.fieldSelectionColorList &&
                            customRug.fieldSelectionColorList.length > 0 &&
                            !customRug.isRugQuotable && (
                              <Row className="mt-3">
                                <Col>
                                  <h5>
                                    <i className="fontSize85 align-self-end squarespaceText">
                                      Please contact Distinctive directly to get
                                      a quote for this product type.
                                    </i>
                                  </h5>
                                </Col>
                              </Row>
                            )
                          )}
                        </CardBody>
                        {customRug.isRugQuotable ? (
                          <CardFooter>
                            <Row>
                              <Col xs="4" />
                              <Col xs="4" />
                              <Col xs="4">
                                <Button
                                  className="float-right productButton"
                                  onClick={() =>
                                    this.setActiveCustomRugStep(
                                      CUSTOM_STEPS.size_shape
                                    )
                                  }
                                >
                                  Next
                                </Button>
                              </Col>
                            </Row>
                          </CardFooter>
                        ) : null}
                      </Card>
                    </Form>
                  </Col>
                </Row>
              }
              stepNo={1}
            />
            <this.ColumnStep
              colName={CUSTOM_STEPS.size_shape}
              activeContent={
                <Row>
                  <Col>
                    <Form>
                      <Card className="customRugCard">
                        <CardBody>
                          <Row className="p-0 m-0 mb-3">
                            <Col className="align-self-end p-0 m-0" xs="12">
                              <i className="fontSize85 align-self-end squarespaceText">
                                Round, square and rectangular are the only
                                options through the website.
                                <br />
                                If you would like any other shape, please
                                contact Distinctive.
                              </i>
                            </Col>
                          </Row>
                          <Row className="p-0 m-0">
                            <Col xs="12" className="p-0 m-0">
                              <Select
                                bsSize="sm"
                                closeMenuOnSelect
                                options={shapesList}
                                value={customRug.shapeSelection}
                                onChange={(option) =>
                                  this.onChangeCustomRugHandler(
                                    option,
                                    params.shapeSelection
                                  )
                                }
                                placeholder="Choose rug shape"
                                className="mb-2"
                              />
                              <Row className="my-2">
                                <Col>
                                  <h4>Size:</h4>
                                </Col>
                              </Row>
                              {customRug.productWidth &&
                                customRug.productWidth.widthName &&
                                customRug.shapeSelection &&
                                customRug.shapeSelection.label &&
                                ((customRug.shapeSelection.label ==
                                  RECTANGULAR &&
                                  customRug.sizeLength.feet > 0) ||
                                  ((customRug.shapeSelection.label == ROUND ||
                                    customRug.shapeSelection.label == SQUARE) &&
                                    customRug.sizeRadius.feet > 0)) && (
                                  <Fragment>
                                    {this.validateWidthSelection(
                                      customRug.shapeSelection.label
                                    ) ? null : (
                                      <Row className="p-0 m-0 mb-3">
                                        <Col
                                          className="align-self-end p-0 m-0"
                                          xs="12"
                                        >
                                          <div
                                            className="fontSize85 align-self-end"
                                            style={{
                                              fontFamily: "Poppins",
                                              color: "red",
                                            }}
                                          >
                                            To order a rug of this size, please
                                            contact Distinctive directly
                                            <br />
                                            or enter a width less than{" "}
                                            {customRug.productWidth.widthName}.
                                          </div>
                                        </Col>
                                      </Row>
                                    )}
                                  </Fragment>
                                )}
                              {(customRug.sizeWidth.feet > 0 ||
                                customRug.sizeWidth.inches > 0 ||
                                customRug.sizeLength > 0 ||
                                customRug.sizeLength.inches > 0) &&
                              (customRug.sizeWidth.feet < 1 ||
                                customRug.sizeLength.feet < 1) ? (
                                <Row className="p-0 m-0 mb-3">
                                  <Col
                                    className="align-self-end p-0 m-0"
                                    xs="12"
                                  >
                                    <div
                                      className="fontSize85 align-self-end"
                                      style={{
                                        fontFamily: "Poppins",
                                        color: "red",
                                      }}
                                    >
                                      Width feet and length feet must be greater
                                      than 0
                                    </div>
                                  </Col>
                                </Row>
                              ) : null}
                              {customRug.shapeSelection && (
                                <Fragment>
                                  {customRug.shapeSelection.label === ROUND ||
                                  customRug.shapeSelection.label === SQUARE ? (
                                    <Row className="mb-2 px-2">
                                      <Col md="3" xs="12">
                                        <DebounceInput
                                          type="number"
                                          className="form-control"
                                          debounceTimeout={1000}
                                          name="sizeRadius"
                                          value={
                                            customRug.sizeRadius.feet || ""
                                          }
                                          onChange={(event) =>
                                            this.onChangeCustomRugInput(
                                              "feet",
                                              event
                                            )
                                          }
                                          placeholder="Feet"
                                          min="0"
                                        />
                                      </Col>
                                      <Col md="3" xs="12">
                                        <DebounceInput
                                          name="sizeRadius"
                                          type="number"
                                          className="form-control"
                                          debounceTimeout={1000}
                                          max={11}
                                          value={
                                            customRug.sizeRadius.inches || ""
                                          }
                                          onChange={(event) =>
                                            this.onChangeCustomRugInput(
                                              "inches",
                                              event
                                            )
                                          }
                                          placeholder="Inches"
                                          min="0"
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    // RECTANGULAR
                                    <Fragment>
                                      {/* <Select
                                        bsSize="sm"
                                        closeMenuOnSelect
                                        options={DISPLAY_FINISHED_RUG_SIZES}
                                        value={customRug.finishedSize}
                                        onChange={(option) =>
                                          this.onChangeCustomRugHandler(
                                            option,
                                            params.finishedSize
                                          )
                                        }
                                        placeholder="Choose finished size"
                                        className="mb-2"
                                      /> */}
                                      {/* {customRug.finishedSize &&
                                      customRug.finishedSize.label ===
                                        "Custom" ? ( */}
                                      {customRug.shapeSelection.label ===
                                      RECTANGULAR ? (
                                        <Row className="mb-2">
                                          <Col>
                                            Width
                                            <Row className="mb-2 px-2">
                                              {" "}
                                              <Col>
                                                <DebounceInput
                                                  name="sizeWidth"
                                                  type="number"
                                                  className="form-control"
                                                  debounceTimeout={1000}
                                                  value={
                                                    customRug.sizeWidth.feet ||
                                                    ""
                                                  }
                                                  onChange={(event) =>
                                                    this.onChangeCustomRugInput(
                                                      "feet",
                                                      event
                                                    )
                                                  }
                                                  placeholder="Feet"
                                                  min="0"
                                                />
                                              </Col>
                                              <Col>
                                                <DebounceInput
                                                  name="sizeWidth"
                                                  type="number"
                                                  className="form-control"
                                                  debounceTimeout={1000}
                                                  max={11}
                                                  value={
                                                    customRug.sizeWidth
                                                      .inches || ""
                                                  }
                                                  onChange={(event) =>
                                                    this.onChangeCustomRugInput(
                                                      "inches",
                                                      event
                                                    )
                                                  }
                                                  placeholder="Inches"
                                                  min="0"
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col>
                                            Length
                                            <Row className="mb-2 px-2">
                                              <Col>
                                                <DebounceInput
                                                  name="sizeLength"
                                                  type="number"
                                                  className="form-control"
                                                  debounceTimeout={1000}
                                                  value={
                                                    customRug.sizeLength.feet ||
                                                    ""
                                                  }
                                                  onChange={(event) =>
                                                    this.onChangeCustomRugInput(
                                                      "feet",
                                                      event
                                                    )
                                                  }
                                                  placeholder="Feet"
                                                  min="0"
                                                />
                                              </Col>
                                              <Col>
                                                <DebounceInput
                                                  name="sizeLength"
                                                  type="number"
                                                  className="form-control"
                                                  debounceTimeout={1000}
                                                  max={11}
                                                  value={
                                                    customRug.sizeLength
                                                      .inches || ""
                                                  }
                                                  onChange={(event) =>
                                                    this.onChangeCustomRugInput(
                                                      "inches",
                                                      event
                                                    )
                                                  }
                                                  placeholder="Inches"
                                                  min="0"
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      ) : null}
                                    </Fragment>
                                  )}
                                </Fragment>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <Row>
                            <Col xs="4">
                              <Button
                                className="float-left productButton"
                                onClick={() =>
                                  this.setActiveCustomRugStep(
                                    CUSTOM_STEPS.field
                                  )
                                }
                              >
                                Back
                              </Button>
                            </Col>
                            <Col xs="4" />
                            <Col xs="4">
                              <Button
                                className="float-right productButton"
                                onClick={() =>
                                  this.setActiveCustomRugStep(
                                    CUSTOM_STEPS.binding
                                  )
                                }
                                disabled={
                                  customRug &&
                                  customRug.shapeSelection &&
                                  ((customRug.shapeSelection.label ==
                                    RECTANGULAR &&
                                    customRug.sizeWidth.feet > 0 &&
                                    customRug.sizeLength.feet > 0) ||
                                    ((customRug.shapeSelection.label == ROUND ||
                                      customRug.shapeSelection.label ==
                                        SQUARE) &&
                                      customRug.sizeRadius.feet > 0)) &&
                                  this.validateWidthSelection(
                                    customRug.shapeSelection.label
                                  ) == true
                                    ? false
                                    : true
                                }
                              >
                                Next
                              </Button>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </Form>
                  </Col>
                </Row>
              }
              stepNo={2}
            />
            <this.ColumnStep
              colName={CUSTOM_STEPS.binding}
              activeContent={
                <Row>
                  <Col>
                    <Card className="customRugCard">
                      <CardBody>
                        <Form>
                          {customRug.constructionTypeId ==
                          constants.CONSTRUCTION_TYPE_IDS.INDOOR_OUTDOOR ? (
                            <Row>
                              <Col>
                                For Outdoor Use?
                                <div className="mt-2">
                                  <Switch
                                    className="ml-2 p-0 m-0"
                                    onChange={() =>
                                      this.toggleCustomRugOption(
                                        customRug.forOutdoorUse,
                                        "forOutdoorUse"
                                      )
                                    }
                                    checked={customRug.forOutdoorUse}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                          <Row>
                            <Col>
                              Serging (Default)
                              <div className="mt-2">
                                <Switch
                                  className="ml-2 p-0 m-0"
                                  onChange={() =>
                                    this.toggleCustomRugOption(
                                      customRug.serging,
                                      "serging"
                                    )
                                  }
                                  checked={customRug.serging}
                                  disabled={
                                    customRug.fieldSelectionSupplierId ==
                                    constants.COMMON_SUPPLIER_IDS.NOURISON
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          {customRug &&
                          customRug.fieldSelectionSupplierId ==
                            constants.COMMON_SUPPLIER_IDS.NOURISON ? ( // includeRugPad may still be true but is ignored for Nourison
                            <Row className="mt-3">
                              <Col>
                                <h5>
                                  <i className="fontSize85 align-self-end squarespaceText">
                                    To add a binding to this product, please
                                    contact Distinctive directly.
                                  </i>
                                </h5>
                              </Col>
                            </Row>
                          ) : (
                            <React.Fragment>
                              <Row>
                                <Col>OR</Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Select
                                    bsSize="sm"
                                    closeMenuOnSelect
                                    options={bindingOptionsList}
                                    value={customRug.bindingSelection}
                                    onChange={(option) =>
                                      this.onChangeCustomRugHandler(
                                        option,
                                        params.bindingSelection
                                      )
                                    }
                                    placeholder="Choose binding"
                                    className="mb-2"
                                  />
                                </Col>
                              </Row>
                              {customRug.bindingSelection &&
                                customRug.bindingSelection.value &&
                                customRug.bindingSelectionColorList &&
                                customRug.bindingSelectionColorList.length <
                                  1 && (
                                  <div className="squarespaceText mb-3">
                                    Loading binding color options....
                                  </div>
                                )}
                              {customRug.bindingSelectionColorList &&
                              customRug.bindingSelectionColorList.length > 0 ? (
                                <Row className="mb-3">
                                  <Col>
                                    <Select
                                      bsSize="sm"
                                      closeMenuOnSelect
                                      options={
                                        customRug.bindingSelectionColorList
                                      }
                                      value={customRug.bindingSelectionColor}
                                      onChange={(option) =>
                                        this.onChangeCustomRugHandler(
                                          option,
                                          params.bindingSelectionColor
                                        )
                                      }
                                      placeholder="Choose binding color"
                                      className="mb-2"
                                    />
                                  </Col>
                                </Row>
                              ) : null}
                            </React.Fragment>
                          )}
                          {customRug &&
                          customRug.fieldSelectionSupplierId ==
                            constants.COMMON_SUPPLIER_IDS.NOURISON ? ( // includeRugPad may still be true but is ignored for Nourison
                            <Row className="mt-3">
                              <Col>
                                <h5>
                                  <i className="fontSize85 align-self-end squarespaceText">
                                    To order a rug pad for this product, please
                                    contact Distinctive directly.
                                  </i>
                                </h5>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col>
                                Include Rug Pad (Default)
                                <div>
                                  <Switch
                                    className="ml-2 p-0 m-0"
                                    onChange={() =>
                                      this.toggleCustomRugOption(
                                        customRug.includeRugPad,
                                        "includeRugPad"
                                      )
                                    }
                                    checked={customRug.includeRugPad}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Form>
                      </CardBody>
                      <CardFooter>
                        <Row>
                          <Col xs="4">
                            <Button
                              className="float-left productButton"
                              onClick={() =>
                                this.setActiveCustomRugStep(
                                  CUSTOM_STEPS.size_shape
                                )
                              }
                            >
                              Back
                            </Button>
                          </Col>
                          <Col xs="4" />
                          <Col xs="4">
                            <Button
                              className="float-right productButton"
                              onClick={() =>
                                this.setActiveCustomRugStep(
                                  CUSTOM_STEPS.customer
                                )
                              }
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              }
              stepNo={3}
            />
            <this.ColumnStep
              colName={CUSTOM_STEPS.customer}
              activeContent={
                <Row>
                  <Col>
                    <Card className="customRugCard">
                      <CardBody>
                        <this.CustomerDisplay />
                      </CardBody>
                      <CardFooter>
                        <Row>
                          <Col xs="4">
                            <Button
                              className="float-left productButton"
                              onClick={() =>
                                this.setActiveCustomRugStep(
                                  CUSTOM_STEPS.binding
                                )
                              }
                            >
                              Back
                            </Button>
                          </Col>
                          <Col xs="4" />
                          <Col xs="4">
                            <Button
                              className="float-right productButton"
                              onClick={() =>
                                this.setActiveCustomRugStep(CUSTOM_STEPS.review)
                              }
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              }
              stepNo={4}
            />
            <this.ColumnStep
              colName={CUSTOM_STEPS.review}
              activeContent={
                <Row>
                  <Col>
                    <Card className="customRugCard">
                      <CardBody className="p-1 fontSize85">
                        <Table style={{ borderTop: params.none }}>
                          {customRug.rugOverview &&
                          customRug.rugOverview.length > 0 ? (
                            <tbody style={{ borderTop: params.none }}>
                              {_.map(customRug.rugOverview, (p, idx) => {
                                return (
                                  <tr
                                    style={{ borderTop: params.none }}
                                    key={`priceItem${idx}`}
                                  >
                                    <td
                                      width="80%"
                                      style={{ borderTop: params.none }}
                                    >
                                      <strong>{p.label}</strong>
                                    </td>
                                    <td style={{ borderTop: params.none }}>
                                      <span className="float-right">
                                        {formatPrice(p.price)}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr key={`priceItem100`}>
                                <td
                                  width="80%"
                                  style={{ borderTop: params.none }}
                                >
                                  <h5>
                                    <strong className="float-right squarespaceText">
                                      Estimated Subtotal
                                    </strong>
                                  </h5>
                                </td>
                                <td style={{ borderTop: params.none }}>
                                  <h5>
                                    <strong className="float-right squarespaceText">
                                      {formatPrice(customRug.estimatedPrice)}
                                    </strong>
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          ) : null}
                        </Table>
                        <Row className="my-3">
                          <Col>
                            Include freight quote
                            <div className="mt-2">
                              <Switch
                                className="ml-2 p-0 m-0"
                                onChange={() =>
                                  this.toggleCustomRugOption(
                                    customRug.freightQuoteDesired,
                                    "freightQuoteDesired"
                                  )
                                }
                                checked={customRug.freightQuoteDesired || false}
                              />
                            </div>
                          </Col>
                          <Col>
                            Include Scraps, if possible
                            <div className="mt-2">
                              <Switch
                                className="ml-2 p-0 m-0"
                                onChange={() =>
                                  this.toggleCustomRugOption(
                                    customRug.sendScrapsDesired,
                                    "sendScrapsDesired"
                                  )
                                }
                                checked={customRug.sendScrapsDesired || false}
                              />
                            </div>
                          </Col>
                        </Row>
                        <i className="fontSize85 align-self-end pl-3 squarespaceText">
                          *May not include pattern match allowances, labor,
                          sales tax or freight
                        </i>
                        {this.warningsSection(this.state.customRugWarnings)}
                        {this.state.quoteOrderSubmitting && (
                          <div>
                            <strong className="align-self-end pl-3 squarespaceText">
                              Your order is being submitted now.
                            </strong>
                          </div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <Row>
                          <Col xs="2" className="pr-1">
                            <Button
                              className="float-left productButton"
                              onClick={() =>
                                this.setActiveCustomRugStep(
                                  CUSTOM_STEPS.customer
                                )
                              }
                            >
                              Back
                            </Button>
                          </Col>
                          <Col xs="6" className="px-0"></Col>
                          <Col xs="4" className="pl-1">
                            <Button
                              className="float-right productButton"
                              onClick={this.submitCustomRugQuote}
                              disabled={this.state.quoteOrderSubmitting}
                            >
                              Submit Rug Quote
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              }
              stepNo={5}
            />
          </Row>
        ) : (
          <Fragment>
            <Row>
              <Col>
                <Button
                  className="productButton text-white float-right px-3 py-2"
                  tag={Link}
                  to={{
                    pathname: "/sq/sign_in_or_register",
                    state: { successRedirect: "/custom-rug" },
                  }}
                >
                  Log in to access the custom rug quote tool
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>
    );
  };

  warningsSection(warningsList) {
    if (warningsList && warningsList.length) {
      return (
        <Row className="mt-3 pl-3">
          <Col>
            {_.map(warningsList, (message, idx) => {
              return (
                <div className="mb-3" key={`warning${idx}`}>
                  <strong className="text-danger fontSizeTurnItUpTo11">
                    {message}
                  </strong>
                </div>
              );
            })}
          </Col>
        </Row>
      );
    }
    return null;
  }

  toggleActiveCart(cartType) {
    this.setState({
      activeCart: cartType,
    });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  addSampleToCart(item) {
    let newList = Object.assign([], this.state.sampleCartItems);
    let selectedItem = Object.assign({}, item);
    let activeColor =
      selectedItem.activeColor ||
      // this is for area rugs, which should only ever have one
      (selectedItem.productColors && selectedItem.productColors.length
        ? selectedItem.productColors[0]
        : null);
    if (activeColor && !activeColor.value) {
      activeColor = {
        value: activeColor.id,
        label: activeColor.name,
      };
    }
    selectedItem.selectedColor = selectedItem.selectedColor
      ? selectedItem.selectedColor
      : activeColor;
    selectedItem.selectedMemoSize = selectedItem.selectedMemoSize
      ? selectedItem.selectedMemoSize
      : // this is for area rugs, which should only ever have one
      selectedItem.productMemoSizes && selectedItem.productMemoSizes.length
      ? selectedItem.productMemoSizes[0]
      : null;
    const foundIndex = _.findIndex(newList, (sample) => {
      return (
        sample.id === selectedItem.id &&
        (this.state.title === params.rugs ||
          (_.isEqual(sample.selectedColor, selectedItem.selectedColor) &&
            _.isEqual(sample.selectedMemoSize, selectedItem.selectedMemoSize)))
      );
    });
    selectedItem.count = 1;
    newList.push(selectedItem);
    // if (foundIndex > -1) {
    //   selectedItem.count = newList[foundIndex].count + 1;
    //   newList[foundIndex] = selectedItem;
    // } else {
    //   selectedItem.count = 1;
    //   newList.push(selectedItem);
    // }
    this.setState(
      {
        sampleCartItems: newList,
      },
      () => {
        Alert.success("Added to your cart!");
        setItemToStorage(params.sampleCartItems, this.state.sampleCartItems);
      }
    );
    console.log("sCart Items", this.state.sampleCartItems);
  }

  removeSampleFromCart(item) {
    if (this.state.sampleCartItems.length > 0) {
      let newList = this.state.sampleCartItems.slice();
      let indexToEdit = _.findIndex(
        newList,
        (x) =>
          x.id === item.id &&
          _.isEqual(x.selectedColor, item.selectedColor) &&
          _.isEqual(x.selectedMemoSize, item.selectedMemoSize)
      );
      let newCount = newList[indexToEdit].count - 1;
      if (newCount === 0) {
        newList.splice(indexToEdit, 1);
      } else {
        newList[indexToEdit].count = newCount;
      }
      this.setState(
        {
          sampleCartItems: newList,
        },
        () => {
          setItemToStorage(params.sampleCartItems, this.state.sampleCartItems);
        }
      );
    }
  }

  deleteSampleFromCart(item) {
    if (this.state.sampleCartItems.length > 0) {
      let newList = _.reject(
        this.state.sampleCartItems,
        (x) =>
          x.id === item.id &&
          _.isEqual(x.selectedColor, item.selectedColor) &&
          _.isEqual(x.selectedMemoSize, item.selectedMemoSize)
      );
      this.setState(
        {
          sampleCartItems: newList,
        },
        () => {
          setItemToStorage(params.sampleCartItems, this.state.sampleCartItems);
        }
      );
    }
  }

  sampleOrderFormValid() {
    const { customer } = this.state;
    const samplesWithoutColor = _.filter(this.state.sampleCartItems, (item) => {
      return !item.selectedColor;
    });
    if (samplesWithoutColor && samplesWithoutColor.length) {
      _.forEach(samplesWithoutColor, (item) => this.removeSampleFromCart(item));
      // This should be a one time fix for any sample carts that are in production.
      // this shouldn't be hit again
      // Alert.warning("One of your samples was missing a color. Please try to add this one again and select a color option")
    }
    const webEmail = customer.webEmail;
    const requestedBy =
      _.trim(customer.requestedByFirstName) &&
      _.trim(customer.requestedByLastName)
        ? `${customer.requestedByFirstName} ${customer.requestedByLastName}`
        : null;
    if (
      customer &&
      requestedBy &&
      customer.webEmail &&
      customer.useDefaultShipTo
    ) {
      if (requestedBy.length > 255) {
        Alert.warning(
          "Please limit length of the combined requested by first and last name to 255 characters."
        );
        return false;
      }
      if (webEmail.length > 150) {
        Alert.warning("Please limit length of the email to 150 characters.");
        return false;
      }
      return customer && requestedBy && webEmail;
    }
    const isValid =
      customer &&
      requestedBy &&
      webEmail &&
      customer.stAddressLine1 &&
      customer.stAddressLine3 &&
      customer.stState &&
      customer.stCity &&
      customer.stZip;
    if (!isValid) return;
    if (requestedBy.length > 255) {
      Alert.warning(
        "Please limit length of the combined requested by first and last name to 255 characters."
      );
      return false;
    }
    if (customer.webEmail && customer.webEmail.length > 150) {
      Alert.warning("Please limit length of the email to 150 characters.");
      return false;
    }
    if (customer.customerPONumber && customer.customerPONumber.length > 20) {
      Alert.warning("Please limit length of the PO number to 20 characters.");
      return false;
    }
    if (customer.freightSidemark && customer.freightSidemark.length > 50) {
      Alert.warning("Please limit length of the sidemark to 50 characters.");
      return false;
    }
    if (customer.stPhone && customer.stPhone.length > 20) {
      Alert.warning(
        "Please limit length of the phone number to 20 characters."
      );
      return false;
    }
    if (customer.stAddressLine1 && customer.stAddressLine1.length > 100) {
      Alert.warning(
        "Please limit length of the address line 1 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine3 && customer.stAddressLine3.length > 100) {
      Alert.warning(
        "Please limit length of the address line 3 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine2 && customer.stAddressLine2.length > 100) {
      Alert.warning(
        "Please limit length of the address line 2 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine4 && customer.stAddressLine4.length > 100) {
      Alert.warning(
        "Please limit length of the address line 4 to 100 characters."
      );
      return false;
    }
    if (customer.stCity && customer.stCity.length > 35) {
      Alert.warning("Please limit length of the city to 35 characters.");
      return false;
    }
    if (customer.stState && customer.stState.length > 5) {
      Alert.warning("Please limit length of the state to 5 characters.");
      return false;
    }
    if (customer.stZip && customer.stZip.length > 10) {
      Alert.warning("Please limit length of the email to 10 characters.");
      return false;
    }
    return (
      isValid &&
      this.state.sampleCartItems &&
      this.state.sampleCartItems.length > 0
    );
  }

  submitSampleOrder() {
    if (!this.sampleOrderFormValid()) return;
    const { customer } = this.state;
    const { sampleCartItems, sampleComment } = this.state;
    this.setState({ sampleCartSubmitting: true }, () => {
      const memoOrder = {
        Id: 0,
        CustomerId: customer.id,
        Sidemark: customer.freightSidemark || "",
        ContactFirstName: customer.requestedByFirstName,
        ContactLastName: customer.requestedByLastName,
        ContactEmail: customer.webEmail,
        AddressLine1: customer.stAddressLine1,
        AddressLine2: customer.stAddressLine2,
        AddressLine3: customer.stAddressLine3,
        AddressLine4: customer.stAddressLine4,
        City: customer.stCity,
        State: customer.stState,
        Zip: customer.stZip,
        Phone: customer.stPhone || "",
        Comment: sampleComment || "",
        MemoOrderLines: _.map(sampleCartItems, (item) => {
          return {
            Id: 0,
            MemoOrderId: 0,
            TypeOfMemoOrderLineStatus: pendingMemoOrderStatus.value,
            LastStatusAt: new Date(),
            ProductId: item.id,
            ProductColorId: item.selectedColor.value,
            MemoSizeId: -1, // placeholder so we can overwrite in the service layer for default size
            Qty: Number(item.count),
            MemoOrderLineComments: [],
            CustomerId: customer.id
            // sampleComment && sampleComment !== ""
            //   ? [
            //       {
            //         Dsc: sampleComment,
            //       },
            //     ]
            //   : [],
          };
        }),
      };
      const payload = {
        MemoOrder: memoOrder,
        UseDefaultShipToAddress: customer.useDefaultShipTo,
      };
      api
        .post("Web/CustomerSaveNewMemoOrder", payload)
        .then((response) => {
          window.scroll(0, 0);
          if (response && response.data && response.data.success) {
            this.setState(
              {
                sampleCartItems: [],
                sidebarOpen: false,
              },
              () => {
                Alert.success("Your Sample Order has been Submitted!");
                removeItemFromStorage(params.sampleCartItems);
              }
            );
          } else {
            this.setState({
              sampleCartMessageFlavor: "danger",
              sampleCartMessage: response.data.message,
            });
          }
        })
        .catch(helpers.catchHandler)
        .finally(() => this.setState({ sampleCartSubmitting: false }));
    });
  }

  selectTerms(selection) {
    this.setState({
      freightTerms: selection,
    });
  }

  addToOrderCart(item, qty, lengthFeet, width) {
    let newList = Object.assign([], this.state.orderCartItems);
    let selectedItem = Object.assign({}, item);
    selectedItem.selectedColor = selectedItem.selectedColor
      ? selectedItem.selectedColor
      : selectedItem.activeColor;
    selectedItem.selectedProductWidth = width;
    selectedItem.lengthFeet = lengthFeet;
    selectedItem.qty = qty;
    const unitPrice = item.selectedRugPrice
      ? item.selectedRugPrice
      : item.unitPrice;
    selectedItem.price = _.round(qty * unitPrice, 2).toFixed(2);
    const foundIndex = _.findIndex(newList, (sample) => {
      return (
        sample.id === selectedItem.id &&
        _.isEqual(sample.selectedColor, selectedItem.selectedColor) &&
        _.isEqual(sample.selectedRugSize, selectedItem.selectedRugSize)
      );
    });
    if (foundIndex > -1) {
      newList[foundIndex] = selectedItem;
    } else {
      newList.push(selectedItem);
    }
    this.setState(
      {
        orderCartItems: newList,
        productLengthFeet: 0,
        productQuantity: 0,
      },
      () => {
        Alert.success("Added to your cart!");
        setItemToStorage(params.orderCartItems, this.state.orderCartItems);
      }
    );
  }

  deleteFromOrderCart(item) {
    if (this.state.orderCartItems.length > 0) {
      let newList = _.reject(
        this.state.orderCartItems,
        (x) =>
          x.id === item.id &&
          _.isEqual(x.selectedColor, item.selectedColor) &&
          _.isEqual(x.selectedRugSize, item.selectedRugSize)
      );
      this.setState(
        {
          orderCartItems: newList,
        },
        () => {
          setItemToStorage(params.orderCartItems, this.state.orderCartItems);
        }
      );
    }
  }

  purchaseOrderFormValid() {
    const { customer } = this.state;
    const requestedBy = `${customer.requestedByFirstName} ${customer.requestedByLastName}`;
    let isValid =
      customer &&
      customer.requestedByFirstName &&
      customer.requestedByLastName &&
      customer.stAddressLine1 &&
      customer.stAddressLine3 &&
      customer.stState &&
      customer.stCity &&
      customer.stZip &&
      customer.customerPONumber &&
      customer.customerPONumber.length > 1;
    if (!isValid) return;
    if (requestedBy.length > 255) {
      Alert.warning(
        "Please limit length of the combined requested by first and last names to 255 characters."
      );
      return false;
    }
    if (customer.webEmail && customer.webEmail.length > 150) {
      Alert.warning("Please limit length of the email to 150 characters.");
      return false;
    }
    if (customer.customerPONumber.length > 20) {
      Alert.warning("Please limit length of the PO number to 20 characters.");
      return false;
    }
    if (customer.freightSidemark && customer.freightSidemark.length > 50) {
      Alert.warning("Please limit length of the sidemark to 50 characters.");
      return false;
    }
    if (customer.stPhone && customer.stPhone.length > 20) {
      Alert.warning(
        "Please limit length of the phone number to 20 characters."
      );
      return false;
    }
    if (customer.stAddressLine1 && customer.stAddressLine1.length > 100) {
      Alert.warning(
        "Please limit length of the address line 1 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine3 && customer.stAddressLine3.length > 100) {
      Alert.warning(
        "Please limit length of the address line 3 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine2 && customer.stAddressLine2.length > 100) {
      Alert.warning(
        "Please limit length of the address line 2 to 100 characters."
      );
      return false;
    }
    if (customer.stAddressLine4 && customer.stAddressLine4.length > 100) {
      Alert.warning(
        "Please limit length of the address line 4 to 100 characters."
      );
      return false;
    }
    if (customer.stCity && customer.stCity.length > 35) {
      Alert.warning("Please limit length of the city to 35 characters.");
      return false;
    }
    if (customer.stState && customer.stState.length > 5) {
      Alert.warning("Please limit length of the state to 5 characters.");
      return false;
    }
    if (customer.stZip && customer.stZip.length > 10) {
      Alert.warning("Please limit length of the email to 10 characters.");
      return false;
    }
    return (
      isValid &&
      this.state.orderCartItems &&
      this.state.orderCartItems.length > 0
    );
  }

  submitPurchaseOrder() {
    if (!this.purchaseOrderFormValid()) return;
    const { orderCartItems, orderComment, customer, productType } = this.state;
    this.setState(
      {
        orderCartSubmitting: true,
      },
      () => {
        const orderLines = _.map(orderCartItems, (item) => {
          return this.shapeOrderLine(item, orderComment);
        });
        const payload = shapeCommonOrderPayload(
          orderLines,
          customer,
          productType
        );
        api
          .post("Web/CustomerSaveNewOrder", payload)
          .then((response) => {
            window.scroll(0, 0);
            if (response && response.data && response.data.success) {
              this.setState(
                {
                  orderCartItems: [],
                  sidebarOpen: false,
                },
                () => {
                  Alert.success("Your order has been submitted!");
                  removeItemFromStorage(params.orderCartItems);
                }
              );
            } else {
              this.setState({
                orderCartMessageFlavor: "danger",
                orderCartMessage: response.data.message,
              });
            }
          })
          .catch(helpers.catchHandler)
          .finally(() => this.setState({ orderCartSubmitting: false }));
      }
    );
  }

  SidebarSection(forSmallScreen = false) {
    const { sidebarOpen } = this.state;
    let cartHeight = 700;
    if (this.node && this.node instanceof HTMLElement) {
      const parent = this.node.parentElement;
      if (parent) {
        const childG = parent.querySelector(`.cartWidth`);
        const gHeight = (childG && childG.clientWidth) || cartHeight;
        cartHeight = gHeight;
      }
    }
    return (
      <Sidebar
        sidebar={<Fragment>{this.CartDisplay(forSmallScreen)}</Fragment>}
        open={sidebarOpen}
        styles={{
          root: {
            overflowY: forSmallScreen ? "scroll" : "visible",
            overflowX: "hidden",
            minHeight: cartHeight,
          },
          sidebar: {
            background: "white",
            zIndex: 200,
            marginTop: 70,
            overflowY: forSmallScreen ? "scroll" : "visible",
            flex: 1,
            flexDirection: "column",
            minHeight: cartHeight,
          },
          // overlay: {
          //   backgroundColor: "rgba(0,0,0,0)",
          //   minHeight: cartHeight,
          // },
          content: {
            overflowY: forSmallScreen ? "scroll" : "visible",
            minHeight: cartHeight,
          },
        }}
        className="sidebarWidth"
        pullRight={true}
        onSetOpen={this.toggleSidebar}
      >
        <Fragment />
      </Sidebar>
    );
  }

  CustomerDisplay({}) {
    const { customer, activeCart, productType, title, customRug } = this.state;
    if (!customer) {
      return null;
    }
    let displayTitle = "";
    const customRugPage = title === params.customRugTitle;
    const isSampleCart =
      !customRugPage && activeCart && activeCart === params.sampleCartItems;
    const isProductCart =
      !customRugPage && activeCart && activeCart === params.orderCartItems;
    if (customRugPage) {
      displayTitle = params.customRug;
    } else {
      displayTitle = activeCart;
    }
    const readOnlyAddress = isSampleCart && customer.useDefaultShipTo;
    return (
      <div
        key={`${displayTitle}CustomerProfileDisplay`}
        id={`${displayTitle}CustomerProfileDisplay`}
      >
        <Form>
          <Row className="mb-2">
            <Col xs="12" lg="3">
              First Name {helpers.requiredStar()}
              <DebounceInput
                className="form-control"
                value={customer.requestedByFirstName}
                name="requestedByFirstName"
                debounceTimeout={1000}
                onChange={this.onCustomerChangeHandler}
                id={`requestedByFirstName${displayTitle}Customer`}
                key={`requestedByFirstName${displayTitle}Customer`}
                maxLength={255}
              />
            </Col>
            <Col xs="12" lg="3">
              Last Name {helpers.requiredStar()}
              <DebounceInput
                className="form-control"
                value={customer.requestedByLastName}
                name="requestedByLastName"
                debounceTimeout={1000}
                onChange={this.onCustomerChangeHandler}
                id={`requestedByLastName${displayTitle}Customer`}
                key={`requestedByLastName${displayTitle}Customer`}
                maxLength={255}
              />
            </Col>
            <Col xs="12" lg="6">
              Email {helpers.requiredStar()}
              <DebounceInput
                className="form-control"
                value={customer.webEmail}
                name="webEmail"
                debounceTimeout={1000}
                onChange={this.onCustomerChangeHandler}
                id={`webEmail${displayTitle}Customer`}
                key={`webEmail${displayTitle}Customer`}
                maxLength={150}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs="12" lg="4">
              PO # {isProductCart ? helpers.requiredStar() : null}
              <DebounceInput
                className="form-control"
                value={customer.customerPONumber}
                name="customerPONumber"
                debounceTimeout={1000}
                onChange={this.onCustomerChangeHandler}
                id={`customerPONumber${displayTitle}Customer`}
                key={`customerPONumber${displayTitle}Customer`}
                maxLength={20}
              />
            </Col>
            <Col xs="12" lg="4">
              Sidemark
              <DebounceInput
                className="form-control"
                name="freightSidemark"
                value={customer.freightSidemark}
                debounceTimeout={1000}
                onChange={this.onCustomerChangeHandler}
                id={`freightSidemark${displayTitle}Customer`}
                key={`freightSidemark${displayTitle}Customer`}
                maxLength={50}
              />
            </Col>
            <Col xs="12" lg="4">
              Contact Phone
              <Input
                className="form-control"
                value={customer.stPhone || ""}
                name="stPhone"
                onChange={this.onCustomerChangeHandler}
                id={`stPhone${displayTitle}Customer`}
                key={`stPhone${displayTitle}Customer`}
                maxLength={20}
                onBlur={() => this.formatPhoneField(customer.stPhone)}
              />
            </Col>
          </Row>
          {isSampleCart && (
            <Row className="mb-2">
              <Col xs="12">
                Use Shipping Address On File
                <Switch
                  className="ml-2 p-0 m-0"
                  onChange={(checked) =>
                    this.onCustomerSwitch(checked, "useDefaultShipTo")
                  }
                  checked={customer.useDefaultShipTo}
                  id={`useDefaultShipTo${displayTitle}Customer`}
                  key={`useDefaultShipTo${displayTitle}Customer`}
                />
              </Col>
            </Row>
          )}
          <Fragment>
            <Row className="mb-2">
              <Col xs="12">
                <Row className="mb-1">
                  <Col className="pr-1">
                    Ship To{" "}
                    {productType === params.customRug ? null : (
                      <Fragment>
                        <i className="fontSize85">(Name and Line 1 Required)</i>
                        {helpers.requiredStar()}
                      </Fragment>
                    )}
                    <DebounceInput
                      className="form-control mb-1"
                      name="stAddressLine1"
                      value={customer.stAddressLine1 || ""}
                      debounceTimeout={1000}
                      onChange={this.onCustomerChangeHandler}
                      placeholder="Company Name *"
                      readOnly={readOnlyAddress}
                      id={`stAddressLine1${displayTitle}Customer`}
                      maxLength={100}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1">
                    <DebounceInput
                      className="form-control mb-1"
                      name="stAddressLine2"
                      value={customer.stAddressLine2 || ""}
                      debounceTimeout={1000}
                      onChange={this.onCustomerChangeHandler}
                      placeholder="C/O (Optional)"
                      readOnly={readOnlyAddress}
                      id={`stAddressLine2${displayTitle}Customer`}
                      maxLength={100}
                    />
                    <DebounceInput
                      className="form-control mb-1"
                      name="stAddressLine3"
                      value={customer.stAddressLine3 || ""}
                      debounceTimeout={1000}
                      onChange={this.onCustomerChangeHandler}
                      placeholder="Shipping Address Line 1 *"
                      readOnly={readOnlyAddress}
                      id={`stAddressLine3${displayTitle}Customer`}
                      maxLength={100}
                    />
                    <DebounceInput
                      className="form-control mb-1"
                      name="stAddressLine4"
                      value={customer.stAddressLine4 || ""}
                      debounceTimeout={1000}
                      onChange={this.onCustomerChangeHandler}
                      placeholder="Address Line 2 (Optional)"
                      readOnly={readOnlyAddress}
                      id={`stAddressLine4${displayTitle}Customer`}
                      maxLength={100}
                    />
                    <Row className="mb-1">
                      <Col xs="6" className="pr-1">
                        <DebounceInput
                          className="form-control"
                          name="stCity"
                          value={customer.stCity || ""}
                          debounceTimeout={1000}
                          onChange={this.onCustomerChangeHandler}
                          placeholder="City"
                          readOnly={readOnlyAddress}
                          id={`stCity${displayTitle}Customer`}
                          maxLength={35}
                        />
                      </Col>
                      <Col xs="2" className="px-1">
                        <DebounceInput
                          className="form-control"
                          name="stState"
                          value={customer.stState || ""}
                          debounceTimeout={1000}
                          onChange={this.onCustomerChangeHandler}
                          placeholder="ST"
                          readOnly={readOnlyAddress}
                          id={`stState${displayTitle}Customer`}
                          maxLength={5}
                        />
                      </Col>
                      <Col xs="4" className="pl-1">
                        <DebounceInput
                          className="form-control"
                          name="stZip"
                          value={customer.stZip || ""}
                          debounceTimeout={1000}
                          onChange={this.onCustomerChangeHandler}
                          placeholder="Zip"
                          readOnly={readOnlyAddress}
                          id={`stZip${displayTitle}Customer`}
                          maxLength={10}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {productType === params.customRug && (
                  <Row className="mt-2">
                    <Col>
                      <DebounceInput
                        element="textarea"
                        style={{ height: "100px" }}
                        className="form-control"
                        value={customRug.customRugComment || ""}
                        name="customRugComment"
                        debounceTimeout={1000}
                        onChange={(event) =>
                          this.onChangeCustomRugInput(null, event)
                        }
                        maxLength={250}
                        placeholder="Special Instructions (Optional)"
                        id={`customRugComment${displayTitle}CustomRug`}
                        key={`customRugComment${displayTitle}CustomRug`}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Fragment>
        </Form>
      </div>
    );
  }

  CartDisplay(forSmallScreen = false) {
    const {
      sampleCartItems,
      orderCartItems,
      activeCart,
      orderCartMessage,
      orderCartMessageFlavor,
      customer,
    } = this.state;
    if (!customer) {
      return null;
    }
    const sampleCount =
      sampleCartItems && sampleCartItems.length > 0
        ? _.chain(sampleCartItems)
            .map((s) => {
              return s.count || 0;
            })
            .sum()
            .value()
        : 0;
    const sampleCartOver10 = sampleCount > 10;
    const sampleCartRow = (sample, index) => {
      const sampleColorId = sample.selectedColor && sample.selectedColor.value;
      const sampleMemoId = "";
      const rowKey = `sampleItem${sample.id}${sampleColorId}${sampleMemoId}`;
      return (
        <Row className="mb-1" key={rowKey}>
          <Col>{sample.cleanerName}</Col>
          <Col>{sample.selectedColor && sample.selectedColor.label}</Col>
          <Col>
            <FontAwesomeIcon
              icon="minus"
              className="mr-2 text-warning"
              onClick={() => this.removeSampleFromCart(sample)}
            />
            {sample.count}
            <FontAwesomeIcon
              icon="plus"
              className="ml-2 text-success"
              onClick={() => this.addSampleToCart(sample)}
            />
          </Col>
          <Col xs="1">
            <FontAwesomeIcon
              icon="times"
              className="ml-2 text-danger"
              onClick={() => this.deleteSampleFromCart(sample)}
            />
          </Col>
        </Row>
      );
    };
    const orderCartRow = (product, index) => {
      const productColorId =
        product.selectedColor && product.selectedColor.value;
      const rowKey = `orderItem${product.id}${productColorId}${index}`;
      return (
        <Row className="mb-2" key={rowKey}>
          <Col xs="8" lg="5">
            {product.cleanerName}
            {product.selectedColor && product.selectedColor.label
              ? ` - ${product.selectedColor.label}`
              : null}
          </Col>
          <Col lg="4" className="d-none d-lg-block">
            {" "}
            {/**********On large screens, show this on same line ***************/}
            {product.productTypeName === params.carpet &&
            product.selectedProductWidth &&
            product.selectedProductWidth.label
              ? `(${product.selectedProductWidth.label} X ${
                  product.lengthFeet
                }) = ${product.qty || "0"} ${product.priceUnitTypeName}`
              : product.productTypeName === "Intl Rug"
              ? `${product.qty || "0"} X (${product.selectedRugSize.label})`
              : `Qty: ${product.qty || "0"}`}
          </Col>
          <Col xs="3" lg="2" className="pr-0">
            <strong className="squarespaceText">
              ${_.round(product.price / 100.0, 2).toFixed(2)}
            </strong>
          </Col>
          <Col xs="1" className="px-0">
            <FontAwesomeIcon
              icon="times"
              className="ml-2 text-danger"
              onClick={() => this.deleteFromOrderCart(product)}
            />
          </Col>
          <Col xs="12" className="d-block d-lg-none">
            {" "}
            {/**********On small screens, show it on a new line ***************/}
            {product.productTypeName === params.carpet &&
            product.selectedProductWidth &&
            product.selectedProductWidth.label
              ? `(${product.selectedProductWidth.label} X ${
                  product.lengthFeet
                }) = ${product.qty || "0"} ${product.priceUnitTypeName}`
              : product.productTypeName === "Intl Rug"
              ? `${product.qty || "0"} X (${product.selectedRugSize.label})`
              : `Qty: ${product.qty || "0"}`}
          </Col>
        </Row>
      );
    };
    const sampleItems = () => {
      if (sampleCartItems.length === 0) {
        return (
          <Row className="mb-2">
            <Col>
              <i>
                <h5 className="squarespaceText">No samples in cart yet</h5>
              </i>
            </Col>
          </Row>
        );
      }
      const list = _.chain(sampleCartItems)
        .map((item, index) => sampleCartRow(item, index))
        .value();
      return (
        <Row className="mb-2">
          <Col>{list}</Col>
        </Row>
      );
    };
    function purchaseOrderItems() {
      if (orderCartItems.length === 0) {
        return (
          <Row className="mb-2">
            <Col>
              <i>
                <h5 className="squarespaceText">
                  No items placed in purchase order cart yet
                </h5>
              </i>
            </Col>
          </Row>
        );
      }
      const list = _.chain(orderCartItems)
        .map((item, index) => orderCartRow(item, index))
        .value();
      let total = 0;
      _.map(orderCartItems, (x) => {
        total = total + parseInt(x.price, 10);
      });
      total = _.round(total / 100.0, 2).toFixed(2);
      return (
        <Fragment>
          <Row className="mb-2">
            <Col>{list}</Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className="float-right">
                <h5>
                  <strong>
                    <u className="squarespaceText mr-2">TOTAL:</u>
                  </strong>
                  ${total}
                </h5>
              </div>
            </Col>
          </Row>
        </Fragment>
      );
    }
    return (
      <Container className="cartWidth mt-3 pb-3 px-2">
        <Row>
          <Col xs="12">
            {<this.CustomerDisplay />}
            <Nav tabs>
              <NavItem className="width50Percent">
                <NavLink
                  className={classnames({
                    active: activeCart === params.orderCartItems,
                    cursorPointer: true,
                  })}
                  onClick={() => {
                    this.toggleActiveCart(params.orderCartItems);
                  }}
                >
                  <h4 className="squarespaceText">Purchase Order</h4>
                </NavLink>
              </NavItem>
              <NavItem className="width50Percent">
                <NavLink
                  className={classnames({
                    active: activeCart === params.sampleCartItems,
                    cursorPointer: true,
                  })}
                  onClick={() => {
                    this.toggleActiveCart(params.sampleCartItems);
                  }}
                >
                  <h4 className="squarespaceText">Samples</h4>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeCart}>
              <TabPane tabId={params.orderCartItems}>
                {orderCartMessage ? (
                  <Row className="mb-2">
                    <Col>
                      <AlertStrap className={orderCartMessageFlavor} fade>
                        {orderCartMessage}
                      </AlertStrap>
                    </Col>
                  </Row>
                ) : null}
                {purchaseOrderItems()}
                <Row>
                  <Col xs={forSmallScreen ? "12" : "8"}>
                    <DebounceInput
                      element="textarea"
                      style={{ height: "100px" }}
                      className="form-control"
                      value={this.state.orderComment || ""}
                      name="orderComment"
                      debounceTimeout={1000}
                      onChange={this.handleInputChange}
                      maxLength={250}
                      placeholder="Special Instructions (Optional)"
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col xs="12">
                        Freight Type
                        <Select
                          options={this.state.freightTermsList}
                          value={
                            this.state.freightTerms
                              ? this.state.freightTerms
                              : {
                                  value: customer.freightTermsId,
                                  label: customer.freightTermsName,
                                }
                          }
                          bsSize="sm"
                          onChange={this.selectTerms}
                          components={makeAnimated()}
                          closeMenuOnSelect={true}
                        />
                      </Col>
                      <Col
                        xs="12"
                        className={forSmallScreen ? "mt-3 text-center" : "mt-0"}
                      >
                        <Button
                          className={
                            forSmallScreen
                              ? "productButton"
                              : "float-right productButton"
                          }
                          onClick={this.submitPurchaseOrder}
                          disabled={
                            this.state.orderCartSubmitting ||
                            !this.purchaseOrderFormValid()
                          }
                        >
                          Submit Order
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={params.sampleCartItems}>
                {this.state.sampleCartMessage ? (
                  <Row className="mb-2">
                    <Col>
                      <AlertStrap
                        className={this.state.sampleCartMessageFlavor}
                        fade
                      >
                        {this.state.sampleCartMessage}
                      </AlertStrap>
                    </Col>
                  </Row>
                ) : null}
                {/* <h5 className="squarespaceText mb-2">
                  Note that you will be invoiced for any samples in excess of 10
                  for a given job. <br />
                  Maximum of 5 rug samples may be ordered at no charge. Any quantity over 5 will be required to be purchased.
                </h5> */}
                {sampleItems()}
                <Row>
                  <Col xs={forSmallScreen ? "12" : "8"}>
                    <DebounceInput
                      element="textarea"
                      style={{ height: "50px" }}
                      className="form-control"
                      value={this.state.sampleComment || ""}
                      name="sampleComment"
                      onChange={this.handleInputChange}
                      debounceTimeout={1000}
                      maxLength={250}
                      placeholder="Special Instructions (Optional)"
                    />
                  </Col>
                  <Col
                    xs={forSmallScreen ? "12" : "4"}
                    className={forSmallScreen ? "mt-3 text-center" : "mt-0"}
                  >
                    <Button
                      className={
                        forSmallScreen
                          ? "productButton"
                          : "float-right productButton"
                      }
                      onClick={this.submitSampleOrder}
                      disabled={
                        this.state.sampleCartSubmitting ||
                        !this.sampleOrderFormValid()
                      }
                    >
                      Submit Sample Request
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const {
      page,
      productType,
      title,
      section,
      filterType,
      subTypes,
      sort_field,
      showPrices,
      colorFamilyList,
      constructionTypeList,
      pileTypeList,
      showProduct,
      productTypeList,
      webCategoryList,
      sidebarOpen,
      pagedList,
      thinking,
      sampleCartItems,
      orderCartItems,
      customer,
      shareLinkBase,
      fiberGroupList,
      singleProductDisplay,
      fetchingProduct,
    } = this.state;
    const { list } = pagedList;
    const showSidebarButton =
      customer &&
      ((sampleCartItems && sampleCartItems.length > 0) ||
        (orderCartItems && orderCartItems.length > 0));
    const sortByList = [
      { sort_field: "DCName", sort_dir: "asc", label: "Title (A-Z)" },
      {
        sort_field: "DCName",
        sort_dir: "desc",
        label: "Title (Z-A)",
        value: 2,
      },
      {
        sort_field: "UpdatedAt",
        sort_dir: "desc",
        label: "Newest",
        value: 3,
      },
      { sort_field: "UpdatedAt", sort_dir: "asc", label: "Oldest", value: 4 },
    ];
    if (showPrices) {
      sortByList.push({
        sort_field: "UnitPrice",
        sort_dir: "asc",
        label: "Price, Low-High",
        value: 5,
      });
      sortByList.push({
        sort_field: "UnitPrice",
        sort_dir: "desc",
        label: "Price, High-Low",
        value: 6,
      });
    }
    const findSortValue = _.find(sortByList, (opt) => {
      return (
        opt.sort_field === this.state.sort_field &&
        opt.sort_dir === this.state.sort_dir
      );
    });
    const sortingDropDown = (
      <Select
        bsSize="sm"
        closeMenuOnSelect
        options={sortByList}
        value={findSortValue}
        onChange={(option) => this.doSort(option.sort_field, option.sort_dir)}
        placeholder={"Sort By..."}
      />
    );
    const useImagesMap = list && list.length > 0;
    let imagesMap = useImagesMap
      ? _.map(list, (item) => {
          return item.listRenderType;
        })
      : `No matching results for your search criteria`;
    const cartButtonDisabled = !(
      customer &&
      ((sampleCartItems && sampleCartItems.length > 0) ||
        (orderCartItems && orderCartItems.length > 0))
    );
    const showFiberFilter =
      title !== params.hardSurface && title !== params.bordersAndBindings;
    const showConstructionFilter =
      title !== params.hardSurface && title !== params.bordersAndBindings;
    if (this.state.redirect === true) {
      return <Redirect to={`${this.state.redirectTo}`} />;
    }
    if (this.state.useCustomRugDisplay) {
      return <this.CustomRugDisplay />;
    }
    if (singleProductDisplay && singleProductDisplay.productId) {
      if (fetchingProduct || !showProduct) {
        return (
          <Fragment>
            <Spinner type="grow" color="secondary" className="mr-2" />{" "}
            <div className="squarespaceText">Loading your product...</div>
          </Fragment>
        );
      }
      const {
        cleanerName,
        cardDeckItems,
        carouselItems,
        supplierLabel,
        dcName,
        dcNumber,
        productWidths,
        repeat,
        patternMatch,
        basicSpecs,
        constructionTypeName,
        // yarnBrandName,
        pileTypeName,
        unitPrice,
        priceUnitTypeName,
        colorOptions,
        activeColor,
        webProductDescription,
        id,
        activePhoto,
        colorNumber,
        // rug specific items
        availableAsCustomRug,
        rugSizes,
        customRugSQPrice,
        showRugOptions,
        selectedRugSize,
        selectedRugPrice,
        // hard surface items
        squareFeetPerCarton,
        thickness,
        itemWidth,
        itemLength,
        perContainer,
        productMemoSizes,
      } = showProduct;
      const allowWebPurchasing =
        _.includes(
          [params.wood, params.borderOrBinding],
          showProduct.productTypeName
        ) || _.includes(dcName, params.contractTileString)
          ? false
          : true;
      const colorSelected = activeColor && activeColor.label;
      const quantityProvided = this.state.productQuantity > 0;
      const rugSizeSelected = selectedRugSize && selectedRugSize.label;
      const productWidthProvided =
        this.state.selectedProductWidth &&
        this.state.selectedProductWidth.label;
      const productLengthProvided =
        this.state.productLengthFeet && this.state.productLengthFeet > 0;
      const carpetPurchaseValid =
        colorSelected && quantityProvided && productLengthProvided;
      const rugPurchaseValid = rugSizeSelected && quantityProvided;
      const atLeastOneMemoSize =
        productMemoSizes && productMemoSizes.length > 0;
      const shareLink = getDirectProductLink(
        shareLinkBase,
        id,
        activeColor && activeColor.value ? activeColor.value : null
      );
      return (
        <React.Fragment>
          {/*********** Product Detail View for Large Screens **************/}
          <Container className="mt-1 squarespaceContainer d-none d-lg-block">
            {/* <this.SidebarSection /> */}
            {this.SidebarSection(false)}
            <Row className={sidebarOpen ? "mb-2 applyOpacity" : "mb-2"}>
              <Col>
                <h6>
                  <i>
                    {title}
                    {section ? ` / ${section}` : null}
                    {` / ${cleanerName}`}
                  </i>
                </h6>
              </Col>
              <Col className="p-0 m-0 maxWidth200">
                <Row className="p-0 m-0 minHeight39">
                  <Col className="p-0 m-0 maxWidth75">
                    <Button
                      className="px-1 sqSidebarButtonOutline float-right minWidth70 mr-2"
                      size="sm"
                      onClick={this.goBackToList}
                    >
                      <FontAwesomeIcon
                        icon="long-arrow-alt-left"
                        className="mr-1"
                      />
                      Back
                    </Button>
                  </Col>
                  <Col className="maxWidth90 p-0 m-0">
                    <Button
                      className="px-1 sqSidebarButton float-right minWidth70"
                      onClick={this.toggleSidebar}
                      disabled={cartButtonDisabled}
                      sz="sm"
                    >
                      <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
                      Cart
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Card className="mb-1 borderNone">
                  <CardImg
                    top
                    width="100%"
                    src={getImageUrl(activePhoto)}
                    alt={activePhoto ? activePhoto.alt : ""}
                  />
                  <CardSubtitle tag="h6" className="p-1 text-muted">
                    {activePhoto ? activePhoto.title : ""}
                  </CardSubtitle>
                </Card>
                <Row>
                  {carouselItems && carouselItems.length > 0
                    ? _.map(carouselItems, (crd, idx) => {
                        return (
                          <Col
                            key={`general${crd.key}${idx}`}
                            xs="4"
                            className="px-1 mb-2"
                          >
                            <Card
                              className="borderNone"
                              onClick={() => this.selectColorOptionByPhoto(crd)}
                            >
                              <CardImg
                                top
                                width="100%"
                                src={getImageUrl(crd)}
                                alt={crd.alt}
                              />
                              <CardSubtitle tag="h6" className="p-1 text-muted">
                                {crd.title}
                              </CardSubtitle>
                            </Card>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </Col>
              <Col xs="6">
                <Row>
                  <Col>
                    {/* <h5>{supplierLabel}</h5> */}
                    <h4>{dcName}</h4>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    {showPrices ? null : (
                      <Button
                        className="productButton text-white px-2 py-2 mb-2"
                        tag={Link}
                        to={{
                          pathname: "/sq/sign_in_or_register",
                          state: {
                            successRedirect: findRedirectUrl(
                              this.props.location.pathname
                            ),
                          },
                        }}
                      >
                        Log in to View Prices & Request Samples
                      </Button>
                    )}
                    {showRugOptions ? (
                      <Fragment>
                        {atLeastOneMemoSize && showPrices && (
                          <Row className="mb-2">
                            <Col xs="5" className="pr-0">
                              {colorOptions.length > 0 ? (
                                <Select
                                  bsSize="sm"
                                  closeMenuOnSelect
                                  options={colorOptions}
                                  value={activeColor}
                                  onChange={(option) =>
                                    this.selectColorOption(option)
                                  }
                                />
                              ) : null}
                            </Col>
                            <Col xs="7" className="px-0">
                              <Button
                                title="Request a sample"
                                onClick={() =>
                                  this.addSampleToCart(showProduct)
                                }
                                className="float-right productButton btn-sm p-1"
                              >
                                Sample Request
                                <FontAwesomeIcon
                                  icon="cut"
                                  className="ml-1 mt-1"
                                />
                              </Button>
                            </Col>
                          </Row>
                        )}
                        <Row className="mb-1">
                          <Col xs="5" className="pr-0">
                            <Select
                              bsSize="sm"
                              closeMenuOnSelect
                              options={rugSizes}
                              value={selectedRugSize}
                              onChange={(option) =>
                                this.selectRugSize(option, showProduct)
                              }
                            />
                          </Col>
                          {showPrices && (
                            <Fragment>
                              <Col xs="4" className="pr-0 pt-1">
                                {formatPrice(selectedRugPrice)} /{" "}
                                {`${priceUnitTypeName}`}
                              </Col>
                              <Col xs="2" className="px-1">
                                <Input
                                  className="form-control"
                                  type="number"
                                  style={{ marginTop: "0" }}
                                  value={this.state.productQuantity || 0}
                                  name="productQuantity"
                                  onChange={this.handleInputChange}
                                  placeholder="Qty"
                                  min="0"
                                />
                              </Col>
                              <Col
                                title="Add to your order"
                                className="px-1 pt-1"
                              >
                                <Button
                                  title={
                                    rugPurchaseValid
                                      ? "Add to cart"
                                      : "Pick a size and quantity first"
                                  }
                                  onClick={() =>
                                    this.addToOrderCart(
                                      showProduct,
                                      this.state.productQuantity,
                                      null,
                                      null
                                    )
                                  }
                                  className="float-right productButton btn-sm p-1"
                                  disabled={!rugPurchaseValid}
                                >
                                  <FontAwesomeIcon icon="cart-plus" size="lg" />
                                </Button>
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Row className="mb-1">
                          {colorOptions.length > 0 ? (
                            <Col xs="5" className="pr-0">
                              <Select
                                bsSize="sm"
                                closeMenuOnSelect
                                options={colorOptions}
                                value={activeColor}
                                onChange={(option) =>
                                  this.selectColorOption(option)
                                }
                              />
                            </Col>
                          ) : null}
                          {showPrices && (
                            <Fragment>
                              <Col xs="3" className="pr-0 pt-1">
                                {formatPrice(unitPrice)} /{" "}
                                {`${priceUnitTypeName}`}
                              </Col>
                              <Col xs="4" className="px-0">
                                <Button
                                  title={
                                    activeColor && activeColor.label
                                      ? "Request a sample"
                                      : "Pick a color first"
                                  }
                                  onClick={() =>
                                    this.addSampleToCart(showProduct)
                                  }
                                  className="float-right productButton btn-sm p-1"
                                  // cannot request until a color is selected
                                  disabled={!colorSelected}
                                >
                                  Sample Request
                                  <FontAwesomeIcon
                                    icon="cut"
                                    className="ml-1 mt-1"
                                  />
                                </Button>
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                        {showPrices && (
                          <Fragment>
                            {allowWebPurchasing ? (
                              <Row>
                                {this.state.productWidthOptions.length > 0 ? (
                                  <Col xs="3" className="pr-0">
                                    Width
                                    <Select
                                      bsSize="sm"
                                      closeMenuOnSelect
                                      options={this.state.productWidthOptions}
                                      value={this.state.selectedProductWidth}
                                      onChange={(option) =>
                                        this.selectProductWidth(option)
                                      }
                                    />
                                  </Col>
                                ) : null}
                                {priceUnitTypeName !== "Each" &&
                                showProduct.productTypeName !== params.wood ? (
                                  <Col xs="3">
                                    {_.includes(
                                      dcName,
                                      params.contractTileString
                                    ) ? (
                                      <Fragment>
                                        Sq ft. {helpers.requiredStar()}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        Length &#40;ft.&#41;{" "}
                                        {helpers.requiredStar()}
                                      </Fragment>
                                    )}
                                    <Input
                                      type="number"
                                      name="productLengthFeet"
                                      value={this.state.productLengthFeet}
                                      onChange={this.handleInputChange}
                                      onBlur={() =>
                                        this.calculateFigures(showProduct)
                                      }
                                      min="0"
                                    />
                                  </Col>
                                ) : null}
                                <Col xs="3" className="pr-0">
                                  {priceUnitTypeName === "Each"
                                    ? "Qty"
                                    : priceUnitTypeName}
                                  <Input
                                    className="form-control"
                                    type="number"
                                    style={{ marginTop: "0" }}
                                    value={this.state.productQuantity || 0}
                                    name="productQuantity"
                                    onChange={this.handleInputChange}
                                    placeholder="Qty"
                                    disabled={priceUnitTypeName !== "Each"}
                                    min="0"
                                  />
                                </Col>
                                {/* <Col xs="4" className="pr-0">
                              {priceUnitTypeName}
                            </Col> */}
                                <Col title="Add to your order" xs="2">
                                  <Button
                                    title={
                                      carpetPurchaseValid
                                        ? "Add to cart"
                                        : "Pick a color and provide quantities first"
                                    }
                                    onClick={() =>
                                      this.addToOrderCart(
                                        showProduct,
                                        this.state.productQuantity,
                                        this.state.productLengthFeet,
                                        this.state.selectedProductWidth
                                      )
                                    }
                                    className="float-right productButton btn-sm p-1 mt-4"
                                    disabled={!carpetPurchaseValid}
                                  >
                                    <FontAwesomeIcon
                                      icon="cart-plus"
                                      size="lg"
                                    />
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="mt-3">
                                <Col>
                                  <h5>
                                    <i className="fontSize85 align-self-end squarespaceText">
                                      Contact Distinctive directly to order this
                                      item.
                                    </i>
                                  </h5>
                                </Col>
                              </Row>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>{webProductDescription}</Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Table size="sm" bordered>
                      <tbody>
                        <tr>
                          <td width="45%">
                            <strong>
                              {title === params.hardSurface
                                ? "Material"
                                : "Fiber"}
                            </strong>
                          </td>
                          <td>{pileTypeName}</td>
                          {/* <td>{yarnBrandName}</td> */}
                        </tr>
                        <tr>
                          <td width="45%">
                            <strong>Construction Type</strong>
                          </td>
                          <td>{constructionTypeName}</td>
                        </tr>
                        {title === params.carpet && (
                          <Fragment>
                            <tr>
                              <td width="45%">
                                <strong>Widths</strong>
                              </td>
                              <td>
                                {/* {_.map(productWidths, (w) => w.widthName) || "n/a"} */}
                                {_.map(productWidths, (w, index) => {
                                  if (index + 1 === productWidths.length) {
                                    return w.widthName;
                                  } else {
                                    return w.widthName + ", ";
                                  }
                                }) || "n/a"}
                              </td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Pattern Match</strong>
                              </td>
                              <td>
                                {repeat
                                  ? repeat
                                  : patternMatch
                                  ? patternMatch
                                  : "n/a"}
                              </td>
                            </tr>
                          </Fragment>
                        )}
                        {title === params.hardSurface && (
                          <Fragment>
                            <tr>
                              <td width="45%">
                                <strong>Piece Measurements</strong>
                              </td>
                              <td>
                                {`${itemWidth}W x ${itemLength}L x ${thickness}H`}
                              </td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Pieces per Carton</strong>
                              </td>
                              <td>{perContainer}</td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Sq. Feet per Carton</strong>
                              </td>
                              <td>{squareFeetPerCarton}</td>
                            </tr>
                          </Fragment>
                        )}
                        <tr>
                          <td width="45%">
                            <strong>Basic Specs/Warranties</strong>
                          </td>
                          <td>{basicSpecs}</td>
                        </tr>
                        {showProduct.detailedSpecsPdf &&
                          showProduct.detailedSpecsPdf.url && (
                            <tr>
                              <td width="45%">
                                <strong>Detailed Specs</strong>
                              </td>
                              <td>
                                <a
                                  href={showProduct.detailedSpecsPdf.url}
                                  target="_blank"
                                  className="squarespaceText"
                                >
                                  PDF
                                  <FontAwesomeIcon
                                    icon="file-pdf"
                                    className="ml-2"
                                  />
                                </a>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  {cardDeckItems && cardDeckItems.length > 0
                    ? _.map(cardDeckItems, (crd, idx) => {
                        return (
                          <Col
                            key={`${crd.key}${idx}`}
                            xs="4"
                            className="px-1 mb-2"
                          >
                            <Card
                              className="borderNone"
                              onClick={() =>
                                this.selectColorOptionByPhoto(
                                  crd,
                                  id,
                                  crd.productColorId
                                )
                              }
                            >
                              <CardImg
                                top
                                width="100%"
                                src={getImageUrl(crd)}
                                alt={crd.alt}
                              />
                              <CardSubtitle tag="h6" className="p-1 text-muted">
                                {crd.title}
                              </CardSubtitle>
                            </Card>
                          </Col>
                        );
                      })
                    : null}
                </Row>
                <Row className="mb-1">
                  <Col>
                    <CopyToClipboard
                      text={shareLink}
                      onCopy={() => {
                        alert("Copied the text: " + shareLink);
                      }}
                    >
                      <h4 title={shareLink}>Link to this Product</h4>
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          {/*********** Product Detail View for Small Screens **************/}
          <Container className="mt-1 squarespaceContainer d-block d-lg-none">
            <this.SidebarSection />
            <Row className={sidebarOpen ? "mb-2 applyOpacity" : "mb-2"}>
              <Col>
                <Button
                  className="px-1 sqSidebarButtonOutline float-left minWidth70 "
                  size="sm"
                  onClick={this.goBackToList}
                >
                  <FontAwesomeIcon
                    icon="long-arrow-alt-left"
                    className="mr-1"
                  />
                  Back
                </Button>
              </Col>
              <Col>
                <Button
                  className="px-1 sqSidebarButton float-right minWidth70 mr-2"
                  onClick={this.toggleSidebar}
                  disabled={cartButtonDisabled}
                  sz="sm"
                >
                  <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
                  Cart
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Row>
                  <Col>
                    <h5 className="ml-2">{dcName}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className="ml-2 text-muted">
                      {activePhoto ? activePhoto.title : ""}{" "}
                      {/* used to display color? */}
                    </h6>
                  </Col>
                  {showPrices && (
                    <Col className="text-right mr-2">
                      <h6 className="p-0 m-0">
                        {showRugOptions ? (
                          "See price below"
                        ) : (
                          <div>
                            {formatPrice(unitPrice)} / {`${priceUnitTypeName}`}
                          </div>
                        )}
                      </h6>
                    </Col>
                  )}
                </Row>
                {showPrices ? null : (
                  <Row>
                    <Col>
                      <Button
                        className="productButton text-white px-2 py-2 mb-2"
                        tag={Link}
                        to={{
                          pathname: "/sq/sign_in_or_register",
                          state: {
                            successRedirect: findRedirectUrl(
                              this.props.location.pathname
                            ),
                          },
                        }}
                      >
                        Log in to View Prices & Request Samples
                      </Button>
                    </Col>
                  </Row>
                )}
                <Card className="mb-1 borderNone ">
                  <CardImg
                    top
                    src={getImageUrl(activePhoto)}
                    alt={activePhoto ? activePhoto.alt : ""}
                    style={{ width: "70%", margin: "auto" }}
                  />
                </Card>
                <Row className="mx-2">
                  {carouselItems && carouselItems.length > 0
                    ? _.map(carouselItems, (crd, idx) => {
                        return (
                          <Col
                            key={`general${crd.key}${idx}`}
                            xs={
                              carouselItems.length >= 3
                                ? "4"
                                : carouselItems.length == 2
                                ? "6"
                                : { size: 6, offset: 3 }
                            }
                            className="mb-2"
                          >
                            <Card
                              className="borderNone"
                              onClick={() => this.selectColorOptionByPhoto(crd)}
                            >
                              <CardImg
                                top
                                src={getImageUrl(crd)}
                                alt={crd.alt}
                              />
                              <CardSubtitle tag="h6" className="p-1 text-muted">
                                {crd.title}
                              </CardSubtitle>
                            </Card>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </Col>
              <Col xs="12">
                <Row className="mb-3">
                  <Col>
                    {showRugOptions ? (
                      <Fragment>
                        {atLeastOneMemoSize && showPrices && (
                          <Row className="mb-2">
                            <Col xs="6" className="pr-0">
                              {colorOptions.length > 0 ? (
                                <Select
                                  bsSize="sm"
                                  closeMenuOnSelect
                                  options={colorOptions}
                                  value={activeColor}
                                  onChange={(option) =>
                                    this.selectColorOption(option)
                                  }
                                />
                              ) : null}
                            </Col>
                            <Col xs="6" className="px-0">
                              <Button
                                title="Request a sample"
                                onClick={() =>
                                  this.addSampleToCart(showProduct)
                                }
                                className="float-right productButton btn-sm p-1 mr-3 "
                              >
                                Sample Request
                                <FontAwesomeIcon
                                  icon="cut"
                                  className="ml-1 mt-1"
                                />
                              </Button>
                            </Col>
                          </Row>
                        )}
                        <Row className="mb-1">
                          <Col xs="4" className="pr-0">
                            <Select
                              bsSize="sm"
                              closeMenuOnSelect
                              options={rugSizes}
                              value={selectedRugSize}
                              onChange={(option) =>
                                this.selectRugSize(option, showProduct)
                              }
                            />
                          </Col>
                          {showPrices && (
                            <Fragment>
                              <Col xs="3" className="pr-0">
                                {formatPrice(selectedRugPrice)} /{" "}
                                {`${priceUnitTypeName}`}
                              </Col>
                              <Col xs="3" className="px-1">
                                <Input
                                  className="form-control"
                                  type="number"
                                  style={{ marginTop: "0" }}
                                  value={this.state.productQuantity || 0}
                                  name="productQuantity"
                                  onChange={this.handleInputChange}
                                  placeholder="Qty"
                                  min="0"
                                />
                              </Col>
                              <Col
                                title="Add to your order"
                                className="px-1 pt-1 mr-2"
                              >
                                <Button
                                  title={
                                    rugPurchaseValid
                                      ? "Add to cart"
                                      : "Pick a size and quantity first"
                                  }
                                  onClick={() =>
                                    this.addToOrderCart(
                                      showProduct,
                                      this.state.productQuantity,
                                      null,
                                      null
                                    )
                                  }
                                  className="float-right productButton btn-sm p-1"
                                  disabled={!rugPurchaseValid}
                                >
                                  <FontAwesomeIcon icon="cart-plus" size="lg" />
                                </Button>
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Row className="mb-1">
                          {colorOptions.length > 0 ? (
                            <Col xs="6" className="pr-0">
                              Select Color
                              <Select
                                bsSize="sm"
                                closeMenuOnSelect
                                options={colorOptions}
                                value={activeColor}
                                onChange={(option) =>
                                  this.selectColorOption(option)
                                }
                              />
                            </Col>
                          ) : null}
                          {showPrices && (
                            <Fragment>
                              <Col xs="6" className="px-0">
                                <Button
                                  title={
                                    activeColor && activeColor.label
                                      ? "Request a sample"
                                      : "Pick a color first"
                                  }
                                  onClick={() =>
                                    this.addSampleToCart(showProduct)
                                  }
                                  className="float-right productButton btn-sm mt-3 mr-3 p-1"
                                  // cannot request until a color is selected
                                  disabled={!colorSelected}
                                >
                                  Sample Request
                                  <FontAwesomeIcon
                                    icon="cut"
                                    className="ml-1 mt-1"
                                  />
                                </Button>
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                        {showPrices && (
                          <Fragment>
                            {allowWebPurchasing ? (
                              <Row>
                                {this.state.productWidthOptions.length > 0 ? (
                                  <Col xs="3" className="pr-0">
                                    Width
                                    <Select
                                      bsSize="sm"
                                      closeMenuOnSelect
                                      options={this.state.productWidthOptions}
                                      value={this.state.selectedProductWidth}
                                      onChange={(option) =>
                                        this.selectProductWidth(option)
                                      }
                                    />
                                  </Col>
                                ) : null}
                                {priceUnitTypeName !== "Each" &&
                                showProduct.productTypeName !== params.wood ? (
                                  <Col xs="4">
                                    {_.includes(
                                      dcName,
                                      params.contractTileString
                                    ) ? (
                                      <Fragment>
                                        Sq ft. {helpers.requiredStar()}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        Length &#40;ft.&#41;{" "}
                                        {helpers.requiredStar()}
                                      </Fragment>
                                    )}
                                    <Input
                                      type="number"
                                      name="productLengthFeet"
                                      value={this.state.productLengthFeet}
                                      onChange={this.handleInputChange}
                                      onBlur={() =>
                                        this.calculateFigures(showProduct)
                                      }
                                      min="0"
                                    />
                                  </Col>
                                ) : null}
                                <Col xs="3" className="pr-0">
                                  {priceUnitTypeName === "Each"
                                    ? "Qty"
                                    : priceUnitTypeName}
                                  <Input
                                    className="form-control"
                                    type="number"
                                    style={{ marginTop: "0" }}
                                    value={this.state.productQuantity || 0}
                                    name="productQuantity"
                                    onChange={this.handleInputChange}
                                    placeholder="Qty"
                                    disabled={priceUnitTypeName !== "Each"}
                                    min="0"
                                  />
                                </Col>
                                {/* <Col xs="4" className="pr-0">
                              {priceUnitTypeName}
                            </Col> */}
                                <Col title="Add to your order" xs="2">
                                  <Button
                                    title={
                                      carpetPurchaseValid
                                        ? "Add to cart"
                                        : "Pick a color and provide quantities first"
                                    }
                                    onClick={() =>
                                      this.addToOrderCart(
                                        showProduct,
                                        this.state.productQuantity,
                                        this.state.productLengthFeet,
                                        this.state.selectedProductWidth
                                      )
                                    }
                                    className="float-right productButton btn-sm p-1 mt-4"
                                    disabled={!carpetPurchaseValid}
                                  >
                                    <FontAwesomeIcon
                                      icon="cart-plus"
                                      size="lg"
                                    />
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="mt-3">
                                <Col>
                                  <h5>
                                    <i className="fontSize85 align-self-end squarespaceText">
                                      Contact Distinctive directly to order this
                                      item.
                                    </i>
                                  </h5>
                                </Col>
                              </Row>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>{webProductDescription}</Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Table size="sm" bordered>
                      <tbody>
                        <tr>
                          <td width="45%">
                            <strong>
                              {title === params.hardSurface
                                ? "Material"
                                : "Fiber"}
                            </strong>
                          </td>
                          <td>{pileTypeName}</td>
                          {/* <td>{yarnBrandName}</td> */}
                        </tr>
                        <tr>
                          <td width="45%">
                            <strong>Construction Type</strong>
                          </td>
                          <td>{constructionTypeName}</td>
                        </tr>
                        {title === params.carpet && (
                          <Fragment>
                            <tr>
                              <td width="45%">
                                <strong>Widths</strong>
                              </td>
                              <td>
                                {/* {_.map(productWidths, (w) => w.widthName) || "n/a"} */}
                                {_.map(productWidths, (w, index) => {
                                  if (index + 1 === productWidths.length) {
                                    return w.widthName;
                                  } else {
                                    return w.widthName + ", ";
                                  }
                                }) || "n/a"}
                              </td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Pattern Match</strong>
                              </td>
                              <td>
                                {repeat
                                  ? repeat
                                  : patternMatch
                                  ? patternMatch
                                  : "n/a"}
                              </td>
                            </tr>
                          </Fragment>
                        )}
                        {title === params.hardSurface && (
                          <Fragment>
                            <tr>
                              <td width="45%">
                                <strong>Piece Measurements</strong>
                              </td>
                              <td>
                                {`${itemWidth}W x ${itemLength}L x ${thickness}H`}
                              </td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Pieces per Carton</strong>
                              </td>
                              <td>{perContainer}</td>
                            </tr>
                            <tr>
                              <td width="45%">
                                <strong>Sq. Feet per Carton</strong>
                              </td>
                              <td>{squareFeetPerCarton}</td>
                            </tr>
                          </Fragment>
                        )}
                        <tr>
                          <td width="45%">
                            <strong>Basic Specs/Warranties</strong>
                          </td>
                          <td>{basicSpecs}</td>
                        </tr>
                        {showProduct.detailedSpecsPdf &&
                          showProduct.detailedSpecsPdf.url && (
                            <tr>
                              <td width="45%">
                                <strong>Detailed Specs</strong>
                              </td>
                              <td>
                                <a
                                  href={showProduct.detailedSpecsPdf.url}
                                  target="_blank"
                                  className="squarespaceText"
                                >
                                  PDF
                                  <FontAwesomeIcon
                                    icon="file-pdf"
                                    className="ml-2"
                                  />
                                </a>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  {cardDeckItems && cardDeckItems.length > 0
                    ? _.map(cardDeckItems, (crd, idx) => {
                        return (
                          <Col
                            key={`${crd.key}${idx}`}
                            xs="4"
                            className="px-1 mb-2"
                          >
                            <Card
                              className="borderNone"
                              onClick={() =>
                                this.selectColorOptionByPhoto(
                                  crd,
                                  id,
                                  crd.productColorId
                                )
                              }
                            >
                              <CardImg
                                top
                                width="100%"
                                src={getImageUrl(crd)}
                                alt={crd.alt}
                              />
                              <CardSubtitle tag="h6" className="p-1 text-muted">
                                {crd.title}
                              </CardSubtitle>
                            </Card>
                          </Col>
                        );
                      })
                    : null}
                </Row>
                <Row className="mb-1">
                  <Col>
                    <CopyToClipboard
                      text={shareLink}
                      onCopy={() => {
                        alert("Copied the text: " + shareLink);
                      }}
                    >
                      <h4 title={shareLink}>Link to this Product</h4>
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
    if (this.state.useProductsDisplay) {
      return (
        <React.Fragment>
          {/*********** Products List View for Large Screens **************/}
          <Container
            className={classnames({
              "mt-3 d-none d-lg-block": true,
              squarespaceContainer: true,
            })}
          >
            {/* <this.SidebarSection /> */}
            {this.SidebarSection(false)}
            <Row
              className={
                sidebarOpen
                  ? "mb-0 filterSection applyOpacity"
                  : "mb-3 filterSection"
              }
            >
              <Col xs="3" className="m-0 p-0">
                <Row className="m-0 p-0">
                  <Col className="m-0 p-0">
                    <h6>
                      <i>
                        {title}
                        {section ? ` / ${section}` : null}
                      </i>
                    </h6>
                  </Col>
                </Row>
              </Col>
              <Col xs="9" className="m-0 p-0">
                <Row className="m-0 p-0">
                  <Col className="m-0 p-0">
                    <Pager {...this.state.pagedList} callBack={this.setPage} />
                  </Col>
                  <Col className="px-1" xs="3">
                    <FilterText
                      filterName="DCName"
                      value={this.currentFilterValue("DCName")}
                      onChangeCallback={this.filterChange}
                      placeholder={"Search..."}
                    />
                  </Col>
                  <Col xs="2" className="pr-1 pl-1">
                    {sortingDropDown}
                  </Col>
                  <Col className="maxWidth90 p-0 m-0">
                    <Button
                      className="px-1 sqSidebarButton float-right minWidth70"
                      onClick={this.toggleSidebar}
                      disabled={cartButtonDisabled}
                      sz="sm"
                    >
                      <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
                      Cart
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <FilterMultiSelect
                  filterName="ColorFamilyList"
                  displayName="Color Family"
                  options={colorFamilyList}
                  values={this.currentFilterValue("ColorFamilyList")}
                  onChangeCallback={this.filterChange}
                  clearValue={this.state.clearValue}
                />
              </Col>
              {showConstructionFilter && (
                <Col xs={4}>
                  {/* <FilterMultiSelect
                    filterName="PileTypeList"
                    displayName="Fiber"
                    options={pileTypeList}
                    values={this.currentFilterValue("PileTypeList")}
                    onChangeCallback={this.filterChange}
                    clearValue={this.state.clearValue}
                  /> */}
                  <FilterMultiSelect
                    filterName="FiberGroupList"
                    displayName="Fiber"
                    options={fiberGroupList}
                    values={this.currentFilterValue("FiberGroupList")}
                    onChangeCallback={this.filterChange}
                    clearValue={this.state.clearValue}
                  />
                </Col>
              )}
              {showConstructionFilter && (
                <Col xs={4}>
                  <FilterMultiSelect
                    filterName="ConstructionTypeList"
                    displayName="Construction"
                    options={constructionTypeList}
                    values={this.currentFilterValue("ConstructionTypeList")}
                    onChangeCallback={this.filterChange}
                    clearValue={this.state.clearValue}
                  />
                </Col>
              )}
            </Row>
            <Row className="productsSection m-0 p-0">
              <Col>
                <Row className="productsRow">
                  {thinking ? (
                    <div className="ml-5 mt-5">
                      <Spinner type="grow" color="secondary" className="mr-2" />{" "}
                      <div className="squarespaceText">
                        Loading your results...
                      </div>
                    </div>
                  ) : (
                    imagesMap
                  )}
                </Row>
              </Col>
            </Row>
            {thinking ? null : (
              <Row>
                <Col className="m-0 p-0">
                  <Pager {...this.state.pagedList} callBack={this.setPage} />
                </Col>
              </Row>
            )}
          </Container>
          {/*********** Products List View for Small Screens **************/}
          <Container
            className={classnames({
              "mt-3 d-block d-lg-none": true,
              squarespaceContainer: true,
            })}
          >
            {/* <this.SidebarSection forSmallScreen={true} /> */}
            {this.SidebarSection(true)}
            <Row
              className={
                sidebarOpen
                  ? "mb-0 filterSection applyOpacity"
                  : "mb-3 filterSection"
              }
            >
              <Col className="mx-2 my-0 p-0">
                <Row className="m-0 p-0 mb-2">
                  <Col>
                    <FilterText
                      filterName="DCName"
                      value={this.currentFilterValue("DCName")}
                      onChangeCallback={this.filterChange}
                      placeholder={"Search..."}
                    />
                  </Col>
                  <Col className="maxWidth90 p-0 m-0">
                    <Button
                      className="px-1 sqSidebarButton float-right minWidth70"
                      onClick={this.toggleSidebar}
                      disabled={cartButtonDisabled}
                      sz="sm"
                    >
                      <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
                      Cart
                    </Button>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col xs={"12"}>
                    <MobileFilterSet
                      filters={this.state.filters}
                      clearFilters={this.clearFilters}
                      open={this.state.mobileFilterCollapse}
                      toggleMobileFilters={this.toggleMobileFilters}
                      hideMobileFilters={this.hideMobileFilters}
                    >
                      <Row className="m-2 p-0">
                        <Col>
                          <FilterMultiSelect
                            filterName="ColorFamilyList"
                            displayName="Color Family"
                            options={colorFamilyList}
                            values={this.currentFilterValue("ColorFamilyList")}
                            onChangeCallback={this.filterChange}
                            clearValue={this.state.clearValue}
                          />
                        </Col>
                      </Row>
                      {showConstructionFilter && (
                        <Row className="m-2 p-0">
                          <Col>
                            {/* <FilterMultiSelect
                              filterName="PileTypeList"
                              displayName="Fiber"
                              options={pileTypeList}
                              values={this.currentFilterValue("PileTypeList")}
                              onChangeCallback={this.filterChange}
                              clearValue={this.state.clearValue}
                            /> */}
                            <FilterMultiSelect
                              filterName="FiberGroupList"
                              displayName="Fiber"
                              options={fiberGroupList}
                              values={this.currentFilterValue("FiberGroupList")}
                              onChangeCallback={this.filterChange}
                              clearValue={this.state.clearValue}
                            />
                          </Col>
                        </Row>
                      )}
                      {showConstructionFilter && (
                        <Row className="m-2 p-0">
                          <Col>
                            <FilterMultiSelect
                              filterName="ConstructionTypeList"
                              displayName="Construction"
                              options={constructionTypeList}
                              values={this.currentFilterValue(
                                "ConstructionTypeList"
                              )}
                              onChangeCallback={this.filterChange}
                              clearValue={this.state.clearValue}
                            />
                          </Col>
                        </Row>
                      )}
                    </MobileFilterSet>
                  </Col>
                  {/* {this.state.mobileFilterCollapse
                    ? null
                    :  */}
                  <Col xs={"12"}>
                    <div
                      onClick={() =>
                        this.setState({ showSortingDropDown: true })
                      }
                    >
                      Sort By
                      {sortingDropDown}
                    </div>
                  </Col>
                  {/* } */}
                </Row>
              </Col>
            </Row>
            <Row className="productsSection m-0 p-0">
              <Col>
                <Row>
                  {thinking ? (
                    <div className="ml-5 mt-5">
                      <Spinner type="grow" color="secondary" className="mr-2" />{" "}
                      <div className="squarespaceText">
                        Loading your results...
                      </div>
                    </div>
                  ) : (
                    imagesMap
                  )}
                </Row>
              </Col>
            </Row>
            {thinking ? null : (
              <Row>
                <Col></Col>
                <Col xs="7" sm="5" className="m-0 p-0">
                  <Pager
                    {...this.state.pagedList}
                    callBack={this.setPage}
                    smallScreen={true}
                  />
                </Col>
                <Col></Col>
              </Row>
            )}
          </Container>
        </React.Fragment>
      );
    }
    if (this.state.useCartDisplay) {
      return <this.CartDisplay />;
    }
    return (
      <div className="ml-5 mt-5">
        <Spinner type="grow" color="secondary" className="ml-5 mt-5 mr-2" />{" "}
        <div className="squarespaceText">Loading ...</div>
      </div>
    );
  }
}
