import React, { Component, Fragment } from "react";
import api from '../utils/api';
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import ToggleButton from 'react-toggle-button';

import helpers from '../utils/helpers';
import {
  Alert,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Card,
  CardBody,
  CardDeck,
  Input,
  Label
} from "reactstrap";

const emptyPhone = {
  id: 0,
  number: '',
  typeOfPhone: null,
  isPrimary: false,
  editing: true
};

const emptyAddress = {
  id: 0,
  ln1: '',
  ln2: '',
  ln3: '',
  ln4: '',
  city: '',
  state: '',
  zip: '',
  typeOfAddress: null,
  isPrimary: false,
  editing: true
};

export default class AgentContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      isMessageVisible: false,
      agentId: props.agentId,
      addressTypeList: [],
      phoneTypeList: [],
      addressList: [],
      phoneList: [],
      pristineAddress: null,
      pristinePhone: null,
      editingAddress: false,
      editingPhone: false 
    };
    this.loadContacts = this.loadContacts.bind(this);
    this.addressValid = this.addressValid.bind(this);
    this.phoneValid = this.phoneValid.bind(this);
    this.onSaveContact = this.onSaveContact.bind(this);
    this.onAddressCancel = this.onAddressCancel.bind(this);
    this.onDeleteAddress = this.onDeleteAddress.bind(this);
    this.onDeletePhone = this.onDeletePhone.bind(this);
    this.onAddressTextChange = this.onAddressTextChange.bind(this);
    this.onPhoneTextChange = this.onPhoneTextChange.bind(this);
    this.onAddressEdit = this.onAddressEdit.bind(this);
    this.onPhoneEdit = this.onPhoneEdit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    const getPhoneType = api.fetch('Reference/GetPhoneTypeList')
      .then((result) => {
        return { phoneTypeList: result.data  };
      }).catch(helpers.catchHandler);

    const getAddressType = api.fetch('Reference/GetAddressTypeList')
      .then((result) => {
        return{ addressTypeList: result.data };
      }).catch(helpers.catchHandler);
    
      Promise.all([
        getPhoneType,
        getAddressType
      ])
      .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus, () => {
          this.loadContacts();
        });
      })
      .catch(helpers.catchHandler);
  }

  onDismiss() {
    this.setState({
      message: null,
      messageFlavor: null,
      isMessageVisible: false
    });
  }

  loadContacts() {
    api.fetch(`Agent/GetAgentContact/${this.state.agentId}`)
      .then(response => {
        if (response.data) {
          this.setState({
            addressList:
              _.map(
                response.data.addresses, a => {
                  a.typeOfAddress = _.find(this.state.addressTypeList, t => { return t.value === a.addressTypeId; });
                  a.editing = false;
                  return a;
                }),
            phoneList:
              _.map(
                response.data.phoneNumbers, p => {
                  p.typeOfPhone = _.find(this.state.phoneTypeList, t => { return t.value === p.phoneTypeId; });
                  p.editing = false;
                  return p;
              }),
            pristineAddress: null,
            pristinePhone: null,
            editingAddress: false,
            editingPhone: false
          })
        }
      }).catch(helpers.catchHandler);

  }

  onAddNewAddress() {
    let list = this.state.addressList.slice();
    list.unshift(emptyAddress)
    this.setState({addressList: list, editingAddress: true});
  }

  onDeleteAddress(index) {
    let list = this.state.addressList.slice();
    if (list[index].id > 0) {
      api.delete(`Agent/DeleteAgentAddress/${list[index].id}`).then(response => {
        if (response.data.success) {
          this.loadContacts();
        } else {
          this.setState({
            message: "An error occurred, address could not be deleted.",
            messageFlavor: "danger",
            isMessageVisible: true
          });
        }
      });
    } else {
      list = _.reject(list, (a, indx) => { return indx === index });
      this.setState({
        addressList: list,
        message: "Address saved.",
        messageFlavor: "success",
        isMessageVisible: true
      });
    }
  }

  onAddressTextChange(e, index, fieldName) {
    let list = this.state.addressList.slice();
    list[index][fieldName] = e.target.value;
    this.setState({ addressList: list });
  }

  onAddressCancel(index) {
    let list = this.state.addressList.slice();
    if (list[index].id > 0) {
      list[index] = this.state.pristineAddress;
      list[index].editing = false
    } else {
      list = _.reject(list, (a, indx) => { return indx === index;})
    }
    this.setState({ addressList: list, pristineAddress: null, editingAddress: false });
  }

  addressValid(address) {
    let warnings = [];

    if (address.ln1 === "") {
      warnings.push("Please enter company name")
    }
    //if (address.ln2 === "") {
    //  warnings.push("Please enter Attention")
    //}
    if (address.ln3 === "") {
      warnings.push("Please enter Address Line 1")
    }
    //if (address.ln4 === "") {
    //  warnings.push("Please enter Address Line 2")
    //}
    if (address.city === "") {
      warnings.push("Please enter City")
    }
    if (address.state === "") {
      warnings.push("Please enter State")
    }
    if (address.zip === "") {
      warnings.push("Please enter Zip")
    }
    if (!address.typeOfAddress) {
      warnings.push("Address type is required")
    }
    const agentHasExistingPrimaryAddress = _.find(this.state.addressList, a => { return a.isPrimary === true && a.id !== address.id})
    if (address.isPrimary && agentHasExistingPrimaryAddress !== undefined) {
      warnings.push("A primary address already exist.")
    }

    if (warnings.length > 0) {
      this.setState({ message: warnings.join(", "), messageFlavor: "danger", isMessageVisible: true });
      return false;
    }
    return true;
  }

  onSaveAddress(index) {
    let list = this.state.addressList.slice();
    let address = list[index];
    if (!this.addressValid(address)) return;

    const addressToSave = {
      Id: address.id,
      Ln1: address.ln1 || "",
      Ln2: address.ln2 || "",
      Ln3: address.ln3 || "",
      Ln4: address.ln4 || "",
      City: address.city || "",
      State: address.state || "",
      Zip: address.zip || "",
      AddressTypeId: address.typeOfAddress ? parseInt(address.typeOfAddress.value) : null,
      IsPrimary: address.isPrimary,
    }

    this.onSaveContact([addressToSave], null);
  }

  onSaveContact(addressList, phoneList) {
    const payload = {
      AgentId: this.state.agentId,
      Addresses: addressList,
      PhoneNumbers: phoneList
    }

    api.post("Agent/SaveAgentContact", payload)
      .then(response => {
        if (response && response.data.success) {
          this.setState({
            message: "Contact information was saved",
            messageFlavor: "success",
            isMessageVisible: true
          }, () => this.loadContacts());
        } else {
          this.setState({
            message: "An error occurred, address cannot be saved at this time.",
            messageFlavor: "danger",
            isMessageVisible: true
          });

        }
      }).catch(helpers.catchHandler);
  }

  onAddressEdit(index) {
    let list = this.state.addressList.slice();
    const pristine = Object.assign({}, list[index]);
    list[index].editing = true;
    this.setState({ addressList: list, pristineAddress: pristine, editingAddress: true });
  }

  onSelectAddressTypeChange(selection, index, fieldName) {
    let list = this.state.addressList.slice();
    list[index][fieldName] = selection;
    this.setState({ addressList: list });
  }

  onAddressPrimaryChange(e, index) {
    let list = this.state.addressList.slice();
    list[index].isPrimary = !list[index].isPrimary;
    this.setState({ addressList: list });

  }

  onAddNewPhone() {
    let list = this.state.phoneList.slice();
    list.unshift(emptyPhone)
    this.setState({ phoneList: list, editingPhone: true });
  }

  onDeletePhone(index) {
    let list = this.state.phoneList.slice();
    if (list[index].id > 0) {
      api.delete(`Agent/DeleteAgentPhone/${list[index].id}`).then(response => {
        if (response.data.success) {
          this.loadContacts();
        } else {
          this.setState({
            message: "An error occurred, phone cannot be deleted at this time.",
            messageFlavor: "danger",
            isMessageVisible: true
          });
        }
      }).catch(helpers.catchHandler);
    } else {
      list = _.reject(list, (p, indx) => { return indx === index });
      this.setState({
        phoneList: list,
        message: "Phone Number saved.",
        messageFlavor: "success",
        isMessageVisible: true
      });
    }
  }

  onPhoneEdit(index) {
    let list = this.state.phoneList.slice();
    const pristine = Object.assign({}, list[index]);
    list[index].editing = true;
    this.setState({ phoneList: list, pristinePhone: pristine, editingPhone: true });
  }

  onPhoneCancel(index) {
    let list = this.state.phoneList.slice();
    if (list[index].id > 0) {
      list[index] = this.state.pristinePhone;
      list[index].editing = false
    } else {
      list = _.reject(list, (a, indx) => { return indx === index; })
    }
    this.setState({ phoneList: list, pristineAddress: null, editingPhone: false });
  }

  onSelectPhoneTypeChange(selection, index, fieldName) {
    let list = this.state.phoneList.slice();
    list[index][fieldName] = selection;
    this.setState({ phoneList: list });
  }

  onPhoneTextChange(e, index, fieldName) {
    let list = this.state.phoneList.slice();
    list[index][fieldName] = e.target.value;
    this.setState({ phoneList: list });
  }

  onPhonePrimaryChange(e, index) {
    let list = this.state.phoneList.slice();
    list[index].isPrimary = !list[index].isPrimary;
    this.setState({ phoneList: list });
  }

  phoneValid(phone) {
    let warnings = [];

    if (phone.number === "") {
      warnings.push("Please enter a phone number")
    }
    if (!phone.typeOfPhone) {
      warnings.push("Phone type is required")
    }
    const agentHasExistingPrimaryPhone = _.find(this.state.phoneList, p => { return p.isPrimary === true && p.id !== phone.id })
    if (phone.isPrimary && agentHasExistingPrimaryPhone !== undefined) {
      warnings.push("A primary phone number already exist.")
    }

    if (warnings.length > 0) {
      this.setState({ message: warnings.join(", "), messageFlavor: "danger", isMessageVisible: true });
      return false;
    }
    return true;
  }

  onSavePhone(index) {
    let list = this.state.phoneList.slice();
    let phone = list[index];
    if (!this.phoneValid(phone)) return;

    const phoneToSave = {
      Id: phone.id,
      Number: phone.number,
      PhoneTypeId: phone.typeOfPhone ? phone.typeOfPhone.value : null,
      IsPrimary: phone.isPrimary,
    }
    this.onSaveContact(null, [phoneToSave]);
  }

  render() {
    return (
      <div>
        {this.state.message &&
          <Row>
          <Col><Alert color={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>{ this.state.message}</Alert></Col>
          </Row>
        }
        <CardDeck>
          <Card className="p-2">
            <Row>
              <Col><h4>Addresses</h4></Col>
              <Col><Button size="sm" className="float-right success" onClick={() => this.onAddNewAddress()} disabled={this.state.editingAddress}>Add</Button></Col>
            </Row>

            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>Type</th>
                    <th>IsPrimary?</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.addressList && this.state.addressList.length > 0 ?
                    this.state.addressList.map((address, index) => (
                      <tr key={index}>
                        {address.editing ? (
                          <Fragment>
                            <td colSpan="3">
                              <div ><Input type="text" maxLength="100" value={address.ln1} onChange={(e) => this.onAddressTextChange(e, index, "ln1")} maxLength={100}  placeholder="Company Name"/ ></div>
                              <div className="mt-2"><Input type="text" maxLength="100" value={address.ln2 } onChange={(e) => this.onAddressTextChange(e, index, "ln2")} maxLength={100}  placeholder="Attention"/></div>
                              <div className="mt-2"><Input type="text" maxLength="100" value={address.ln3}  onChange={(e) => this.onAddressTextChange(e, index, "ln3")} maxLength={100} placeholder="Address Line 1"/></div>
                              <div className="mt-2"><Input type="text" maxLength="100" value={address.ln4}  onChange={(e) => this.onAddressTextChange(e, index, "ln4")} maxLength={100}  placeholder="Address Line 2"/></div>
                              <Row className="mt-2">
                                <Col><Input type="text" maxLength="35" value={address.city} onChange={(e) => this.onAddressTextChange(e, index, "city")} maxLength={35} placeholder="City" /></Col>
                                <Col><Input type="text" maxLength="5" value={address.state} onChange={(e) => this.onAddressTextChange(e, index, "state")} maxLength={5}  placeholder="State" /></Col>
                                <Col><Input type="text" maxLength="10" value={address.zip} onChange={(e) => this.onAddressTextChange(e, index, "zip")} maxLength={10}  placeholder="Zip" /></Col>
                              </Row>
                              <div className="mt-2">
                                <Select
                                  options={this.state.addressTypeList}
                                  value={address.typeOfAddress}
                                  onChange={(e) => this.onSelectAddressTypeChange(e, index, "typeOfAddress")}
                                />
                              </div>
                              <div>
                                <Label>Is Primary?</Label>
                              <ToggleButton
                                  value={address.isPrimary}
                                  activeLabel={"Yes"}
                                  inactiveLabel={"No"}
                                  onToggle={(e) => this.onAddressPrimaryChange(e, index)}
                                />
                              </div>
                            </td>
                            <td>
                              <ButtonGroup className="float-right">
                                <Button size="sm" className="primary" onClick={()=>this.onSaveAddress(index)}><FontAwesomeIcon icon="save" /></Button>
                                <Button size="sm" className="secondary" onClick={()=>this.onAddressCancel(index) }><FontAwesomeIcon icon="reply" /></Button>
                              </ButtonGroup>
                            </td>
                          </Fragment>
                        ) : (
                        <Fragment>
                          <td>
                            <div>{address.ln1}</div>
                            <div>{address.ln2}</div>
                            <div>{address.ln3}</div>
                            <div>{address.ln4}</div>
                            <div>
                              <span>{address.city} {address.state} {address.zip}</span>
                            </div>
                          </td>
                              <td>{address.typeOfAddress.label}</td>
                          <td>{address.isPrimary && <FontAwesomeIcon className="text-success" icon="check" />}</td>
                          <td>
                            <ButtonGroup className="float-right">
                              <Button size="sm" className="primary" onClick={() => this.onAddressEdit(index)} disabled={this.state.editingAddress}><FontAwesomeIcon icon="edit" /></Button>
                              <Button size="sm" className="danger" onClick={() => this.onDeleteAddress(index)} disabled={this.state.editingAddress}><FontAwesomeIcon icon="trash" /></Button>
                            </ButtonGroup>
                          </td>
                        </Fragment>)}
                      </tr>
                    )) : null}
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <Card className="p-2">
            <Row>
              <Col><h4>Phone Numbers</h4></Col>
              <Col><Button size="sm" className="float-right success" onClick={() => this.onAddNewPhone()} disabled={this.state.editingPhone}>Add</Button></Col>
            </Row>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th width={"160px"}>Number</th>
                    <th width={"160px" }>Type</th>
                    <th>Is Primary</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.phoneList && this.state.phoneList.length > 0 ?
                    this.state.phoneList.map((phone, index) => (
                      <tr key={`phone-${index}`}>
                        {phone.editing ? (
                        <Fragment>
                            <td><Input type="text" maxLength="20" value={phone.number} onChange={(e) => this.onPhoneTextChange(e, index, "number")} maxLength={100} placeholder="(xxx)xxx-xxxx" /></td>
                            <td>
                              <Select
                                options={this.state.phoneTypeList}
                                value={phone.typeOfPhone}
                                onChange={(e) => this.onSelectPhoneTypeChange(e, index, "typeOfPhone")}
                              />
                            </td>
                            <td>
                              <ToggleButton
                                value={phone.isPrimary}
                                activeLabel={"Yes"}
                                inactiveLabel={"No"}
                                onToggle={(e) => this.onPhonePrimaryChange(e, index)}
                              />
                            </td>
                          <td>
                            <ButtonGroup className="float-right">
                              <Button size="sm" className="primary" onClick={() => this.onSavePhone(index)}><FontAwesomeIcon icon="save" /></Button>
                              <Button size="sm" className="secondary" onClick={() => this.onPhoneCancel(index)}><FontAwesomeIcon icon="reply" /></Button>
                            </ButtonGroup>
                          </td>
                        </Fragment>
                    ) : (
                        <Fragment>
                          <td>{phone.number}</td>
                          <td>{phone.typeOfPhone ? phone.typeOfPhone.label : null}</td>
                          <td>{phone.isPrimary ? <FontAwesomeIcon icon="check" className="text-success"/> : null}</td>
                          <td>
                            <ButtonGroup className="float-right">
                              <Button size="sm" className="primary" onClick={() => this.onPhoneEdit(index)} disabled={this.state.editingPhone}><FontAwesomeIcon icon="edit" /></Button>
                              <Button size="sm" className="danger" onClick={() => this.onDeletePhone(index)} disabled={this.state.editingPhone}><FontAwesomeIcon icon="trash" /></Button>
                            </ButtonGroup>
                        </td>
                          </Fragment>
                        )}
                    </tr>
                  )) : null}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </CardDeck>
      </div>
    )
  }
}