import React, { Fragment } from 'react';
import {
    Card,
    Row,
    Col,
    Container,
    CardBody,
    Alert,
    Button,
    ButtonGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Table
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import {
    api,
    helpers,
    date_helpers,
    constants
} from '../utils';
import {
    DropzoneUploader,
    CustomerTypeahead
} from '../components';
import CustomerAdmin from './CustomerAdmin';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import moment from 'moment';

const emptyClaim = {
  id: null,
  claimDate: new Date(),
  customer: null,
  claimStatus: {value: 1, label: 'Open'},
  supplierOriginalInvoiceDate: null,
  supplierReplacementInvoiceDate: null,
  customerContact: '',
  originalOrderId: '',
  replacementOrderId: '',
  creditOrderId: '',
  originalProduct: '',
  originalColor: '',
  originalSize: '',
  originalInvoiceAmount: '',
  replacementProduct: '',
  replacementColor: '',
  replacementSize: '',
  replacementInvoiceAmount: '',
  replacementFreightCarrier: '',
  replacementFreightInvoice: '',
  replacementFreightAmount: '',
  supplierCreditNumber: '',
  supplierCreditReceived: '',
  totalCreditDue: '',
  freightCreditNumber: '',
  freightCreditReceived: '',
  totalCreditReceived: '',
  supplierClaimNumber: '',
  supplierOriginalInvoice: '',
  supplierReplacementInvoice: '',
  complaintText: ''
};

const emptyComment = {
  id: 0,
  dsc: "",
  createdAt: new Date()
};

const FINISHED_RUG_SIZES = constants.FINISHED_RUG_SIZES;

export default class EditClaim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          routeClaimId: props.location.state && props.location.state.claimId
            ? props.location.state.claimId
            : null,
          claim: emptyClaim,
          // supplier: {
          //     accountNumber: "",
          //     contact: emptyContact
          // },
          addingImage: false,
          files: [],
          claimAttachments: [],
          isMessageVisible: false,
          message: null,
          messageFlavor: null,
          showCustomerSearchModal: false,
          supplierList: [],
          comments: [],
          showConfirmModal: false,
          currentUserName: props.currentUser.firstName + ' ' + props.currentUser.lastName
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.updateCurrencyField = this.updateCurrencyField.bind(this);
        this.onSupplierChange = this.onSupplierChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.saveClaim = this.saveClaim.bind(this);
        this.toggleImages = this.toggleImages.bind(this);
        this.getClaimAttachments = this.getClaimAttachments.bind(this);
        this.onToggleCustomerSearchModal = this.onToggleCustomerSearchModal.bind(this);
        this.onCustomerSelectedFromSearchModal = this.onCustomerSelectedFromSearchModal.bind(this);
    }

    componentDidMount() {
      const getClaimStatuses = api.fetch("Reference/GetClaimStatusList")
      .then(response => {
        return { claimStatusList: response.data };
      })
      .catch(helpers.catchHandler);

      const getSuppliers = api.fetch('Reference/GetSupplierList')
      .then((result) => {
        return { supplierList: result.data };
      })
      .catch(helpers.catchHandler);

      Promise.all([
          getClaimStatuses,
          getSuppliers
      ])
          .then((aggregateResults) => {
              const newStatus = {};
              aggregateResults.forEach(r => Object.assign(newStatus, r));
              this.setState(newStatus, () =>
                  this.loadClaim());
          })
          .catch(helpers.catchHandler);
    }

    loadClaim(newClaimId) {
      let claimId = newClaimId ? newClaimId : this.props.computedMatch.params.id;
      if (claimId === "new") {
        this.setState({ claim: emptyClaim });
      } else if (claimId > 0) {
        api.fetch(`Claim/GetClaim/${claimId}`)
        .then(response => {
          if (response.data) {
            const claim = {
              ...response.data.claim,
              customer: helpers.resolveValue(
                null,
                response.data.claim.customerId,
                response.data.claim.customerName),
              customerContact: helpers.nullableString(response.data.claim.customerContact),
              claimStatus: helpers.resolveValue(
                null,
                response.data.claim.typeOfClaimStatus,
                response.data.claim.typeOfClaimStatusName),
              originalOrderId: helpers.nullableString(response.data.claim.originalOrderId),
              replacementOrderId: helpers.nullableString(response.data.claim.replacementOrderId),
              creditOrderId: helpers.nullableString(response.data.claim.creditOrderId),
              originalProduct: helpers.nullableString(response.data.claim.originalProduct),
              originalColor: helpers.nullableString(response.data.claim.originalColor),
              originalSize: helpers.nullableString(response.data.claim.originalSize),
              originalInvoiceAmount: _.round(response.data.claim.originalInvoiceAmount, 2).toFixed(2),
              replacementProduct: helpers.nullableString(response.data.claim.replacementProduct),
              replacementColor: helpers.nullableString(response.data.claim.replacementColor),
              replacementSize: helpers.nullableString(response.data.claim.replacementSize),
              replacementInvoiceAmount: _.round(response.data.claim.replacementInvoiceAmount, 2).toFixed(2),
              replacementFreightCarrier: helpers.nullableString(response.data.claim.replacementFreightCarrier),
              replacementFreightInvoice: helpers.nullableString(response.data.claim.replacementFreightInvoice),
              replacementFreightAmount: _.round(response.data.claim.replacementFreightAmount, 2).toFixed(2),
              supplier: helpers.resolveValue(
                null,
                response.data.claim.supplierId,
                response.data.claim.supplierName),
              supplierCreditNumber: helpers.nullableString(response.data.claim.supplierCreditNumber),
              supplierCreditReceived: _.round(response.data.claim.supplierCreditReceived, 2).toFixed(2),
              totalCreditDue: _.round(response.data.claim.totalCreditDue, 2).toFixed(2),
              freightCreditNumber: helpers.nullableString(response.data.claim.freightCreditNumber),
              freightCreditReceived: _.round(response.data.claim.freightCreditReceived, 2).toFixed(2),
              totalCreditReceived: _.round(response.data.claim.totalCreditReceived, 2).toFixed(2),
              supplierClaimNumber: helpers.nullableString(response.data.claim.supplierClaimNumber),
              supplierOriginalInvoice: helpers.nullableString(response.data.claim.supplierOriginalInvoice),
              supplierReplacementInvoice: helpers.nullableString(response.data.claim.supplierReplacementInvoice),
              complaintText: helpers.nullableString(response.data.claim.complaintText)
            }
            const claimComments = _.map(response.data.claimComments, (item, index) => {
              return {
                id: item.id,
                dsc: item.dsc,
                local_id: -1 * (index + 1),
                createdAt: item.createdAt,
                createdByUserName: item.createdByUserName
              }
            });
            this.setState({
              claim: claim,
              comments: claimComments,
              claimAttachments: response.data.claimAttachments
            }, () => {
              this.getCustomer(claim.customerId)
            });
          }
        }).catch(helpers.catchHandler);
      }
    }

    onDateChange(name, date) {
      this.setState({ claim: { ...this.state.claim, [name]: date } });
    }

    onInputChange(fieldName, value) {
        this.setState(prevState => ({
            ...prevState,
            claim: {
                ...prevState.claim,
                [fieldName]: value
            }
        }));
    }

    onSelectChange(selection, fieldName) {
      this.setState({
        claim: {
          ...this.state.claim,
          [fieldName]: selection
        }
      });
    }

    updateCurrencyField(fieldName, value) {
        if (value) {
            this.setState(prevState => ({
                ...prevState,
                claim: {
                    ...prevState.claim,
                    [fieldName]: _.round(value, 2).toFixed(2)  // parseFloat(value).toFixed(2)
                }
            }));
        }
    }

    onSupplierChange(selection) {
      api.fetch(`Supplier/GetSupplier/${selection.value}`)
      .then(response => {
        const supplierClaimsContact = _.find(response.data.contacts, (c) => c.typeOfContactName === 'Claims' && c.deactivatedAt == null);
        this.setState({
          supplier: response.data.supplier,
          claim: {
            ...this.state.claim,
            supplier: {
                value: selection.value,
                label: selection.label
            },
            supplierContactName: supplierClaimsContact ? `${supplierClaimsContact.firstName} ${supplierClaimsContact.lastName}` : null,
            supplierContactEmail: supplierClaimsContact ? supplierClaimsContact.email : null
          } 
        });
      })
      .catch(helpers.catchHandler);
    }

    removeClaimAttachment(attachment) {
        if (!window.confirm('This is a permanent delete.  Are you sure?')) return;
        const attachments = this.state.claimAttachments.slice();
        const filtered = _.filter(attachments, x => x.id !== attachment.id);

        api
            .post(`Claim/DeleteClaimAttachment/${attachment.id}`)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        claimAttachments: filtered
                    });
                } else {
                  this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
                }
            })
            .catch(helpers.catchHandler);
        return null;
    }


    onCancel() {
        this.setState({ redirect: true });
    }

    toggleImages() {
        this.setState({
            addingImage: !this.state.addingImage
        });
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.claim.claimDate) {
            warnings.push("Claim Date required.");
        }
        if (!this.state.claim.customer.value) {
            warnings.push("Account required.");
        }
        if (!this.state.claim.claimStatus.value) {
            warnings.push("Claim Status required.");
        }
        if (!this.state.claim.originalOrderId) {
            warnings.push("Original Invoice required.");
        }
        // if (!this.state.product.unitCost && this.state.product.unitCost != "0") {
        //     warnings.push("Net Cut Cost required.");
        // }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger",
                isMessageVisible: true
            });
        } else {
          this.setState({ message: null, isMessageVisible: false });
        }
        return warnings.length === 0;
    }

    formatClaimPayload(claim) {
        const payload = {
            Id: claim.id ? claim.id : 0,
            ClaimDate: date_helpers.formatDateForServer(claim.claimDate),
            CustomerId: claim.customer.value,
            CustomerContact: claim.customerContact,
            TypeOfClaimStatus: claim.claimStatus.value,
            OriginalOrderId: Number(claim.originalOrderId),
            ReplacementOrderId: claim.replacementOrderId ? Number(claim.replacementOrderId) : null,
            CreditOrderId: claim.creditOrderId ? Number(claim.creditOrderId) : null,  
            OriginalProduct: claim.originalProduct,
            OriginalColor: claim.originalColor,
            OriginalSize: claim.originalSize,
            OriginalInvoiceAmount: Number(claim.originalInvoiceAmount),
            ReplacementColor: claim.replacementColor,
            ReplacementProduct: claim.replacementProduct,
            ReplacementSize: claim.replacementSize,
            ReplacementInvoiceAmount: Number(claim.replacementInvoiceAmount),
            ReplacementFreightCarrier: claim.replacementFreightCarrier,
            ReplacementFreightInvoice: claim.replacementFreightInvoice,
            ReplacementFreightAmount: Number(claim.replacementFreightAmount),
            SupplierCreditNumber: claim.supplierCreditNumber,
            SupplierCreditReceived: Number(claim.supplierCreditReceived),
            TotalCreditDue: Number(claim.totalCreditDue),
            FreightCreditNumber: claim.freightCreditNumber,
            FreightCreditReceived: Number(claim.freightCreditReceived),
            TotalCreditReceived: Number(claim.totalCreditReceived),
            SupplierId: claim.supplier.value,
            SupplierClaimNumber: claim.supplierClaimNumber,
            SupplierOriginalInvoice: claim.supplierOriginalInvoice,
            SupplierOriginalInvoiceDate: date_helpers.formatDateForServer(claim.supplierOriginalInvoiceDate),
            SupplierReplacementInvoice: claim.supplierReplacementInvoice,
            SupplierReplacementInvoiceDate: date_helpers.formatDateForServer(claim.supplierReplacementInvoiceDate),
            ComplaintText: claim.complaintText
        };
        return payload;
    }

    formatClaimAttachmentsPayload(files) {
        const formatted = [];
        _.map(files,
            x => {
                const file = {
                    Id: 0,
                    ClaimAttachmentTypeId: 1,
                    ClaimId: this.state.claim.id ? this.state.claim.id : 0,
                    Base64EncodedPhoto: x.base64EncodedPhoto,
                    MimeType: x.type,
                    AssetName: x.name,
                    // Content: []
                }
                formatted.push(file);
            });
        return formatted;
    }

    saveClaim() {
        window.scroll(0,0);
        if (!this.isFormValid()) return;
        const claim = this.state.claim;
        const Claim = this.formatClaimPayload(claim);
        const ClaimComments = _.map(this.state.comments, (c) => ({
          id: c.id ? c.id : 0,
          dsc: c.dsc,
        }))
        const ClaimAttachments = this.state.addingImage ? this.formatClaimAttachmentsPayload(this.state.files) : [];
        const payload = {
            Claim: Claim,
            ClaimComments: ClaimComments,
            ClaimAttachments: ClaimAttachments
        };
        api.post('Claim/SaveClaim', payload)
        .then(response => {
            if (response.data.success) {
                const claimAttachments = this.state.claimAttachments.slice();
                const newList = _.concat(claimAttachments, ClaimAttachments);
                this.setState({
                    claimAttachments: newList,
                    addingImage: false,
                    message: `Claim saved.`,
                    messageFlavor: "success",
                    isMessageVisible: true,
                    redirect: true
                }, 
                  // () => this.getClaimAttachments(this.state.claim.id)
                );
            } else {
              this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }
        })
        .catch(helpers.catchHandler);
    }

    getClaimAttachments(claimId) {
        api.fetch(`Claim/GetClaimAttachments/${claimId}`)
        .then(response => {
            this.setState(prevState => ({
                ...prevState,
                claimAttachments: response.data
            }));
        })
        .catch(helpers.catchHandler);
    }

    onDismiss() {
      this.setState({
        isMessageVisible: !this.state.isMessageVisible,
        message: null,
        messageColor: null
      });
    }

    onToggleCustomerSearchModal() {
      this.setState({showCustomerSearchModal: !this.state.showCustomerSearchModal})
    }

    onCustomerSelectedFromSearchModal(customerId, customerName) {
      let claimCopy = Object.assign({}, this.state.claim);
      if (!this.state.claim.customer || this.state.claim.customer.value !== customerId ) {
          claimCopy = {
              ...claimCopy,
              contactFirstName: '',
              contactLastName: ''
          }
      }
      this.setState({
          showCustomerSearchModal: false,
          claim: {
              ...claimCopy,
              customer: helpers.resolveValue(null, customerId, customerName)
          }
      }, () => this.getCustomer(customerId));
    }

    getCustomer(customerId) {
      api.fetch(`Customer/GetActiveContacts/${customerId}`).then((response) => {
        const customerShipToAddresses = _.chain(response.data.customerAddresses)
          .filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.SHIP_TO })
          .map(a => {
            a.value = a.id;
            a.label = helpers.formatAddressLabel(a, '* ')
            return a;
          }).value();
        let customerInfo = response.data.customer;
        let customerContacts = response.data.contacts;
        _.each(customerContacts, c => {
          // remove deactivated phones and addresses for each contact
          const addresses = _.filter(c.addresses, a => a.deactivatedAt === null);
          const phoneNumbers = _.filter(c.phoneNumbers, p => p.deactivatedAt === null);
          c.expanded = false;
          c.addresses = addresses;
          c.phoneNumbers = phoneNumbers;
          
          // set value and label for select
          c.label = `${c.firstName} ${c.lastName}`;
          c.value = c.id;
          return c;
        });
        this.setState({ 
          customerInfo: customerInfo, 
          // customerContacts: customerContacts,
          customerShipToAddresses: customerShipToAddresses,
          shippingAddresses: customerShipToAddresses
        });
      }).catch(helpers.catchHandler);
    }

    handleTypeaheadSelectionChange(fieldName, selection) {
      let claimCopy = Object.assign({}, this.state.claim);
        if (selection.value !== (claimCopy.customer && claimCopy.customer.value)) {
            claimCopy = {
              ...claimCopy,
              contactFirstName: '',
              contactLastName: ''
            }
        }
        this.setState({ 
            claim: {
              ...claimCopy, 
              [fieldName]: selection,
              errorMessage: null
            }
        }, () => this.getCustomer(selection.value));
    }

    fetchOriginalOrderInfo() {
      api.fetch(`Claim/GetClaimOriginalOrderInfo/${this.state.claim.originalOrderId}`)
      .then((response) => {
        if (response.data) {
          this.setState({ 
            claim: {
              ...this.state.claim,
              customer: helpers.resolveValue(null, response.data.customerId, response.data.customerName),
              customerNumber: response.data.customerNumber,
              customerContact: helpers.nullableString(response.data.customerContactName),
              originalProduct: helpers.nullableString(response.data.originalProduct),
              originalColor: helpers.nullableString(response.data.originalColor),
              originalSize: helpers.nullableString(response.data.originalSize),
              originalInvoiceAmount: _.round(response.data.originalInvoiceAmount, 2).toFixed(2),
              replacementOrderId: helpers.nullableString(response.data.relatedReplacementOrderId),
              creditOrderId: helpers.nullableString(response.data.relatedCreditOrderId), 
              replacementProduct: helpers.nullableString(response.data.replacementProduct),
              replacementColor: helpers.nullableString(response.data.replacementColor),
              replacementSize: helpers.nullableString(response.data.replacementSize),
              replacementInvoiceAmount: _.round(response.data.replacementInvoiceAmount, 2).toFixed(2),
              replacementFreightCarrier: helpers.nullableString(response.data.replacementFreightCarrier),
              replacementFreightInvoice: helpers.nullableString(response.data.replacementFreightInvoice),
              replacementFreightAmount: _.round(response.data.replacementFreightAmount, 2).toFixed(2),
              supplier: helpers.resolveValue(null, response.data.originalProductSupplierId, response.data.originalProductSupplierName),
              supplierContactName: helpers.nullableString(response.data.supplierContactName),
              supplierContactEmail: helpers.nullableString(response.data.supplierContactEmail),
              supplierOriginalInvoice: helpers.nullableString(response.data.supplierOriginalInvoice),
              supplierOriginalInvoiceDate: date_helpers.formatDateForServer(response.data.supplierOriginalInvoiceDate),
              supplierReplacementInvoice: helpers.nullableString(response.data.supplierReplacementInvoice),
              supplierReplacementInvoiceDate: date_helpers.formatDateForServer(response.data.supplierReplacementInvoiceDate),
              supplierCreditNumber: helpers.nullableString(response.data.supplierCreditNumber),
              supplierCreditReceived: _.round(response.data.supplierCreditReceived, 2).toFixed(2),
              totalCreditDue: _.round(response.data.totalCreditDue, 2).toFixed(2),
              freightCreditNumber: helpers.nullableString(response.data.freightCreditNumber),
              freightCreditReceived: _.round(response.data.freightCreditReceived, 2).toFixed(2),
              totalCreditReceived: _.round(response.data.totalCreditReceived, 2).toFixed(2)
            }
          }, () => {
            if (response.data.customerId > 0) {
              this.getCustomer(response.data.customerId);
            }
          }); 
        } else {
          this.setState({claim: emptyClaim});
        }
      }).catch(helpers.catchHandler);
    }


    onAddNewComment() {
      this.setState({
        newComment: {
          ...emptyComment,
          createdByUserName: this.state.currentUserName
        }
      });
    }
  
    onCancelAddNewComment() {
      this.setState({
        newComment: null
      });
    }
  
    changeNewCommentField(event) {
      this.setState({
        newComment: {
          ...this.state.newComment,
          [event.target.name]: event.target.value
        }
      });
    }
  
    onEditComment(localCommentId) {
      const indexToEdit = _.findIndex(this.state.comments, { local_id: localCommentId });
      let tempList = _.cloneDeep(this.state.comments);
      const snapshotItem = Object.assign({}, tempList[indexToEdit]);
      tempList[indexToEdit].isEditing = true;
      tempList[indexToEdit].pristine = snapshotItem;
      this.setState({
        comments: tempList
      })
    }
  
    onCancelEditComment(localCommentId) {
      const indexToEdit = _.findIndex(this.state.comments, { local_id: localCommentId });
      let tempList = _.cloneDeep(this.state.comments);
      tempList[indexToEdit].isEditing = false;
      tempList[indexToEdit] = tempList[indexToEdit].pristine;
      this.setState({
        comments: tempList
      })
    }
  
    changeCommentField(event, localCommentId) {
      const indexToEdit = _.findIndex(this.state.comments, { local_id: localCommentId });
      const newList = Object.assign([], this.state.comments);
      newList[indexToEdit][event.target.name] = event.target.value;
      this.setState({
        comments: newList
      })
    }
  
    onSaveEditedComment(localCommentId) {
      const indexToEdit = _.findIndex(this.state.comments, { local_id: localCommentId });
      const newList = Object.assign([], this.state.comments);
      newList[indexToEdit].isEditing = false;
      this.setState({
        comments: newList
      })
    }
  
    onSaveNewComment() {
      let newList = Object.assign([], this.state.comments);
      let commentToSave = Object.assign({}, this.state.newComment);
      // if (!this.isCommentValid()) return;
      commentToSave = {
        ...commentToSave,
        local_id: -1 * (this.state.comments.length + 1)
      }
      newList = [...newList, commentToSave];
      this.setState({
        comments: newList,
        newComment: null
      })
    }
  
    onDeleteComment(localCommentId) {
      const indexToEdit = _.findIndex(this.state.comments, { local_id: localCommentId });
      const newList = Object.assign([], this.state.comments);   // const newList = _.cloneDeep(jobFees);
      newList.splice(indexToEdit, 1);
      this.setState({
        comments: newList
      })
    }


    render() {
      if (this.state.redirect === true) {
          return <Redirect to="/claims" />;
      };
      const previewStyle = {
          display: 'inline',
          width: 200,
          height: 200
      };
      const SaveCancelButtons =() => {
        return <Row className="expand-md row-spacing">
          <Col className="text-right">
            <ButtonGroup className="float-right">
              <Button
                className="primary"
                size="sm"
                onClick={() => this.saveClaim()}
              >
                <FontAwesomeIcon icon="save" /> Save
              </Button>
              <Button
                className="secondary"
                size="sm"
                onClick={this.onCancel}
              >
                <FontAwesomeIcon icon="reply" /> Back to Claims
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

      }
      return (
        <Container fluid className="p-2">
          <Card style={{ borderStyle: "none" }}>
            <CardBody>
              {this.state.message &&
                <Row className="mb-2">
                  <Col>
                    <Alert className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={() => this.onDismiss()}>{this.state.message}</Alert>
                  </Col>
                </Row>
              }
              <Row className="mb-3">
                <Col xs="8">
                    <h3 className="pull-left page-title">{this.state.claim.id ? 'Edit Claim' : 'Add Claim'}</h3>
                </Col>
                <Col xs="4">{SaveCancelButtons()}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs="9">
                  <Card className="p-3 mb-2" style={{background: "#eee"}}>
                    <Row className="mb-3">
                      <Col xs="6">
                        <h4 className="bold-title">{this.state.claim.id ? `Claim ${this.state.claim.id}` : `New Claim`}</h4>
                      </Col>
                      <Col xs="6">
                        <h4 className="bold-title float-right">{`Customer Account # ${this.state.claim && this.state.claim.customerNumber ? this.state.claim.customerNumber : '-'}`}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Date of Claim {helpers.requiredStar()}</Label>
                        <div className="datePickerWidth">
                          <DatePicker
                            onChange={e => this.onDateChange("claimDate", e)}
                            selected={date_helpers.dateFormatForClient(this.state.claim.claimDate)}
                            className="form-control"
                          />
                        </div>
                      </Col>
                      {/* <Col xs="3">
                        <Label className="bold-title m-0">Account</Label>
                        <Input
                          type="text"
                          name="customerName"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.customerName}
                          maxLength="50"
                        />
                      </Col> */}
                      <Col sm="3">
                        <Button
                            className="m-0 p-0"
                            color="link"
                            style={{textDecoration: "underline"}}
                            onClick={this.onToggleCustomerSearchModal}
                        >
                            Account {helpers.requiredStar()}
                        </Button>
                        <CustomerTypeahead
                            onChange={(e) => this.handleTypeaheadSelectionChange("customer", e)}
                            isMulti={false}
                            customer={this.state.claim.customer}
                        />
                    </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Contact</Label>
                        <Input
                          type="text"
                          name="customerContact"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.customerContact}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Status {helpers.requiredStar()}</Label>
                        <Select
                          closeMenuOnSelect
                          value={this.state.claim.claimStatus}
                          options={this.state.claimStatusList}
                          onChange={(e) => this.onSelectChange(e, "claimStatus")}
                          // isDisabled={this.state.claim.id > 0}  // updating claim
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Original Invoice {helpers.requiredStar()}</Label>
                        <Input
                          type="text"
                          name="originalOrderId"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.originalOrderId}
                          maxLength="50"
                          onBlur={() => this.setState({showConfirmModal: true})}
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Invoice</Label>
                        <Input
                          type="text"
                          name="replacementOrderId"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementOrderId}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Credit Invoice</Label>
                        <Input
                          type="text"
                          name="creditOrderId"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.creditOrderId}
                          maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Original Product</Label>
                        <Input
                          type="text"
                          name="originalProduct"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.originalProduct}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Original Color</Label>
                        <Input
                          type="text"
                          name="originalColor"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.originalColor}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Original Size</Label>
                        <Input
                          type="text"
                          name="originalSize"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.originalSize}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Original Cust Invoice Amount</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="originalInvoiceAmount"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.originalInvoiceAmount}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.originalInvoiceAmount)}
                            min="0"
                        />
                      </Col>
                    </Row>
                  </Card>
                  <Card className="p-3 mb-2" style={{background: "#eee"}}>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Product</Label>
                        <Input
                          type="text"
                          name="replacementProduct"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementProduct}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Color</Label>
                        <Input
                          type="text"
                          name="replacementColor"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementColor}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Size</Label>
                        <Input
                          type="text"
                          name="replacementSize"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementSize}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Invoice Amount</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="replacementInvoiceAmount"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.replacementInvoiceAmount}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.replacementInvoiceAmount)}
                            min="0"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Freight Carrier</Label>
                        <Input
                          type="text"
                          name="replacementFreightCarrier"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementFreightCarrier}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Freight Invoice</Label>
                        <Input
                          type="text"
                          name="replacementFreightInvoice"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementFreightInvoice}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3"></Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Replacement Freight Amount</Label>
                        <Input
                          type="text"
                          name="replacementFreightAmount"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.replacementFreightAmount}
                          onBlur={(event) => this.updateCurrencyField(
                            event.target.name,
                            this.state.claim.replacementFreightAmount)}
                          maxLength="50"
                        />
                      </Col>
                    </Row>
                  </Card>
                  <Card className="p-3 mb-2" style={{background: "#eee"}}>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Supplier Credit #</Label>
                        <Input
                          type="text"
                          name="supplierCreditNumber"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.supplierCreditNumber}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Supplier Credit Received</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="supplierCreditReceived"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.supplierCreditReceived}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.supplierCreditReceived)}
                            min="0"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Total Credit Due</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="totalCreditDue"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.totalCreditDue}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.totalCreditDue)}
                            min="0"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <Label className="bold-title m-0">Freight Credit #</Label>
                        <Input
                          type="text"
                          name="freightCreditNumber"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.freightCreditNumber}
                          maxLength="50"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Freight Credit Received</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="freightCreditReceived"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.freightCreditReceived}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.freightCreditReceived)}
                            min="0"
                        />
                      </Col>
                      <Col xs="3">
                        <Label className="bold-title m-0">Total Credit Received</Label>
                        <Input
                            type="number"
                            step="0.01"
                            name="totalCreditReceived"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.totalCreditReceived}
                            onBlur={(event) => this.updateCurrencyField(
                                event.target.name,
                                this.state.claim.totalCreditReceived)}
                            min="0"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs="3">
                  <Card className="p-3" style={{background: "#eee"}}>
                    <Row className="mb-2">
                      {/* <Col>
                        <Label className="bold-title m-0">Supplier</Label>
                        <Input
                          type="text"
                          name="supplierName"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.supplierName}
                          maxLength="50"
                        />
                      </Col> */}
                       <Col>
                          <Label className="bold-title m-0">Supplier {helpers.requiredStar()}</Label>
                          <Select
                              closeMenuOnSelect
                              value={this.state.claim.supplier}
                              options={this.state.supplierList}
                              onChange={this.onSupplierChange}
                          />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <Label className="bold-title">Claim Contact: </Label> <span >{this.state.claim ? this.state.claim.supplierContactName : null}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <Label className="bold-title">Email: </Label> <span>{this.state.claim ? this.state.claim.supplierContactEmail : null}</span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label className="bold-title m-0">Supplier Claim #</Label>
                        <Input
                          type="text"
                          name="supplierClaimNumber"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.supplierClaimNumber}
                          maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label className="bold-title m-0">Supplier Original Invoice</Label>
                        <Input
                          type="text"
                          name="supplierOriginalInvoice"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.supplierOriginalInvoice}
                          maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label className="bold-title m-0">Supplier Original Invoice Date</Label>
                        <div className="datePickerWidth">
                          <DatePicker
                            onChange={e => this.onDateChange("supplierOriginalInvoiceDate", e)}
                            selected={date_helpers.dateFormatForClient(this.state.claim.supplierOriginalInvoiceDate)}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label className="bold-title m-0">Supplier Replacement Invoice</Label>
                        <Input
                          type="text"
                          name="supplierReplacementInvoice"
                          onChange={(event) => this.onInputChange(
                              event.target.name,
                              event.target.value)}
                          value={this.state.claim.supplierReplacementInvoice}
                          maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <Label className="bold-title m-0">Supplier Replacement Invoice Date</Label>
                        <div className="datePickerWidth">
                          <DatePicker
                            onChange={e => this.onDateChange("supplierReplacementInvoiceDate", e)}
                            selected={date_helpers.dateFormatForClient(this.state.claim.supplierReplacementInvoiceDate)}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12">
                  <Card className="p-3 mb-2" style={{background: "#eee"}}>
                    <Row className="mb-3">
                      <Col>
                        <h4 className="bold-title">Nature of Complaint</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <Label className="bold-title m-0">Web Description</Label> */}
                        <Input
                            type="textarea"
                            name="complaintText"
                            onChange={(event) => this.onInputChange(
                                event.target.name,
                                event.target.value)}
                            value={this.state.claim.complaintText}
                            maxLength="1500"
                            style={{height: "150px"}}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Label className="bold-title m-0">Images</Label>
                  <div>
                    <Button
                      className={this.state.addingImage ? "danger" : "success"}
                      size="sm"
                      id="dropzone-toggler"
                      onClick={this.toggleImages}
                    >
                      {this.state.addingImage
                        ? <FontAwesomeIcon icon="minus" />
                        : <FontAwesomeIcon icon="plus" />
                      } Images
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  {this.state.addingImage && (
                    <div>
                      {this.state.errorMessage ?
                          <Alert className="warning">
                              {this.state.errorMessage}
                          </Alert>
                          : null
                      }
                      <DropzoneUploader
                        fileUploadOnly
                        // defaultAttachmentType={constants.ATTACHMENT_TYPE.DETAILED_SPECS}
                        filesCallback={(files) => this.setState({files: files})}
                        // productAttachmentTypeList={this.state.productAttachmentTypeList}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    {this.state.claimAttachments.map(ca => (
                      <Col key={ca.id}>
                        <Row>
                          <Col>
                              <Label>{ca.productAttachmentTypeName}</Label>{" "}
                              <Button
                                  className="danger btn-outline-secondary"
                                  size="sm"
                                  onClick={() => this.removeClaimAttachment(ca)}
                              >
                                  <FontAwesomeIcon icon="trash" />
                              </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          {/* {ca.productAttachmentTypeName === 'Detailed Specs'
                            ? (<Button title="View PDF" key={ca.id} color="danger"
                                onClick={() => window.open(ca.url, "_blank")}
                              >
                                <FontAwesomeIcon icon='file-pdf' size="3x" />
                              </Button>)
                            : ( */}
                                <img alt="Preview" key={ca.id}
                                  src={ca.url} style={previewStyle}
                                  onClick={() => window.open(ca.url, "_blank")}
                                />
                              {/* )
                          } */}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Fragment>
                <Row className="mb-2 mt-5">
                  <Col>
                    <Button
                      size="sm"
                      className="success float-right"
                      onClick={() => this.onAddNewComment()}
                    >
                      Add New Comment
                    </Button>
                    <h5>Comments</h5>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-end">
                  <Col xs="12">
                    <Table
                      striped
                      hover
                      size="sm"
                      responsive={false}
                      id="feesTable"
                    >
                      <thead>
                        <tr>
                          <th>Comment</th>
                          {/* <th>Is Private?</th> */}
                          <th>Entered By</th>
                          <th>Date</th>
                          <th width="8%" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.newComment
                          ? <tr>
                              <React.Fragment>
                                <td width="50%">
                                  <Input
                                    type="text"
                                    name="dsc"
                                    onChange={(event) => this.changeNewCommentField(event)}
                                    value={this.state.newComment.dsc}
                                    className="form-control"
                                  />
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  <ButtonGroup className="float-right">
                                    <Button
                                      size="sm"
                                      className="primary btn"
                                      onClick={() => this.onSaveNewComment()}
                                      title="Add comment"
                                      // disabled={!(this.state.newComment && this.state.newComment.dsc)}
                                    >
                                      <FontAwesomeIcon icon="save" />
                                    </Button>
                                    <Button
                                      size="sm"
                                      className="secondary btn"
                                      onClick={() => this.onCancelAddNewComment()}
                                      title="Cancel New Comment"
                                    >
                                      <FontAwesomeIcon icon="reply" />
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              </React.Fragment>
                            </tr>
                          : null
                        }
                        {this.state.comments && this.state.comments.length > 0 &&
                          _.map(this.state.comments, (c, index) => (
                            <tr key={c.local_id}>
                              {c.isEditing
                                ? <React.Fragment>
                                    <td width="50%">
                                      <Input
                                        type="text"
                                        name="dsc"
                                        onChange={(event) => this.changeCommentField(event, c.local_id)}
                                        value={c.dsc}
                                        className="form-control"
                                      />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <ButtonGroup className="float-right">
                                        <Button
                                          size="sm"
                                          className="primary btn"
                                          onClick={() => this.onSaveEditedComment(c.local_id)}
                                          title="Edit comment"
                                          // disabled={!(c && c.dsc)}
                                        >
                                          <FontAwesomeIcon icon="save" />
                                        </Button>
                                        <Button
                                          size="sm"
                                          className="secondary btn"
                                          onClick={() => this.onCancelEditComment(c.local_id)}
                                          title="Cancel Edit Comment"
                                        >
                                          <FontAwesomeIcon icon="reply" />
                                        </Button>
                                      </ButtonGroup>
                                    </td>
                                  </React.Fragment>
                                : <React.Fragment>
                                  <td style={{ width: "50%" }}>{c.dsc}</td>
                                  <td>{c.createdByUserName}</td>
                                  <td>{moment(c.createdAt).format("M/DD/YY")}</td>
                                  {/* <td>
                                    <div className="ml-2">
                                      {c.isPrivate
                                        ? <span className="text-success">
                                          <FontAwesomeIcon icon="check" />
                                        </span>
                                        : null
                                      }
                                    </div>
                                  </td> */}
                                  <td>
                                    <ButtonGroup className="float-right">
                                      <Button
                                        size="sm"
                                        className="primary btn-outline-secondary"
                                        onClick={() => this.onEditComment(c.local_id)}
                                      // disabled={}
                                      >
                                        <FontAwesomeIcon icon="edit" />
                                      </Button>
                                      <Button
                                        size="sm"
                                        className="danger btn-outline-secondary"
                                        onClick={() =>
                                          this.onDeleteComment(c.local_id)
                                        }
                                        title="Remove from quote"
                                      >
                                        <FontAwesomeIcon icon="times-circle" />
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </React.Fragment>
                              }
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Fragment>
              {SaveCancelButtons()} 
              {this.state.showCustomerSearchModal
                  ? <Modal
                      isOpen={this.state.showCustomerSearchModal}
                      toggle={() => this.setState({showCustomerSearchModal: false})}
                      size="lg"
                    >
                      <ModalBody>
                        <CustomerAdmin 
                            usedByCustomerSearchModal={true}
                            onCustomerSelected={this.onCustomerSelectedFromSearchModal}
                        />
                      </ModalBody>
                    </Modal>
                : null
              }
              {this.state.showConfirmModal
                ? <Modal
                    isOpen={this.state.showConfirmModal}
                    toggle={() => this.setState({showConfirmModal: false})}
                    size="sm"
                  >
                    <ModalBody>
                      Overwrite form fields with data from this order invoice number?
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup className="float-right">
                          <Button 
                            className="m-1"    
                            color="primary"
                            onClick={() => this.setState({showConfirmModal: false}, () => this.fetchOriginalOrderInfo())}>
                              Yes
                          </Button>
                          <Button
                            className="m-1"
                            color="secondary"
                            onClick={() => this.setState({showConfirmModal: false})}>
                              No
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                  </Modal>
                : null
              }

            </CardBody>
          </Card>
        </Container>
      );
    }
}

