import React, { Component, memo } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Row,
    Table,
    Alert,
    Collapse,
    CardBody,
    Label,
    Badge, 
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
    api,
    helpers,
    date_helpers, 
    constants
} from '../utils';
import {
    SortHeader
} from '../components';
import moment from 'moment';


const emptyMemoOrderLine = {
  typeOfMemoOrderLineStatus: {value: 1, label: "Pending"},
  lastStatusAt: new Date(),
  product: null,
  productColor: null,
  memoSize: null,
  qty: '1',
  commentCount: 0 // for temporary use only
};

const emptySampleOrderLine = {
  typeOfMemoOrderLineStatus: {value: 1, label: "Pending"},
  lastStatusAt: new Date(),
  sampleBook: null,
  qty: '1',
  commentCount: 0 // for temporary use only
};

const emptyOrderLineComment = {
  id: 0,
  dsc: "",
  commentType: null,
  markedForDeletion: false
};

class MemoOrderLines extends Component {
    constructor(props) {
      super(props);
      this.state = {
          memoOrderId: props.memoOrderId,
          memoOrderLines: _.filter(props.memoOrderLines, orderline => orderline.product != null),
          sampleOrderLines: _.filter(props.memoOrderLines, orderline => orderline.sampleBook != null),
          customerId: props.customerId,
          filters: [],
          sort_field: 'id',
          sort_dir: 'asc',
          message: null,
          messageFlavor: null,
          isMessageVisible: false,
          memoOrderLineStatusList: [],
          memoOrderLineToAddEdit: emptyMemoOrderLine,
          sampleOrderLineToAddEdit: emptySampleOrderLine,
          showMemoOrderLineForm: true,
          showSampleOrderLineForm: true,
          newMemoOrderLineComment: emptyOrderLineComment,
          newSampleOrderLineComment: emptyOrderLineComment,
          memoOrderLineCommentList: [],
          sampleOrderLineCommentList: [],
          commentTypeList: [],
          editingMemoOrderLineNotes: false,
          editingSampleOrderLineNotes: false,
          selectAllMemoOrderLinesCheckboxSelected: false,
          selectAllSampleOrderLinesCheckboxSelected: false,
          productList: [],
          sampleBookList: [],
          product: null,
          loadingProductOptions: false,
          loadingSampleBookOptions: false,
          deletingMultipleOrderLines: false
      };
      this.refreshList = this.refreshList.bind(this);
      this.onDismiss = this.onDismiss.bind(this);
      this.doSort = this.doSort.bind(this);
      this.setMemoOrderLineLastStatusAtDate = this.setMemoOrderLineLastStatusAtDate.bind(this);
      this.setSampleOrderLineLastStatusAtDate = this.setSampleOrderLineLastStatusAtDate.bind(this);
      this.onEditMemoOrderLine = this.onEditMemoOrderLine.bind(this);
      this.onEditSampleOrderLine = this.onEditSampleOrderLine.bind(this);
      this.setSelectedProduct = this.setSelectedProduct.bind(this);
      this.fetchProductMemoSizes = this.fetchProductMemoSizes.bind(this);
      this.fetchProductSelectsForOrderLineToEdit = this.fetchProductSelectsForOrderLineToEdit.bind(this);
      this.onSelectAllMemoOrderLinesCheckbox = this.onSelectAllMemoOrderLinesCheckbox.bind(this);
      this.onSelectAllSampleOrderLinesCheckbox = this.onSelectAllSampleOrderLinesCheckbox.bind(this);
      this.onSelectIndividualMemoOrderLineCheckbox = this.onSelectIndividualMemoOrderLineCheckbox.bind(this);
      this.onSelectIndividualSampleOrderLineCheckbox = this.onSelectIndividualSampleOrderLineCheckbox.bind(this);
      this.saveMemoOrderLine = this.saveMemoOrderLine.bind(this);
      this.updateSelectedMemoOrderLines = this.updateSelectedMemoOrderLines.bind(this);
      this.deleteSelectedMemoOrderLines = this.deleteSelectedMemoOrderLines.bind(this);
      this.moveSelectedMemoOrderLinesToNewOrder = this.moveSelectedMemoOrderLinesToNewOrder.bind(this);
      this.getProductOptions = this.getProductOptions.bind(this);
      this.refreshProductOptions = this.refreshProductOptions.bind(this);
      // this.refreshSampleBookOptions = this.refreshSampleBookOptions.bind(this);
      this.onSampleBookChange = this.onSampleBookChange.bind(this);
      this.onSaveMemoOrderLineNote = this.onSaveMemoOrderLineNote.bind(this);
      this.onSaveSampleOrderLineNote = this.onSaveSampleOrderLineNote.bind(this);
      this.showDeleteMemoOrderLineConfirmationModal = this.showDeleteMemoOrderLineConfirmationModal.bind(this);
      this.showMoveSelectedConfirmationModal = this.showMoveSelectedConfirmationModal.bind(this);
    }

    componentDidMount() {
      this.setState({loading: true, loadingProductOptions: true, loadingSampleBookOptions: true}, () => {
          Promise.all([
              api.fetch("Reference/GetMemoOrderLineStatusList"),
              this.getProductOptions(),
              this.getSampleBookOptions(),
              api.fetch("Reference/GetCommentTypeList")
          ]).then(([memoOrderLineStatusList, products, sampleBooks, commentTypes]) => {
            this.setState({
              memoOrderLineStatusList: memoOrderLineStatusList.data,
              productList: products.data,
              sampleBookList: sampleBooks.data,
              commentTypeList: commentTypes.data
            })
          })
          .catch(error => Alert.error(error))
          .finally(() => this.setState({loading: false, loadingProductOptions: false, loadingSampleBookOptions: false}))
      })
        //refreshList is not called here as initial memoOrderLines come in as props from MemoOrders component
    }

    componentDidUpdate(prevProps) {
      if (prevProps.memoOrderLines !== this.props.memoOrderLines) {
        this.setState({ 
          // memoOrderLines: this.props.memoOrderLines,
          memoOrderLines: _.filter(this.props.memoOrderLines, orderline => orderline.product != null),
          sampleOrderLines: _.filter(this.props.memoOrderLines, orderline => orderline.sampleBook != null),
        });
      }

      if (prevProps.memoOrderId !== this.props.memoOrderId) {
          this.setState({ memoOrderId: this.props.memoOrderId });
      }

      if (prevProps.customerId  !== this.props.customerId) {
        this.setState({ customerId: this.props.customerId });
    }
    }

    getProductOptions(name = "") {
        return api.post('Product/GetSelectList', {
            Page: 1,
            PageSize: 100,
            SortField: "DCName",
            SortDir: "asc",
            DCName: name,
            HasMemoSize: true,
            ActiveOnly: true,
            HasColors: true,
            ProductTypeList: [
                constants.PRODUCT_TYPE.CARPET.value,
                constants.PRODUCT_TYPE.RUG.value,
                constants.PRODUCT_TYPE.RUG_PAD.value,
                constants.PRODUCT_TYPE.INTL_RUG.value,
                constants.PRODUCT_TYPE.HARD_SURFACE.value,
                constants.PRODUCT_TYPE.ROLL_RUNNER.value,
                constants.PRODUCT_TYPE.BORDER_OR_BINDING.value,
                constants.PRODUCT_TYPE.CARPET_TILE.value,
                constants.PRODUCT_TYPE.SAMPLE.value,
                constants.PRODUCT_TYPE.SQUARE_FOOT_RUG.value
            ]
        })
    }

    refreshProductOptions(input, callback) {
        this.setState({loadingProductOptions: true}, () => {
            this.getProductOptions(input).then(r => {
                if(r){
                    callback(r.data);
                    // this.setState({productList: r.data})
                    // return r.data
                }else{
                    throw new Error("Unable to refresh product list")
                }
            })
            .catch(error => Alert.error(error))
            .finally(() => this.setState({loadingProductOptions: false}))
        })
    }

    getSampleBookOptions(name = "") {
      return api.post('SampleBook/GetSelectList', {
          Page: 1,
          PageSize: 100,
          SortField: "BookName",
          SortDir: "asc",
          ActiveOnly: true,
          BookName: name
      })
    }

    // refreshSampleBookOptions(input, callback) {
    //   this.setState({loadingSampleBookOptions: true}, () => {
    //       this.getSampleBookOptions(input).then(r => {
    //           if(r){
    //               callback(r.data);
    //               // this.setState({sampleBookList: r.data})
    //               // return r.data
    //           }else{
    //               throw new Error("Unable to refresh product list")
    //           }
    //       })
    //       .catch(error => Alert.error(error))
    //       .finally(() => this.setState({loadingSampleBookOptions: false}))
    //   })
    // }

    refreshList(sortField, sortDirection) {
        const payload = {
            SortField: sortField || this.state.sort_field,
            SortDir: sortDirection || this.state.sort_dir,
        };
        api.post(`MemoOrder/GetMemoOrderLines/${this.state.memoOrderId}`, payload)
        .then(response => {
          let orderLines = _.map(response.data, ol => {
            return {
              id: ol.id,
              lastStatusAt:  date_helpers.getMomentFromString(ol.lastStatusAt),
              memoOrderId: ol.memoOrderId,
              memoSize: helpers.resolveValue(null, ol.memoSizeId, ol.memoSizeName),
              productColor: helpers.resolveValue(
                  null, 
                  ol.productColorId, 
                  `${ol.productColorName}${ol.productColorNumber ? (' ' + ol.productColorNumber) : ''}`
              ),
              product: helpers.resolveValue(
                  null, 
                  ol.productId, 
                  `${ol.productDCName} ${ol.productDCNumber ? '(' + ol.productDCNumber + ')' : ''}`),
              sampleBook: helpers.resolveValue(
                  null, 
                  ol.sampleBookId, 
                  ol.sampleBookName),
              sampleBookStock: ol.sampleBookStock,
              productMemoShelfNumber: helpers.nullableString(ol.productMemoShelfNumber),
              typeOfMemoOrderLineStatus: helpers.resolveValue(null, ol.typeOfMemoOrderLineStatus, ol.typeOfMemoOrderLineStatusName),
              memoOrderLineStatus: null,
              supplierName: ol.productSupplierName,
              qty: ol.qty ? ol.qty : '1',
              deactivatedAt: null,
              orderLineComments: _.map(ol.memoOrderLineComments, comment => {
                  return {
                      ...comment, 
                      local_id:  -1*(ol.memoOrderLineComments.indexOf(comment)+1),
                    commentType: helpers.resolveValue(null, comment.commentTypeId, comment.commentTypeName),
                      createdAt: date_helpers.getMomentFromString(comment.createdAt)
                  }
              }),
              commentCount: ol.memoOrderLineComments ? ol.memoOrderLineComments.length : 0,  // for temporary use only
              productMemoSize: ol.productMemoSize,
              isSelected: false,
              lastSampleReceivedAt:  date_helpers.getMomentFromString(ol.lastSampleReceivedAt),
            }
          });
          this.setState({
            memoOrderLines: _.filter(orderLines, orderline => orderline.product != null),
            sampleOrderLines: _.filter(orderLines, orderline => orderline.sampleBook != null),
          });
        })
        .catch(helpers.catchHandler);
    }   

    doSort(field, direction) {
        this.setState({ sort_dir: direction, sort_field: field });
        this.refreshList(field, direction);
      }

    toggleAddMemoOrderLineCollapse() {
      this.setState({
        // showMemoOrderLineForm: !this.state.showMemoOrderLineForm,
        memoOrderLineToAddEdit: emptyMemoOrderLine,
        memoOrderLineCommentList: [],
        message: null,
        messageFlavor: null,
        isMessageVisible: false,
        productColorsList: [],
        memoSizeList: [],
        editingMemoOrderLineNotes: false, 
      });
    }

    toggleAddSampleOrderLineCollapse() {
      this.setState({
        // showSampleOrderLineForm: !this.state.showSampleOrderLineForm,
        sampleOrderLineToAddEdit: emptySampleOrderLine,
        sampleOrderLineCommentList: [],
        message: null,
        messageFlavor: null,
        isMessageVisible: false,
        editingSampleOrderLineNotes: false, 
      });
    }

    setMemoOrderLineStatus(option) {
      this.setState(prevState => ({
          ...prevState,
          memoOrderLineToAddEdit: {
              ...prevState.memoOrderLineToAddEdit,
              typeOfMemoOrderLineStatus: option
          }
      }));
    }

    setSampleOrderLineStatus(option) {
      this.setState(prevState => ({
        ...prevState,
        sampleOrderLineToAddEdit: {
            ...prevState.sampleOrderLineToAddEdit,
            typeOfMemoOrderLineStatus: option
        }
      }));
    }

    setMemoOrderLineLastStatusAtDate(date) {
      this.setState({
        memoOrderLineToAddEdit: {
            ...this.state.memoOrderLineToAddEdit,
            lastStatusAt: date
        }
      });
    }

    setSampleOrderLineLastStatusAtDate(date) {
      this.setState({ 
        sampleOrderLineToAddEdit: {
            ...this.state.sampleOrderLineToAddEdit,
            lastStatusAt: date
        }
      });
    }

    setSelectedProduct(option) {
      api.fetch(`Order/GetActiveProductColors/${option.value}`)
        .then(response => {
          this.setState({
            productColorsList: response.data,
            memoOrderLineToAddEdit: {
              ...this.state.memoOrderLineToAddEdit,
              product: option,
              productColor: response.data.length > 0 ? response.data[0] : null
            }
          }, () => this.fetchProductMemoSizes(option));
        })
        .catch(helpers.catchHandler);
    }

    onSampleBookChange(e) {
      this.setState(prevState => ({
          ...prevState,
          sampleOrderLineToAddEdit: {
            ...prevState.sampleOrderLineToAddEdit,
            sampleBook: e
          }
        }));
    }

    fetchProductMemoSizes(option) {
      api.fetch(`Product/GetProduct/${option.value}`)
        .then(response => {
          let selectedProductColorId = this.state.memoOrderLineToAddEdit.productColor ? this.state.memoOrderLineToAddEdit.productColor.value : null;
          let memoSizeList = _.filter(response.data.productMemoSizes, function (x) {
            return x.productColorId === selectedProductColorId
          })
          this.setState({
            memoSizeList: _.map(memoSizeList, ms => {
              return {
                ...ms,
                value: ms.memoSizeId,
                label: ms.memoSizeName
              }
            }),
            memoOrderLineToAddEdit: {
              ...this.state.memoOrderLineToAddEdit,
              // memoSize: memoSizeList.length > 0 ? helpers.resolveValue(null, response.data.productMemoSizes[0].memoSizeId, response.data.productMemoSizes[0].memoSizeName) : null
              memoSize: memoSizeList.length > 0 
                  ? helpers.resolveValue(
                      null, 
                      response.data.defaultMemoSizeId ? response.data.defaultMemoSizeId : response.data.productMemoSizes[0].memoSizeId,
                      response.data.defaultMemoSizeId ? response.data.defaultMemoSizeName : response.data.productMemoSizes[0].memoSizeName) 
                  : null
            }
          });
        })
        .catch(helpers.catchHandler);
    }

    setSelectedProductColor(option) {
      this.setState(prevState => ({
        ...prevState,
        memoOrderLineToAddEdit: {
          ...prevState.memoOrderLineToAddEdit,
          productColor: option
        }
      }));
    }

    setSelectedMemoSize(option) {
      this.setState(prevState => ({
          ...prevState,
          memoOrderLineToAddEdit: {
              ...prevState.memoOrderLineToAddEdit,
              memoSize: option
          }
      }));
    }

    handleMemoOrderLineQtyChange(value) {
      this.setState({
          memoOrderLineToAddEdit: {
              ...this.state.memoOrderLineToAddEdit,
              qty: value
          }
      });
    }

    handleSampleOrderLineQtyChange(value) {
      this.setState({
          sampleOrderLineToAddEdit: {
              ...this.state.sampleOrderLineToAddEdit,
              qty: value
          }
      });
    }

    isFormValid(item) {
        let warnings = [];
        if(!item.typeOfMemoOrderLineStatus) {
            warnings.push("Status is required.");
        }
        if(!item.lastStatusAt) {
            warnings.push("Last Status At is required.");
        }
        if(!item.qty) {
            warnings.push("Quantity is required.");
        }
        if (!item.product && !item.sampleBook) {
            warnings.push("Product is required.");
        }
        // if(!item.productColor) {
        //     warnings.push("Product color is required.");
        // }
        if(item.product && !item.memoSize) {
            warnings.push("Memo size is required.");
        }
        if (warnings.length) {
            this.setState({
              message: warnings.join(' '),
              messageFlavor: "danger",
              isMessageVisible: true
            });
        } else {
          this.setState({ message: null, isMessageVisible: false, messageFlavor: null });
        }
        return warnings.length === 0;
    }

    saveNewMemoOrderWithOrderLine(orderLineToAddEdit) {
      if (!this.isFormValid(orderLineToAddEdit)) return;
      const orderLineComments = orderLineToAddEdit.product
        ? this.formatOrderLineCommentsForDto(this.state.memoOrderLineCommentList, orderLineToAddEdit.id)
        : this.formatOrderLineCommentsForDto(this.state.sampleOrderLineCommentList, orderLineToAddEdit.id);
      const data = {
        Id: 0,
        MemoOrderId: this.state.memoOrderId,
        TypeOfMemoOrderLineStatus: orderLineToAddEdit.typeOfMemoOrderLineStatus.value,
        LastStatusAt: orderLineToAddEdit.lastStatusAt,
        ProductId: orderLineToAddEdit.product ? orderLineToAddEdit.product.value : null,
        SampleBookId: orderLineToAddEdit.sampleBook ? orderLineToAddEdit.sampleBook.value : null,
        ProductColorId: orderLineToAddEdit.productColor ? orderLineToAddEdit.productColor.value : null,
        MemoSizeId: orderLineToAddEdit.memoSize ? orderLineToAddEdit.memoSize.value : null,
        Qty: Number(orderLineToAddEdit.qty),
        memoOrderLineComments: orderLineComments,
        CustomerId: this.state.customerId
      };
      this.setState({
          // showMemoOrderLineForm: false,
          memoOrderLineToAddEdit: emptyMemoOrderLine,
          sampleOrderLineToAddEdit: emptySampleOrderLine
      }, () => this.props.saveNewMemoOrderWithOrderLine(data));
    }


    saveNewMemoOrderLine(orderLineToAddEdit) {
      if (!this.isFormValid(orderLineToAddEdit)) return;
      const orderLineComments = orderLineToAddEdit.product
        ? this.formatOrderLineCommentsForDto(this.state.memoOrderLineCommentList, orderLineToAddEdit.id)
        : this.formatOrderLineCommentsForDto(this.state.sampleOrderLineCommentList, orderLineToAddEdit.id);
      const data = {
        Id: 0,
        MemoOrderId: this.state.memoOrderId,
        TypeOfMemoOrderLineStatus: orderLineToAddEdit.typeOfMemoOrderLineStatus.value,
        LastStatusAt: orderLineToAddEdit.lastStatusAt,
        ProductId: orderLineToAddEdit.product ? orderLineToAddEdit.product.value : null,
        SampleBookId: orderLineToAddEdit.sampleBook ? orderLineToAddEdit.sampleBook.value : null,
        ProductColorId: orderLineToAddEdit.productColor ? orderLineToAddEdit.productColor.value : null,
        MemoSizeId: orderLineToAddEdit.memoSize ? orderLineToAddEdit.memoSize.value : null,
        Qty: Number(orderLineToAddEdit.qty),
        memoOrderLineComments: orderLineComments,
        CustomerId: this.state.customerId
      };
      this.saveMemoOrderLine(data);
    }

    updateMemoOrderLine(orderLineToUpdate, updateToShipped = null, updateToBackOrdered = null) {
      if (!this.isFormValid(orderLineToUpdate)) return;
      const orderLineComments = orderLineToUpdate.product
        ? this.formatOrderLineCommentsForDto(this.state.memoOrderLineCommentList, orderLineToUpdate.id)
        : this.formatOrderLineCommentsForDto(this.state.sampleOrderLineCommentList, orderLineToUpdate.id);
      const data = {
        Id: orderLineToUpdate.id,
        MemoOrderId: this.state.memoOrderId,
        TypeOfMemoOrderLineStatus: updateToShipped 
                                        ? constants.MEMO_ORDERLINE_STATUS.SHIPPED 
                                        : updateToBackOrdered
                                            ? constants.MEMO_ORDERLINE_STATUS.BACKORDER
                                            : orderLineToUpdate.typeOfMemoOrderLineStatus.value,
        LastStatusAt: updateToShipped || updateToBackOrdered ? moment() : orderLineToUpdate.lastStatusAt,
        ProductId: orderLineToUpdate.product ? orderLineToUpdate.product.value : null,
        SampleBookId: orderLineToUpdate.sampleBook ? orderLineToUpdate.sampleBook.value : null,
        ProductColorId: orderLineToUpdate.productColor ? orderLineToUpdate.productColor.value : null,
        MemoSizeId: orderLineToUpdate.memoSize ? orderLineToUpdate.memoSize.value : null,
        Qty: Number(orderLineToUpdate.qty),
        MemoOrderLineComments: orderLineComments,
        CustomerId: this.state.customerId
      };
      this.saveMemoOrderLine(data);
    }

    saveMemoOrderLine(data) {
      api.post("MemoOrder/SaveMemoOrderLine", data)
      .then(response => {
          if (response.data.success) { 
            this.setState({
                // showMemoOrderLineForm: false,
                memoOrderLineToAddEdit: data.ProductId ? emptyMemoOrderLine : this.state.memoOrderLineToAddEdit,
                newMemoOrderLineComment: data.ProductId ? "" : this.state.newMemoOrderLineComment,
                memoOrderLineCommentList: data.ProductId ? [] : this.state.memoOrderLineCommentList,
                editingMemoOrderLineNotes: data.ProductId ? false : this.state.editingMemoOrderLineNotes,
                sampleOrderLineToAddEdit: data.SampleBookId ? emptySampleOrderLine : this.state.sampleOrderLineToAddEdit,
                newSampleOrderLineComment: data.SampleBookId ? "" : this.state.newSampleOrderLineComment,
                sampleOrderLineCommentList: data.SampleBookId ? [] : this.state.sampleOrderLineCommentList,
                editingSampleOrderLineNotes: data.SampleBookId ? false : this.state.editingSampleOrderLineNotes,
            }, () => this.refreshList());
          } else {
              this.setState({ 
                messageFlavor: "danger",
                message: response.data.message,
                isMessageVisible: true
              });
          }   
      })
      .catch(helpers.catchHandler);
    }

    // For Ship Selected && Backorder Selected buttons
    updateSelectedMemoOrderLines(updateToShipped = null, updateToBackOrdered = null) {
      let memoOrderLinesSelected = _.filter(this.state.memoOrderLines, orderline => orderline.isSelected);
      let sampleOrderLinesSelected = _.filter(this.state.sampleOrderLines, orderline => orderline.isSelected);
      let linesSelected = [...memoOrderLinesSelected, ...sampleOrderLinesSelected];
      if (linesSelected.length == 0) return;

      let linesToUpdate = _.map(linesSelected, orderLine => {
        if (!this.isFormValid(orderLine)) return;
        return {
          Id: orderLine.id,
          MemoOrderId: this.state.memoOrderId,
          TypeOfMemoOrderLineStatus: updateToShipped 
                                          ? constants.MEMO_ORDERLINE_STATUS.SHIPPED 
                                          : updateToBackOrdered
                                              ? constants.MEMO_ORDERLINE_STATUS.BACKORDER
                                              : orderLine.typeOfMemoOrderLineStatus.value,
          LastStatusAt: updateToShipped || updateToBackOrdered ? moment() : orderLine.lastStatusAt,
          ProductId: orderLine.product ? orderLine.product.value : null,
          SampleBookId: orderLine.sampleBook ? orderLine.sampleBook.value : null,
          ProductColorId: orderLine.productColor ? orderLine.productColor.value : null,
          MemoSizeId: orderLine.memoSize ? orderLine.memoSize.value : null,
          Qty: Number(orderLine.qty),
          MemoOrderLineComments: orderLine.orderLineComments,
          CustomerId: this.state.customerId
        };
      });

      api.post(`MemoOrder/UpdateSelectedMemoOrderLines/${this.state.memoOrderId}`, linesToUpdate)
      .then(response => {
          if (response.data.success) { 
              this.setState({
                  // showMemoOrderLineForm: false,
                  memoOrderLineToAddEdit: emptyMemoOrderLine,
                  sampleOrderLineToAddEdit: emptySampleOrderLine,
                  selectAllMemoOrderLinesCheckboxSelected: false,
                  selectAllSampleOrderLinesCheckboxSelected: false
              }, () => this.refreshList());
          } else {
              this.setState({ 
                messageFlavor: "danger",
                message: response.data.message,
                isMessageVisible: true
              });
          }   
      })
      .catch(helpers.catchHandler);
    }


    showMoveSelectedConfirmationModal() {
      let memoOrderLinesSelected = _.filter(this.state.memoOrderLines, orderline => orderline.isSelected);
      let sampleOrderLinesSelected = _.filter(this.state.sampleOrderLines, orderline => orderline.isSelected);
      let linesSelected = [...memoOrderLinesSelected, ...sampleOrderLinesSelected];
      if (linesSelected.length == 0) return;
      this.setState({showMoveSelectedConfirmationModal: true});
    }

    
    moveSelectedMemoOrderLinesToNewOrder() {
      let memoOrderLinesSelected = _.filter(this.state.memoOrderLines, orderline => orderline.isSelected);
      let sampleOrderLinesSelected = _.filter(this.state.sampleOrderLines, orderline => orderline.isSelected);
      let linesSelected = [...memoOrderLinesSelected, ...sampleOrderLinesSelected];
      if (linesSelected.length == 0) return;
      let linesToUpdate = _.map(linesSelected, orderLine => {
        if (!this.isFormValid(orderLine)) return;
        return {
          Id: orderLine.id,
          MemoOrderId: this.state.memoOrderId,
          TypeOfMemoOrderLineStatus: constants.MEMO_ORDERLINE_STATUS.BACKORDER, // orderLine.typeOfMemoOrderLineStatus.value,
          LastStatusAt: orderLine.lastStatusAt,
          ProductId: orderLine.product ? orderLine.product.value : null,
          SampleBookId: orderLine.sampleBook ? orderLine.sampleBook.value : null,
          ProductColorId: orderLine.productColor ? orderLine.productColor.value : null,
          MemoSizeId: orderLine.memoSize ? orderLine.memoSize.value : null,
          Qty: Number(orderLine.qty),
          MemoOrderLineComments: orderLine.orderLineComments,
          CustomerId: this.state.customerId
        };
      });
      this.setState({
        showMoveSelectedConfirmationModal: false
      }, () => {
        this.props.moveSelectedMemoOrderLinesToNewOrder(linesToUpdate);
      })
    }

    showDeleteMemoOrderLineConfirmationModal() {
      let memoOrderLinesSelected = _.filter(this.state.memoOrderLines, orderline => orderline.isSelected);
      let sampleOrderLinesSelected = _.filter(this.state.sampleOrderLines, orderline => orderline.isSelected);
      let linesSelected = [...memoOrderLinesSelected, ...sampleOrderLinesSelected];
      if (linesSelected.length == 0) return;
      this.setState({
        showDeleteMemoOrderLineConfirmationModal: true,
        deletingMultipleOrderLines: true
      });
    }

    deleteSelectedMemoOrderLines() {  // hard delete
      let memoOrderLinesSelected = _.filter(this.state.memoOrderLines, orderline => orderline.isSelected);
      let sampleOrderLinesSelected = _.filter(this.state.sampleOrderLines, orderline => orderline.isSelected);
      let linesSelected = [...memoOrderLinesSelected, ...sampleOrderLinesSelected];
      if (linesSelected.length == 0) return;
      let linesToDelete = _.map(linesSelected, orderLine => {
        return {
          Id: orderLine.id
        };
      });

        api.post(`MemoOrder/HardDeleteSelectedMemoOrderLines`, linesToDelete)
      .then(response => {
          if (response.data.success) { 
            this.setState({
                // showMemoOrderLineForm: false,
                memoOrderLineToAddEdit: emptyMemoOrderLine,
                sampleOrderLineToAddEdit: emptySampleOrderLine,
                selectAllMemoOrderLinesCheckboxSelected: false,
                selectAllSampleOrderLinesCheckboxSelected: false,
                showDeleteMemoOrderLineConfirmationModal: false,
            }, () => this.refreshList());
          } else {
              this.setState({ 
                messageFlavor: "danger",
                message: response.data.message,
                isMessageVisible: true,
                showDeleteMemoOrderLineConfirmationModal: false,
              });
          }   
      })
      .catch(helpers.catchHandler);
    }

    formatOrderLineCommentsForDto(orderLineComments, orderLineId) {
      const orderLineCommentList = [];
        _.map(orderLineComments,
          x => {
            const comment = {
              Id: x.id,
              Dsc: x.dsc,
              OrderLineId: orderLineId,
              CommentTypeId: x.commentType ? x.commentType.value : null,
              MarkedForDeletion: x.markedForDeletion
            };
            orderLineCommentList.push(comment);
          });
      return orderLineCommentList;
    }

    onEditMemoOrderLine(memoOrderLine) {
      this.setState({
        // showMemoOrderLineForm: true,
        memoOrderLineToAddEdit: memoOrderLine,
        memoOrderLineCommentList: memoOrderLine.orderLineComments,
        editingMemoOrderLineNotes: memoOrderLine.orderLineComments.length > 0 ? true : false, 
      }, () => this.fetchProductSelectsForOrderLineToEdit(memoOrderLine.product.value, memoOrderLine.productColor.value));
    }

    onEditSampleOrderLine(sampleOrderLine) {
      this.setState({
        // showSampleOrderLineForm: true,
        sampleOrderLineToAddEdit: sampleOrderLine,
        sampleOrderLineCommentList: sampleOrderLine.orderLineComments,
        editingSampleOrderLineNotes: sampleOrderLine.orderLineComments.length > 0 ? true : false, 
      });
    }

    async fetchProductSelectsForOrderLineToEdit(productId, productColorId) {
      let memoSizeList;
      let productColorsList;

      await api.fetch(`Order/GetActiveProductColors/${productId}`)
      .then(response => {
          productColorsList = response.data
      })
      .catch(helpers.catchHandler);

      await api.fetch(`Product/GetProduct/${productId}`)
      .then(response => {
          memoSizeList = _.filter(response.data.productMemoSizes, function(x) {
              return x.productColorId === productColorId
          })
      })
      .catch(helpers.catchHandler);

      this.setState({
          productColorsList: productColorsList,
          memoSizeList: _.map(memoSizeList, ms => {
              return {
              ...ms,
              value: ms.memoSizeId,
              label: ms.memoSizeName
              }
          })
      });
    }

    handleDelete(id) {  // hard delete
      api.post(`MemoOrder/HardDeleteMemoOrderLine/${id}`)
      .then(response => {
          if (response.data.success) { 
            this.setState({ 
              showDeleteMemoOrderLineConfirmationModal: false,
              memoOrderLineToDeleteId: null
            }, () => this.refreshList());
              // this.refreshList()
          } else {
              this.setState({ 
                messageFlavor: "danger",
                message: response.data.message,
                isMessageVisible: true,
                showDeleteMemoOrderLineConfirmationModal: false,
                memoOrderLineToDeleteId: null
              });
          }  
      }) 
      .catch(helpers.catchHandler);
    }

    onToggleMemoOrderLineNotes() {
      let tempList = _.cloneDeep(this.state.memoOrderLineCommentList);
      if (this.state.editingMemoOrderLineNotes) {
          tempList = _.map(this.state.memoOrderLineCommentList, comment => {
              return {
                  ...comment,
                  markedForDeletion: true
              }
          });
      } else {
          tempList = _.map(this.state.memoOrderLineCommentList, comment => {
              return {
                  ...comment,
                  markedForDeletion: false
              }
          });
      }
      this.setState({
          editingMemoOrderLineNotes: !this.state.editingMemoOrderLineNotes,
          memoOrderLineCommentList: tempList
      });
    }

    onToggleSampleOrderLineNotes() {
      let tempList = _.cloneDeep(this.state.sampleOrderLineCommentList);
      if (this.state.editingSampleOrderLineNotes) {
          tempList = _.map(this.state.sampleOrderLineCommentList, comment => {
              return {
                  ...comment,
                  markedForDeletion: true
              }
          });
      } else {
          tempList = _.map(this.state.sampleOrderLineCommentList, comment => {
              return {
                  ...comment,
                  markedForDeletion: false
              }
          });
      }
      this.setState({
          editingSampleOrderLineNotes: !this.state.editingSampleOrderLineNotes,
          sampleOrderLineCommentList: tempList
      });
    }

    onChangeMemoOrderLineComment(fieldName, value) {
      this.setState({
        newMemoOrderLineComment: {
            ...this.state.newMemoOrderLineComment,
            [fieldName]: value
        }
      })
    }

    onChangeSampleOrderLineComment(fieldName, value) {
      this.setState({
        newSampleOrderLineComment: {
            ...this.state.newSampleOrderLineComment,
            [fieldName]: value
        }
      })
    }

    isMemoOrderLineCommentValid() {
      const warnings = [];
      if (!this.state.newMemoOrderLineComment.dsc) {
          warnings.push("Comment required in order to add.");
      }
      // if (!this.state.newMemoOrderLineComment.commentType) {
      //     warnings.push("To add comment, plesae select what comment applies to.");
      // }
      if (warnings.length) {
          window.scroll(0,0);
        this.setState({ message: warnings.join(' '), messageFlavor: "danger", isMessageVisible: true });
      }
      return warnings.length === 0;
    }

    isSampleOrderLineCommentValid() {
      const warnings = [];
      if (!this.state.newSampleOrderLineComment.dsc) {
          warnings.push("Comment required in order to add.");
      }
      // if (!this.state.newMemoOrderLineComment.commentType) {
      //     warnings.push("To add comment, plesae select what comment applies to.");
      // }
      if (warnings.length) {
          window.scroll(0,0);
        this.setState({ message: warnings.join(' '), messageFlavor: "danger", isMessageVisible: true });
      }
      return warnings.length === 0;
    }

    onSaveMemoOrderLineNote() {
      if (!this.isMemoOrderLineCommentValid()) return;
      const notes = this.state.memoOrderLineCommentList.slice();
      let commentToSave = Object.assign({}, this.state.newMemoOrderLineComment);
      commentToSave = {
          ...commentToSave,
          local_id: -1 * (this.state.memoOrderLineToAddEdit.commentCount + 1)
      }
      this.setState({
          newMemoOrderLineComment: emptyOrderLineComment,
          memoOrderLineCommentList: [...notes, commentToSave], 
          memoOrderLineToAddEdit: {
              ...this.state.memoOrderLineToAddEdit,
              commentCount: this.state.memoOrderLineToAddEdit.commentCount + 1
          }
      });
    }

    onSaveSampleOrderLineNote() {
      if (!this.isSampleOrderLineCommentValid()) return;
      const notes = this.state.sampleOrderLineCommentList.slice();
      let commentToSave = Object.assign({}, this.state.newSampleOrderLineComment);
      commentToSave = {
          ...commentToSave,
          local_id: -1 * (this.state.sampleOrderLineToAddEdit.commentCount + 1)
      }
      this.setState({
          newSampleOrderLineComment: emptyOrderLineComment,
          sampleOrderLineCommentList: [...notes, commentToSave], 
          sampleOrderLineToAddEdit: {
              ...this.state.sampleOrderLineToAddEdit,
              commentCount: this.state.sampleOrderLineToAddEdit.commentCount + 1
          }
      });
    }

    onEditMemoOrderLineComment(commentId) {
      const indexToEdit = _.findIndex(this.state.memoOrderLineCommentList, {local_id: commentId});
      let tempList = _.cloneDeep(this.state.memoOrderLineCommentList);
      const snapshotItem = Object.assign({}, tempList[indexToEdit]);
      tempList[indexToEdit].isEditing = true;
      tempList[indexToEdit].pristine = snapshotItem;
      this.setState({
          memoOrderLineCommentList: tempList
      });
    }

    onEditSampleOrderLineComment(commentId) {
      const indexToEdit = _.findIndex(this.state.sampleOrderLineCommentList, {local_id: commentId});
      let tempList = _.cloneDeep(this.state.sampleOrderLineCommentList);
      const snapshotItem = Object.assign({}, tempList[indexToEdit]);
      tempList[indexToEdit].isEditing = true;
      tempList[indexToEdit].pristine = snapshotItem;
      this.setState({
          sampleOrderLineCommentList: tempList
      });
    }

    updateMemoOrderLineCommentDscField(fieldName, value, commentId) {
      const tempList = this.state.memoOrderLineCommentList.slice();
      const comment = _.find(tempList, x => x.local_id === commentId);
      comment[fieldName] = value;
      this.setState({
          memoOrderLineCommentList: tempList
      });
    }

    updateSampleOrderLineCommentDscField(fieldName, value, commentId) {
      const tempList = this.state.sampleOrderLineCommentList.slice();
      const comment = _.find(tempList, x => x.local_id === commentId);
      comment[fieldName] = value;
      this.setState({
          sampleOrderLineCommentList: tempList
      });
    }

    // updateCommentTypeField(selection, commentId) {
    //   const tempList = this.state.memoOrderLineCommentList.slice();
    //   const comment = _.find(tempList, x => x.local_id === commentId);
    //   comment.commentType = {
    //       value: selection.value,
    //       label: selection.label
    //   }
    //   this.setState({
    //       memoOrderLineCommentList: tempList
    //   }); 
    // }

    onSaveMemoOrderLineEditedComment(commentId) {
      const indexToEdit = _.findIndex(this.state.memoOrderLineCommentList, {local_id: commentId});
      let tempList = this.state.memoOrderLineCommentList.slice();
      tempList[indexToEdit].isEditing = false;
      this.setState({
        memoOrderLineCommentList: tempList,
        message: null,
        messageFlavor: null,
          isMessageVisible: false
      });
    }

    onSaveSampleOrderLineEditedComment(commentId) {
      const indexToEdit = _.findIndex(this.state.sampleOrderLineCommentList, {local_id: commentId});
      let tempList = this.state.sampleOrderLineCommentList.slice();
      tempList[indexToEdit].isEditing = false;
      this.setState({
        sampleOrderLineCommentList: tempList,
        message: null,
        messageFlavor: null,
        isMessageVisible: false
      });
    }

    onCancelMemoOrderLineEditComment(commentId) {
      const indexToEdit = _.findIndex(this.state.memoOrderLineCommentList, {local_id: commentId});
      let tempList = this.state.memoOrderLineCommentList.slice();
      tempList[indexToEdit] = Object.assign({}, tempList[indexToEdit].pristine);
      this.setState({
        memoOrderLineCommentList: tempList,
        message: null,
        messageFlavor: null,
        isMessageVisible: false
      });
    }

    onCancelSampleOrderLineEditComment(commentId) {
      const indexToEdit = _.findIndex(this.state.sampleOrderLineCommentList, {local_id: commentId});
      let tempList = this.state.sampleOrderLineCommentList.slice();
      tempList[indexToEdit] = Object.assign({}, tempList[indexToEdit].pristine);
      this.setState({
        sampleOrderLineCommentList: tempList,
        message: null,
        messageFlavor: null,
        isMessageVisible: false
      });
    }

    onDeleteMemoOrderLineComment(commentId) {
      const indexToEdit = _.findIndex(this.state.memoOrderLineCommentList, {local_id: commentId});
      let tempList = _.cloneDeep(this.state.memoOrderLineCommentList);
      tempList[indexToEdit].markedForDeletion = true;
      this.setState({
          memoOrderLineCommentList: tempList
      });
    }

    onDeleteSampleOrderLineComment(commentId) {
      const indexToEdit = _.findIndex(this.state.sampleOrderLineCommentList, {local_id: commentId});
      let tempList = _.cloneDeep(this.state.sampleOrderLineCommentList);
      tempList[indexToEdit].markedForDeletion = true;
      this.setState({
          sampleOrderLineCommentList: tempList
      });
    }

    onSelectAllMemoOrderLinesCheckbox() {
      if(this.state.selectAllMemoOrderLinesCheckboxSelected) {
        let tempList = _.map(this.state.memoOrderLines, orderline => {
          return {
            ...orderline,
            isSelected: false
          }
        })
        this.setState({
          // idsOfLinesToShipOrBackorder: [],
          memoOrderLines: tempList,
          selectAllMemoOrderLinesCheckboxSelected: !this.state.selectAllMemoOrderLinesCheckboxSelected
        })
      } 
      else {
        let tempList = _.map(this.state.memoOrderLines, orderline => {
          if (orderline.typeOfMemoOrderLineStatus.value !== constants.MEMO_ORDERLINE_STATUS.SHIPPED) {
            return {
              ...orderline,
              isSelected: true
            }
          } else {
            return {
              ...orderline
            }
          }
        })
        this.setState({
          memoOrderLines: tempList,
          selectAllMemoOrderLinesCheckboxSelected: !this.state.selectAllMemoOrderLinesCheckboxSelected
        })
      }
    }

    onSelectAllSampleOrderLinesCheckbox() {
      if(this.state.selectAllSampleOrderLinesCheckboxSelected) {
        let tempList = _.map(this.state.sampleOrderLines, orderline => {
          return {
            ...orderline,
            isSelected: false
          }
        })
        this.setState({
          // idsOfLinesToShipOrBackorder: [],
          sampleOrderLines: tempList,
          selectAllSampleOrderLinesCheckboxSelected: !this.state.selectAllSampleOrderLinesCheckboxSelected
        })
      } 
      else {
        let tempList = _.map(this.state.sampleOrderLines, orderline => {
          if (orderline.typeOfMemoOrderLineStatus.value !== constants.MEMO_ORDERLINE_STATUS.SHIPPED) {
            return {
              ...orderline,
              isSelected: true
            }
          } else {
            return {
              ...orderline
            }
          }
        })
        this.setState({
          sampleOrderLines: tempList,
          selectAllSampleOrderLinesCheckboxSelected: !this.state.selectAllSampleOrderLinesCheckboxSelected
        })
      }
    }

    onSelectIndividualMemoOrderLineCheckbox(orderline) {
      const indexToEdit = _.findIndex(this.state.memoOrderLines, {id: orderline.id});
      let tempList = this.state.memoOrderLines.slice(); 
      tempList[indexToEdit].isSelected = !tempList[indexToEdit].isSelected;
      this.setState({
        memoOrderLines: tempList
      })
    }

    onSelectIndividualSampleOrderLineCheckbox(orderline) {
      const indexToEdit = _.findIndex(this.state.sampleOrderLines, {id: orderline.id});
      let tempList = this.state.sampleOrderLines.slice(); 
      tempList[indexToEdit].isSelected = !tempList[indexToEdit].isSelected;
      this.setState({
        sampleOrderLines: tempList
      })
    }

    onDismiss() {
      this.setState({
        isMessageVisible: false,
        message: null,
        messageFlavor: null
      });
    }

    render() {
        if(this.state.loading) {
            return <div>Loading...</div>
        }
        return (
            <div>
              {this.state.message && (
                  <Row className="mb-2">
                      <Col>
                        <Alert className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={() => this.onDismiss()}>{this.state.message}</Alert>
                      </Col>
                  </Row>
              )}
              <Modal
                  isOpen={this.state.showDeleteMemoOrderLineConfirmationModal}
                  // isOpen={this.state.confirmationModalOrderLineId ? true : false} 
                  toggle={() => this.setState({showDeleteMemoOrderLineConfirmationModal: !this.state.showDeleteMemoOrderLineConfirmationModal})}
              >
                  <ModalHeader>{this.state.deletingMultipleOrderLines ? 'Delete Order Lines' :  'Delete Order Line?'}</ModalHeader>
                  <Form>
                    <ModalBody className="mb-4">
                      <Label>{this.state.deletingMultipleOrderLines ? 'Are you sure you want to permanently delete these order lines?' :  'Are you sure you want to permanently delete this order line?'}</Label>
                    </ModalBody>
                    <ModalFooter>
                      <ButtonGroup>
                        <Button 
                            className="secondary" 
                            onClick={ () => this.setState({ showDeleteMemoOrderLineConfirmationModal: false })}
                        > Cancel
                        </Button>
                        <Button className="danger" 
                                onClick={() => {
                                  if (this.state.deletingMultipleOrderLines) {
                                    this.deleteSelectedMemoOrderLines()
                                  } else {
                                    this.handleDelete(this.state.memoOrderLineToDeleteId)}
                                  }
                                }
                        >
                            Delete
                        </Button>
                      </ButtonGroup>
                    </ModalFooter>
                  </Form>
              </Modal>
              <Modal
                  isOpen={this.state.showMoveSelectedConfirmationModal}
                  // isOpen={this.state.confirmationModalOrderLineId ? true : false} 
                  toggle={() => this.setState({showMoveSelectedConfirmationModal: !this.state.showMoveSelectedConfirmationModal})}
              >
                  <ModalHeader>Move Selected Order Lines</ModalHeader>
                  <Form>
                    <ModalBody className="mb-4">
                      <Label>Are you sure you want to permanently move the selected order lines to a new order?</Label>
                    </ModalBody>
                    <ModalFooter>
                      <ButtonGroup>
                        <Button 
                          className="secondary" 
                          onClick={ () => this.setState({ showMoveSelectedConfirmationModal: false })}
                        > Cancel
                        </Button>
                        <Button 
                          className="danger" 
                          onClick={() => this.moveSelectedMemoOrderLinesToNewOrder()}
                        >
                          Create New Order
                        </Button>
                      </ButtonGroup>
                    </ModalFooter>
                  </Form>
              </Modal>
              <Row className="row-spacing expand-md mt-2 mb-0 pb-0">
                  <Col>
                      <h5>Memo Order Lines</h5>
                  </Col>
              </Row>
              <Collapse isOpen={this.state.showMemoOrderLineForm} className="row-spacing">
                  <Card>
                      <CardBody>
                          <Row>
                              <Col>
                                  <h6 className="page-title">{this.state.memoOrderLineToAddEdit.id > 0 ? 'Update Selected' : 'Add New'}</h6>
                              </Col>
                          </Row>
                          <Row className="mb-4">
                              <Col>Status {helpers.requiredStar()}
                                    <Select
                                        closeMenuOnSelect
                                        value={this.state.memoOrderLineToAddEdit.typeOfMemoOrderLineStatus}
                                        options={this.state.memoOrderLineStatusList}
                                        onChange={(option) => this.setMemoOrderLineStatus(option)}
                                    />
                              </Col>
                              <Col>Status Date {helpers.requiredStar()}
                                <DatePicker
                                  id="lastStatusAt"
                                  selected={date_helpers.dateFormatForClient(this.state.memoOrderLineToAddEdit.lastStatusAt)}
                                  onChange={this.setMemoOrderLineLastStatusAtDate}
                                  className="form-control"
                                />
                              </Col>
                              <Col>Style {helpers.requiredStar()}
                                <AsyncSelect
                                    loading={this.state.loadingProductOptions}
                                    loadOptions={_.debounce(this.refreshProductOptions, 250)}
                                    defaultOptions={this.state.productList}
                                    onChange={this.setSelectedProduct}
                                    value={this.state.memoOrderLineToAddEdit.product}
                                />
                              </Col>
                              <Col>Color {helpers.requiredStar()}
                                  <Select
                                      isClearable
                                      closeMenuOnSelect
                                      value={this.state.memoOrderLineToAddEdit.productColor}
                                      options={this.state.productColorsList}
                                      onChange={(option) => this.setSelectedProductColor(option)}
                                  />
                              </Col>
                              <Col>Size {helpers.requiredStar()}
                                  <Select
                                      closeMenuOnSelect
                                      value={this.state.memoOrderLineToAddEdit.memoSize}
                                      options={this.state.memoSizeList}
                                      onChange={(option) => this.setSelectedMemoSize(option)}
                                  />
                              </Col>
                              <Col>Quantity {helpers.requiredStar()}
                                <Input
                                    type="number"
                                    step="1"
                                    name="qty"
                                    onChange={(event) => this.handleMemoOrderLineQtyChange(
                                        event.target.value
                                    )}
                                    value={this.state.memoOrderLineToAddEdit.qty}
                                />
                            </Col>
                          </Row>
                          {this.state.editingMemoOrderLineNotes && (
                                <div>
                                    <h5><Badge className="mb-2 primary">Notes</Badge></h5>
                                    <Row className="mb-4">
                                        <Col xs="4">Notes (internal only) {helpers.requiredStar()}
                                            <Input
                                                type="text"
                                                name="dsc"
                                                value={this.state.newMemoOrderLineComment.dsc}
                                                onChange={(event) => this.onChangeMemoOrderLineComment(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                maxLength="250"
                                            />
                                        </Col>
                                        <Col xs="1"> 
                                            <Button
                                                className="success mt-4"
                                                size="sm"
                                                onClick={this.onSaveMemoOrderLineNote}
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add Note
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs="7">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.memoOrderLineCommentList.map(c => (
                                                        <tr key={c.local_id}>
                                                            {c.isEditing
                                                            ?   <React.Fragment>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            name="dsc"
                                                                            value={c.dsc}
                                                                            onChange={(event) => this.updateMemoOrderLineCommentDscField(
                                                                                event.target.name,
                                                                                event.target.value,
                                                                                c.local_id
                                                                            )}
                                                                            maxLength="250"
                                                                        />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                className="primary btn"
                                                                                onClick={() => this.onSaveMemoOrderLineEditedComment(c.local_id)}
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon icon="save" /> Save
                                                                            </Button>
                                                                            <Button
                                                                                className="secondary btn"
                                                                                onClick={() => this.onCancelMemoOrderLineEditComment(c.local_id)}
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </td>
                                                                </React.Fragment>
                                                            :   <React.Fragment>
                                                                {c.deactivatedAt || c.markedForDeletion
                                                                ?   null
                                                                :   <React.Fragment>
                                                                        <td>{c.dsc}</td>
                                                                        <td className="text-right">
                                                                            <ButtonGroup>
                                                                                <Button
                                                                                    className="primary btn-outline-secondary"
                                                                                    size="sm"
                                                                                    onClick={this.onEditMemoOrderLineComment.bind(this, c.local_id)}
                                                                                    title="Edit comment"
                                                                                >
                                                                                    <FontAwesomeIcon icon="edit" />
                                                                                </Button>
                                                                                {c.deactivatedAt ? (
                                                                                    <Button
                                                                                    className="info"
                                                                                    size="sm"
                                                                                    // onClick={this.handleOrderLineUndelete.bind(this, orderLine.id)}
                                                                                    title="Revive comment"
                                                                                >
                                                                                    <FontAwesomeIcon icon="recycle" /> Revive
                                                                                </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        className="danger btn-outline-secondary"
                                                                                        size="sm"
                                                                                        onClick={this.onDeleteMemoOrderLineComment.bind(this, c.local_id)}
                                                                                        title="Delete comment"
                                                                                    >
                                                                                        <FontAwesomeIcon icon="trash" />
                                                                                    </Button>
                                                                                )} 
                                                                            </ButtonGroup>
                                                                        </td>
                                                                    </React.Fragment>
                                                                }     
                                                                </React.Fragment>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                          <Row>
                              <Col>
                                <ButtonGroup size="sm" className="float-left">
                                    <Button
                                        className="secondaryGray"
                                        onClick={() => this.onToggleMemoOrderLineNotes()}
                                    >
                                        {this.state.editingMemoOrderLineNotes ? 'Remove Notes' : 'Add Notes'}
                                    </Button>
                                </ButtonGroup>
                                  <ButtonGroup className="btn-group btn-access float-right">
                                      <Button
                                          size="sm"
                                          className="btn primary"
                                          onClick={() => this.state.memoOrderLines.length > 0 || this.state.sampleOrderLines.length > 0
                                                            ? this.state.memoOrderLineToAddEdit.id
                                                                ?   this.updateMemoOrderLine(this.state.memoOrderLineToAddEdit)
                                                                :   this.saveNewMemoOrderLine(this.state.memoOrderLineToAddEdit) 
                                                            : this.saveNewMemoOrderWithOrderLine(this.state.memoOrderLineToAddEdit)}
                                      >
                                          <FontAwesomeIcon icon="save" /> Save
                                      </Button>
                                      {this.state.memoOrderLineToAddEdit.id > 0 
                                        ? <Button
                                              size="sm"
                                              className="btn secondary"
                                              onClick={() => this.toggleAddMemoOrderLineCollapse()}
                                          >
                                              <FontAwesomeIcon icon="times-circle" /> Cancel
                                          </Button>
                                        : null
                                      }
                                  </ButtonGroup>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Collapse>
              <Row className="row-spacing">
                  <Col>
                      <Table striped hover size="sm">
                          <thead>
                              <tr>
                                  <th style={{width: "3%"}}>
                                    <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                      <Input
                                        type="checkbox"
                                        checked={this.state.selectAllMemoOrderLinesCheckboxSelected}
                                        onChange={this.onSelectAllMemoOrderLinesCheckbox}
                                        className="headerRowCheckbox"
                                      />
                                    </div>
                                  </th>
                                  <th style={{width: "8%"}}>
                                      <SortHeader
                                          displayName="Status"
                                          field="Status"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'Status'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "12%"}}></th>
                                  <th style={{width: "9%"}}>
                                      <SortHeader
                                          displayName="Status Date"
                                          field="LastStatusAt"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'LastStatusAt'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "20%"}}>
                                      <SortHeader
                                          displayName="Style"
                                          field="Style"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'Style'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "15%"}}>
                                      <SortHeader
                                          displayName="Color"
                                          field="ProductColor"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'ProductColor'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "5%"}}>
                                      <SortHeader
                                          displayName="Shelf"
                                          field="productMemoShelfNumber"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'productMemoShelfNumber'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "10%"}}>
                                      <SortHeader
                                          displayName="Size"
                                          field="MemoSize"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'MemoSize'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "7%"}}>
                                      <SortHeader
                                          displayName="Qty Ordered"
                                          field="QtyOrdered"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'QtyOrdered'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "5%"}}>
                                      <SortHeader
                                          displayName="In Stock"
                                          field="QtyInStock"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'QtyInStock'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th width="15%" />
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.memoOrderLines.map(ol => (
                                  <tr key={ol.id} className="data-row" style={this.state.memoOrderLineToAddEdit.id == ol.id ? {border: "3px solid #f5d442"} : null}>
                                    <td>
                                      <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                      <Input 
                                          type="checkbox"
                                          checked={ol.isSelected}
                                          onChange={() => this.onSelectIndividualMemoOrderLineCheckbox(ol)}
                                          disabled={ol.typeOfMemoOrderLineStatus.value === constants.MEMO_ORDERLINE_STATUS.SHIPPED}
                                      />
                                      </div>
                                    </td>
                                      <td>{ol.typeOfMemoOrderLineStatus.label}</td>
                                      <td>
                                        <div>
                                            {ol.typeOfMemoOrderLineStatus.value !== constants.MEMO_ORDERLINE_STATUS.SHIPPED &&
                                                // && ol.productMemoSize && ol.productMemoSize.qty > 0 && 
                                                <Button
                                                    className="mx-1 info"
                                                    onClick={() => this.updateMemoOrderLine(ol, true, null)}
                                                    size="sm"
                                                >
                                                    Ship
                                                </Button>
                                            }
                                            {ol.typeOfMemoOrderLineStatus.value === constants.MEMO_ORDERLINE_STATUS.PENDING &&
                                                <Button
                                                    className="mx-1 warning"
                                                    onClick={() => this.updateMemoOrderLine(ol, null, true)}
                                                    size="sm"
                                                >
                                                    Backorder
                                                </Button>
                                            }
                                        </div>
                                      </td>
                                      <td>{date_helpers.formatDateToShortDate(ol.lastStatusAt)}</td>
                                      <td>
                                        {ol.product ? ol.product.label : ol.sampleBook.label}

                                        {ol.orderLineComments.length > 0 ? 
                                          ol.orderLineComments.map(c => {
                                            if (c.deactivatedAt == null) {
                                              return <div key={c.id}><span className="boldText">{c.dsc}</span> @ {moment(c.createdAt).format("M/DD/YY h:mm a")}</div>
                                            }
                                          }) : null}
                                      </td>
                                      <td>{ol.productColor ? ol.productColor.label : null}</td>
                                      <td>{ol.productMemoShelfNumber ? ol.productMemoShelfNumber : ''}</td> 
                                      <td>{ol.productMemoSize ? ol.memoSize.label : ''}</td>
                                      <td className="text-center">{ol.qty ? ol.qty : ''}</td>
                                      <td className="text-center">{ol.productMemoSize ? ol.productMemoSize.qty : ''}</td>
                                      <td className="text-right">
                                          <ButtonGroup>
                                            <div>
                                              <React.Fragment>
                                                  <Button
                                                      className="primary btn-outline-secondary"
                                                      onClick={() => this.onEditMemoOrderLine(ol)}
                                                      size="sm"
                                                      // disabled={this.state.showSampleOrderLineForm}
                                                  >
                                                      <FontAwesomeIcon icon="edit" />
                                                  </Button>
                                                  <Button
                                                      className="danger btn-outline-secondary"
                                                      size="sm"
                                                      onClick={() => this.setState({
                                                        showDeleteMemoOrderLineConfirmationModal: true,
                                                        memoOrderLineToDeleteId: ol.id
                                                      })}
                                                  >
                                                      <FontAwesomeIcon icon="trash" />
                                                  </Button>
                                              </React.Fragment>
                                            </div>
                                          </ButtonGroup>
                                      </td>
                                  </tr>
                              ))}
                          </tbody>
                      </Table>
                  </Col>
              </Row>

              <Row className="row-spacing expand-md mt-2 mb-0 pb-0">
                  <Col>
                      <h5>Sample Order Lines</h5>
                  </Col>
              </Row>
              <Collapse isOpen={this.state.showSampleOrderLineForm} className="row-spacing">
                  <Card>
                      <CardBody>
                          <Row>
                              <Col>
                                  <h6 className="page-title">{this.state.sampleOrderLineToAddEdit.id > 0 ? 'Update Selected' : 'Add New'}</h6>
                              </Col>
                          </Row>
                          <Row className="mb-4">
                              <Col>Status {helpers.requiredStar()}
                                <Select
                                    closeMenuOnSelect
                                    value={this.state.sampleOrderLineToAddEdit.typeOfMemoOrderLineStatus}
                                    options={this.state.memoOrderLineStatusList}
                                    onChange={(option) => this.setSampleOrderLineStatus(option)}
                                />
                              </Col>
                              <Col>Status Date {helpers.requiredStar()}
                                <DatePicker
                                    id="lastStatusAt"
                                    selected={date_helpers.dateFormatForClient(this.state.sampleOrderLineToAddEdit.lastStatusAt)}
                                    onChange={this.setSampleOrderLineLastStatusAtDate}
                                    className="form-control"
                                />
                              </Col>
                              <Col>Samples/Books {helpers.requiredStar()}
                                <Select
                                  closeMenuOnSelect
                                  value={this.state.sampleOrderLineToAddEdit.sampleBook}
                                  options={this.state.sampleBookList}
                                  onChange={(option) => this.onSampleBookChange(option)}
                                />
                                {/* <AsyncSelect
                                  loading={this.state.loadingSampleBookOptions}
                                  loadOptions={_.debounce(this.refreshSampleBookOptions, 250)}
                                  defaultOptions={this.state.sampleBookList}
                                  onChange={this.onSampleBookChange}
                                  value={this.state.sampleOrderLineToAddEdit.sampleBook}
                                /> */}
                              </Col>
                              <Col>Quantity {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  step="1"
                                  name="qty"
                                  onChange={(event) => this.handleSampleOrderLineQtyChange(
                                      event.target.value
                                  )}
                                  value={this.state.sampleOrderLineToAddEdit.qty}
                                />
                            </Col>
                          </Row>
                          {this.state.editingSampleOrderLineNotes && (
                                <div>
                                    <h5><Badge className="mb-2 primary">Notes</Badge></h5>
                                    <Row className="mb-4">
                                        <Col xs="4">Notes (internal only) {helpers.requiredStar()}
                                            <Input
                                                type="text"
                                                name="dsc"
                                                value={this.state.newSampleOrderLineComment.dsc}
                                                onChange={(event) => this.onChangeSampleOrderLineComment(
                                                    event.target.name,
                                                    event.target.value
                                                )}
                                                maxLength="250"
                                            />
                                        </Col>
                                        <Col xs="1"> 
                                            <Button
                                                className="success mt-4"
                                                size="sm"
                                                onClick={this.onSaveSampleOrderLineNote}
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add Note
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs="7">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.sampleOrderLineCommentList.map(c => (
                                                        <tr key={c.local_id}>
                                                            {c.isEditing
                                                            ?   <React.Fragment>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            name="dsc"
                                                                            value={c.dsc}
                                                                            onChange={(event) => this.updateSampleOrderLineCommentDscField(
                                                                                event.target.name,
                                                                                event.target.value,
                                                                                c.local_id
                                                                            )}
                                                                            maxLength="250"
                                                                        />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                className="primary btn"
                                                                                onClick={() => this.onSaveSampleOrderLineEditedComment(c.local_id)}
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon icon="save" /> Save
                                                                            </Button>
                                                                            <Button
                                                                                className="secondary btn"
                                                                                onClick={() => this.onCancelSampleOrderLineEditComment(c.local_id)}
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon icon="times-circle" /> Cancel
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </td>
                                                                </React.Fragment>
                                                            :   <React.Fragment>
                                                                {c.deactivatedAt || c.markedForDeletion
                                                                ?   null
                                                                :   <React.Fragment>
                                                                        <td>{c.dsc}</td>
                                                                        <td className="text-right">
                                                                            <ButtonGroup>
                                                                                <Button
                                                                                    className="primary btn-outline-secondary"
                                                                                    size="sm"
                                                                                    onClick={this.onEditSampleOrderLineComment.bind(this, c.local_id)}
                                                                                    title="Edit comment"
                                                                                >
                                                                                    <FontAwesomeIcon icon="edit" />
                                                                                </Button>
                                                                                {c.deactivatedAt ? (
                                                                                    <Button
                                                                                    className="info"
                                                                                    size="sm"
                                                                                    // onClick={this.handleOrderLineUndelete.bind(this, orderLine.id)}
                                                                                    title="Revive comment"
                                                                                >
                                                                                    <FontAwesomeIcon icon="recycle" /> Revive
                                                                                </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        className="danger btn-outline-secondary"
                                                                                        size="sm"
                                                                                        onClick={this.onDeleteSampleOrderLineComment.bind(this, c.local_id)}
                                                                                        title="Delete comment"
                                                                                    >
                                                                                        <FontAwesomeIcon icon="trash" />
                                                                                    </Button>
                                                                                )} 
                                                                            </ButtonGroup>
                                                                        </td>
                                                                    </React.Fragment>
                                                                }     
                                                                </React.Fragment>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                          <Row>
                              <Col>
                                <ButtonGroup size="sm" className="float-left">
                                    <Button
                                        className="secondaryGray"
                                        onClick={() => this.onToggleSampleOrderLineNotes()}
                                    >
                                        {this.state.editingSampleOrderLineNotes ? 'Remove Notes' : 'Add Notes'}
                                    </Button>
                                </ButtonGroup>
                                  <ButtonGroup className="btn-group btn-access float-right">
                                      <Button
                                          size="sm"
                                          className="btn primary"
                                          onClick={() => this.state.memoOrderLines.length > 0 || this.state.sampleOrderLines.length > 0 
                                                            ? this.state.sampleOrderLineToAddEdit.id
                                                                ?   this.updateMemoOrderLine(this.state.sampleOrderLineToAddEdit)
                                                                :   this.saveNewMemoOrderLine(this.state.sampleOrderLineToAddEdit) 
                                                            : this.saveNewMemoOrderWithOrderLine(this.state.sampleOrderLineToAddEdit)}
                                      >
                                          <FontAwesomeIcon icon="save" /> Save
                                      </Button>
                                      {this.state.sampleOrderLineToAddEdit.id > 0 
                                        ? <Button
                                            size="sm"
                                            className="btn secondary"
                                            onClick={() => this.toggleAddSampleOrderLineCollapse()}
                                          >
                                            <FontAwesomeIcon icon="times-circle" /> Cancel
                                          </Button>
                                        : null
                                      }
                                  </ButtonGroup>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Collapse>
              <Row className="row-spacing mb-3">
                  <Col>
                      <Table striped hover size="sm">
                          <thead>
                              <tr>
                                  <th style={{width: "3%"}}>
                                    <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                      <Input
                                        type="checkbox"
                                        checked={this.state.selectAllSampleOrderLinesCheckboxSelected}
                                        onChange={this.onSelectAllSampleOrderLinesCheckbox}
                                        className="headerRowCheckbox"
                                      />
                                    </div>
                                  </th>
                                  <th style={{width: "8%"}}>
                                      <SortHeader
                                          displayName="Status"
                                          field="Status"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'Status'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "12%"}}></th>
                                  <th style={{width: "9%"}}>
                                      <SortHeader
                                          displayName="Status Date"
                                          field="LastStatusAt"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'LastStatusAt'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "20%"}}>
                                      <SortHeader
                                          displayName="Sample"
                                          field="Sample"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'Sample'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "15%"}}>
                                      <SortHeader
                                          displayName="Cust Rec Date"
                                          field="custRcvdDate"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'custRcvdDate'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "5%"}}></th>
                                  <th style={{width: "10%"}}></th>
                                  <th style={{width: "7%"}}>
                                      <SortHeader
                                          displayName="Qty Ordered"
                                          field="QtyOrdered"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'QtyOrdered'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th style={{width: "5%"}}>
                                      <SortHeader
                                          displayName="In Stock"
                                          field="QtyInStock"
                                          sortDir={this.state.sort_dir}
                                          sorted={this.state.sort_field === 'QtyInStock'}
                                          callBack={this.doSort}
                                      />
                                  </th>
                                  <th width="15%" />
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.sampleOrderLines.map(ol => (
                                  <tr key={ol.id} className="data-row" style={this.state.sampleOrderLineToAddEdit.id == ol.id ? {border: "3px solid #f5d442"} : null}>
                                    <td>
                                      <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                      <Input 
                                          type="checkbox"
                                          checked={ol.isSelected}
                                          onChange={() => this.onSelectIndividualSampleOrderLineCheckbox(ol)}
                                          disabled={ol.typeOfMemoOrderLineStatus.value === constants.MEMO_ORDERLINE_STATUS.SHIPPED}
                                      />
                                      </div>
                                    </td>
                                      <td>{ol.typeOfMemoOrderLineStatus.label}</td>
                                      <td>
                                        <div>
                                            {ol.typeOfMemoOrderLineStatus.value !== constants.MEMO_ORDERLINE_STATUS.SHIPPED &&
                                                // && ol.productMemoSize && ol.productMemoSize.qty > 0 && 
                                                <Button
                                                    className="mx-1 info"
                                                    onClick={() => this.updateMemoOrderLine(ol, true, null)}
                                                    size="sm"
                                                >
                                                    Ship
                                                </Button>
                                            }
                                            {ol.typeOfMemoOrderLineStatus.value === constants.MEMO_ORDERLINE_STATUS.PENDING &&
                                                <Button
                                                    className="mx-1 warning"
                                                    onClick={() => this.updateMemoOrderLine(ol, null, true)}
                                                    size="sm"
                                                >
                                                    Backorder
                                                </Button>
                                            }
                                        </div>
                                      </td>
                                      <td>{date_helpers.formatDateToShortDate(ol.lastStatusAt)}</td>
                                      <td>
                                        {ol.sampleBook.label}
                                        {ol.orderLineComments.length > 0 ? 
                                          ol.orderLineComments.map(c => {
                                            if (c.deactivatedAt == null) {
                                              return <div key={c.id}><span className="boldText">{c.dsc}</span> @ {moment(c.createdAt).format("M/DD/YY h:mm a")}</div>
                                            }
                                          }) : null}
                                      </td>
                                      <td>{date_helpers.formatDateToShortDate(ol.lastSampleReceivedAt)}</td>
                                      <td></td> 
                                      <td></td>
                                      <td className="text-center">{ol.qty ? ol.qty : ''}</td>
                                      <td className="text-center">{ol.sampleBookStock}</td>
                                      <td className="text-right">
                                          <ButtonGroup>
                                            <div>
                                              <React.Fragment>
                                                  <Button
                                                      className="primary btn-outline-secondary"
                                                      onClick={() => this.onEditSampleOrderLine(ol)}
                                                      size="sm"
                                                      // disabled={this.state.showSampleOrderLineForm}
                                                  >
                                                      <FontAwesomeIcon icon="edit" />
                                                  </Button>
                                                  <Button
                                                      className="danger btn-outline-secondary"
                                                      size="sm"
                                                      onClick={() => this.setState({
                                                        showDeleteMemoOrderLineConfirmationModal: true,
                                                        memoOrderLineToDeleteId: ol.id
                                                      })}
                                                  >
                                                      <FontAwesomeIcon icon="trash" />
                                                  </Button>
                                              </React.Fragment>
                                            </div>
                                          </ButtonGroup>
                                      </td>
                                  </tr>
                              ))}
                          </tbody>
                      </Table>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonGroup>
                    <Button
                        size="sm"
                        className="info"
                        onClick={() => this.updateSelectedMemoOrderLines(true, null)}
                        title="Ship selected"
                    >
                        {/* <FontAwesomeIcon icon="plus" /> */}
                          Ship Selected
                    </Button>
                    <Button
                        size="sm"
                        className="dark"
                        onClick={() => this.updateSelectedMemoOrderLines(null, true)}
                        title="Backorder selected"
                    >
                        {/* <FontAwesomeIcon icon="plus" /> */}
                          Backorder Selected
                    </Button>
                    <Button
                        size="sm"
                        className="danger"
                        onClick={() => this.showDeleteMemoOrderLineConfirmationModal()}
                        title="Delete selected"
                    >
                        {/* <FontAwesomeIcon icon="plus" /> */}
                          Delete Selected
                    </Button>
                    <Button
                        size="sm"
                        className="warning"
                        onClick={() => this.showMoveSelectedConfirmationModal()}
                        title="Backorder selected"
                    >
                        {/* <FontAwesomeIcon icon="plus" /> */}
                          Move Selected
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </div>
        );
    }
}

export default MemoOrderLines;
