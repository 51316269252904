import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

const API = '/api';
// const MAX_CACHE_SERVE_COUNT = 20;
// let cache = {
//     locations: { list: null, serve_count: 0 }
// };

const redirectToSquarespacePage = (pathname) => {
  let href;
  if (pathname) {
    href = `https://www.distinctivecarpets.com${pathname}`;
  } else {
    href = `https://www.distinctivecarpets.com`;
  }
  var anchor = window.document.createElement("a");
  anchor.target = "_parent"
  anchor.href = href;
  anchor.click();
}

const clearCookies = () => {
  return _.map(["SQ_CUSTOMER", "SQ_TOKEN"], key => {
  // setting to nothing first, which should update the Squarespace cookie as well
  // old date
  Cookies.set(key, "", { expires: new Date('1970-12-17T03:24:00') });
  Cookies.remove(key, { domain: '.distinctivecarpets.com' })
  Cookies.remove(key, { domain: 'qa.distinctivecarpets.com' })
  Cookies.remove(key, { domain: 'app.distinctivecarpets.com' })
  Cookies.remove(key, { domain: '*.distinctivecarpets.com' })
})};

const getCookie = (key, parseJSONvalue) => {
    // TESTING ONLY
    // return "32FLcpbQKQTw74RjlwxrrW5nQgbBbtQuKLMJSbNrB0wSkKmEAaWUoTG8HLpxalX6uAawJ4UFd67DOYCwQBFLj6ju+oGRZE9zkBCLqirHgt7UFw1OpStDighz0snXxrKuXb8EKDpxLaeJSfnzvKoc9IZIDSPvSut4cLAYR9UfjqpA5Yj06P0KVarQWeWlcCsVYoFPVLlAIIu1O4KEe+kkLjXPL4mhSohn7vMIwE2EO1ysUpJgCj1L9jC2u9FXgnBDiqQkjhAcswuV+0VSgkRmNrPOSl0FJD/xNjgzeptUl+5tpFFhWTnBElc3h8jc+4YQPGHyJpxDL+VcST0Fh+hFTWg8a/WOKBNFOijtDw20ZCHiFxN8SiwgnV87DXVeHjODk4kYdGNWm5MTPVpewhyMvSsX5grCaBLvZ2P0sw5aQe/7pxdhQX1cQzTztVQu7z4hnwKazx9fqXgK9TuSYa+aP29Zl+skfAuJCAfTHNwlU6wfuEI+cFBHRAgIiPGAHdjXGcmJiJ4ryg53bOGtatkKaia98ib9qQVlsAG7x0OpGzj0Ra9ulpY/v5HDRUvBrMqk1Kf9kGLO6m5uhxlGE2I/raBwkifF6CDgva0GLlIuqndPK3zqrm++NmnM0pjx8aTx/Bo+fHcBmFxDFvQp2/av5uAbWGUBIJ4IwvjK3LdQmAvCzxKXhbm3fokB5CUlieGaAFz91S0YbKZRSIQcj5yseqCgFG3LXOlSWRFReKDHMclQ6l5hzSlg6NTCz1CSZndc1TiE6L+pVvxUAogJv0VSjnAY16w3Vsif/sLj8JMeh493l5Brd+JskTaaQhpX+otdjJntc+S9Mp3kKT947TUp6wqvKpXnTQeOlvUycSD43dbOos+FKki/kngefq3qtsBa/7Y2aUfikEsfedFmMMWaTQM7nLvIWtmM1d5WgWZWIzKMLUwAqhmuQz9ykCFXRz6eNHixyuymWskOX3lm3MgFGYWCnib9n+D1fpvQhCSBOHQOALVY/MfQdN09w4rQxSg69KwkQJ/aiD4tuswevIx1BWyIKtSBCW2VMmvU4kL20kGxJ8NaRFiqb10Y7G2alye1xgzUDlHrneBNqoRvUbvj00IeI89lXvG3a8wWE1i42BHRNMJVmQnRMhjqZEscH+bSLNBSJPeiEjo+imKa2nR73s7KGITI35wU7l0g/nTrOEfhPXPENa37r9nyy3dGBYHnAoUJxcbTvzw/XqdVgkcDY98B7cxW0iyZ8L6S/ictxH4HlHcsseuyRmaDaQ3kezhOuD1x0XDSYUtNXKMAHGIG96VLmPWFFX3ksp9Iea+UAikoxCIXLIFG7G6cSKtk/rqLE6X9z1Nl6o6+Jd/rrpEZFiIaC/8Tt4IiHSiZ/7U3nBtElw9/3ZeshvjzAG7kSlctfYNxcd9MMSSwO5sukeQ5yIOyQLi7Fzp5NtUcNi1c1xIGLhW8jplx/FJUvjJfC/1E/App+c1uX48+EjCAAOTumG9TGVZ0CYfHGf3rK66DefXX87PXfiVvYktzpYLDYp6XydHibxkLlIBO+wWQpMgMzKO7vBEqTtNz3cBtfSvQwUY=";
  const k = Cookies.get(key);
  if (k) {
    if (parseJSONvalue) {
      let string = k.replace(/\\/g, "");
      // removing double quotes at the beginning
      string = string.substring(1);
      // removing double quotes at the end
      string = string.slice(0, -1);
      const item = {};
      const parsedItem = JSON.parse(string);
      if (parsedItem) {
        _.map(parsedItem, (value, key) => {
            item[_.camelCase(key)] = value;
        })
      }
      if (key === 'SQ_CUSTOMER') {
        console.log('SQ_CUSTOMER parsed cookie', item)
      }
      return item;
    }
    return k
  };
  return "";
};

function customerToken() {
    let token;
    try {
      token = getCookie('SQ_TOKEN', false);
      if (token) {
        return token;
      } else {
        // for local development
        token = localStorage.getItem('customerToken')
      }
    } finally {
      return token;
    }
};

const getCookieTimeout = (key) => {
    // TESTING ONLY
    // return "32FLcpbQKQTw74RjlwxrrW5nQgbBbtQuKLMJSbNrB0wSkKmEAaWUoTG8HLpxalX6uAawJ4UFd67DOYCwQBFLj6ju+oGRZE9zkBCLqirHgt7UFw1OpStDighz0snXxrKuXb8EKDpxLaeJSfnzvKoc9IZIDSPvSut4cLAYR9UfjqpA5Yj06P0KVarQWeWlcCsVYoFPVLlAIIu1O4KEe+kkLjXPL4mhSohn7vMIwE2EO1ysUpJgCj1L9jC2u9FXgnBDiqQkjhAcswuV+0VSgkRmNrPOSl0FJD/xNjgzeptUl+5tpFFhWTnBElc3h8jc+4YQPGHyJpxDL+VcST0Fh+hFTWg8a/WOKBNFOijtDw20ZCHiFxN8SiwgnV87DXVeHjODk4kYdGNWm5MTPVpewhyMvSsX5grCaBLvZ2P0sw5aQe/7pxdhQX1cQzTztVQu7z4hnwKazx9fqXgK9TuSYa+aP29Zl+skfAuJCAfTHNwlU6wfuEI+cFBHRAgIiPGAHdjXGcmJiJ4ryg53bOGtatkKaia98ib9qQVlsAG7x0OpGzj0Ra9ulpY/v5HDRUvBrMqk1Kf9kGLO6m5uhxlGE2I/raBwkifF6CDgva0GLlIuqndPK3zqrm++NmnM0pjx8aTx/Bo+fHcBmFxDFvQp2/av5uAbWGUBIJ4IwvjK3LdQmAvCzxKXhbm3fokB5CUlieGaAFz91S0YbKZRSIQcj5yseqCgFG3LXOlSWRFReKDHMclQ6l5hzSlg6NTCz1CSZndc1TiE6L+pVvxUAogJv0VSjnAY16w3Vsif/sLj8JMeh493l5Brd+JskTaaQhpX+otdjJntc+S9Mp3kKT947TUp6wqvKpXnTQeOlvUycSD43dbOos+FKki/kngefq3qtsBa/7Y2aUfikEsfedFmMMWaTQM7nLvIWtmM1d5WgWZWIzKMLUwAqhmuQz9ykCFXRz6eNHixyuymWskOX3lm3MgFGYWCnib9n+D1fpvQhCSBOHQOALVY/MfQdN09w4rQxSg69KwkQJ/aiD4tuswevIx1BWyIKtSBCW2VMmvU4kL20kGxJ8NaRFiqb10Y7G2alye1xgzUDlHrneBNqoRvUbvj00IeI89lXvG3a8wWE1i42BHRNMJVmQnRMhjqZEscH+bSLNBSJPeiEjo+imKa2nR73s7KGITI35wU7l0g/nTrOEfhPXPENa37r9nyy3dGBYHnAoUJxcbTvzw/XqdVgkcDY98B7cxW0iyZ8L6S/ictxH4HlHcsseuyRmaDaQ3kezhOuD1x0XDSYUtNXKMAHGIG96VLmPWFFX3ksp9Iea+UAikoxCIXLIFG7G6cSKtk/rqLE6X9z1Nl6o6+Jd/rrpEZFiIaC/8Tt4IiHSiZ/7U3nBtElw9/3ZeshvjzAG7kSlctfYNxcd9MMSSwO5sukeQ5yIOyQLi7Fzp5NtUcNi1c1xIGLhW8jplx/FJUvjJfC/1E/App+c1uX48+EjCAAOTumG9TGVZ0CYfHGf3rK66DefXX87PXfiVvYktzpYLDYp6XydHibxkLlIBO+wWQpMgMzKO7vBEqTtNz3cBtfSvQwUY=";
  const k = Cookies.getJSON(key);
  if (k) {

    return k
  };
  return "";
};

function userToken() {
  try {
    return localStorage.getItem('token');
  } catch (e) {
    console.log(e)
  }
}

function commonHeaders() {
  const squarespace =
    _.includes(window.location.href, '/sq/')
      || _.includes(window.location.href, '/all-carpet')
      || _.includes(window.location.href, '/log-in-sign-up')
      || _.includes(window.location.href, '/shopping-cart');

  let token = null;
  
  try {
    token = squarespace ? localStorage.getItem('customerToken') : localStorage.getItem('token');
  } catch (e) {
    console.log(e)
  }

  return {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
  };
}

const headers = () => Object.assign(commonHeaders(), { 'Content-Type': 'application/json' });
const multipart_headers = () => Object.assign(commonHeaders(), { 'Content-Type': 'multipart/form-data' });

function queryString(params) {
    const query = Object.keys(params)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&');
    return `${query.length ? '?' : ''}${query}`;
}

// function cachedList(urlPath, key = null) {
//     if (key === null) key = urlPath;
//     if (cache[key].list !== null && cache[key].serve_count < MAX_CACHE_SERVE_COUNT) {
//         cache[key].serve_count += 1;
//         return new Promise(((resolve, reject) => {
//             resolve(cache[key].list);
//         }));
//     }
//     return axios.get(`${API}/${urlPath}`, { headers: headers() })
//         .then((response) => {
//             cache[key].list = response.data.list;
//             cache[key].serve_count = 0;
//             return cache[key].list;
//         }).catch(error => error);
// }

export default {
    fetch_raw(url, params = {}) {
        return axios.get(`${url}${queryString(params)}`, { headers: headers() });
    },

    fetch(url, params = {}) {
        return axios.get(`${API}/${url}${queryString(params)}`, { headers: headers() });
    },

    upload(verb, url, data) {
        switch (verb.toLowerCase()) {
            case 'post':
                return axios.post(`${API}/${url}`, data, { headers: headers() });
            case 'put':
                return axios.put(`${API}/${url}`, data, { headers: headers() });
            case 'patch':
                return axios.patch(`${API}/${url}`, data, { headers: headers() });
            default:
        }
    },

    post(url, data, responseType = null) {
        let postData = {
            method: "post",
            url: `${API}/${url}`,
            data: data,
            timeout: 600000,
            headers: headers()
        };
        if (responseType) {
            postData.responseType = responseType;
        }
        return axios(postData);
    },

    put(url, data) {
        return axios.put(`${API}/${url}`, data, { headers: headers() });
    },

    patch(url, data) {
        return axios.patch(`${API}/${url}`, data, { headers: headers() });
    },

    delete(url) {
        return axios.delete(`${API}/${url}`, { headers: headers() });
    },

    post_form_data(url, formData) {
        return axios.post(`${API}/${url}`, formData, { headers: multipart_headers() });
    },

    put_form_data(url, formData) {
        return axios.put(`${API}/${url}`, formData, { headers: multipart_headers() });
    },

    post_get_blob(url, data) {
        return axios.post(`${API}/${url}`, data, {
          headers: {
            Authorization: `Bearer ${userToken()}`
          },
          withCredentials: true,
          responseType: "blob"
        });
      },

    userToken() {
        return userToken();
    },
    customerToken() {
      return customerToken();
    },
    getCookie(key, parseJSONvalue) {
      return getCookie(key, parseJSONvalue);
    },
    getCookieTimeout(key) {
      return getCookieTimeout(key);
    },
    clearCookies() {
      return clearCookies();
    },
    redirectToSquarespacePage(url) {
      return redirectToSquarespacePage(url)
    }
};
