import React from "react";
import { Redirect } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Spinner
} from "reactstrap";

export default class SqLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.currentCustomer) {
      this.props.SquarespaceLogout();
    } 
  }

  render() {
    if (!this.props.currentCustomer) {
      return <Redirect to='/log-in-sign-up' />
    }
    return (
      <Container className="squarespaceContainer">
        <Row>
          <Col sm="12" className="text-center">
            <Spinner type="grow" color="secondary" className="mr-2" />
            <div className="squarespaceText">Logging out now...</div>
          </Col>
        </Row>
      </Container>
    );
  }
}
