import React, { Component } from "react";
import {
  Button,
  Container,
  Label,
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  Input,
  ButtonGroup,
} from "reactstrap";
import api from "../utils/api";
import helpers from "../utils/helpers";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: props.currentUser,
      message: null,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  onChangeHandler(event) {
    this.setState({
      editItem: {
        ...this.state.editItem,
        [event.target.name]: event.target.value,
      },
    });
  }

  onCancel() {
    setTimeout(() => {
      this.setState({ redirect: true });
    }, 1000);
  }

  isFormValid() {
    let warnings = [];
    if (
      !this.state.editItem.firstName ||
      this.state.editItem.firstName.length < 2
    ) {
      warnings.push("Please provide a first name");
    }
    if (
      !this.state.editItem.lastName ||
      this.state.editItem.lastName.length < 2
    ) {
      warnings.push("Please provide a last name");
    }
    if (!this.state.editItem.email) {
      warnings.push("Please provide an e-mail address");
    }
    if (
      !this.state.editItem.mobileNumber ||
      this.state.editItem.mobileNumber.length < 7
    ) {
      warnings.push(
        "Please provide a valid telephone number including area code"
      );
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(","),
        messageFlavor: "danger",
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  handleSubmit(event) {
    if (!this.isFormValid()) return;
    event.preventDefault();
    const payload = {
      id: this.state.editItem.id,
      firstName: this.state.editItem.firstName,
      lastName: this.state.editItem.lastName,
      email: this.state.editItem.email,
      mobileNumber: this.state.editItem.mobileNumber,
    };
    api
      .put("Account", payload)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            messageFlavor: "success",
            message:
              "You have successfully updated your profile. We're now redirecting you to the home screen.",
          });
          this.props.setCurrentUser(this.state.editItem);
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 3000);
        } else {
          this.setState({
            messageFlavor: "danger",
            message: "Profile update failed. Please try again.",
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <Container className="mt-5">
        <Card>
          <CardBody>
            {this.state.message !== null && (
              <Row>
                <Col>
                  <Alert className={this.state.messageFlavor}>
                    {this.state.message}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="row-spacing">
              <Col>
                <h3 className="page-title">Edit Profile</h3>
              </Col>
            </Row>
            <form className="row-spacing">
              <Row className="mb-2">
                <Col sm="6">
                  <Label className="label"> First Name: </Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={this.state.editItem.firstName}
                    onChange={this.onChangeHandler}
                    placeholder="First Name"
                    maxLength="25"
                  />
                </Col>
                <Col sm="6">
                  <Label className="label"> Last Name: </Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={this.state.editItem.lastName}
                    onChange={this.onChangeHandler}
                    placeholder="Last Name"
                    maxLength="25"
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="7">
                  <Label className="label"> E-Mail: </Label>
                  <Input
                    type="email"
                    name="email"
                    value={this.state.editItem.email}
                    onChange={this.onChangeHandler}
                    placeholder="E-Mail Address"
                    maxLength="150"
                  />
                </Col>
                <Col sm="5">
                  <Label className="label"> Mobile Phone: </Label>
                  <Input
                    type="text"
                    name="mobileNumber"
                    value={this.state.editItem.mobileNumber}
                    onChange={this.onChangeHandler}
                    placeholder="Mobile Phone #"
                    maxLength="15"
                  />
                </Col>
              </Row>
              <ButtonGroup className="float-right">
                <Button
                  className="success"
                  size="sm"
                  onClick={this.handleSubmit}
                >
                  <FontAwesomeIcon icon="save" /> Update
                </Button>
                <Button className="secondary" size="sm" onClick={this.onCancel}>
                  <FontAwesomeIcon icon="times-circle" /> Cancel
                </Button>
              </ButtonGroup>
            </form>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
