import React, { Component, Fragment } from 'react';
import {
	Button,
	Form,
	Col,
	Row,
	Alert,
	Input,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Card,
	CardHeader,
	CardBody,
	Label,
	Badge
} from 'reactstrap';
import makeAnimated from "react-select/animated";
import Select from 'react-select';
import { constants, api, helpers, date_helpers } from '../utils';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import ContactList from './ContactList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropzoneUploader } from '../components';
import moment from 'moment';

const newCustomer = {
	id: null,
	name: '',
	agent: 8,
	area: null,
	invoiceTerms: null,
	freightTerms: null,
	pricingTypeId: {value: 1, label: 'Net Sales'},
	customerCreditType: '',
	accountType: null,
	customerStatus: null,
	contactPreferenceType: null,
	number: '',
	creditLine: 0,
	taxNo: '',
	fedId: '',
	websiteUrl: '',
	isDCCustomer: true,
	autoSendSamples: false,
	comment: '',
	contactList: [],
	primaryEmail: ''
};

const currentDate = moment().format("MM-DD-YYYY");

const cleanBadIds = (contactList) => {
	// this poorly named function also sets up the data so the API likes it
	return _.map(contactList, function (c) {
		c.id = (c.id > 0 ? c.id : 0); // clean any 'new' ids
		c.typeOfContactName = c.typeOfContact.label;
		c.typeOfContact = c.typeOfContact.value;
		c.phoneNumbers = _.map(c.phoneNumbers, function (p) {
			p.id = (p.id > 0 ? p.id : 0); // clean any 'new' ids
			p.typeOfPhoneName = p.typeOfPhone.label;
			p.typeOfPhone = p.typeOfPhone.value;
			return p;
		});
		c.addresses = _.map(c.addresses, function (a) {
			a.id = (a.id > 0 ? a.id : 0); // clean any 'new' ids
			a.typeOfAddressName = a.typeOfAddress.label;
			a.typeOfAddress = a.typeOfAddress.value;
			return a;
		});
		return c;
	});
};

export default class AddCustomersAndContacts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: null,
			errorMessage: null,
			agentList: [],
			areaList: [],
			invoiceTermsList: [],
			freightTermsList: [],
			customerCreditTypeList: [],
			accountTypeList: [],
			customerStatusList: [],
			contactPreferenceTypeList: [],
			selectedDCC: 1,
			selectedCustomerIds: [],
			customerName: "",
			customer: newCustomer,
			contacts: [],
			isContactEditing: false,
			showModal: false,
			addingImage: false,
			showDeleteAttachmentConfirmationModal: false,
			redirect: false
		};
		this.handleFormInputChange = this.handleFormInputChange.bind(this);
		this.handleFormSelectionChange = this.handleFormSelectionChange.bind(this);
		this.onSubmitAdd = this.onSubmitAdd.bind(this);
		this.onSubmitAddNotSaved = this.onSubmitAddNotSaved.bind(this);
		this.onIsDCCustomerChange = this.onIsDCCustomerChange.bind(this);
		this.onAutoSendSamplesChange = this.onAutoSendSamplesChange.bind(this);
		this.onContactListChanged = this.onContactListChanged.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.toggleContactEditing = this.toggleContactEditing.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.toggleImages = this.toggleImages.bind(this);
	}

	onContactListChanged(contactList) {
		this.setState({ contacts: contactList });
	}

	componentDidMount() {
		const getAgents = api.fetch('Reference/GetAgentList')
			.then((result) => {
				return { agentList: result.data };
			})
			.catch(helpers.catchHandler);
		const getAreas = api.fetch('Reference/GetAreaList')
			.then((result) => {
				return { areaList: result.data };
			})
			.catch(helpers.catchHandler);
		const getInvoiceTermsList = api.fetch('Reference/GetInvoiceTermList')
			.then((result) => {
				return { invoiceTermsList: result.data };
			})
			.catch(helpers.catchHandler);
			// moved this one to public web controller for the Squarespace api and this context
		const getFreightTermsList = api.fetch('Web/GetFreightTermList')
			.then((result) => {
				return { freightTermsList: result.data };
			})
			.catch(helpers.catchHandler);
		//const getCustomerCreditType = api.fetch('Reference/GetCustomerCreditTypeList')
		//	.then((result) => {
		//		return { customerCreditTypeList: result.data };
		//	})
		//	.catch(helpers.catchHandler);
		const getAccountTypes = api.fetch('Reference/GetAccountTypeList')
			.then((result) => {
				return { accountTypeList: result.data };
			})
			.catch(helpers.catchHandler);
		const getCustomerStatus = api.fetch('Reference/GetCustomerStatusList')
			.then((result) => {
				return { customerStatusList: result.data };
			})
			.catch(helpers.catchHandler);
		//const getContactPreferenceTypes = api.fetch('Reference/GetContactPreferenceTypeList')
		//	.then((result) => {
		//		return { contactPreferenceTypeList: result.data };
		//	})
		//	.catch(helpers.catchHandler);
		const getCustomerAttachmentTypes = api.fetch('Reference/GetCustomerAttachmentTypeList')
			.then((result) => {
				return { customerAttachmentTypeList: result.data };
			})
			.catch(helpers.catchHandler);

		Promise.all([
			getAgents,
			getAreas,
			getInvoiceTermsList,
			getFreightTermsList,
			//getCustomerCreditType,
			getAccountTypes,
			getCustomerStatus,
			//getContactPreferenceTypes,
			getCustomerAttachmentTypes,
		])
			.then((aggregateResults) => {
				const newStatus = {};
				aggregateResults.forEach(r => Object.assign(newStatus, r));
				this.setState(newStatus, () => {
					if (this.props.customerId) {
						this.getCustomer();
					} else {
						this.getNextDCAccountNumber();
					}
				});
			})
			.catch(helpers.catchHandler);
	}

	getCustomer() {
		api.fetch(`Customer/GetAllContacts/${this.props.customerId}`)
			.then((result) => {
				let c = Object.assign({}, result.data.customer);
				c.area = helpers.resolveValue(c.area, c.areaId, c.areaName);
				c.agent = helpers.resolveValue(c.agent, c.agentId, c.agentName);
				c.invoiceTerms = helpers.resolveValue(c.invoiceTerms, c.invoiceTermsId, c.invoiceTermsName);
				c.freightTerms = helpers.resolveValue(c.freightTerms, c.freightTermsId, c.freightTermsName);
				c.customerCreditType = helpers.resolveValue(c.customerCreditType, c.customerCreditTypeId, c.customerCreditTypeName);
				c.pricingTypeId = helpers.resolvePricingType(c.pricingTypeId);
				c.accountType = helpers.resolveValue(c.accountType, c.accountTypeId, c.accountTypeName);
				c.contactPreferenceType = helpers.resolveValue(c.contactPreferenceType, c.contactPreferenceTypeId, c.contactPreferenceTypeName) ;
				c.customerStatus = helpers.resolveValue(c.customerStatus, c.customerStatusId, c.customerStatusName);
				c.customerName = result.data.customer.name;
				c.attachments = result.data.customer.attachments;
				// squarespace required fields ---> populated when customer registers
				c.webEmail = result.data.customer.webEmail;
				c.webFirstName = result.data.customer.webFirstName;
				c.webLastName = result.data.customer.webLastName;
				c.createdAt = date_helpers.dateTimeFormat(result.data.customer.createdAt, "MM/DD/YYYY");

				let contactList = _.map(result.data.contacts, contact => {
					return {
						addresses: _.map(contact.addresses, address => {
							return {
								city: address.city,
								id: address.id,
								isPrimary: address.isPrimary,
								ln1: address.ln1,
								ln2: address.ln2,
								ln3: address.ln3,
								ln4: address.ln4,
								state: address.state,
								typeOfAddress: { label: address.typeOfAddressName, value: address.typeOfAddress },
								zip: address.zip,
								deactivatedAt: address.deactivatedAt,
							}
						}),
						email: contact.email,
						firstName: contact.firstName,
						id: contact.id,
						isPrimary: contact.isPrimary,
						lastName: contact.lastName,
						phoneNumbers: _.map(contact.phoneNumbers, phone => {
							return {
								id: phone.id,
								number: phone.number,
								typeOfPhone: { label: phone.typeOfPhoneName, value: phone.typeOfPhone },
								deactivatedAt: phone.deactivatedAt,
								isPrimary: phone.isPrimary,
							}
						}),
						typeOfContact: { label: contact.typeOfContactName, value: contact.typeOfContact },
						deactivatedAt: contact.deactivatedAt
					}
				});
				this.setState({ customer: c, contacts: contactList });
			})
			.catch(helpers.catchHandler);
	}

	getNextDCAccountNumber() {
		api.fetch(`Customer/GetNextDCAccountNumber`)
		.then((result) => {
			this.setState({
				customer: {
					...this.state.customer,
					number: result.data
				}
			})
		})
	}

	handleFormInputChange(event) {
		this.setState({ customer: { ...this.state.customer, [event.target.name]: event.target.value } });
	}

	handleFormSelectionChange(elementName, selection) {
		this.setState({
			customer: {
				...this.state.customer,
				[elementName]: {
					value: selection.value,
					label: selection.label
				}
			}
		})
	}

	toggleContactEditing(isEditing) {
		this.setState({ isContactEditing: isEditing });
	}

	toggleModal(show) {
		this.setState({ showModal: show });
	}

	onIsDCCustomerChange(event) {
		if (event.target.checked) {
			this.setState({ customer: { ...this.state.customer, isDCCustomer: true } });
		} else {
			this.setState({ customer: { ...this.state.customer, isDCCustomer: false } });
		}
	}

	onAutoSendSamplesChange(event) {
		if (event.target.checked) {
			this.setState({ customer: { ...this.state.customer, autoSendSamples: true } });
		} else {
			this.setState({ customer: { ...this.state.customer, autoSendSamples: false } });
		}
	}

	isFormValid() {
		let warnings = [];
		if (!this.state.customer.number || this.state.customer.number < 0) {
			warnings.push("Account number is required and must be a postive number.");
		}
		if (this.state.customer.number > 2147483647) {
			warnings.push("Account number is too large.");
		}
		if (!this.state.customer.customerStatus) {
			warnings.push("Account status required.");
		}
		if (!this.state.customer.name) {
			warnings.push("Company name required.");
		}
		// if (!this.state.customer.agent) {
		// 	warnings.push("Customer agent required.");
		// }
		if (!this.state.customer.area) {
			warnings.push("Area required.");
		}
		if (!this.state.customer.accountType) {
			warnings.push("Account type required.");
		}
		if (!this.state.customer.freightTerms) {
			warnings.push("Freight terms required.");
		}
		if (!this.state.customer.invoiceTerms) {
			warnings.push("Invoice terms required.");
		}
		if (!this.state.customer.pricingTypeId) {
			warnings.push("Pricing rules required");
		}
		// if (!this.state.customer.customerCreditType) {
		// 	warnings.push("Account credit type required.");
		// }
		// if (!this.state.customer.contactPreferenceType) {
		// 	warnings.push("Account contact preference type required.");
		// }
		if (!this.state.customer.creditLine && this.state.customer.creditLine < 0) {
			warnings.push("Account credit line is required and must be a postive number (if zero credit line, please enter a 0).");
		}
		if (this.state.customer.creditLine > 2147483647) {
			warnings.push("Account credit lin is too large.");
		}
		// if (this.state.customer.taxNo.length < 9) {
		// 	warnings.push("A valid tax # is required (numbers only: no spaces or dashes).");
		// }
		// if (!this.state.customer.fedId || this.state.customer.fedId < 9) {
		// 	warnings.push("A valid fed. ID is required (numbers only: no spaces or dashes).");
		// }
		// if (!this.state.contacts.length >= 1) {    // Should this be the case?
		// 	warnings.push("At least 1 contact is required per customer.")
		// }
		if (_.some(this.state.files, file => !file.imageType.value)) {
			warnings.push("File Type required on all files to be uploaded.")
		}	
		if (_.filter(this.state.files, file => file.imageType.value === constants.CUSTOMER_ATTACHMENT_TYPE.TAX_CERTIFICATE).length > 1 ) {
			warnings.push("Only one tax certificate per customer may me uploaded.")
		}	
		if (_.filter(this.state.files, file => file.imageType.value === constants.CUSTOMER_ATTACHMENT_TYPE.MEMO_BILLED_SAMPLE_FORM).length > 1 ) {
			warnings.push("Only one memo billed sample form per customer may me uploaded.")
		}	
		if (_.some(this.state.files, file => file.imageType.value === constants.CUSTOMER_ATTACHMENT_TYPE.TAX_CERTIFICATE) 
			&& _.some(this.state.customer.attachments, attachment => attachment.customerAttachmentTypeId === constants.CUSTOMER_ATTACHMENT_TYPE.TAX_CERTIFICATE) ) {
			warnings.push("Cannot upload customer tax certificate. Customer tax certificate already exists.")
		}	
		if (_.some(this.state.files, file => file.imageType.value === constants.CUSTOMER_ATTACHMENT_TYPE.MEMO_BILLED_SAMPLE_FORM) 
			&& _.some(this.state.customer.attachments, attachment => attachment.customerAttachmentTypeId === constants.CUSTOMER_ATTACHMENT_TYPE.MEMO_BILLED_SAMPLE_FORM) ) {
			warnings.push("Cannot upload memo billed sample form. Memo billed sample form already exists.")
		}
		if (_.some(this.state.files, file => !file.dateReceived)) {
			warnings.push("Date received required for all attachments")
		}

		if (warnings.length) {
			this.setState({
				message: warnings.join(' '),
				messageFlavor: "danger"
			});
		}
		return warnings.length === 0;
	}

	formatCustomerAttachmentsPayload(files) {
		const formatted = [];
		_.map(files,
			x => {
				const file = {
					Id: 0,
					CustomerId: this.state.customer.id ? this.state.customer.id : 0,
					Base64EncodedPhoto: x.base64EncodedPhoto,
					CustomerAttachmentTypeId: x.imageType.value,
					MimeType: x.type,
					AssetName: x.name,
					DateReceived: x.dateReceived
					// Content: []
				}
				formatted.push(file);
			});
		return formatted;
	}

	onSubmitAdd(event) {
		let exorcisedContacts = cleanBadIds(_.cloneDeep(this.state.contacts)); // deep copy because the original was getting messed with
		window.scroll(0,0);
		if (!this.isFormValid()) return;
		event.preventDefault();
		const customerDto = {
			Id: this.state.customer.id,
			AreaId: this.state.customer.area.value,
			AgentId: this.state.customer.agent ? this.state.customer.agent.value : null,
			InvoiceTermsId: this.state.customer.invoiceTerms.value,
			FreightTermsId: this.state.customer.freightTerms.value,
			PricingTypeId: this.state.customer.pricingTypeId ? this.state.customer.pricingTypeId.value : null,
			// CustomerCreditTypeId: this.state.customer.customerCreditType.value,
			AccountTypeId: this.state.customer.accountType.value,
			CustomerStatusId: this.state.customer.customerStatus.value,
			// ContactPreferenceTypeId: this.state.customer.contactPreferenceType.value,
			Name: this.state.customer.name,
			PrimaryEmail: this.state.customer.primaryEmail,
			Number: Number(this.state.customer.number),
			CreditLine: Number(this.state.customer.creditLine),
			TaxNo: this.state.customer.taxNo,
			FedId: this.state.customer.fedId ? this.state.customer.fedId : "", // though not used anymore, keep old if it exists?
			WebsiteURL: this.state.customer.websiteURL,
			IsDCCustomer: this.state.customer.isDCCustomer,
			AutoSendSamples: this.state.customer.autoSendSamples,
			Comment: this.state.customer.comment,
			WebEmail: this.state.customer.webEmail,
			WebFirstName: this.state.customer.webFirstName,
			WebLastName: this.state.customer.webLastName,
			Ln1: this.state.customer.ln1,
			Ln2: this.state.customer.ln2,
			Ln3: this.state.customer.ln3,
			Ln4: this.state.customer.ln4,
			City: this.state.customer.city,
			State: this.state.customer.state,
			Zip: this.state.customer.zip
		};
		var Attachments = this.state.addingImage ? this.formatCustomerAttachmentsPayload(this.state.files) : [];

		const verb = this.state.customer.id ? 'put' : 'post';
		let url = (customerDto.Id ? `Customer/${customerDto.Id}` : 'Customer/')
		api.upload(verb, url, { Customer: customerDto, Contacts: exorcisedContacts, Attachments: Attachments })
			.then(result => {
				if (result.data.success) {
					if (this.props.customerId) {
						this.setState({ redirect: true });
					} else {
						this.setState({
							customer: {
								...this.state.customer,
								id: result.data.message
							}
						}, () => {
							this.props.setActiveTab && this.props.setActiveTab("4");
							this.props.setActiveCustomer && this.props.setActiveCustomer(parseInt(result.data.message, 10));
							}
						);
					}
				} else {
					this.setState({ messageFlavor: "danger", message: "Something went wrong. The error message was as follows: " + result.data.message });
				}
			})
			.catch(helpers.catchHandler);
	}

	onSubmitAddNotSaved() {
		this.setState({ errorMessage: "There are unsaved changes to one or more contacts, please save or cancel those changes before saving the account." })
		this.setState({ errorMessageFlavor: "danger" });
		this.toggleModal(true);
	}

	toggleImages() {
		this.setState({
			addingImage: !this.state.addingImage
		});
	}

	removeCustomerAttachment(attachment) {
		const attachments = this.state.customer.attachments.slice();
		const filtered = _.filter(attachments, x => x.id !== attachment.id);

		api
			.post(`Customer/DeleteCustomerAttachment/${attachment.id}`)
			.then(response => {
				if (response.data.success) {
					this.setState({
						customer: {
							...this.state.customer,
							attachments: filtered
						},
						showDeleteAttachmentConfirmationModal: false,
						attachmentToDelete: null
					});
				} else {
					this.setState({ 
						messageFlavor: "danger", 
						message: response.data.message,
						showDeleteAttachmentConfirmationModal: false,
						attachmentToDelete: null		
					});
				}
			})
			.catch(helpers.catchHandler);
	}
	render() {
		const c = this.state.customer;
		if (this.state.redirect) {
			return (<Redirect to="/CustomerAdmin" />);
		}
		return (
			<Card style={{ borderStyle: "none" }}>
				<CardBody>
						<Modal
							isOpen={this.state.showModal}
							toggle={() => this.toggleModal(false)}
						>
							<ModalBody>
								<Alert className={this.state.errorMessageFlavor}>{this.state.errorMessage}</Alert>
							</ModalBody>
							<ModalFooter>
								<Button 
									className="secondary" 
									size="sm" 
									onClick={() => this.toggleModal(false)}
								>OK
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							isOpen={this.state.showDeleteAttachmentConfirmationModal}
							// isOpen={this.state.confirmationModalOrderLineId ? true : false} 
							toggle={() => this.setState({showDeleteAttachmentConfirmationModal: !this.state.showDeleteAttachmentConfirmationModal})}
						>
							<ModalHeader>Delete Attachment</ModalHeader>
							<Form>
									<ModalBody className="mb-4">
											<Label>Are you sure you want to permanently delete this attachment?</Label>
									</ModalBody>
									<ModalFooter>
										<ButtonGroup>
											<Button 
												className="secondary" 
												onClick={ () => this.setState({ showDeleteAttachmentConfirmationModal: false })}
											> Cancel
											</Button>
											<Button className="danger" 
													onClick={() => this.removeCustomerAttachment(this.state.attachmentToDelete)}
											>
												Delete
											</Button>

										</ButtonGroup>
									</ModalFooter>
							</Form>
						</Modal>
						{this.state.message && (
							<Row className="mb-2">
								<Col>
									<Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<h4>{c.id ? `Edit Information For ${c.customerName}` : 'Add New Account'}</h4>
							</Col>
						</Row>
						<Form>
							<Row className="mb-2">
								<Col>Account #
									<Input
										type="text"
										name="number"
										id="number"
										// onChange={this.handleFormInputChange} 
										value={c.number ? c.number : ""}
										readOnly
									/>
								</Col>
								<Col>Date Entered
									<Input
										type="text"
										name="createdAt"
										id="createdAt"
									value={c.createdAt ? c.createdAt : currentDate}
										className="form-control"
										readOnly
									/>
								</Col>
								<Col>Entered By
									<Input
										type="text"
										name="createdByUserName"
										id="createdByUserName"
										value={c.createdByUserName ? c.createdByUserName : `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`}
										className="form-control"
										readOnly
									/>
								</Col>
								<Col>Account Status {helpers.requiredStar()}
									<Select
										closeMenuOnSelect={true}
										value={c.customerStatus}
										components={makeAnimated()}
										options={this.state.customerStatusList}
										onChange={this.handleFormSelectionChange.bind(this, "customerStatus")}
										placeholder="Select Account Status"
									/>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<Row className="mb-2">
										<Col>Company Name {helpers.requiredStar()}
											<Input
												type="text"
												name="name"
												id="name"
												onChange={this.handleFormInputChange}
												value={c.name ? c.name : ""}
												className="form-control"
												maxLength="100"
											/>
										</Col>
									</Row>
									<Row className="mb-2">
										<Col>Primary Email
											<Input
												type="text"
												name="primaryEmail"
												id="primaryEmail"
												onChange={this.handleFormInputChange}
												value={c.primaryEmail ? c.primaryEmail : ""}
												placeholder="E-Mail"
												maxLength="150"
											/>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row className="mb-2">
										<Col>Agent
											<div style={{ lineHeight: "24px" }}>
												<Select 
													bsSize="sm"
													closeMenuOnSelect={true}
													value={c.agent}
													components={makeAnimated()}
													options={this.state.agentList}
													onChange={this.handleFormSelectionChange.bind(this, "agent")}
													placeholder="Select an Agent"
												/>
											</div>
										</Col>
									</Row>
									<Row className="mb-2">
										<Col>Area {helpers.requiredStar()}
											<div style={{ lineHeight: "24px" }}>
												<Select 
													closeMenuOnSelect={true}
													value={c.area}
													components={makeAnimated()}
													options={this.state.areaList}
													onChange={this.handleFormSelectionChange.bind(this, "area")}
													placeholder="Select an Area"
												/>
											</div>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row className="mb-2">
										<Col>Agent Information
											<Card>
												<CardHeader>
													<Row>
														<Col xs="3" style={{fontWeight: "bold"}}>Name:</Col>
														<Col>{c.agentName}</Col>
													</Row>
													<Row>
														<Col xs="3" style={{fontWeight: "bold"}}>Phone:</Col>
														<Col>{c.agentMobileNumber}</Col>
													</Row>
													<Row>
														<Col xs="3" style={{fontWeight: "bold"}}>Email:</Col>
														<Col>{c.agentEmail}</Col>
													</Row>
												</CardHeader>
											</Card>
										</Col>
									</Row>
								</Col>
							</Row>							
							<Row className="mb-2">
								<Col>Account Type {helpers.requiredStar()}
									<Select
										closeMenuOnSelect={true}
										value={c.accountType}
										components={makeAnimated()}
										options={this.state.accountTypeList}
										onChange={this.handleFormSelectionChange.bind(this, "accountType")}
										placeholder="Select an Account Type"
									/>
								</Col>
								<Col xs="6">Freight Terms {helpers.requiredStar()}
									<Select
										closeMenuOnSelect={true}
										value={c.freightTerms}
										components={makeAnimated()}
										options={this.state.freightTermsList}
										onChange={this.handleFormSelectionChange.bind(this, "freightTerms")}
										placeholder="Select Freight Terms"
									/>
								</Col>
							</Row>
							{/* <Row className="mb-2">
								<Col xs="6">Account Credit Type
									<Select
										closeMenuOnSelect={true}
										value={c.customerCreditType}
										components={makeAnimated()}
										options={this.state.customerCreditTypeList}
										onChange={this.handleFormSelectionChange.bind(this, "customerCreditType")}
										placeholder="Select Account Credit Type"
									/>
								</Col>
							</Row> */}
							<Row className="mb-2">
								{/* <Col xs="6">Contact Preference Type
									<Select
										closeMenuOnSelect={true}
										value={c.contactPreferenceType}
										components={makeAnimated()}
										options={this.state.contactPreferenceTypeList}
										onChange={this.handleFormSelectionChange.bind(this, "contactPreferenceType")}
										placeholder="Select a Contact Preference Type"
									/>
								</Col> */}
							</Row>
							<Row className="mb-2">
								<Col>Invoice Terms {helpers.requiredStar()}
									<div style={{ lineHeight: "24px" }}>
										<Select 
											closeMenuOnSelect={true}
											value={c.invoiceTerms}
											components={makeAnimated()}
											options={this.state.invoiceTermsList}
											onChange={this.handleFormSelectionChange.bind(this, "invoiceTerms")}
											placeholder="Select an Invoice Terms"
										/>
									</div>
								</Col>
								<Col>Credit Line {helpers.requiredStar()}
									<Input
										type="text"
										name="creditLine"
										id="creditLine"
										onChange={this.handleFormInputChange} 
										value={c.creditLine}
									/>
								</Col>
								<Col>Resale Tax No.
									<Input
										type="text"
										name="taxNo"
										id="taxNo"
										maxLength="20"
										onChange={this.handleFormInputChange}
										value={c.taxNo ? c.taxNo : ""}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								{/* <Col xs="4">Fed. ID
									<Input
										type="text"
										name="fedId"
										id="fedId"
										maxLength="25"
										onChange={this.handleFormInputChange}
										value={c.fedId ? c.fedId : ""}
										placeholder="Digits only, no spaces or dashes"
									/>
								</Col> */}
								<Col xs="8">Website URL
									<Input
										type="text"
										name="websiteURL"
										id="websiteURL"
										maxLength="255"
										onChange={this.handleFormInputChange}
										value={c.websiteURL ? c.websiteURL : ""}
									/>
								</Col>
								<Col>
									<div>
										<label>Auto Send Samples? </label> 
										<input
											className="ml-2"
											type="checkbox"
											name="autoSendSamples"
											value={c.autoSendSamples}
											onChange={this.onAutoSendSamplesChange}
											checked={c.autoSendSamples}
										/>
									</div>
									<div>
										Latest sample shipped: {c.latestSampleShippedDate}
									</div>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>Web First Name
									<Input
										type="text"
										name="webFirstName"
										id="webFirstName"
										maxLength="25"
										onChange={this.handleFormInputChange}
										value={c.webFirstName ? c.webFirstName : ""}
										readOnly
									/>
								</Col>
								<Col>Web Last Name
									<Input
										type="text"
										name="webLastName"
										id="webLastName"
										maxLength="25"
										onChange={this.handleFormInputChange}
										value={c.webLastName ? c.webLastName: ""}
										readOnly
									/>
								</Col>
								<Col md="6">Web Email
									<Input
										type="text"
										name="webEmail"
										id="webEmail"
										maxLength="150"
										onChange={this.handleFormInputChange}
										value={c.webEmail ? c.webEmail: ""}
									/>
								</Col>
								<Col> Pricing Rules {helpers.requiredStar()}
									<div style={{ lineHeight: "24px" }}>
										<Select 
											closeMenuOnSelect={true}
											value={c.pricingTypeId}
											components={makeAnimated()}
											options={constants.PRICING_TYPES}
											onChange={this.handleFormSelectionChange.bind(this, "pricingTypeId")}
											placeholder="choose a pricing rule"
										/>
									</div>
								</Col>
							</Row>
							{this.state.contacts &&
								<ContactList
									contactList={this.state.contacts}
									onListChanged={this.onContactListChanged}
									toggleContactEditing={this.toggleContactEditing}
									apiPath={"Customer/"}
								/>
							}
							<Row className="mb-3">
								<Col>
									<Row className="mb-2">
											<Col>
													<Label className="bold-title m-0">Customer Attachments</Label>
													<div>
															<Button
																	className={this.state.addingImage ? "danger" : "success"}
																	size="sm"
																	id="dropzone-toggler"
																	onClick={this.toggleImages}
															>
																	{this.state.addingImage
																					? <FontAwesomeIcon icon="minus" />
																					: <FontAwesomeIcon icon="plus" />
																			} Attachments
															</Button>
													</div>
											</Col>
									</Row>
									<Row className="mb-2">
											<Col>
													{this.state.addingImage && (
															<div>
																	{this.state.errorMessage ?
																			<Alert className="warning">
																					{this.state.errorMessage}
																			</Alert>
																			: null
																	}
																	<DropzoneUploader
																		accept={"application/pdf, application/msword, application/docx"}
																		filesCallback={(files) => this.setState({ files: files })}
																		customerAttachmentTypeList={this.state.customerAttachmentTypeList}
																	/>
															</div>
													)}
											</Col>
									</Row>
									<Row>
										<Col>
											{c.attachments && c.attachments.map(a => (
												<Row key={a.id}>
														<Col>
															<Label>{a.customerAttachmentTypeName}</Label>{" "}
													</Col>
													<Col>
														Date Received: {moment(a.dateReceived).format("MM/DD/YYYY")}
														</Col>
														<Col>
															<a href={a.url} key={a.id}>
																View
															</a>
														</Col>
														<Col>
																<Button
																		className="danger btn-outline-secondary"
																		size="sm"
																		onClick={() => this.setState({
																			showDeleteAttachmentConfirmationModal: true,
																			attachmentToDelete: a
																		})}
																>
																	<FontAwesomeIcon icon="trash" />
																</Button>
														</Col>
												</Row>
											))}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>Comment
									<Input
										type="textarea"
										style={{height: "300px"}}
										name="comment"
										id="comment"
										onChange={this.handleFormInputChange} value={c.comment ? c.comment : ""}
										placeholder="Please leave all comments about the current account here"
									/>
								</Col>
							</Row>
						</Form>
						<Row>
							<Col>
								<ButtonGroup className="btn-group float-right">
								{this.state.isContactEditing
									?	<Button
											className="primary"
											size="sm"
											onClick={this.onSubmitAddNotSaved}
										>
											<FontAwesomeIcon icon="save" /> Save Account
										</Button>
									:	<Button
											className="primary"
											size="sm"
											onClick={this.onSubmitAdd}
										>
											<FontAwesomeIcon icon="save" /> Save Account
										</Button>
								}
											<Button
												className="secondary"
												size="sm"
												tag={Link}
												to="/customeradmin"
											>
												<FontAwesomeIcon icon="times-circle" /> Cancel
											</Button>
								</ButtonGroup>
							</Col>
						</Row>
					</CardBody>
				</Card>
        );
    }
}