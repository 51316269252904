import React from 'react';
import { Table, Button, Row, Col,  Input, 
  Modal, ModalHeader, ModalBody, ButtonGroup, Alert} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import api from '../utils/api';
import makeAnimated from "react-select/animated";
import Select from 'react-select';


const emptyContact= {
  Id: null,
  FirstName:'',
  LastName:'',
  Email:'',
  IsPrimary: false,
  PhoneList: [],
  AddressList: [],
};             

const emptyPhone= {
  Id: null,
  Number: '',
  TypeOfPhone: null,
  DeactivatedAt: null
};

const emptyAddress= {
  Id: null,
  Ln1:"",
  Ln2:"",
  Ln3:"",
  Ln4:"",
  City:"",
  State:"",
  Zip:"",
  TypeOfAddress: null,
  DeactivatedAt: null
}        

let addId= 0;

const getAddId=() => {
  addId--;
  return addId;
};                                                                                                                                                                                                          

class EditAddress extends React.Component{
  constructor(props) {
    super(props);
    this.state= {
      contact: props.contact,
      addressTypeList: [],
      showAddressEdit: true,
      errorMessage: null,
      Ln1: props.editAddress.Ln1,
      Ln2: props.editAddress.Ln2,
      Ln3: props.editAddress.Ln3,
      Ln4: props.editAddress.Ln4,
      City: props.editAddress.City,
      State: props.editAddress.State,
      Zip: props.editAddress.Zip,
      TypeOfAddress: props.editAddress.TypeOfAddress
    };

    this.onLn1Change=this.onLn1Change.bind(this);
    this.onLn2Change=this.onLn2Change.bind(this);
    this.onLn3Change=this.onLn3Change.bind(this);
    this.onLn4Change=this.onLn4Change.bind(this);
    this.onCityChange=this.onCityChange.bind(this);
    this.onStateChange=this.onStateChange.bind(this);
    this.onZipChange=this.onZipChange.bind(this);
    this.onTypeOfAddressSelection=this.onTypeOfAddressSelection.bind(this);
    this.onAddressSave = this.onAddressSave.bind(this);
    this.onAddressCancel = this.onAddressCancel.bind(this);
  } 

  componentDidMount(){
    api.fetch(`${this.props.apiPath}GetContactAddressType`)
      .then((result) => {
        let list = result.data.map(function (t){
          return {
                label: t.name,
                  value: t.id
          };
      });
      this.setState({addressTypeList: list });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contact !== this.props.contact) {
      this.setState({ contact: this.props.contact });
    }
  }

  changeAddressProperty(propertyName, newValue){
    let newState = Object.assign({}, this.state);
    newState[propertyName] = newValue;
    this.setState(newState);
  }

  onAddressSave() {
    this.props.onAddressSave({
    Id: this.props.editAddress.Id,
    Ln1: this.state.Ln1,
    Ln2: this.state.Ln2,
    Ln3: this.state.Ln3,
    Ln4: this.state.Ln4,
    City: this.state.City,
    State: this.state.State,
    Zip: this.state.Zip,
    TypeOfAddress: this.state.TypeOfAddress
    });

  }

  onAddressCancel() {
    this.props.onAddressCancel(this.props.editAddress.Id);
  }

  onLn1Change(event){
    this.changeAddressProperty("Ln1", event.target.value);
  }

  onLn2Change(event){
    this.changeAddressProperty("Ln2", event.target.value);
  }

  onLn3Change(event){
   this.changeAddressProperty("Ln3", event.target.value);
  }

  onLn4Change(event){
    this.changeAddressProperty("Ln4", event.target.value);
  }

  onCityChange(event){
    this.changeAddressProperty("City", event.target.value);
  }

  onStateChange(event){
    this.changeAddressProperty("State", event.target.value);
  }
                                                                                                                                                                                                                                                                                     
  onZipChange(event){
    this.changeAddressProperty("Zip", event.target.value);
  }

  onTypeOfAddressSelection(selection){
    this.changeAddressProperty("TypeOfAddress", selection);
  }

  render() {
    return (                                   
        <Modal isOpen={true} backdrop={true}>
         <ModalHeader>Add New Address for {this.state.contact.FirstName}</ModalHeader>
          <ModalBody>
            {this.state.errorMessage !== null
                ? <Alert className="danger">{this.state.errorMessage}</Alert>
                : null
            }
                <Row>
                    <Col lg="12">Address 
                      <Input type="text" name="Ln1" id="Ln1" maxLength="100"
                              onChange={this.onLn1Change} value={this.state.Ln1}
                              placeholder="Line 1" 
                      />
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}>
                    <Col lg="12"> 
                      <Input type="text" name="Ln2" id="Ln2" maxLength="100"
                              onChange={this.onLn2Change} value={this.state.Ln2}
                              placeholder="Line 2" 
                      />
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}> 
                    <Col lg="12"> 
                      <Input type="text" name="Ln3" id="Ln3" maxLength="100"
                              onChange={this.onLn3Change} value={this.state.Ln3}
                              placeholder="Line 3" 
                      />
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}> 
                    <Col lg="12">
                      <Input type="text" name="Ln4" id="Ln4" maxLength="100"
                              onChange={this.onLn4Change} value={this.state.Ln4}
                              placeholder="Line 4" 
                      />
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}> 
                    <Col lg="6">City
                      <Input type="text" name="City" id="City" maxLength="35"
                              onChange={this.onCityChange} value={this.state.City}
                      />
                    </Col>
                    <Col lg="3">State
                      <Input type="text" name="State" id="State" maxLength="2"
                              onChange={this.onStateChange} value={this.state.State}
                      />  
                    </Col>
                    <Col lg="3">Zip Code
                      <Input type="text" name="Zip" id="Zip" maxLength="10"
                              onChange={this.onZipChange} value={this.state.Zip}
                      />  
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}>
                    <Col lg="6">Address Type 
                      <Select 
                          closeMenuOnSelect={true}
                          value={this.state.TypeOfAddress}
                          isMulti={false}
                          components={makeAnimated()}
                          options={this.state.addressTypeList}
                          onChange={this.onTypeOfAddressSelection}
                          placeholder="Select an Address Type"
                        />
                    </Col>
                </Row>
                  <ButtonGroup style={{marginTop: "12px"}}> 
                    <Button className="btn primary" onClick={this.onAddressSave}>Submit</Button>
                    <Button className="btn secondary" onClick={this.onAddressCancel} >Cancel</Button>
                  </ButtonGroup>
          </ModalBody>
      </Modal>                                                                                                                                            
    );
  }
}

class EditContact extends React.Component {
  constructor(props) {
    super(props);
  this.state= {
    contact: props.contact,
    phoneTypeList: [],
    showAddressEdit: false,
    editAddress: null,
    editing: false,
    onNameAdd: props.onNameAdd,
    onCancel: props.onCancel
  };

  this.onAddressAdd = this.onAddressAdd.bind(this);
  this.onPhoneDelete = this.onPhoneDelete.bind(this);
  this.onPhoneRevive = this.onPhoneRevive.bind(this);
  this.onAddressDelete = this.onAddressDelete.bind(this);
  this.onAddressRevive = this.onAddressRevive.bind(this);
  this.onPhoneAdd = this.onPhoneAdd.bind(this);
  this.onAddressCancel = this.onAddressCancel.bind(this);
  this.onAddressSave=this.onAddressSave.bind(this);
  this.onContactSave=this.onContactSave.bind(this);
  this.changeProperty=this.changeProperty.bind(this);
  this.onFirstNameChange = this.onFirstNameChange.bind(this);
  this.onLastNameChange = this.onLastNameChange.bind(this);
  this.onEmailChange = this.onEmailChange.bind(this);
  this.onIsPrimarySelection = this.onIsPrimarySelection.bind(this);
}

  componentDidMount() {
    api.fetch(`${this.props.apiPath}GetContactPhoneType`)
      .then((result) => {
        let list = result.data.map(function (p){
          return {
                label: p.name,
                value: p.id
          };
        });
      this.setState({phoneTypeList: list });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contact !== this.props.contact) {
      this.setState({ contact: this.props.contact });
    }
  }

  changeProperty(propertyName, newValue) {
    let newContact = Object.assign({}, this.state.contact);
    newContact[propertyName] = newValue;
    this.setState({contact: newContact});
  }

  onFirstNameChange(event){
    this.changeProperty("FirstName", event.target.value);
  } 
  
  onLastNameChange(event){
    this.changeProperty("LastName", event.target.value);
  } 
  
  onEmailChange(event){
    this.changeProperty("Email", event.target.value);
  } 

  onIsPrimarySelection(selection){
    this.changeProperty("IsPrimary", selection.value);
  } 

  onTypeOfPhoneSelection(phoneId, selection) {
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    editList[indexToEdit].TypeOfPhone = selection;
    this.changeProperty("PhoneList", editList);
  }

  onNumberChange(phoneId, event){
    // find and update the element in the phone array
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    editList[indexToEdit].Number = event.target.value;
    // send the whole phone array to the function to 
    // update the contact property on state (replacing the whole array)
    this.changeProperty("PhoneList", editList);
  }

  onPhoneAdd(){
    let newPhone = Object.assign({}, emptyPhone);
    newPhone.Id = getAddId();
    newPhone.editing = true;
    newPhone.pristine = null;
    const newPhoneList = this.state.contact.PhoneList.slice();
    newPhoneList.push(newPhone);
    this.changeProperty("PhoneList", newPhoneList);
  }

  onPhoneRevive(phoneId, event){
    api.post(`${this.props.apiPath}RevivePhone/${phoneId}`)
    .then(r => {
      if (r.data.success === false) {
        this.setState({messageFlavor: "danger", message: r.data.message});
      } else {
        // we're all good, set the phone to undeleted
        let editList = this.state.contact.PhoneList.slice();
        let indexToEdit = _.findIndex(editList, {Id: phoneId});
        editList[indexToEdit].deactivatedAt = null;
        this.changeProperty("PhoneList", editList);
      }
    }).catch(helpers.catchHandler);
  }

  onPhoneDelete(phoneId, event){
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    let currentPhone = editList[indexToEdit];
    if (currentPhone.Id > 0) {
      // we really exist in the database, we need to send a deletion request
      api.delete(`${this.props.apiPath}Phone/${phoneId}`).then(r => {
        if (r.data.success === false) {
          this.setState({messageFlavor: "danger", message: r.data.message});
        } else {
          // we're all good, set the phone to deleted
          editList[indexToEdit].deactivatedAt = new Date();
          this.changeProperty("PhoneList", editList);
        }
      }).catch(helpers.catchHandler);
    } else {
      // just remove it - we're still a poseur
      editList.splice(indexToEdit, 1);
      this.changeProperty("PhoneList", editList);
    }
  }

  onPhoneCancel(phoneId, event){
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    if (editList[indexToEdit].pristine === null) {
      // they clicked Add but changed their mind
      editList.splice(indexToEdit, 1);
    } else {
      editList[indexToEdit] = editList[indexToEdit].pristine;
    }
    this.changeProperty("PhoneList", editList);
  }

  onPhoneEdit(phoneId, event){
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
    editList[indexToEdit].editing = true;
    this.changeProperty("PhoneList", editList);
  }

  onPhoneSave(phoneId, event){
    event.preventDefault();
    let editList = this.state.contact.PhoneList.slice();
    let indexToEdit = _.findIndex(editList, {Id: phoneId});
    editList[indexToEdit].editing = false;
    this.changeProperty("PhoneList", editList);
  }

  onAddressDelete(event, addressId){
    let editList = this.state.contact.AddressList.slice();
    let indexToEdit = _.findIndex(editList, {Id: addressId});
    let currentAddress = editList[indexToEdit];
    if (currentAddress.Id > 0) {
      api.delete(`${this.props.apiPath}DeleteAddress/${addressId}`).then(r => {
        if (r.data.success === false){
          this.setState({messageFlavor: "danger", message: r.data.message});
        } else {
          editList[indexToEdit].deactivatedAt = new Date();
          this.changeProperty("AddressList", editList);
        }
      }).catch(helpers.catchHandler);
    } else {
      editList.splice(indexToEdit, 1);
      this.changeProperty("AddressList", editList);
    }
  }

  onAddressRevive(addressId, event){
    api.post(`${this.props.apiPath}ReviveAddress/${addressId}`)
    .then(r => {
      if (r.data.success === false) {
        this.setState({messageFlavor: "danger", message: r.data.message});
      } else {
        let editList = this.state.contact.AddressList.slice();
        let indexToEdit = _.findIndex(editList, {Id: addressId});
        editList[indexToEdit].deactivatedAt = null;
        this.changeProperty("AddressList", editList);
      }
    }).catch(helpers.catchHandler);
  }

  setAllExistingPrimaryAddressesToFalse(addressList, type) {
    //let setToFalseList = _.filter(addressList)
  }

  onAddressAdd(){
    let newAddress = Object.assign({}, emptyAddress);
    newAddress.Id = getAddId();
    newAddress.editing = true;
    newAddress.pristine = null;
    const newAddressList = this.state.contact.AddressList.slice();
    newAddressList.push(newAddress);
    this.changeProperty("AddressList", newAddressList)
    this.setState ({ showAddressEdit: true, editAddress: newAddress});
  }

  onAddressSave(address){
    let editList = this.state.contact.AddressList.slice();
    let indexToEdit = _.findIndex(editList, {Id: address.Id});
    editList[indexToEdit] = address;
    editList[indexToEdit].editing = false;
    //this.setAllExistingPrimaryAddressesToFalse(addressList, editList[indexToEdit].type);
    this.changeProperty("AddressList", editList);
    this.setState ({ showAddressEdit: false});              
  }

 onAddressCancel(addressId){
   let editList = this.state.contact.AddressList.slice();
   let indexToEdit = _.findIndex(editList, {Id: addressId});
   if (editList[indexToEdit].pristine === null){
     editList.splice(indexToEdit, 1);
   }else {
     editList[indexToEdit] = editList[indexToEdit.pristine];
   }
   this.changeProperty("AddressList", editList);
   this.setState({ showAddressEdit: false});
 }

 onAddressEdit(addressId, event){
  let editList = this.state.contact.addresList.slice();
  let indexToEdit = _.findIndex(editList, {Id: addressId});
  editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
  editList[indexToEdit].editing = true;
  this.changeProperty("AddressList", editList);
  this.setState({showAddressEdit: true, editAddress: editList[indexToEdit]})
}

onContactSave(event){
  event.preventDefault();
  this.props.onSaveContact(this.state.contact);
}

render() {
  return (
      <React.Fragment>
        <tr>
        <td> First Name
          <Input type="text" name="FirstName" id="FirstName" maxLength="25"
            onChange={this.onFirstNameChange} value={this.state.contact.FirstName}
            placeholder="First Name" />
        </td>                                                                    
        <td> Last Name
            <Input type="text" name="LastName" id="LastName" maxLength="25"
              onChange={this.onLastNameChange} value={this.state.contact.LastName}
              placeholder="Last Name" />
        </td>
        <td > E-Mail
            <Input type="text" name="Email" id="Email" maxLength="150"
              onChange={this.onEmailChange} value={this.state.contact.Email}
              placeholder="E-Mail" />
        </td>
        <td> Primary? 
        <Select
            closeMenuOnSelect
            value={helpers.booleanListEntry(this.state.contact.IsPrimary)}
            isMulti={false}
            components={makeAnimated()}
            options={helpers.yesNoOptions()}
            onChange={this.onIsPrimarySelection}
            defaultValues={true}
          />
        </td>
      <td> 
      <ButtonGroup className="float-right"  style={{ marginTop: "25px" }}>
         <Button className="primary" onClick={this.onContactSave}>
           Save
          </Button>
         <Button className="secondary" onClick={this.state.onCancel}>
           Cancel
        </Button>
     </ButtonGroup>
    </td>
    </tr>
    <tr>
      <td colSpan="2">
          <Table>
          <thead>
            <tr>
              <th>Phone</th>
              <th>
                <ButtonGroup className="float-right btn-group-sm btn-sm">
                  <Button className="btn-group-sm btn-sm success" onClick={this.onPhoneAdd}>
                    <FontAwesomeIcon icon="plus-circle" /> Add Phone
                  </Button>
                </ButtonGroup>
              </th>Phone
            </tr>
          </thead>
          <tbody>     
            {this.state.contact.PhoneList.map(c =>   
              (<tr key={`ph-${c.Id}`}>
                {c.editing ? <React.Fragment>
                <td>
              <Input type="text" name="Number" id="Number" maxLength="20"
                    onChange={this.onNumberChange.bind(this, c.Id)} value={c.Number} 
              />
            </td>
            <td>
              <Select 
                closeMenuOnSelect={true}
                value={c.TypeOfPhone}
                isMulti={false}
                components={makeAnimated()}
                options={this.state.phoneTypeList}
                onChange={this.onTypeOfPhoneSelection.bind(this,c.Id)}
                placeholder="Phone Type"
              />
            </td>
            <td>
            <ButtonGroup className="text-right"  style={{ marginTop: "2px", marginLeft: "15px" }}>
              <Button className="primary" onClick={this.onPhoneSave.bind(this, c.Id)}>
                Save
              </Button>
              <Button className="secondary" onClick={this.onPhoneCancel.bind(this,c.Id)}>
                Cancel
              </Button>
            </ButtonGroup>
            </td>
              </React.Fragment>
              :<React.Fragment>
                <td>{c.Number}</td>
                <td>{c.TypeOfPhone.label}</td>
                <td className="text-right">
                  <ButtonGroup>
                    <Button className="dark" size="sm" onClick={this.onPhoneEdit.bind(this, c.Id)}>
                      <FontAwesomeIcon icon="edit" /> Edit       
                    </Button>
                  {c.deactivatedAt
                  ?  (<Button className="info" size="sm" onClick={this.onPhoneRevive.bind(this, c.Id)}>
                     <FontAwesomeIcon icon="recycle" /> Revive
                   </Button>)
                  : (<Button className="danger" size="sm" onClick={this.onPhoneDelete.bind(this, c.Id)}>
                        <FontAwesomeIcon icon="times-circle" /> Delete
                      </Button>) 
                  }
                </ButtonGroup>
              </td>
              </React.Fragment>
              }   
              </tr>
              )
             )}
          </tbody>
       </Table>
       </td>
       <td colSpan="2">
        <Table>
          <thead>
            <tr>
              <th>Address</th>
              <th colSpan="8">
                    <ButtonGroup className="float-right btn-group-sm btn-sm">
                      <Button className="btn-group-sm btn-sm success" onClick= {() => this.onAddressAdd()}>
                        <FontAwesomeIcon icon="plus-circle" /> Add Address
                      </Button>
                    </ButtonGroup>
              </th>
            </tr>
          </thead>
        <tbody>
        {this.state.contact.AddressList.map(a =>
         <React.Fragment key={`add-${a.Id}`}>
          <tr>
            <td>{a.Ln1}</td>
            <td>{a.Ln2}</td>
            <td>{a.Ln3}</td>
            <td>{a.Ln4}</td>
            <td>{a.City}</td>
            <td>{a.State}</td>
            <td>{a.Zip}</td>
            <td>{a.TypeOfAddress ? a.TypeOfAddress.label : null }</td>
            <td>
            {a.Id === null ? null
                : <ButtonGroup>
                  <Button className="dark" size="sm" onClick={this.onAddressEdit.bind(this, a.Id)}>
                    <FontAwesomeIcon icon="edit" /> Edit       
                  </Button>
                {a.deactivatedAt === null
                ? (<Button className="danger" size="sm" onClick={this.onAddressDelete.bind(this, a.Id)}>
                    <FontAwesomeIcon icon="times-circle" /> Delete
                  </Button>)
                : (<Button className="info" size="sm" onClick={this.onAddressRevive.bind(this, a.Id)}>
                    <FontAwesomeIcon icon="recycle" /> Revive
                  </Button>)
                }
              </ButtonGroup>
            }
            </td>
           </tr>
           </React.Fragment>
          )}
          </tbody>
        </Table>
        {(this.state.showAddressEdit === true) &&
         <EditAddress
            contact={this.state.contact} 
            editAddress={this.state.editAddress} 
            onAddressCancel={this.onAddressCancel}
            onAddressSave={this.onAddressSave}  
            onAddressAdd={this.onAddressAdd}  
            apiPath={this.props.apiPath}
         />
          }
      </td>
    </tr>
    </React.Fragment>
  );
}
}

export default class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      contactList: props.contactList,
      showAddressEdit: false,
      contact: emptyContact,
      editing: false, 
      redirect: false,
      message: null,
      messageFlavor: null,
  };

  this.showAdd = this.showAdd.bind(this);
  this.isEditingAny = this.isEditingAny.bind(this);   
  this.onContactSave=this.onContactSave.bind(this);                                                                                                                                                                                                                                                             
  }

componentDidUpdate(prevProps, prevState) {
  if (prevProps.contactList !== this.props.contactList) {
    this.setState({ contactList: this.props.contactList });
  }
}
  showAdd(){
    const newList = this.state.contactList.slice();
    let newContact = Object.assign({}, emptyContact);
    newContact.Id = getAddId();
    newContact.editing = true;
    newList.unshift(newContact);
    this.setState({ contactList: newList });
  }

  isEditingAny() {
    return _.find(this.state.contactList, function(c){ return c.editing; }) !== undefined;
  }

  onContactSave(newContactInfo){
    let saveList = this.state.contactList.slice();
    let existingIdx = _.findIndex(this.state.contactList, function(c){
      return c.Id === newContactInfo.Id;
    });
    if(existingIdx === -1)
      {                         
        console.error("could not find contact that should have existed "+ newContactInfo.Id );
      }
    else {

        newContactInfo.editing = false;
        saveList[existingIdx] = newContactInfo;
        this.setState({contactList: saveList});
        this.props.onListChanged(saveList);
    }
  }

  onEditContact(contactid, event){
    let editList = this.state.contactList.slice();
    let indexToEdit = _.findIndex(editList, {Id: contactid});
    editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
    editList[indexToEdit].editing = true;
    this.setState({contactList: editList});
  }

  onContactDelete(contactId, event){
    let editList = this.state.contactList.slice();
    let indexToEdit = _.findIndex(editList, {Id: contactId});
    let currentContact = editList[indexToEdit];
    if (currentContact.Id > 0) {
      api.delete(`${this.props.apiPath}Contact/${contactId}`).then(r => {
        if (r.data.success === false) {
          this.setState({messageFlavor: "danger", message: r.data.message});
        } else {
          editList[indexToEdit].deactivatedAt = new Date();
          this.setState({ contactList: editList});
          this.props.onListChanged(editList);
        }
      }).catch(helpers.catchHandler);
    } else {
      editList.splice(indexToEdit, 1);
      this.setState({ contactList: editList});
      this.props.onListChanged(editList);
    }
}

  render() {
    // if (this.state.redirect === true){
    //   return <Redirect to='/AddCustomersAndContacts' />;
    // }
    return (
    <div>
      {this.state.message !== null
        ?<Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
        :null
      }
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Is Primary?</th>
            <th> 
              {!this.isEditingAny()
              ? <Button className="secondary" id="toggler" onClick={this.showAdd}> <FontAwesomeIcon icon="plus-circle" /> 
                  Add
                </Button>
              : null
              } 
            </th>
          </tr>
        </thead>
          <tbody>
            {this.state.contactList.map(c =>
            (<React.Fragment key={`contact-${c.Id}`}>{c.editing ? 
                <EditContact
                  contact={c}
                  onSaveContact={this.onContactSave}
                  onCancel={this.onCancel}
                  contactList={this.state.contactList}
                  onEditContact={this.onEditContact}
                  apiPath={this.props.apiPath}
                />  
              : <tr>
              <td> {c.FirstName} {c.LastName} </td> 
              <td> {c.Email} </td> 
              <td> {c.IsPrimary ? "yes" : null} </td>
              <td>
                  <Button className="dark" onClick={this.onEditContact.bind(this,c.Id)}>
                    <FontAwesomeIcon icon="edit"/> Edit       
                  </Button>
              </td>
              </tr>
            }</React.Fragment>
           )
          )}
          </tbody>
      </Table>                                      
    </div>
    );
  }
}