import _ from "lodash";
import * as constants from "./constants";
import * as api from "./api";

const priceDefaults = constants.default.CUSTOM_RUG_DEFAULT_PRICES;
const sergingList = constants.default.SERGING_LIST;
const machineSergingList = constants.default.MACHINE_SERGING_LIST;
const miteredList = constants.default.MITERED_LIST;
const apiPost = api.default.post;

const { RUG_BACKING_PRICE_PER, RUG_BACKING_COST_PER } = priceDefaults;

const MIN_REPEAT = constants.default.MIN_REPEAT;

function getMinRepeat(selectedField, fieldRepeatLength, fieldRepeatWidth) {
  let defaultRepeatInches = Number(MIN_REPEAT);
  if (selectedField) {
    const repeatLengthInches = fieldRepeatLength ? Number(fieldRepeatLength) : 0.0;
    const repeatWidthInches = fieldRepeatWidth ? Number(fieldRepeatWidth) : 0.0;
    if (repeatLengthInches > defaultRepeatInches 
      || repeatWidthInches > defaultRepeatInches) 
    {
      defaultRepeatInches = _.ceil(_.max([repeatLengthInches, repeatWidthInches]));
    }
  }
  return _.round(defaultRepeatInches, 2).toFixed(2);
}

function calculateSqFeetAndFinEdgeFt(
  rugLengthFeet,
  rugLengthInches,
  rugWidthFeet,
  rugWidthInches
) {
  if (!rugWidthInches) {
    rugWidthInches = 0;
  }
  let widFt = rugWidthInches / 12 + parseFloat(rugWidthFeet.toString());
  let sqFt;
  let finishedEdgeFt;
  if (isNaN(widFt)) {
    sqFt = 0;
    finishedEdgeFt = 0;
    return { sqFt: null, finishedEdgeFt: null };
  }
  let linFt = rugLengthInches / 12 + parseFloat(rugLengthFeet.toString());
  sqFt = _.round(linFt * widFt);
  finishedEdgeFt = _.round(2 * linFt + 2 * widFt);
  return {
    sqFt: sqFt,
    finishedEdgeFt: finishedEdgeFt,
  };
}

function calculateRugExtraCosts(orderLine, sqFt, finishedEdgeFt, showCosts) {
  let {
    rugBackingPricePer,
    rugBackingCostPer,
    rugBackingCmsnRate,
    sergingPricePer,
    sergingCostPer,
    sergingCmsnRate,
    mSergingPricePer,
    mSergingCostPer,
    mSergingCmsnRate,
    hsSergingPricePer,
    hsSergingCostPer,
    hsSergingCmsnRate,
  } = orderLine;
  if (!rugBackingPricePer) {
    rugBackingPricePer = RUG_BACKING_PRICE_PER;
  }
  if (!rugBackingCostPer) {
    rugBackingCostPer = RUG_BACKING_COST_PER;
  }
  // if (!showCosts) {
    if (!sergingPricePer) {
      sergingPricePer = sergingList[0].pricePer;
    }
    if (!sergingCostPer) {
      sergingCostPer = sergingList[0].costPer;
    }
    if (!mSergingPricePer) {
      mSergingPricePer = machineSergingList[0].pricePer;
    }
    if (!mSergingCostPer) {
      mSergingCostPer = machineSergingList[0].costPer;
    }
    if (!hsSergingPricePer) {
      hsSergingPricePer = miteredList[0].pricePer;
    }
    if (!hsSergingCostPer) {
      hsSergingCostPer = miteredList[0].costPer;
    }
  // }
  let totalRugBackingPrice = 0;
  let totalRugBackingCost = 0;
  let rugBackingCmsn = 0;
  let totalSergingPrice = 0;
  let totalSergingCost = 0;
  let sergingCmsn = 0;
  let totalMSergingPrice = 0;
  let totalMSergingCost = 0;
  let mSergingCmsn = 0;
  let totalHSSergingPrice = 0;
  let totalHSSergingCost = 0;
  let hsSergingCmsn = 0;

  // updateRugBackingTotals
  totalRugBackingPrice = rugBackingPricePer * sqFt;
  // updateSergingTotals

  totalSergingPrice = sergingPricePer * finishedEdgeFt;
  // updateMSergingTotals
  totalMSergingPrice = mSergingPricePer * finishedEdgeFt;
  // updateHSSergingTotals   (missing from original app?)
  totalHSSergingPrice = hsSergingPricePer * finishedEdgeFt;

  // if (showCosts) {
    // updateRugBackingTotals
    totalRugBackingCost = rugBackingCostPer * sqFt;
    rugBackingCmsn = (rugBackingCmsnRate / 100.0) * totalRugBackingPrice;
    // updateSergingTotals
    totalSergingCost = sergingCostPer * finishedEdgeFt;
    sergingCmsn = (sergingCmsnRate / 100.0) * totalSergingPrice;
    // updateMSergingTotals
    totalMSergingCost = mSergingCostPer * finishedEdgeFt;
    mSergingCmsn = (mSergingCmsnRate / 100.0) * totalMSergingPrice;
    // updateHSSergingTotals   (missing from original app?)
    totalHSSergingCost = hsSergingCostPer * finishedEdgeFt;
    hsSergingCmsn = (hsSergingCmsnRate / 100.0) * totalHSSergingPrice;
  // }
  return {
    totalRugBackingPrice: _.round(totalRugBackingPrice, 2).toFixed(2),
    totalRugBackingCost: _.round(totalRugBackingCost, 2).toFixed(2),
    rugBackingCmsn: _.round(rugBackingCmsn, 2).toFixed(2),
    totalSergingPrice: _.round(totalSergingPrice, 2).toFixed(2),
    totalSergingCost: _.round(totalSergingCost, 2).toFixed(2),
    sergingCmsn: _.round(sergingCmsn, 2).toFixed(2),
    totalMSergingPrice: _.round(totalMSergingPrice, 2).toFixed(2),
    totalMSergingCost: _.round(totalMSergingCost, 2).toFixed(2),
    mSergingCmsn: _.round(mSergingCmsn, 2).toFixed(2),
    totalHSSergingPrice: _.round(totalHSSergingPrice, 2).toFixed(2),
    totalHSSergingCost: _.round(totalHSSergingCost, 2).toFixed(2),
    hsSergingCmsn: _.round(hsSergingCmsn, 2).toFixed(2),
  };
}

function calculateFieldUnits(widthNumericFeet, fieldLengthFeet, fieldLengthInches, priceUnitTypeName) {
  let totalFieldUnits;
  if (widthNumericFeet) {
    let lngth = 0;
    if ((fieldLengthFeet !== '' || fieldLengthFeet !== null)
      && (fieldLengthInches !== "" || fieldLengthInches !== null)) {
      lngth = parseFloat(fieldLengthFeet.toString()) + (parseFloat(fieldLengthInches.toString()) / 12.0)
    }
    if (lngth === 0) return;
    if (priceUnitTypeName && priceUnitTypeName === "Sq Yds") {
      let sqYds = ((widthNumericFeet / 3.0) * lngth) / 3.0;
      if (isNaN(sqYds)) return;
      totalFieldUnits = sqYds;
    } else if (priceUnitTypeName && priceUnitTypeName === "Sq Ft") {
      totalFieldUnits = widthNumericFeet * lngth
    } else {
      totalFieldUnits = "0.0"
    }
  }
  return _.round(totalFieldUnits, 3).toFixed(3);
}

async function getDefaultFieldSize(payload, selectedField, fieldRepeatLength, fieldRepeatWidth) {
  let fieldLengthFeet;
  let fieldLengthInches;
  let appliedFieldRepeat;
  let widthFeetName;

  let response = await apiPost("Order/GetDefaultFieldSize", payload);
  appliedFieldRepeat = getMinRepeat(selectedField, fieldRepeatLength, fieldRepeatWidth);
  try {
    if (response && response.data) {
      let defaultValues = response.data;

      fieldLengthFeet = defaultValues.lengthFeetFeetOnly;
      fieldLengthInches = defaultValues.lengthFeetInchesOnly;
      widthFeetName = defaultValues.widthFeetName;
    } else {
      console.error('failed to retrieve order/getdefaultfieldsize');
    }
  } catch {
    console.log('error in getDefaultField Size calculations')
  }
  return {
    fieldLengthFeet: fieldLengthFeet,
    fieldLengthInches: fieldLengthInches,
    appliedFieldRepeat: appliedFieldRepeat,
    widthFeetName: widthFeetName
  };
}

function getFieldDescription(fieldLabel, fieldColor) {
  return `Field (A): ${fieldLabel} ${fieldColor ? ", " + fieldColor.label : ''}`
}

function getRugPadDescription(fieldLabel, fieldColor) {
  return `${fieldLabel} ${fieldColor ? ", " + fieldColor.label : ''}`
}

const calc = {
  calculateSqFeetAndFinEdgeFt,
  calculateRugExtraCosts,
  calculateFieldUnits,
  getMinRepeat,
  getDefaultFieldSize,
  getFieldDescription,
  getRugPadDescription
};

export default calc;
