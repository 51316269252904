import React, { Component, Fragment } from "react";
import {
  Container,
  Card,
  CardBody,
  Alert,
  Row,
  Col,
  Table,
  Button,
  ButtonGroup,
  Input
} from "reactstrap";
import api from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {helpers, date_helpers} from '../utils';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import {
  SortHeader,
} from '../components';

const emptyAgentSampleBook = {
  id: -1,
  sampleBook: null,
  quantity: null,
  editing: true,
  shipDate: new Date(),
};

export default class AgentSampleBookInventory extends Component{
  constructor(props) {
    super(props);
    this.state = {
      messageFlavor: null,
      message: null,
      isMessageVisible: false,
      agentId: props.agentId,
      sampleBookList: [],
      bookList: [],
      pristineItem: null,
      pagedList: {
        list: [],
        pageSize: 100,
        pageNumber: 1
      },
      sortField: 'Name',
      sortDir: 'asc',

    };
    this.onDismiss = this.onDismiss.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onBookSelection = this.onBookSelection.bind(this);
    this.onShipDateChange = this.onShipDateChange.bind(this);
    this.onAddNew = this.onAddNew.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.doSort = this.doSort.bind(this);
  }

  componentDidMount() {
    const getAgents = api.fetch('Reference/GetAgentList')
      .then((result) => {
        return { agentList: result.data };
      })
      .catch(helpers.catchHandler);

    const getBooks = api.fetch('Reference/GetSampleBookList')
      .then((result) => {
        return { bookList: result.data };
      })
      .catch(helpers.catchHandler);
    Promise.all([
      getAgents,
      getBooks,
    ])
      .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus, ()=>this.refresh())
      }).catch(helpers.catchHandler);
  }

  refresh(sortField, sortDirection) {
    let payload = {
      SortField: sortField || this.state.sortField,
      SortDir: sortDirection || this.state.sortDir 
    }
    api.post(`SampleBook/GetAgentSampleBooks/${this.props.agentId}`, payload)
      .then(response => {
        if (response && response.data) {
          this.setState({
            pagedList: {
              ...this.state.pagedList,
              list: _.map(response.data, b => {
                b.sampleBook = { label: b.sampleBookName, value: b.sampleBookId};
                b.editing = false;
                b.shipDate = date_helpers.getMomentFromString(b.shipDate);
                return b;
              })
            }
          })
        }
      }).catch(helpers.catchHandler);
  }

  onDismiss() {
    this.setState({ message: null, messageFlavor: null, isMessageVisible: false });
  }

  onBookSelection(selection, index) {
    let list = this.state.pagedList.list.slice();
    list[index].sampleBook = selection;
    this.setState({ pagedList: { ...this.state.pagedList, list: list } });
  }

  onInputChange(e, fieldName, index) {
    let list = this.state.pagedList.list.slice();
    list[index][fieldName] = e.target.value;
    this.setState({ pagedList: { ...this.state.pagedList, list: list } });
  }

  onShipDateChange(date, index) {
    let list = this.state.pagedList.list.slice();
    list[index].shipDate = date;
    this.setState({ pagedList: { ...this.state.pagedList, list: list } });
  }

  onAddNew() {
    let list = this.state.pagedList.list.slice();
    list.unshift(emptyAgentSampleBook);
    this.setState({ pagedList: { ...this.state.pagedList, list: list } });
  }

  onEdit(index) {
    let list = this.state.pagedList.list.slice();
    const pristine = Object.assign({}, list[index]);
    list[index].editing = true;
    this.setState({ pagedList: { ...this.state.pagedList, list: list }, pristineItem: pristine });
  }

  onCancel(index) {
    let list = this.state.pagedList.list.slice();
    if (list[index].id > 0) {
      list[index] = this.state.pristineItem;
      list[index].editing = false;
    } else {
      list = _.reject(list, (l, idx) => { return idx === index; });
    }
    this.setState({ pagedList: { ...this.state.pagedList, list: list }, pristineItem: null });
  }

  isFormValid(sampleToSave) {
    console.log('sampleToSave', sampleToSave);
    let warnings = [];
    if (!sampleToSave.quantity) {
      warnings.push("Quantity is required.");
    }
    if (!sampleToSave.sampleBook) {
      warnings.push("Sample Book is required.");
    }
    if (sampleToSave.id < 0 && sampleToSave.sampleBook && sampleToSave.sampleBook.value &&
      _.find(this.state.pagedList.list, b => { return b.sampleBook.value === sampleToSave.sampleBook.value && b.id > 0}) !== undefined) {
      warnings.push("This sample book already exist in agent inventory. Please adjust existing quantity.");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(' '),
        messageFlavor: "danger",
        isMessageVisible: true
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  doSort(field, direction) {
    this.setState({ sortDir: direction, sortField: field });
    this.refresh(field, direction);
}

  onSave(index) {
    let list = this.state.pagedList.list.slice();
    let agentSampleBook = _.find(list, (s, idx) => { return idx === index });
    if (!this.isFormValid(agentSampleBook)) return;

    let payload = {
      Id: agentSampleBook.id > 0 ? agentSampleBook.id : 0,
      AgentId: this.state.agentId,
      SampleBookId: agentSampleBook.sampleBook && agentSampleBook.sampleBook.value ? agentSampleBook.sampleBook.value : null,
      Quantity: parseInt(agentSampleBook.quantity, 10),
      ShipDate: agentSampleBook.shipDate
    };
    api.post("SampleBook/SaveAgentSampleBook", payload)
      .then(response => {
        if (response && response.data.success) {
          this.setState({
            message: "Sample book details saved.",
            messageFlavor: "success",
            isMessageVisible: true,
          }, ()=> this.refresh())
        } else {
          this.setState({
            message: "An error occurred.",
            messageFlavor: "danger",
            isMessageVisible: true
          })
        }
      })
  }

  render() {
    const {
      message,
      messageFlavor,
      isMessageVisible,
      pagedList,
      bookList,
    } = this.state;
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {message && (
              <Row className="mb-2">
                <Col>
                  <Alert className={messageFlavor} isOpen={isMessageVisible} onDismiss={this.onDismiss}>{message}</Alert>
                </Col>
              </Row>
            )}
            <Row className="mb-2">
              <Col>
                <h4 className="pull-left page-title">Sample Book Inventory</h4>
              </Col>
              <Col>
                <Button size="sm" className="success float-right" onClick={this.onAddNew}>Add Book</Button>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Table striped>
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      <th>
                        <SortHeader 
                          displayName="Name" 
                          field="Name" 
                          sortDir={this.state.sortDir}
                          sorted={this.state.sortField === 'Name'} 
                          callBack={this.doSort} 
                        />
                      </th>
                      <th>
                        <SortHeader 
                          displayName="Ship Date" 
                          field="ShipDate" 
                          sortDir={this.state.sortDir}
                          sorted={this.state.sortField === 'ShipDate'} 
                          callBack={this.doSort} 
                        />
                      </th>
                      <th>
                        <SortHeader 
                          displayName="Qty" 
                          field="Quantity" 
                          sortDir={this.state.sortDir}
                          sorted={this.state.sortField === 'Quantity'} 
                          callBack={this.doSort} 
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagedList && pagedList.list && pagedList.list.length > 0 ?
                      pagedList.list.map((b, index) => (
                        <tr key={index}>
                          {b.editing ?
                            <Fragment>
                              <td>
                                <Select
                                  closeMenuOnSelect
                                  value={b.sampleBook}
                                  isMulti={false}
                                  components={makeAnimated()}
                                  options={bookList}
                                  onChange={(e)=>this.onBookSelection(e, index)}
                                  defaultValues={true}
                                />
                              </td>
                              <td>
                                <DatePicker
                                  id="shipDate"
                                  selected={date_helpers.dateFormatForClient(b.shipDate)}
                                  onChange={(date) => this.onShipDateChange(date, index)}
                                  className="form-control"
                                />
                              </td>
                              <td><Input type="number" name="quantity" onChange={(e) => this.onInputChange(e, "quantity", index)} value={b.quantity} /></td>
                              <td>
                                <ButtonGroup className="float-right">
                                  <Button size="sm" className="primary" onClick={()=>this.onSave(index)}><FontAwesomeIcon icon="save" /></Button>
                                  <Button size="sm" className="secondary" onClick={()=>this.onCancel(index)}><FontAwesomeIcon icon="reply" /></Button>
                                </ButtonGroup>
                                </td>
                            </Fragment> 
                            : <Fragment>
                                <td>{b.sampleBookName}  <div className="detailTextNoSize">{b.sampleBookTypeName}</div></td>
                                <td>{date_helpers.formatDateToShortDate(b.shipDate)}</td>
                                <td>{b.quantity}</td>
                                <td>
                                <ButtonGroup className="float-right">
                                  <Button className="primary" size="sm" onClick={()=>this.onEdit(index)}><FontAwesomeIcon icon="edit" /></Button>
                                    {/*<Button size="sm"><FontAwesomeIcon icon="trash-alt" /></Button>*/}
                                  </ButtonGroup>
                                </td>
                            </Fragment>}
                        </tr>
                      )) : <tr><td colSpan="4" className="detailTextNoSize font14">No records found</td></tr>}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </Container>
    )
  }
}