import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, Col, Container, Label, Row, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api, date_helpers, helpers } from '../utils';
import _ from 'underscore';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import AgentContact from './AgentContact';

const apiUrl = 'Agent/';

const emptyAgent = {
  id: null,
  mobileNumber: '', // not used, but col in database does not allow nulls
  comment: ''
};

const inputMaxLengths = {
  firstName: 25,
  lastName: 25,
  idTag: 5,
  ssn: 11,
  comment: 300,
  payToName: 100,
  email: 150,
  mobileNumber: 15
};

export default class EditAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageFlavor: 'danger',
      message: null,
      isMessageVisible: false,
      editItem: null,
      agentId: props.agentId,
      areaList: []
    };
    this.loadAgent = this.loadAgent.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.formatSSN = this.formatSSN.bind(this);
  }
  componentDidMount() {
    api.fetch("Reference/GetAreaList").then(response => {
      this.setState({
        areaList: response.data
      }, this.loadAgent)
    }).catch(helpers.catchHandler);
  }

  loadAgent() {
    if (this.state.agentId > 0) {
      api.fetch(`Agent/GetAgent/${this.state.agentId}`).then(response => {
        if (response && response.data) {
          let agent = response.data;
          agent.ssn = agent.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
          agent.homeArea = helpers.resolveValue(null, agent.homeAreaId, agent.homeAreaName);
          this.setState({
            editItem: response.data
          })
        }
      }).catch(helpers.catchHandler);
    } else {
      this.setState({ editItem: emptyAgent });
    }
  }

  onDismiss() {
    this.setState({
      isMessageVisible: !this.state.isMessageVisible,
      message: null,
      messageFlavor: null
    });
  }

  formatSSN() {
    let agent = Object.assign({}, this.state.editItem);
      agent.ssn = agent.ssn.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
    this.setState({
      editItem: agent
    })
  }

  onTextChange(fieldName, value) {
    const editItem = Object.assign({}, this.state.editItem);
    editItem[fieldName] = value;
    this.setState({ editItem: editItem });
  }

  onDateChange(name, date) {
    this.setState({ editItem: { ...this.state.editItem, [name]: date } });
  }

  onSelectChange(event) {
    this.setState({
      editItem: {
        ...this.state.editItem,
        homeArea: event,
        homeAreaId: event.value,
        homeAreaName: event.label
      }
    });
  }

  isFormValid() {
    const warnings = [];
    if (!this.state.editItem.firstName) {
      warnings.push("First Name is required.");
    }
    if (!this.state.editItem.lastName) {
      warnings.push("Last Name is required.");
    }
    if (!this.state.editItem.email || this.state.editItem.email.length < 1 || !helpers.emailIsValid(this.state.editItem.email)) {
      warnings.push("Valid email is required.");
    }
    if (!this.state.editItem.hiredOn) {
      warnings.push("Hired On date is required.");
    }
    if ((!this.state.editItem.ssn && !this.state.editItem.ein)) {
      warnings.push("SSN or EIN is required.");
    }
    if (!this.state.editItem.homeAreaId) {
      warnings.push("Home Area is required.");
    }
    if (!this.state.editItem.idTag || this.state.editItem.idTag.length < 1) {
      warnings.push("ID Tag is required.");
    }
    if (this.state.editItem.cmsnRate.length < 1) {
      warnings.push("Commission Rate is required.");
    }
    if (!this.state.editItem.payToName || this.state.editItem.payToName.length < 1) {
      warnings.push("Pay To Name is required.");
    }
    if (warnings.length) {
      this.setState({ message: warnings.join(' '), messageFlavor: "danger", isMessageVisible: true });
    } else {
      this.setState({ message: null, isMessageVisible: false });
    }
    return warnings.length === 0;
  }

  onSubmit() {
    window.scroll(0, 0);
    if (!this.isFormValid()) return;
    const payload = {
      ...this.state.editItem,
      ssn: this.state.editItem.ssn.replaceAll("-", ""),
      hiredOn: date_helpers.formatDateForServer(this.state.editItem.hiredOn),
      departureDate: date_helpers.formatDateForServer(this.state.editItem.departureDate),
      cmsnRate: Number(this.state.editItem.cmsnRate)
    };
    delete payload.homeArea;
    delete payload.homeAreaName;
    if (!payload.id) { delete payload.id; }
    const verb = this.state.editItem.id > 0 ? "put" : "post";
    api.upload(verb, apiUrl, payload)
      .then(result => {
        if (result.data.success) {
           this.setState({ 
               messageFlavor: "success",
             message: payload.id ? "Record Updated" : "Record Saved",
             isMessageVisible: true
           }, () => this.loadAgent());
        } else {
          this.setState({ messageFlavor: "danger", message: result.data.message, isMessageVisible: true });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    const SaveButton = () => {
      return (<Row className="expand-md mb-2 mt-2">
        <Col>
          <ButtonGroup className="float-right">
            <Button
              className="primary"
              size="sm"
              onClick={this.onSubmit}
            >
              <FontAwesomeIcon icon="save" /> Save
            </Button>
            <Button
              className="secondary"
              size="sm"
              tag={Link}
              to={"/agentadmin"}
            >
              <FontAwesomeIcon icon="times-circle" /> Close
            </Button>
          </ButtonGroup>
        </Col>
      </Row>);
    }
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {this.state.editItem &&
              <div className="row-spacing">
                {this.state.message !== null && (
                  <Row className="mb-2">
                    <Col>
                    <Alert className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={ this.onDismiss}>{this.state.message}</Alert>
                    </Col>
                  </Row>
              )}
                <Row className="mb-2">
                  <Col>
                    <h4 className="pull-left page-title">
                      {this.state.editItem.id && this.state.editItem.id > 0 ? 'Edit' : 'Add New'} Agent
                    </h4>
                </Col>
                <Col>
                  {SaveButton()}
                  </Col>
              </Row>

              <Row className="mb-2">
                  <Col>
                    <Label for="firstName" className="mb-1">First Name {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="firstName"
                      id="firstName"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.firstName || ""}
                      maxLength={inputMaxLengths.firstName}
                    />
                  </Col>
                  <Col>
                    <Label for="lastName" className="mb-1">Last Name {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="lastName"
                      id="lastName"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.lastName || ""}
                      maxLength={inputMaxLengths.lastName}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="3">
                    <Label for="email" className="mb-1">Email {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="email"
                      id="email"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.email || ""}
                      maxLength={inputMaxLengths.email}
                    />
                  </Col>
                  <Col xs="3">
                    <Label for="email" className="mb-1">Mobile #</Label>
                    <Input
                      type="input"
                      name="mobileNumber"
                      id="mobileNumber"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.mobileNumber || ""}
                      maxLength={inputMaxLengths.mobileNumber}
                    />
                  </Col>
                  <Col>
                    <Label for="hiredOn" className="mb-1">Hired On {helpers.requiredStar()}</Label>
                    <div className="datePickerWidth">
                      <DatePicker
                        onChange={e => this.onDateChange("hiredOn", e)}
                        selected={date_helpers.dateFormatForClient(this.state.editItem.hiredOn)}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col>
                    <Label for="departureDate" className="mb-1">Departure Date</Label>
                    <div className="datePickerWidth">
                      <DatePicker
                        onChange={e => this.onDateChange("departureDate", e)}
                        selected={date_helpers.dateFormatForClient(this.state.editItem.departureDate)}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  {/* <Col>
                    <Label for="mobileNumber" className="mb-1">Mobile Number {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="mobileNumber"
                      id="mobileNumber"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.mobileNumber || ""}
                      maxLength={inputMaxLengths.mobileNumber}
                    />
                  </Col> */}
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label for="ssn" className="mb-1">SSN {helpers.requiredStar()}</Label>
                    <Input
                    type="input"
                    name="ssn"
                    id="ssn"
                    onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                    value={this.state.editItem.ssn || ""}
                    maxLength={inputMaxLengths.ssn}
                    onBlur={this.formatSSN}
                    />
                  </Col>
                  <Col>
                    <Label for="ssn" className="mb-1">EIN {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="ein"
                      id="ein"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.ein || ""}
                      maxLength="15"
                    />
                  </Col>
                  <Col xs="6">
                    <Label for="homeArea" className="mb-1">Home Area {helpers.requiredStar()}</Label>
                    <Select
                      closeMenuOnSelect
                      id="homeArea"
                      value={this.state.editItem.homeArea}
                      isMulti={false}
                      components={makeAnimated()}
                      options={this.state.areaList}
                      onChange={this.onSelectChange}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="3">
                    <Label for="idTag" className="mb-1">ID Tag {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="idTag"
                      id="idTag"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.idTag || ""}
                      maxLength={inputMaxLengths.idTag}
                    />
                  </Col>
                  <Col xs="3">
                    <Label for="idTag" className="mb-1">Commission Rate{helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="cmsnRate"
                      id="cmsnRate"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.cmsnRate || "0"}
                      maxLength={inputMaxLengths.cmsnRate}
                    />
                  </Col>
                  <Col>
                    <Label for="payToName" className="mb-1">Pay To Name {helpers.requiredStar()}</Label>
                    <Input
                      type="input"
                      name="payToName"
                      id="payToName"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.payToName || ""}
                      maxLength={inputMaxLengths.payToName}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  
                  <Col>
                    <Label for="comment" className="mb-1">Comments</Label>
                    <Input
                      type="textarea"
                      name="comment"
                      id="comment"
                      onChange={(event) => this.onTextChange(event.target.name, event.target.value)}
                      value={this.state.editItem.comment || ""}
                      maxLength={inputMaxLengths.comment}
                    />
                  </Col>
              </Row>

              <AgentContact agentId={this.state.agentId} />
              {SaveButton()}
              </div>
            }
          </CardBody>
        </Card>
      </Container>);
  }
}
