import React, { Component } from 'react';

export default class Company extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <div>
                <h1>Company</h1>
                <div className="Card">
                    <div className="CardHeader">
                       
                    </div>
                </div>

            </div>
        );
    }
}

