import React, { Component, Fragment } from 'react';
import { Card, Table, Button, Row, Col,  Input, 
	Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Alert, Form } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import api from '../utils/api';
import makeAnimated from "react-select/animated";
import Select from 'react-select';

const emptyContact = {
	id: null,
	firstName: '',
	lastName: '',
	email: '',
	typeOfContact: {label: null, value: 0},
	isPrimary: false,
	phoneNumbers: [],
	addresses: [],
    key: 0,
    deactivatedAt: null
};             

const emptyPhone = {
	id: null,
	number: '',
	typeOfPhone: {label: null, value: 0},
	deactivatedAt: null,
    typeOfPhoneName: null,
    isPrimary: false,
};

const emptyAddress = {
	id: null,
	ln1: '',
	ln2: '',
	ln3: '',
	ln4: '',
	city: '',
	state: '',
	zip: '',
	typeOfAddress: {label: null, value: 0},
	isPrimary: false,
	deactivatedAt: null,
	typeOfAddressName: null
};

let addId = 0;
let nextNumber = 0;

// what?
const getAddId=() => {
	addId--;
	return addId;
};

const gimmeNextNumber = () => {nextNumber++; return nextNumber}

class EditPhone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.editPhone,
			contact: props.contact,
			showPhoneEdit: true,
			errorMessage: null,
			phoneTypeList: props.phoneTypeList
		};

		this.handleFormInputChange = this.handleFormInputChange.bind(this);
		this.handleFormSelectionChange = this.handleFormSelectionChange.bind(this);
		this.onPhoneSave = this.onPhoneSave.bind(this);
		this.onPhoneCancel = this.onPhoneCancel.bind(this);
		this.onIsPrimarySelection = this.onIsPrimarySelection.bind(this);
	}

	handleFormInputChange(event) {
		this.setState({...this.state.user, [event.target.name]: event.target.value});
	}

	handleFormSelectionChange(elementName, selection) {
		this.setState({
			[elementName]: {
				value: selection.value,
				label: selection.label
			}
		})
	}

  isFormValid() {
		let warnings = [];
		if (! this.state.number) {warnings.push("Please enter a phone number.");}
		if (this.state.typeOfPhone.label === null) {warnings.push("Please choose a type of phone.");}
		if (warnings.length) {
			this.setState({
                errorMessage: warnings.join(' '),
			});
		} else {
			this.setState({errorMessage: null});
		}
		return warnings.length === 0;
  }
    
  onPhoneSave() {
		if(! this.isFormValid()) return;
		this.props.onPhoneSave({
			id: this.props.editPhone.id,
			number: this.state.number,
			typeOfPhone: this.state.typeOfPhone,
			isPrimary: this.state.isPrimary,
		});
  }
    
  onPhoneCancel() {
		this.props.onPhoneCancel(this.state.id);
   }
    
  onIsPrimarySelection(){

		this.setState({isPrimary: !this.state.isPrimary});
	} 

  render() {
		return (                                   
			<Modal isOpen={true} backdrop={true}>
				<ModalHeader>Add new phone for {this.state.contact.firstName} {this.state.contact.lastName}</ModalHeader>
				<ModalBody>
					{this.state.errorMessage !== null
						? <Alert className="danger">{this.state.errorMessage}</Alert>
						: null
					}
					<Row>
						<Col lg="12">Phone Number
						<Input maxLength="100" type="text" name="number" id="number"
								onChange={this.handleFormInputChange} value={this.state.number}
								placeholder="Number" 
						/>
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}>
						<Col lg="6">Phone Type 
							<Select 
								closeMenuOnSelect={true}
								value={this.state.typeOfPhone}
								isMulti={false}
								components={makeAnimated()}
								options={this.state.phoneTypeList}
								onChange={this.handleFormSelectionChange.bind(this, "typeOfPhone")}
								placeholder="Select a phone type"
							/>
						</Col>
						<Col lg="6">Primary? 
							<br />
							<input
								type="checkbox"
								value="isPrimary"
								onChange={this.onIsPrimarySelection}
								checked={this.state.isPrimary}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="text-right">
							<ButtonGroup style={{marginTop: "12px"}}> 
								<Button 
									className="primary" 
									size="sm" 
									onClick={this.onPhoneSave}
								>
									<FontAwesomeIcon icon="save" /> Save
								</Button>
								<Button
									className="secondary"
									size="sm"
									onClick={this.onPhoneCancel}
								>
									<FontAwesomeIcon icon="times-circle" /> Cancel
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</ModalBody>
			</Modal>                                                                                                                                            
		);
	}
}

class EditAddress extends Component{
	constructor(props) {
		super(props);
		this.state = {...props.editAddress,
			contact: props.contact,
			showAddressEdit: true,
			errorMessage: null,
			addressTypeList: props.addressTypeList
		}

		this.handleFormInputChange = this.handleFormInputChange.bind(this);

		this.onTypeOfAddressSelection = this.onTypeOfAddressSelection.bind(this);
		this.onIsPrimarySelection = this.onIsPrimarySelection.bind(this);

		this.onAddressSave = this.onAddressSave.bind(this);
		this.onAddressCancel = this.onAddressCancel.bind(this);
	} 

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.contact !== this.props.contact) {
			this.setState({ contact: this.props.contact });
		}
	}

	isFormValid(){
		let warnings = [];
		if (!this.state.ln1) {warnings.push("Please enter at least one address line.");}
		if (!this.state.city) {warnings.push("Please enter a city.");}
		if (this.state.state.length !== 2) {warnings.push("Please use a 2-letter state abbreviation.");}
		if (!this.state.zip) {warnings.push("Please enter a zipcode.");}
		if (this.state.typeOfAddress.label === null) {warnings.push("Please choose a type of address.")}
		if (warnings.length) {
			this.setState({
                errorMessage: warnings.join(' '),
			});
		} else {
			this.setState({errorMessage: null});
		}
		return warnings.length === 0;
	}

	onAddressSave() {
		if(! this.isFormValid()) return;
		this.props.onAddressSave({
			id: this.props.editAddress.id,
			ln1: this.state.ln1,
			ln2: this.state.ln2,
			ln3: this.state.ln3,
			ln4: this.state.ln4,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			typeOfAddress: this.state.typeOfAddress,
			isPrimary: this.state.isPrimary
		});
	}

	onAddressCancel() {
		this.props.onAddressCancel(this.props.editAddress.id);
	}

	handleFormInputChange(event) {
	  this.setState({...this.state.user, [event.target.name]: event.target.value});
  }

	changeAddressProperty(propertyName, newValue){
		let newState = Object.assign({}, this.state);
		newState[propertyName] = newValue;
		this.setState(newState);
	}

	onTypeOfAddressSelection(selection){
		this.changeAddressProperty("typeOfAddress", selection);
	}

	onIsPrimarySelection(event){
		if (event.target.checked) {
			this.setState({isPrimary: true});
		} else {
			this.setState({isPrimary: false});
		}
	} 

	render() {
		return (                                   
			<Modal isOpen={true} backdrop={true}>
				<ModalHeader>Add new address for {this.state.contact.firstName} {this.state.contact.lastName}</ModalHeader>
				<ModalBody>
					{this.state.errorMessage !== null
						? <Alert className="danger">{this.state.errorMessage}</Alert>
						: null
					}
					<Row>
						<Col lg="12">Address 
						<Input maxLength="100" type="text" name="ln1" id="ln1"
								onChange={this.handleFormInputChange} value={this.state.ln1}
								placeholder="Line 1" 
						/>
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}>
						<Col lg="12"> 
						<Input maxLength="100" type="text" name="ln2" id="ln2"
								onChange={this.handleFormInputChange} value={this.state.ln2}
								placeholder="Line 2" 
						/>
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}> 
						<Col lg="12"> 
						<Input maxLength="100" type="text" name="ln3" id="ln3"
								onChange={this.handleFormInputChange} value={this.state.ln3}
								placeholder="Line 3" 
						/>
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}> 
						<Col lg="12">
						<Input maxLength="100" type="text" name="ln4" id="ln4"
								onChange={this.handleFormInputChange} value={this.state.ln4}
								placeholder="Line 4" 
						/>
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}> 
						<Col lg="6">City
						<Input maxLength="35" type="text" name="city" id="city"
								onChange={this.handleFormInputChange} value={this.state.city}
						/>
						</Col>
						<Col lg="3">State
						<Input maxLength="5"  type="text" name="state" id="state"
								onChange={this.handleFormInputChange} placeholder={"Abbr."} value={this.state.state}
						/>  
						</Col>
						<Col lg="3">Zip Code
						<Input maxLength="10" type="text" name="zip" id="zip"
								onChange={this.handleFormInputChange} value={this.state.zip}
						/>  
						</Col>
					</Row>
					<Row style={{marginTop: "12px"}}>
						<Col lg="6">Address Type 
							<Select 
								closeMenuOnSelect={true}
								value={this.state.typeOfAddress}
								isMulti={false}
								components={makeAnimated()}
								options={this.state.addressTypeList}
								onChange={this.onTypeOfAddressSelection}
								placeholder="Select an Address Type"
							/>
						</Col>
						<Col lg="6">Primary? 
							<br /><input type="checkbox" value="isPrimary" onChange={this.onIsPrimarySelection} checked={this.state.isPrimary} />
						</Col>
					</Row>
					<Row>
						<Col className="text-right">
							<ButtonGroup style={{marginTop: "12px"}}> 
								<Button className="primary" size="sm" onClick={this.onAddressSave}>
                  <FontAwesomeIcon icon="save" /> Save
								</Button>
								<Button className="secondary" size="sm" onClick={this.onAddressCancel}>
                  <FontAwesomeIcon icon="times-circle" /> Cancel
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</ModalBody>
			</Modal>                                                                                                                                            
		);
	}
}

class EditContact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contact: props.contact,
			typeOfContactList: [],
			phoneTypeList: [],
			addressTypeList: [],
			showAddressEdit: false,
			editAddress: null,
			showPhoneEdit: false,
			editPhone: null,
			editing: false,
			onNameAdd: props.onNameAdd,
			onCancel: props.onCancel,
			contactName: props.contact.firstName + ' ' + props.contact.lastName
		};

		this.handleFormInputChange = this.handleFormInputChange.bind(this);
		this.onAddressAdd = this.onAddressAdd.bind(this);
		this.onPhoneDelete = this.onPhoneDelete.bind(this);
		this.onPhoneRevive = this.onPhoneRevive.bind(this);
		this.onAddressDelete = this.onAddressDelete.bind(this);
		this.onAddressRevive = this.onAddressRevive.bind(this);
		this.onPhoneAdd = this.onPhoneAdd.bind(this);
		this.onPhoneSave = this.onPhoneSave.bind(this);
		this.onPhoneCancel = this.onPhoneCancel.bind(this);
		this.onAddressCancel = this.onAddressCancel.bind(this);
		this.onAddressSave = this.onAddressSave.bind(this);
		this.onContactSave = this.onContactSave.bind(this);
		this.onContactCancel = this.onContactCancel.bind(this);
		this.changeProperty = this.changeProperty.bind(this);
		this.onIsPrimarySelection = this.onIsPrimarySelection.bind(this);
		this.onTypeOfContactSelection = this.onTypeOfContactSelection.bind(this);
	}

	componentDidMount() {
		api.fetch('Reference/GetPhoneTypeList')
		.then((result) => {
			this.setState({ phoneTypeList: result.data });
		});

		api.fetch('Reference/GetContactTypeList')
		.then((result) => {
			this.setState({ typeOfContactList: result.data });
		});
		
	api.fetch('Reference/GetAddressTypeList')
		.then((result) => {
			this.setState({addressTypeList: result.data});
		});
	}

  componentDidUpdate(prevProps, prevState) {
		if (prevProps.contact !== this.props.contact) {
			this.setState({ contact: this.props.contact });
		}
	}
    
	changeProperty(propertyName, newValue) {
		let newContact = Object.assign({}, this.state.contact);
		newContact[propertyName] = newValue;
		this.setState({contact: newContact});
	}

	handleFormInputChange(event) {
        this.setState({contact: {...this.state.contact, [event.target.name]: event.target.value}});
    }

	onTypeOfContactSelection(selection){
		this.changeProperty("typeOfContact", selection);
	}

	onIsPrimarySelection(event){
		if (event.target.checked) {
			this.setState({contact: {...this.state.contact, isPrimary: true}});
		} else {
			this.setState({contact: {...this.state.contact, isPrimary: false}});
		}
	} 

	onTypeOfPhoneSelection(phoneId, selection) {
		let editList = this.state.contact.phoneNumbers.slice();
		let indexToEdit = _.findIndex(editList, {id: phoneId});
		editList[indexToEdit].typeOfPhone = selection;
		this.changeProperty("phoneNumbers", editList);
	}

	onNumberChange(phoneId, event){
		// find and update the element in the phone array
		let editList = this.state.contact.phoneNumbers.slice();
		let indexToEdit = _.findIndex(editList, {id: phoneId});
		editList[indexToEdit].number = event.target.value;
		// send the whole phone array to the function to 
		// update the contact property on state (replacing the whole array)
		this.changeProperty("phoneNumbers", editList);
	}

	onPhoneAdd(){
		let newPhone = Object.assign({}, emptyPhone);
		newPhone.id = getAddId();
		newPhone.editing = true;
		newPhone.pristine = null;
		const newPhoneNumbers = this.state.contact.phoneNumbers.slice();
		newPhoneNumbers.push(newPhone);
		this.changeProperty("phoneNumbers", newPhoneNumbers);
        //this.setState ({ showAddressEdit: true, editAddress: newAddress});
        this.setState({showPhoneEdit: true, editPhone: newPhone});
    }
    
    onPhoneEdit(phoneId){
		let editList = this.state.contact.phoneNumbers.slice();
		let indexToEdit = _.findIndex(editList, {id: phoneId});
		editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
		//editList[indexToEdit].editing = true;
        //this.changeProperty("phoneNumbers", editList);
        this.setState({contact: {...this.state.contact, phoneNumbers: editList}});
        this.setState({showPhoneEdit: true, editPhone: editList[indexToEdit]});
    }

  onPhoneSave(phone){
		let editList = this.state.contact.phoneNumbers.slice();
		if (phone.isPrimary) {
			_.each(editList, x => {
				x.isPrimary = false;
			})
		}
		let indexToEdit = _.findIndex(editList, {id: phone.id});
		editList[indexToEdit] = phone;
		editList[indexToEdit].editing = false;
		//this.changeProperty("phoneNumbers", editList);
		this.setState({
			contact: {
				...this.state.contact,
				phoneNumbers: editList
			},
			showPhoneEdit: false
		});
	}

    onPhoneCancel(phoneId){
		let editList = this.state.contact.phoneNumbers.slice();
        let indexToEdit = _.findIndex(editList, x => x.id === phoneId);
        
        if (editList[indexToEdit].number === ""){
			editList.splice(indexToEdit, 1);
		// } else {
		// 	editList[indexToEdit] = editList[indexToEdit.pristine];
        }
		//editList[indexToEdit - 1].editing = false;
		this.changeProperty("phoneNumbers", editList);

		this.setState({showPhoneEdit: false});
    }

	onPhoneDelete(phoneId){
		let editList = this.state.contact.phoneNumbers.slice();
		let indexToEdit = _.findIndex(editList, {id: phoneId});
		let currentPhone = editList[indexToEdit];
		if (currentPhone.id > 0) {
		// we really exist in the database, we need to send a deletion request
			api.delete(this.props.apiPath + 'DeletePhone/' + phoneId)
				.then(r => {
					if (r.data.success === false) {
						this.setState({messageFlavor: "danger", message: r.data.message});
					} else {
            // we're all good, set the phone to deleted
            editList[indexToEdit].deactivatedAt = new Date();
            this.changeProperty("phoneNumbers", editList);
					}
				}).catch(helpers.catchHandler);
		} else {
			// just remove it - we're still a poseur
			editList.splice(indexToEdit, 1);
			this.changeProperty("phoneNumbers", editList);
		}
    }
    
	onPhoneRevive(phoneId){
		api.delete(this.props.apiPath + 'RevivePhone/' + phoneId)
			.then(r => {
        if (r.data.success === false) {
            this.setState({messageFlavor: "danger", message: r.data.message});
        } else {
            // we're all good, set the phone to undeleted
            let editList = this.state.contact.phoneNumbers.slice();
            let indexToEdit = _.findIndex(editList, {id: phoneId});
            editList[indexToEdit].deactivatedAt = null;
            this.changeProperty("phoneNumbers", editList);
        }
			}).catch(helpers.catchHandler);
	}

	onAddressAdd(){
		let newAddress = Object.assign({}, emptyAddress);
		newAddress.id = getAddId();
		newAddress.editing = true;
		newAddress.pristine = null;
		const newAddresses = this.state.contact.addresses.slice();
		newAddresses.push(newAddress);
		this.changeProperty("addresses", newAddresses)
		this.setState({ showAddressEdit: true, editAddress: newAddress});
    }
    
	onAddressEdit(addressId){
		let editList = this.state.contact.addresses.slice();
		let indexToEdit = _.findIndex(editList, {id: addressId});
		editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
		editList[indexToEdit].editing = true;
		//this.changeProperty("addresses", editList);
		this.setState({contact: {...this.state.contact, addresses: editList}});
		this.setState({showAddressEdit: true, editAddress: editList[indexToEdit]})
	}
    
	onAddressSave(address) {
		let editList = this.state.contact.addresses.slice();
		let updatePrimaryByType = _.filter(editList, x => x.typeOfAddress.value === address.typeOfAddress.value)
		if (address.isPrimary) {
			_.each(updatePrimaryByType, x => {
				x.isPrimary = false;
			});
		}
		let indexToEdit = _.findIndex(editList, {id: address.id});
		editList[indexToEdit] = address;
		editList[indexToEdit].editing = false;
		this.changeProperty("addresses", editList);
		this.setState({ showAddressEdit: false});              
   }
    
    onAddressCancel(addressId){
		let editList = this.state.contact.addresses.slice();
		
		let indexToEdit = _.findIndex(editList, x => x.id === addressId);
		if (editList[indexToEdit].ln1 === ""){
			editList.splice(indexToEdit, 1);
		// } else {
		// 	editList[indexToEdit] = editList[indexToEdit.pristine];
		}
		this.setState({contact: {...this.state.contact, addresses: editList}});
		this.setState({ showAddressEdit: false});
	}

  	onAddressDelete(addressId){
		let editList = this.state.contact.addresses.slice();
		let indexToEdit = _.findIndex(editList, {id: addressId});
		let currentAddress = editList[indexToEdit];
		if (currentAddress.id > 0) {
			api.delete(this.props.apiPath + 'DeleteAddress/' + addressId)
				.then(r => {
					if (r.data.success === false){
						this.setState({messageFlavor: "danger", message: r.data.message});
					} else {
						editList[indexToEdit].deactivatedAt = new Date();
						this.changeProperty("addresses", editList);
					}
				}).catch(helpers.catchHandler);
		} else {
			editList.splice(indexToEdit, 1);
			this.changeProperty("addresses", editList);
		}
    }

	onAddressRevive(addressId){
		api.delete(this.props.apiPath + 'ReviveAddress/' + addressId)
			.then(r => {
				if (r.data.success === false) {
					this.setState({messageFlavor: "danger", message: r.data.message});
				} else {
					let editList = this.state.contact.addresses.slice();
					let indexToEdit = _.findIndex(editList, {id: addressId});
					editList[indexToEdit].deactivatedAt = null;
					this.changeProperty("addresses", editList);
				}
			}).catch(helpers.catchHandler);
	}

	onContactSave(event){
		event.preventDefault();
		this.props.onContactSave(this.state.contact);
	}

	onContactCancel() {
		this.props.onContactCancel(this.props.contact.id);
	}

	render() {
		return (
			<Fragment>
				<tr>
					<td>
						<Input
							type="text"
							name="firstName"
							id="firstName"
							onChange={this.handleFormInputChange}
							value={this.state.contact.firstName}
							placeholder="First Name"
							maxLength="25"
						/>
					</td>
					<td>
						<Input
							type="text"
							name="lastName"
							id="lastName"
							onChange={this.handleFormInputChange}
							value={this.state.contact.lastName}
							placeholder="Last Name"
							maxLength="25"
						/>
					</td>                                                                 
					<td>
						<Input
							type="text"
							name="email"
							id="email"
							onChange={this.handleFormInputChange}
							value={this.state.contact.email}
							placeholder="E-Mail"
							maxLength="150"
						/>
					</td>
					<td>-</td>   
					<td>
						<div style={{lineHeight: "24px"}}>
							<Select
								closeMenuOnSelect
								value = {this.state.contact.typeOfContact}
								isMulti={false}
								components={makeAnimated()}
								options={this.state.typeOfContactList}
								onChange={this.onTypeOfContactSelection}
								defaultValues={true}
							/>
						</div>
					</td>
					<td> 
						<div style={{textAlign: "center", verticalAlign: "middle"}}>	
							<Input
								type="checkbox"
								value="isPrimary"
								onChange={this.onIsPrimarySelection}
								checked={this.state.contact.isPrimary}
							/>
						</div>
					</td>
					<td>
						<ButtonGroup className="float-right">
							<Button 
								className="primary" 
								size="sm" 
								onClick={this.onContactSave}
							>
								<FontAwesomeIcon icon="save" />
							</Button>
							<Button
								className="secondary"
								size="sm"
								onClick={this.onContactCancel.bind(this, this.state.contact.id)}
							>
								<FontAwesomeIcon icon="times-circle" />
							</Button>
						</ButtonGroup>
					</td>
				</tr>
				<tr className="mt-2">
					<td colSpan="2">
						<Table hover>
							<thead>
								<tr>
									<th colSpan="2">Phone</th>
									<th>
										<Button className="success float-right" onClick={this.onPhoneAdd} size="sm" >
											<FontAwesomeIcon icon="plus" /> Add Phone
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>    
								{_.map(_.filter(this.state.contact.phoneNumbers, c => !c.deactivatedAt), c =>
									<tr key={`ph-${c.id}`}>
										<Fragment>
                        <td>{helpers.formatPhoneNumber(c.number)}</td>
                        <td>{c.typeOfPhone.label} {c.isPrimary ? "(Primary)" : null}</td>
                        <td className="text-right">
                            <ButtonGroup>
                                <Button
																	className="primary btn-outline-secondary"
																	size="sm"
																	onClick={this.onPhoneEdit.bind(this, c.id)}
																>
																	<FontAwesomeIcon icon="edit" />
                                </Button>
                                {c.deactivatedAt
																	? <Button
																			className="info"
																			size="sm" 
																			onClick={this.onPhoneRevive.bind(this, c.id)}
																			>
																				<FontAwesomeIcon icon="recycle" />
																		</Button>
																	: <Button
																			className="danger btn-outline-secondary"
																			size="sm"
																			onClick={this.onPhoneDelete.bind(this, c.id)}
																		>
																			<FontAwesomeIcon icon="trash" />
																		</Button>
                                }
                            </ButtonGroup>
                        </td>
                    </Fragment>
									</tr>
								)}
							</tbody>
						</Table>
					</td>
					<td colSpan="4">
						<Table hover>
							<thead>
								<tr>
									<th colSpan="2">Address</th>
									<th>
										<Button className="success float-right" size="sm" onClick= {() => this.onAddressAdd()}>
											<FontAwesomeIcon icon="plus" /> Add Address 
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{_.map(_.filter(this.state.contact.addresses, a => !a.deactivatedAt), a =>
									<Fragment key={`add-${a.id}`}>
										<tr>
											<td>
												{helpers.formatAddress(a).map(line => {
													return <Row key={gimmeNextNumber()}><Col>{line}</Col></Row>;
												})}
											</td>
											<td>{a.typeOfAddress.label} {a.isPrimary ? "(Primary)" : null}</td>
											<td className="text-right">
												{a.id === null
													? null
													: <ButtonGroup style={{ marginBottom: "-25px" }} className="float-right">
														<Button className="primary btn-outline-secondary" size="sm" onClick={this.onAddressEdit.bind(this, a.id)}>
															<FontAwesomeIcon icon="edit" />
														</Button>
														{a.deactivatedAt
															? (<Button className="info" size="sm" onClick={this.onAddressRevive.bind(this, a.id)}>
																<FontAwesomeIcon icon="recycle" />
															</Button>)
															: (<Button className="danger btn-outline-secondary" size="sm" onClick={this.onAddressDelete.bind(this, a.id)}>
																<FontAwesomeIcon icon="trash" />
															</Button>)
														}
													</ButtonGroup>
												}
											</td>
										</tr>
									</Fragment>
								)}
							</tbody>
						</Table>
						{(this.state.showAddressEdit) &&
							<EditAddress
								contact={this.state.contact} 
								editAddress={this.state.editAddress} 
								onAddressCancel={this.onAddressCancel}
								onAddressSave={this.onAddressSave}  
								onAddressAdd={this.onAddressAdd}  
								apiPath={this.props.apiPath}
								addressTypeList={this.state.addressTypeList}
							/>
						}
						{(this.state.showPhoneEdit) &&
							<EditPhone
								contact={this.state.contact}
								editPhone={this.state.editPhone}
								onPhoneCancel={this.onPhoneCancel}
								onPhoneSave={this.onPhoneSave}  
								onPhoneAdd={this.onPhoneAdd}  
								apiPath={this.props.apiPath}
								phoneTypeList={this.state.phoneTypeList}
							/>
						}
					</td>
				</tr>
				{/* <tr>
					<td colSpan="5">
						<ButtonGroup className="float-right" style={{ marginTop: "25px" }}>
							<Button 
								className="primary" 
								size="sm" 
								onClick={this.onContactSave}
							>
								<FontAwesomeIcon icon="save" /> Save Contact
							</Button>
							<Button
								className="secondary"
								size="sm"
								onClick={this.onContactCancel.bind(this, this.state.contact.id)}
							>
								<FontAwesomeIcon icon="times-circle" /> Cancel
							</Button>
						</ButtonGroup>
					</td>
				</tr> */}
			</Fragment>
		);
	}
}

export default class ContactList extends Component {
  constructor(props) {
	super(props);
	this.state = {
		contactList: props.contactList,
		showAddressEdit: false,
		editing: false,
		editingContact: emptyContact,
		redirect: false,
		message: null,
		messageFlavor: null,
		typeOfContactList: [],
		showModal: false,
		errorMessage: null,
		errorMessageFlavor: null
	};	
	this.isFormValid = this.isFormValid.bind(this);
	this.showAdd = this.showAdd.bind(this);
	this.onContactSave = this.onContactSave.bind(this);
	this.onContactCancel = this.onContactCancel.bind(this);                                                                                                                                                                                                                                               
  }

	isFormValid(newContactInfo, contactList){
	let warnings = [];
		let hasPrimaryContact = _.find(contactList, function (c) { return c.isPrimary === true; }) !== undefined;
		if (!newContactInfo.firstName) {warnings.push("Please enter a first name.");}
		if (!newContactInfo.lastName) {warnings.push("Please enter a last name.");}
		// if (!newContactInfo.email || !helpers.emailIsValid(newContactInfo.email)) {warnings.push("Please enter a valid email address.");}
		if (newContactInfo.typeOfContact.label === null) { warnings.push("Please choose a type of contact.") }
		if (warnings.length > 0) {
			this.setState({ errorMessage: warnings.join(", "), errorMessageFlavor: 'danger'})
    }
		return warnings.length === 0;
	}

	componentDidMount() {
		api.fetch(this.props.apiPath + 'GetContactType')
			.then((result) => {
				let list = result.data.map(function (c) {
					return {
						label: c.name,
						value: c.id
					};
				});
				this.setState({ typeOfContactList: list });
			});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contactList !== this.props.contactList) {
			this.setState({ contactList: this.props.contactList });
		}
	}

	showAdd(){
		const newList = this.state.contactList.slice();
		let newContact = Object.assign({}, emptyContact);
		newContact.editing = true;
		newContact.id = getAddId();
		newList.unshift(newContact);
		this.setState({ 
			contactList: newList,
			editing: true,
			editingContact: newContact
		}, () => { this.props.toggleContactEditing(true) });
	}


	onContactSave(newContactInfo) {
		if(!this.isFormValid(newContactInfo, this.state.contactList)) return;
		let newList = this.state.contactList.slice();

		newList = _.map(newList, x => {
			return {
				...x,
				isPrimary: false
			}
		}); 
		let existingIdx = _.findIndex(this.state.contactList, function(c){
			return c.id === newContactInfo.id;
		});
		if(existingIdx === -1){                         
			console.error("Could not find contact that should have existed " + newContactInfo.id );
		}
		else {
			newContactInfo.editing = false;
			newList[existingIdx] = newContactInfo;
			this.setState({
				contactList: newList,
				editing: false,
				editingContact: emptyContact
			}, () => {
				this.props.onListChanged(newList);
				this.props.toggleContactEditing(false);
			});
			if(existingIdx === -1){                         
				console.error("Could not find contact that should have existed " + newContactInfo.id );
			}
			else {
				newContactInfo.editing = false;
				newList[existingIdx] = newContactInfo;
				this.setState({
					contactList: newList,
					editing: false,
					editingContact: emptyContact
				}, () => {
					this.props.onListChanged(newList);
					this.props.toggleContactEditing(false);
				});
			}
		}
	}

	onEditContact(contactId) {
		let editList = this.state.contactList.slice();
		let indexToEdit = _.findIndex(editList, { id: contactId });

		editList[indexToEdit].pristine = Object.assign({}, editList[indexToEdit]);
		editList[indexToEdit].editing = true;

		this.setState({
			editing: true,
			editingContact: editList[indexToEdit],
			contactList: editList,
		}, () => { this.props.toggleContactEditing(true)});
	}

	onContactDelete(contactId){
		let editList = this.state.contactList.slice();
		let indexToEdit = _.findIndex(editList, {id: contactId});
		let currentContact = editList[indexToEdit];
		if (currentContact.id > 0) {
			api.delete(`${this.props.apiPath}DeleteContact/${contactId}`).then(r => {
				if (r.data.success === false) {
					this.setState({messageFlavor: "danger", message: r.data.message});
				} else {
					if (this.props.removeContactOnDeactivate) {
						editList.splice(indexToEdit, 1);
						this.setState({ contactList: editList});
						this.props.onListChanged(editList);
					} else {
						editList[indexToEdit].deactivatedAt = new Date();
						this.setState({ contactList: editList});
						this.props.onListChanged(editList);
					}
				}
			}).catch(helpers.catchHandler);
		} else {
			editList.splice(indexToEdit, 1);
			this.setState({ contactList: editList});
			this.props.onListChanged(editList);
		}
	}

	onContactRevive(contactId){
		let editList = this.state.contactList.slice();
		let indexToEdit = _.findIndex(editList, {id: contactId});
		let currentContact = editList[indexToEdit];
		if (currentContact.id > 0) {
			api.delete(`${this.props.apiPath}ReviveContact/${contactId}`).then(r => {
				if (r.data.success === false) {
					this.setState({messageFlavor: "danger", message: r.data.message});
				} else {
					editList[indexToEdit].deactivatedAt = null;
					this.setState({ contactList: editList});
					this.props.onListChanged(editList);
				}
			}).catch(helpers.catchHandler);
		} else {
			editList.splice(indexToEdit, 1);
			this.setState({ contactList: editList});
			this.props.onListChanged(editList);
		}
	}

	onContactCancel(contactId) {
		let editList = this.state.contactList.slice();
		let index = _.findIndex(editList, x => x.id === contactId);
		if (editList[index].firstName === "") {
			editList.splice(index, 1);
		}
		this.setState({
			editing: false,
			editingContact: emptyContact,
			contactList: editList
		}, () => { this.props.toggleContactEditing(false) });
	}

	toggleModal(show) {
		this.setState({showModal: show});
	}

render() {
    	return (
    		<Card className="p-3 mb-3">
					<h4>Contact Information  {helpers.requiredStar()}</h4>
					{this.state.errorMessage !== null ?
						<Alert className={this.state.errorMessageFlavor}>{this.state.errorMessage}</Alert>
					: null}
						<React.Fragment>
							<Table>
								<thead>
									<tr>
										<th>First Name {helpers.requiredStar()}</th>
										<th>Last Name {helpers.requiredStar()}</th>
										<th>Email</th>
										<th>Phone Numbers</th>
										<th>Contact Type {helpers.requiredStar()}</th>
										<th>Primary Contact?</th>
										<th> 
											{!this.state.editing
												? <Button
														size="sm"
														// id="toggler"
														className="float-right success"
														onClick={this.showAdd}
													> 
														<FontAwesomeIcon icon="plus" /> Add
													</Button>
												: null
											} 
										</th>
									</tr>
								</thead>
								<tbody>
									{_.map(_.filter(this.state.contactList, c => !c.deactivatedAt), c =>
										<Fragment key={`contact-${c.id}`}>
											{this.state.editingContact.id === c.id
												? <EditContact
														contact={c}
														onContactSave={this.onContactSave}
														onContactCancel={this.onContactCancel}
														contactList={this.state.contactList}
														onEditContact={this.onEditContact}
														apiPath={this.props.apiPath}
													/>  
												: <tr>
													<td>{c.firstName}</td> 
													<td>{c.lastName}</td>
													<td>{c.email}</td>
													<td>
															{_.map(_.filter(c.phoneNumbers, p => !p.deactivatedAt), p =>
																<Row key={`ph-${p.id}`}>
																	<Col xs="4">{p.typeOfPhone.label}</Col>
																	<Col xs="8" className='ml-0 pl-0'>{helpers.formatPhoneNumber(p.number)} {p.isPrimary ? "(Primary)" : null}</Col>
																</Row>
															)}
													</td> 
													<td>{c.typeOfContact.label || ""}</td>
													<td>{c.isPrimary ? <FontAwesomeIcon icon='check' /> : null}</td>
													<td>
														<ButtonGroup className="float-right">
															<Button size="sm" className="primary btn-outline-secondary" onClick={this.onEditContact.bind(this, c.id)}>
																<FontAwesomeIcon icon="edit"/>       
															</Button>
															{c.deactivatedAt
																? <Button size="sm" className="info" onClick={this.onContactRevive.bind(this, c.id)}>
																	<FontAwesomeIcon icon="recycle"/>  Revive     
																</Button>
															: <Button size="sm" className="danger btn-outline-secondary" onClick={this.onContactDelete.bind(this, c.id)}>
																	<FontAwesomeIcon icon="trash"/>       
																</Button>
														}
													</ButtonGroup>
												</td>
											</tr>
									}
								</Fragment>
							)}
						</tbody>
					</Table>
				</React.Fragment>
				<Modal isOpen={this.state.showModal} toggle={() => this.toggleModal(false)}>
					<ModalBody>
						<Alert className={this.state.errorMessageFlavor}>{this.state.errorMessage}</Alert>
					</ModalBody>
					<ModalFooter>
						<Button className="secondary" size="sm" onClick={() => this.toggleModal(false)}>OK</Button>
					</ModalFooter>
				</Modal>
			</Card>
		);
	}
}
