import React, { Fragment, Component} from "react";
import {
  Row,
  Col,
  Container,
} from "reactstrap";
import _ from "lodash";
import { api, helpers, constants } from "../utils";

export default class SqSalesRepScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesRepList: null
    };
    this.fetchAgentList = this.fetchAgentList.bind(this);
    this.redirectToContactScreen = this.redirectToContactScreen.bind(this);
  }

  componentDidMount() {
    this.fetchAgentList();
  }

  redirectToContactScreen(){
    window.location.pathname = '/contact-us'
  };

  fetchAgentList(e) {
    const payload = {
      filters: []
    };
    api
      .post("web/GetSalesReps", payload)
      .then((response) => {
        if (response && response.data) {
          let agents = [];
          _.map(response.data, agent => {
            let agentName = `${agent.firstName} ${agent.lastName}`;
            agent.FullName = agentName;
            if (agent.email !== "") {
              agents.push(agent);
            }
          })
          let agentsGroupedByState = _.groupBy(agents, agent => agent.state)
          let stateSections = _.chain(constants.STATES_LIST)
            .map(stOption => {
              const stateRepsList = _.find(agentsGroupedByState, (_repList, stateKey) => stOption.value === stateKey.trim())
              if (stateRepsList) {
                return {
                  key: `statekKey${stOption.value}`,
                  stateName: stOption.label,
                  repList: stateRepsList
                };
              } else {
                return null;
              }
            })
            .filter(stOption => { return stOption && stOption.stateName })
            .value();

            const length = stateSections && stateSections.length;
            let sections = [];
            if (length && length > 0) {
              const columnGroupSize = Math.ceil(length / 2)
              const set = _.chunk(stateSections, columnGroupSize)
              sections = set;
            }
          this.setState({ salesRepList: sections });
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    const {
      salesRepList
    } = this.state;
    return (
      <Container>
        <Row>
          <Col sm="12">
            <Row>
              {salesRepList
                && salesRepList.length
                  ? _.map(salesRepList, (colGroup, i) => {
                      return (
                        <Col xs="6" key={`colGroup${i}`}>
                          {_.map(colGroup, stateList => { return (
                            <Fragment key={`${stateList.key}`}>
                             <Row>
                               <Col>
                                 <h5 className="squarespaceText">{stateList.stateName}</h5>
                               </Col>
                             </Row>
                             <Row className="mb-2 pl-3">
                               <Col>
                                 {_.map(stateList.repList, rep => {
                                   return (
                                     <Row className="mb-2" key={`${rep.idTag}${rep.lastName}`}>
                                       <Col className="pl-3">
                                       <strong>{rep.FullName}: </strong>
                                       <a href={`mailTo:${rep.email}`}>{rep.email}</a>
                                       </Col>
                                     </Row>
                                   )
                                 })}
                               </Col>
                             </Row>
                           </Fragment>
                          ) })}
                        </Col>
                      )
                    })
                  : null}
            </Row>
            <Row>
              <Col className="squarespaceText align-self-center" md="8" xs="12">
                <h4 className='float-right'>Don't see an agent in your area?</h4>
              </Col>
              <Col>
                <button
                  className="squarespaceButton float-right"
                  style={{ marginTop: "0" }}
                  onClick={this.redirectToContactScreen}
                >
                  Contact Us
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
