import React, { Component, Fragment } from 'react';
import { Collapse, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FilterSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: (props.open !== undefined ? props.open : false),
            clearFilters: props.clearFilters
        };
        this.toggleFilter = this.toggleFilter.bind(this);
        this.getFilterDisplay = this.getFilterDisplay.bind(this);
    }

    getFilterDisplay(filters) {
        if (!filters || !filters.length || filters.length === 0) {
            return null;
        }
        const filterDisplay = filters.map(x => (((x.value !== null && x.value !== undefined)
                || (x.values && x.values.length && x.values.length > 0) 
                || x.startDate
                || x.endDate)
            ? x.filteredDisplay
            : null)).join(' | ');
        if (!filterDisplay) {
            return '';
        }
        return [<span className='title' key='applied-filter-title'>Applied Filters: </span>, filterDisplay];
    }

    toggleFilter() {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        const filterDisplayText = this.getFilterDisplay(this.props.filters);
        return (
            <Fragment>
                <span
                    title="Click here to toggle filter display"
                    className="filter"
                    onClick={this.toggleFilter}
                    size="sm"
                >
                    <FontAwesomeIcon icon="filter" /> Filters
                </span>
                <span className="filter-display">
                    {filterDisplayText}
                </span>
                {this.props.filters && this.props.filters.length !== 0 && filterDisplayText
                    ? (
                    <Button
                        className="danger btn-outline-secondary"
                        size="sm"
                        onClick={this.state.clearFilters}
                    >
                        <FontAwesomeIcon
                            icon="backspace"
                            className="mr-2 ml-0 mt-0 mb-0"
                        />{" "}
                        Clear
                    </Button>
                ) : null}
                <Collapse isOpen={this.state.collapse} className="row-spacing filter-definition-row">
                    <Row>
                        <Col>
                            {this.props.children}
                        </Col>
                    </Row>
                </Collapse>
            </Fragment>
        );
    }
}

export default FilterSet;