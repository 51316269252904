import React, { Component } from 'react';

export default class NotFound extends Component {
    render() {
        return (
            <h1 id="heading">404 Not Found</h1>
        );
    }
}

