import React, { Component, Fragment } from "react";
import moment from 'moment';
import {
  Table,
  ButtonGroup,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  Input,
  Alert as Alert,
} from 'reactstrap';
import api from '../utils/api';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import _ from 'lodash';
import date_helpers from '../utils/date_helpers';
import helpers from '../utils/helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Redirect } from 'react-router-dom';

const emptyExpense = {
  id: -1,
  name: '',
  agentId: null,
  supplier: null,
  checkNumber: '',
  invoiceNumber: '',
  items: [],
  mth: new Date(),
  invoiceDate: null,
  paidOn: null,
  total: 0
};

const emptyExpenseItem = {
  id: -1,
  expenseCategory: null,
  sampleBook: null,
  expenseType: null,
  amount: '',
  comment: '',
  editing: true
}

const SAMPLE_EXPENSE_NAME = "Sample Expense";

const formatTotal = (items) => {
  items = _.map(_.reject(items, i => i.deactivatedAt), i => { return Number(parseFloat(i.amount, 10).toFixed(2)) });
  let total = _.sum(items);
  return helpers.formatCurrency(total);
}

export default class EditExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseId: props.match && props.match.params && props.match.params.id,
      message: null,
      messageFlavor: null,
      isMessageVisible: false,
      agentList: [],
      expenseCategoryList: [],
      bookList: [],
      editExpense: emptyExpense,
      editing: false,
      pristine: null,
      redirect: false,
      redirectExpenseCategoryAdmin: false
    };
    this.onSubmitAdd = this.onSubmitAdd.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.loadExpense = this.loadExpense.bind(this);
    this.onItemSelectChange = this.onItemSelectChange.bind(this);
    this.onItemTextChange = this.onItemTextChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.handleUnDelete = this.handleUnDelete.bind(this);
    this.reloadExpenseCategory = this.reloadExpenseCategory.bind(this);
    this.redirectExpenseCategoryAdmin = this.redirectExpenseCategoryAdmin.bind(this);
  }

  componentDidMount() {
    const getAgents = api.fetch('Reference/GetAgentList')
      .then((result) => {
        return { agentList: result.data };
      })
      .catch(helpers.catchHandler);
    const getExpenseTypes = api.fetch('Reference/GetExpenseTypeList')
      .then((result) => {
        return { expenseTypeList: result.data };
      })
      .catch(helpers.catchHandler);
    const getBooks = api.fetch('Reference/GetSampleBookList')
      .then((result) => {
        return { bookList: result.data };
      })
      .catch(helpers.catchHandler);
    const getExpenseCategories = api.fetch('Reference/GetExpenseCategoryList')
      .then((result) => {
        return { expenseCategoryList: result.data };
      })
      .catch(helpers.catchHandler);
    const getExpenseCategoriesForClerk = api.fetch('ExpenseCategory/GetExpenseCategoryListForClerk')
    .then((result) => {
        return { expenseCategoryList: result.data };
    })
    .catch(helpers.catchHandler);
    // const getSupplier = api.fetch('Reference/GetSupplierList')
    //   .then((result) => {
    //     return { supplierList: result.data };
    //   }).catch(helpers.catchHandler);
    const getExpensePayToList = api.fetch('Reference/GetExpensePayToList')
      .then((result) => {
        return { payToList: result.data };
      }).catch(helpers.catchHandler);
  let APIlist = [
    getAgents,
    getExpenseTypes,
    getBooks,
    // getSupplier,
    getExpensePayToList
    ]
    //push the full list if admin, otherwise this is a finance clerk
    if(this.props.currentUser && this.props.currentUser.isAdmin) {
      APIlist.push(getExpenseCategories);
    } else {
      APIlist.push(getExpenseCategoriesForClerk);
    }
      
    Promise.all(APIlist)
      .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus, () =>
          this.loadExpense());
      })
      .catch(helpers.catchHandler);
  }

  loadExpense() {
    let expenseId = this.props.computedMatch.params.id;
    if (expenseId === "new") {
      this.setState({ editExpense: emptyExpense });
    } else if (expenseId > 0) {
      api.fetch(`Expense/GetExpense/${expenseId}`)
        .then(response => {
          if (response.data) {
            let data = response.data;
            let expenseItem = _.head(response.data.expenses);
            let expense = {
              id: data.id,
              name: data.name,
              mth: new Date(expenseItem.mth),
              invoiceDate: expenseItem.invoiceDate ? new Date(expenseItem.invoiceDate) : null,
              paidOn: expenseItem.paidOn ? new Date(expenseItem.paidOn) : null,
              checkNumber: expenseItem.checkNumber,
              invoiceNumber: expenseItem.invoiceNumber,
              createdAt: expenseItem.createdAt,
              supplier: _.find(this.state.payToList, s => { return s.label === expenseItem.payToName }),
              agent: helpers.resolveValue(expenseItem.agent, expenseItem.agentId, `${expenseItem.agentFirstName} ${expenseItem.agentLastName}`),
              items: _.map(data.expenses, item => {
                item.expenseCategory = helpers.resolveValue(item.expenseCategory, item.expenseCategoryId, item.expenseCategoryName);
                item.expenseType = helpers.resolveValue(item.expenseType, item.expenseTypeId, item.expenseTypeName);
                item.sampleBook = helpers.resolveValue(item.sampleBook, item.sampleBookId, item.sampleBookName);
                return item;
              })
            }
            if (!expense.supplier) {
              expense.supplier = { 
                label: expenseItem.payToName, 
                value: expenseItem.payToName
              };
            }
            expense.total = formatTotal(expense.items.slice());
            this.setState({ editExpense: expense });
          }
        }).catch(helpers.catchHandler);
    }
  }

  onDismiss() {
    this.setState({
      isMessageVisible: !this.state.isMessageVisible,
      message: null,
      messageFlavor: null
    });
  }

  onTextChange(e, fieldName) {
    let expense = Object.assign({}, this.state.editExpense)
    expense[fieldName] = e.target.value;
    this.setState({ editExpense: expense });
  }

  onSelectChange(selection, fieldName) {
    this.setState({
      editExpense: {
        ...this.state.editExpense,
        [fieldName]: selection
      }
    });
  }

  onDateChange(date, fieldName) {
    this.setState({
      editExpense: { ...this.state.editExpense, [fieldName]: date }
    })
  }

  isFormValid() {
    let warnings = [];
    //if (!this.state.editExpense.checkNumber) {
    //  warnings.push("Check Number is required.");
    //}
    if (!this.state.editExpense.mth) {
      warnings.push("Month is required.");
    }
    if (!this.state.editExpense.supplier) {
      warnings.push("Pay To Name is required.");
    }
    if (this.state.editExpense.items.length === 0) {
      warnings.push("Expense item is required");
    }
    if (this.state.editExpense.items && this.state.editExpense.items.length > 1 && this.state.editExpense.name === "") {
      warnings.push("Expense group name is required")
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(' '),
        messageFlavor: "danger",
        isMessageVisible: true
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  onSubmitAdd(e) {
    if (!this.isFormValid()) return;
    e.preventDefault();
    let expense = Object.assign({}, this.state.editExpense);
    let payload = {
      Name: expense.name,
      Expenses: _.map(expense.items, (i, index) => ({
          Id: i.id,
          ExpenseCategoryId: i.expenseCategory ? i.expenseCategory.value : null,
          SampleBookId: i.sampleBook ? i.sampleBook.value : null,
          ExpenseTypeId: i.expenseType ? i.expenseType.value : null,
          AgentId: expense.agent ? expense.agent.value : null,
          PayToName: expense.supplier ? expense.supplier.label : null,
          Amount: Number(i.amount),
          Comment: i.comment,
          Mth: expense.mth ? expense.mth : null,
          CheckNumber: expense.checkNumber,
          InvoiceNumber: expense.invoiceNumber ? expense.invoiceNumber : null,
          InvoiceDate: expense.invoiceDate ? expense.invoiceDate : null,
          PaidOn: expense.paidOn ? expense.paidOn : null,
          IsParent: (index === 0)
      }))
    };
    if (expense.id > 0) {
      payload.Id = expense.id;
    }
    api.upload('post', 'Expense/SaveExpense', payload).then(result => {
      if (result.data.success) {
        let newState = {messageFlavor: "success", message: "Expense was saved", isMessageVisible: true, editExpense: emptyExpense};
        if (expense.id > 0) {
          this.setState(newState);
          this.loadExpense();
        } else {
          newState.message = "Expense was saved. You will be redirected momentarily.";
          this.setState(newState, () => setTimeout(() => this.setState({ redirect: true }), 1500));
        }
      } else {
        this.setState({ messageFlavor: "danger", message: result.data.message, isMessageVisible: true });
      }
    }).catch(helpers.catchHandler);
  }

  isItemValid(item) {
    let warnings = [];
    if (!item.amount) {
      warnings.push("Amount is required.");
    }
    if (!item.expenseCategory) {
      warnings.push("Category is required.");
    }
    if (!item.expenseType) {
      warnings.push("Expense Type Required.");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(' '),
        messageFlavor: "danger",
        isMessageVisible: true
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;

  }

  onItemTextChange(e, fieldName, index) {
    let expense = Object.assign({}, this.state.editExpense)
    expense.items[index][fieldName] = e.target.value;
    this.setState({ editExpense: expense });
  }

  onItemSelectChange(selection, fieldName, index) {
    let expense = Object.assign({}, this.state.editExpense)
    expense.items = expense.items.slice();
    expense.items[index][fieldName] = selection;
    if (fieldName === "expenseType" && selection.label !== SAMPLE_EXPENSE_NAME) {
      expense.items[index].sampleBook = null;
    }
    this.setState({
      editExpense: expense
    });
  }

  addNewExpenseItem() {
    var expense = Object.assign({}, this.state.editExpense);
    expense.items = expense.items.slice();
    var newItem = Object.assign({}, emptyExpenseItem);
    newItem.expenseType = _.find(this.state.expenseTypeList, t => t.label === 'Company Expense');
    expense.items.push(newItem);
    this.setState({ editExpense: expense, editing: true });
  }

  onSaveItem(index) {
    var expense = Object.assign({}, this.state.editExpense);
    var item = Object.assign({}, expense.items[index]);
    if (!this.isItemValid(item)) return;
    expense.items[index].editing = false;
    expense.total = formatTotal(expense.items.slice());
    this.setState({ editExpense: expense, editing: false });
  }

  onCancelItem(index) {
    var expense = Object.assign({}, this.state.editExpense);
    expense.items = expense.items.slice();
    if (this.state.pristine === null) {
      expense.items = _.reject(expense.items, (i, indx) => { return indx === index; });
    } else {
      expense.items[index] = this.state.pristine;
      expense.items[index].editing = false;
    }
    this.setState({ editExpense: expense, editing: false, pristine: null });
  }

  onEditItem(index) {
    var expense = Object.assign({}, this.state.editExpense);
    var item = Object.assign({}, expense.items[index]);
    expense.items[index].editing = true;
    this.setState({ editExpense: expense, editing: true, pristine: item });
  }

  onDeleteItem(index) {
    var expense = Object.assign({}, this.state.editExpense);
    expense.items = expense.items.slice();
    var item = Object.assign({}, expense.items[index]);
    if (item.id < 0) {
      expense.items = _.reject(expense.items, (i, indx) => {
        return indx === index;
      });
      this.setState({ editExpense: expense });
    } else {
      this.deleteItem(item.id)
    }
  }

  deleteItem(itemId) {
    if (!window.confirm(`Are you sure you'd like to delete this expense line item?`)) return;
    api.delete(`Expense/DeleteExpenseItem/${itemId}`)
      .then(response => {
        if (response.data.success) {
          this.setState({message: "Expense Item has been deleted", messageFlavor: "success", isMessageVisible: true})
          this.loadExpense();
        } else {
          this.setState({
            message: (response.data.message || "An error occurred. Expense Item cannot be deleted at this time."),
            messageFlavor: "success",
            isMessageVisible: true
          })
        }
      }).catch(helpers.catchHandler);
  }

  handleUnDelete(id) {
    api.post('Expense/ReviveExpenseItem/' + id)
      .then(response => {
        if (response.data.success) {
          this.setState({ message: "Expense Item was revived", messageFlavor: "success", isMessageVisible: true })
          this.refreshList()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      }).catch(helpers.catchHandler);
  }

  reloadExpenseCategory() {
    api.fetch('Reference/GetExpenseCategoryList')
      .then((result) => {
        this.setState({expenseCategoryList: result.data})
      }).catch(helpers.catchHandler);
  }

  redirectExpenseCategoryAdmin() {
    window.open("/expensecategory", '_blank');
  }

  render() {
    if (this.state.redirect) { return <Redirect to='/expense' /> }
    const {
      editExpense,
      agentList,
      expenseCategoryList,
      expenseTypeList,
      bookList,
      message,
      messageFlavor,
      isMessageVisible,
      editing, 
      payToList
    } = this.state;
    const GroupedButton = () => {
      return (
        <ButtonGroup className="btn-group btn-access float-right">
          <Button className="primary buttonGroupSpacing" size="sm" onClick={this.onSubmitAdd} disabled={this.state.editing}>
            <FontAwesomeIcon icon="save" /> Save
          </Button>
          <Button className="secondary buttonGroupSpacing" size="sm" tag={Link} to={`/expense`}>
            <FontAwesomeIcon icon="times-circle" /> Cancel
          </Button>
      </ButtonGroup>
    )}
    return (
      <Fragment>
        <Row className="m-2 p-2">
          <Col>
            <h4 className="page-title">{editExpense.id ? 'Edit' : 'Add'} Expense</h4>
          </Col>
          <Col>{GroupedButton()}</Col>
        </Row>

        {editExpense &&
          <Row className="m-2 p-2">
            <Col>
              <Card>
                <CardBody>
                  <Card className="p-2 mb-2">
                    {message && (
                      <Row className="p-2 mb-2">
                        <Col>
                          <Alert className={messageFlavor} isOpen={isMessageVisible} toggle={() => this.onDismiss()}>{message}</Alert>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>

                        <Row className="mb-2">
                          <Col xs="3" className="">
                            {editExpense.createdAt &&
                              <div className="detailTextNoSize">
                                <span> Created on: {date_helpers.dateTimeFormat(editExpense.createdAt, "MM/DD/YYYY")}  </span>
                              </div>
                            }
                            <span className="boldText">Month {helpers.requiredStar()}</span>
                            <DatePicker
                              selected={editExpense.mth}
                              className="form-control float-right"
                              placeholderText="Select a date performed"
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              onChange={(e) => this.onDateChange(e, "mth")}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="4">
                            <span className="boldText">PAY TO {helpers.requiredStar()}</span>
                            <Select
                              closeMenuOnSelect
                              value={editExpense.supplier}
                              isMulti={false}
                              components={makeAnimated()}
                              options={payToList}
                              onChange={(e) => this.onSelectChange(e, "supplier")}
                              placeholder="Select a Pay To"
                            />
                        </Col>
                        <Col className="float-right">
                          <span className="boldText">CHECK #</span>
                          <Input
                            className="form-control"
                            value={editExpense.checkNumber}
                            name="checkNumber"
                            id="checkNumber"
                            onChange={(e) => this.onTextChange(e, 'checkNumber')}
                            maxLength="10"
                            type="input"
                          />
                        </Col>

                          <Col className="float-right">
                            <span className="boldText">PAID ON</span>
                            <DatePicker
                              selected={editExpense.paidOn}
                              className="form-control float-right"
                              placeholderText="Select a date"
                              onChange={(e) => this.onDateChange(e, "paidOn")}
                            />
                          </Col>
                        </Row>
                      <Row className="mb-2">
                        {/*<Col className="float-right">
                            <span className="boldText">AGENT</span>
                            <Select
                              closeMenuOnSelect
                              value={editExpense.agent}
                              isMulti={false}
                              components={makeAnimated()}
                              options={agentList}
                              onChange={(e) => this.onSelectChange(e, "agent")}
                              placeholder="Select an Agent"
                            />
                          </Col>*/}
                          <Col className="float-right">
                            <span className="boldText">INVOICE #</span>
                            <Input
                              className="form-control"
                              value={editExpense.invoiceNumber}
                              name="invoiceNumber"
                              id="invoiceNumber"
                              onChange={(e) => this.onTextChange(e, 'invoiceNumber')}
                              maxLength="30"
                              type="input"
                            />
                        </Col>
                        <Col className="float-right">
                          <span className="boldText">INVOICE DATE</span>
                          <DatePicker
                            selected={editExpense.invoiceDate}
                            className="form-control float-right"
                            placeholderText="Select a date"
                            onChange={(e) => this.onDateChange(e, "invoiceDate")}
                          />
                        </Col>

                        </Row>
                      </Col>
                      <Col xs="3" className="centerText p-4 expenseTotalStyle">
                        <span className="boldText">AMOUNT DUE</span>
                        <div className="font24">{editExpense.total}
                        </div>
                      </Col>
                    </Row>
                  </Card>

                  <Row className="mb-2">
                    {editExpense.items.length > 1 &&
                      <Col>
                        <span className={editExpense.name === '' && editExpense.name.length < 3 ? "highlightText boldText" : "boldText"}>Expense Group Name  {helpers.requiredStar()}</span>
                        <Input type="text" id="name" maxLength="255" value={editExpense.name} onChange={(e) => this.onTextChange(e, "name")} />
                      </Col>
                    }
                    <Col className="float-right">
                      <Button size="sm" className="info float-right" onClick={() => this.addNewExpenseItem()}>Add Expense Item</Button>
                    </Col>
                  </Row>

                  {editExpense.items.length > 0 &&
                    <Fragment>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Category {helpers.requiredStar()} <FontAwesomeIcon className="text-primary ml-2" icon="sync-alt" onClick={()=>this.reloadExpenseCategory()} title="Refresh category list"/></th>
                            <th>Expense Type {helpers.requiredStar()}</th>
                            <th width="20%">Sample Type</th>
                            <th width="10%">Amount {helpers.requiredStar()}</th>
                            <th>Memo</th>
                            <th width="8%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {editExpense.items.length ?
                            _.reject(editExpense.items, x => x.deactivatedAt).map((item, index) => (
                              <tr key={`expenseItem-${index}`}>
                                {item.editing ? (
                                  <Fragment>
                                    <td>
                                      <CreatableSelect
                                        closeMenuOnSelect
                                        value={item.expenseCategory}
                                        isMulti={false}
                                        components={makeAnimated()}
                                        options={expenseCategoryList}
                                        onChange={(e) => this.onItemSelectChange(e, "expenseCategory", index)}
                                        onCreateOption={()=>this.redirectExpenseCategoryAdmin()}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        closeMenuOnSelect
                                        value={item.expenseType}
                                        isMulti={false}
                                        components={makeAnimated()}
                                        options={expenseTypeList}
                                        onChange={(e) => this.onItemSelectChange(e, "expenseType", index)}
                                      />
                                    </td>
                                    <td>
                                        <Select
                                          closeMenuOnSelect
                                          value={item.sampleBook}
                                          isMulti={false}
                                          components={makeAnimated()}
                                          options={bookList}
                                          onChange={(e) => this.onItemSelectChange(e, "sampleBook", index)}
                                          isDisabled={
                                            !item.expenseType ||
                                            item.expenseType && item.expenseType.label !== SAMPLE_EXPENSE_NAME 
                                          }
                                          isClearable
                                        />
                                    </td>
                                    <td>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        value={item.amount}
                                        name="amount"
                                        id="amount"
                                        onChange={(e) => this.onItemTextChange(e, 'amount', index)}
                                        maxLength="200"
                                        min="0"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="textarea"
                                        id="comment"
                                        value={item.comment}
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.onItemTextChange(e, 'comment', index)}
                                        name="comment"
                                        placeholder="Add a comment"
                                        maxLength="300"
                                      />
                                    </td>
                                    <td>
                                      <ButtonGroup className="float-right">
                                        <Button size="sm" className="primary buttonGroupSpacing" onClick={() => this.onSaveItem(index)}><FontAwesomeIcon icon="save" /></Button>
                                        <Button size="sm" className="danger buttonGroupSpacing" onClick={() => this.onCancelItem(index)}><FontAwesomeIcon icon="times-circle" /></Button>
                                      </ButtonGroup>
                                    </td>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <td>{item.expenseCategory.label || ""}</td>
                                    <td>{item.expenseType.label || ""}</td>
                                    <td>{item.sampleBook ? item.sampleBook.label : ""}</td>
                                      <td>{helpers.formatCurrency(item.amount)|| ""}</td>
                                    <td>{item.comment || ""}</td>
                                    <td>
                                      <ButtonGroup className="float-right">
                                        {item.deactivatedAt ? (
                                          <Button size="sm" className="info" onClick={() => this.handleUnDelete(item.id)} disabled={editing}><FontAwesomeIcon icon="reply" title="Revive expense item"/></Button>
                                        ) : (
                                          <Fragment>
                                            <Button size="sm" className="primary buttonGroupSpacing" onClick={() => this.onEditItem(index)} disabled={editing}><FontAwesomeIcon icon="edit" title="Edit expense item"/></Button>
                                            <Button size="sm" className="danger buttonGroupSpacing" onClick={() => this.onDeleteItem(index)} disabled={editing}><FontAwesomeIcon icon="trash-alt" title="Delete Expense item"/></Button>
                                          </Fragment>
                                        )}
                                      </ButtonGroup>
                                    </td>
                                  </Fragment>
                                )}
                              </tr>
                            )) : null}
                        </tbody>
                      </Table>
                      {GroupedButton()}
                    </Fragment>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Fragment>
    );
  }
}
