import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Card,
	CardBody,
	Row,
	Col,
	Container,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Label,
	ButtonGroup,
	Button,
	Alert as Alert2,
	Input,
	Table,
	Collapse,
	Badge,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroupButtonDropdown
} from 'reactstrap';
import Alert from "react-s-alert";
import {
	api,
	helpers,
	constants,
	date_helpers,
	calc
} from '../utils';
import CustomerAdmin from './CustomerAdmin';
import { CustomerTypeahead, InputDebounce } from '../components';
import classnames from 'classnames';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import ToggleButton from 'react-toggle-button';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";
import moment from 'moment';
import { object } from 'underscore';

const MIN_REPEAT = constants.MIN_REPEAT;
const MITERED_PRODUCT_NAME = constants.MITERED_PRODUCT_NAME;
const EDGE_TYPES = {
	MITERED: 'Hidden Stitch Mitered Corners',
	MACHINED: 'Applied Machine Border'
};
const FINISHED_RUG_SIZES = constants.FINISHED_RUG_SIZES;

const ORDER_LINE_TYPES_DONT_SHOW_FREIGHT = [
	constants.ORDER_LINE_TYPE.CUSTOM_RUG.value,
	constants.ORDER_LINE_TYPE.RUG_BORDER.value,
	constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value
];

const COMPONENT_ORDER_LINE_TYPES = [
	constants.ORDER_LINE_TYPE.RUG_FIELD.value,
	constants.ORDER_LINE_TYPE.RUG_BORDER.value,
	constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value
];

const MODAL_DELETE_CONFIRMATION_TYPES = {
	NORMAL_ORDER_LINE: 1,
	CUSTOM_RUG_ORDER_LINE: 2
};

const rugComponentTypeList = [
	constants.ORDER_LINE_TYPE.RUG_FIELD,
	constants.ORDER_LINE_TYPE.RUG_BORDER,
	constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS
];


let CustomRugProducts;

const {
	RUG_BACKING_PRICE_PER,
	RUG_BACKING_COST_PER,
	PAD_CUT_COST,
	PAD_CUT_PRICE
} = constants.CUSTOM_RUG_DEFAULT_PRICES

const emptyOrder = {
	id: null,
	orderNumber: null,
	orderStatus: { // default value
		value: constants.ORDER_STATUS.OPEN.value,
		label: constants.ORDER_STATUS.OPEN.label
	},
	mth: new Date(),
	isDistinctiveOrder: true,
	createdAt: new Date(),
	placedBy: '',
	customer: null,
	agent: null,
	isReplacementOrder: false,
	isCreditOrder: false,
	referencesOrder: "",
	// billing info
	btContactFirstName: "",
	btContactLastName: "",
	btContactEmail: "",
	btAddressLine1: "",
	btAddressLine2: "",
	btAddressLine3: "",
	btAddressLine4: "",
	btCity: "",
	btState: "",
	btZip: "",
	btPhone: "",
	btFax: "",
	invoiceTerms: null,
	customerPONumber: "",
	relatedReplacementOrders: ""
};

const emptyOrderLine = {
	id: 0,
	orderId: null,
	parentOrderLineId: null,
	orderLineType: { // default value
		value: constants.ORDER_LINE_TYPE.CARPET.value,
		label: constants.ORDER_LINE_TYPE.CARPET.label
	},
	dsc: "",
	unitCost: "",
	cost: "",
	unitPrice: "",
	price: "",
	// cmsnRate: "10.00",
	cmsn: "",
	isHidden: false,
	product: null,
	productColor: null,
	width: null,
	lengthFeet: "",
	orderLineStatus: { // default value
		value: constants.ORDER_LINE_STATUS.NA.value,
		label: constants.ORDER_LINE_STATUS.NA.label
	},
	priceUnitType: 'Sq Yds',
	squareFeetRequired: "",
	creditCardSurchargeRate: "0",
	taxRate: "0",  // not nullable in db
	rugSize: "",
	finishedSize: {value: 1, label: "Custom"},
	rugLengthFeet: "0",
	rugLengthInches: "0",
	rugWidthFeet: "8",
	rugWidthInches: "0",
	rugType: null,
	rugIntlCollection: null,
	rugIntlSize: null,
	qty: "",
	cartonCount: "",
	squareFeetPerCarton: "", // does not belong to orderLine schema, but set for wood and carpet tile product calculations
	// freight:
	freightCarrier: null,
	freightSidemark: "",
	freightInvoiceNumber: "",
	freightBolNumber: "",
	freightDsc: "Freight Charges",
	freightTerms: null,
	freightCost: "0.00",
	freightPrice: "0.00",
	stContactFirstName: "",
	stContactLastName: "",
	stContactEmail: "",
	stAddressLine1: "",
	stAddressLine2: "",
	stAddressLine3: "",
	stAddressLine4: "",
	stCity: "",
	stState: "",
	stZip: "",
	stPhone: "",
	createdAt: null,
	createdByUserId: null,
	width: { widthNumericFeet: 0 },
	commentCount: 0, // for temporary use only
	productAllowCommission: null,

	// custom rug component specific
	rugWidthFeet: "8",
	rugWidthInches: "0",
	rugLengthFeet: "10",
	rugLengthInches: "0",
	rugDsc: "",
	sqFt: "0.0",
	finishedEdgeFt: "0.0",
	selectedField: null,
	appliedFieldRepeat: "0.0",
	selectedFieldColor: null,
	selectedFieldWidth: null,
	fieldWidthList: [],
	bindingList: [],
	selectedBinding: null,
	fieldRepeatWidth: "0.0",
	fieldRepeatLength: "0.0",
	repeatDsc: "",
	fieldLengthFeet: "0",
	fieldLengthInches: "0",
	fieldCostPer: "0.0",
	totalFieldCost: "0.00",
	fieldPricePer: "0.00",
	totalFieldUnits: "0.0",
	totalFieldPrice: "0.00",
	// fieldCmsnRate: "10.00",
	fieldCmsn: "0.00",
	fieldPriceUnitType: "",

	selectedBorder: null,
	// appliedBorderRepeat: "0.0",
	selectedBorderColor: null,
	selectedBorderWidth: null,
	borderWidthList: [],
	bindingList: [],
	// borderRepeatWidth: "0.0",
	// borderRepeatLength: "0.0",
	// repeatDsc: "",
	borderLengthFeet: "",
	borderLengthInches: "",
	borderCostPer: "0.0",
	totalBorderCost: "0.00",
	borderPricePer: "0.00",
	totalBorderUnits: "0.0",
	totalBorderPrice: "0.00",
	// borderCmsnRate: "10.00",
	borderCmsn: "0.00",
	borderPriceUnitType: "",
};

const emptyOrderLineComment ={
	id: 0,
	dsc: "",
	commentType: null,
	isPrivate: false,
	markedForDeletion: false
};

const emptyRugOrderLine = (customRugComponentProducts, currentAgentCmsnRate) => {
	return {  // for custom rug quotes only (from angular app)
	...emptyOrderLine,
	rugEditsValid: false,
	rugHasClippedCorners: false,
	rugClippedCornersCost: customRugComponentProducts.DefaultClippedCornersProduct ? _.round(customRugComponentProducts.DefaultClippedCornersProduct.unitCost, 2).toFixed(2) : "5.00",
	rugClippedCornersPrice: customRugComponentProducts.DefaultClippedCornersProduct ? _.round(customRugComponentProducts.DefaultClippedCornersProduct.unitPrice, 2).toFixed(2) : "15.00",
	rugHasLaborCutout: false,
	rugLaborCutoutCost: customRugComponentProducts.DefaultLaborCutoutProduct ? _.round(customRugComponentProducts.DefaultLaborCutoutProduct.unitCost, 2).toFixed(2) : "75.00",
	rugLaborCutoutPrice: customRugComponentProducts.DefaultLaborCutoutProduct ? _.round(customRugComponentProducts.DefaultLaborCutoutProduct.unitPrice, 2).toFixed(2) : "100.00",
	rugHasLaborRound: false,
	rugLaborRoundCost: customRugComponentProducts.DefaultLaborRoundProduct ? _.round(customRugComponentProducts.DefaultLaborRoundProduct.unitCost, 2).toFixed(2) : "25.00",
	rugLaborRoundPrice: customRugComponentProducts.DefaultLaborRoundProduct ? _.round(customRugComponentProducts.DefaultLaborRoundProduct.unitPrice, 2).toFixed(2) : "30.00",
	rugHasCutCharge: false,
	rugCutChargeCost: customRugComponentProducts.DefaultCutChargeProduct ? _.round(customRugComponentProducts.DefaultCutChargeProduct.unitCost, 2).toFixed(2) : "0.00",
	rugCutChargePrice: customRugComponentProducts.DefaultCutChargeProduct ? _.round(customRugComponentProducts.DefaultCutChargeProduct.unitPrice, 2).toFixed(2) : "25.00",
	rugHasEndArmor: false,
	rugArmorCost: customRugComponentProducts.DefaultRugEndArmorProduct ? _.round(customRugComponentProducts.DefaultRugEndArmorProduct.unitCost, 2).toFixed(2) : "8.00",
	rugArmorPrice: customRugComponentProducts.DefaultRugEndArmorProduct ? _.round(customRugComponentProducts.DefaultRugEndArmorProduct.unitPrice, 2).toFixed(2) : "10.00",
	rugHasInboundFreight: false,
	selectedInboundFreight: customRugComponentProducts.DefaultInboundFreightProduct ? customRugComponentProducts.DefaultInboundFreightProduct : null,
	rugInboundFreightCost: customRugComponentProducts.DefaultInboundFreightProduct ? _.round(customRugComponentProducts.DefaultInboundFreightProduct.unitCost, 2).toFixed(2) : "45.00",
	rugInboundFreightPrice: customRugComponentProducts.DefaultInboundFreightProduct ? _.round(customRugComponentProducts.DefaultInboundFreightProduct.unitPrice, 2).toFixed(2) : "45.00",
	rugHasBacking: false,
	rugHasPad: false,
	rugSerged: true,
	rugMachineSerged: true,
	rugHiddenStitch: true,
	rugWidthFeet: "8",
	rugWidthInches: "0",
	rugLengthFeet: "10",
	rugLengthInches: "0",
	rugDsc: "",
	finishedSize: {value: 1, label: "Custom"},
	// selectedRugEdge: "Serging",
	sqFt: "0.0",
	finishedEdgeFt: "0.0",
	orderLines: [],
	selectedField: null,
	searchingProduct: false,
	searchProductFailed: false,
	// hideSearchingProductWhenUnsubscribed: new Observable(() => () => this.searchingProduct = false),
	appliedFieldRepeat: "0.0",
	selectedFieldColor: null,
	selectedFieldWidth: null,
	fieldWidthList: [],
	bindingList: [],
	selectedBinding: null,
	fieldRepeatWidth: "0.0",
	fieldRepeatLength: "0.0",
	repeatDsc: "",
	fieldLengthFeet: "0.0",
	fieldLengthInches: "0.0",
	fieldCostPer: "0.0",
	totalFieldCost: "0.00",
	fieldPricePer: "0.00",
	totalFieldUnits: "0.0",
	totalFieldPrice: "0.00",
	fieldCmsnRate: currentAgentCmsnRate,	//"10.00",
	fieldCmsn: "0.00",
	fieldPriceUnitType: "",
	// fieldDsc: "",

	totalRugBackingPrice: "0.00",
	rugBackingPricePer: customRugComponentProducts.DefaultBackingProduct ? _.round(customRugComponentProducts.DefaultBackingProduct.unitPrice, 2).toFixed(2) : RUG_BACKING_PRICE_PER,
	totalRugBackingUnits: "0.0",
	totalRugBackingCost: "0.00",
	rugBackingCostPer: customRugComponentProducts.DefaultBackingProduct ? _.round(customRugComponentProducts.DefaultBackingProduct.unitCost, 2).toFixed(2) : RUG_BACKING_COST_PER,
	rugBackingCmsn: "0.00",
	rugBackingCmsnRate: currentAgentCmsnRate,	//"10.00"

	selectedPad: customRugComponentProducts.DefaultRugPadLuxFeltCustomSizeProduct ? customRugComponentProducts.DefaultRugPadLuxFeltCustomSizeProduct : null,
	padList: [],
	hasPadCutCharge: true,
	padCutCost: customRugComponentProducts.DefaultPadCutChargeProduct ? _.round(customRugComponentProducts.DefaultPadCutChargeProduct.unitCost, 2).toFixed(2) : PAD_CUT_COST,
	padCutPrice: customRugComponentProducts.DefaultPadCutChargeProduct ? _.round(customRugComponentProducts.DefaultPadCutChargeProduct.unitPrice, 2).toFixed(2) : PAD_CUT_PRICE,
	selectedPadWidth: null,
	padWidthList: [],
	totalPadUnits: "0.0",
	padLengthFeet: "0.0",
	padCostPer: "0.00",
	totalPadCost: "0.00",
	padPricePer: "0.00",
	totalPadPrice: "0.00",
	padCmsnRate: currentAgentCmsnRate,	//"10.00"
	padCmsn: "0.00",
	padPriceUnitType: "",

	selectedSerging: null,

	sergingCostPer: "0.00",
	totalSergingCost: "0.00",
	sergingPricePer: "0.00",
	totalSergingPrice: "0.00",
	sergingCmsnRate: currentAgentCmsnRate,	//"10.00"
	sergingCmsn: "0.00",

	selectedMSerging: null,
	mSergingCostPer: "0.00",
	totalMSergingCost: "0.00",
	mSergingPricePer: "0.00",
	totalMSergingPrice: "0.00",
	mSergingCmsnRate: currentAgentCmsnRate,	//"10.00"
	mSergingCmsn: "0.00",

	selectedHSSerging: null,
	hsSergingCostPer: "0.00",
	totalHSSergingCost: "0.00",
	hsSergingPricePer: "0.00",
	totalHSSergingPrice: "0.00",
	hsSergingCmsnRate: currentAgentCmsnRate,	//"10.00"
	hsSergingCmsn: "0.00",

	selectedRugBindingStyle: null,
	rugBindingList: [],
	selectedRugBindingColor: null,
	rugBindingColorList: [],
	rugBindingCostPer: "0.00",
	totalRugBindingCost: "0.00",
	rugBindingPricePer: "0.00",
	totalRugBindingPrice: "0.00",
	rugBindingCmsnRate: currentAgentCmsnRate,	//"10.00"
	rugBindingCmsn: "0.00"
}};

// const create_UUID = () => {
// 	var dt = new Date().getTime();
// 	var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
// 		c
// 	) {
// 		var r = (dt + Math.random() * 16) % 16 | 0;
// 		dt = Math.floor(dt / 16);
// 		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
// 	});
// 	return uuid;
// };

const emptyOrderPayment = {
	id: null,
	paymentType: { // default value
		value: constants.PAYMENT_TYPE.EXTENDED_CREDIT.value,
		label: constants.PAYMENT_TYPE.EXTENDED_CREDIT.label
	},
	customerPaymentStatus: null,
	referenceNumber: "",
	payToName: "",
	checkNumber: "",
	amountDue: "", // remove this not nullable field eventually?
	amountPaid: "",
	billOn: "",
	dueOn: "",
	paidOn: "",
	forWhat: "",   // remove this not nullable field eventually?
	invoiceNumber: "",
	comment: "",
	dateReceived: new Date(),
	expDate: '',
	cardNumber: "",
	billingName: "",
	billingZip: "",
	creditCardType: null,
	creditCardSurchargeRate: "",
	taxRate: ""
};

const emptyOrderFulfillment = {
	id: 0,
	orderFulfillmentStatus: null,
	supplier: null,
	freightCarrier: null,
	referenceNumber: "",
	invoiceNumber: "",
	checkNumber: "",
	amountDue: "",
	amountPosted: "0",
	notes: "",
	billDate: new Date(),
	dueDate: new Date(),
	payDate: new Date(),
	isPayToSupplier: true
};

const invoiceOptions = _.map(constants.TYPE_OF_INVOICE, (v, k) => {return {label: k, value: v}});

// const CUSTOM_DSC =
// 	`Please inspect this merchandise for style, size and color to insure you have received proper product.
// 	Mill will not honor any complaints on visible defects after merchandise has been cut or installed. By
// 	accepting shipment of this material the bill to agrees to pay all attorney, court, and collection fees if
// 	placed for collections on this invoice. Freight Claims: Title of Carpet transfers to the purchaser when
// 	accepted by Freight carrier. You have 14 days to report concealed Damage. ALL CARPETS MUST BE
// 	PROFESSIONALLY CLEANED EVERY 18 MONTHS TO MAINTAIN YOUR WARRANTY! IF YOU USE A
// 	CYCLONIC ACTION VACUUM ON ANY OF OUR CARPETS IT COULD VOID YOUR WARRANTY.`;

const CUSTOM_DSC2 = ``;

const TABS = {
	GENERAL_INFORMATION: 1,
	LINE_ITEMS: 2,
	PAYMENT: 3,
	FULLFILLMENT: 4,
	FINAL_REVIEW: 5,
	PRINT: 6,
}

export default class OrderEntry extends Component {
	constructor(props) {

		const date = new Date();

		super(props);
		this.state = {
			activeTab: this.props.location && this.props.location.state && this.props.location.state.activeOrderId ? TABS.FINAL_REVIEW : TABS.GENERAL_INFORMATION,
			activeOrderId: this.props.location && this.props.location.state && this.props.location.state.activeOrderId ? this.props.location.state.activeOrderId : null,
			showCancelOrderModal: false,
			generalOrderSaved: false,
			order: {
				...emptyOrder,
				createdByUser: {
					value: props.currentUser.id,
					label: props.currentUser.firstName + ' ' + props.currentUser.lastName
				},
				// updatedByUser: {
				// 	value: props.currentUser.id,
				// 	label: props.currentUser.firstName + ' ' + props.currentUser.lastName
				// },
			},
			rugShapeList: [],
			message: null,
			messageFlavor: null,
			monthList: constants.monthList,
			yearList: constants.yearList,
			orderStatusList: [],
			userList: [],
			agentList: [],
			productsList: [],
			// collectionProductsList: [], // used for Intl Rug order lines
			widthsList: [],
			freightCarrierList: [],
			commentTypeList: [],
			freightTermsList: [],
			customerPaymentStatusList: [],
			credtiCardTypeList: [],
			orderFulfillmentStatusList: [],
			supplierList: [],
			totalOrderCost: "",
			totalOrderPrice: "",
			orderLines: [],
			orderFulfillments: [],
			orderPayments: [],
			verifications: [],
			orderId: props.orderId,
			orderFulfillment: emptyOrderFulfillment,
			addNewFulfillment: false,
			orderPayment: emptyOrderPayment,
			paymentTypeList: [],
			selectedCustomer: [],
			creditCardTypeList: [],
			sumOfPayments: "",
			balance: "",
			orderLine: emptyOrderLine,
			newOrderLineComment: emptyOrderLineComment,
			orderLineCommentList: [],
			showOrderLineForm: false,
			orderLineCount: 0,
			editingFreight: false,
			editingNotes: false,
			notesList: null,
			orderLineTypeList: [],
			orderLineStatusList: [],
			colorFamilyList: [],
			areaList: [],
			addUpdatePayment: false,
			orderFulfillmentsTotalDue: "",
			orderFulfillmentsTotalPosted: "",
			orderFulfillmentsBalance: "",
			customRugToViewEdit: null,
			confirmationModalOrderLineId: null,
			confirmationModalType: null,
			confirmationModalComponentLines: null,
			showCustomerSearchModal: false,
			colorOptOut: false,
			generatingReplacement: false,
			showOrderLineStatusModal: false,
			backOrderOrShippedDate: null,
			hasSalesTaxOrSurcharge: false,
			// rug related
			selectedRugEdge: "Serging",
			sergingList: [],
			mSergingList: [],
			hsSergingList: [],
			invoiceDate: date,
			dueDate: date,
			customDescription: '',
			customDescription2: CUSTOM_DSC2,
			invoiceType: invoiceOptions[0],
			customerAddressList: [],
			customerShipToAddresses: []
		};
		this.toggle = this.toggle.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.yesCancelOrder = this.yesCancelOrder.bind(this);
		this.noCancelOrder = this.noCancelOrder.bind(this);
		this.handleOrderStatusSelectChange = this.handleOrderStatusSelectChange.bind(this);
		// this.handleMonthSelectChange = this.handleMonthSelectChange.bind(this);
		// this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
		this.handleCreatedAtInputChange = this.handleCreatedAtInputChange.bind(this);
		this.handleTakenBySelectChange = this.handleTakenBySelectChange.bind(this);
		this.handleOnPlacedByInputChange = this.handleOnPlacedByInputChange.bind(this);
		this.onToggleCustomerSearchModal = this.onToggleCustomerSearchModal.bind(this);
		this.onCustomerSelectedFromSearchModal = this.onCustomerSelectedFromSearchModal.bind(this);
		this.handleCustomerSelectChange = this.handleCustomerSelectChange.bind(this);
		this.handleOrderInputChange = this.handleOrderInputChange.bind(this);
		this.getCustomer = this.getCustomer.bind(this);
		this.handleAgentSelectChange = this.handleAgentSelectChange.bind(this);
		this.onReferencesOrderChange = this.onReferencesOrderChange.bind(this);
		this.isGeneralInfoFormValid = this.isGeneralInfoFormValid.bind(this);
		this.saveOrderEntryGeneralInfo = this.saveOrderEntryGeneralInfo.bind(this);
		this.isOrderLineFormValid = this.isOrderLineFormValid.bind(this);
		this.setSelectedShippingContact = this.setSelectedShippingContact.bind(this);
		this.setSelectedShippingAddress = this.setSelectedShippingAddress.bind(this);
		this.setSelectedBillingContact = this.setSelectedBillingContact.bind(this);
		this.setSelectedBillingAddress = this.setSelectedBillingAddress.bind(this);
		this.saveOrderLine = this.saveOrderLine.bind(this);
		this.formatSavedOrder = this.formatSavedOrder.bind(this);
		this.formatSavedOrderLines = this.formatSavedOrderLines.bind(this);
		this.formatSavedOrderPayments = this.formatSavedOrderPayments.bind(this);
		this.handleDateReceivedChange = this.handleDateReceivedChange.bind(this);
		this.createInitialOrderFulfillments = this.createInitialOrderFulfillments.bind(this);
		this.isOrderPaymentFormValid = this.isOrderPaymentFormValid.bind(this);
		this.isOrderFulfillmentFormValid = this.isOrderFulfillmentFormValid.bind(this);
		this.downloadReprint = this.downloadReprint.bind(this);
		this.transformItemToRugComponentOrderLine = this.transformItemToRugComponentOrderLine.bind(this);

		// rug component stuff
		this.toggleCutCharge = this.toggleCutCharge.bind(this);
		this.toggleArmor = this.toggleArmor.bind(this);
		this.toggleInboundFreight = this.toggleInboundFreight.bind(this);
		this.toggleBacking = this.toggleBacking.bind(this);
		this.togglePad = this.togglePad.bind(this);
		this.toggleClippedCorners = this.toggleClippedCorners.bind(this);
		this.toggleLaborCutout = this.toggleLaborCutout.bind(this);
		this.toggleLaborRound = this.toggleLaborRound.bind(this);
		this.toggleEdgeOptions = this.toggleEdgeOptions.bind(this);
		this.initializeRugOrder = this.initializeRugOrder.bind(this);
		this.refreshRugDescription = this.refreshRugDescription.bind(this);
		this.rugSizeChange = this.rugSizeChange.bind(this);
		this.fieldSizeChange = this.fieldSizeChange.bind(this);
		this.setRugEdge = this.setRugEdge.bind(this);
		this.onHasPadCutChargeChange = this.onHasPadCutChargeChange.bind(this);
		this.borderSizeChange = this.borderSizeChange.bind(this);

		this.generateReplacementCredit = this.generateReplacementCredit.bind(this);
		this.applyVolumePricingIfNecessary = this.applyVolumePricingIfNecessary.bind(this);
		this.calculateFigures = this.calculateFigures.bind(this);
		this.loadInvoiceDynamicDsc = this.loadInvoiceDynamicDsc.bind(this);
		this.getOverallStatus = this.getOverallStatus.bind(this);
		// this.renderSignOffForm = this.renderSignOffForm.bind(this);
		this.renderWorkorderForm = this.renderWorkorderForm.bind(this);
		this.onChangeAdditionalRugField = this.onChangeAdditionalRugField.bind(this);
	}

	async componentDidMount() {
		this.getOrderSelectsData();
		let CUSTOM_DSC = '';
		await api.fetch('Order/GetInvoiceBoilerPlate')
			.then(response => {
				_.forEach(response.data, (x) => {
					CUSTOM_DSC = CUSTOM_DSC + x.dsc + " ";
				});
				this.setState({customDescription: CUSTOM_DSC});
			})
			.catch(helpers.catchHandler);
		if (this.props.location && this.props.location.state && this.props.location.state.activeOrderId) {
			this.getExistingOrder();
		}
	}

	generateReplacementCredit() {
			this.setState({ generatingReplacement: true, message: "Generating Replacement Credit", messageFlavor: "info" });
			api.post(`Order/GenerateReplacementOrder/${this.state.order.id}`)
				.then(response => {
					if (response.data) {
						this.setState({
							generatingReplacement: false,
							message: "Replacement Order Generated",
							messageFlavor: "success",
							order: {
								...this.state.order,
								relatedReplacementOrders: `R${response.data}`
							}
						});

					} else {
						this.setState({
							message: "There was an error generating replacement credit.",
							messageFlavor: "danger"
						});
					}
				}).catch(helpers.catchHandler);
		}

	getOrderSelectsData() {
		api.fetch('Order/GetOrderSelectsData')
			.then(response => {
				CustomRugProducts = response.data.customRugProducts;
				this.setState({
					orderStatusTypeList: response.data.orderStatusTypeList,
					orderLineTypeList: this.formatOrderLineTypeList(response.data.orderLineTypeList),
					orderLineStatusList: response.data.orderLineStatusList,
					paymentTypeList: response.data.paymentTypeList,
					colorFamilyList: response.data.colorFamilyList,
					agentList: response.data.agentList,
					areaList: response.data.areaList,
					orderStatusList: response.data.orderStatusList,
					userList: response.data.userList,
					freightCarrierList: response.data.freightCarrierList,
					commentTypeList: response.data.commentTypeList,
					freightTermsList: response.data.freightTermsList,
					customerPaymentStatusList: response.data.customerPaymentStatusList,
					creditCardTypeList: response.data.creditCardTypeList,
					orderFulfillmentStatusList: response.data.orderFulfillmentStatusList,
					supplierList: response.data.supplierList,
					rugShapeList: response.data.rugShapeList
				});
			})
			.catch(helpers.catchHandler);
	}

	getExistingOrder() {
		api.fetch(`Order/${this.props.location.state.activeOrderId}`).then((response) => {
			const order = this.formatSavedOrder(response.data.order);
			let orderLines = this.formatSavedOrderLines(response.data.orderLines);
			const orderPayments = this.formatSavedOrderPayments(response.data.orderPayments);
			const orderFulfillments = this.formatSavedOrderFulfillments(response.data.orderFulfillments);
			let invoiceState = response.data.invoice
				? {
						invoiceDate: response.data.invoice.invoiceDate
							? date_helpers.getSelectedDate(response.data.invoice.invoiceDate).valueOf()
							: null,
						dueDate: response.data.invoice.dueDate
							? date_helpers.getSelectedDate(response.data.invoice.dueDate).valueOf()
							: null,
						customDescription: response.data.invoice.customDescription,
						customDescription2: response.data.invoice.customDescription2,
						invoiceType: _.find(invoiceOptions, io => io.value === response.data.invoice.typeOfInvoice),
					}
				: {};
			this.setState(Object.assign(invoiceState, {
				order: order,
				orderId: order.id,
				orderLines: orderLines,
				orderPayments: orderPayments,
				orderFulfillments: orderFulfillments,
				hasSalesTaxOrSurcharge: _.findIndex(orderLines, o => {
					return o.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value
						|| o.orderLineTypeId === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value
				}) >= 0
			}), () => {
				this.getOrderTotals(response.data.orderLines, response.data.orderPayments, response.data.orderFulfillments,
					() => this.getCustomer(response.data.order.customerId));
			});
		}).catch(helpers.catchHandler);
	}

	async getCustomer(customerId) {
		api.fetch(`Customer/GetActiveContacts/${customerId}`)
			.then((response) => {
				let customerInfo = response.data.customer;
				let customerContacts = response.data.contacts;
				let customerAddresses = _.chain(response.data.customerAddresses)
					.filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.BILL_TO })
					.map(a => {
						a.value = a.id;
						a.label = helpers.formatAddressLabel(a)
						return a;
					}).value();
				const customerShipToAddresses = _.chain(response.data.customerAddresses)
					.filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.SHIP_TO })
					.map(a => {
						a.value = a.id;
						a.label = helpers.formatAddressLabel(a, '* ')
						return a;
					}).value();
				customerContacts.map(c => {
					// remove deactivated phones and addresses for each contact
					const addresses = _.filter(c.addresses, a => a.deactivatedAt === null);
					const phoneNumbers = _.filter(c.phoneNumbers, p => p.deactivatedAt === null);
					c.expanded = false;
					c.addresses = addresses;
					c.phoneNumbers = phoneNumbers;

					// set value and label for select
					c.label = `${c.firstName} ${c.lastName}`;
					c.value = c.id;
					return c;
				});
				let customerCredits = _.map(response.data.customerCredits, c => {
					c.value = c.id;
					c.label = `${c.displayAmount} ${c.comment} - Given on ${moment(c.givenOn).format("MM/DD/YYYY")}`;
					// c.amount = c.amount !== 0 && c.amount !== '' ? c.amount / 100 : null;
					c.amount = c.amount;
					return c;
				});
				this.setState({
					customerInfo: customerInfo,
					customerContacts: customerContacts,
					customerOutstandingCredits: customerCredits,
					customerAddressList: customerAddresses,
					customerShipToAddresses: customerShipToAddresses
				}, () => {
					if(customerAddresses.length == 1) {
						this.setSelectedBillingAddress(customerAddresses[0])
					}
				});
			}).catch(helpers.catchHandler);
	}

	async getOrderLines(orderId) {
		api.fetch(`Order/GetOrderLines/${orderId}?x=${new Date().getMilliseconds()}`)
			.then(response => {
				const orderLines = this.formatSavedOrderLines(response.data);
				let updatedCustomRugToViewEdit;
				if (this.state.customRugToViewEdit) {
					updatedCustomRugToViewEdit = _.find(orderLines, ol => ol.id === this.state.customRugToViewEdit.id);
				}
				this.setState({
					orderLines: orderLines,
					colorOptOut: false,
					customRugToViewEdit: updatedCustomRugToViewEdit
				}, () => {
					this.getOrderTotals(response.data, this.state.orderPayments, this.state.orderFulfillments);
				})
			})
			.catch(helpers.catchHandler);
	}

	getOrderPayments(orderId) {
		api.fetch(`Order/GetOrderPayments/${orderId}`)
			.then(response => {
				const orderPayments = this.formatSavedOrderPayments(response.data);
				this.setState({
					orderPayments: orderPayments
				}, () => {
					this.getOrderTotals(this.state.orderLines, response.data, this.state.orderFulfillments);
				});
			})
			.catch(helpers.catchHandler);
	}

	getOrderFulfillments(orderId) {
		api.fetch(`Order/GetOrderFulfillments/${orderId}`)
			.then(response => {
				const orderFulfillments = this.formatSavedOrderFulfillments(response.data);
				this.setState({
					orderFulfillments: orderFulfillments
				}, () => {
					this.getOrderTotals(this.state.orderLines, this.state.orderPayments, response.data, () => this.getCustomer(this.state.order.customer.value));
				});
			})
			.catch(helpers.catchHandler);
	}

	async getOrderTotals(orderLines, orderPayments, orderFulfillments, getCustomerCallback = null) {
		const tempOrderLines = _.map(orderLines, orderLine => {
			return {
				cost: Number(orderLine.cost),
				price: Number(orderLine.price),
				cmsn: Number(orderLine.cmsn),
				deactivatedAt: orderLine.deactivatedAt,
				orderLineTypeId: orderLine.orderLineTypeId,
				orderLineTypeName: orderLine.orderLineTypeName
			}
		});

		const tempOrderPayments = _.map(orderPayments, orderPayment => {
			return {
				amountPaid: Number(orderPayment.amountPaid),
				deactivatedAt: orderPayment.deactivatedAt,
				paymentTypeId: orderPayment.paymentTypeId
			}
		});
		const tempOrderFulfillments = _.map(orderFulfillments, orderFulfillment => {
			return {
				amountPosted: Number(orderFulfillment.amountPosted),
				amountDue: Number(orderFulfillment.amountDue),
				balance: Number(orderFulfillment.balance),
				deactivatedAt: orderFulfillment.deactivatedAt
			}
		});
		const payload = {
			orderLines: tempOrderLines,
			orderPayments: tempOrderPayments,
			orderFulfillments: tempOrderFulfillments
		}
		await api.post("Order/GetOrderTotals", payload)
			.then(response => {
				this.setState({
					totalOrderCost: response.data.totalOrderCostDisplay,
					totalOrderPrice: response.data.totalOrderPriceDisplay,
					// totalPaidCmsn: _.round(response.data.totalPaidCmsn, 2).toFixed(2),
					estimatedGrossProfit: response.data.estimatedGrossProfitDisplay,
					estimatedNetProfit: response.data.estimatedNetProfitDisplay,
					sumOfPayments: response.data.sumOfPaymentsDisplay,
					balance: response.data.balanceDisplay,
					totalWithoutCreditCardSurcharge: _.round(response.data.totalWithoutCreditCardSurcharge, 2).toFixed(2),
					sumOfTaxableItems: _.round(response.data.sumOfTaxableItems, 2).toFixed(2),
					orderFulfillmentsTotalDue: response.data.orderFulfillmentsTotalDueDisplay,
					orderFulfillmentsTotalPosted: response.data.orderFulfillmentsTotalPostedDisplay,
					orderFulfillmentsBalance: response.data.orderFulfillmentsBalanceDisplay,
				}, () => {
					if(getCustomerCallback && typeof getCustomerCallback === "function") {
						getCustomerCallback();
					}
				});
			})
			.catch(helpers.catchHandler);
	}

	formatOrderLineTypeList(orderLineTypeList) {
		const formattedOrderLineTypeList = _.reject(orderLineTypeList, item => {
			return item.value === constants.ORDER_LINE_TYPE.FREIGHT.value
				|| item.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value
				|| item.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value
				|| item.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value
		});
		return formattedOrderLineTypeList;
	}

	formatSavedOrder(order) {
		let customer = helpers.resolveValue(null, order.customerId, order.customerName);
		customer.number = order.customerNumber;
		return {
			id: order.id,
			orderNumber: order.orderNumber,
			orderStatus: helpers.resolveValue(
				null,
				order.orderStatusId,
				order.orderStatusName),
			mth: date_helpers.dateFormatForClient(order.mth),
			createdAt: date_helpers.dateFormatForClient(order.createdAt),
			createdByUser: helpers.resolveValue(
				null,
				order.createdByUserId,
				order.createdByUserName),
			// updatedByUser: helpers.resolveValue(
			// 		null,
			// 		order.updatedByUserId,
			// 		order.updatedByUserName),
			placedBy: order.placedBy,
			customer: customer,
			agent: helpers.resolveValue(
				null,
				order.agentId,
				order.agentName),
			currentAgentCmsnRate: order.currentAgentCmsnRate,  // read only
			isDistinctiveOrder: order.isDistinctiveOrder ? true : false,
			isReplacementOrder: order.isReplacementOrder ? true : false,
			isCreditOrder: order.isCreditOrder ? true : false,
			referencesOrder: helpers.nullableString(order.referencesOrderId),
			relatedReplacementOrders: helpers.nullableString(order.relatedReplacementOrders),
			btContactFirstName: helpers.nullableString(order.btContactFirstName),
			btContactLastName: helpers.nullableString(order.btContactLastName),
			btContactEmail: helpers.nullableString(order.btContactEmail),
			btAddressLine1: helpers.nullableString(order.btAddressLine1),
			btAddressLine2: helpers.nullableString(order.btAddressLine2),
			btAddressLine3: helpers.nullableString(order.btAddressLine3),
			btAddressLine4: helpers.nullableString(order.btAddressLine4),
			btCity: helpers.nullableString(order.btCity),
			btState: helpers.nullableString(order.btState),
			btZip: helpers.nullableString(order.btZip),
			btPhone: helpers.nullableString(order.btPhone),
			btFax: helpers.nullableString(order.btFax),
			customerPONumber: helpers.nullableString(order.customerPONumber),
			invoiceTerms: helpers.resolveValue(
				null,
				order.invoiceTermsId,
				order.invoiceTermsName)
		};
	}

	formatSavedOrderLines(orderLines) {
		let formattedOrderLines = _.map(orderLines, item => {
			let newShape = {
				id: item.id,
				rugShape: helpers.resolveValue(
					null,
					item.rugShapeId,
					item.rugShapeName),
				parentOrderLineId: item.parentOrderLineId,
				orderId: item.orderId,
				orderLineType: helpers.resolveValue(
					null,
					item.orderLineTypeId,
					item.orderLineTypeName),
				dsc: item.dsc,
				cost: _.round(item.cost, 2).toFixed(2),
				price: _.round(item.price, 2).toFixed(2),
				cmsnRate: _.round(item.cmsnRate, 2).toFixed(2),
				cmsn: _.round(item.cmsn, 2).toFixed(2),
				creditCardSurchargeRate: item.creditCardSurchargeRate !== null ? _.round(item.creditCardSurchargeRate, 2) : "",
				taxRate: _.round(item.taxRate, 2).toFixed(2),
				isHidden: item.isHidden,
				product: helpers.resolveValue(
					null,
					item.productId,
					`${item.productDCName} ${item.productDCNumber ? item.productDCNumber : null}`
					// item.productDCName
				),
				productColor: helpers.resolveValue(
					null,
					item.productColorId,
					`${item.productColorName} ${item.productColorNumber ? item.productColorNumber : null}`
					// item.productColorName
				),
				width: item.widthId
					? {
						value: item.widthId,
						label: item.widthName,
						widthNumericFeet: item.widthNumericFeet
					}
					: item.widthFeet && { value: 0, label: item.widthFeet, widthNumericFeet: item.widthFeet },
				unitCost: _.round(item.unitCost, 2).toFixed(2),
				unitPrice: _.round(item.unitPrice, 2).toFixed(2),
				// lengthFeet: item.lengthFeet !== null ? item.lengthFeet.toString() : "",
				lengthFeet: item.lengthFeet !== null ? _.round(item.lengthFeet, 2).toFixed(2) : "",
				orderLineStatus: helpers.resolveValue(
					null,
					item.orderLineStatusId,
					item.orderLineStatusName),
				priceUnitType: helpers.resolveValue(
					null,
					item.priceUnitTypeId,
					item.priceUnitTypeName),
				qty: item.qty !== null ? item.qty.toString() : "",
				cartonCount: helpers.nullableString(item.cartonCount),
				squareFeetPerCarton: helpers.nullableString(item.productSquareFeetPerCarton),  // retrieved from product info, but not currently saved in orderLine table
				perContainer: helpers.nullableString(item.productPerContainer),  // retrieved from product info, but not currently saved in orderLine table
				squareFeetRequired: item.squareFeetRequired,
				freightCarrier: helpers.resolveValue(
					null,
					item.freightCarrierId,
					item.freightCarrierName),
				freightSidemark: helpers.nullableString(item.freightSidemark),
				freightInvoiceNumber: helpers.nullableString(item.freightInvoiceNumber),
				freightBolNumber: helpers.nullableString(item.freightBolNumber),
				freightTerms: helpers.resolveValue(
					null,
					item.freightTermsId,
					item.freightTermsName),
				freightDsc: item.freightDsc ? item.freightDsc : "Freight Charges",	// used to be: helpers.nullableString(item.freightDsc),
				freightCost: item.freightCost ? _.round(item.freightCost, 2).toFixed(2) : "0",
				freightPrice: item.freightPrice ? _.round(item.freightPrice, 2).toFixed(2) : "0",
				rugSize: item.rugSize,
				finishedSize: item.finishedSize ? _.find(FINISHED_RUG_SIZES, sz => sz.label == item.finishedSize) || {value: 1, label: "Custom"} : {value: 1, label: "Custom"},
				repeatDsc: item.repeatDsc && item.repeatDsc != "" ? item.repeatDsc : "0.0\"x0.0\"",
				repeatNumeric: item.repeatNumeric,
				run: item.run,
				borderLetter: helpers.nullableString(item.borderLetter),
				borderWidth: item.borderWidth,
				rugLengthFeet: item.rugLengthFeet != null ? item.rugLengthFeet : "",
				rugLengthInches: item.rugLengthInches != null ? item.rugLengthInches : "",
				rugWidthFeet: item.rugWidthFeet != null ? item.rugWidthFeet : "",
				rugWidthInches: item.rugWidthInches != null ? item.rugWidthInches : "",
				rugType: helpers.resolveValue(
					null,
					item.rugTypeId,
					item.rugTypeName),
				rugIntlCollection: helpers.resolveValue(
					null,
					item.rugIntlCollectionId,
					item.rugIntlCollectionName),
				rugIntlSize: helpers.resolveValue(
					null,
					item.rugIntlSizeId,
					item.rugIntlSizeDsc),
				stContactFirstName: helpers.nullableString(item.stContactFirstName),
				stContactLastName: helpers.nullableString(item.stContactLastName),
				stContactEmail: helpers.nullableString(item.stContactEmail),
				stAddressLine1: helpers.nullableString(item.stAddressLine1),
				stAddressLine2: helpers.nullableString(item.stAddressLine2),
				stAddressLine3: helpers.nullableString(item.stAddressLine3),
				stAddressLine4: helpers.nullableString(item.stAddressLine4),
				stCity: helpers.nullableString(item.stCity),
				stState: helpers.nullableString(item.stState),
				stZip: helpers.nullableString(item.stZip),
				stPhone: helpers.nullableString(item.stPhone),
				rugStyle: item.rugStyle,
				finishedEdge: item.finishedEdge,
				laborCost: item.laborCost,
				totalAgreedCharges: item.totalAgreedCharges,
				forOutdoorUse: item.forOutdoorUse,
				deactivatedAt: item.deactivatedAt,
				orderLineComments: _.map(item.orderLineComments, comment => {
					return {
						...comment,
						//createdAt: "asdf", createdAt is whats rendered in line item comments not updated
						local_id: -1 * (item.orderLineComments.indexOf(comment) + 1),
						commentType: helpers.resolveValue(null, comment.commentTypeId, comment.commentTypeName)
					}
				}),
				commentCount: item.orderLineComments ? item.orderLineComments.length : 0  // for temporary use only
			}
			if (item.orderLineTypeId === constants.ORDER_LINE_TYPE.RUG_FIELD.value) {
				newShape.fieldLengthFeet = newShape.rugLengthFeet.toString();
				newShape.fieldLengthInches = newShape.rugLengthInches.toString();
			}
			return newShape;
		});

		// same function updated to take into account freight lines that have a rug field as a parent order line // still testing
		// rearrange orderlines to take into account custom rug orderlines with component orderlines
		let rugOrderLines = _.filter(formattedOrderLines, function (orderLine) {
			return orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
		});

		rugOrderLines = _.map(rugOrderLines, function (rugOrderLine) {
			let componentLines = _.filter(formattedOrderLines, function (orderLine) {
				let parentOrderLine = null;
				if (orderLine.parentOrderLineId) {
					parentOrderLine = _.find(formattedOrderLines, fol => fol.id === orderLine.parentOrderLineId);
				}
				return orderLine.parentOrderLineId === rugOrderLine.id
					|| (orderLine.orderLineType
						&& orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.FREIGHT.value
						&& parentOrderLine
						&& parentOrderLine.orderLineType
						&& parentOrderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value
						&& parentOrderLine.parentOrderLineId === rugOrderLine.id)
			});
			_.forEach(componentLines, function (componentLine) {   // remove componentLines from formattedOrderLines
				formattedOrderLines = _.reject(formattedOrderLines, function (orderLine) {
					return orderLine.id === componentLine.id
				})
			});
			return {
				...rugOrderLine,
				componentLines: componentLines
			}
		});
		_.forEach(rugOrderLines, function (rugOrderLine) {
			formattedOrderLines = _.map(formattedOrderLines, function (orderLine) {
				if (orderLine.id === rugOrderLine.id) {
					return rugOrderLine
				}
				else {
					return orderLine
				}
			});
		});

		formattedOrderLines = _.map(formattedOrderLines, line => {
			if (line.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value) {
				// let list = _.filter(formattedOrderLines, l => { return l.parentOrderLineId === line.id })
				line.price = _.round(_.sumBy(line.componentLines, p => { return parseFloat(p.price); }), 2).toFixed(2);
				line.cost = _.round(_.sumBy(line.componentLines, c => { return parseFloat(c.cost); }), 2).toFixed(2);
			}
			return line;
		})

		return formattedOrderLines;
	}

	formatSavedOrderPayments(orderPayments) {
		const formattedOrderPayments = _.map(orderPayments, item => {
			return {
				id: item.id,
				orderId: item.orderId,
				customerPaymentStatus: helpers.resolveValue(
					null,
					item.customerPaymentStatusId,
					item.customerPaymentStatusName),
				paymentType: helpers.resolveValue(
					null,
					item.paymentTypeId,
					item.paymentTypeName),
				referenceNumber: helpers.nullableString(item.referenceNumber),
				payToName: helpers.nullableString(item.payToName),
				checkNumber: helpers.nullableString(item.checkNumber),
				// amountDue: item.amountDue,
				amountPaid: _.round(item.amountPaid, 2).toFixed(2),
				// balance: item.balance,
				forWhat: helpers.nullableString(item.forWhat),
				invoiceNumber: helpers.nullableString(item.invoiceNumber),
				dateReceived: date_helpers.dateFormatForClient(item.dateReceived),
				expDate: item.expDate,
				cardNumber: helpers.nullableString(item.cardNumber),
				billingName: helpers.nullableString(item.billingName),
				billingZip: helpers.nullableString(item.billingZip),
				comment: helpers.nullableString(item.comment),
				creditCardType: helpers.resolveValue(
					null,
					item.creditCardTypeId,
					item.creditCardTypeName),
				createdAt: item.createdAt,
				createdByUserId: item.createdByUserId,
				deactivatedAt: item.deactivatedAt,
				deactivatedByUserId: item.deactivatedByUserId
			}
		});
		return formattedOrderPayments
	}

	formatSavedOrderFulfillments(orderFulfillments) {
		const formattedOrderFulfillments = _.map(orderFulfillments, item => {
			return {
				...item,
				amountDue: _.round(item.amountDue, 2).toFixed(2),
				amountPosted: _.round(item.amountPosted, 2).toFixed(2),
				orderFulfillmentOrderLines: _.map(item.orderFulfillmentOrderLines, ol => {
					return {
						...ol,
						orderLineCost: _.round(ol.orderLineCost, 2).toFixed(2)
					}
				}),
				orderFulfillmentStatus: helpers.resolveValue(
					null,
					item.orderFulfillmentStatusId,
					item.orderFulfillmentStatusName),
				supplier: helpers.resolveValue(
					null,
					item.supplierId,
					item.supplierName),
				freightCarrier: helpers.resolveValue(
					null,
					item.freightCarrierId,
					item.freightCarrierName),
				referenceNumber: helpers.nullableString(item.referenceNumber),
				invoiceNumber: helpers.nullableString(item.invoiceNumber),
				checkNumber: helpers.nullableString(item.checkNumber),
				notes: helpers.nullableString(item.notes),
				billDate: date_helpers.dateFormatForClient(item.billDate),
				dueDate: date_helpers.dateFormatForClient(item.dueDate),
				payDate: date_helpers.dateFormatForClient(item.payDate)
			}
		});
		return formattedOrderFulfillments
	}

	//General Functions:
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState(
				{ activeTab: tab }
				, () => {
					if (tab === 6) {  // Invoice Print
						this.getOverallStatus();
					}

				});
		}
	}

	onClickCancelButton() {
		this.setState({
			showCancelOrderModal: true
		});
	}

	yesCancelOrder() {
		this.setState({
			redirectToOrders: true
		});
	}

	noCancelOrder() {
		this.setState({
			showCancelOrderModal: false
		});
	}

	//Order Entry General Information:
	handleOrderStatusSelectChange(selection) {
		this.setState({
			order: {
				...this.state.order,
				orderStatus: selection
			}
		});
	}

	handleCreatedAtInputChange(date) {
		this.setState({
			order: {
				...this.state.order,
				createdAt: date
			}
		});
	}

	setMonth(date) {
		this.setState(prevState => ({
			...prevState,
			order: {
				...prevState.order,
				mth: date
			}
		}));
	}

	handleTakenBySelectChange(selection) {
		this.setState({
			order: {
				...this.state.order,
				createdByUser: selection
				// updatedByUser: selection
			}
		});
	}

	handleOnPlacedByInputChange(e) {
		this.setState({
			order: {
				...this.state.order,
				placedBy: e.target.value
			}
		});
	}

	onToggleCustomerSearchModal() {
		this.setState({ showCustomerSearchModal: !this.state.showCustomerSearchModal })
	}

	onCustomerSelectedFromSearchModal(customerId, customerName) {
		this.setState({
			showCustomerSearchModal: false,
		}, () => this.handleCustomerSelectChange(helpers.resolveValue(null, customerId, customerName)));
	}

	handleCustomerSelectChange(selection) {
		api.fetch(`Customer/GetActiveContacts/${selection.value}`)
			.then((response) => {
				let customerInfo = response.data.customer;
				let customerContacts = response.data.contacts;
				let customerAddresses = _.chain(response.data.customerAddresses)
					.filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.BILL_TO})
					.map(a => {
					a.value = a.id;
					a.label = helpers.formatAddressLabel(a)
					return a;
				}).value();
				const customerShipToAddresses = _.chain(response.data.customerAddresses)
				.filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.SHIP_TO })
				.map(a => {
					a.value = a.id;
					a.label = helpers.formatAddressLabel(a, '* ')
					return a;
				}).value();
				customerContacts.map(c => {
					// remove deactivated phones and addresses for each contact
					const addresses = _.filter(c.addresses, a => a.deactivatedAt === null);
					const phoneNumbers = _.filter(c.phoneNumbers, p => p.deactivatedAt === null);
					c.expanded = false;
					c.addresses = addresses;
					c.phoneNumbers = phoneNumbers;

					// set value and label for select
					c.label = `${c.firstName} ${c.lastName}`;
					c.value = c.id;
					return c;
				});
				let customerCredits = _.map(response.data.customerCredits, c => {
					c.value = c.id;
					c.label = `${c.displayAmount} ${c.comment} - Given on ${moment(c.givenOn).format("MM/DD/YYYY")}`;
					// c.amount = c.amount !== 0 && c.amount !== '' ? c.amount / 100 : null;
					c.amount = c.amount;
					return c;
				});

				this.setState({
					customerInfo: customerInfo,
					customerContacts: customerContacts,
					customerOutstandingCredits: customerCredits,
					customerAddressList: customerAddresses,
					customerShipToAddresses: customerShipToAddresses,
					order: {
						...this.state.order,
						customer: selection,
						agent: (customerInfo.agentId && customerInfo.agentName) ? helpers.resolveValue(null, customerInfo.agentId, customerInfo.agentName) : null,
						btAddressLine1: null,
						btAddressLine2: null,
						btAddressLine3: null,
						btAddressLine4: null,
						btCity: null,
						btState: null,
						btZip: null
					},
					selectedBillingAddress: null
				}, () => {
					if(customerAddresses.length == 1) {
						this.setSelectedBillingAddress(customerAddresses[0])
					}
				});
			})
			.catch(helpers.catchHandler);
	}

	handleAgentSelectChange(selection) {
		this.setState({
			order: {
				...this.state.order,
				agent: selection
			}
		});
	}

	onReferencesOrderChange(e) {
		this.setState({
			order: {
				...this.state.order,
				referencesOrder: e.target.value
			}
		});
	}

	handleOrderInputChange(fieldName, value) {
		this.setState(prevState => ({
			...prevState,
			order: {
				...prevState.order,
				[fieldName]: value
			}
		}));
	}

	isGeneralInfoFormValid() {
		const warnings = [];
		if (!this.state.order.orderStatus.value) {
			warnings.push("Order Status is required.");
		}
		if (!this.state.order.mth) {
			warnings.push("Order Month is required.");
		}
		// if (!this.state.order.orderYear.value) {
		//     warnings.push("Order Year is required.");
		// }
		if (!this.state.order.createdAt) {
			warnings.push("Created at is required.");
		}
		{/* Tracy wanted the name of this field changed to Sold To */ }
		if (!this.state.order.placedBy) {
			warnings.push("Order Sold To is required.");
		}
		if (!this.state.order.createdByUser.value || !this.state.order.createdByUser.label) {
			warnings.push("Order Entered By is required.");
		}
		// if (!this.state.order.updatedByUser.value || !this.state.order.updatedByUser.label) {
		// 	warnings.push("Order Entered By is required.");
		// }
		if (!this.state.order.customer || !this.state.order.customer.value) {
			warnings.push("Account is required.");
		}
		if (!this.state.order.agent || !this.state.order.agent.value) {
			warnings.push("Agent is required.");
		}
		if (!this.state.order.referencesOrder &&
			(this.state.order.isReplacementOrder || this.state.order.isCreditOrder)) {
			warnings.push("This is a Replacement or Credit order: References Order is required. ");
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	saveOrderEntryGeneralInfo() {
		if (!this.isGeneralInfoFormValid()) return;
		const order = this.state.order;
		const data = {
			Id: order.id ? order.id : 0,
			CustomerId: order.customer.value,
			AgentId: order.agent.value,
			OrderStatusId: order.orderStatus.value,
			PlacedBy: order.placedBy,
			CreatedAt: order.createdAt,
			CreatedByUserId: order.createdByUser.value,	// Entered By field
			// UpdatedByUserId: order.updatedByUser.value,	// Entered By field
			Mth: date_helpers.formatDateForServer(order.mth),
			IsReplacementOrder: order.isReplacementOrder,
			IsDistinctiveOrder: order.isDistinctiveOrder,
			IsCreditOrder: order.isCreditOrder,
			ReferencesOrderId: order.referencesOrder ? Number(order.referencesOrder) : null,
			CustomerPONumber: order.customerPONumber,
			InvoiceTermsId: order.id && order.invoiceTerms ? order.invoiceTerms.value : this.state.customerInfo.invoiceTermsId,   // if new order, get invoiceTerms from customer
			BTContactFirstName: order.btContactFirstName,
      BTContactLastName: order.btContactLastName,
      BTContactEmail: order.btContactEmail,
      BTAddressLine1: order.btAddressLine1,
      BTAddressLine2: order.btAddressLine2,
      BTAddressLine3: order.btAddressLine3,
      BTAddressLine4: order.btAddressLine4,
      BTCity: order.btCity,
      BTState: order.btState,
      BTZip: order.btZip
		};
		api.post('Order/SaveOrderGeneralInformation', data)
			.then(response => {
				this.setState({
					order: this.formatSavedOrder(response.data),
					orderId: response.data.id,
					setGeneralOrderSavedFlag: true,
					activeTab: TABS.LINE_ITEMS,
					message: null,
					messageFlavor: null
				});
			})
			.catch(helpers.catchHandler);
	}

	// Order Line Entry:

	getOrderLineCorrespondingProductType(value) {
		let productType;
		switch (value.toString()) {
			case constants.ORDER_LINE_TYPE.CARPET.value.toString():
			case constants.ORDER_LINE_TYPE.RUG_FIELD.value.toString():
			case constants.ORDER_LINE_TYPE.RUG_BORDER.value.toString():
				productType = constants.PRODUCT_TYPE.CARPET.value;
				break;
			case constants.ORDER_LINE_TYPE.MISCELLANEOUS.value.toString():
				productType = constants.PRODUCT_TYPE.MISCELLANEOUS.value;
				break;
			case constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value.toString():
				productType = constants.PRODUCT_TYPE.RUG_COMPONENT.value;
				break;
			case constants.ORDER_LINE_TYPE.RUG_PAD.value.toString():
				productType = constants.PRODUCT_TYPE.RUG_PAD.value;
				break;
			case constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value.toString():
				productType = constants.PRODUCT_TYPE.INTL_RUG.value;
				break;
			case constants.ORDER_LINE_TYPE.HARD_SURFACE.value.toString():
				productType = constants.PRODUCT_TYPE.HARD_SURFACE.value;
				break;
			case constants.ORDER_LINE_TYPE.ROLL_RUNNER.value.toString():
				productType = constants.PRODUCT_TYPE.ROLL_RUNNER.value;
				break;
			case constants.ORDER_LINE_TYPE.BINDING.value.toString():
				productType = constants.PRODUCT_TYPE.BORDER_OR_BINDING.value;
				break;
			case constants.ORDER_LINE_TYPE.CARPET_TILE.value.toString():
				productType = constants.PRODUCT_TYPE.CARPET_TILE.value;
				break;
			case constants.ORDER_LINE_TYPE.SAMPLE.value.toString():
				productType = constants.PRODUCT_TYPE.SAMPLE.value;
				break;
		};
		return productType;
	}

	onAddNewOrderLine(orderLineType = null) {
		let productType;
		if (orderLineType) {
			productType = this.getOrderLineCorrespondingProductType(orderLineType.value);
		} else {
			productType = constants.PRODUCT_TYPE.CARPET.value;
		}

		// Fetch products for default new rug component line type first
		// api.fetch(`Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.CARPET.value}`)
		api.fetch(`Order/GetProductsOfProductType/${productType}`)
			.then(response => {
				//if response success
				if (this.state.customRugToViewEdit) {
					let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
						this.state.customRugToViewEdit.rugLengthFeet,
						this.state.customRugToViewEdit.rugLengthInches,
						this.state.customRugToViewEdit.rugWidthFeet,
						this.state.customRugToViewEdit.rugWidthInches);

					this.setState({
						showOrderLineForm: true,
						message: null,
						messageFlavor: null,
						productsList: response.data,
						orderLine: {
							...emptyOrderLine,
							cmsnRate: this.state.order.currentAgentCmsnRate,
							fieldCmsnRate: this.state.order.currentAgentCmsnRate,
							borderCmsnRate: this.state.order.currentAgentCmsnRate,
							parentOrderLineId: this.state.customRugToViewEdit.id,
							orderLineType: orderLineType ? orderLineType : constants.ORDER_LINE_TYPE.RUG_FIELD,
							sqFt: sqFt,
							finishedEdgeFt: finishedEdgeFt,
							rugLengthFeet: this.state.customRugToViewEdit.rugLengthFeet,			// needed for local fuctions to work
							rugLengthInches: this.state.customRugToViewEdit.rugLengthInches,  // needed for local fuctions to work
							rugWidthFeet: this.state.customRugToViewEdit.rugWidthFeet,				// needed for local fuctions to work
							rugWidthInches: this.state.customRugToViewEdit.rugWidthInches, 		// needed for local fuctions to work
							rugDsc: this.state.customRugToViewEdit.dsc,												// needed for local fuctions to work
							shippingAddresses: this.state.customerShipToAddresses
						}
					});
				} else {
					this.setState({
						showOrderLineForm: true,
						message: null,
						messageFlavor: null,
						productsList: response.data,
						orderLine: {
							...emptyOrderLine,
							cmsnRate: this.state.order.currentAgentCmsnRate,
							fieldCmsnRate: this.state.order.currentAgentCmsnRate,
							borderCmsnRate: this.state.order.currentAgentCmsnRate,
							orderLineType: constants.ORDER_LINE_TYPE.CARPET,
							shippingAddresses: this.state.customerShipToAddresses
						}
					});
				}

			});
	}

	async handleOrderLineTypeChange(selection) {
		if (selection.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value || selection.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value) {
			this.onAddNewOrderLine(selection);
		} else if (selection.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value) {
			let padProductsList;
			let rugBindingList;
			let inboundFreightProductList;
			let customRugComponentProducts;
			await api.fetch(`Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.RUG_PAD.value}`)
				.then(response => {
					padProductsList = response.data;
				});
			await api.fetch(`Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.BORDER_OR_BINDING.value}`)
				.then(response => {
					rugBindingList = response.data;
				});
			await api.fetch(`Order/GetProductsOfProductType/${constants.PRODUCT_TYPE.RUG_COMPONENT.value}`)
				.then(response => {
					inboundFreightProductList = _.filter(response.data, p => _.includes(p.label, 'Inbound'))
				});
			await api.fetch(`Order/GetCustomRugComponentProducts/`)
				.then(response => {
					customRugComponentProducts = {
						DefaultCutChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultCutChargeProduct),
						DefaultRugEndArmorProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultRugEndArmorProduct),
						DefaultInboundFreightProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultInboundFreightProduct),
						DefaultBackingProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultBackingProduct),
						DefaultPadCutChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultPadCutChargeProduct),
						Under30SFPadCutChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.Under30SFPadCutChargeProduct),
						DefaultClippedCornersProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultClippedCornersProduct),
						DefaultLaborCutoutProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultLaborCutoutProduct),
						DefaultLaborRoundProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultLaborRoundProduct),
						DefaultFabricationChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultFabricationChargeProduct),
						DefaultUVSergingChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultUVSergingChargeProduct),
						DefaultWoolSergingChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultWoolSergingChargeProduct),
						DefaultPremiumWoolSergingChargeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultPremiumWoolSergingChargeProduct),
						DefaultWoolSergingChargeNProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultWoolSergingChargeNProduct),
						DefaultAppliedMachineBordersProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultAppliedMachineBordersProduct),
						DefaultMiteredProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultMiteredProduct),
						DefaultRugPadLuxFeltCustomSizeProduct: _.find(response.data, x => x.configName === constants.CUSTOM_RUG_CONFIG_NAMES.DefaultRugPadLuxFeltCustomSizeProduct)
					}
				});
			const emptyRugLine = emptyRugOrderLine(customRugComponentProducts, this.state.order.currentAgentCmsnRate);
			const sergingList = [
				customRugComponentProducts.DefaultWoolSergingChargeProduct,
				customRugComponentProducts.DefaultPremiumWoolSergingChargeProduct,
				customRugComponentProducts.DefaultWoolSergingChargeNProduct,
				customRugComponentProducts.DefaultUVSergingChargeProduct
			];
			const mSergingList = [customRugComponentProducts.DefaultAppliedMachineBordersProduct];
			const hsSergingList = [customRugComponentProducts.DefaultMiteredProduct];
			this.setState({
				orderLine: {
					...emptyRugLine,
					cmsnRate: this.state.order.currentAgentCmsnRate,
					orderLineType: selection,
					id: this.state.orderLine.id,
					//freight:
					freightCarrier: this.state.orderLine.freightCarrier,
					freightSidemark: this.state.orderLine.freightSidemark,
					freightInvoiceNumber: this.state.orderLine.freightInvoiceNumber,
					freightBolNumber: this.state.orderLine.freightBolNumber,
					freightDsc: this.state.orderLine.freightDsc,
					freightTerms: this.state.orderLine.freightTerms,
					freightCost: this.state.orderLine.freightCost,
					freightPrice: this.state.orderLine.freightPrice,
					stContactFirstName: this.state.orderLine.stContactFirstName,
					stContactLastName: this.state.orderLine.stContactLastName,
					stContactEmail: this.state.orderLine.stContactEmail,
					stAddressLine1: this.state.orderLine.stAddressLine1,
					stAddressLine2: this.state.orderLine.stAddressLine2,
					stAddressLine3: this.state.orderLine.stAddressLine3,
					stAddressLine4: this.state.orderLine.stAddressLine4,
					stCity: this.state.orderLine.stCity,
					stState: this.state.orderLine.stState,
					stZip: this.state.orderLine.stZip,
					stPhone: this.state.orderLine.stPhone
				},
				padProductsList: padProductsList,
				rugBindingList: rugBindingList,
				inboundFreightProductList: inboundFreightProductList,
				customRugComponentProducts: customRugComponentProducts,
				sergingList: sergingList,
				mSergingList: mSergingList,
				hsSergingList: hsSergingList,
			}, () => {
				this.initializeRugOrder();
			});
		} else {
			let productType = this.getOrderLineCorrespondingProductType(selection.value);
			let productsList = [];
			// let collectionList = [];
			// if (selection.value !== constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
				await api.fetch(`Order/GetProductsOfProductType/${productType}`)
					.then(response => {
						productsList = response.data;
					});
			// } else {
			// 	// For Intl Coll rugs, collection must be selected before product
			// 	await api.fetch("Order/GetRugIntlCollectionList")
			// 		.then(response => {
			// 			collectionList = response.data;
			// 		});
			// }

			this.setState({
				orderLine: {
					...emptyOrderLine,
					cmsnRate: this.state.order.currentAgentCmsnRate,
					fieldCmsnRate: this.state.order.currentAgentCmsnRate,
					borderCmsnRate: this.state.order.currentAgentCmsnRate,
					orderLineType: selection,
					id: this.state.orderLine.id,
					//freight:
					freightCarrier: this.state.orderLine.freightCarrier,
					freightSidemark: this.state.orderLine.freightSidemark,
					freightInvoiceNumber: this.state.orderLine.freightInvoiceNumber,
					freightBolNumber: this.state.orderLine.freightBolNumber,
					freightDsc: this.state.orderLine.freightDsc,
					freightTerms: this.state.orderLine.freightTerms,
					freightCost: this.state.orderLine.freightCost,
					freightPrice: this.state.orderLine.freightPrice,
					stContactFirstName: this.state.orderLine.stContactFirstName,
					stContactLastName: this.state.orderLine.stContactLastName,
					stContactEmail: this.state.orderLine.stContactEmail,
					stAddressLine1: this.state.orderLine.stAddressLine1,
					stAddressLine2: this.state.orderLine.stAddressLine2,
					stAddressLine3: this.state.orderLine.stAddressLine3,
					stAddressLine4: this.state.orderLine.stAddressLine4,
					stCity: this.state.orderLine.stCity,
					stState: this.state.orderLine.stState,
					stZip: this.state.orderLine.stZip,
					stPhone: this.state.orderLine.stPhone
				},
				// rugIntlCollectionList: collectionList,
				productsList: productsList
			});
		}
	}

	async setSelectedProduct(option) {
		await api.fetch(`Order/GetActiveProductColors/${option.value}`)
			.then(response => {
				this.setState({
					productColorsList: response.data,
					orderLine: {
						...this.state.orderLine,
						product: option,
						productColor: response.data.length > 0 ? response.data[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		await api.fetch(`Product/GetProductWidths/${option.value}`)
			.then(response => {
				const widths = _.map(response.data, w => {
					return {
						value: w.widthId,
						label: w.widthName,
						widthNumericFeet: w.widthNumericFeet
					}
				});
				this.setState({
					//widthsList: widths,
					orderLine: {
						...this.state.orderLine,
						widthsList: widths,
						width: widths.length > 0 ? widths[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
			await api.fetch(`Product/GetRugIntlSizes/${option.value}`)
				.then(response => {
					const rugSizes = _.map(response.data, sz => {
						return {
							value: sz.id,
							label: sz.dsc,
							cost: _.round(sz.cost, 2).toFixed(2),
							price: _.round(sz.price, 2).toFixed(2)
						}
					});
					this.setState({
						rugIntlSizeList: rugSizes,
						orderLine: {
							...this.state.orderLine,
							rugIntlSize: rugSizes.length > 0 ? rugSizes[0] : null,
							unitCost: rugSizes.length > 0 ? rugSizes[0].cost : this.state.orderLine.unitCost,
							unitPrice: rugSizes.length > 0 ? rugSizes[0].price : this.state.orderLine.unitPrice
							// unitCost: response.data.length > 0 ? _.round(response.data[0].cost, 2).toFixed(2) : this.state.orderLine.unitCost,
							// unitPrice: response.data.length > 0 ? _.round(response.data[0].price, 2).toFixed(2) : this.state.orderLine.unitPrice,
						}
					});
				})
				.catch(helpers.catchHandler);
		}
		console.log(`right here, ${option.value}, ${this.state.activeOrderId}`);
		await api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
		.then(response => {
			this.setState({
					orderLine: {
							...this.state.orderLine,
							unitCost: this.state.orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value ? _.round(response.data.unitCost, 2).toFixed(2) : this.state.orderLine.unitCost,
							unitPrice: this.state.orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value ? _.round(response.data.unitPrice, 2).toFixed(2) : this.state.orderLine.unitPrice,
							volumeCost: _.round(response.data.volumeCost, 2).toFixed(2),			// not saved on orderline, only used for volume calculations
							volumePrice: _.round(response.data.volumePrice, 2).toFixed(2),		// not saved on orderline, only used for volume calculations
							rollSize: response.data.rollSize,  																// not saved on orderline, only used for volume calculations
							priceUnitType: helpers.resolveValue(
									null,
									response.data.priceUnitTypeId,
									response.data.priceUnitTypeName),
							perContainer: helpers.nullableString(response.data.perContainer),
							squareFeetPerCarton: helpers.nullableString(response.data.squareFeetPerCarton),
							productAllowCommission: response.data.allowCommission
					},
					nonVolumeUnitCost: _.round(response.data.unitCost, 2).toFixed(2),
					nonVolumeUnitPrice:  _.round(response.data.unitPrice, 2).toFixed(2)
			}, () => {
					this.calculateFigures()
			});
		})
		.catch(helpers.catchHandler);
	}

	// setSelectedCollection(option) {
	// 	// Fetch products that belong to collection and are INTL_RUG product types
	// 	api.fetch(`Order/GetActiveCollectionProducts/${option.value}`)
	// 		.then(response => {
	// 			this.setState({
	// 				collectionProductsList: response.data,
	// 				orderLine: {
	// 					...this.state.orderLine,
	// 					rugIntlCollection: option,
	// 					product: response.data.length > 0 ? response.data[0] : null
	// 				}
	// 			}, () => {
	// 				if (response.data.length > 0) {
	// 					this.setSelectedProduct(response.data[0])
	// 				}
	// 			});
	// 		})
	// 		.catch(helpers.catchHandler);
	// }

	handleFormSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection
			},
			showOrderLineStatusModal:
				(selection.label === constants.ORDER_LINE_STATUS.BACKORDER.label
				|| selection.label === constants.ORDER_LINE_STATUS.SHIPPED.label)
				? true : false
		}, () => {
			if (elementName === "productColor") {
				this.calculateFigures();
				}
		});
	}
	handleRugShapeSelectChange(elementName, selection) {
		let newRugDsc = this.state.orderLine.rugDsc;
		if(selection.label === constants.CUSTOM_RUG_SHAPE_LIST.ROUND.label){
			newRugDsc = newRugDsc.replace("Custom Rug", "Round Custom Rug")
		}else{
			newRugDsc = newRugDsc.replace( "Round Custom Rug", "Custom Rug")
		} 
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection,
				rugDsc: newRugDsc
			},
			showOrderLineStatusModal:
				(selection.label === constants.ORDER_LINE_STATUS.BACKORDER.label
				|| selection.label === constants.ORDER_LINE_STATUS.SHIPPED.label)
				? true : false
		}, () => {
			if (elementName === "productColor") {
				this.calculateFigures();
				}
		});
	}

	handleWidthSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection
			}
		}, () => {
			this.calculateFigures();
		});
	}

	handleRugIntlSizeChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection,
				unitCost: selection.cost,
				unitPrice: selection.price
			}
		}, () => {
			this.calculateFigures();
		});
	}

	handleOrderLineStateChange(fieldName, value) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[fieldName]: value
			}
		});
	}

	updateOrderLineRateOrCurrencyField(fieldName, value) {
		if (value) {
			this.setState({
				orderLine: {
					...this.state.orderLine,
					[fieldName]: _.round(value, 2).toFixed(2)
				}
			});
		}
	}

	onChangeOrderLineComment(fieldName, value) {
		this.setState({
			newOrderLineComment: {
				...this.state.newOrderLineComment,
				[fieldName]: value
			}
		})
	}

	handleOrderLineCommentSelectionChange(elementName, selection) {
		this.setState({
			newOrderLineComment: {
				...this.state.newOrderLineComment,
				[elementName]: {
					value: selection.value,
					label: selection.label
				}
			}
		});
	}

	isOrderLineCommentValid() {
		const warnings = [];
		if (!this.state.newOrderLineComment.dsc) {
			warnings.push("Comment required in order to add.");
		}
		if (!this.state.newOrderLineComment.commentType) {
			warnings.push("To add comment, please select what comment applies to.");
		}
		if (warnings.length) {
			window.scroll(0, 0);
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	onSaveNote(forOrderLineStatus) {
		const notes = this.state.orderLineCommentList.slice();
		let commentToSave = Object.assign({}, this.state.newOrderLineComment);
		if (forOrderLineStatus) {
			commentToSave = emptyOrderLineComment;
			commentToSave = {
				...commentToSave,
				dsc: `${this.state.orderLine.orderLineStatus.label} on ${moment(this.state.backOrderOrShippedDate).format("M/DD/YY")}`,
				commentType: _.find(this.state.commentTypeList, c => c.label === "Customer"),
				isPrivate: false,
				local_id: -1 * (this.state.orderLine.commentCount + 1)
			}
		} else {
			if (!this.isOrderLineCommentValid()) return;
			commentToSave = {
				...commentToSave,
				local_id: -1 * (this.state.orderLine.commentCount + 1)
			}
		}
		this.setState({
			newOrderLineComment: emptyOrderLineComment,
			orderLineCommentList: [...notes, commentToSave],
			orderLine: {
				...this.state.orderLine,
				commentCount: this.state.orderLine.commentCount + 1
			},
			showOrderLineStatusModal: false,
			backOrderOrShippedDate: null
		});
	}

	onEditComment(commentId) {
		const indexToEdit = _.findIndex(this.state.orderLineCommentList, { local_id: commentId });
		let tempList = _.cloneDeep(this.state.orderLineCommentList);
		const snapshotItem = Object.assign({}, tempList[indexToEdit]);
		tempList[indexToEdit].isEditing = true;
		tempList[indexToEdit].pristine = snapshotItem;
		this.setState({
			orderLineCommentList: tempList
		});
	}

	updateCommentDscField(fieldName, value, commentId) {
		const tempList = this.state.orderLineCommentList.slice();
		const comment = _.find(tempList, x => x.local_id === commentId);
		comment[fieldName] = value;
		this.setState({
			orderLineCommentList: tempList
		});
	}

	updateCommentTypeField(selection, commentId) {
		const tempList = this.state.orderLineCommentList.slice();
		const comment = _.find(tempList, x => x.local_id === commentId);
		comment.commentType = {
			value: selection.value,
			label: selection.label
		}
		this.setState({
			orderLineCommentList: tempList
		});
	}

	updateCommentIsPrivateField(value, commentId) {
		const tempList = this.state.orderLineCommentList.slice();
		const comment = _.find(tempList, x => x.local_id === commentId);
		comment.isPrivate = !comment.isPrivate;
		this.setState({
			orderLineCommentList: tempList
		});
	}

	onSaveEditedComment(commentId) {
		const indexToEdit = _.findIndex(this.state.orderLineCommentList, { local_id: commentId });
		let tempList = this.state.orderLineCommentList.slice();
		tempList[indexToEdit].isEditing = false;
		this.setState({
			orderLineCommentList: tempList,
			message: null,
			messageFlavor: null
		});
	}

	onCancelEditComment(commentId) {
		const indexToEdit = _.findIndex(this.state.orderLineCommentList, { local_id: commentId });
		let tempList = this.state.orderLineCommentList.slice();
		tempList[indexToEdit] = Object.assign({}, tempList[indexToEdit].pristine);
		this.setState({
			orderLineCommentList: tempList,
			message: null,
			messageFlavor: null
		});
	}

	onDeleteComment(commentId) {
		const indexToEdit = _.findIndex(this.state.orderLineCommentList, { local_id: commentId });
		let tempList = _.cloneDeep(this.state.orderLineCommentList);
		tempList[indexToEdit].markedForDeletion = true;
		this.setState({
			orderLineCommentList: tempList
		});
	}

	cancelAddOrUpdateOrderLine() {
		this.setState({
			orderLine: emptyOrderLine, // not updating agent's commission rate if add or update orderline is cancelled
			orderLineCommentList: [],
			showOrderLineForm: false,
			editingFreight: false,
			editingNotes: false,
			message: null,
			messageFlavor: null
		});
	}

	isOrderLineFormValid() {
		const warnings = [];
		if (!this.state.orderLine.orderLineType.value) {
			warnings.push("Line Type is required.");
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value) {
			const alreadyExists = _.find(this.state.orderLines, function (orderLine) {
				return (orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value &&
					orderLine.deactivatedAt === null && orderLine.id)
			})
			if (alreadyExists && alreadyExists.id !== this.state.orderLine.id) {
				warnings.push("Credit Surcharge has already been applied.");
			}
			if (!this.state.orderLine.creditCardSurchargeRate) {
				warnings.push("Credit Card Surcharge rate is required.");
			}
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value) {
			const alreadyExists = _.find(this.state.orderLines, function (orderLine) {
				return (orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value &&
					orderLine.deactivatedAt === null && orderLine.id)
			})
			if (alreadyExists && alreadyExists.id !== this.state.orderLine.id) {
				warnings.push("Sales tax has already been applied.");
			}
			if (!this.state.orderLine.taxRate) {
				warnings.push("Sales tax rate is required.");
			}
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.BINDING.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.HARD_SURFACE.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET_TILE.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_PAD.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.MISCELLANEOUS.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.ROLL_RUNNER.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
			if (!this.state.orderLine.product) {
				warnings.push("Product is required.");
			}
			if (!this.state.orderLine.dsc) {
				warnings.push("Description is required.");
			}
			if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET.value
				|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_PAD.value) {
				if (!this.state.orderLine.width) {
					warnings.push("Width is required.");
				}
			}
			if ((this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET.value && this.state.orderLine.priceUnitType.value !== constants.PRICE_UNIT_TYPE.EACH.value)
				|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.BINDING.value
				|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.ROLL_RUNNER.value
				|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_PAD.value) {
				if (!this.state.orderLine.lengthFeet) {
					warnings.push("Length (ft.) is required.");
				}
			}
			if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.MISCELLANEOUS.value
				|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value) {
				if (!this.state.orderLine.qty) {
					warnings.push("Qty is required.");
				}
			}
			if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.HARD_SURFACE.value ||
				this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET_TILE.value) {
				if (!this.state.orderLine.squareFeetRequired) {
					warnings.push("Sq. Units Required is required.");
				}
				if (!this.state.orderLine.cartonCount) {
					warnings.push("# Items is required.");
				}
			}
			if (!this.state.orderLine.unitCost) {
				warnings.push("Unit Cost is required.");
			}
			if (!this.state.orderLine.cost) {
				warnings.push("Total Cost is required.");
			}
			if (!this.state.orderLine.unitPrice) {
				warnings.push("Unit Price is required.");
			}
			if (!this.state.orderLine.price) {
				warnings.push("Price is required.");
			}
			if (!this.state.orderLine.cmsnRate) {
				warnings.push("Commission Rate is required.");
			}
			if (!this.state.orderLine.cmsn) {
				warnings.push("Commission is required.");
			}
		}
		if (this.state.editingFreight) {
			if (!this.state.orderLine.freightDsc) {
				warnings.push("Freight Description is required.");
			}
			if (!this.state.orderLine.freightCarrier) {
				warnings.push("Freight Carrier is required.");
			}
			// if (!this.state.orderLine.freightTerms) {
			//     warnings.push("Freight Terms is required.");
			// }
			// if (!this.state.orderLine.freightSidemark) {
			//     warnings.push("Freight Sidemark is required.");
			// }
			// if (!this.state.orderLine.freightInvoiceNumber) {
			//     warnings.push("Freight Invoice Number is required.");
			// }
			// if (!this.state.orderLine.freightBolNumber) {
			//     warnings.push("Freigh Bol Number is required.");
			// }
			if (!this.state.orderLine.freightCost) {
				warnings.push("Freight Cost is required.");
			}
			if (!this.state.orderLine.freightPrice) {
				warnings.push("Freight Price is required.");
			}
			// if (!this.state.orderLine.stContactEmail) {
			// 	warnings.push("Ship to email is not valid");
			// }
			// if item is the parent to a deactivated freightOrderLine
			// const childItem = _.find(this.state.orderLines, (x) => {return (x.parentOrderLineId === this.state.orderLine.id && x.deactivatedAt !== null)});
			// if (childItem) {
			//     warnings.push("This order line already has a freight order line associated with it. To edit it, please revive it first.");
			// }
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value) {
				if (!this.state.orderLine.rugDsc) {
					warnings.push("Rug Description is required.");
				}
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value) {
			if (!this.state.orderLine.selectedField) {
				warnings.push("Field Style is required.");
			}
			if (!this.state.orderLine.dsc) {
				warnings.push("Description is required.");
			}
			if (!this.state.orderLine.fieldCostPer) {
				warnings.push("Unit Cost is required.");
			}
			if (!this.state.orderLine.totalFieldCost) {
				warnings.push("Total Cost is required.");
			}
			if (!this.state.orderLine.fieldPricePer) {
				warnings.push("Unit Price is required.");
			}
			if (!this.state.orderLine.totalFieldPrice) {
				warnings.push("Price is required.");
			}
			if (!this.state.orderLine.fieldCmsnRate) {
				warnings.push("Commission Rate is required.");
			}
			if (!this.state.orderLine.fieldCmsn) {
				warnings.push("Commission is required.");
			}
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value) {
			if (!this.state.orderLine.selectedBorder) {
				warnings.push("Border Style is required.");
			}
			if (!this.state.orderLine.dsc) {
				warnings.push("Description is required.");
			}
			if (!this.state.orderLine.borderLengthFeet) {
				warnings.push("Border Length Feet is required.");
			}
			if (!this.state.orderLine.borderLengthInches) {
				warnings.push("Border Length Inches is required.");
			}
			if (!this.state.orderLine.borderCostPer) {
				warnings.push("Unit Cost is required.");
			}
			if (!this.state.orderLine.totalBorderCost) {
				warnings.push("Total Cost is required.");
			}
			if (!this.state.orderLine.borderPricePer) {
				warnings.push("Unit Price is required.");
			}
			if (!this.state.orderLine.totalBorderPrice) {
				warnings.push("Price is required.");
			}
			if (!this.state.orderLine.borderCmsnRate) {
				warnings.push("Commission Rate is required.");
			}
			if (!this.state.orderLine.borderCmsn) {
				warnings.push("Commission is required.");
			}
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	formatOrderLineForDto(orderLine) {
		let formattedOrderLineForDto = {
			Id: orderLine.id,
			OrderId: this.state.orderId,
			ParentOrderLineId: null, // should always be null if not a freight order line?
			OrderLineTypeId: orderLine.orderLineType.value,
			Dsc: orderLine.dsc,
			UnitCost: Number(orderLine.unitCost),
			Cost: Number(orderLine.cost),
			UnitPrice: Number(orderLine.unitPrice),
			Price: Number(orderLine.price),
			CmsnRate: orderLine.productAllowCommission ? Number(orderLine.cmsnRate) : 0,
			Cmsn: orderLine.productAllowCommission ? Number(orderLine.cmsn) : 0,
			IsHidden: orderLine.isHidden,
			ProductId: orderLine.product ? orderLine.product.value : null,
			ProductColorId: orderLine.productColor ? orderLine.productColor.value : null,
			WidthId: orderLine.width ? orderLine.width.value : null,
			LengthFeet: orderLine.lengthFeet || orderLine.lengthFeet === 0 ? Number(orderLine.lengthFeet) : null,
			OrderLineStatusId: orderLine.orderLineStatus ? orderLine.orderLineStatus.value : constants.ORDER_LINE_STATUS.NA.value,
			PriceUnitTypeId: orderLine.priceUnitType ? orderLine.priceUnitType.value : null,
			SquareFeetRequired: orderLine.squareFeetRequired ? Number(orderLine.squareFeetRequired) : null,
			RugSize: orderLine.rugSize,
			FinishedSize: orderLine.finishedSize.label,
			RugLengthFeet: orderLine.RugLengthFeet ? Number(orderLine.rugLengthFeet) : null,
			RugLengthInches: orderLine.RugLengthInches ? Number(orderLine.rugLengthInches) : null,
			RugWidthFeet: orderLine.RugWidthFeet ? Number(orderLine.rugWidthFeet) : null,
			RugWidthInches: orderLine.RugWidthInches ? Number(orderLine.rugWidthInches) : null,
			RugTypeId: orderLine.rugType ? orderLine.rugType.value : null,
			RugIntlCollectionId: orderLine.rugIntlCollection ? orderLine.rugIntlCollection.value : null,
			RugIntlSizeId: orderLine.rugIntlSize ? orderLine.rugIntlSize.value : null,
			CreatedAt: moment(),
			CreatedByUserId: this.props.currentUser.id,
			CreditCardSurchargeRate: orderLine.CreditCardSurchargeRate ? Number(orderLine.creditCardSurchargeRate) : null,
			TaxRate: Number(orderLine.taxRate),
			Qty: orderLine.qty ? Number(orderLine.qty) : null,
			cartonCount: orderLine.cartonCount ? Number(orderLine.cartonCount) : null
		};
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value) {
			let surchargePrice = parseFloat(this.state.totalWithoutCreditCardSurcharge) * (parseFloat(orderLine.creditCardSurchargeRate) / 100.0);
			formattedOrderLineForDto = {
				...formattedOrderLineForDto,
				UnitCost: 0,
				Cost: 0,
				UnitPrice: 0,
				Price: Number(surchargePrice), //Number(this.state.orderLine.price), 114.0675
				CmsnRate: 0,
				Cmsn: 0,
				TaxRate: 0,
				CreditCardSurchargeRate: Number(orderLine.creditCardSurchargeRate),
				Dsc: 'Credit Card Surcharge'
			}
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value) {
			let taxRatePrice = (parseFloat(orderLine.taxRate) / 100.0) * parseFloat(this.state.sumOfTaxableItems);
			formattedOrderLineForDto = {
				...formattedOrderLineForDto,
				UnitCost: 0,
				Cost: Number(taxRatePrice), //0,
				UnitPrice: 0,
				Price: Number(taxRatePrice), //Number(this.state.orderLine.price),
				CmsnRate: 0,
				Cmsn: 0,
				TaxRate: Number(orderLine.taxRate),
				CreditCardSurchargeRate: 0,
				Dsc: 'Sales Tax'
			}
		}
		return formattedOrderLineForDto;
	}

	formatFreightOrderLineForDto(orderLine) {
		const formattedOrderLineForDto = {
			Id: orderLine.freightOrderLineId,
			OrderId: this.state.orderId,
			ParentOrderLineId: orderLine.parentOrderLineId
				? COMPONENT_ORDER_LINE_TYPES.includes(orderLine.orderLineType.value)
					? orderLine.id
					: orderLine.parentOrderLineId
				: null, // null if parent order line being created at same time
			OrderLineTypeId: constants.ORDER_LINE_TYPE.FREIGHT.value,
			Dsc: orderLine.freightDsc,
			UnitCost: 0,
			Cost: Number(orderLine.freightCost),
			UnitPrice: 0,
			Price: Number(orderLine.freightPrice),
			CmsnRate: 0,
			Cmsn: 0,
			IsHidden: orderLine.isHidden,
			CreatedAt: moment(),
			CreatedByUserId: this.props.currentUser.id,
			TaxRate: Number(orderLine.taxRate),
			FreightCarrierId: orderLine.freightCarrier ? orderLine.freightCarrier.value : null,
			FreightSidemark: orderLine.freightSidemark,
			FreightInvoiceNumber: orderLine.freightInvoiceNumber,
			FreightBolNumber: orderLine.freightBolNumber,
			FreightTermsId: orderLine.freightTerms ? orderLine.freightTerms.value : null,
			STContactFirstName: orderLine.stContactFirstName,
			STContactLastName: orderLine.stContactLastName,
			STContactEmail: orderLine.stContactEmail,
			STAddressLine1: orderLine.stAddressLine1,
			STAddressLine2: orderLine.stAddressLine2,
			STAddressLine3: orderLine.stAddressLine3,
			STAddressLine4: orderLine.stAddressLine4,
			STCity: orderLine.stCity,
			STState: orderLine.stState,
			STZip: orderLine.stZip,
			STPhone: orderLine.stPhone
		}
		return formattedOrderLineForDto;
	}

	formatOrderLineCommentsForDto(orderLineComments, orderLineId) {
		const orderLineCommentList = [];
		_.map(orderLineComments,
			x => {
				const comment = {
					Id: x.id,
					Dsc: x.dsc,
					OrderLineId: orderLineId,
					TypeOfComment: x.commentType.value,
					// CommentTypeId: x.commentType.value,
					IsPrivate: x.isPrivate,
					CreatedAt: moment(),
					CreatedByUserId: this.props.currentUser.id,
					MarkedForDeletion: x.markedForDeletion
				};
				orderLineCommentList.push(comment);
			});
		return orderLineCommentList;
	}

	async saveOrderLine() {
		window.scroll(0, 0);
		if (this.state.customRugToViewEdit) {    // orderLine to update is a custom rug component
			if (!this.isOrderLineFormValid()) return;
			let orderLine;
			switch (this.state.orderLine.orderLineType.value) {
				case constants.ORDER_LINE_TYPE.RUG_FIELD.value:
					orderLine = this.parseFieldLine().OrderLine;
					break;
				case constants.ORDER_LINE_TYPE.RUG_BORDER.value:
					orderLine = this.parseBorderLine().OrderLine;
					break;
				case constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value:
					let productId = this.state.orderLine && this.state.orderLine.product ? this.state.orderLine.product.value : 0;
					orderLine = this.parseMiscLine(
						this.state.orderLine.unitCost,
						this.state.orderLine.cost,
						this.state.orderLine.unitPrice,
						this.state.orderLine.price,
						this.state.orderLine.cmsn,
						this.state.orderLine.cmsnRate,
						this.state.orderLine.dsc,
						productId,
						this.state.orderLine.qty).OrderLine;
					break;
				default:
					console.log('unknown rug component type: ', this.state.orderLine.orderLineType.value);
					break;
			}

			let freightOrderLine = null;
			if (this.state.editingFreight) {
				freightOrderLine = this.formatFreightOrderLineForDto(this.state.orderLine);
			} else {
				const childItem = _.find(this.state.customRugToViewEdit.componentLines, function (x) { return (x.parentOrderLineId === orderLine.Id && x.deactivatedAt === null) });
				if (childItem) {   // if item is the parent to a freightOrderLine
					api.post(`Order/DeleteOrderLine/${childItem.id}`)
						.catch(helpers.catchHandler);
				}
			}
			const orderLineComments = this.formatOrderLineCommentsForDto(this.state.orderLineCommentList, orderLine.id);
			const componentOrderLine = {
				OrderLine: orderLine,
				OrderLineComments: orderLineComments,
				FreightOrderLine: freightOrderLine
			};
			const rugOrderLine = {
				OrderLine: {
					Id: this.state.customRugToViewEdit.id,
					OrderId: this.state.orderId,
					ParentOrderLineId: null,
					OrderLineTypeId: constants.ORDER_LINE_TYPE.CUSTOM_RUG.value,
					Dsc: this.state.orderLine.rugDsc,
					IsHidden: false,
					CreatedAt: moment(),
					CreatedByUserId: this.props.currentUser.id,
					CmsnRate: 0,
					Cmsn: 0,
					UnitCost: 0,
					Cost: 0,
					PriceUnitTypeId: constants.PRICE_UNIT_TYPE.EACH.value,
					UnitPrice: 0,
					Price: 0,
					RugLengthFeet: Number(this.state.orderLine.rugLengthFeet),
					RugLengthInches: Number(this.state.orderLine.rugLengthInches),
					RugWidthFeet: Number(this.state.orderLine.rugWidthFeet),
					RugWidthInches: Number(this.state.orderLine.rugWidthInches),
					FinishedSize: this.state.orderLine.finishedSize 
						? this.state.orderLine.finishedSize.label
						: null,
					// FinishedSize: this.state.orderLine.rugWidthFeet + "\' " + this.state.orderLine.rugWidthInches + "\" x " + this.state.orderLine.rugLengthFeet + "\' " + this.state.orderLine.rugLengthInches + "\"" // should this be from presets?
					RugShapeId: this.state.orderLine.rugShape 
						? this.state.orderLine.rugShape.value 
						: null,
					ForOutdoorUse: this.state.orderLine.forOutdoorUse
				},
				FreightOrderLine: null,
				OrderLineComments: []
			};
			const payload = {
				RugOrderLine: rugOrderLine,
				ComponentOrderLine: componentOrderLine
			};
			api.post("Order/SaveCustomRugComponent", payload)
				.then(response => {
					if (response.data.success) {
						this.getOrderLines(this.state.orderId);
						this.getOrderFulfillments(this.state.orderId);
						this.cancelAddOrUpdateOrderLine();
					} else {
						this.setState({ messageFlavor: "danger", message: response.data.message });
					}
				})
				.catch(helpers.catchHandler);
		} else {
			if (!this.isOrderLineFormValid()) return;
			let isSaving = true;
			await this.calculateFigures(isSaving);
			const orderLine = this.formatOrderLineForDto(this.state.orderLine);
			let freightOrderLine = null;
			if (this.state.editingFreight) {
				freightOrderLine = this.formatFreightOrderLineForDto(this.state.orderLine);
			} else {
				const childItem = _.find(this.state.orderLines, function (x) { return (x.parentOrderLineId === orderLine.Id && x.deactivatedAt === null) });
				if (childItem) {   // if item is the parent to a freightOrderLine
					api.post(`Order/DeleteOrderLine/${childItem.id}`)
						.catch(helpers.catchHandler);
				}
			}
			const orderLineComments = this.formatOrderLineCommentsForDto(this.state.orderLineCommentList, orderLine.id);
			const payload = {
				OrderLine: orderLine,
				OrderLineComments: orderLineComments,
				FreightOrderLine: freightOrderLine
			};
			api.post("Order/SaveOrderLine", payload)
				.then(response => {
					if (response.data.success) {
						this.getOrderLines(this.state.orderId);
						this.getOrderFulfillments(this.state.orderId);
						this.cancelAddOrUpdateOrderLine();
					} else {
						this.setState({ messageFlavor: "danger", message: response.data.message });
					}
				})
				.catch(helpers.catchHandler);
		}
	}

	mergeOrderLine(item, parentItem) {
		const mergedOrderLine = {
			id: item.parentOrderLineId,
			freightOrderLineId: item.id,
			orderId: parentItem.orderId,
			// parentOrderLineId: parentItem.orderLineType.value == constants.ORDER_LINE_TYPE.RUG_FIELD.value ? parentItem.parentOrderLineId : parentItem.id,
			parentOrderLineId: COMPONENT_ORDER_LINE_TYPES.includes(parentItem.orderLineType.value) ? parentItem.parentOrderLineId : parentItem.id,
			// parentOrderLineId: parentItem.id,
			orderLineType: parentItem.orderLineType,
			dsc: parentItem.dsc,
			unitCost: parentItem.unitCost,
			cost: parentItem.cost,
			unitPrice: parentItem.unitPrice,
			price: parentItem.price,
			cmsnRate: parentItem.cmsnRate,
			cmsn: parentItem.cmsn,
			isHidden: parentItem.isHidden,
			product: parentItem.product,
			productColor: parentItem.productColor,
			lengthFeet: parentItem.lengthFeet,
			orderLineStatus: parentItem.orderLineStatus,
			priceUnitType: parentItem.priceUnitType,
			squareFeetRequired: parentItem.squareFeetRequired,
			creditCardSurchargeRate: null,
			taxRate: parentItem.taxRate,
			rugIntlCollection: parentItem.rugIntlCollection,
			rugIntlSize: parentItem.rugIntlSize,
			createdAt: parentItem.createdAt,
			createdByUserId: parentItem.createdByUserId,
			updatedByUserId: parentItem.updatedByUserId,
			width: parentItem.width,
			qty: parentItem.qty,
			cartonCount: parentItem.cartonCount,
			squareFeetPerCarton: parentItem.squareFeetPerCarton,
			perContainer: parentItem.perContainer,
			// custom rug stuff:
			rugSize: parentItem.rugSize,
			finishedSize: parentItem.finishedSize,
			rugLengthFeet: parentItem.rugLengthFeet,
			rugLengthInches: parentItem.rugLengthInches,
			rugWidthFeet: parentItem.rugWidthFeet,
			rugWidthInches: parentItem.rugWidthInches,
			rugDsc: parentItem.rugDsc,
			sqFt: parentItem.sqFt,
			finishedEdgeFt: parentItem.finishedEdgeFt,
			rugType: parentItem.rugType,
			// field stuff (used for local calculations):
			fieldLengthFeet: parentItem.fieldLengthFeet,
			fieldLengthInches: parentItem.fieldLengthInches,
			selectedField: parentItem.selectedField,
			selectedFieldColor: parentItem.selectedFieldColor,
			selectedFieldWidth: parentItem.selectedFieldWidth,
			// fieldDsc: parentItem.dsc,
			fieldCmsnRate: parentItem.cmsnRate,
			fieldCmsn: parentItem.cmsn,
			fieldCostPer: parentItem.unitCost,
			totalFieldCost: parentItem.cost,
			fieldPricePer: parentItem.unitPrice,
			totalFieldPrice: parentItem.price,
			fieldPriceUnitType: parentItem.priceUnitType,
			totalFieldUnits: parentItem.qty,
			fieldRun: parentItem.run,
			appliedFieldRepeat: parentItem.repeatNumeric,
			repeatDsc: parentItem.repeatDsc,
			//freight:
			freightCarrier: item.freightCarrier,
			freightSidemark: item.freightSidemark,
			freightInvoiceNumber: item.freightInvoiceNumber,
			freightBolNumber: item.freightBolNumber,
			freightDsc: item.dsc,
			freightTerms: item.freightTerms,
			freightCost: item.cost,
			freightPrice: item.price,
			stContactFirstName: item.stContactFirstName,
			stContactLastName: item.stContactLastName,
			stContactEmail: item.stContactEmail,
			stAddressLine1: item.stAddressLine1,
			stAddressLine2: item.stAddressLine2,
			stAddressLine3: item.stAddressLine3,
			stAddressLine4: item.stAddressLine4,
			stCity: item.stCity,
			stState: item.stState,
			stZip: item.stZip,
			stPhone: item.stPhone,
			commentCount: parentItem.commentCount,
			rugShape: parentItem.rugShape,
			forOutdoorUse: parentItem.forOutdoorUse
		}
		return mergedOrderLine
	}

	updateOrderLine(item) {
		window.scroll(0, 0);
		if (this.state.customRugToViewEdit) {    // orderLine to update is a custom rug component
			if (item.orderLineType.value !== constants.ORDER_LINE_TYPE.FREIGHT.value) {
				item = this.transformItemToRugComponentOrderLine(item);
			}

			let mergedOrderLine;
			let orderLineCommentList = item.orderLineComments.slice();

			// if item is the parent to a freightOrderLine
			const childItem = _.find(this.state.customRugToViewEdit.componentLines, function (x) { return (x.parentOrderLineId === item.id && x.deactivatedAt === null) });
			if (childItem) {
				const parentItem = Object.assign({}, item);
				mergedOrderLine = Object.assign({}, this.mergeOrderLine(childItem, parentItem));
			}

			// if item is a freightOrderLine and has a parent orderLine associated with it
			if (item.parentOrderLineId && item.orderLineType.value === constants.ORDER_LINE_TYPE.FREIGHT.value) {
				let parentItem = _.find(this.state.customRugToViewEdit.componentLines, function (x) { return x.id === item.parentOrderLineId });
				if (parentItem) {
					parentItem = this.transformItemToRugComponentOrderLine(parentItem);
					mergedOrderLine = Object.assign({}, this.mergeOrderLine(item, parentItem));
					orderLineCommentList = parentItem.orderLineComments.slice();
				}
			}
			this.setState({
				orderLine: mergedOrderLine ? mergedOrderLine : item,
				orderLineCommentList: orderLineCommentList,
				showOrderLineForm: true,
				editingFreight: (item.parentOrderLineId && item.orderLineType.value === constants.ORDER_LINE_TYPE.FREIGHT.value || childItem) ? true : false,	// display Freight section if order line has freight info saved
				editingNotes: orderLineCommentList.length > 0 ? true : false, 																																									// display Notes section if order line has notes saved
			}, () => {
				this.fetchAppropiateDataForSelects(mergedOrderLine ? mergedOrderLine : item);
			});


		} else {    // orderLine to update does not belong to a custom rug
			let mergedOrderLine;
			let orderLineCommentList = item.orderLineComments.slice();

			// if item is the parent to a freightOrderLine
			const childItem = _.find(this.state.orderLines, function (x) { return (x.parentOrderLineId === item.id && x.deactivatedAt === null) });
			if (childItem) {
				const parentItem = Object.assign({}, item);
				mergedOrderLine = Object.assign({}, this.mergeOrderLine(childItem, parentItem));
			}

			// if item is a freightOrderLine and has a parent orderLine associated with it
			if (item.parentOrderLineId) {
				const parentItem = _.find(this.state.orderLines, function (x) { return x.id === item.parentOrderLineId });
				mergedOrderLine = Object.assign({}, this.mergeOrderLine(item, parentItem));
				orderLineCommentList = parentItem.orderLineComments.slice();
			}

			this.setState({
				orderLine: mergedOrderLine ? mergedOrderLine : item,
				orderLineCommentList: orderLineCommentList,
				showOrderLineForm: true,
				editingFreight: (item.parentOrderLineId || childItem) ? true : false,		// display Freight section if order line has freight info saved
				editingNotes: orderLineCommentList.length > 0 ? true : false,							// display Notes section if order line has notes saved
			}, () => {
				this.fetchAppropiateDataForSelects(mergedOrderLine ? mergedOrderLine : item);
			});
		}
	}


	transformItemToRugComponentOrderLine(item) {
		let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
			this.state.customRugToViewEdit.rugLengthFeet,
			this.state.customRugToViewEdit.rugLengthInches,
			this.state.customRugToViewEdit.rugWidthFeet,
			this.state.customRugToViewEdit.rugWidthInches);
		item.sqFt = sqFt;
		item.finishedEdgeFt = finishedEdgeFt;
		item.rugLengthFeet = this.state.customRugToViewEdit.rugLengthFeet.toString();			// needed for local fuctions to work
		item.rugLengthInches = this.state.customRugToViewEdit.rugLengthInches.toString();  // needed for local fuctions to work
		item.rugWidthFeet = this.state.customRugToViewEdit.rugWidthFeet.toString();				// needed for local fuctions to work
		item.rugWidthInches = this.state.customRugToViewEdit.rugWidthInches.toString();		// needed for local fuctions to work
		item.rugShape = this.state.customRugToViewEdit.rugShape;
		item.forOutdoorUse = this.state.customRugToViewEdit.forOutdoorUse;
		item.rugDsc = this.state.customRugToViewEdit.dsc;																	// needed for local fuctions to work

		switch (item.orderLineType.value) {
			case constants.ORDER_LINE_TYPE.RUG_FIELD.value:
			case constants.ORDER_LINE_TYPE.FREIGHT.value:
				// item.fieldLengthFeet = Math.floor(item.lengthFeet);
				// item.fieldLengthInches = Math.round((item.fieldLengthInches ? item.fieldLengthInches : 0 * 12) % 1) // this is calculated elsewhere but not on orderline load so

				item.fieldLengthFeet = Math.floor(item.lengthFeet);
				let inchesDec = item.lengthFeet - item.fieldLengthFeet;
				item.fieldLengthInches = (inchesDec * 12).toFixed(0); // this is calculated elsewhere but not on orderline load so
				item.selectedField = Object.assign({}, item.product);
				item.selectedFieldColor = item.productColor ? Object.assign({}, item.productColor) : null;
				item.selectedFieldWidth = Object.assign({}, item.width);
				// item.fieldDsc = helpers.nullableString(item.dsc);
				item.fieldCmsnRate = item.cmsnRate;
				item.fieldCmsn = item.cmsn;
				item.fieldCostPer = item.unitCost;
				item.totalFieldCost = item.cost;
				item.fieldPricePer = item.unitPrice;
				item.totalFieldPrice = item.price;
				item.fieldPriceUnitType = item.priceUnitType ? Object.assign({}, item.priceUnitType) : null;
				item.totalFieldUnits = item.qty;
				item.fieldRun = item.run;
				item.appliedFieldRepeat = item.repeatNumeric;
			case constants.ORDER_LINE_TYPE.RUG_BORDER.value:
			case constants.ORDER_LINE_TYPE.FREIGHT.value:
				item.borderLengthFeet = Math.floor(item.lengthFeet);
				let inchesDec2 = item.lengthFeet - item.borderLengthFeet;
				item.borderLengthInches = (inchesDec2 * 12).toFixed(0); // this is calculated elsewhere but not on orderline load so
				item.selectedBorder = Object.assign({}, item.product);
				item.selectedBorderColor = Object.assign({}, item.productColor);
				item.selectedBorderWidth = Object.assign({}, item.width);
				// item.borderDsc = helpers.nullableString(item.dsc);
				item.borderCmsnRate = item.cmsnRate;
				item.borderCmsn = item.cmsn;
				item.borderCostPer = item.unitCost;
				item.totalBorderCost = item.cost;
				item.borderPricePer = item.unitPrice;
				item.totalBorderPrice = item.price;
				item.borderPriceUnitType = item.priceUnitType ? Object.assign({}, item.priceUnitType) : null;
				item.totalBorderUnits = item.qty;
				// item.borderRun = item.run;
				// item.appliedBorderRepeat = item.repeatNumeric;
				break;
			case constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value:
			// break;
			default:
			// code block
		}
		return item;
	}

	async fetchAppropiateDataForSelects(orderLine) { // fetch appropriate data for selects for product to be updated
		let productAllowCommission;
		let volumeCost;
		let volumePrice;
		let nonVolumeUnitCost;
		let nonVolumeUnitPrice;
		let rollSize;
		let productsList = [];
		let productColorsList = [];
		let widthsList = [];
		// let collectionList = [];
		// let collectionProductsList = [];
		let rugIntlSizeList = [];
		let repeatLength = 0;
		let repeatWidth = 0;
		let repeatDsc = "";
		let productType = this.getOrderLineCorrespondingProductType(orderLine.orderLineType.value);
		if (orderLine.product) {
			await api.fetch(`Product/GetProductWithOrder/${orderLine.product.value}/${this.state.orderId}`)   // TODO: make new api to fetch only this property
			.then(response => {
				productAllowCommission = response.data.allowCommission;
				volumeCost = _.round(response.data.volumeCost, 2).toFixed(2);		// not saved on orderline, only used for volume calculations
				volumePrice = _.round(response.data.volumePrice, 2).toFixed(2);
				nonVolumeUnitCost= _.round(response.data.unitCost, 2).toFixed(2);
				nonVolumeUnitPrice=  _.round(response.data.unitPrice, 2).toFixed(2);
				nonVolumeUnitCost= _.round(response.data.unitCost, 2).toFixed(2);
				nonVolumeUnitPrice=  _.round(response.data.unitPrice, 2).toFixed(2);
				repeatLength = _.round(response.data.repeatLength, 2).toFixed(2);
				repeatWidth = _.round(response.data.repeatWidth, 2).toFixed(2);
				rollSize = response.data.rollSize;
				repeatDsc = response.data.repeat;
			})
			.catch(helpers.catchHandler);
		}
		if (orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
			await api.fetch(`Order/GetProductsOfProductType/${productType}`)
				.then(response => {
					productsList = response.data;
				});

			if (orderLine.product) {  // some orderLines don't have a product apparently
				await api.fetch(`Product/GetProductWidths/${orderLine.product.value}`)
					.then(response => {
						widthsList = _.map(response.data, w => {
							return {
								value: w.widthId,
								label: w.widthName,
								widthNumericFeet: w.widthNumericFeet
							}
						});
					})
					.catch(helpers.catchHandler);

				await api.fetch(`Order/GetActiveProductColors/${orderLine.product.value}`)
					.then(response => {
						productColorsList = response.data;
					})
					.catch(helpers.catchHandler);
			}
			this.setState({
				productsList: productsList,
				productColorsList: productColorsList ? productColorsList : null,
				orderLine: {
					...orderLine,
					widthsList: widthsList ? widthsList : null,
					productAllowCommission: productAllowCommission,
					volumeCost: volumeCost,
					volumePrice: volumePrice,
					rollSize: rollSize
				},
				nonVolumeUnitCost: nonVolumeUnitCost, 
				nonVolumeUnitPrice:  nonVolumeUnitPrice	
				//widthsList: widthsList
			})
		} else {
			// await api.fetch("Order/GetRugIntlCollectionList")
			// 	.then(response => {
			// 		collectionList = response.data;
			// 	})
			// 	.catch(helpers.catchHandler);;

			// await api.fetch(`Order/GetActiveCollectionProducts/${orderLine.rugIntlCollection.value}`)
			// 	.then(response => {
			// 		collectionProductsList = response.data;
			// 	})
			// 	.catch(helpers.catchHandler);

			await api.fetch(`Order/GetProductsOfProductType/${productType}`)
			.then(response => {
				productsList = response.data;
			});

			await api.fetch(`Order/GetActiveProductColors/${orderLine.product.value}`)
				.then(response => {
					productColorsList = response.data;
				})
				.catch(helpers.catchHandler);

			await api.fetch(`Product/GetRugIntlSizes/${orderLine.product.value}`)
				.then(response => {
					rugIntlSizeList = _.map(response.data, sz => {
						return {
							value: sz.id,
							label: sz.dsc,
							cost: sz.cost,
							price: sz.price
						}
					});
				})
				.catch(helpers.catchHandler);
			productColorsList.unshift({ label: "No color selected", value: null });
			this.setState({
				// rugIntlCollectionList: collectionList,
				// collectionProductsList: collectionProductsList,
				productsList: productsList,
				productColorsList: productColorsList,
				rugIntlSizeList: rugIntlSizeList,
				orderLine: {
					...orderLine,
					productAllowCommission: productAllowCommission
				}
			})
		}
	}

	onToggleFreight() {
		this.setState({
			editingFreight: !this.state.editingFreight,
			message: null,
			messageFlavor: null
		}, () => {
			if (this.state.editingFreight
				&& this.state.orderLine
				&& (!this.state.orderLine.shippingAddresses 
						|| this.state.orderLine.shippingAddresses.length < this.state.customerShipToAddresses.length))
			{
				this.setState({
					orderLine: {
						...this.state.orderLine,
						shippingAddresses: this.state.customerShipToAddresses
					}
				});
			}
		});
	}

	onToggleNotes() {
		let tempList = _.cloneDeep(this.state.orderLineCommentList);
		if (this.state.editingNotes) {
			tempList = _.map(this.state.orderLineCommentList, comment => {
				return {
					...comment,
					markedForDeletion: true
				}
			});
		} else {
			tempList = _.map(this.state.orderLineCommentList, comment => {
				return {
					...comment,
					markedForDeletion: false
				}
			});
		}
		this.setState({
			editingNotes: !this.state.editingNotes,
			orderLineCommentList: tempList

		});
	}

	applyVolumePricingIfNecessary() {
		let unitCost = _.round(this.state.orderLine.unitCost, 2).toFixed(2);
		let unitPrice =_.round(this.state.orderLine.unitPrice, 2).toFixed(2);
		let volumePricingApplied = this.state.orderLine.volumePricingApplied;
		const lengthFeet = this.state.orderLine.lengthFeet ? _.round(this.state.orderLine.lengthFeet, 2).toFixed(2) : "";
		const squareFeetRequired = this.state.orderLine.squareFeetRequired ?_.round(this.state.orderLine.squareFeetRequired, 2).toFixed(2) : "";
		if (lengthFeet || squareFeetRequired) {  // lengthFeet for carpets and rug pads. squareFeetRequired for Wood items
			let hasQualifyingVolume = 
				(Number(this.state.orderLine.rollSize) > 0 
					&& (Number(lengthFeet) > Number(this.state.orderLine.rollSize) || Number(squareFeetRequired) > Number(this.state.orderLine.rollSize))) ? true : false;
			if (hasQualifyingVolume) {
					if(window.confirm('Apply volume pricing?')) {
						unitCost = _.round(this.state.orderLine.volumeCost, 2).toFixed(2);
						unitPrice = _.round(this.state.orderLine.volumePrice, 2).toFixed(2);
						volumePricingApplied = true;
					}
			} else {
				if (volumePricingApplied && window.confirm('Apply non volume pricing?')) {
					unitCost =_.round(this.state.nonVolumeUnitCost, 2).toFixed(2);
					unitPrice = _.round(this.state.nonVolumeUnitPrice, 2).toFixed(2);
					volumePricingApplied = false;
				}
			}
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				unitCost: unitCost,
				unitPrice: unitPrice,
				volumePricingApplied: volumePricingApplied
			}
		}, () => this.calculateFigures());
	}

	async calculateFigures(isSaving = false) {
		const orderLine = this.state.orderLine;
		if (!orderLine.product) return;

		const orderLineType = orderLine.orderLineType.value;
		if (orderLineType === constants.ORDER_LINE_TYPE.CARPET.value || orderLineType === constants.ORDER_LINE_TYPE.RUG_PAD.value) {
			let updatedOrderLine = Object.assign({}, orderLine);
			updatedOrderLine.unitCost = _.round(orderLine.unitCost, 2).toFixed(2);			// round entered value even if no calculations are made
			updatedOrderLine.unitPrice = _.round(orderLine.unitPrice, 2).toFixed(2);		// round entered value even if no calculations are made
			updatedOrderLine.cmsnRate = _.round(orderLine.cmsnRate, 2).toFixed(2);			// round entered value even if no calculations are made

			const lengthFeet = orderLine.lengthFeet ? _.round(orderLine.lengthFeet, 2).toFixed(2) : "";
			const widthNumericFeet = orderLine.width && orderLine.width.widthNumericFeet;
			let payload = {
				orderLineTypeId: orderLine.orderLineType.value,
				lengthFeet: lengthFeet ? Number(lengthFeet) : null,
				widthNumericFeet: Number(widthNumericFeet),
				qty: Number(orderLine.qty),
				unitCost: Number(updatedOrderLine.unitCost),
				unitPrice: Number(updatedOrderLine.unitPrice),
				cmsnRate: Number(_.round(orderLine.cmsnRate, 2).toFixed(2)),
				priceUnitTypeName: orderLine.priceUnitType ? orderLine.priceUnitType.label : '' // should send Id,
			}
			if (payload.widthNumericFeet && (payload.lengthFeet || payload.qty) &&
				payload.unitCost && payload.unitPrice) {
				await api.post("Order/CalculateFigures", payload)
					.then(response => {
						updatedOrderLine.unitCost = _.round(response.data.unitCost, 2).toFixed(2);
						updatedOrderLine.unitPrice = _.round(response.data.unitPrice, 2).toFixed(2);
						updatedOrderLine.cmsnRate = _.round(response.data.cmsnRate, 2).toFixed(2);
						updatedOrderLine.lengthFeet = lengthFeet;
						updatedOrderLine.widthNumericFeet = widthNumericFeet;
						updatedOrderLine.qty = _.round(response.data.qty, 2).toFixed(2);
						updatedOrderLine.cost = _.round(response.data.cost, 2).toFixed(2);
						updatedOrderLine.price = _.round(response.data.price, 2).toFixed(2);
						updatedOrderLine.cmsn = _.round(response.data.cmsn, 2).toFixed(2);
					})
					.catch(helpers.catchHandler);
			} else {
				updatedOrderLine.qty = '';
				updatedOrderLine.cost = '';
				updatedOrderLine.price = '';
				updatedOrderLine.cmsn = '';
				updatedOrderLine.dsc = '';
			}

			const productDsc = orderLine.product.label;
			const productColorDsc = orderLine.productColor ? orderLine.productColor.label : '';
			const widthDsc = orderLine.width ? orderLine.width.label : '';
			const lengthDsc = orderLine.lengthFeet || updatedOrderLine.qty || orderLine.qty;
			const qtyDsc = updatedOrderLine.qty || orderLine.qty;
			const priceUnitDsc = orderLine.priceUnitType ? orderLine.priceUnitType.label : '';

			// before was blank if api call didn't go through, now still displays dsc
			if (!isSaving && orderLine.width && (orderLine.lengthFeet || updatedOrderLine.qty || orderLine.qty)) {
				updatedOrderLine.dsc = `${productDsc}, ${productColorDsc} (${widthDsc} X ${lengthDsc}) = ${qtyDsc} ${priceUnitDsc}`
			}
			this.setState({
				orderLine: updatedOrderLine
			});
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.MISCELLANEOUS.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value
			|| this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value) {
			let updatedOrderLine = Object.assign({}, this.state.orderLine);

			updatedOrderLine.cmsnRate = _.round(this.state.orderLine.cmsnRate, 2).toFixed(2);
			if (!isSaving && this.state.orderLine.qty && this.state.orderLine.product) {
				let dsc;
				if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value && this.state.orderLine.productColor) {
					dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}, ${this.state.orderLine.productColor.label}`
				} else {
					dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}`
				}
				updatedOrderLine.dsc = dsc;
			}
			if (this.state.orderLine.qty &&
				this.state.orderLine.unitCost && this.state.orderLine.unitPrice && updatedOrderLine.cmsnRate) {
				const payload = {
					orderLineTypeId: this.state.orderLine.orderLineType.value,
					qty: Number(this.state.orderLine.qty),
					unitCost: Number(_.round(this.state.orderLine.unitCost, 2).toFixed(2)),
					unitPrice: Number(_.round(this.state.orderLine.unitPrice, 2).toFixed(2)),
					cmsnRate: Number(_.round(this.state.orderLine.cmsnRate, 2).toFixed(2))
				}
				await api.post("Order/CalculateFigures", payload)
					.then(response => {
						// if (response.data.success) {
						updatedOrderLine = {
							...updatedOrderLine,
							cost: _.round(response.data.cost, 2).toFixed(2),
							price: _.round(response.data.price, 2).toFixed(2),
							cmsn: _.round(response.data.cmsn, 2).toFixed(2)
						}
						// }
					})
					.catch(helpers.catchHandler);
			} else {
				updatedOrderLine = {
					...this.state.orderLine,
					cost: '',
					price: '',
					cmsn: '',
					dsc: ''
				}
			}
			this.setState({
				orderLine: updatedOrderLine
			});
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.BINDING.value ||
			this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.ROLL_RUNNER.value) {
			let updatedOrderLine = Object.assign({}, this.state.orderLine);
			updatedOrderLine = {
				...updatedOrderLine,
				unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2),			// round entered value even if no calculations are made
				unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2),		// round entered value even if no calculations are made
				cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)			// round entered value even if no calculations are made
			}
			if (!isSaving && this.state.orderLine.product && this.state.orderLine.lengthFeet) {
				updatedOrderLine = {
					...updatedOrderLine,
					dsc: `${this.state.orderLine.product.label}, ${this.state.orderLine.productColor ? this.state.orderLine.productColor.label : ''} - ${this.state.orderLine.lengthFeet} Lin Ft`
				}
			}
			if (this.state.orderLine.lengthFeet &&
				this.state.orderLine.unitCost && this.state.orderLine.unitPrice && updatedOrderLine.cmsnRate) {
				const lengthFeet = this.state.orderLine.lengthFeet ? _.round(this.state.orderLine.lengthFeet, 2).toFixed(2) : "";
				const payload = {
					orderLineTypeId: this.state.orderLine.orderLineType.value,
					lengthFeet: lengthFeet ? Number(lengthFeet) : null,
					unitCost: Number(_.round(this.state.orderLine.unitCost, 2).toFixed(2)),
					unitPrice: Number(_.round(this.state.orderLine.unitPrice, 2).toFixed(2)),
					cmsnRate: Number(_.round(this.state.orderLine.cmsnRate, 2).toFixed(2))
				}
				await api.post("Order/CalculateFigures", payload)
					.then(response => {
						// if (response.data.success) {
						updatedOrderLine = {
							...updatedOrderLine,
							lengthFeet: lengthFeet,
							qty: _.round(response.data.qty, 2).toFixed(2),
							cost: _.round(response.data.cost, 2).toFixed(2),
							price: _.round(response.data.price, 2).toFixed(2),
							cmsn: _.round(response.data.cmsn, 2).toFixed(2)
						}
						// }
					})
					.catch(helpers.catchHandler);
			} else {
				updatedOrderLine = {
					...this.state.orderLine,
					qty: '',
					cost: '',
					price: '',
					cmsn: '',
					dsc: ''
				}
			}
			this.setState({
				orderLine: updatedOrderLine
			});
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.HARD_SURFACE.value ||
			this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET_TILE.value) {
			let updatedOrderLine = Object.assign({}, this.state.orderLine);
			updatedOrderLine = {
				...updatedOrderLine,
				unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2),			// round entered value even if no calculations are made
				unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2),		// round entered value even if no calculations are made
				cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)			// round entered value even if no calculations are made
			}

			if (this.state.orderLine.squareFeetRequired &&
					this.state.orderLine.unitCost && this.state.orderLine.unitPrice && updatedOrderLine.cmsnRate &&
					this.state.orderLine.squareFeetPerCarton) {
				const squareFeetRequired = _.round(this.state.orderLine.squareFeetRequired, 2).toFixed(2);
				const payload = {
					orderLineTypeId: this.state.orderLine.orderLineType.value,
					squareFeetRequired: Number(squareFeetRequired),
					squareFeetPerCarton: Number(_.round(this.state.orderLine.squareFeetPerCarton, 2).toFixed(2)),
					unitCost: Number(updatedOrderLine.unitCost),
					unitPrice: Number(updatedOrderLine.unitPrice),
					cmsnRate: Number(_.round(this.state.orderLine.cmsnRate, 2).toFixed(2))
				}
				await api.post("Order/CalculateFigures", payload)
					.then(response => {
						// if (response.data.success) {
						let dsc;
						let cartonOrCartonsString = response.data.cartonCount > 1 ? 'Cartons' : 'Carton';
						if (!isSaving && this.state.orderLine.product && this.state.orderLine.squareFeetRequired) {
							dsc = `${this.state.orderLine.product.label}, ${this.state.orderLine.productColor ? this.state.orderLine.productColor.label : ''} ` +
								`${response.data.cartonCount} ${cartonOrCartonsString} ` +
								`(${_.round(response.data.qty, 2).toFixed(2)} ${this.state.orderLine.priceUnitType.label})`
						}
						updatedOrderLine = {
							...updatedOrderLine,
							squareFeetRequired: squareFeetRequired,
							cartonCount: response.data.cartonCount,
							qty: _.round(response.data.qty, 2).toFixed(2),
							cost: _.round(response.data.cost, 2).toFixed(2),
							price: _.round(response.data.price, 2).toFixed(2),
							cmsn: _.round(response.data.cmsn, 2).toFixed(2),
							dsc: dsc ? dsc : this.state.orderLine.dsc
						}
						// }
					})
					.catch(helpers.catchHandler);
			}
			else {
				updatedOrderLine = {
					...this.state.orderLine,
					cartonCount: '',
					qty: '',
					cost: '',
					price: '',
					cmsn: '',
					dsc: ''
				}
			}
			this.setState({
				orderLine: updatedOrderLine
			});
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value) {
			let updatedOrderLine = Object.assign({}, this.state.orderLine);
			if (this.state.orderLine.rugIntlSize) {
				updatedOrderLine = {
					...updatedOrderLine,
					unitCost: _.round(this.state.orderLine.unitCost, 2).toFixed(2),
					unitPrice: _.round(this.state.orderLine.unitPrice, 2).toFixed(2),
					cmsnRate: _.round(this.state.orderLine.cmsnRate, 2).toFixed(2)
				}
			}
			if (!isSaving && this.state.orderLine.qty && this.state.orderLine.product && this.state.orderLine.rugIntlSize) {
				let dsc;
				if (this.state.orderLine.productColor) {
					dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label}, ${this.state.orderLine.productColor.label} (${this.state.orderLine.rugIntlSize.label})`
				} else {
					dsc = `${this.state.orderLine.qty} X ${this.state.orderLine.product.label} (${this.state.orderLine.rugIntlSize.label})`
				}
				updatedOrderLine = {
					...updatedOrderLine,
					dsc: dsc
				}
			}
			if (this.state.orderLine.qty &&
				this.state.orderLine.unitCost && this.state.orderLine.unitPrice && updatedOrderLine.cmsnRate) {
				const payload = {
					orderLineTypeId: this.state.orderLine.orderLineType.value,
					qty: Number(this.state.orderLine.qty),
					unitCost: Number(_.round(this.state.orderLine.unitCost, 2).toFixed(2)),
					unitPrice: Number(_.round(this.state.orderLine.unitPrice, 2).toFixed(2)),
					cmsnRate: Number(_.round(this.state.orderLine.cmsnRate, 2).toFixed(2))
				}
				await api.post("Order/CalculateFigures", payload)
					.then(response => {
						// if (response.data.success) {
						updatedOrderLine = {
							...updatedOrderLine,
							cost: _.round(response.data.cost, 2).toFixed(2),
							price: _.round(response.data.price, 2).toFixed(2),
							cmsn: _.round(response.data.cmsn, 2).toFixed(2)
						}
						// }
					})
					.catch(helpers.catchHandler);
			}
			else {
				updatedOrderLine = {
					...this.state.orderLine,
					cost: '',
					price: '',
					cmsn: '',
					dsc: ''
				}
			}
			this.setState({
				orderLine: updatedOrderLine
			});
		}
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value) {
			const creditCardSurchargeRate = _.round(this.state.orderLine.creditCardSurchargeRate, 2).toFixed(2);
			const payload = {
				orderLineTypeId: this.state.orderLine.orderLineType.value,
				creditCardSurchargeRate: Number(creditCardSurchargeRate),
				totalOrderCost: Number(this.state.totalOrderCost),
				totalWithoutCreditCardSurcharge: Number(this.state.totalWithoutCreditCardSurcharge),
			}
			api.post("Order/CalculateFigures", payload)
				.then(response => {
					// if (response.data.success) {
					this.setState(prevState => ({
						...prevState,
						orderLine: {
							...prevState.orderLine,
							creditCardSurchargeRate: creditCardSurchargeRate,
							price: _.round(response.data.price, 2).toFixed(2)
						}
					}));
					// }
				})
				.catch(helpers.catchHandler);
		}

		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value) {
			const taxRate = _.round(this.state.orderLine.taxRate, 2).toFixed(2);
			const payload = {
				orderLineTypeId: this.state.orderLine.orderLineType.value,
				taxRate: Number(taxRate),
				totalOrderCost: Number(this.state.totalOrderCost),
				sumOfTaxableItems: Number(this.state.sumOfTaxableItems),
			}
			api.post("Order/CalculateFigures", payload)
				.then(response => {
					// if (response.data.success) {
					this.setState(prevState => ({
						...prevState,
						orderLine: {
							...prevState.orderLine,
							taxRate: taxRate,
							price: _.round(response.data.price, 2).toFixed(2)
						}
					}));
					// }
				})
				.catch(helpers.catchHandler);
		}
	}

	async setSelectedShippingContact(option) {
		let phoneNumber = await _.find(option.phoneNumbers, x => x.typeOfPhone === constants.PHONE_TYPE.SHIP_TO);
		if (!phoneNumber) {
			phoneNumber = await _.find(option.phoneNumbers, x => x.isPrimary === true);
		}
		const shippingAddresses = this.state.customerShipToAddresses.concat(
			_.map(_.filter(option.addresses, x => x.typeOfAddress === constants.ADDRESS_TYPE.SHIP_TO),
				ad => {
					return {
						...ad,
						value: ad.id,
						label: helpers.formatAddressLabel(ad)
					}
				}));
		this.setState({
			orderLine: {
				...this.state.orderLine,
				stContactFirstName: option.firstName,
				stContactLastName: option.lastName,
				stContactEmail: helpers.nullableString(option.email),
				stPhone: phoneNumber ? phoneNumber.number : "",
				shippingAddresses: shippingAddresses
			}
		});
	}

	setSelectedShippingAddress(option) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				stAddressLine1: helpers.nullableString(option.ln1),
				stAddressLine2: helpers.nullableString(option.ln2),
				stAddressLine3: helpers.nullableString(option.ln3),
				stAddressLine4: helpers.nullableString(option.ln4),
				stCity: helpers.nullableString(option.city),
				stState: helpers.nullableString(option.state),
				stZip: helpers.nullableString(option.zip),

			}
		})
	}

	async setSelectedBillingContact(option) {
		// let phoneNumber = await _.find(option.phoneNumbers, x => x.typeOfPhone === constants.PHONE_TYPE.CELL);   // No BILL_TO phone type currently available
		let phoneNumber = await _.find(option.phoneNumbers, x => x.isPrimary === true);                                 // Should default to primary phone?
		let faxNumber = await _.find(option.faxNumbers, x => x.typeOfPhone === constants.PHONE_TYPE.FAX);
		let billingAddresses = _.filter(option.addresses, x => x.typeOfAddress === constants.ADDRESS_TYPE.BILL_TO);
		this.setState({
			order: {
				...this.state.order,
				btContactFirstName: option.firstName,
				btContactLastName: option.lastName,
				btContactEmail: helpers.nullableString(option.email),
				btPhone: phoneNumber ? phoneNumber.number : "",
				btFax: faxNumber ? faxNumber.number : "",
				billingAddresses: _.map(billingAddresses, ad => {  // only used temporarily, not persisted
					return {
						...ad,
						value: ad.id,
						label: helpers.formatAddressLabel(ad)
					}
				})
			}
		});
	}

	setSelectedBillingAddress(option) {
		this.setState({
			order: {
				...this.state.order,
				btAddressLine1: helpers.nullableString(option.ln1),
				btAddressLine2: helpers.nullableString(option.ln2),
				btAddressLine3: helpers.nullableString(option.ln3),
				btAddressLine4: helpers.nullableString(option.ln4),
				btCity: helpers.nullableString(option.city),
				btState: helpers.nullableString(option.state),
				btZip: helpers.nullableString(option.zip)
			},
			selectedBillingAddress: option
		})
	}

	showOrderLineDeleteConfirmationModal(orderLineId, orderLineType, orderLineComponentLines) {
		if (this.state.confirmationModalOrderLineId) {
			this.setState({
				confirmationModalOrderLineId: null,
				confirmationModalType: null,
				confirmationModalComponentLines: null
			});
		}
		else {
			this.setState({
				confirmationModalOrderLineId: orderLineId,
				confirmationModalType: orderLineType,
				confirmationModalComponentLines: orderLineComponentLines
			});
		}
	}

	handleDeleteConfirmationCancel() {
		this.setState({ confirmationModalOrderLineId: null });
	}

	async handleOrderLineDelete(orderLineId) {
		const childItem = _.find(this.state.orderLines, function (x) { return (x.parentOrderLineId === orderLineId && x.deactivatedAt === null) });
		if (childItem) {   // if item is the parent to a freightOrderLine
			await api.post(`Order/DeleteOrderLine/${childItem.id}`)
				.catch(helpers.catchHandler);
		}

		api.post(`Order/DeleteOrderLine/${orderLineId}`)
			.then(response => {
				this.getOrderLines(this.state.orderId);
				this.getOrderFulfillments(this.state.orderId);
			})
			.catch(helpers.catchHandler);

		this.setState({ confirmationModalOrderLineId: null });
	}

	handleOrderLineUndelete(orderLineId) {
		api
			.post(`Order/ReviveOrderLine/${orderLineId}`)
			.then(response => {
				this.getOrderLines(this.state.orderId);
			})
			.catch(helpers.catchHandler);
	}

	async handleCustomRugOrderLineDelete(rugOrderLineId, componentLines) {
		let componentLineIds = _.map(componentLines, cl => {
			return cl.id
		});
		componentLineIds.push(rugOrderLineId);
		const payload = {
			linesToDeleteIds: componentLineIds
		}

		api.post(`Order/DeleteCustomRugOrderLine`, payload)
			.then(response => {
				if (response.data.success) {
					this.getOrderLines(this.state.orderId);
					this.getOrderFulfillments(this.state.orderId);
				}
			})
			.catch(helpers.catchHandler);

		this.setState({ confirmationModalOrderLineId: null });
	}

	//Order Payments:
	onAddNewPayment() {
		this.setState({
			addUpdatePayment: true,
			message: null,
			messageFlavor: null
		});
	}

	cancelAddOrUpdatePayment() {
		this.setState({
			orderPayment: emptyOrderPayment,
			addUpdatePayment: false,
			message: null,
			messageFlavor: null
		});
	}

	handleOrderPaymentStateUpdate(fieldName, value) {
		this.setState({
			orderPayment: {
				...this.state.orderPayment,
				[fieldName]: value
			}
		});
	}

	updateOrderPaymentRateOrCurrencyField(fieldName, value) {
		if (value) {
			this.setState({
				orderPayment: {
					...this.state.orderPayment,
					[fieldName]: _.round(value, 2).toFixed(2)
				}
			});
		}
	}

	handlePaymentTypeChange(elementName, selection) {
		// If updating a payment, and payment type is changed, all fields
		// for old payment type need to be cleared out. Keep Id though.
		this.setState({
			orderPayment: {
				...emptyOrderPayment,
				id: this.state.orderPayment.id,
				[elementName]: selection
			}
		});
	}

	handleDateReceivedChange(date) {
		this.setState({
			orderPayment: {
				...this.state.orderPayment,
				dateReceived: date
			}
		});
	}

	handleCreditCardTypeChange(elementName, selection) {
		this.setState({
			orderPayment: {
				...this.state.orderPayment,
				[elementName]: selection
			}
		});
	}

	handleOutstandingCreditChange(elementName, selection) {
		this.setState({
			orderPayment: {
				...this.state.orderPayment,
				[elementName]: selection
			}
		});
	}

	setSelectedCustomer(option) {
		this.setState(prevState => ({
			...prevState,
			payment: {
				...prevState.payment,
				customer: option
			}
		}));
	}

	isBillingInfoFormValid() {
		const warnings = [];
		if (!this.state.order.btAddressLine1) {
			warnings.push("Bill To Name is required.");
		}
		if (!this.state.order.btAddressLine3) {
			warnings.push("Billing Address is required.");
		}
		if (!this.state.order.btCity) {
			warnings.push("City is required.");
		}
		if (!this.state.order.btState) {
			warnings.push("State is required.");
		}
		if (!this.state.order.btZip) {
			warnings.push("Zip is required.");
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	formatBillingInfoPayload() {
		const formattedBillingInfo = {
			OrderId: this.state.orderId,
			CustomerPONumber: this.state.order.customerPONumber,
			BTContactFirstName: this.state.order.btContactFirstName,
			BTContactLastName: this.state.order.btContactLastName,
			BTContactEmail: this.state.order.btContactEmail,
			BTAddressLine1: this.state.order.btAddressLine1,
			BTAddressLine2: this.state.order.btAddressLine2,
			BTAddressLine3: this.state.order.btAddressLine3,
			BTAddressLine4: this.state.order.btAddressLine4,
			BTCity: this.state.order.btCity,
			BTState: this.state.order.btState,
			BTZip: this.state.order.btZip,
			BTPhone: this.state.order.btPhone,
			BTFax: this.state.order.btFax,
			InvoiceTermsId: this.state.order.invoiceTerms.value
		};
		return formattedBillingInfo;
	}

	saveBillingInfo() {
		window.scroll(0, 0);
		if (!this.isBillingInfoFormValid()) return;
		const BillingInfoDto = this.formatBillingInfoPayload();
		api.post("Order/SaveBillingInfo", BillingInfoDto)
			.then(response => {
				if (response.data.success) {
					Alert.success("Billing info saved");
					this.setState({
						message: null,
						messageFlavor: null
					},
						// () => this.getCustomer(this.state.order.customer.value)
					);
				} else {
					this.setState({ messageFlavor: "danger", message: response.data.message });
				}
			})
			.catch(helpers.catchHandler);
	}

	isOrderPaymentFormValid() {
		const warnings = [];
		// if (!this.state.order.btAddressLine1) {
		// 	warnings.push("Bill To Name is required.");
		// }
		// if (!this.state.order.btAddressLine3) {
		// 	warnings.push("Billing Address is required.");
		// }
		// if (!this.state.order.btCity) {
		// 	warnings.push("City is required.");
		// }
		// if (!this.state.order.btState) {
		// 	warnings.push("State is required.");
		// }
		// if (!this.state.order.btZip) {
		// 	warnings.push("Zip is required.");
		// }
		if (!this.state.orderPayment.paymentType) {
			warnings.push("Payment Type is required.");
		}
		if (this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CASH.value) {
			if (!this.state.orderPayment.amountPaid) {
				warnings.push("Cash Amount is required.");
			}
		}
		if (this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CHECK.value) {
			if (!this.state.orderPayment.amountPaid) {
				warnings.push("Check Amount is required.");
			}
			if (!this.state.orderPayment.checkNumber) {
				warnings.push("Check Number is required.");
			}
			if (!this.state.orderPayment.dateReceived) {
				warnings.push("Date Received is required.");
			}
		}
		if (this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CREDIT_CARD.value) {
			if (!this.state.orderPayment.creditCardType || !this.state.orderPayment.creditCardType.value) {
				warnings.push("Credit Card Type is required.");
			}
			if (!this.state.orderPayment.amountPaid) {
				warnings.push("Charge Amount is required.");
			}
			if (!this.state.orderPayment.dateReceived) {
				warnings.push("Date Received is required.");
			}
			if (!this.state.orderPayment.expDate) {
				warnings.push("Expiration Date is required.");
			}
			if (!this.state.orderPayment.cardNumber) {
				warnings.push("Card Number is required.");
			}
			if (!this.state.orderPayment.billingName) {
				warnings.push("Billing Name is required.");
			}
			if (!this.state.orderPayment.billingZip) {
				warnings.push("Billing Zip is required.");
			}
		}
		if (this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.EXTENDED_CREDIT.value) {
			if (!this.state.orderPayment.amountPaid) {
				warnings.push("Credit Amount is required.");
			}

		}
		if (this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.REBATE.value) {
			if (!this.state.orderPayment.amountPaid) {
				warnings.push("Rebate Amount is required.");
			}
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	formatOrderPaymentPayload() {
		const orderPayment = Object.assign({}, this.state.orderPayment);
		const formattedOrderPayment = {
			Id: orderPayment.id ? orderPayment.id : 0,
			OrderId: this.state.orderId,
			// OrderLineId: null,
			CustomerPaymentStatusId: null,
			CustomerPaymentStatusName: null,
			SampleBookId: null,
			ReferenceNumber: orderPayment.referenceNumber,
			PayToName: orderPayment.payToName,
			CheckNumber: orderPayment.checkNumber,
			AmountPaid: Number(orderPayment.amountPaid),
			ForWhat: orderPayment.forWhat,
			// InvoiceNumber: orderPayment.invoiceNumber,
			Comment: orderPayment.comment,
			PaymentTypeId: orderPayment.paymentType.value,
			CreatedAt: new Date(),
			CreatedByUserId: this.props.currentUser.id,
			DateReceived: orderPayment.dateReceived,
			ExpDate: orderPayment.expDate,
			CardNumber: orderPayment.cardNumber,
			BillingName: orderPayment.billingName,
			BillingZip: orderPayment.billingZip,
			CreditCardTypeId: orderPayment.creditCardType ? orderPayment.creditCardType.value : null,
			CreditCardTypeName: orderPayment.creditCardType ? orderPayment.creditCardType.label : null,
			DeactivatedAt: null,
			DeactivatedByUserId: null,
			CustomerCreditId: orderPayment.customerOutstandingCredit ? orderPayment.customerOutstandingCredit.value : null
		};
		return formattedOrderPayment;
	}

	saveOrderPayment() {
		window.scroll(0, 0);
		if (!this.isOrderPaymentFormValid()) return;
		const OrderPaymentDto = this.formatOrderPaymentPayload();
		api.post("Order/SaveOrderPayment", OrderPaymentDto)
			.then(response => {
				if (response.data.success) {
					this.getOrderPayments(this.state.orderId);
					this.setState({
						orderPayment: emptyOrderPayment,
						addUpdatePayment: false,
						message: null,
						messageFlavor: null
					}, () => this.getCustomer(this.state.order.customer.value));
				} else {
					this.setState({ messageFlavor: "danger", message: response.data.message });
				}
			})
			.catch(helpers.catchHandler);
	}

	updateOrderPayment(orderPayment) {
		this.setState({
			orderPayment: orderPayment,
			addUpdatePayment: true
		});
	}

	handleDelete(orderPaymentId) {
		api
			.post(`Order/DeleteOrderPayment/${orderPaymentId}`)
			.then(response => {
				this.getOrderPayments(this.state.orderId);
			})
			.catch(helpers.catchHandler);
	}

	handleUndelete(orderPaymentId) {
		api
			.post(`Order/ReviveOrderPayment/${orderPaymentId}`)
			.then(response => {
				this.getOrderPayments(this.state.orderId);
			})
			.catch(helpers.catchHandler);
	}

	//Order Fulfillments:
	createInitialOrderFulfillments() {
		api.fetch(`Order/CreateInitialOrderFulfillments/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderFulfillments: response.data
				});
			})
			.catch(helpers.catchHandler);
	}

	addNewFulfillment() {
		this.setState({
			orderFulfillment: emptyOrderFulfillment,
			addNewFulfillment: !this.state.addNewFulfillment
		});
	}

	handleOrderFullfillmentSelectChange(elementName, selection) {
		this.setState({
			orderFulfillment: {
				...this.state.orderFulfillment,
				[elementName]: selection
			}
		});
	}

	handleFulfillmentStateUpdate(fieldName, value) {
		this.setState(prevState => ({
			...prevState,
			orderFulfillment: {
				...prevState.orderFulfillment,
				[fieldName]: value
			}
		}));
	}

	editOrderFulfillment(orderFulfillmentId) {
		const indexToEdit = _.findIndex(this.state.orderFulfillments, { id: orderFulfillmentId });
		let tempList = this.state.orderFulfillments.slice();
		const snapshotItem = Object.assign({}, tempList[indexToEdit]);
		tempList[indexToEdit].isEditing = true;
		tempList[indexToEdit].pristine = snapshotItem;
		this.setState({
			orderFulfillments: tempList
		});
	}

	updateOrderFulfillmentStatus(selection, orderFulfillmentId) {
		const tempList = this.state.orderFulfillments.slice();
		const fulfillment = _.find(tempList, x => x.id === orderFulfillmentId);
		fulfillment.orderFulfillmentStatus = {
			value: selection.value,
			label: selection.label
		}
		this.setState({
			orderFulfillments: tempList
		});
	}


	handleEditFulfillmentStateUpdate(fieldName, value, orderFulfillmentId) {
		const orderFulfillments = this.state.orderFulfillments.slice();
		const orderFulfillment = _.find(orderFulfillments, x => x.id === orderFulfillmentId);
		orderFulfillment[fieldName] = value;
		this.setState({
			orderFulfillments: orderFulfillments
		});
	}

	editFulfillmentDateChange(fieldName, orderFulfillmentId, date) {
		const orderFulfillments = this.state.orderFulfillments.slice();
		const orderFulfillment = _.find(orderFulfillments, x => x.id === orderFulfillmentId);
		orderFulfillment[fieldName] = date;
		this.setState({
			orderFulfillments: orderFulfillments
		});
	}

	onCancelEditOrderFulfillment(orderFulfillmentId) {
		const indexToEdit = _.findIndex(this.state.orderFulfillments, { id: orderFulfillmentId });
		let tempList = this.state.orderFulfillments.slice();
		tempList[indexToEdit] = tempList[indexToEdit].pristine;
		this.setState({
			orderFulfillments: tempList,
			message: null,
			messageFlavor: null
		});
	}

	isOrderFulfillmentFormValid(fulfillment) {
		const warnings = [];
		if (!fulfillment.orderFulfillmentStatus) {
			warnings.push("Fulfillment status is required.");
		}
		if (!fulfillment.supplier && !fulfillment.freightCarrier) {
			warnings.push("Pay To is required.");
		}
		if (isNaN(Number(fulfillment.amountDue)) || fulfillment.amountDue === '') {
			warnings.push("Amount Due is required.");
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	formatOrderFulfillmentForPayload(orderFulfillment) {
		const formattedOrderFulfillment = {
			Id: orderFulfillment.id ? orderFulfillment.id : 0,
			OrderId: this.state.orderId,
			OrderFulfillmentStatusId: orderFulfillment.orderFulfillmentStatus ? orderFulfillment.orderFulfillmentStatus.value : orderFulfillment.orderFulfillmentStatusId,
			SupplierId: orderFulfillment.supplier ? orderFulfillment.supplier.value : orderFulfillment.supplierId,
			FreightCarrierId: orderFulfillment.freightCarrier ? orderFulfillment.freightCarrier.value : orderFulfillment.freightCarrierId,
			ReferenceNumber: orderFulfillment.referenceNumber,
			InvoiceNumber: orderFulfillment.invoiceNumber,
			CheckNumber: orderFulfillment.checkNumber,
			AmountDue: Number(orderFulfillment.amountDue),
			AmountPosted: Number(orderFulfillment.amountPosted),
			Notes: orderFulfillment.notes,
			BillDate: orderFulfillment.billDate,
			DueDate: orderFulfillment.dueDate,
			PayDate: orderFulfillment.payDate,
			CreatedAt: moment(),
			CreatedByUserId: this.props.currentUser.id
		};
		return formattedOrderFulfillment;
	}

	saveOrderFulfillment(orderFulfillment) {
		window.scroll(0, 0);
		if (!this.isOrderFulfillmentFormValid(orderFulfillment)) return;
		const payload = this.formatOrderFulfillmentForPayload(orderFulfillment);
		api.post("Order/SaveOrderFulfillment", payload)
			.then(response => {
				if (response.data.success) {
					this.setState({
						orderFulfillment: emptyOrderFulfillment,
						addNewFulfillment: false,
						message: null,
						messageFlavor: null
					}, () => this.getOrderFulfillments(this.state.orderId));
				} else {
					this.setState({ messageFlavor: "danger", message: response.data.message });
				}
			})
			.catch(helpers.catchHandler);
	}

	deleteOrderFulfillment(orderFulfillmentId) {
		if (!window.confirm('This is a permanent delete.  Are you sure?')) return;
		api.post(`Order/DeleteOrderFulfillment/${orderFulfillmentId}`)
			.then(response => {
				this.getOrderFulfillments(this.state.orderId);
			})
			.catch(helpers.catchHandler);
	}

	reviveOrderFulfillment(orderFulfillmentId) {
		api.post(`Order/ReviveOrderFulfillment/${orderFulfillmentId}`)
			.then(response => {
				this.getOrderFulfillments(this.state.orderId);
			})
			.catch(helpers.catchHandler);
	}


	// rug component stuff
	toggleCutCharge() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasCutCharge: !this.state.orderLine.rugHasCutCharge
			}
		})
	}

	toggleArmor() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasEndArmor: !this.state.orderLine.rugHasEndArmor
			}
		})
	}

	toggleInboundFreight() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasInboundFreight: !this.state.orderLine.rugHasInboundFreight
			}
		})
	}

	toggleBacking() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasBacking: !this.state.orderLine.rugHasBacking
			}
		})
	}

	togglePad() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasPad: !this.state.orderLine.rugHasPad
			}
		}, () => {
			this.setRugValidity()
		})
	}

	toggleClippedCorners() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasClippedCorners: !this.state.orderLine.rugHasClippedCorners
			}
		})
	}

	toggleLaborCutout() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasLaborCutout: !this.state.orderLine.rugHasLaborCutout
			}
		})
	}

	toggleLaborRound() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugHasLaborRound: !this.state.orderLine.rugHasLaborRound
			}
		})
	}

	toggleEdgeOptions() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				edgeOptionsOpen: !this.state.orderLine.edgeOptionsOpen
			}
		})
	}

	setRugEdge(rugEdgeName) {
		// refreshRugDescription
		let edgeSuffix;
		if (rugEdgeName === EDGE_TYPES.MITERED) {
			edgeSuffix = 'Serged to Match Background';
		} else {
			edgeSuffix = 'Serged';
		}
		let rugDescription = this.state.orderLine.rugWidthFeet + "\' " + this.state.orderLine.rugWidthInches + "\" x " + this.state.orderLine.rugLengthFeet + "\' " + this.state.orderLine.rugLengthInches + "\" Custom Rug-" + edgeSuffix;
		this.setState({
			selectedRugEdge: rugEdgeName,
			orderLine: {
				...this.state.orderLine,
				rugDsc: rugDescription			// Doesn't work
			}
		}, () => {
			this.setRugValidity();
		})
	}

	// Set rug properties
	async initializeRugOrder() {
		await this.rugSizeChange();
		await this.setSelectedPad(this.state.orderLine.selectedPad);	// Already set to default pad
		const selectedSerging = this.state.sergingList[0];
		const selectedMSerging = this.state.mSergingList[0];
		const selectedHSSerging = this.state.hsSergingList[0];
		this.sergingSelected(selectedSerging);
		this.mSergingSelected(selectedMSerging);
		this.hsSergingSelected(selectedHSSerging);
	}

	async sergingSelected(selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				selectedSerging: selection,
				sergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
				sergingPricePer: _.round(selection.unitPrice, 2).toFixed(2)
			}
		}, () => { this.updateSergingTotals() })
	}

	async updateSergingTotals() {
		const totalSergingPrice = this.state.orderLine.sergingPricePer * this.state.orderLine.finishedEdgeFt;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				sergingCostPer: _.round(this.state.orderLine.sergingCostPer, 2).toFixed(2),
				sergingPricePer: _.round(this.state.orderLine.sergingPricePer, 2).toFixed(2),
				sergingCmsnRate: _.round(this.state.orderLine.sergingCmsnRate, 2).toFixed(2),
				totalSergingPrice: _.round(totalSergingPrice, 2).toFixed(2),
				totalSergingCost: _.round(this.state.orderLine.sergingCostPer * this.state.orderLine.finishedEdgeFt, 2).toFixed(2),
				sergingCmsn: _.round((this.state.orderLine.sergingCmsnRate / 100.0) * totalSergingPrice, 2).toFixed(2)
			}
		})
	}

	async mSergingSelected(selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				selectedMSerging: selection,
				mSergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
				mSergingPricePer: _.round(selection.unitPrice, 2).toFixed(2)
			}
		}, () => { this.updateMSergingTotals() })
	}

	async updateMSergingTotals() {
		const totalMSergingPrice = this.state.orderLine.mSergingPricePer * this.state.orderLine.finishedEdgeFt;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				mSergingCostPer: _.round(this.state.orderLine.mSergingCostPer, 2).toFixed(2),
				mSergingPricePer: _.round(this.state.orderLine.mSergingPricePer, 2).toFixed(2),
				mSergingCmsnRate: _.round(this.state.orderLine.mSergingCmsnRate, 2).toFixed(2),
				totalMSergingPrice: _.round(totalMSergingPrice, 2).toFixed(2),
				totalMSergingCost: _.round(this.state.orderLine.mSergingCostPer * this.state.orderLine.finishedEdgeFt, 2).toFixed(2),
				mSergingCmsn: _.round((this.state.orderLine.mSergingCmsnRate / 100.0) * totalMSergingPrice, 2).toFixed(2)
			}
		})
	}

	async hsSergingSelected(selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				selectedHSSerging: selection,
				hsSergingCostPer: _.round(selection.unitCost, 2).toFixed(2),
				hsSergingPricePer: _.round(selection.unitPrice, 2).toFixed(2)
			}
		}, () => { this.updateHSSergingTotals() })
	}

	updateHSSergingTotals() {
		const totalHSSergingPrice = this.state.orderLine.hsSergingPricePer * this.state.orderLine.finishedEdgeFt;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				hsSergingCostPer: _.round(this.state.orderLine.hsSergingCostPer, 2).toFixed(2),
				hsSergingPricePer: _.round(this.state.orderLine.hsSergingPricePer, 2).toFixed(2),
				hsSergingCmsnRate: _.round(this.state.orderLine.hsSergingCmsnRate, 2).toFixed(2),
				totalHSSergingPrice: _.round(totalHSSergingPrice, 2).toFixed(2),
				totalHSSergingCost: _.round(this.state.orderLine.hsSergingCostPer * this.state.orderLine.finishedEdgeFt, 2).toFixed(2),
				hsSergingCmsn: _.round((this.state.orderLine.hsSergingCmsnRate / 100.0) * totalHSSergingPrice, 2).toFixed(2),
			}
		})
	}

	refreshRugDescription() {
		let edgeSuffix;
		if (this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
			edgeSuffix = 'Serged to Match Background';
		} else {
			edgeSuffix = 'Serged';
		}
		const rugDsc = this.state.orderLine.rugWidthFeet + "\' " + this.state.orderLine.rugWidthInches + "\" x " + this.state.orderLine.rugLengthFeet + "\' " + this.state.orderLine.rugLengthInches + "\" Custom Rug-" + edgeSuffix;
		return rugDsc;
	}

	calculateFieldDsc() {
		let dsc;
		if (this.state.orderLine.selectedField) {
			dsc = calc.getFieldDescription(this.state.orderLine.selectedField.label, this.state.orderLine.selectedFieldColor);   // ${this.state.orderLine.totalFieldUnits ? this.state.orderLine.totalFieldUnits : ''} ${this.state.orderLine.fieldPriceUnitType ? this.state.orderLine.fieldPriceUnitType.label : ''}
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				dsc: dsc
			}
		}, () => 	this.setRugValidity());
	}

	setFinishedRugSize(option) {
			this.setState({
				orderLine: {
					...this.state.orderLine,
					finishedSize: option,
					rugWidthFeet: option.rugWidthFeet,
					rugWidthInches: option.rugWidthInches,
					rugLengthFeet: option.rugLengthFeet,
					rugLengthInches: option.rugLengthInches
				}
			}, () => this.rugSizeChange());
	}

  onChangeAdditionalRugField(line, fieldName, value) {
  	let payload = {
			id: line.id,
			rugStyle: line.rugStyle,
			finishedEdge: line.finishedEdge,
			laborCost: line.laborCost,
			totalAgreedCharges: line.totalAgreedCharges,
			sendScrap: line.sendScrap
		};
		payload[fieldName] = value;
		api.post('order/SaveAdditionalRugLineFields', payload).then(r => {
			if (r.data.success) {
				let newLines = this.state.orderLines.slice();
				const idx = _.findIndex(newLines, ln => ln.id === line.id);
				newLines[idx][fieldName] = value;
				this.setState({orderLines: newLines});
			} else {
				Alert.error(`Failed to save the ${fieldName} value!`);
			}
		}).catch(error => {
			console.error(error);
			Alert.error("There was an error saving the rug line " + fieldName);
		});
  }

  // renderSignOffForm(lineId) {
	// 	if (!this.state.orderId) {
	// 		console.warn("orderId is empty");
	// 		return;
	// 	}
	// 	/*
	// 	the following fields may need to be captured, remembered and defaulted for orderline with a dialog:
	// 		ContactFullName, OverallSize, FinishedEdge, RugCost, PadCost, OtherCost, TotalCost
	// 	*/
	// 	this.setState({ printing: true }, () => {
	// 		const pdfWindow = window.open();
	// 		api.post_get_blob(`order/GetRugSignOffPDF/${this.state.orderId}/${lineId}`, {}).then(r => {
	// 			if (r.status !== 200) {
	// 				throw new Error("Error rendering document");
	// 			}
	// 			const file = new Blob([r.data], { type: "application/pdf" });
	// 			const fileURL = URL.createObjectURL(file);
	// 			pdfWindow.location.href = fileURL;
	// 		}).catch(error => {
	// 			console.log(error);
	// 			Alert.error("There was an error downloading the document");
	// 			pdfWindow.close();
	// 		}).finally(() => this.setState({ printing: false }))
	// 	});
  // }

  renderWorkorderForm(lineId) {
		if (!this.state.orderId) {
			console.warn("orderId is empty");
			return;
		}
		this.setState({ printing: true }, () => {
			const pdfWindow = window.open();
			api.post_get_blob(`order/GetRugWorkOrderPDF/${this.state.orderId}/${lineId}`, {}).then(r => {
				if (r.status !== 200) {
					throw new Error("Error rendering document");
				}
				const file = new Blob([r.data], { type: "application/pdf" });
				const fileURL = URL.createObjectURL(file);
				pdfWindow.location.href = fileURL;
			}).catch(error => {
				console.log(error);
				Alert.error("There was an error downloading the document");
				pdfWindow.close();
			}).finally(() => this.setState({ printing: false }))
		});
  }

	async rugSizeChange() {
		let rugDsc = this.refreshRugDescription();
		let { sqFt, finishedEdgeFt } = calc.calculateSqFeetAndFinEdgeFt(
			this.state.orderLine.rugLengthFeet,
			this.state.orderLine.rugLengthInches,
			this.state.orderLine.rugWidthFeet,
			this.state.orderLine.rugWidthInches);
		let {
			totalRugBackingPrice,
			totalRugBackingCost,
			rugBackingCmsn,
			totalSergingPrice,
			totalSergingCost,
			sergingCmsn,
			totalMSergingPrice,
			totalMSergingCost,
			mSergingCmsn,
			totalHSSergingPrice,
			totalHSSergingCost,
			hsSergingCmsn
		} = calc.calculateRugExtraCosts(this.state.orderLine, sqFt, finishedEdgeFt);
		await this.setFieldDefaultSize();
		await this.defaultPadRequired();
		this.setState({
			orderLine: {
				...this.state.orderLine,
				sqFt: sqFt,
				rugDsc: rugDsc,
				finishedEdgeFt: finishedEdgeFt,
				totalRugBackingUnits: sqFt,
				totalRugBackingPrice: _.round(totalRugBackingPrice, 2).toFixed(2),
				totalRugBackingCost: _.round(totalRugBackingCost, 2).toFixed(2),
				rugBackingCmsn: _.round(rugBackingCmsn, 2).toFixed(2),
				totalSergingPrice: _.round(totalSergingPrice, 2).toFixed(2),
				totalSergingCost: _.round(totalSergingCost, 2).toFixed(2),
				sergingCmsn: _.round(sergingCmsn, 2).toFixed(2),
				totalMSergingPrice: _.round(totalMSergingPrice, 2).toFixed(2),
				totalMSergingCost: _.round(totalMSergingCost, 2).toFixed(2),
				mSergingCmsn: _.round(mSergingCmsn, 2).toFixed(2),
				totalHSSergingPrice: _.round(totalHSSergingPrice, 2).toFixed(2),
				totalHSSergingCost: _.round(totalHSSergingCost, 2).toFixed(2),
				hsSergingCmsn: _.round(hsSergingCmsn, 2).toFixed(2)
			}
		})
	}

	async setSelectedField(option) {
		await api.fetch(`Order/GetActiveProductColors/${option.value}`)
			.then(response => {
				this.setState({
					productColorsList: response.data,
					orderLine: {
						...this.state.orderLine,
						selectedFieldColor: response.data.length > 0 ? response.data[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		await api.fetch(`Product/GetProductWidths/${option.value}`)
			.then(response => {
				const widths = _.map(response.data, w => {
					return {
						value: w.widthId,
						label: w.widthName,
						widthNumericFeet: w.widthNumericFeet
					}
				});
				this.setState({
					orderLine: {
						...this.state.orderLine,
						widthsList: widths,
						selectedFieldWidth: widths.length > 0 ? widths[0] : null
						//width: widths.length > 0 ? widths[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		await	api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderLine: {
						...this.state.orderLine,
						selectedField: option,
						fieldCostPer: _.round(response.data.unitCost, 2).toFixed(2),
						fieldPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
						fieldRepeatWidth: _.round(response.data.repeatWidth, 3).toFixed(3),
						fieldRepeatLength: _.round(response.data.repeatLength, 3).toFixed(3),
						fieldPriceUnitType: helpers.resolveValue(
							null,
							response.data.priceUnitTypeId,
							response.data.priceUnitTypeName),
						repeatDsc: _.round(response.data.repeatWidth, 3).toFixed(3) + ' x ' + _.round(response.data.repeatLength, 3).toFixed(3),
						fieldRun: response.data.run,
						productAllowCommission: response.data.allowCommission,
					}
				}, this.setFieldDefaultSize);
			}).catch(helpers.catchHandler);
	}

	async setFieldDefaultSize() {
		let orderLine = Object.assign({}, this.state.orderLine);
		console.log('orderLine', orderLine);
		const {
			selectedField,
			fieldRepeatLength,
			fieldRepeatWidth	
		} = orderLine;
		if (orderLine && orderLine.selectedField) {
			const payload = {
				CustomWidth: Number(parseFloat(orderLine.rugWidthFeet) + parseFloat(orderLine.rugWidthInches / 12.0)),
				CustomLength: Number(parseFloat(orderLine.rugLengthFeet) + parseFloat(orderLine.rugLengthInches / 12.0)),
				ProductId: Number(orderLine.selectedField.value)
			}
			let resp = await calc.getDefaultFieldSize(payload, selectedField, fieldRepeatLength, fieldRepeatWidth);
			this.applyDefaultSizes(resp);
		}
	}

	applyDefaultSizes(results) {
		const {
		 appliedFieldRepeat, fieldLengthFeet, fieldLengthInches, widthFeetName
		} = results;
		var width = _.find(this.state.orderLine.widthsList, w => { return w.label === widthFeetName });
		this.setState({
			orderLine: {
				...this.state.orderLine,
				appliedFieldRepeat: appliedFieldRepeat,
				fieldLengthFeet: fieldLengthFeet,
				fieldLengthInches: fieldLengthInches ? fieldLengthInches : 0,
				selectedFieldWidth: width
			}
		}, this.fieldSizeChange);
	}

	fieldSizeChange() {
	  let totalFieldUnits;
	  if (this.state.orderLine && this.state.orderLine.selectedFieldWidth) {
			totalFieldUnits = calc.calculateFieldUnits(
			  this.state.orderLine.selectedFieldWidth.widthNumericFeet,
			  this.state.orderLine.fieldLengthFeet,
			  this.state.orderLine.fieldLengthInches,
			  this.state.orderLine.fieldPriceUnitType && this.state.orderLine.fieldPriceUnitType.label
			);
	  }
	  if (totalFieldUnits) {
			this.setState({
			  orderLine: {
					...this.state.orderLine,
					totalFieldUnits: totalFieldUnits
			  }
			}, this.updateFieldTotals);
	  }
	}

	updateFieldTotals() {
		let totalFieldPrice = this.state.orderLine.fieldPricePer * this.state.orderLine.totalFieldUnits;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				fieldCostPer: _.round(this.state.orderLine.fieldCostPer, 2).toFixed(2),
				fieldPricePer: _.round(this.state.orderLine.fieldPricePer, 2).toFixed(2),
				fieldCmsnRate: _.round(this.state.orderLine.fieldCmsnRate, 2).toFixed(2),
				totalFieldPrice: _.round(totalFieldPrice, 2).toFixed(2),
				totalFieldCost: _.round(this.state.orderLine.fieldCostPer * this.state.orderLine.totalFieldUnits, 2).toFixed(2),
				fieldCmsn: _.round((this.state.orderLine.fieldCmsnRate / 100.0) * totalFieldPrice, 2).toFixed(2)
			}
		}, this.calculateFieldDsc);
	}

	async updateRugBackingTotals() {
		let totalRugBackingPrice = this.state.orderLine.rugBackingPricePer * this.state.orderLine.totalRugBackingUnits;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugBackingCostPer: _.round(this.state.orderLine.rugBackingCostPer, 2).toFixed(2),
				rugBackingPricePer: _.round(this.state.orderLine.rugBackingPricePer, 2).toFixed(2),
				rugBackingCmsnRate: _.round(this.state.orderLine.rugBackingCmsnRate, 2).toFixed(2),
				totalRugBackingPrice: _.round(totalRugBackingPrice, 2).toFixed(2),
				totalRugBackingCost: _.round(this.state.orderLine.rugBackingCostPer * this.state.orderLine.totalRugBackingUnits, 2).toFixed(2),
				rugBackingCmsn: _.round((this.state.orderLine.rugBackingCmsnRate / 100.0) * totalRugBackingPrice, 2).toFixed(2)
			}
		});
	}

	setSelectedPad(option) {
		api.fetch(`Product/GetProductWidths/${option.value}`).then(response => {
				const widths = _.map(response.data, w => {
					return {
						value: w.widthId,
						label: w.widthName,
						widthNumericFeet: w.widthNumericFeet
					}
				});
				this.setState({
					padWidthList: widths,
					orderLine: {
						...this.state.orderLine,
						selectedPadWidth: widths.length > 0 ? widths[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderLine: {
						...this.state.orderLine,
						selectedPad: option,
						padCostPer: _.round(response.data.unitCost, 2).toFixed(2),
						padPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
						padPriceUnitType: helpers.resolveValue(
							null,
							response.data.priceUnitTypeId,
							response.data.priceUnitTypeName)
					}
				}, () => {
					this.defaultPadRequired();
				});
			})
			.catch(helpers.catchHandler);
	}

	async defaultPadRequired() {
		// default pad length to meet sq ft requirements
		let padLengthFeet = "0";
		if (this.state.orderLine.selectedPadWidth) {
			let dimA = (this.state.orderLine.rugWidthInches / 12.0) + parseFloat(this.state.orderLine.rugWidthFeet.toString());
			let dimB = (this.state.orderLine.rugLengthInches / 12.0) + parseFloat(this.state.orderLine.rugLengthFeet.toString());
			let longer = dimA > dimB ? dimA : dimB;
			let shorter = dimA > dimB ? dimB : dimA;
			// let minLen = this.sqFt / this.selectedPadWidth.widthNumericFeet;
			if (longer <= this.state.orderLine.selectedPadWidth.widthNumericFeet) {
				padLengthFeet = Math.ceil(shorter);
			} else {
				padLengthFeet = Math.ceil(longer);
			}
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				padLengthFeet: padLengthFeet
			}
		}, () => this.padSizeChange())
	}


	padSizeChange() {
		let totalPadUnits;
		let message;
		let messageFlavor;
		if (!this.state.orderLine.selectedPadWidth || !this.state.orderLine.padLengthFeet) return;
		let lngth = parseFloat(this.state.orderLine.padLengthFeet.toString());
		if (isNaN(lngth)) return;
		let widthNumericFeet = parseFloat(this.state.orderLine.selectedPadWidth.widthNumericFeet.toString());
		if (this.state.orderLine.padPriceUnitType.label === "Sq Yds") {
			let sqYds = ((widthNumericFeet / 3.0) * lngth) / 3.0;
			if (isNaN(sqYds)) return;
			totalPadUnits = sqYds;
		} else if (this.state.orderLine.padPriceUnitType.label === "Sq Ft") {
			totalPadUnits = widthNumericFeet * lngth;
		} else {
			totalPadUnits = 0.0;
			message = "Cannot calculate required material for this pad's price unit: " + this.state.orderLine.padPriceUnitType.label + ". Unknown Price Units";
			messageFlavor = "danger";
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				totalPadUnits: _.round(totalPadUnits, 2).toFixed(2),
			},
			message: message,
			messageFlavor: messageFlavor
		}, () => this.updatePadTotals())
	}

	updatePadTotals() {
		let totalPadPrice = this.state.orderLine.padPricePer * this.state.orderLine.totalPadUnits;
		let padCutCost;
		let padCutPrice;

		if ( (parseFloat(this.state.orderLine.totalPadUnits) <= 30 && this.state.orderLine.padPriceUnitType.value == constants.PRICE_UNIT_TYPE.SQ_FT.value)
					|| (parseFloat(this.state.orderLine.totalPadUnits) <= 30/9 && this.state.orderLine.padPriceUnitType.value == constants.PRICE_UNIT_TYPE.SQ_YARDS.value) ) {
			padCutCost = this.state.customRugComponentProducts.Under30SFPadCutChargeProduct.unitCost
			padCutPrice = this.state.customRugComponentProducts.Under30SFPadCutChargeProduct.unitPrice
		} else {
			padCutCost = this.state.customRugComponentProducts.DefaultPadCutChargeProduct.unitCost
			padCutPrice = this.state.customRugComponentProducts.DefaultPadCutChargeProduct.unitPrice
		}

		this.setState({
			orderLine: {
				...this.state.orderLine,
				padCostPer: _.round(this.state.orderLine.padCostPer, 2).toFixed(2),
				padPricePer: _.round(this.state.orderLine.padPricePer, 2).toFixed(2),
				padCmsnRate: _.round(this.state.orderLine.padCmsnRate, 2).toFixed(2),
				totalPadPrice: _.round(totalPadPrice, 2).toFixed(2),
				totalPadCost: _.round(this.state.orderLine.padCostPer * this.state.orderLine.totalPadUnits, 2).toFixed(2),
				padCmsn: _.round((this.state.orderLine.padCmsnRate / 100.0) * totalPadPrice, 2).toFixed(2), 
				padCutCost: _.round(padCutCost, 2).toFixed(2),
				padCutPrice: _.round(padCutPrice, 2).toFixed(2)
			}
		}, () => {
			this.setRugValidity();
		})
	}

	setSelectedInboundFreight(option) {
		api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderLine: {
						...this.state.orderLine,
						selectedInboundFreight: option,
						rugInboundFreightCost: _.round(response.data.unitCost, 2).toFixed(2),
						rugInboundFreightPrice: _.round(response.data.unitPrice, 2).toFixed(2)
					}
				});
			})
			.catch(helpers.catchHandler);
	}

	// rugBindingStyleSelected in original app
	setSelectedRugBindingStyle(option) {
		api.fetch(`Order/GetActiveProductColors/${option.value}`)
			.then(response => {
				this.setState({
					rugBindingColorList: response.data,
					orderLine: {
						...this.state.orderLine,
						selectedRugBindingColor: response.data.length > 0 ? response.data[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderLine: {
						...this.state.orderLine,
						selectedRugBindingStyle: option,
						rugBindingCostPer: _.round(response.data.unitCost, 2).toFixed(2),
						rugBindingPricePer: _.round(response.data.unitPrice, 2).toFixed(2)
					}
				}, () => {
					this.updateRugBindingTotals();
				});
			})
			.catch(helpers.catchHandler);
	}

	updateRugBindingTotals() {
		let totalRugBindingPrice = this.state.orderLine.rugBindingPricePer * this.state.orderLine.finishedEdgeFt;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugBindingCostPer: _.round(this.state.orderLine.rugBindingCostPer, 2).toFixed(2),
				rugBindingPricePer: _.round(this.state.orderLine.rugBindingPricePer, 2).toFixed(2),
				rugBindingCmsnRate: _.round(this.state.orderLine.rugBindingCmsnRate, 2).toFixed(2),
				totalRugBindingPrice: _.round(totalRugBindingPrice, 2).toFixed(2),
				totalRugBindingCost: _.round(this.state.orderLine.rugBindingCostPer * this.state.orderLine.finishedEdgeFt, 2).toFixed(2),
				rugBindingCmsn: _.round((this.state.orderLine.rugBindingCmsnRate / 100.0) * totalRugBindingPrice, 2).toFixed(2)
			}
		}, () => {
			this.setRugValidity();
		});
	}

	handleRugOrderLineStateChange(elementName, value) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: value
			}
		});
	}

	handleRugFormSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection,
			}
		}, () => {
			if (elementName === "selectedFieldColor") {
				this.calculateFieldDsc();
			}
			if (elementName === "selectedBorderColor") {
				this.calculateBorderDsc();
			}
		});
	}

	handleFieldWidthSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection
			}
		}, () => {
			this.fieldSizeChange();
		});
	}

	handlePadWidthSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection
			}
		}, () => {
			this.defaultPadRequired();
		});
	}

	onHasPadCutChargeChange() {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				hasPadCutCharge: !this.state.orderLine.hasPadCutCharge
			}
		});
	}

	async setSelectedBorder(option) {
		await api.fetch(`Order/GetActiveProductColors/${option.value}`)
			.then(response => {
				this.setState({
					borderColorList: response.data,
					orderLine: {
						...this.state.orderLine,
						selectedBorderColor: response.data.length > 0 ? response.data[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		await api.fetch(`Product/GetProductWidths/${option.value}`)
			.then(response => {
				const widths = _.map(response.data, w => {
					return {
						value: w.widthId,
						label: w.widthName,
						widthNumericFeet: w.widthNumericFeet
					}
				});
				this.setState({
					orderLine: {
						...this.state.orderLine,
						widthsList: widths,
						selectedBorderWidth: widths.length > 0 ? widths[0] : null
					}
				});
			})
			.catch(helpers.catchHandler);

		await	api.fetch(`Product/GetProductWithOrder/${option.value}/${this.state.orderId}`)
			.then(response => {
				this.setState({
					orderLine: {
						...this.state.orderLine,
						selectedBorder: option,
						borderCostPer: _.round(response.data.unitCost, 2).toFixed(2),
						borderPricePer: _.round(response.data.unitPrice, 2).toFixed(2),
						// borderRepeatWidth: _.round(response.data.repeatWidth, 3).toFixed(3),
						// borderRepeatLength: _.round(response.data.repeatLength, 3).toFixed(3),
						borderPriceUnitType: helpers.resolveValue(
							null,
							response.data.priceUnitTypeId,
							response.data.priceUnitTypeName),
						// repeatDsc: _.round(response.data.repeatWidth, 3).toFixed(3) + ' x ' + _.round(response.data.repeatLength, 3).toFixed(3),
						// fieldRun: response.data.run,
						productAllowCommission: response.data.allowCommission,
					}
				}, () => {
					this.borderSizeChange();
				});
			})
			.catch(helpers.catchHandler);
	}

	calculateBorderDsc() {
		let dsc;
		if (this.state.orderLine.selectedBorder) {
			// dsc = `Field (A): ${this.state.orderLine.totalFieldUnits ? this.state.orderLine.totalFieldUnits : ''} ${this.state.orderLine.fieldPriceUnitType ? this.state.orderLine.fieldPriceUnitType.label : ''} ${this.state.orderLine.selectedField ? this.state.orderLine.selectedField.label : ''} ${this.state.orderLine.selectedFieldColor ? this.state.orderLine.selectedFieldColor.label : ''}`
			dsc = `${this.state.orderLine.borderLetter ? this.state.orderLine.borderLetter + ":" : ""} ${this.state.orderLine.selectedBorder ? this.state.orderLine.selectedBorder.label : ""} ${this.state.orderLine.selectedBorderColor ? ", " + this.state.orderLine.selectedBorderColor.label : ''}`
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				dsc: dsc
			}
		}, () => 	this.setRugValidity());
	}

	handleBorderWidthSelectChange(elementName, selection) {
		this.setState({
			orderLine: {
				...this.state.orderLine,
				[elementName]: selection
			}
		}, () => {
			this.borderSizeChange();
		});
	}

	borderSizeChange() {
		let totalBorderUnits;
		if (this.state.orderLine && this.state.orderLine.selectedBorderWidth) {
			let lngth = 0;
			if ((this.state.orderLine.borderLengthFeet !== '' || this.state.orderLine.borderLengthFeet !== null)
				&& (this.state.orderLine.borderLengthInches !== "" || this.state.orderLine.borderLengthInches !== null)) {
				lngth = parseFloat(this.state.orderLine.borderLengthFeet.toString()) + (parseFloat(this.state.orderLine.borderLengthInches.toString()) / 12.0)
			}
			if (lngth === 0) {this.calculateBorderDsc()};
			let widthNumericFeet = 0;
			//if (this.state.orderLine && this.state.orderLine.selectedBorderWidth) {
				widthNumericFeet = this.state.orderLine.selectedBorderWidth.widthNumericFeet;
			//}
			if (this.state.orderLine.borderPriceUnitType && this.state.orderLine.borderPriceUnitType.label === "Sq Yds") {
				let sqYds = ((widthNumericFeet / 3.0) * lngth) / 3.0;
				if (isNaN(sqYds)) {this.calculateBorderDsc()};
				totalBorderUnits = sqYds;
			} else if (this.state.orderLine.borderPriceUnitType && this.state.orderLine.borderPriceUnitType.label === "Sq Ft") {
				totalBorderUnits = widthNumericFeet * lngth
			} else {
				totalBorderUnits = "0.0"
				// message = "Cannot calculate required material for this price unit: " + this.state.orderLine.priceUnitType.label + ". Unknown Price Units",
				// messageFlavor = "danger"
			}
		}

		this.setState({
			orderLine: {
				...this.state.orderLine,
				totalBorderUnits: _.round(totalBorderUnits, 3).toFixed(3)
			}
		}, () => {
			this.updateBorderTotals()
		})
	}

	updateBorderTotals() {
		let totalBorderPrice = this.state.orderLine.borderPricePer * this.state.orderLine.totalBorderUnits;
		this.setState({
			orderLine: {
				...this.state.orderLine,
				borderCostPer: _.round(this.state.orderLine.borderCostPer, 2).toFixed(2),
				borderPricePer: _.round(this.state.orderLine.borderPricePer, 2).toFixed(2),
				borderCmsnRate: _.round(this.state.orderLine.borderCmsnRate, 2).toFixed(2),
				totalBorderPrice: _.round(totalBorderPrice, 2).toFixed(2),
				totalBorderCost: _.round(this.state.orderLine.borderCostPer * this.state.orderLine.totalBorderUnits, 2).toFixed(2),
				borderCmsn: _.round((this.state.orderLine.borderCmsnRate / 100.0) * totalBorderPrice, 2).toFixed(2)
			}
		}, () => {
			this.calculateBorderDsc();
		})
	}

	updateCustomRug(customRugOrderLine) {
		window.scroll(0, 0);
		this.setState({
			showOrderLineForm: false,
			customRugToViewEdit: customRugOrderLine
		});
	}

	returnToMainOrderLineScreen() {
		window.scroll(0, 0);
		this.setState({
			showOrderLineForm: false,
			customRugToViewEdit: null
		}, () => this.cancelAddOrUpdateOrderLine());
	}

	isCustomRugOrderLineFormValid() {  // quotes only -- angular app
		const warnings = [];
		if (this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value) {
			if (!this.state.orderLine.rugDsc) {
				warnings.push("Rug Description is required.");
			}
			if (!this.state.orderLine.rugWidthFeet) {
				warnings.push("Rug Width Feet is required.");
			}
			if (!this.state.orderLine.rugWidthInches) {
				warnings.push("Rug Width Inches is required.");
			}
			if (!this.state.orderLine.rugLengthFeet) {
				warnings.push("Rug Length Feet is required.");
			}
			if (!this.state.orderLine.rugLengthInches) {
				warnings.push("Rug Length Inches required.");
			}
			if (!this.state.orderLine.selectedField) {
				warnings.push("Field is required.");
			}
			// if (!this.state.orderLine.selectedFieldColor) {
			// 	warnings.push("Field Color is required.");
			// }
			if (!this.state.orderLine.selectedFieldWidth) {
				warnings.push("Field Width is required.");
			}
			if (!this.state.orderLine.fieldLengthFeet) {
				warnings.push("Field Length Feet is required.");
			}
			if (!this.state.orderLine.fieldLengthInches && !this.state.fieldInches === 0) {
				//zero is a valid number of inches
				warnings.push("Field Length Inches is required.");
			}
			if (!this.state.orderLine.fieldPricePer) {
				warnings.push("Field Unit Price is required.");
			}
			if (!this.state.orderLine.fieldCmsnRate) {
				warnings.push("Field Commission Rate is required.");
			}
			if (this.state.orderLine.rugHasCutCharge) {
				if (!this.state.orderLine.rugCutChargeCost) {
					warnings.push("Cut Charge Cost is required.");
				}
				if (!this.state.orderLine.rugCutChargePrice) {
					warnings.push("Cut Charge Price is required.");
				}
			}
			if (this.state.orderLine.rugHasEndArmor) {
				if (!this.state.orderLine.rugArmorCost) {
					warnings.push("End Armor Cost is required.");
				}
				if (!this.state.orderLine.rugArmorPrice) {
					warnings.push("End Armor Price is required.");
				}
			}
			if (this.state.orderLine.rugHasInboundFreight) {
				if (!this.state.orderLine.rugInboundFreightCost) {
					warnings.push("Inbound Freight Cost is required.");
				}
				if (!this.state.orderLine.rugInboundFreightCost) {
					warnings.push("Inbound Freight Price is required.");
				}
			}
			if (this.state.orderLine.rugHasBacking) {
				if (!this.state.orderLine.totalRugBackingUnits) {
					warnings.push("Backing Sq Ft is required.");
				}
				if (!this.state.orderLine.rugBackingPricePer) {
					warnings.push("Backing Unit Price is required.");
				}
				if (!this.state.orderLine.rugBackingCmsnRate) {
					warnings.push("Backing Cmsn % is required.");
				}
			}
			if (this.state.orderLine.rugHasPad && this.state.orderLine.hasPadCutCharge) {
				if (!this.state.orderLine.padCutCost) {
					warnings.push("Pad Cut Charge Cost is required.");
				}
				if (!this.state.orderLine.padCutPrice) {
					warnings.push("Pad Cut Charge Price is required.");
				}
			}
			if (this.state.orderLine.rugHasClippedCorners) {
				if (!this.state.orderLine.rugClippedCornersCost) {
					warnings.push("Clipped Corners Cost is required.");
				}
				if (!this.state.orderLine.rugClippedCornersPrice) {
					warnings.push("Clipped Corners Price is required.");
				}
			}
			if (this.state.orderLine.rugHasLaborCutout) {
				if (!this.state.orderLine.rugLaborCutoutCost) {
					warnings.push("Cutout Labor Cost is required.");
				}
				if (!this.state.orderLine.rugLaborCutoutPrice) {
					warnings.push("Cutout Labor Price is required.");
				}
			}
			if (this.state.orderLine.rugHasLaborRound) {
				if (!this.state.orderLine.rugLaborRoundCost) {
					warnings.push("Round/Oval Labor Cost is required.");
				}
				if (!this.state.orderLine.rugLaborRoundPrice) {
					warnings.push("Round/Oval Labor Price is required.");
				}
			}
			if (this.state.orderLine.rugHasPad) {
				if (!this.state.orderLine.selectedPad) {
					warnings.push("Please select a pad or remove pad.");
				}
				if (!this.state.orderLine.selectedPadWidth) {
					warnings.push("Pad Width is required.");
				}
				if (!this.state.orderLine.padLengthFeet) {
					warnings.push("Pad Length Feet is required.");
				}
				if (!this.state.orderLine.padPricePer) {
					warnings.push("Pad Unit Price is required.");
				}
				if (!this.state.orderLine.padCmsnRate) {
					warnings.push("Pad Commission Rate is required.");
				}
			}
			if (this.state.selectedRugEdge === EDGE_TYPES.MACHINED) {
				if (!this.state.orderLine.selectedMSerging) {
					warnings.push("Edge Style is required.");
				}
				if (!this.state.orderLine.mSergingPricePer) {
					warnings.push("Edge Unit Price is required.");
				}
				if (!this.state.orderLine.mSergingCmsnRate) {
					warnings.push("Edge Commission Rate is required.");
				}
			} else if (this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
				if (!this.state.orderLine.selectedHSSerging) {
					warnings.push("Edge Style is required.");
				}
				if (!this.state.orderLine.hsSergingPricePer) {
					warnings.push("Edge Unit Price is required.");
				}
				if (!this.state.orderLine.hsSergingCmsnRate) {
					warnings.push("Edge Commission Rate is required.");
				}
			} else {
				if (!this.state.orderLine.selectedSerging) {
					warnings.push("Edge Style is required.");
				}
				if (!this.state.orderLine.sergingPricePer) {
					warnings.push("Edge Unit Price is required.");
				}
				if (!this.state.orderLine.sergingCmsnRate) {
					warnings.push("Edge Commission Rate is required.");
				}
			}
			if (this.state.selectedRugEdge === EDGE_TYPES.MACHINED
				|| this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
				if (!this.state.orderLine.selectedRugBindingStyle) {
					warnings.push("Binding Style is required.");
				}
				if (!this.state.orderLine.selectedRugBindingColor) {
					warnings.push("Binding Color is required.");
				}
				if (!this.state.orderLine.rugBindingPricePer) {
					warnings.push("Binding Unit Price is required.");
				}
				if (!this.state.orderLine.rugBindingCmsnRate) {
					warnings.push("Field Commission Rate is required.");
				}
			}
			if (this.state.editingFreight) {
				if (!this.state.orderLine.freightDsc) {
					warnings.push("Freight Description is required.");
				}
				if (!this.state.orderLine.freightCarrier) {
					warnings.push("Freight Carrier is required.");
				}
				// if (!this.state.orderLine.freightTerms) {
				//     warnings.push("Freight Terms is required.");
				// }
				// if (!this.state.orderLine.freightSidemark) {
				//     warnings.push("Freight Sidemark is required.");
				// }
				// if (!this.state.orderLine.freightInvoiceNumber) {
				//     warnings.push("Freight Invoice Number is required.");
				// }
				// if (!this.state.orderLine.freightBolNumber) {
				//     warnings.push("Freigh Bol Number is required.");
				// }
				if (!this.state.orderLine.freightCost) {
					warnings.push("Freight Cost is required.");
				}
				if (!this.state.orderLine.freightPrice) {
					warnings.push("Freight Price is required.");
				}
			}
		}
		if (warnings.length) {
			this.setState({ message: warnings.join(' '), messageFlavor: "danger" });
		}
		return warnings.length === 0;
	}

	saveCustomRugOrderLine() {
		window.scroll(0, 0);
		if (!this.isCustomRugOrderLineFormValid()) return;
		setTimeout(() => {
			let componentLines = [];
			componentLines.push(
				this.parseFieldLine())
			if (this.state.orderLine.rugHasCutCharge) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugCutChargeCost, this.state.orderLine.rugCutChargeCost, this.state.orderLine.rugCutChargePrice, this.state.orderLine.rugCutChargePrice, 0, 0, "1 X Cut Charge", this.state.customRugComponentProducts.DefaultCutChargeProduct.value, 1));  // 1133 // was -1 //supplierProductId = -1?
			}
			if (this.state.orderLine.rugHasEndArmor) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugArmorCost, this.state.orderLine.rugArmorCost, this.state.orderLine.rugArmorPrice, this.state.orderLine.rugArmorPrice, 0, 0, "1 X 8 Rug Ends Armor Charge", this.state.customRugComponentProducts.DefaultRugEndArmorProduct.value, 1));  // 3907 // was 4025 //legacy supplierProductId = 4649
			}
			if (this.state.orderLine.rugHasInboundFreight) {
				componentLines.push(
					this.parseMiscLine(
						this.state.orderLine.rugInboundFreightCost, 
						this.state.orderLine.rugInboundFreightCost, 
						this.state.orderLine.rugInboundFreightPrice, 
						this.state.orderLine.rugInboundFreightPrice, 
						0, 
						0, 
						`1 X ${this.state.orderLine.selectedInboundFreight.label}`,	
						this.state.orderLine.selectedInboundFreight.value,	// this.state.customRugComponentProducts.DefaultInboundFreightProduct.value, 
						1
					)
				); //2530 // was 2645 //legacy supplierProductId = 3070
			}
			if (this.state.orderLine.rugHasBacking) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugBackingCostPer, this.state.orderLine.totalRugBackingCost, this.state.orderLine.rugBackingPricePer, this.state.orderLine.totalRugBackingPrice, this.state.orderLine.rugBackingCmsn, this.state.orderLine.rugBackingCmsnRate, `${this.state.orderLine.totalRugBackingUnits} X 1 Rug Backing Custom Rug`, this.state.customRugComponentProducts.DefaultBackingProduct.value, this.state.orderLine.totalRugBackingUnits)); // 2391 // was 2505 //legacy supplierProductId = 2908
			}
			if (this.state.orderLine.rugHasPad && this.state.orderLine.hasPadCutCharge) {
				componentLines.push(
					this.parseMiscLine(
						this.state.orderLine.padCutCost,
						this.state.orderLine.padCutCost,
						this.state.orderLine.padCutPrice,
						this.state.orderLine.padCutPrice,
						0,
						0,
						"1 X 6 Rug Pad Cut Fee Over 31SF",
						(parseFloat(this.state.orderLine.totalPadUnits) <= 30 && this.state.orderLine.padPriceUnitType.value == constants.PRICE_UNIT_TYPE.SQ_FT.value)
							|| (parseFloat(this.state.orderLine.totalPadUnits) <= 30/9 && this.state.orderLine.padPriceUnitType.value == constants.PRICE_UNIT_TYPE.SQ_YARDS.value)
								 ? this.state.customRugComponentProducts.Under30SFPadCutChargeProduct.value
								 : this.state.customRugComponentProducts.DefaultPadCutChargeProduct.value,
						1
					)
				); // 2384 // was 2498 //legacy supplierProductId = 2901
			}
			if (this.state.orderLine.rugHasClippedCorners) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugClippedCornersCost, this.state.orderLine.rugClippedCornersCost, this.state.orderLine.rugClippedCornersPrice, this.state.orderLine.rugClippedCornersPrice, 0, 0, "1 X Applied (1) Clipped Corner", this.state.customRugComponentProducts.DefaultClippedCornersProduct.value, 1)); // 2573 // was 2688 //legacy supplierProductId = 3116
			}
			if (this.state.orderLine.rugHasLaborCutout) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugLaborCutoutCost, this.state.orderLine.rugLaborCutoutCost, this.state.orderLine.rugLaborCutoutPrice, this.state.orderLine.rugLaborCutoutPrice, 0, 0, "1 X Applied Labor Cutout/ Fireplace", this.state.customRugComponentProducts.DefaultLaborCutoutProduct.value, 1)); //2581 // was 2696 //legacy supplierProductId = 3127
			}
			if (this.state.orderLine.rugHasLaborRound) {
				componentLines.push(
					this.parseMiscLine(this.state.orderLine.rugLaborRoundCost, this.state.orderLine.rugLaborRoundCost, this.state.orderLine.rugLaborRoundPrice, this.state.orderLine.rugLaborRoundPrice, 0, 0, "1 X Applied Labor Round/Oval/Octagon shape", this.state.customRugComponentProducts.DefaultLaborRoundProduct.value, 1)); //2574 // was 2689 //legacy supplierProductId = 3117
			}
			if (this.state.orderLine.finishedEdgeFt < 15.0) {
				componentLines.push(
					this.parseMiscLine(
						this.state.customRugComponentProducts.DefaultFabricationChargeProduct.unitCost,
						this.state.customRugComponentProducts.DefaultFabricationChargeProduct.unitCost,
						this.state.customRugComponentProducts.DefaultFabricationChargeProduct.unitPrice,
						this.state.customRugComponentProducts.DefaultFabricationChargeProduct.unitPrice,
						0,
						0,
						"xFabrication Charge minn",
						this.state.customRugComponentProducts.DefaultFabricationChargeProduct.value,
						1
					)
				); //2535 //legacy supplierProductId = 3075
			}
			componentLines.push(this.parseEdgeLine());

			// let rugShapeId = this.state.orderLine.rugHasLaborRound 
			// 	? rugShapeId = 1  // 1 - Round Oval
			// 	: rugShapeId = 2; // 2 - Rectangular
			// _.forEach(componentLines, cl => {
			// 	cl.OrderLine = Object.assign({}, {...cl.OrderLine, RugShapeId: rugShapeId});
			// })
			let padOrderLine;
			let bindingOrderLine;
			let freightOrderLine;
			if (this.state.orderLine.rugHasPad) {
				padOrderLine = this.parsePadLine();
			}
			if (this.state.selectedRugEdge === EDGE_TYPES.MACHINED
				|| this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
				bindingOrderLine = this.parseBindingLine();
			}
			if (this.state.editingFreight) {
				freightOrderLine = this.formatFreightOrderLineForDto(this.state.orderLine);
			}
			const orderLineComments = this.formatOrderLineCommentsForDto(this.state.orderLineCommentList, this.state.orderLine.id);
			const rugOrderLine = {
				OrderLine: {
					Id: this.state.orderLine.id,
					OrderId: this.state.orderId,
					ParentOrderLineId: null,
					OrderLineTypeId: constants.ORDER_LINE_TYPE.CUSTOM_RUG.value, //this.state.orderLine.orderLineType.value,
					Dsc: this.state.orderLine.rugDsc,
					IsHidden: false,
					CreatedAt: moment(),
					CreatedByUserId: this.props.currentUser.id,
					CmsnRate: 0,
					Cmsn: 0,
					UnitCost: 0,
					PriceUnitTypeId: constants.PRICE_UNIT_TYPE.EACH.value,
					UnitPrice: 0,
					RugLengthFeet: Number(this.state.orderLine.rugLengthFeet),
					RugLengthInches: Number(this.state.orderLine.rugLengthInches),
					RugWidthFeet: Number(this.state.orderLine.rugWidthFeet),
					RugWidthInches: Number(this.state.orderLine.rugWidthInches),
					FinishedSize: this.state.orderLine.finishedSize.label,
					RugShapeId: this.state.orderLine.rugShape 
						? this.state.orderLine.rugShape.value 
						: null,
					ForOutdoorUse: this.state.orderLine.forOutdoorUse
				},
				FreightOrderLine: freightOrderLine,
				OrderLineComments: orderLineComments
			};
			const payload = {
				RugOrderLine: rugOrderLine,
				ComponentLines: componentLines,
				PadOrderLine: padOrderLine ? padOrderLine : null,
				BindingOrderLine: bindingOrderLine ? bindingOrderLine : null
			};
			api.post("Order/SaveCustomRugOrderLine", payload)
				.then(response => {
					if (response.data.success) {
						this.getOrderLines(this.state.orderId);
						this.getOrderFulfillments(this.state.orderId);
						// this.getOrderTotals(this.state.orderLIn)
						this.cancelAddOrUpdateOrderLine();
					} else {
						this.setState({ messageFlavor: "danger", message: response.data.message });
					}
				})
				.catch(helpers.catchHandler);
		}, 300);
	}

	parseFieldLine() {
		console.log("Orderline on state", this.state.orderLine);
		const fieldLine = {
			OrderLine: {
				Id: this.state.orderLine.id ? this.state.orderLine.id : 0,
				OrderId: this.state.orderId,
				ParentOrderLineId: this.state.orderLine.parentOrderLineId ? this.state.orderLine.parentOrderLineId : null,  // rugOrderLineId  (added on backend after parent custom rug has been created)
				OrderLineTypeId: constants.ORDER_LINE_TYPE.RUG_FIELD.value,
				CreatedAt: this.state.orderLine.createdAt ? this.state.orderLine.createdAt : moment(),
				CreatedByUserId: this.props.currentUser.id,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.fieldCmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.fieldCmsn) : 0,
				UnitCost: Number(this.state.orderLine.fieldCostPer),
				Cost: Number(this.state.orderLine.totalFieldCost),   // Cost: Math.round(this.state.orderLine.totalFieldCost * 100) / 100,
				Dsc: this.state.orderLine.dsc ? this.state.orderLine.dsc : calc.getFieldDescription(this.state.orderLine.selectedField.label, this.state.orderLine.selectedFieldColor),  // rug quote does not allow dsc to be entered, so default
				IsHidden: true,
				UnitPrice: Number(this.state.orderLine.fieldPricePer),
				Price: Number(this.state.orderLine.totalFieldPrice),   // Price: Math.round(this.state.orderLine.totalFieldPrice * 100) / 100,
				PriceUnitTypeId: this.state.orderLine.fieldPriceUnitType.value,
				Qty: Number(this.state.orderLine.totalFieldUnits),      // SquareYards: Math.round(this.state.orderLine.totalFieldUnits * 100) / 100,
				OrderLineStatusId: this.state.orderLine.orderLineStatus ? this.state.orderLine.orderLineStatus.value : constants.ORDER_LINE_STATUS.NA.value,
				ProductId: this.state.orderLine.selectedField.value,
				ProductColorId: this.state.orderLine.selectedFieldColor ? this.state.orderLine.selectedFieldColor.value : null,
				// LengthInches: Number(this.state.orderLine.fieldLengthInches),  no such thing on backend
				LengthFeet: Number(this.state.orderLine.fieldLengthFeet) + (Number(this.state.orderLine.fieldLengthInches) / 12.0),
				WidthFeet: Number(this.state.orderLine.selectedFieldWidth.widthNumericFeet),
				RepeatDsc: this.state.orderLine.repeatDsc, //this.state.orderLine.fieldRepeatWidth + ' x ' + this.state.orderLine.fieldRepeatLength,
				RepeatNumeric: Number(this.state.orderLine.appliedFieldRepeat),
				Run: this.state.orderLine.fieldRun,
				BorderLetter: "A",
				WidthId: this.state.orderLine.selectedFieldWidth ? this.state.orderLine.selectedFieldWidth.value : null,
				// RugShape: this.state.orderLine.rugShape ? this.state.orderLine.rugShape : null
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return fieldLine;
	}

	parseBorderLine() {
		const borderLine = {
			OrderLine: {
				Id: this.state.orderLine.id ? this.state.orderLine.id : 0,
				OrderId: this.state.orderId,
				ParentOrderLineId: this.state.orderLine.parentOrderLineId ? this.state.orderLine.parentOrderLineId : null,  // rugOrderLineId  (added on backend after parent custom rug has been created)
				OrderLineTypeId: constants.ORDER_LINE_TYPE.RUG_BORDER.value,
				CreatedAt: moment(),
				CreatedByUserId: this.props.currentUser.id,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.borderCmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.borderCmsn) : 0, // Cmsn: Math.round(this.state.orderLine.borderCmsn * 100) / 100,
				UnitCost: Number(this.state.orderLine.borderCostPer),
				Cost: Number(this.state.orderLine.totalBorderCost),   // Cost: Math.round(this.state.orderLine.totalBorderCost * 100) / 100,
				Dsc: this.state.orderLine.dsc,
				IsHidden: true,
				UnitPrice: Number(this.state.orderLine.borderPricePer),
				Price: Number(this.state.orderLine.totalBorderPrice),   // Price: Math.round(this.state.orderLine.totalBorderPrice * 100) / 100,
				PriceUnitTypeId: this.state.orderLine.borderPriceUnitType.value,
				Qty: Number(this.state.orderLine.totalBorderUnits),      // SquareYards: Math.round(this.state.orderLine.totalBorderUnits * 100) / 100,
				OrderLineStatusId: this.state.orderLine.orderLineStatus ? this.state.orderLine.orderLineStatus.value : constants.ORDER_LINE_STATUS.NA.value,
				ProductId: this.state.orderLine.selectedBorder.value,
				ProductColorId: this.state.orderLine.selectedBorderColor.value,
				// LengthInches: Number(this.state.orderLine.borderLengthInches),  no such thing on backend
				LengthFeet: Number(this.state.orderLine.borderLengthFeet) + (Number(this.state.orderLine.borderLengthInches) / 12.0),
				WidthFeet: Number(this.state.orderLine.selectedBorderWidth.widthNumericFeet),
				BorderLetter: this.state.orderLine.borderLetter,
				BorderWidth: this.state.orderLine.borderWidth,
				WidthId: this.state.orderLine.selectedBorderWidth ? this.state.orderLine.selectedBorderWidth.value : null
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return borderLine;
	}

	parseMiscLine(unitCost, cost, unitPrice, price, cmsn=0, cmsnRate=0, dsc, productId=0, qty=1) {
		const miscLine = {
			OrderLine: {
				Id: this.state.orderLine.id ? this.state.orderLine.id : 0,
				OrderId: this.state.orderId,
				ParentOrderLineId: this.state.orderLine.parentOrderLineId ? this.state.orderLine.parentOrderLineId : null,  // rugOrderLineId  (added on backend after parent custom rug has been created)
				CreatedAt: moment(),
				CreatedByUserId: this.props.currentUser.id,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(cmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(cmsn) : 0,
				UnitCost: Number(unitCost),
				Cost: Number(cost),
				Dsc: dsc,
				Qty: Number(qty),
				Hidden: true,
				OrderLineTypeId: constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
				UnitPrice: Number(unitPrice),
				Price: Number(price),
				PriceUnitTypeId: constants.PRICE_UNIT_TYPE.EACH.value,
				OrderLineStatusId: constants.ORDER_LINE_STATUS.NA.value,  // No misc rug component orderlines had status in old system
				ProductId: productId
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return miscLine
	}

	parsePadLine() {
		const padLine = {
			OrderLine: {
				Id: 0,
				OrderId: this.state.orderId,
				ParentOrderLineId: null,
				CreatedAt: moment(),
				CreatedByUserId: this.props.currentUser.id,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.padCmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.padCmsn): 0,          			//  Cmsn: Math.round(this.state.orderLine.padCmsn * 100) / 100,
				UnitCost: Number(this.state.orderLine.padCostPer),
				Cost: Number(this.state.orderLine.totalPadCost),
				Dsc: this.state.orderLine.selectedPad.label,
				Hidden: false,
				OrderLineTypeId: constants.ORDER_LINE_TYPE.RUG_PAD.value,
				UnitPrice: Number(this.state.orderLine.padPricePer),
				Price: Number(this.state.orderLine.totalPadPrice),
				PriceUnitTypeId: this.state.orderLine.padPriceUnitType.value,
				Qty: Number(this.state.orderLine.totalPadUnits),  				// Math.round(this.state.orderLine.totalFieldUnits * 100) / 100,
				OrderLineStatusId: constants.ORDER_LINE_STATUS.MAT_EMERY_PARK.value,   //should this be constant?
				ProductId: this.state.orderLine.selectedPad.value,
				LengthFeet: Number(this.state.orderLine.padLengthFeet),
				WidthId: this.state.orderLine.selectedPadWidth ? this.state.orderLine.selectedPadWidth.value : null,
				RugSize: this.state.orderLine.rugWidthFeet + "\' " + this.state.orderLine.rugWidthInches + "\" x " + this.state.orderLine.rugLengthFeet + "\' " + this.state.orderLine.rugLengthInches + "\""
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return padLine;
	}

	parseBindingLine() {
		const bindingLine = {
			OrderLine: {
				Id: 0,
				OrderId: this.state.orderId,
				ParentOrderLineId: null,  // save as separate order line or as part of custom rug order line?
				CreatedAt: moment(),
				CreatedByUserId: this.props.currentUser.id,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.rugBindingCmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(this.state.orderLine.rugBindingCmsn) : 0,
				UnitCost: Number(this.state.orderLine.rugBindingCostPer),
				Cost: Number(this.state.orderLine.totalRugBindingCost),
				Dsc: this.state.orderLine.selectedRugBindingStyle.label + ' - ' + this.state.orderLine.selectedRugBindingColor.label,
				Hidden: false,
				OrderLineTypeId: constants.ORDER_LINE_TYPE.BINDING.value,
				UnitPrice: Number(this.state.orderLine.rugBindingPricePer),
				Price: Number(this.state.orderLine.totalRugBindingPrice),
				PriceUnitTypeId: constants.PRICE_UNIT_TYPE.LIN_FT.value,
				OrderLineStatusId: constants.ORDER_LINE_STATUS.IN_STOCK.value,   //should this be constant?,
				ProductId: this.state.orderLine.selectedRugBindingStyle.value,
				ProductColorId: this.state.orderLine.selectedRugBindingColor.value,
				LengthFeet: Number(this.state.orderLine.finishedEdgeFt)    			// should be saved in Qty
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return bindingLine;
	}

	parseEdgeLine() {
		let CmsnRate;
		let Cmsn;
		let UnitCost;
		let Cost;
		let Dsc;
		let UnitPrice;
		let Price;
		let SupplierProductId;
		if (this.state.selectedRugEdge === EDGE_TYPES.MACHINED) {
			CmsnRate = this.state.orderLine.productAllowCommission ? this.state.orderLine.mSergingCmsnRate : 0;
			Cmsn = this.state.orderLine.productAllowCommission ? this.state.orderLine.mSergingCmsn : 0;
			UnitCost = this.state.orderLine.mSergingCostPer;
			Cost = this.state.orderLine.totalMSergingCost;
			Dsc = this.state.orderLine.finishedEdgeFt + ' x Applied Labor Borders (Machine) 2-3/4 & 4-3/4';
			UnitPrice = this.state.orderLine.mSergingPricePer;
			Price = this.state.orderLine.totalMSergingPrice;
			SupplierProductId = this.state.orderLine.selectedMSerging.value;
		} else if (this.state.selectedRugEdge === EDGE_TYPES.MITERED) {
			CmsnRate = this.state.orderLine.productAllowCommission ? this.state.orderLine.hsSergingCmsnRate : 0;
			Cmsn = this.state.orderLine.productAllowCommission ? this.state.orderLine.hsSergingCmsn : 0;
			UnitCost = this.state.orderLine.hsSergingCostPer;
			Cost = this.state.orderLine.totalHSSergingCost;
			Dsc = this.state.orderLine.finishedEdgeFt + ' x ' + MITERED_PRODUCT_NAME;
			UnitPrice = this.state.orderLine.hsSergingPricePer;
			Price = this.state.orderLine.totalHSSergingPrice;
			SupplierProductId = this.state.orderLine.selectedHSSerging.value;
		} else {
			CmsnRate = this.state.orderLine.productAllowCommission ? this.state.orderLine.sergingCmsnRate : 0;
			Cmsn = this.state.orderLine.productAllowCommission ? this.state.orderLine.sergingCmsn : 0;
			UnitCost = this.state.orderLine.sergingCostPer;
			Cost = this.state.orderLine.totalSergingCost;
			Dsc = this.state.orderLine.finishedEdgeFt + ' x ' + this.state.orderLine.selectedSerging.label;
			UnitPrice = this.state.orderLine.sergingPricePer;
			Price = this.state.orderLine.totalSergingPrice;
			SupplierProductId = this.state.orderLine.selectedSerging.value;
		}
		let edgeLine = {
			OrderLine: {
				Id: 0,
				OrderId: this.state.orderId,
				// ParentOrderLineId: null,  // rugOrderLineId  (added on backend after parent custom rug has been created)
				CreatedAt: moment(),
				CreatedByUserId: this.props.currentUser.id,
				OrderLineTypeId: constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value,
				Qty: Number(this.state.orderLine.finishedEdgeFt),
				Hidden: true,
				PriceUnitTypeId: constants.PRICE_UNIT_TYPE.EACH.value,
				OrderLineStatusId: constants.ORDER_LINE_STATUS.NA.value,
				// dsc2: 'EDGE: ' + this.selectedRugEdge,
				CmsnRate: this.state.orderLine.productAllowCommission ? Number(CmsnRate) : 0,
				Cmsn: this.state.orderLine.productAllowCommission ? Number(Cmsn) : 0,
				UnitCost: Number(UnitCost),
				Cost: Number(Cost),
				Dsc: Dsc,
				UnitPrice: Number(UnitPrice),
				Price: Number(Price),
				ProductId: SupplierProductId
			},
			FreightOrderLine: null,
			OrderLineComments: []
		}
		return edgeLine;
	}

	setRugValidity() {
		let isValid = (this.state.orderLine.selectedField !== null);
		if (isValid && this.state.orderLine.rugHasPad) {
			isValid = this.state.orderLine.selectedPad !== null && this.state.orderLine.selectedPadWidth !== null;
		}
		if (isValid && (this.state.selectedRugEdge === EDGE_TYPES.MACHINED || this.state.selectedRugEdge === EDGE_TYPES.MITERED)) {
			isValid = this.state.orderLine.selectedRugBindingStyle !== null && this.state.orderLine.selectedRugBindingColor !== null;
		}
		this.setState({
			orderLine: {
				...this.state.orderLine,
				rugEditsValid: isValid
			}
		})
	}

	onNoColorSelected() {
		let lineOrder = Object.assign({}, this.state.orderLine);
		if (lineOrder !== null) {
			lineOrder.productColor = null
		}
		this.setState({
			orderLine: lineOrder,
			colorOptOut: !this.state.colorOptOut
		});
	}

	downloadExport = (data, fileName, mime) => {
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(data, fileName);
		}
		else {
			const file = new Blob([data], { type: mime });
			var anchor = window.document.createElement('a');
			anchor.download = fileName;
			anchor.href = (window.webkitURL || window.URL).createObjectURL(file);
			anchor.dataset.downloadurl = [mime, anchor.download, anchor.href].join(':');
			anchor.click();
		}
	}

	downloadReprint(isSignOffForm = false) {
		if (!this.state.orderId) {
			console.warn("orderId is empty");
			return;
		}
		this.setState({ printing: true }, () => {
			const payload = {
				OrderId: this.state.orderId,
				InvoiceTypeId: this.state.invoiceType.value,
				InvoiceDate: date_helpers.getSelectedDate(this.state.invoiceDate || moment()).format(date_helpers.YMD),
				DueDate: date_helpers.getSelectedDate(this.state.dueDate || moment()).format(date_helpers.YMD),
				CustomDescription: this.state.customDescription,
				CustomDescription2: this.state.customDescription2,
				IsSignOffForm: isSignOffForm
			};
			const pdfWindow = window.open();
			api.post_get_blob("order/GetOrderPDF", payload).then(r => {
				if (r.status !== 200)
						throw new Error("Error rendering document");

					const file = new Blob([r.data], { type: "application/pdf" });
					const fileURL = URL.createObjectURL(file);
					pdfWindow.location.href = fileURL;
			}).catch(error => {
					console.error(error);
					Alert.error("There was an error downloading the document");
					pdfWindow.close();
			}).finally(() => this.setState({ printing: false }))
		})
	}

	handleStatusOrderCancel() {
		this.setState({ showOrderLineStatusModal: false, backOrderOrShippedDate: null})
	}

	recalculateSalesTaxAndSurcharge() {
		api.post(`Order/CalculateSalesTaxSurcharge/${this.state.orderId}`)
			.then(response => {
				this.setState({
					message: "Sales Tax and Surcharge recalculated",
					messageFlavor: "success",

				}, () => {
						setTimeout(() => {
							this.setState({
								message: null,
								messageFlavor: null,
							})
						}, 400);
				})
			})
	}

	getOverallStatus() {
		let overallStatus;
		let lBackorderDate;
		let unShipCnt=0;
		let shipCnt=0;
		let orderStatus = this.state.order.orderStatus;
		if (orderStatus.value === constants.ORDER_STATUS.MEMO_BLD.value)
		{
			overallStatus = "MEMO BLD";
		}
		else
		{
			overallStatus = "IN-STOCK";
			_.forEach(this.state.orderLines, (ol) => {
				if (ol.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET.value) {
					if (ol.orderLineStatus && ol.orderLineStatus.value === constants.ORDER_LINE_STATUS.BACKORDER.value) {
						overallStatus = "BACKORDER";
						try
						{
							_.forEach(ol.orderLineComments, c => {
								if (c.dsc.toString().substring(0,9) == "BACKORDER")
								{
									lBackorderDate = c.dsc.toString().substring(13);
								}
							})
						}
						catch {}
					}	else {
						if (ol.orderLineStatus && ol.orderLineStatus.value === constants.ORDER_LINE_STATUS.SHIPPED.value)
							shipCnt++;
						else
							unShipCnt++;
					}
				}
				if (ol.orderLineType.value === constants.ORDER_LINE_TYPE.HARD_SURFACE.value) {
					if (ol.orderLineStatus && ol.orderLineStatus.value === constants.ORDER_LINE_STATUS.BACKORDER.value) {
						overallStatus = "BACKORDER";
						try
						{
							_.forEach(ol.orderLineComments, c => {
								if (c.dsc.toString().substring(0,9) == "BACKORDER")
								{
									lBackorderDate = c.dsc.toString().substring(13);
								}
							})
						}
						catch {}
					}	else {
						if (ol.orderLineStatus && ol.orderLineStatus.value === constants.ORDER_LINE_STATUS.SHIPPED.value)
							shipCnt++;
						else
							unShipCnt++;
					}
				}
				if (ol.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value) {
					_.forEach(ol.componentLines, cl => {
						if (cl.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value) {
							if (cl.orderLineStatus && cl.orderLineStatus.value === constants.ORDER_LINE_STATUS.BACKORDER.value) {
								overallStatus = "BACKORDER";
								try
								{
									_.forEach(cl.orderLineComments, c => {
										if (c.dsc.toString().substring(0,9) == "BACKORDER")
										{
											lBackorderDate = c.dsc.toString().substring(13);
										}
									})
								}
								catch {}
							}	else {
								if (cl.orderLineStatus && cl.orderLineStatus.value === constants.ORDER_LINE_STATUS.SHIPPED.value)
									shipCnt++;
								else
									unShipCnt++;
							}
						}	else {
							if (cl.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value) {
								if (cl.orderLineStatus && cl.orderLineStatus.value === constants.ORDER_LINE_STATUS.BACKORDER.value) {
									overallStatus = "BACKORDER";
									try
									{
										_.forEach(cl.orderLineComments, c => {
											if (c.dsc.toString().substring(0,9) == "BACKORDER")
											{
												lBackorderDate = c.dsc.toString().substring(13);
											}
										})
									}
									catch {}
								}	else {
									if (cl.orderLineStatus && cl.orderLineStatus.value === constants.ORDER_LINE_STATUS.SHIPPED.value)
										shipCnt++;
									else
										unShipCnt++;
								}
							}
						}
					})
				}
			});
		};
		// shipped status only if all carpet,rug,wood lines come back as shipped
		// backorder status if any line comes back as backorder
		// in-stock for a mix of non-backorder lines
		if (overallStatus=="IN-STOCK")
		{
			if (unShipCnt==0 && shipCnt>0)
				overallStatus = "SHIPPED";
		}
		this.setState({
			overallStatus: overallStatus,
			lBackorderDate: lBackorderDate
		}, () => this.loadInvoiceDynamicDsc());
	}

	async loadInvoiceDynamicDsc() {
		await api.fetch('Order/GetInvoiceDynamicDscBoilerPlate/' + this.state.orderId).then(response => {
			this.setState({customDescription2: response.data});
		}).catch(helpers.catchHandler);
	}

	render() {
		const {
			squarespace
		} = this.props;
		let orderLinesToMapOver = this.state.customRugToViewEdit ? this.state.customRugToViewEdit.componentLines : this.state.orderLines;
		if (this.state.redirectToOrders) {
			return <Redirect to="/orders" />;
		}
		return (
			<Container fluid>
				<Modal isOpen={this.state.showOrderLineStatusModal} toggle={() => this.onClickCancelButton()}>
					<ModalHeader>Provide a {this.state.orderLine.orderLineStatus ? this.state.orderLine.orderLineStatus.label : ''} Date</ModalHeader>
					<ModalBody>
						<DatePicker
							id="date"
							selected={this.state.backOrderOrShippedDate}
							onChange={(e) => this.setState({backOrderOrShippedDate: e})}
							className="form-control"
						/>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<Button className="primary"
								onClick={()=>this.onSaveNote(true)}
							>
								Save
								</Button>
							<Button className="secondary" onClick={() => this.handleStatusOrderCancel()}>Cancel</Button>
						</ButtonGroup>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.showCancelOrderModal} toggle={() => this.onClickCancelButton()}>
					<ModalHeader>Cancel Order</ModalHeader>
					<Form>
						<ModalBody className="mb-4">
							<Label>Are you sure you want to cancel adding or updating this order?</Label>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup>
								<Button className="primary" onClick={() => this.yesCancelOrder()}>Yes</Button>
								<Button className="secondary" onClick={() => this.noCancelOrder()}>No</Button>
							</ButtonGroup>
						</ModalFooter>
					</Form>
				</Modal>


				<Modal isOpen={this.state.confirmationModalOrderLineId} toggle={() => this.showOrderLineDeleteConfirmationModal()}>
					<ModalHeader>Delete Order Line</ModalHeader>
					<Form>
						<ModalBody className="mb-4">
							<Label>Are you sure you want to delete this order line?</Label>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup>
								<Button className="primary"
									onClick={this.state.confirmationModalType === MODAL_DELETE_CONFIRMATION_TYPES.NORMAL_ORDER_LINE
										? () => this.handleOrderLineDelete(this.state.confirmationModalOrderLineId)
										: () => this.handleCustomRugOrderLineDelete(this.state.confirmationModalOrderLineId, this.state.confirmationModalComponentLines)}
								>
									Yes
								</Button>
								<Button className="secondary" onClick={() => this.handleDeleteConfirmationCancel()}>Cancel</Button>
							</ButtonGroup>
						</ModalFooter>
					</Form>
				</Modal>


				<Row>
					<Col>
						<h3 className="page-title">
							{squarespace ? "Custom Rug Quote" :
								(<Fragment>
									{this.state.activeTab === TABS.GENERAL_INFORMATION ? 'Order Entry : General Information' :
										this.state.activeTab === TABS.LINE_ITEMS ? 'Order Entry : Line Items' :
											this.state.activeTab === TABS.PAYMENT ? 'Order Entry : Payment' :
												this.state.activeTab === TABS.FULLFILLMENT ? 'Order Entry : Fullfillment' :
													this.state.activeTab === TABS.FINAL_REVIEW ? 'Order Entry : Final Review' :
														this.state.activeTab === TABS.PRINT ? 'Order Entry : Print' : null
									}
								</Fragment>)
							}
						</h3>
					</Col>
					<Col>
						{this.state.order.id &&
							<h3 className="text-right page-title">
								Order #
								{this.state.order.orderNumber}
							</h3>
						}
					</Col>
				</Row>

				<Row className="mb-3">
					<Col xs="2">
						<Nav tabs className="flex-column nav-pills">
							<NavItem>
								<NavLink
									className={classnames({
										active: this.state.activeTab === TABS.GENERAL_INFORMATION,
										primary: this.state.activeTab === TABS.GENERAL_INFORMATION
									})}
									onClick={() => this.toggle(TABS.GENERAL_INFORMATION)}
								>
									General Information
								</NavLink>
							</NavItem>
							{this.state.order.id && (
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === TABS.LINE_ITEMS,
											primary: this.state.activeTab === TABS.LINE_ITEMS
										})}
										onClick={() => this.toggle(TABS.LINE_ITEMS)}
									>
										Line Items
									</NavLink>
								</NavItem>
							)}
							{this.state.order.id && (
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === TABS.PAYMENT,
											primary: this.state.activeTab === TABS.PAYMENT
										})}
										onClick={() => this.toggle(TABS.PAYMENT)}
									>
										Payment
									</NavLink>
								</NavItem>
							)}
							{this.state.order.id && (
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === TABS.FULLFILLMENT,
											primary: this.state.activeTab === TABS.FULLFILLMENT
										})}
										onClick={() => this.toggle(TABS.FULLFILLMENT)}
									>
										Fullfillment
									</NavLink>
								</NavItem>
							)}
							{this.state.order.id && (
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === TABS.FINAL_REVIEW,
											primary: this.state.activeTab === TABS.FINAL_REVIEW
										})}
										onClick={() => this.toggle(TABS.FINAL_REVIEW)}
									>
										Final Review
									</NavLink>
								</NavItem>
							)}
							{this.state.order.id && (
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === TABS.PRINT,
											primary: this.state.activeTab === TABS.PRINT
										})}
										onClick={() => this.toggle(TABS.PRINT)}
									>
										Print
									</NavLink>
								</NavItem>
							)}
						</Nav>
					</Col>

					<Col xs="10">
						<TabContent activeTab={this.state.activeTab} >
							<TabPane tabId={TABS.GENERAL_INFORMATION}>
								<Card style={{ borderStyle: "none" }}>
									<CardBody>
										{this.state.message
											? <Alert2 className={this.state.messageFlavor}>{this.state.message}</Alert2>
											: null
										}
										<Row className="mb-3">
											<Col xs="12" md="6" lg="2">Order Status
												<Select
													options={this.state.orderStatusList}
													value={this.state.order.orderStatus}
													onChange={this.handleOrderStatusSelectChange}
												/>
											</Col>
											<Col>Month
												<DatePicker
													selected={this.state.order.mth}
													onChange={(date) => this.setMonth(date)}
													dateFormat="MMMM yyyy"
													showMonthYearPicker
													className="form-control"
												/>
											</Col>
											<Col xs="12" md="6" lg="4">Entered At
												<DatePicker
													id="createdAt"
													selected={this.state.order.createdAt ? this.state.order.createdAt : ""}
													onChange={this.handleCreatedAtInputChange}
													className="form-control"
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col xs="12" md="6" lg="6">Entered By
												<Select
													options={this.state.userList}
													value={this.state.order.createdByUser}
													onChange={this.handleTakenBySelectChange}
												/>
											</Col>
											<Col xs="12" md="6" lg="6">Ordered By
											{/* Tracy wanted the name of this field changed to Sold To */}
												<Input
													type="text"
													name="placedBy"
													id="placedBy"
													onChange={this.handleOnPlacedByInputChange}
													value={this.state.order.placedBy}
													maxLength="50"
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col xs="12" md="6" lg="6">
												<Button
													className="m-0 p-0"
													color="link"
													style={{ textDecoration: "underline" }}
													onClick={this.onToggleCustomerSearchModal}
												>
													Account {helpers.requiredStar()}
												</Button>
												<CustomerTypeahead
													onChange={(e) => this.handleCustomerSelectChange(e)}
													isMulti={false}
													customer={this.state.order.customer}
												/>
											</Col>
											<Col xs="12" md="6" lg="6">Agent &#40;to receive commission&#41;
												<Select
													options={this.state.agentList}
													value={this.state.order.agent}
													onChange={this.handleAgentSelectChange}
												/>
											</Col>
										</Row>
										<Row className="mb-3">

											<Col xs="12" md="6" lg="6" className="mt-1 mb-3 text-center">
												{this.state.order.id
													&& !this.state.order.isCreditOrder
													&& !this.state.order.isReplacementOrder
													&& this.state.order.relatedReplacementOrders === '' &&
													<Button
														className="mt-4 distinctiveLight"
														size="sm"
														style={{ minWidth: "100px" }}
														onClick={() => this.generateReplacementCredit()}
														disabled={this.state.generatingReplacement}
													>
														Gen Replacement/Credit {this.state.generatingReplacement ? <FontAwesomeIcon icon="spinner" spin/> : null}
													</Button>
												}
											</Col>
											<Col xs="6" md="3" lg="2">Replacement order
												<ToggleButton
													value={this.state.order ? this.state.order.isReplacementOrder : false}
													activeLabel={"Yes"}
													inactiveLabel={"No"}
													onToggle={(value) => {
														this.setState(prevState => ({
															...prevState,
															order: {
																...prevState.order,
																isReplacementOrder: !value
															}
														}));
													}}
												/>
												Credit order
												<ToggleButton
													value={this.state.order ? this.state.order.isCreditOrder : false}
													activeLabel={"Yes"}
													inactiveLabel={"No"}
													onToggle={(value) => {
														this.setState(prevState => ({
															...prevState,
															order: {
																...prevState.order,
																isCreditOrder: !value
															}
														}));
													}}
												/>
											</Col>
											{(this.state.order && this.state.order.id > 0
												&& (this.state.order.isReplacementOrder
													|| this.state.order.isCreditOrder
													|| this.state.order.relatedReplacementOrders !== '')) &&
												<Col xs="6" md="3" lg="4">{this.state.order.relatedReplacementOrders === '' ? "References Order" : "Related Replacement Orders"}
													<Input
														type='text'
														value={this.state.order.relatedReplacementOrders === '' ? this.state.order.referencesOrder : this.state.order.relatedReplacementOrders}
														placeholder="Order #"
														onChange={this.onReferencesOrderChange}
													/>
												</Col>
											}
										</Row>
										{this.state.customerInfo && !this.state.customerInfo.hasTaxCertificate &&
											<Row className="mt-4 mb-4">
												<Col>
													<Alert2
														color="danger"
														className="text-center"
														style={{ fontWeight: "700", color: "red" }}
													>
														MISSING sales certificate - charge sales tax!
													</Alert2>
												</Col>
											</Row>
										}
										<Row className="mt-4">
											<Col className="text-right">
												<ButtonGroup>
													<Button
														className="primary"
														size="sm"
														onClick={() => this.saveOrderEntryGeneralInfo()}
													>
														<FontAwesomeIcon icon="save" /> Save
													</Button>
													<Button
														className="secondary"
														size="sm"
														onClick={this.onClickCancelButton}
													>
														<FontAwesomeIcon icon="times-circle" /> Cancel
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
										<Modal
											isOpen={this.state.showCustomerSearchModal}
											toggle={() => this.setState({ showCustomerSearchModal: false })}
											size="lg"
										>
											<ModalBody>
												<CustomerAdmin
													usedByCustomerSearchModal={true}
													onCustomerSelected={this.onCustomerSelectedFromSearchModal}
												/>
											</ModalBody>
										</Modal>
									</CardBody>
								</Card>
							</TabPane>
							<TabPane tabId={TABS.LINE_ITEMS}>
								{this.state.message && (
									<Alert2 className={this.state.messageFlavor}>
										{this.state.message}
									</Alert2>
								)}
								{this.state.showOrderLineForm
									? null
									: <React.Fragment>
										<Row className="row-spacing">
											<Col>
												<Button
													className="float-right success"
													size="sm"
													onClick={() => this.onAddNewOrderLine()}
													title="Add line item"
												>
													<FontAwesomeIcon icon="plus-circle" /> {this.state.customRugToViewEdit ? 'Add Rug Component' : 'Add Line Item'}
												</Button>
											</Col>
										</Row>
									</React.Fragment>
								}
								<Collapse isOpen={this.state.showOrderLineForm} className="row-spacing">
									<Card>
										<CardBody>
											{this.state.orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value &&
												this.state.orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.SALES_TAX.value &&
												<React.Fragment>
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET.value &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col xs="3">Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																<Col xs="3">Product Color{" "} <span className="ml-5 detailTextNoSize float-right"><Input type="checkbox" checked={this.state.colorOptOut} onChange={() => this.onNoColorSelected()} />No Color Selected</span>
																	<Select
																		options={this.state.productColorsList}
																		value={this.state.orderLine.productColor}
																		onChange={this.handleFormSelectChange.bind(this, "productColor")}
																		isDisabled={this.state.colorOptOut}
																	/>
																</Col>
																<Col xs="2">Width {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLine.widthsList}
																		value={this.state.orderLine.width}
																		onChange={this.handleWidthSelectChange.bind(this, "width")}
																	/>
																</Col>
																{this.state.orderLine.priceUnitType
																	&& this.state.orderLine.priceUnitType.value === constants.PRICE_UNIT_TYPE.EACH.value
																	? <Col md="2"> Qty
																		<Input
																			type="number"
																			step="1"
																			name="qty"
																			value={this.state.orderLine.qty}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			onBlur={() => this.calculateFigures()}
																		/>
																	</Col>
																	: <Col xs="2">Length &#40;ft.&#41; {helpers.requiredStar()}
																		<Input
																			type="number"
																			step="0.0001"
																			name="lengthFeet"
																			value={this.state.orderLine.lengthFeet}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			onBlur={() => this.applyVolumePricingIfNecessary()}
																		/>
																	</Col>
																}
																<Col xs="2">
																	{this.state.orderLine.priceUnitType
																		&& this.state.orderLine.priceUnitType.value !== constants.PRICE_UNIT_TYPE.EACH.value
																		&& this.state.orderLine.priceUnitType.value !== constants.PRICE_UNIT_TYPE.LIN_FT.value
																		? <React.Fragment>
																			{this.state.orderLine.priceUnitType
																				&& (this.state.orderLine.priceUnitType.value === constants.PRICE_UNIT_TYPE.SQ_YARDS.value
																					|| this.state.orderLine.priceUnitType.value === constants.PRICE_UNIT_TYPE.LIN_FT.value)
																				? `Sq. Yds.`
																				: `Sq. Ft`
																			}
																			<Input
																				type="number"
																				step="0.0001"
																				name="qty"
																				value={this.state.orderLine.qty}
																				readOnly
																			/>
																		</React.Fragment>
																		: null
																	}
																</Col>
															</Row>
															<Row className="mb-4">
																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_PAD.value &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																<Col>Width {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLine.widthsList}
																		value={this.state.orderLine.width}
																		onChange={this.handleWidthSelectChange.bind(this, "width")}
																	/>
																</Col>
																<Col>Length &#40;ft.&#41; {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.0001"
																		name="lengthFeet"
																		value={this.state.orderLine.lengthFeet}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		// onBlur={() => this.calculateFigures()}
																		onBlur={() => this.applyVolumePricingIfNecessary()}
																	/>
																</Col>
																<Col>Sq. Ft.
																<Input
																		type="number"
																		step="0.0001"
																		name="qty"
																		value={this.state.orderLine.qty}
																		readOnly
																	/>
																</Col>
															</Row>
															<Row className="mb-4">
																<Col>Cost/Unit {helpers.requiredStar()}
																{console.log("order line on state", this.state.orderLine)}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
													{(this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.MISCELLANEOUS.value ||
														this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value) &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SAMPLE.value &&
																	<Col>Product Color
																	<Select
																			options={this.state.productColorsList}
																			value={this.state.orderLine.productColor}
																			onChange={this.handleFormSelectChange.bind(this, "productColor")}
																		/>
																	</Col>
																}
																<Col xs="3">Qty
																<Input
																		type="number"
																		step="1"
																		name="qty"
																		value={this.state.orderLine.qty}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
															</Row>
															<Row className="mb-4">

																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
													{(this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.HARD_SURFACE.value ||
														this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CARPET_TILE.value) &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																<Col>Product Color
																<Select
																		options={this.state.productColorsList}
																		value={this.state.orderLine.productColor}
																		onChange={this.handleFormSelectChange.bind(this, "productColor")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Sq. Units Required {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="squareFeetRequired"
																		value={this.state.orderLine.squareFeetRequired}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.applyVolumePricingIfNecessary()}
																	/>
																</Col>
																<Col># Items {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="1"
																		name="cartonCount"
																		value={this.state.orderLine.cartonCount}
																		readOnly
																	/>
																</Col>
																<Col>
																	<div className="total-text-left mt-3">
																		{this.state.orderLine.squareFeetPerCarton
																			? `${this.state.orderLine.squareFeetPerCarton} ${this.state.orderLine.priceUnitType.label}`
																			: null
																		}
																	</div>
																	<div className="total-text-left">
																		{this.state.orderLine.perContainer
																			? `${this.state.orderLine.perContainer} per`
																			: null
																		}
																	</div>
																</Col>
															</Row>
															<Row className="mb-4">
																<Col>Sq. Ft.
																<Input
																		type="number"
																		step="0.0001"
																		name="qty"
																		value={this.state.orderLine.qty}
																		readOnly
																	/>
																</Col>
																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
													{(this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.BINDING.value ||
														this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.ROLL_RUNNER.value) &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																<Col>Product Color
																<Select
																		options={this.state.productColorsList}
																		value={this.state.orderLine.productColor}
																		onChange={this.handleFormSelectChange.bind(this, "productColor")}
																	/>
																</Col>
																<Col>Length &#40;ft.&#41; {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.0001"
																		name="lengthFeet"
																		value={this.state.orderLine.lengthFeet}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
															</Row>
															<Row className="mb-4">
																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.INTL_COLL_RUG.value &&
														<div>
															<Row>
																<Col>Line Type {helpers.requiredStar()}
																	<Select
																		options={this.state.orderLineTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
															</Row>
															<Row>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																{/* <Col>Collection {helpers.requiredStar()}
																	<Select
																		options={this.state.rugIntlCollectionList}
																		value={this.state.orderLine.rugIntlCollection}
																		onChange={(option) => this.setSelectedCollection(option)}
																	/>
																</Col> */}
																<Col>Product {helpers.requiredStar()}
																	<Select
																		// options={this.state.collectionProductsList}
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
																<Col>Product Color
																<Select
																		options={this.state.productColorsList}
																		value={this.state.orderLine.productColor}
																		onChange={this.handleFormSelectChange.bind(this, "productColor")}
																	/>
																</Col>
																<Col>Size
																<Select
																		options={this.state.rugIntlSizeList}
																		value={this.state.orderLine.rugIntlSize}
																		onChange={this.handleRugIntlSizeChange.bind(this, "rugIntlSize")}
																	/>
																</Col>
																<Col xs="2">Qty
																<Input
																		type="number"
																		step="1"
																		name="qty"
																		value={this.state.orderLine.qty}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
															</Row>
															<Row className="mb-4">
																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}

													{COMPONENT_ORDER_LINE_TYPES.includes(this.state.orderLine.orderLineType.value) &&
														<Row className="mb-1">
															<Col xs="2">
																<Label className="m-0 p-0">Finished Size</Label>
																<Select
																	options={FINISHED_RUG_SIZES}
																	value={this.state.orderLine.finishedSize}
																	onChange={(option) => this.setFinishedRugSize(option)}
																/>
															</Col>
															<Col className="mr-3">
																<Row>
																	<Col>
																		<Label className="m-0 p-0">Width</Label>
																	</Col>
																	<Col>
																		<Label className="m-0 p-0">Length</Label>
																	</Col>
																</Row>
																<Row>
																	<Col className="pr-0">
																		<InputGroupAddon addonType="append" className="mr-1">
																			<Input
																				type="text"
																				name="rugWidthFeet"
																				placeholder="W'"
																				required
																				value={this.state.orderLine.rugWidthFeet}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={this.rugSizeChange}
																			/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																		</InputGroupAddon>
																	</Col>
																	<Col className="pr-0">
																		<InputGroupAddon addonType="append" className="mr-1">
																			<Input
																				type="text"
																				name="rugWidthInches"
																				placeholder='W"'
																				required
																				value={this.state.orderLine.rugWidthInches}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={this.rugSizeChange}
																			/>
																				<span className="ml-1" style={{ fontWeight: "bold" }}> "</span>
																				<span className="ml-3 mt-2" style={{ fontWeight: "bold" }}> X </span>
																		</InputGroupAddon>
																	</Col>
																	<Col className="pr-0">
																		<InputGroupAddon addonType="append" className="mr-1">
																			<Input
																				type="text"
																				name="rugLengthFeet"
																				id="rugLengthFeet"
																				required
																				placeholder="L'"
																				value={this.state.orderLine.rugLengthFeet}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={this.rugSizeChange}
																			/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																		</InputGroupAddon>
																	</Col>
																	<Col className="pr-0">
																		<InputGroupAddon addonType="append" className="mr-1">
																			<Input
																				type="text"
																				name="rugLengthInches"
																				required
																				placeholder='L"'
																				value={this.state.orderLine.rugLengthInches}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={this.rugSizeChange}
																			/><span className="ml-1" style={{ fontWeight: "bold" }}> " </span>
																		</InputGroupAddon>
																	</Col>
																</Row>
															</Col>
															<Col xs="4">
																<Label className="m-0 p-0">Rug Description</Label>
																<Input
																	type="text"
																	name="rugDsc"
																	value={this.state.orderLine.rugDsc}
																	required
																	onChange={(event) => this.handleRugOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="250"
																/>
															</Col>
															<Col xs="1">
																<Label className="m-0 p-0">Sq Ft</Label>
																<Input
																	type="text"
																	name="sqFt"
																	required
																	value={this.state.orderLine.sqFt || ""}
																	readOnly
																/>
															</Col>
															<Col xs="1">
																<Label className="m-0 p-0">Fin Edge Ft</Label>
																<Input
																	type="text"
																	name="finishedEdgeFt"
																	required
																	value={this.state.orderLine.finishedEdgeFt || ""}
																	readOnly
																/>
															</Col>
														</Row>
													}
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_FIELD.value &&
														<div>
															<Row className="mb-1">
																<Col xs="2">
																	<Label className="m-0 p-0">Component Type</Label>
																	<Select
																		options={rugComponentTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col xs="2">Line Status
																<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
																{/* The following two fields are saved with the parent rug orderline, not the field line (parent rug orderline has the truth for these two fields) */}
																<Col xs="1">
																	Rug Shape
																	<Select
																		options={this.state.rugShapeList}
																		value={this.state.orderLine.rugShape}
																		onChange={this.handleRugShapeSelectChange.bind(this, "rugShape")}
																	/>
																</Col>
																<Col xs="1">For outdoor use
																	<ToggleButton
																		value={this.state.orderLine.forOutdoorUse}
																		activeLabel={"Yes"}
																		inactiveLabel={"No"}
																		onToggle={(value) => {
																			this.setState(prevState => ({
																				...prevState,
																				orderLine: {
																					...prevState.orderLine,
																					forOutdoorUse: !value
																				}
																			}));
																		}}
																	/>
															</Col>
															</Row>
															<Row className="mb-3">
																<Col xs="4">
																	<Label className="m-0 p-0">Field Style</Label>
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.selectedField}
																		onChange={(option) => this.setSelectedField(option)}
																	/>
																	<Input
																		type="text"
																		readOnly
																		className="total-text"
																		value={this.state.orderLine.selectedField !== null
																			? `Run: ${this.state.orderLine.fieldRun ? this.state.orderLine.fieldRun : ""}`
																			: ''
																		}
																	/>
																</Col>
																<Col>
																	<Label className="m-0 p-0">Color</Label>
																	<Select
																		closeMenuOnSelect
																		options={this.state.productColorsList}
																		value={this.state.orderLine.selectedFieldColor}
																		onChange={this.handleRugFormSelectChange.bind(this, "selectedFieldColor")}
																		name="selectedFieldColor"
																		isClearable
																	/>
																	<Input
																		type="text"
																		readOnly
																		className="total-text"
																		value={this.state.orderLine.selectedField !== null
																			? `Repeat: ${this.state.orderLine.repeatDsc}`
																			: ''
																		}
																	/>
																</Col>
																<Col>
																	<Label className="m-0 p-0">Width</Label>
																	<Select
																		options={this.state.orderLine.widthsList}
																		value={this.state.orderLine.selectedFieldWidth}
																		onChange={this.handleFieldWidthSelectChange.bind(this, "selectedFieldWidth")}
																	/>
																	<Input
																		type="text"
																		className="total-text"
																		name="totalFieldUnits"
																		value={this.state.orderLine.totalFieldUnits}
																		readOnly
																	/>
																</Col>
																<Col>
																	<Row>
																		<Col>
																			<Label className="m-0 p-0">Length</Label>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="fieldLengthFeet"
																					id="fieldLengthFeet"
																					value={this.state.orderLine.fieldLengthFeet}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder="L'"
																					onBlur={this.fieldSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																			</InputGroupAddon>
																		</Col>
																		<Col>
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="fieldLengthInches"
																					value={this.state.orderLine.fieldLengthInches}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder='L"'
																					onBlur={this.fieldSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> " </span>
																			</InputGroupAddon>
																		</Col>
																	</Row>
																	<Input
																		type="text"
																		className="total-text"
																		style={{ textAlign: "left" }}
																		value={this.state.orderLine.fieldPriceUnitType
																				? this.state.orderLine.fieldPriceUnitType.label
																				: ''
																		}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cost</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldCostPer"
																		value={this.state.orderLine.fieldCostPer}
																		required
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateFieldTotals()}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="totalFieldCost"
																		value={this.state.orderLine.totalFieldCost || ""}
																		required
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Price</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldPricePer"
																		value={this.state.orderLine.fieldPricePer || ""}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateFieldTotals()}
																		required
																	/>
																	<Input
																		type="number"
																		step="0.01"
																		className="total-text"
																		name="totalFieldPrice"
																		value={this.state.orderLine.totalFieldPrice || ""}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cmsn %</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldCmsnRate"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.fieldCmsnRate : "0.0"}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateFieldTotals()}
																		required
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="fieldCmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.fieldCmsn : "0.0"}
																		readOnly
																	/>
																</Col>
															</Row>
														</div>
													}
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_BORDER.value &&
														<div>
															<Row className="mb-1">
																<Col xs="2">
																	<Label className="m-0 p-0">Component Type</Label>
																	<Select
																		options={rugComponentTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col xs="2">Line Status
																	<Select
																		options={this.state.orderLineStatusList}
																		value={this.state.orderLine.orderLineStatus}
																		onChange={this.handleFormSelectChange.bind(this, "orderLineStatus")}
																	/>
																</Col>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
															</Row>
															<Row>
																<Col xs="4">
																	<Label className="m-0 p-0">Border Style</Label>
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.selectedBorder}
																		onChange={(option) => this.setSelectedBorder(option)}
																	/>
																</Col>
																<Col>
																	<Label className="m-0 p-0">Color</Label>
																	<Select
																		options={this.state.borderColorList}
																		value={this.state.orderLine.selectedBorderColor}
																		onChange={this.handleRugFormSelectChange.bind(this, "selectedBorderColor")}
																		name="selectedBorderColor"
																	/>
																</Col>
																<Col>
																	<Label className="m-0 p-0">Width</Label>
																	<Select
																		options={this.state.orderLine.widthsList}
																		value={this.state.orderLine.selectedBorderWidth}
																		onChange={this.handleBorderWidthSelectChange.bind(this, "selectedBorderWidth")}
																	/>
																	<Input
																		type="text"
																		className="total-text"
																		name="totalBorderUnits"
																		value={this.state.orderLine.totalBorderUnits}
																		readOnly
																	/>
																</Col>
																<Col>
																	<Row>
																		<Col>
																			<Label className="m-0 p-0">Length Required</Label>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="borderLengthFeet"
																					id="borderLengthFeet"
																					value={this.state.orderLine.borderLengthFeet}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder="L'"
																					onBlur={this.borderSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																			</InputGroupAddon>
																		</Col>
																		<Col>
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="borderLengthInches"
																					value={this.state.orderLine.borderLengthInches}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder='L"'
																					onBlur={this.borderSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> " </span>
																			</InputGroupAddon>
																		</Col>
																	</Row>
																	<Input
																		type="text"
																		className="total-text"
																		style={{ textAlign: "left" }}
																		value={this.state.orderLine.selectedBorder === null
																			? ''
																			: this.state.orderLine.borderPriceUnitType
																				? this.state.orderLine.borderPriceUnitType.label
																				: ''
																		}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cost</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="borderCostPer"
																		value={this.state.orderLine.borderCostPer}
																		required
																		// readOnly   // readOnly in angular app for some reason
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																			event.target.name,
																			event.target.value
																		)}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="totalBorderCost"
																		value={this.state.orderLine.totalBorderCost || ""}
																		required
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Price</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="borderPricePer"
																		value={this.state.orderLine.borderPricePer || ""}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateBorderTotals()}
																		required
																	/>
																	<Input
																		type="number"
																		step="0.01"
																		className="total-text"
																		name="totalBorderPrice"
																		value={this.state.orderLine.totalBorderPrice || ""}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cmsn %</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="borderCmsnRate"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.borderCmsnRate : "0.0"}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateBorderTotals()}
																		required
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="borderCmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.borderCmsn : "0.0"}
																		readOnly
																	/>
																</Col>
															</Row>
															<Row className="mb-2">
																<Col xs="2">
																	<Label className="m-0 p-0">Letter</Label>
																	<Input
																		type="text"
																		name="borderLetter"
																		value={this.state.orderLine.borderLetter || ""}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateBorderDsc()}
																	/>
																</Col>
																<Col xs="2">
																	<Label className="m-0 p-0">Border Width</Label>
																	<Input
																		type="text"
																		readOnly
																		className="total-text"
																		value={this.state.orderLine.borderWidth || ""}
																	/>
																</Col>
															</Row>
														</div>
													}
													{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.RUG_MISCELLANEOUS.value &&
														<div>
															<Row className="mb-3">
																<Col xs="4">
																	<Label className="m-0 p-0">Component Type</Label>
																	<Select
																		options={rugComponentTypeList}
																		value={this.state.orderLine.orderLineType}
																		onChange={this.handleOrderLineTypeChange.bind(this)}
																		isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
																	/>
																</Col>
																<Col>Description {helpers.requiredStar()}
																	<Input
																		type="text"
																		name="dsc"
																		value={this.state.orderLine.dsc}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
														</Row>
														{/* {this.state.orderLine.orderLineType.label !== "MISCELLANEOUS (RUG COMPONENT)" && */}
															<Row>
																<Col>Product {helpers.requiredStar()}
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.product}
																		onChange={(option) => this.setSelectedProduct(option)}
																	/>
																</Col>
															</Row>
															{/* } */}
															<Row className="mb-4">
																<Col>Qty
																	<Input
																		type="number"
																		step="1"
																		name="qty"
																		value={this.state.orderLine.qty}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Cost/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitCost"
																		value={this.state.orderLine.unitCost}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Total Cost {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cost"
																		value={this.state.orderLine.cost}
																		readOnly
																	/>
																</Col>
																<Col>Price/Unit {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="unitPrice"
																		value={this.state.orderLine.unitPrice}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																	/>
																</Col>
																<Col>Price {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="price"
																		value={this.state.orderLine.price}
																		readOnly
																	/>
																</Col>
																<Col>Cmsn% {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsnRate"
																		value={this.state.orderLine.cmsnRate || "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.calculateFigures()}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
																<Col>Commission {helpers.requiredStar()}
																	<Input
																		type="number"
																		step="0.01"
																		name="cmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.cmsn : "0.0"}
																		onChange={(event) => this.handleOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																</Col>
															</Row>
														</div>
													}
												{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value &&
													<Row className="mb-2">
														<Col xs="12">
															<Row>
																<Col xs="2">
																	<Label className="m-0 p-0">Finished Size</Label>
																	<Select
																		options={FINISHED_RUG_SIZES}
																		value={this.state.orderLine.finishedSize}
																		onChange={(option) => this.setFinishedRugSize(option)}
																	/>
																</Col>
																<Col className="mr-3">
																	<Row>
																		<Col>
																			<Label className="m-0 p-0">Width</Label>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Length</Label>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="rugWidthFeet"
																					placeholder="W'"
																					required
																					value={this.state.orderLine.rugWidthFeet}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					onBlur={this.rugSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																			</InputGroupAddon>
																		</Col>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="rugWidthInches"
																					placeholder='W"'
																					required
																					value={this.state.orderLine.rugWidthInches}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					onBlur={this.rugSizeChange}
																				/>
																					<span className="ml-1" style={{ fontWeight: "bold" }}> "</span>
																					<span className="ml-3 mt-2" style={{ fontWeight: "bold" }}> X </span>
																			</InputGroupAddon>
																		</Col>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="rugLengthFeet"
																					id="rugLengthFeet"
																					required
																					placeholder="L'"
																					value={this.state.orderLine.rugLengthFeet}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					onBlur={this.rugSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																			</InputGroupAddon>
																		</Col>
																		<Col className="pr-1">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="rugLengthInches"
																					required
																					placeholder='L"'
																					value={this.state.orderLine.rugLengthInches}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					onBlur={this.rugSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> " </span>
																			</InputGroupAddon>
																		</Col>
																	</Row>
																</Col>
																<Col xs="4">
																	<Label className="m-0 p-0">Rug Description</Label>
																	<Input
																		type="text"
																		name="rugDsc"
																		value={this.state.orderLine.rugDsc}
																		required
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		maxLength="250"
																	/>
																</Col>
																<Col xs="1" className="px-1">
																	<Label className="m-0 p-0">Sq Ft</Label>
																	<Input
																		type="text"
																		name="sqFt"
																		required
																		value={this.state.orderLine.sqFt}
																		readOnly
																	/>
																</Col>
																<Col xs="1" className="px-1">
																	<Label className="m-0 p-0">Fin Edge Ft</Label>
																	<Input
																		type="text"
																		name="finishedEdgeFt"
																		required
																		value={this.state.orderLine.finishedEdgeFt}
																		readOnly
																	/>
																</Col>
															</Row>
															<Row className="mb-3">
																<Col xs="3">
																	<Label className="m-0 p-0">Field</Label>
																	<Select
																		options={this.state.productsList}
																		value={this.state.orderLine.selectedField}
																		onChange={(option) => this.setSelectedField(option)}
																	/>
																	<Input
																		type="text"
																		readOnly
																		className="total-text"
																		value={this.state.orderLine.selectedField !== null
																			? `Run: ${this.state.orderLine.fieldRun ? this.state.orderLine.fieldRun : ""}`
																			: ''
																		}
																	/>
																</Col>
																<Col>
																	<Label className="m-0 p-0">Color</Label>
																	<Select
																		closeMenuOnSelect
																		options={this.state.productColorsList}
																		value={this.state.orderLine.selectedFieldColor}
																		onChange={this.handleRugFormSelectChange.bind(this, "selectedFieldColor")}
																		name="selectedFieldColor"
																		isClearable
																	/>
																	<Input
																		type="text"
																		readOnly
																		className="total-text"
																		// value={this.state.orderLine.selectedField !== null
																		// 		? `Repeat: ${this.state.orderLine.fieldRepeatWidth} x ${this.state.orderLine.fieldRepeatLength}`
																		// 		: ''
																		// 	}
																		value={`Repeat: ${this.state.orderLine.repeatDsc}`}
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Width</Label>
																	<Select
																		options={this.state.orderLine.widthsList}
																		value={this.state.orderLine.selectedFieldWidth}
																		onChange={this.handleFieldWidthSelectChange.bind(this, "selectedFieldWidth")}
																	/>
																	<Input
																		type="text"
																		className="total-text"
																		name="totalFieldUnits"
																		value={this.state.orderLine.totalFieldUnits}
																		readOnly
																	/>
																</Col>
																<Col>
																	<Row>
																		<Col>
																			<Label className="m-0 p-0">Length</Label>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="pr-0">
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="fieldLengthFeet"
																					id="fieldLengthFeet"
																					value={this.state.orderLine.fieldLengthFeet}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder="L'"
																					onBlur={this.fieldSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> ' </span>
																			</InputGroupAddon>
																		</Col>
																		<Col>
																			<InputGroupAddon addonType="append" className="mr-1">
																				<Input
																					type="text"
																					name="fieldLengthInches"
																					value={this.state.orderLine.fieldLengthInches}
																					onChange={(event) => this.handleRugOrderLineStateChange(
																						event.target.name,
																						event.target.value
																					)}
																					required
																					placeholder='L"'
																					onBlur={this.fieldSizeChange}
																				/><span className="ml-1" style={{ fontWeight: "bold" }}> " </span>
																			</InputGroupAddon>
																		</Col>
																	</Row>
																	<Input
																		type="text"
																		className="total-text"
																		style={{ textAlign: "left" }}
																		value={this.state.orderLine.selectedField === null
																			? ''
																			: this.state.orderLine.fieldPriceUnitType
																				? this.state.orderLine.fieldPriceUnitType.label
																				: ''
																		}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cost</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldCostPer"
																		value={this.state.orderLine.fieldCostPer}
																		required
																		readOnly
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																			event.target.name,
																			event.target.value
																		)}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="totalFieldCost"
																		value={this.state.orderLine.totalFieldCost}
																		required
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Price</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldPricePer"
																		value={this.state.orderLine.fieldPricePer}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateFieldTotals()}
																		required
																	/>
																	<Input
																		type="number"
																		step="0.01"
																		className="total-text"
																		name="totalFieldPrice"
																		value={this.state.orderLine.totalFieldPrice}
																		readOnly
																	/>
																</Col>
																<Col xs="1">
																	<Label className="m-0 p-0">Cmsn %</Label>
																	<Input
																		type="number"
																		className="rt"
																		step="0.01"
																		name="fieldCmsnRate"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.fieldCmsnRate : "0.0"}
																		onChange={(event) => this.handleRugOrderLineStateChange(
																			event.target.name,
																			event.target.value
																		)}
																		onBlur={() => this.updateFieldTotals()}
																		required
																		disabled={!this.state.orderLine.productAllowCommission}
																	/>
																	<Input
																		type="number"
																		className="total-text"
																		step="0.01"
																		name="fieldCmsn"
																		value={this.state.orderLine.productAllowCommission ? this.state.orderLine.fieldCmsn : "0.0"}
																		readOnly
																	/>
																</Col>
																{/* The following two fields are saved with the parent rug orderline, not the field line (parent rug orderline has the truth for these two fields) */}
																<Col xs="1">
																	Rug Shape
																	<Select
																		options={this.state.rugShapeList}
																		value={this.state.orderLine.rugShape}
																		onChange={this.handleRugShapeSelectChange.bind(this, "rugShape")}
																	/>
																</Col>
																<Col xs="1">For outdoor use
																	<ToggleButton
																		value={this.state.orderLine.forOutdoorUse}
																		onToggle={(value) => {
																			this.setState(prevState => ({
																				...prevState,
																				orderLine: {
																					...prevState.orderLine,
																					forOutdoorUse: !value
																				}
																			}));
																		}}
																	/>
															</Col>
															</Row>
															<Row className="mb-2">
																<Col xs="12">
																	<InputGroup>
																		<InputGroupAddon addonType="prepend">
																			<InputGroupText>Options :</InputGroupText>
																		</InputGroupAddon>
																		<ButtonGroup>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleCutCharge}
																				className={this.state.orderLine.rugHasCutCharge ? "success" : "secondary"}
																			>
																				Cut Charge
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleArmor}
																				className={this.state.orderLine.rugHasEndArmor ? "success" : "secondary"}
																			>
																				End Armor
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleInboundFreight}
																				className={this.state.orderLine.rugHasInboundFreight ? "success" : "secondary"}
																			>
																				Inbound Freight
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleBacking}
																				className={this.state.orderLine.rugHasBacking ? "success" : "secondary"}
																			>
																				Backing
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.togglePad}
																				className={this.state.orderLine.rugHasPad ? "success" : "secondary"}
																			>
																				Pad
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleClippedCorners}
																				className={this.state.orderLine.rugHasClippedCorners ? "success" : "secondary"}
																			>
																				Clipped Corners
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleLaborCutout}
																				className={this.state.orderLine.rugHasLaborCutout ? "success" : "secondary"}
																			>
																				Cutout
																	</Button>
																			<Button
																				className="btn btn-sm"
																				onClick={this.toggleLaborRound}
																				className={this.state.orderLine.rugHasLaborRound ? "success" : "secondary"}
																			>
																				Round/Oval
																	</Button>
																		</ButtonGroup>
																		<InputGroupButtonDropdown
																			addonType="append"
																			isOpen={this.state.orderLine.edgeOptionsOpen}
																			toggle={this.toggleEdgeOptions}
																			id="selectedRugEdgeOption"
																		>
																			<DropdownToggle caret>
																				Edge: {this.state.selectedRugEdge}
																			</DropdownToggle>
																			<DropdownMenu>
																				<DropdownItem
																					onClick={() => this.setRugEdge('Serging')}
																				>
																					Serging
																		</DropdownItem>
																				<DropdownItem
																					onClick={() => this.setRugEdge('Applied Machine Border')}
																				>
																					Applied Machine Border
																		</DropdownItem>
																				<DropdownItem
																					onClick={() => this.setRugEdge('Hidden Stitch Mitered Corners')}
																				>
																					Hidden Stitch Mitered Corners
																		</DropdownItem>
																			</DropdownMenu>
																		</InputGroupButtonDropdown>
																	</InputGroup>
																</Col>
															</Row>
															{this.state.orderLine.rugHasCutCharge &&
																<React.Fragment>
																	<Row>
																		<Col xs="10" className="rug-option-header">
																			Cut Charge
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugCutChargeCost"
																				value={this.state.orderLine.rugCutChargeCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugCutChargePrice"
																				value={this.state.orderLine.rugCutChargePrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasEndArmor &&
																<React.Fragment>
																	<Row>
																		<Col xs="10" className="rug-option-header">
																			End Armor
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugArmorCost"
																				value={this.state.orderLine.rugArmorCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugArmorPrice"
																				value={this.state.orderLine.rugArmorPrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasInboundFreight &&
																<React.Fragment>
																	<Row>
																		<Col xs="6" className="rug-option-header">
																			Inbound Freight
																		</Col>
																		<Col xs="3" xl="4">
																			<Label className="m-0 p-0">Product</Label>
																			<Select
																				options={this.state.inboundFreightProductList}
																				value={this.state.orderLine.selectedInboundFreight}
																				onChange={(option) => this.setSelectedInboundFreight(option)}
																				name="selectedPad"
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugInboundFreightCost"
																				value={this.state.orderLine.rugInboundFreightCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugInboundFreightPrice"
																				value={this.state.orderLine.rugInboundFreightPrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasBacking &&
																<React.Fragment>
																	<Row>
																		<Col xs="8" className="rug-option-header">
																			Backing
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Sq Ft</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="totalRugBackingUnits"
																				value={this.state.orderLine.totalRugBackingUnits}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateRugBackingTotals()}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBackingCostPer"
																				required
																				value={this.state.orderLine.rugBackingCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalRugBackingCost"
																				required
																				value={this.state.orderLine.totalRugBackingCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBackingPricePer"
																				required
																				value={this.state.orderLine.rugBackingPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateRugBackingTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalRugBackingPrice"
																				value={this.state.orderLine.totalRugBackingPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBackingCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.rugBackingCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateRugBackingTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="rugBackingCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.rugBackingCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasPad &&
																<React.Fragment>
																	<Row>
																		<Col xs="1" className="rug-option-header">
																			Pad
																		</Col>
																		<Col xs="3" xl="4">
																			<Label className="m-0 p-0">Pad</Label>
																			<Select
																				options={this.state.padProductsList}
																				value={this.state.orderLine.selectedPad}
																				onChange={(option) => this.setSelectedPad(option)}
																				name="selectedPad"
																			/>
																		</Col>
																		<Col xs="3" xl="2">
																			<Label>
																				<input
																					type="checkbox"
																					name="hasPadCutCharge"
																					value={this.state.orderLine.hasPadCutCharge}
																					onChange={this.onHasPadCutChargeChange}
																					checked={this.state.orderLine.hasPadCutCharge}
																				/> Pad Cut Charge
																			</Label>
																			{this.state.orderLine.hasPadCutCharge &&
																				<Row>
																					<Col xs="6">
																						<Label className="m-0 p-0">Cut Cost</Label>
																						<Input
																							type="text"
																							className="m-0 p-0"
																							name="padCutCost"
																							value={this.state.orderLine.padCutCost}
																							readOnly
																						/>
																					</Col>
																					<Col xs="6">
																						<Label className="m-0 p-0">Cut Price</Label>
																						<Input
																							type="text"
																							className="m-0 p-0"
																							name="padCutPrice"
																							value={this.state.orderLine.padCutPrice}
																							onChange={(event) => this.handleRugOrderLineStateChange(
																								event.target.name,
																								event.target.value
																							)}
																							onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																								event.target.name,
																								event.target.value
																							)}
																						/>
																					</Col>
																				</Row>
																			}
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Width</Label>
																			<Select
																				options={this.state.padWidthList}
																				value={this.state.orderLine.selectedPadWidth}
																				onChange={this.handlePadWidthSelectChange.bind(this, "selectedPadWidth")}
																			/>
																			<Input
																				type="text"
																				className="form-control total-text"
																				name="totalPadUnits"
																				value={this.state.orderLine.totalPadUnits}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Length</Label>
																			<Input
																				type="text"
																				name="padLengthFeet"
																				id="padLengthFeet"
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.padSizeChange()}
																				required
																				value={this.state.orderLine.padLengthFeet}
																				placeholder="L'"
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				value={this.state.orderLine.selectedPad === null
																					? ''
																					: this.state.orderLine.padPriceUnitType
																						? this.state.orderLine.padPriceUnitType.label
																						: ''
																				}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="padCostPer"
																				required
																				value={this.state.orderLine.padCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalPadCost"
																				required
																				value={this.state.orderLine.totalPadCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="padPricePer"
																				required
																				value={this.state.orderLine.padPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updatePadTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalPadPrice"
																				value={this.state.orderLine.totalPadPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="padCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.padCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updatePadTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="padCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.padCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasClippedCorners &&
																<React.Fragment>
																	<Row>
																		<Col xs="10" className="rug-option-header">
																			Clipped Corners
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugClippedCornersCost"
																				value={this.state.orderLine.rugClippedCornersCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugClippedCornersPrice"
																				value={this.state.orderLine.rugClippedCornersPrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasLaborCutout &&
																<React.Fragment>
																	<Row>
																		<Col xs="10" className="rug-option-header">
																			Cutout Labor
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugLaborCutoutCost"
																				value={this.state.orderLine.rugLaborCutoutCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugLaborCutoutPrice"
																				value={this.state.orderLine.rugLaborCutoutPrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.orderLine.rugHasLaborRound &&
																<React.Fragment>
																	<Row>
																		<Col xs="10" className="rug-option-header">
																			Round/Oval Labor
																</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugLaborRoundCost"
																				value={this.state.orderLine.rugLaborRoundCost}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugLaborRoundPrice"
																				value={this.state.orderLine.rugLaborRoundPrice}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																					event.target.name,
																					event.target.value
																				)}
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.selectedRugEdge === 'Serging' &&
																<React.Fragment>
																	<Row className="mb-4">
																		<Col xs="5" className="rug-option-header">
																			Edge: {this.state.selectedRugEdge}
																		</Col>
																		<Col xs="3">
																			<Label className="m-0 p-0">Style</Label>
																			<Select
																				options={this.state.sergingList}
																				value={this.state.orderLine.selectedSerging}
																				onChange={this.sergingSelected.bind(this)}
																				name="selectedSerging"
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Fin Edge Ft</Label>
																			<Input
																				type="text"
																				name="finishedEdgeFt"
																				required
																				value={this.state.orderLine.finishedEdgeFt}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="sergingCostPer"
																				required
																				value={this.state.orderLine.sergingCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalSergingCost"
																				required
																				value={this.state.orderLine.totalSergingCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="sergingPricePer"
																				required
																				value={this.state.orderLine.sergingPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateSergingTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalSergingPrice"
																				value={this.state.orderLine.totalSergingPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="sergingCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.sergingCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateSergingTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="sergingCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.sergingCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.selectedRugEdge === 'Applied Machine Border' &&
																<React.Fragment>
																	<Row>
																		<Col xs="5" className="rug-option-header">
																			Edge: {this.state.selectedRugEdge}
																		</Col>
																		<Col xs="3">
																			<Label className="m-0 p-0">Style</Label>
																			<Select
																				options={this.state.mSergingList}
																				value={this.state.orderLine.selectedMSerging}
																				onChange={this.mSergingSelected.bind(this)}
																				name="selectedMSerging"
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Fin Edge Ft</Label>
																			<Input
																				type="text"
																				// className="feet-text"
																				name="finishedEdgeFt"
																				required
																				value={this.state.orderLine.finishedEdgeFt}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="mSergingCostPer"
																				required
																				value={this.state.orderLine.mSergingCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalMSergingCost"
																				required
																				value={this.state.orderLine.totalMSergingCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="mSergingPricePer"
																				required
																				value={this.state.orderLine.mSergingPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateMSergingTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalMSergingPrice"
																				value={this.state.orderLine.totalMSergingPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="mSergingCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.mSergingCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateMSergingTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="mSergingCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.mSergingCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{this.state.selectedRugEdge === 'Hidden Stitch Mitered Corners' &&
																<React.Fragment>
																	<Row>
																		<Col xs="5" className="rug-option-header">
																			Edge: {this.state.selectedRugEdge}
																		</Col>
																		<Col xs="3">
																			<Label className="m-0 p-0">Style</Label>
																			<Select
																				options={this.state.hsSergingList}
																				value={this.state.orderLine.selectedHSSerging}
																				onChange={this.hsSergingSelected.bind(this)}
																				name="selectedHSSerging"
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Fin Edge Ft</Label>
																			<Input
																				type="text"
																				name="finishedEdgeFt"
																				required
																				value={this.state.orderLine.finishedEdgeFt}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="hsSergingCostPer"
																				required
																				value={this.state.orderLine.hsSergingCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalHSSergingCost"
																				required
																				value={this.state.orderLine.totalHSSergingCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="hsSergingPricePer"
																				required
																				value={this.state.orderLine.hsSergingPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateHSSergingTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalHSSergingPrice"
																				value={this.state.orderLine.totalHSSergingPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="hsSergingCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.hsSergingCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateHSSergingTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="hsSergingCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.hsSergingCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
															{(this.state.selectedRugEdge === 'Hidden Stitch Mitered Corners' || this.state.selectedRugEdge === 'Applied Machine Border') &&
																<React.Fragment>
																	<Row className="mb-4">
																		{/* <Col xs="1"></Col> */}
																		<Col xs="4">
																			<Label className="m-0 p-0">Binding Style</Label>
																			<Select
																				options={this.state.rugBindingList}  // should be a rugBindingList
																				value={this.state.orderLine.selectedRugBindingStyle}
																				onChange={(option) => this.setSelectedRugBindingStyle(option)}
																				name="selectedRugBindingStyle"
																			/>
																			{this.state.selectedRugEdge === 'Hidden Stitch Mitered Corners' &&
																				<span style={{ fontWeight: "bold" }}>Remember to choose a binding wide enough!</span>
																			}
																		</Col>
																		<Col xs="3">
																			<Label className="m-0 p-0">Color</Label>
																			<Select
																				options={this.state.rugBindingColorList}
																				value={this.state.orderLine.selectedRugBindingColor}
																				onChange={this.handleRugFormSelectChange.bind(this, "selectedRugBindingColor")}
																				name="selectedRugBindingColor"
																			// (change)="setRugValidity()"
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Fin Edge Ft</Label>
																			<Input
																				type="text"
																				name="finishedEdgeFt"
																				required
																				value={this.state.orderLine.finishedEdgeFt}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cost</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBindingCostPer"
																				required
																				value={this.state.orderLine.rugBindingCostPer}
																				readOnly
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalRugBindingCost"
																				required
																				value={this.state.orderLine.totalRugBindingCost}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Price</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBindingPricePer"
																				required
																				value={this.state.orderLine.rugBindingPricePer}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateRugBindingTotals()}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="totalRugBindingPrice"
																				value={this.state.orderLine.totalRugBindingPrice}
																				readOnly
																			/>
																		</Col>
																		<Col>
																			<Label className="m-0 p-0">Cmsn %</Label>
																			<Input
																				type="text"
																				className="rt"
																				name="rugBindingCmsnRate"
																				required
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.rugBindingCmsnRate : "0.0"}
																				onChange={(event) => this.handleRugOrderLineStateChange(
																					event.target.name,
																					event.target.value
																				)}
																				onBlur={() => this.updateRugBindingTotals()}
																				disabled={!this.state.orderLine.productAllowCommission}
																			/>
																			<Input
																				type="text"
																				className="total-text"
																				name="rugBindingCmsn"
																				value={this.state.orderLine.productAllowCommission ? this.state.orderLine.rugBindingCmsn : "0.0"}
																				readOnly
																			/>
																		</Col>
																	</Row>
																</React.Fragment>
															}
														</Col>
													</Row>
												}
												{this.state.editingFreight && (
													<div>
														<h5><Badge className="mb-2 primary">Freight</Badge></h5>
														<Row>
															<Col>Description {helpers.requiredStar()}
																<Input
																	type="text"
																	name="freightDsc"
																	value={this.state.orderLine.freightDsc}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="250"
																/>
															</Col>
															<Col>Freight Carrier {helpers.requiredStar()}
																<Select
																	options={this.state.freightCarrierList}
																	value={this.state.orderLine.freightCarrier}
																	onChange={this.handleFormSelectChange.bind(this, "freightCarrier")}
																/>
															</Col>
															<Col>Freight Terms
															<Select
																	options={this.state.freightTermsList}
																	value={this.state.orderLine.freightTerms}
																	onChange={this.handleFormSelectChange.bind(this, "freightTerms")}
																/>
															</Col>
															<Col>Sidemark
																<Input
																	type="text"
																	name="freightSidemark"
																	value={this.state.orderLine.freightSidemark}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="50"
																/>
															</Col>
														</Row>
														<Row>
															<Col>Freight Inv. #
																<Input
																	type="text"
																	name="freightInvoiceNumber"
																	value={this.state.orderLine.freightInvoiceNumber}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="20"
																/>
															</Col>
															<Col>BOL #
																<Input
																	type="text"
																	name="freightBolNumber"
																	value={this.state.orderLine.freightBolNumber}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="20"
																/>
															</Col>
															<Col>Cost {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="freightCost"
																	value={this.state.orderLine.freightCost}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col>Price {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="freightPrice"
																	value={this.state.orderLine.freightPrice}
																	onChange={(event) => this.handleOrderLineStateChange(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderLineRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
														</Row>
														<Row>
															<Col xs="6">Select Ship To Contact
															<Select
																	options={this.state.customerContacts}
																	value={this.state.customerShipppingContact}
																	onChange={this.setSelectedShippingContact}
																/>
															</Col>
															<Col xs="6">Select Ship To Address
															<Select
																	options={this.state.orderLine.shippingAddresses || this.state.customerShipToAddresses}  // if contact is selected, orderLine.shippingAddresses contains its ship to addresses
																	value={this.state.selectedShippingAddress}
																	onChange={this.setSelectedShippingAddress}
																/>
															</Col>
														</Row>
														<Row>
															<Col>
																<Row>
																	<Col>Contact First Name
																	<Input
																			type="text"
																			name="stContactFirstName"
																			value={this.state.orderLine.stContactFirstName}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="25"
																		/>
																	</Col>
																	<Col>Contact Last Name
																	<Input
																			type="text"
																			name="stContactLastName"
																			value={this.state.orderLine.stContactLastName}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="25"
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col>Contact Phone Number
																	<Input
																			type="text"
																			name="stPhone"
																			value={this.state.orderLine.stPhone}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="20"
																		/>
																	</Col>
																	<Col>Contact Email
																	<Input
																			type="text"
																			name="stContactEmail"
																			value={this.state.orderLine.stContactEmail}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="150"
																		/>
																	</Col>
																</Row>
															</Col>
															<Col>
																<Row>
																	<Col>Ship To Name
																	<Input
																			type="text"
																			name="stAddressLine1"
																			value={this.state.orderLine.stAddressLine1}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="100"
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col>Additional Name &#40;Optional&#41;
																	<Input
																			type="text"
																			name="stAddressLine2"
																			value={this.state.orderLine.stAddressLine2}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="100"
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col>Shipping Address
																	<Input
																			type="text"
																			name="stAddressLine3"
																			value={this.state.orderLine.stAddressLine3}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="100"
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col>Address Line 2
																	<Input
																			type="text"
																			name="stAddressLine4"
																			value={this.state.orderLine.stAddressLine4}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="100"
																		/>
																	</Col>
																</Row>
																<Row className="mb-4">
																	<Col>City
																	<Input
																			type="text"
																			name="stCity"
																			value={this.state.orderLine.stCity}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="35"
																		/>
																	</Col>
																	<Col>State
																	<Input
																			type="text"
																			name="stState"
																			value={this.state.orderLine.stState}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="5"
																		/>
																	</Col>
																	<Col>Zip
																	<Label></Label>
																		<Input
																			type="text"
																			name="stZip"
																			value={this.state.orderLine.stZip}
																			onChange={(event) => this.handleOrderLineStateChange(
																				event.target.name,
																				event.target.value
																			)}
																			maxLength="10"
																		/>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												)}
												{this.state.editingNotes && (
													<div>
														<h5><Badge className="mb-2 primary">Notes</Badge></h5>
														<Row className="mb-4">
															<Col xs="6">Comment {helpers.requiredStar()}
																<Input
																	type="text"
																	name="dsc"
																	value={this.state.newOrderLineComment.dsc}
																	onChange={(event) => this.onChangeOrderLineComment(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="250"
																/>
															</Col>
															<Col xs="1">
																<Label className="mt-4">for</Label>
															</Col>
															<Col xs="3">{helpers.requiredStar()}
																<Select
																	options={this.state.commentTypeList}
																	value={this.state.newOrderLineComment.commentType}
																	onChange={this.handleOrderLineCommentSelectionChange.bind(this, "commentType")}
																/>
															</Col>
															<Col xs="1">Is Private
																<ToggleButton
																		value={this.state.newOrderLineComment.isPrivate}
																		activeLabel={"Yes"}
																		inactiveLabel={"No"}
																		onToggle={(value) => {
																			this.setState(prevState => ({
																				...prevState,
																				newOrderLineComment: {
																					...prevState.newOrderLineComment,
																					isPrivate: !value
																				}
																			}));
																		}}
																	/>
															</Col>
															<Col xs="1">
																<Button
																	className="success mt-3"
																	size="sm"
																	onClick={() => this.onSaveNote()}
																>
																	<FontAwesomeIcon icon="plus" />
																</Button>
															</Col>
														</Row>
														<Row className="mb-3">
															<Col>
																<Table>
																	<thead>
																		<tr>
																			<th>Applies To</th>
																			<th>Description</th>
																			<th>Is Private?</th>
																			<th></th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.state.orderLineCommentList.map(c => (
																			<tr key={c.local_id}>{/* ? c.id : create_UUID()}> */}
																				{c.isEditing
																					? <React.Fragment>
																						<td>
																							<Select
																								options={this.state.commentTypeList}
																								value={c.commentType}
																								onChange={(e) => this.updateCommentTypeField(e, c.local_id)}
																							/>
																						</td>
																						<td>
																							<Input
																								type="text"
																								name="dsc"
																								value={c.dsc}
																								onChange={(event) => this.updateCommentDscField(
																									event.target.name,
																									event.target.value,
																									c.local_id
																								)}
																								maxLength="250"
																							/>
																						</td>
																						<td>
																							<ToggleButton
																								value={c.isPrivate}
																								activeLabel={"Yes"}
																								inactiveLabel={"No"}
																								onToggle={(value) => this.updateCommentIsPrivateField(
																									value,
																									c.local_id
																								)}
																							/>
																						</td>
																						<td className="text-right">
																							<ButtonGroup>
																								<Button
																									className="primary btn"
																									onClick={() => this.onSaveEditedComment(c.local_id)}
																									size="sm"
																								>
																									<FontAwesomeIcon icon="save" /> Save
																							</Button>
																								<Button
																									className="secondary btn"
																									onClick={() => this.onCancelEditComment(c.local_id)}
																									size="sm"
																								>
																									<FontAwesomeIcon icon="times-circle" /> Cancel
																							</Button>
																							</ButtonGroup>
																						</td>
																					</React.Fragment>
																					: <React.Fragment>
																						{c.deactivatedAt || c.markedForDeletion
																							? null
																							: <React.Fragment>
																								<td>{c.commentType.label}</td>
																								<td>{c.dsc}</td>
																								<td>{c.isPrivate ? "Yes" : "No"}</td>
																								<td className="text-right">
																									<ButtonGroup>
																										<Button
																											className="primary btn-outline-secondary"
																											size="sm"
																											onClick={this.onEditComment.bind(this, c.local_id)}
																											title="Edit comment"
																										>
																											<FontAwesomeIcon icon="edit" />
																										</Button>
																										{c.deactivatedAt ? (
																											<Button
																												className="info"
																												size="sm"
																												// onClick={this.handleOrderLineUndelete.bind(this, orderLine.id)}
																												title="Revive comment"
																											>
																												<FontAwesomeIcon icon="recycle" /> Revive
																											</Button>
																										) : (
																												<Button
																													className="danger btn-outline-secondary"
																													size="sm"
																													onClick={this.onDeleteComment.bind(this, c.local_id)}
																													title="Delete comment"
																												>
																													<FontAwesomeIcon icon="trash" />
																												</Button>
																											)}
																									</ButtonGroup>
																								</td>
																							</React.Fragment>
																						}
																					</React.Fragment>
																				}
																			</tr>
																		))}
																	</tbody>
																</Table>
															</Col>
														</Row>
													</div>
												)}
												<Row className="mb-4">
													<Col>
														<ButtonGroup size="sm" className="float-left">
															{!ORDER_LINE_TYPES_DONT_SHOW_FREIGHT.includes(this.state.orderLine.orderLineType.value) &&
																<Button
																	className="distinctiveLight"
																	onClick={() => this.onToggleFreight()}
																>
																	{this.state.editingFreight ? 'Remove Freight' : 'Add Freight'}
																</Button>
															}
															{this.state.orderLine.orderLineType.value !== constants.ORDER_LINE_TYPE.CUSTOM_RUG.value &&
																<Button
																	className="secondaryGray"
																	onClick={() => this.onToggleNotes()}
																>
																	{this.state.editingNotes ? 'Remove Notes' : 'Add Notes'}
																</Button>
															}
														</ButtonGroup>
														<ButtonGroup size="sm" className="float-right">
															<Button
																className="primary"
																disabled={this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
																	&& !this.state.orderLine.rugEditsValid}
																onClick={this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
																	? () => this.saveCustomRugOrderLine()
																	: () => this.saveOrderLine()
																}
															>
																<FontAwesomeIcon icon="save" /> Save Line Item
															</Button>
															<Button
																className="secondary"
																onClick={() => this.cancelAddOrUpdateOrderLine()}
															>
																<FontAwesomeIcon icon="times-circle" /> Cancel
															</Button>
														</ButtonGroup>
													</Col>
												</Row>
											</React.Fragment>
											}
											{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value &&
												<React.Fragment>
													<Row>
														<Col xs="3">Line Type {helpers.requiredStar()}
															<Select
																options={this.state.orderLineTypeList}
																value={this.state.orderLine.orderLineType}
																onChange={this.handleOrderLineTypeChange.bind(this)}
																isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
															/>
														</Col>
														<Col xs="3">Credit Card Surcharge Rate (%) {helpers.requiredStar()}
															<Input
																type="number"
																step="0.001"
																name="creditCardSurchargeRate"
																value={this.state.orderLine.creditCardSurchargeRate}
																onChange={(event) => this.handleOrderLineStateChange(
																	event.target.name,
																	event.target.value
																)}
																onBlur={() => this.calculateFigures()}
															/>
														</Col>
													</Row>
													<Row className="mb-4">
														<Col>
															<ButtonGroup size="sm" className="float-right">
																<Button
																	className="primary"
																	onClick={() => this.saveOrderLine()}
																>
																	<FontAwesomeIcon icon="save" /> Save Line Item
															</Button>
																<Button
																	className="secondary"
																	onClick={() => this.cancelAddOrUpdateOrderLine()}
																>
																	<FontAwesomeIcon icon="times-circle" /> Cancel
															</Button>
														</ButtonGroup>
													</Col>
												</Row>
											</React.Fragment>
										}
										{this.state.orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.SALES_TAX.value &&
											<React.Fragment>
												<Row>
													<Col xs="3">Line Type {helpers.requiredStar()}
														<Select
															options={this.state.orderLineTypeList}
															value={this.state.orderLine.orderLineType}
															onChange={this.handleOrderLineTypeChange.bind(this)}
															isDisabled={this.state.orderLine.id !== 0} // if updating order line, do not allow order line type change
														/>
													</Col>
													<Col xs="3">Tax Rate (%) {helpers.requiredStar()}
														<Input
															type="number"
															step="0.001"
															name="taxRate"
															value={this.state.orderLine.taxRate}
															onChange={(event) => this.handleOrderLineStateChange(
																event.target.name,
																event.target.value
															)}
															onBlur={this.calculateFigures}
															//onBlur={() => this.calculateFigures()}
														/>
													</Col>
												</Row>
												<Row className="mb-4">
													<Col>
														<ButtonGroup size="sm" className="float-right">
															<Button
																className="primary"
																onClick={() => this.saveOrderLine()}
															>
																<FontAwesomeIcon icon="save" /> Save Line Item
															</Button>
																<Button
																	className="secondary"
																	onClick={() => this.cancelAddOrUpdateOrderLine()}
																>
																	<FontAwesomeIcon icon="times-circle" /> Cancel
															</Button>
															</ButtonGroup>
														</Col>
													</Row>
												</React.Fragment>
											}
										</CardBody>
									</Card>
								</Collapse>

								<Row className="mt-5 mb-5">
									<Col>
										<Row>
											<Col sm="8">
												<h5>
													<Badge className="mb-2 primary">
														{this.state.customRugToViewEdit ? 'Current Saved Custom Rug Components' : 'Current Saved Line Items'}
													</Badge>
												</h5>
											</Col>
											{this.state.customRugToViewEdit &&
												<Col sm="4">
													<Button
														size="sm"
														className="secondary float-right"
														disabled={this.state.showOrderLineForm}
														onClick={() => this.returnToMainOrderLineScreen()}
													>
														<FontAwesomeIcon icon="reply" /> Back
													</Button>
												</Col>
											}
										</Row>

										<Table hover size="sm">
											<thead>
												<tr>
													<th></th>
													<th>{this.state.customRugToViewEdit ? 'Component Type' : 'Line Type'}</th>
													<th>Description</th>
													<th>Total Cost</th>
													<th>Total Price</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{orderLinesToMapOver && orderLinesToMapOver.map(orderLine => (
												<React.Fragment key={orderLine.id}>
													<tr>
														<td>{orderLine.id}</td>
														<td>{orderLine.orderLineType.label}</td>
															<td>
																{orderLine.dsc}
																{orderLine.orderLineComments && orderLine.orderLineComments.length > 0 ?
																	_.filter(orderLine.orderLineComments, oc => { return !oc.isPrivate}).map(c => (
																		<div key={c.id}><span className="boldText">{c.dsc}</span> - added @ {moment(c.createdAt).format("M/DD/YY h:mm a")}</div>
																	))
																: null}
															</td>
														<td>{helpers.formatCurrency(orderLine.cost)}</td>
														<td>{helpers.formatCurrency(orderLine.price)}</td>
														<td className="text-right">
															<ButtonGroup>
																<Button
																	className="primary btn-outline-secondary"
																	size="sm"
																	onClick={orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
																		? this.updateCustomRug.bind(this, orderLine)
																		: this.updateOrderLine.bind(this, orderLine)
																	}
																	title="Edit line item"
																>
																	<FontAwesomeIcon icon="edit" />
																</Button>
																{!orderLine.deactivatedAt ? (
																	<Button
																		className="danger btn-outline-secondary"
																		size="sm"
																		onClick={
																			this.showOrderLineDeleteConfirmationModal.bind(
																				this,
																				orderLine.id,
																				orderLine.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
																					? MODAL_DELETE_CONFIRMATION_TYPES.CUSTOM_RUG_ORDER_LINE
																					: MODAL_DELETE_CONFIRMATION_TYPES.NORMAL_ORDER_LINE,
																				orderLine.componentLines)
																		}
																		title="Delete line item"
																	>
																		<FontAwesomeIcon icon="trash" />
																	</Button>
																) : (
																		<Button
																			className="info"
																			size="sm"
																			onClick={this.handleOrderLineUndelete.bind(this, orderLine.id)}
																			title="Revive line item"
																		>
																			<FontAwesomeIcon icon="recycle" /> Revive
																		</Button>
																	)}
															</ButtonGroup>
														</td>
													</tr>
														{orderLine.componentLines &&
															<React.Fragment>
																{orderLine.componentLines.map(componentLine => (
																	<tr key={componentLine.id} style={{ background: "#eee" }}>
																		<td></td>
																		<td>{componentLine.orderLineType.label}</td>
																		<td>{componentLine.dsc}</td>
																		<td>${componentLine.cost}</td>
																		<td>${componentLine.price}</td>
																		<td></td>
																	</tr>
																))}
															</React.Fragment>
														}
													</React.Fragment>
												))}
											</tbody>
										</Table>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId={TABS.PAYMENT}>
								{this.state.message
									? <Alert2 className={this.state.messageFlavor}>{this.state.message}</Alert2>
									: null
								}
								<Row>
									<Col>
										<h5><Badge className="mb-2 primary">Billing Info</Badge></h5>
									</Col>
								</Row>
								<Row>
									<Col xs="8">
										<h3 style={{ fontStyle: "italic" }}>{this.state.customerInfo ? this.state.customerInfo.name : ''}</h3>
									</Col>
									<Col>
										<h4>Account Credit Line:
											${this.state.customerInfo ? this.state.customerInfo.creditLine : ''}
										</h4>
									</Col>
								</Row>
								<Row>
									<Col xs="12" md="4">Account PO#
										<Input
											type="text"
											name="customerPONumber"
											value={this.state.order.customerPONumber}
											onChange={(event) => this.handleOrderInputChange(
												event.target.name,
												event.target.value
											)}
											maxLength="20"
										/>
									</Col>
								</Row>
								{/*<Row>
									<Col xs="3">Select Bill To Contact
										<Select
											options={this.state.customerContacts}
											value={this.state.customerBillingContact}
											onChange={this.setSelectedBillingContact}
										/>
									</Col>
									<Col xs="6">Select Bill To Address
										<Select
											//options={this.state.order.billingAddresses}
											options={this.state.customerAddressList} //SACHA
											value={this.state.selectedBillingAddress}
											onChange={this.setSelectedBillingAddress}
										/>
									</Col>
								</Row>*/}
								<Row>
									<Col className="mr-3">
										<Row>
											<Col>Select Bill To Address
										<Select
													//options={this.state.order.billingAddresses}
													options={this.state.customerAddressList} //SACHA
													value={this.state.selectedBillingAddress}
													onChange={this.setSelectedBillingAddress}
												/>
											</Col>
										</Row>
										<Row>
											<Col>Bill To Name {helpers.requiredStar()}
												<Input
													type="text"
													name="btAddressLine1"
													value={this.state.order.btAddressLine1}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="100"
												/>
											</Col>
										</Row>
										<Row>
											<Col>Additional Name
												<Input
													type="text"
													name="btAddressLine2"
													value={this.state.order.btAddressLine2}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="250"
												/>
											</Col>
										</Row>
										<Row>
											<Col>Billing Address {helpers.requiredStar()}
												<Input
													type="text"
													name="btAddressLine3"
													value={this.state.order.btAddressLine3}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="250"
												/>
											</Col>
										</Row>
										<Row>
											<Col>Address Line 2
												<Input
													type="text"
													name="btAddressLine4"
													value={this.state.order.btAddressLine4}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="250"
												/>
											</Col>
										</Row>
										<Row className="mb-4">
											<Col>City {helpers.requiredStar()}
												<Input
													type="text"
													name="btCity"
													value={this.state.order.btCity}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="35"
												/>
											</Col>
											<Col>State {helpers.requiredStar()}
												<Input
													type="text"
													name="btState"
													value={this.state.order.btState}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="5"
												/>
											</Col>
											<Col>Zip {helpers.requiredStar()}
												<Label></Label>
												<Input
													type="text"
													name="btZip"
													value={this.state.order.btZip}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="10"
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col >
												<Button
													className="dark float-right"
													size="sm"
													onClick={() => this.saveBillingInfo()}
												>
													<FontAwesomeIcon icon="save" /> Save Billing Info
												</Button>
											</Col>
										</Row>
									</Col>
									<Col className="ml-3">
										{/*
										<Row>
											<Col>Contact First Name {helpers.requiredStar()}
												<Input
													type="text"
													name="btContactFirstName"
													value={this.state.order.btContactFirstName}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="25"
												/>
											</Col>
											<Col>Contact Last Name {helpers.requiredStar()}
												<Input
													type="text"
													name="btContactLastName"
													value={this.state.order.btContactLastName}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="25"
												/>
											</Col>
										</Row>
										<Row>
											<Col>Contact Phone Number {helpers.requiredStar()}
												<Input
													type="text"
													name="btPhone"
													value={this.state.order.btPhone}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="20"
												/>
											</Col>
											<Col>Contact Fax
												<Input
													type="text"
													name="btFax"
													value={this.state.order.btFax}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="20"
												/>
											</Col>
										</Row>
										<Row>
											<Col>Contact Email {helpers.requiredStar()}
												<Input
													type="text"
													name="btContactEmail"
													value={this.state.order.btContactEmail}
													onChange={(event) => this.handleOrderInputChange(
														event.target.name,
														event.target.value
													)}
													maxLength="150"
												/>
											</Col>
										</Row>
										*/}
										<Row className="mt-5">
											<Col>
												<h5><Badge className="mb-2 primary">Total Due</Badge></h5>
												<h4>{this.state.totalOrderPrice}</h4>
											</Col>
											<Col>
												<h5><Badge className="mb-2 primary">Total Posted</Badge></h5>
												<h4>{this.state.sumOfPayments}</h4>
											</Col>
											<Col>
												<h5><Badge className="mb-2 primary">Balance</Badge></h5>
												<h4>{this.state.balance}</h4>
											</Col>
										</Row>
									</Col>
								</Row>
								<Collapse isOpen={this.state.addUpdatePayment} className="row-spacing">
									<Card>
										<CardBody>
											<Row className="row-spacing">
												<Col className="px-3">
													<Row>
														<Col xs="2">
															<h5><Badge className="mb-2 primary">Payment Info</Badge></h5>
														</Col>
													</Row>
													<Row>
														<Col>Payment Type {helpers.requiredStar()}
															<Select
																options={this.state.paymentTypeList}
																value={this.state.orderPayment.paymentType}
																onChange={this.handlePaymentTypeChange.bind(this, "paymentType")}
															/>
														</Col>
														{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CREDIT.value ?
															(<Col>Credit Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	min="0"
																	value={this.state.orderPayment.amountPaid}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>) : null
														}

														<Col xs="12" md="4">Invoice Terms
															<Input
																type="text"
																name="invoiceTerms"
																value={this.state.order.invoiceTerms ? this.state.order.invoiceTerms.label : ''}
																readOnly
															/>
														</Col>
													</Row>
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CASH.value && (
														<Row>
															<Col>Cash Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	min='0'
																	value={this.state.orderPayment.amountPaid ? this.state.orderPayment.amountPaid : ''}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col />
															<Col />
														</Row>
													)}
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.EXTENDED_CREDIT.value && (
														<Row>
															<Col>Credit Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	value={this.state.orderPayment.amountPaid ? this.state.orderPayment.amountPaid : ''}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col>Credit Type {helpers.requiredStar()}
																<Input
																	type="text"
																	name="creditType"
																	value={this.state.customerInfo ? this.state.customerInfo.customerCreditTypeName : ''}
																	readOnly
																/>
															</Col>
															<Col>Credit Line {helpers.requiredStar()}
																<Input
																	type="text"
																	name="creditLine"
																	value={this.state.customerInfo ? this.state.customerInfo.creditLine : ''}
																	readOnly
																/>
															</Col>
														</Row>
													)}
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CREDIT_CARD.value && (
														<Row className="mb-4">
															<Col>Charge Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	value={this.state.orderPayment.amountPaid}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col>Card Type {helpers.requiredStar()}
																<Select
																	options={this.state.creditCardTypeList}
																	value={this.state.orderPayment.creditCardType}
																	onChange={this.handleCreditCardTypeChange.bind(this, "creditCardType")}
																/>
															</Col>
															<Col>Date Received {helpers.requiredStar()}
																<DatePicker
																	name="dateReceived"
																	selected={this.state.orderPayment.dateReceived ? this.state.orderPayment.dateReceived : ""}
																	onChange={this.handleDateReceivedChange}
																	className="form-control"
																/>
															</Col>
															<Col sm="1">Exp Date {helpers.requiredStar()}
																<Input
																	type="text"
																	name="expDate"
																	value={this.state.orderPayment.expDate || ""}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="7"
																/>
															</Col>
															<Col sm="2">Card Number {helpers.requiredStar()}
																<Input
																	type="text"
																	name="cardNumber"
																	value={this.state.orderPayment.cardNumber}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="25"
																/>
															</Col>
															<Col>Billing Name {helpers.requiredStar()}
																<Input
																	type="text"
																	name="billingName"
																	value={this.state.orderPayment.billingName}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="255"
																/>
															</Col>
															<Col>Billing Zip {helpers.requiredStar()}
																<Input
																	type="text"
																	name="billingZip"
																	value={this.state.orderPayment.billingZip}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="11"
																/>
															</Col>
														</Row>
													)}
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.REBATE.value && (
														<Row className="mb-4">
															<Col>Rebate Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	value={this.state.orderPayment.amountPaid}
																	// max={this.state.customerOutstandingCredit}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col>Available Rebates
																<Select
																	options={this.state.customerOutstandingCredits}
																	value={this.state.orderPayment.customerOutstandingCredit}
																	onChange={this.handleOutstandingCreditChange.bind(this, "customerOutstandingCredit")}
																/>
															</Col>
														</Row>
													)}
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CHECK.value && (
														<Row>
															<Col>Check Amount {helpers.requiredStar()}
																<Input
																	type="number"
																	step="0.01"
																	name="amountPaid"
																	value={this.state.orderPayment.amountPaid}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	onBlur={(event) => this.updateOrderPaymentRateOrCurrencyField(
																		event.target.name,
																		event.target.value
																	)}
																/>
															</Col>
															<Col>Check Number {helpers.requiredStar()}
																<Input
																	type="text"
																	name="checkNumber"
																	value={this.state.orderPayment.checkNumber}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="50"
																/>
															</Col>
															<Col>Date Received
																<DatePicker
																	id="dateReceived"
																	selected={this.state.orderPayment.dateReceived ? this.state.orderPayment.dateReceived : ""}
																	onChange={this.handleDateReceivedChange}
																	className="form-control"
																/>
															</Col>
														</Row>
													)}
													{this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.EXTENDED_CREDIT.value ||
														this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CHECK.value ||
														this.state.orderPayment.paymentType.value === constants.PAYMENT_TYPE.CREDIT.value
														? <Row className="mt-0 mb-4">
															<Col>Notes
																		<Input
																	type="textarea"
																	name="comment"
																	value={this.state.orderPayment.comment}
																	onChange={(event) => this.handleOrderPaymentStateUpdate(
																		event.target.name,
																		event.target.value
																	)}
																	maxLength="300"
																/>
															</Col>
														</Row>
														: null
													}
													<Row className="mb-2">
														<Col>
															<ButtonGroup size="sm" className="float-right">
																<Button
																	className="primary"
																	onClick={() => this.saveOrderPayment()}
																>
																	<FontAwesomeIcon icon="save" /> Save Payment
																</Button>
																<Button
																	className="secondary"
																	onClick={() => this.cancelAddOrUpdatePayment()}
																>
																	<FontAwesomeIcon icon="times-circle" /> Cancel
																</Button>
															</ButtonGroup>
														</Col>
													</Row>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Collapse>
								<Row className="mt-5 mb-1">
									<Col>
										<h5>
											<Badge className="mb-2 primary">
												Current Saved Payments
											</Badge>
										</h5>
									</Col>
									<Col>
										{this.state.addUpdatePayment
											? null
											: <Button
															className="float-right success"
															size="sm"
															onClick={() => this.onAddNewPayment()}
															disabled={this.state.addUpdatePayment}
															title="Add new payment"
														>
															<FontAwesomeIcon icon="plus-circle" /> Add Payment
													</Button>
											}
									</Col>
								</Row>
								<Row>
									<Col>
										<Table striped hover responsive size="sm">
											<thead>
												<tr>
													<th>Type</th>
													<th>Received</th>
													<th>Amount</th>
													<th />
												</tr>
											</thead>
											<tbody>
												{this.state.orderPayments.map(p => (
													<React.Fragment key={p.id}>
													{p.deactivatedAt 
														? null
														: <tr>
																<td>{p.paymentType ? p.paymentType.label : ""}</td>
																<td>
																	{p.dateReceived
																		? date_helpers.dateTimeFormat(p.dateReceived, "MM/DD/YYYY hh:mm A")
																		: date_helpers.dateTimeFormat(p.createdAt, "MM/DD/YYYY hh:mm A")}
																</td>
																<td>{p.paymentType ? p.paymentType.value === constants.PAYMENT_TYPE.CREDIT.value && "-" : ""}{helpers.formatCurrency(p.amountPaid)}</td>
																<td>
																	<ButtonGroup className="float-right">
																		<Button
																			className="primary btn-outline-secondary"
																			size="sm"
																			onClick={this.updateOrderPayment.bind(this, p)}
																		>
																			<FontAwesomeIcon icon="edit" />
																		</Button>
																		{p.deactivatedAt ? (
																			null
																			// <Button
																			// 		className="info"
																			// 		size="sm"
																			// 		onClick={this.handleUndelete.bind(this, p.id)}
																			// 	>
																			// 		<FontAwesomeIcon icon="recycle" /> Revive
																			// 	</Button>
																		) : (
																			<Button
																				className="danger btn-outline-secondary"
																				size="sm"
																				onClick={this.handleDelete.bind(this, p.id)}
																			>
																				<FontAwesomeIcon icon="trash" />
																			</Button>
																		)}
																	</ButtonGroup>
																</td>
															</tr>
													}
													</React.Fragment>
												))}
											</tbody>
										</Table>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId={TABS.FULLFILLMENT}>
								{this.state.message
									? <Alert2 className={this.state.messageFlavor}>{this.state.message}</Alert2>
									: null}
								{this.state.addNewFulfillment
									? null
									: <Row className="row-spacing">
										<Col>
											<Button
												className="float-right success"
												size="sm"
												onClick={() => this.addNewFulfillment()}
											>
												<FontAwesomeIcon icon="plus" /> Add Fulfillment Line
												</Button>
										</Col>
									</Row>
								}
								<Collapse isOpen={this.state.addNewFulfillment}>
									<Card>
										<CardBody>
											<Row>
												<Col>
													<Label check>Is Pay To Supplier?</Label>
													<ToggleButton
														value={this.state.orderFulfillment.isPayToSupplier}
														activeLabel={"Yes"}
														inactiveLabel={"No"}
														onToggle={(value) => {
															this.setState(prevState => ({
																...prevState,
																orderFulfillment: {
																	...prevState.orderFulfillment,
																	isPayToSupplier: !value
																}
															}));
														}}
													/>
												</Col>
											</Row>
											<Row className="row-spacing">
												<Col>Status
													<Select
														options={this.state.orderFulfillmentStatusList}
														value={this.state.orderFulfillment.orderFulfillmentStatus}
														onChange={this.handleOrderFullfillmentSelectChange.bind(this, "orderFulfillmentStatus")}
													/>
												</Col>
												<Col>Pay To {this.state.orderFulfillment.isPayToSupplier ? "Supplier" : "Freight Carrier"}
													{this.state.orderFulfillment.isPayToSupplier ? (
														<Select
															options={this.state.supplierList}
															value={this.state.orderFulfillment.supplier}
															onChange={this.handleOrderFullfillmentSelectChange.bind(this, "supplier")}
														/>
													) : (
															<Select
																options={this.state.freightCarrierList}
																value={this.state.orderFulfillment.freightCarrier}
																onChange={this.handleOrderFullfillmentSelectChange.bind(this, "freightCarrier")}
															/>
														)}
												</Col>
												<Col>Ref#
													<Input
														type="text"
														name="referenceNumber"
														value={this.state.orderFulfillment.referenceNumber}
														onChange={(event) => this.handleFulfillmentStateUpdate(
															event.target.name,
															event.target.value
														)}
														maxLength="255"
													/>
												</Col>
												<Col>Inv#
													<Input
														type="text"
														name="invoiceNumber"
														value={this.state.orderFulfillment.invoiceNumber}
														onChange={(event) => this.handleFulfillmentStateUpdate(
															event.target.name,
															event.target.value
														)}
														maxLength="255"
													/>
												</Col>
												<Col>Chk#
													<Input
														type="text"
														name="checkNumber"
														value={this.state.orderFulfillment.checkNumber}
														onChange={(event) => this.handleFulfillmentStateUpdate(
															event.target.name,
															event.target.value
														)}
														maxLength="255"
													/>
												</Col>
												<Col>Amt
													<Input
														type="text"
														name="amountDue"
														value={this.state.orderFulfillment.amountDue}
														onChange={(event) => this.handleFulfillmentStateUpdate(
															event.target.name,
															event.target.value
														)}
													/>
												</Col>
											</Row>
											<Row className="row-spacing">
												<Col>Notes
													<Input
														type="text"
														name="notes"
														value={this.state.orderFulfillment.notes}
														onChange={(event) => this.handleFulfillmentStateUpdate(
															event.target.name,
															event.target.value
														)}
														maxLength="255"
													/>
												</Col>
											</Row>
											<Row className="row-spacing">
												<Col>
													<ButtonGroup className="float-right">
														<Button
															className="primary"
															size="sm"
															onClick={() => this.saveOrderFulfillment(this.state.orderFulfillment)}
														>
															<FontAwesomeIcon icon="save" /> Save
														</Button>
														<Button
															className="secondary"
															size="sm"
															onClick={() => this.addNewFulfillment()}
														>
															<FontAwesomeIcon icon="times-circle" /> Cancel
														</Button>
													</ButtonGroup>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Collapse>
								<Row style={{ paddingTop: "15px" }}>
									<Col>
										<Table striped hover responsive size="sm">
											<thead>
												<tr>
													<th>Status</th>
													<th>Pay To / Line Dsc</th>
													<th>Ref#</th>
													<th>Inv#</th>
													<th>Chk#</th>
													<th>Amt Due</th>
													<th>Amt Post</th>
													<th>Dates</th>
													<th>Notes</th>
													<th />
												</tr>
											</thead>
											<tbody>
												{this.state.orderFulfillments.map(fulfillment => (
													<tr key={fulfillment.id}>
														<td>
															{fulfillment.isEditing ? (
																<Select
																	options={this.state.orderFulfillmentStatusList}
																	value={fulfillment.orderFulfillmentStatus}
																	onChange={(e) => this.updateOrderFulfillmentStatus(e, fulfillment.id)}
																/>
															) : (
																	fulfillment.orderFulfillmentStatus.label
																)}
														</td>
														<td>
															<React.Fragment>
																<p>{fulfillment.supplierPayToName || fulfillment.freightCarrierName}</p>
																{fulfillment.orderFulfillmentOrderLines.map(ol => (
																	<p key={ol.orderLineId} style={{ color: "blue" }}>{ol.orderLineDsc} - <span style={{ color: "black" }}><strong>${ol.orderLineCost}</strong></span></p>
																))}
															</React.Fragment>
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="referenceNumber"
																	value={fulfillment.referenceNumber}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																	maxLength="255"
																/>
															) : (
																	fulfillment.referenceNumber
																)}
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="invoiceNumber"
																	value={fulfillment.invoiceNumber}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																	maxLength="255"
																/>
															) : (
																	fulfillment.invoiceNumber
																)}
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="checkNumber"
																	value={fulfillment.checkNumber}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																	maxLength="255"
																/>
															) : (
																	fulfillment.checkNumber
																)}
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="amountDue"
																	value={fulfillment.amountDue}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																/>
															) : (
																	`${helpers.formatCurrency(fulfillment.amountDue)}`
																)}
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="amountPosted"
																	value={fulfillment.amountPosted}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																/>
															) : (
																	`${fulfillment.amountPosted !== null ? helpers.formatCurrency(fulfillment.amountPosted) : 0}`
																)}
														</td>
														<td>
															{fulfillment.isEditing
																? <React.Fragment>
																	<Label>Bill</Label>
																	<div>
																		<DatePicker
																			id="billDate"
																			selected={fulfillment.billDate ? fulfillment.billDate : ""}
																			onChange={this.editFulfillmentDateChange.bind(this, "billDate", fulfillment.id)}
																			className="form-control"
																		/>
																	</div>
																	<Label>Due</Label>
																	<div>
																		<DatePicker
																			id="dueDate"
																			selected={fulfillment.dueDate ? fulfillment.dueDate : ""}
																			onChange={this.editFulfillmentDateChange.bind(this, "dueDate", fulfillment.id)}
																			className="form-control"
																		/>
																	</div>
																	<Label>Pay</Label>
																	<div>
																		<DatePicker
																			id="payDate"
																			selected={fulfillment.payDate ? fulfillment.payDate : ""}
																			onChange={this.editFulfillmentDateChange.bind(this, "payDate", fulfillment.id)}
																			className="form-control"
																		/>
																	</div>
																</React.Fragment>
																: <React.Fragment>
																	<Label>Bill</Label>
																	<p>{date_helpers.toMDYDateString(fulfillment.billDate)}</p>
																	<Label>Due</Label>
																	<p>{date_helpers.toMDYDateString(fulfillment.dueDate)}</p>
																	<Label>Pay</Label>
																	<p>{date_helpers.toMDYDateString(fulfillment.payDate)}</p>
																</React.Fragment>
															}
														</td>
														<td>
															{fulfillment.isEditing ? (
																<Input
																	type="text"
																	name="notes"
																	value={fulfillment.notes}
																	onChange={(event) => this.handleEditFulfillmentStateUpdate(
																		event.target.name,
																		event.target.value,
																		fulfillment.id
																	)}
																	maxLength="255"
																/>
															) : (
																	fulfillment.notes
																)}
														</td>
														<td className="text-right">
															{!fulfillment.isEditing ? (
																<ButtonGroup className="float-right">
																	<Button
																		className="primary btn-outline-secondary"
																		size="sm"
																		onClick={() => this.editOrderFulfillment(fulfillment.id)}
																	>
																		<FontAwesomeIcon icon="edit" />
																	</Button>
																	{fulfillment.deactivatedAt
																		? <Button
																			className="info"
																			size="sm"
																			onClick={() => this.reviveOrderFulfillment(fulfillment.id)}
																			title="Revive fulfillment item"
																		>
																			<FontAwesomeIcon icon="recycle" /> Revive
																			</Button>
																		: <Button
																			className="danger btn-outline-secondary"
																			size="sm"
																			onClick={() => this.deleteOrderFulfillment(fulfillment.id)}
																		>
																			<FontAwesomeIcon icon="trash" />
																		</Button>
																	}
																</ButtonGroup>
															) : (
																	<ButtonGroup className="float-right">
																		<Button
																			className="primary"
																			size="sm"
																			onClick={() => this.saveOrderFulfillment(fulfillment)}
																		>
																			<FontAwesomeIcon icon="save" /> Save
																		</Button>
																		<Button
																			className="secondary"
																			size="sm"
																			onClick={() => this.onCancelEditOrderFulfillment(fulfillment.id)}
																		>
																			<FontAwesomeIcon icon="times-circle" /> Cancel
																		</Button>
																	</ButtonGroup>
																)}
														</td>
													</tr>
												))}
											</tbody>
										</Table>
										<Row className="float-right">
											<Col className="text-right">
												Total Due:
											</Col>
											<Col>
												<Input
													type="text"
													name="totalDue"
													// value={helpers.formatCurrency(this.state.orderFulfillmentsTotalDue)} // not stored -generated dynamically
													value={this.state.orderFulfillmentsTotalDue} // not stored -generated dynamically
													readOnly
												/>
											</Col>
										</Row>
										<Row className="float-right">
											<Col className="text-right">
												Total Posted:
											</Col>
											<Col>
												<Input
													type="text"
													name="totalDue"
													// value={helpers.formatCurrency(this.state.orderFulfillmentsTotalPosted)} // not stored -generated dynamically
													value={this.state.orderFulfillmentsTotalPosted} // not stored -generated dynamically
													readOnly
												/>
											</Col>
										</Row>
										<Row className="float-right">
											<Col className="text-right">
												Balance:
											</Col>
											<Col>
												<Input
													type="text"
													name="totalDue"
													// value={helpers.formatCurrency(this.state.orderFulfillmentsBalance)} // not stored -generated dynamically
													value={this.state.orderFulfillmentsBalance} // not stored -generated dynamically
													readOnly
												/>
											</Col>
										</Row>
									</Col>
								</Row>

							</TabPane>
							<TabPane tabId={TABS.FINAL_REVIEW}>
								<Row>
									<Col>
										{this.state.message
											? <Alert2 className={this.state.messageFlavor}>{this.state.message}</Alert2>
											: null}
									</Col>
								</Row>
								{this.state.order.customer
									? (<Row className="py-2">
											<Col sm="3">
												<h4>{this.state.order.orderStatus.label}</h4>
											</Col>
											<Col sm="6">
												<div style={{fontSize: "1.4rem"}}>{this.state.order.customer.label} <span style={{fontWeight: "bold", fontStyle: "italic"}}>{this.state.order.customer.number}</span></div>
											</Col>
											<Col sm="3">
												<div style={{fontSize: "1.4rem"}}>Agent: {this.state.order.agent ? this.state.order.agent.label : null}</div>
											</Col>
										</Row>)
									: null
								}
								<Row>
									<Table size="sm" className="m-0 p-0">
										<thead>
											<tr>
												<th colSpan="3" style={{ backgroundColor: "#999", color: "#fff" }}>Summary</th>
											</tr>
										</thead>
										<tbody>
											{this.state.orderLines.map(line => (
												<Fragment key={line.id}>
												<tr>
													<td>
														{line.dsc}
														{line.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
															? (<ButtonGroup className="float-right">
																	{/* <Button color="light" size="sm" onClick={() => this.renderSignOffForm(line.id)}>Sign-Off Form</Button> */}
																	<Button color="light" size="sm" onClick={() => this.downloadReprint(true)}>Sign-Off Form</Button>
																	<Button color="dark" size="sm" onClick={() => this.renderWorkorderForm(line.id)}>Work Order</Button>
																</ButtonGroup>)
															: null
														}
													</td>
													<td className="priceColumn">{helpers.formatCurrency(line.price)}</td>
													<td className="costColumn">{helpers.formatCurrency(line.cost)}</td>
												</tr>
												{line.orderLineType.value === constants.ORDER_LINE_TYPE.CUSTOM_RUG.value
													? (<Fragment>
															<tr>
																<td colSpan="3" className="rug-addl">
																	<Row>
																		<Col>
																	Rug Style:
																	<InputDebounce
																		type="input"
																		id="rugStyle"
																		name="rugStyle"
																		onChangeCallback={({value: newValue}) => this.onChangeAdditionalRugField(line, 'rugStyle', newValue)}
																		value={line.rugStyle}
																		maxLength="200"
																	/>
																		</Col>
																		<Col>
																	Finished Edge:
																	<InputDebounce
																		type="input"
																		id="finishedEdge"
																		name="finishedEdge"
																		onChangeCallback={({value: newValue}) => this.onChangeAdditionalRugField(line, 'finishedEdge', newValue)}
																		value={line.finishedEdge}
																		maxLength="200"
																	/>
																		</Col>
																	</Row>
																</td>
															</tr>
															<tr>
																<td colSpan="3" className="rug-addl">
																	<Row>
																		<Col>
																	Labor Cost:
																	<InputDebounce
																		type="input"
																		id="laborCost"
																		name="laborCost"
																		onChangeCallback={({value: newValue}) => this.onChangeAdditionalRugField(line, 'laborCost', newValue)}
																		value={line.laborCost}
																		maxLength="100"
																	/>
																		</Col>
																		<Col>
																	Total Agreed Charges:
																	<InputDebounce
																		type="input"
																		name="id"
																		name="totalAgreedCharges"
																		onChangeCallback={({value: newValue}) => this.onChangeAdditionalRugField(line, 'totalAgreedCharges', newValue)}
																		value={line.totalAgreedCharges}
																		maxLength="100"
																	/>
																		</Col>
																		<Col>
																	Send Scrap:
																	<ToggleButton
																		value={line.sendScrap}
																		activeLabel={"Yes"}
																		inactiveLabel={"No"}
																		onToggle={(value) => this.onChangeAdditionalRugField(line, 'sendScrap', !value)}
																	/>
																		</Col>
																	</Row>
																</td>
															</tr>
														</Fragment>)
													: null
												}
												</Fragment>
											))}
											<tr>
												<td>Invoice Total</td>
												<td className="priceColumn" style={{borderTop: "double 3px #000000"}}>{this.state.totalOrderPrice}</td>
												<td className="costColumn" style={{borderTop: "double 3px #000000"}}>{this.state.totalOrderCost}</td>
											</tr>
											<tr>
												<td><strong>Estimated Gross Profit</strong></td>
												<td colSpan="2" className="totalColumn">
													<strong>
														{this.state.estimatedGrossProfit}
													</strong>
												</td>
											</tr>
											{this.state.orderLines.map(line => (
												<tr key={line.id}>
													{line.orderLineType.value !== constants.ORDER_LINE_TYPE.CREDIT_CARD_SURCHARGE.value
														&& line.orderLineType.value !== constants.ORDER_LINE_TYPE.SALES_TAX.value
														&& line.orderLineType.value !== constants.ORDER_LINE_TYPE.FREIGHT.value
														? <React.Fragment>
															<td>{`${line.dsc} - Agent Commission`}</td>
															<td className="priceColumn">({_.round(line.cmsnRate).toFixed(2)}%)</td>
															<td className="costColumn">{helpers.formatCurrency(line.cmsn)}</td>
														</React.Fragment>
														: null
													}
												</tr>
											))}
											<tr>
												<td><strong>Estimated Net Profit</strong></td>
												<td className="totalColumn" colSpan="2">
													<strong>
														{this.state.estimatedNetProfit}
													</strong>
												</td>
											</tr>
											{this.state.hasSalesTaxOrSurcharge &&
												<tr>
													<td colSpan="3"><Button size="sm" className="primary" onClick={() => this.recalculateSalesTaxAndSurcharge()}>Recalculate Sales Tax/Surcharge</Button></td>
												</tr>
											}
										</tbody>
									</Table>
								</Row>
								<Row>
									<Table size="sm" className="m-0 p-0">
										<thead>
											<tr>
												<th colSpan="3" style={{ backgroundColor: "#888", color: "#fff" }}>Verifications</th>
											</tr>
										</thead>
										<tbody>
											{this.state.verifications.map(v => (
												<tr>
													<td>{v.warning}</td>
													<td>{v.message}</td>
													<td>{v.something}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</Row>
								<Row>
								</Row>
							</TabPane>
							<TabPane tabId={TABS.PRINT}>
								<Fragment>
									<Col className="mb-4">
									Invoice Type
									<Select
										options={invoiceOptions}
										value={this.state.invoiceType}
										onChange={v => this.setState({invoiceType: v})}
									/>
									</Col>
									<Col className="mb-4">
										Description 1
										<Input
											type='textarea'
											maxLength="800"
											style={{ height: 200 }}
											value={this.state.customDescription || ""}
											onChange={(e) => this.setState({ customDescription: e.target.value })}
										/>
									</Col>
									<Col className="mb-4">
										Description 2
										<Input
											style={{ height: 200 }}
											type='textarea'
											maxLength="300"
											value={this.state.customDescription2 || ""}
											onChange={(e) => this.setState({ customDescription2: e.target.value })}
										/>
									</Col>
									<Col className="mb-4">
										Invoice Date
										<DatePicker
											selected={this.state.invoiceDate}
											onChange={date => this.setState({ invoiceDate: date })}
											className="form-control"
										/>
									</Col>
									<Col>Due Date
										<DatePicker
											selected={this.state.dueDate}
											onChange={date => this.setState({ dueDate: date })}
											className="form-control"
										/>
									</Col>
									{this.state.printing ?
										<FontAwesomeIcon style={{marginTop: 5, marginLeft: 15}} icon="spinner" spin size="lg"/>
										:
										<Button
											className="backgroundSuccess"
											onClick={() => this.downloadReprint(false)}
											style={{ marginLeft: 15, marginTop: 5 }}
										>
											Print
										</Button>
									}
								</Fragment>
							</TabPane>

						</TabContent>
					</Col>
				</Row>
			</Container>
		);
	}
}

