import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Table,
    Container,
    Form,
    Card,
    Alert,
    CardBody,
    Input,
    Modal,
    ModalBody
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    filter_helpers,
    api,
    helpers,
    date_helpers,
    constants
} from '../utils';
import MemoOrderLines from './MemoOrderLines';
import CustomerAdmin from './CustomerAdmin';
import {
    CustomerTypeahead,
    FilterSet,
    FilterText,
    FilterBoolean,
    FilterSelect,
    SortHeader, 
    Pager
} from '../components';

const addAnyOption = (list) => {
    let newList = list.slice();
    newList.unshift({ label: "(Any)", value: null });
    return newList;
  };

let currentUserId = null;
let currentUserName = null;

const getCurrentUser = () => {
    try {
        currentUserId = localStorage.getItem('currentUser')
          ? JSON.parse(localStorage.getItem('currentUser')).id
          : null 
        currentUserName = localStorage.getItem('currentUser')
          ? JSON.parse(localStorage.getItem('currentUser')).username
          : null
        return (
            {
                value: currentUserId,
                label: currentUserName
            }
        );
      } catch(e) {
          console.log('e', e);
      }
}

const emptyMemoOrder = {
    id: 0,
    customer: null,
    sidemark: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    memoShipMethod: constants.MEMO_SHIP_METHOD.FEDEX_GROUND,
    trackingNumber: '',
    memoOrderLines: [],
    createdAt: new Date(),
    createdByUser: null
};

export default class MemoOrders extends Component {
    constructor(props) {
        super(props);
        let lastMemoOrderFilters = null;
        try {
          lastMemoOrderFilters = localStorage.getItem('lastMemoOrderFilters');
        } catch {}
        const defaultFilters = lastMemoOrderFilters
          ? JSON.parse(lastMemoOrderFilters)
          : [{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only: Yes" }];
        this.state = {
            suppliers: [], 
            messageFlavor: null,
            message: null,
            editItem: null,
            sortField: 'CreatedAt',
            sortDir: 'desc',
            filters: defaultFilters,
            clearValue: false,
            showEdit: false,
            pagedList: {
                list: [],
                pageSize: 100,
                pageNumber: 1
            },
            customerList: [],
            contactTypeList: [],
            contactAddressTypeList: [],
            contacts: [],
            contactList: [],
            supplierIdCaptured: false,
            memoOrder: {
                ...emptyMemoOrder,
                createdByUser: getCurrentUser()
            },
            isContactEditing: false, 
            customerContacts: [],
            selectedContact: null,
            customerShipToAddresses: [],
            showCustomerSearchModal: false,
            memoOrderLineStatusList: [],
            // relatedMemoBilledOrders: [],
            memoShipMethods: []
        };
        this.showAdd = this.showAdd.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.setPage = this.setPage.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setSelectedShippingContact = this.setSelectedShippingContact.bind(this);
        this.setSelectedShippingAddress = this.setSelectedShippingAddress.bind(this);
        this.handleCreatedAtInputChange = this.handleCreatedAtInputChange.bind(this);
        this.saveNewMemoOrderWithOrderLine = this.saveNewMemoOrderWithOrderLine.bind(this);
        this.onToggleCustomerSearchModal = this.onToggleCustomerSearchModal.bind(this);
        this.onCustomerSelectedFromSearchModal = this.onCustomerSelectedFromSearchModal.bind(this);
        this.moveSelectedMemoOrderLinesToNewOrder = this.moveSelectedMemoOrderLinesToNewOrder.bind(this);
    }

    componentDidMount() {
        const getMemoOrderLineStatues = api.fetch("Reference/GetMemoOrderLineStatusList")
        .then(response => {
            return { memoOrderLineStatusList: response.data };
        })
        .catch(helpers.catchHandler);

        const getMemoShipMethods = api.fetch("Reference/GetMemoShipMethodsList")
        .then(response => {
            return { memoShipMethodsList: response.data };
        })
        .catch(helpers.catchHandler);

        // const getCustomers = api.fetch("Reference/GetCustomerList")
        // .then((response) => {
        //     return { customerList: response.data };
        // })
        // .catch(helpers.catchHandler);

        Promise.all([
            getMemoOrderLineStatues,
            getMemoShipMethods,
            // getCustomers
        ])
            .then((aggregateResults) => {
                const newStatus = {};
                aggregateResults.forEach(r => Object.assign(newStatus, r));
                this.setState(newStatus, () =>
                    this.refreshList());
            })
            .catch(helpers.catchHandler);

        if (this.props.location.state && this.props.location.state.memoOrderId) {
            this.onEdit(this.props.location.state.memoOrderId)
        }
    }

    refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            Page: this.state.pagedList.pageNumber,
            PageSize: this.state.pagedList.pageSize,
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir,
        };

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

        api.post('MemoOrder/GetPaginatedList', payload)
        .then((response) => {
            this.setState({
                pagedList: response.data
            });
        }).catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
      const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
      try {
        localStorage.setItem("lastMemoOrderFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, clearValue: false });
      this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) return filterElement.value;
        return '';
    }

    clearFilters() {
      const filters = [];
      try {
        localStorage.setItem("lastMemoOrderFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, clearValue: true });
      this.refreshList(null, null, filters);
    }

    setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, () => this.refreshList());
    }

    onCancel() {
        this.setState({
            showEdit: false,
            memoOrder: {
                ...emptyMemoOrder,
                createdByUser: getCurrentUser()
            },
            message: null, 
            messageFlavor: null,
            customerInfo: null
        }, () => this.refreshList());
    }

    showAdd() {
        this.setState({
            memoOrder: {
                ...emptyMemoOrder,
                createdByUser: getCurrentUser()
            },
            showEdit: true
        });
    }

    onEdit(memoOrderId) {
        api.fetch(`MemoOrder/GetMemoOrder/${memoOrderId}`)
        .then((response) => {
            const item = response.data;
            this.setState({
                memoOrder: {
                    id: item.id,
                    customer: helpers.resolveValue(null, item.customerId, item.customerName), 
                    sidemark: helpers.nullableString(item.sidemark),
                    contactFirstName: helpers.nullableString(item.contactFirstName),
                    contactLastName: helpers.nullableString(item.contactLastName),
                    contactEmail: helpers.nullableString(item.contactEmail),
                    addressLine1: helpers.nullableString(item.addressLine1),
                    addressLine2: helpers.nullableString(item.addressLine2),
                    addressLine3: helpers.nullableString(item.addressLine3),
                    addressLine4: helpers.nullableString(item.addressLine4),
                    city: helpers.nullableString(item.city),
                    state: helpers.nullableString(item.state),
                    zip: helpers.nullableString(item.zip),
                    phone: helpers.nullableString(item.phone),
                    memoShipMethod: helpers.resolveValue(null, item.memoShipMethodId, item.memoShipMethodName),
                    trackingNumber: helpers.nullableString(item.trackingNumber),
                    comment: helpers.nullableString(item.comment),
                    createdAt: date_helpers.dateFormatForClient(item.createdAt),
                    creatdByUserName: item.createdByUserName,
                    createdByUser: helpers.resolveValue(
                        null,
                        item.createdByUserId,
                        item.createdByUserName),
                    memoOrderLines: _.map(item.memoOrderLines, ol => {
                        return {
                            id: ol.id,
                            lastStatusAt: date_helpers.getMomentFromString(ol.lastStatusAt),
                            memoOrderId: ol.memoOrderId,
                            memoSize: helpers.resolveValue(null, ol.memoSizeId, ol.memoSizeName),
                            productColor: helpers.resolveValue(
                                null, 
                                ol.productColorId, 
                                // ol.productColorName
                                `${ol.productColorName} ${ol.productColorNumber ? ol.productColorNumber : ''}`),
                            product: helpers.resolveValue(
                                null, 
                                ol.productId, 
                                // ol.productDCName
                                `${ol.productDCName} ${ol.productDCNumber ? '(' + ol.productDCNumber + ')' : ''}`),
                            sampleBook: helpers.resolveValue(
                                null, 
                                ol.sampleBookId, 
                                ol.sampleBookName),
                            sampleBookStock: ol.sampleBookStock,
                            productMemoShelfNumber: helpers.nullableString(ol.productMemoShelfNumber),
                            typeOfMemoOrderLineStatus: helpers.resolveValue(null, ol.typeOfMemoOrderLineStatus, ol.typeOfMemoOrderLineStatusName),
                            memoOrderLineStatus: null,
                            supplierName: ol.productSupplierName,
                            qty: ol.qty ? ol.qty : '1',
                            deactivatedAt: null,
                            orderLineComments: _.map(ol.memoOrderLineComments, comment => {
                                return {
                                    ...comment, 
                                    local_id:  -1*(ol.memoOrderLineComments.indexOf(comment)+1),
                                    commentType: helpers.resolveValue(null, comment.commentTypeId, comment.commentTypeName)
                                }
                            }),
                            commentCount: ol.memoOrderLineComments ? ol.memoOrderLineComments.length : 0,  // for temporary use only
                            productMemoSize: ol.productMemoSize,
                            isSelected: false,
                            lastSampleReceivedAt:  date_helpers.getMomentFromString(ol.lastSampleReceivedAt)
                        }
                    })
                },
                // relatedMemoBilledOrders: item.relatedMemoBilledOrders,
                showEdit: true
            }, () => this.getCustomer(item.customerId));
        }).catch(helpers.catchHandler);
      window.scrollTo(0, 0);
    }

    getCustomer(customerId) {
      api.fetch(`Customer/GetActiveContacts/${customerId}`).then((response) => {
        const customerShipToAddresses = _.chain(response.data.customerAddresses)
          .filter(ca => { return ca.addressTypeId === constants.ADDRESS_TYPE.SHIP_TO })
          .map(a => {
            a.value = a.id;
            a.label = helpers.formatAddressLabel(a, '* ')
            return a;
          }).value();
        let customerInfo = response.data.customer;
        let customerContacts = response.data.contacts;
        _.each(customerContacts, c => {
          // remove deactivated phones and addresses for each contact
          const addresses = _.filter(c.addresses, a => a.deactivatedAt === null);
          const phoneNumbers = _.filter(c.phoneNumbers, p => p.deactivatedAt === null);
          c.expanded = false;
          c.addresses = addresses;
          c.phoneNumbers = phoneNumbers;
          
          // set value and label for select
          c.label = `${c.firstName} ${c.lastName}`;
          c.value = c.id;
          return c;
        });
        this.setState({ 
          customerInfo: customerInfo, 
          customerContacts: customerContacts,
          customerShipToAddresses: customerShipToAddresses,
          shippingAddresses: customerShipToAddresses
        });
      }).catch(helpers.catchHandler);
    }

    handleTypeaheadSelectionChange(fieldName, selection) {
      let memoOrderCopy = Object.assign({}, this.state.memoOrder);
        if (selection.value !== (memoOrderCopy.customer && memoOrderCopy.customer.value)) {
            memoOrderCopy = {
                ...memoOrderCopy,
                contactFirstName: '',
                contactLastName: '',
                contactEmail: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
            }
        }
        this.setState({ 
            memoOrder: {
                ...memoOrderCopy, 
                [fieldName]: selection,
                errorMessage: null
            }
        }, () => this.getCustomer(selection.value));
    }

    handleCreatedAtInputChange(date) {
        this.setState({
            memoOrder: {
                ...this.state.memoOrder,
                createdAt: date
            }
        });
    }

    handleMemoOrderStateChange(fieldName, value) {
        this.setState({
            memoOrder: {
                ...this.state.memoOrder,
                [fieldName]: value
            }
        });
    }

    async setSelectedShippingContact(option) {
      const phoneNumber = await _.find(option.phoneNumbers, x => x.isPrimary);
      const shippingAddresses = this.state.customerShipToAddresses.concat(
        _.map(_.filter(option.addresses, x => x.typeOfAddress === constants.ADDRESS_TYPE.SHIP_TO),
          ad => {
            return {
              ...ad,
              value: ad.id,
              label: helpers.formatAddressLabel(ad)
            }
          }));
      this.setState({
          memoOrder: {
            ...this.state.memoOrder,
            contactFirstName: option.firstName,
            contactLastName: option.lastName,
            contactEmail: helpers.nullableString(option.email),
            phone: phoneNumber ? phoneNumber.number : "",
          },
          shippingAddresses: shippingAddresses
      });
    }

    setSelectedShippingAddress(option) {
        this.setState({
            memoOrder: {
                ...this.state.memoOrder,
                addressLine1: helpers.nullableString(option.ln1),
                addressLine2: helpers.nullableString(option.ln2),
                addressLine3: helpers.nullableString(option.ln3),
                addressLine4: helpers.nullableString(option.ln4),
                city: helpers.nullableString(option.city),
                state: helpers.nullableString(option.state),
                zip: helpers.nullableString(option.zip)
            }
        })
    }

    setSelectedMemoShipMethod(option) {
        this.setState({
            memoOrder: {
                ...this.state.memoOrder,
                memoShipMethod: option
            }
        });
      }

    isFormValid() {
        const warnings = [];
        if (this.state.memoOrder.memoOrderLines.length < 1 && !this.state.savingNewMemoOrderWithOrderLine) {
            warnings.push("At least one order line is required.")
        }
        if (!this.state.memoOrder.customer) {
            warnings.push("Account is required.");
        }
        if (!this.state.memoOrder.contactFirstName) {
            warnings.push("Contact First Name is required.");
        }
        if (!this.state.memoOrder.contactLastName) {
            warnings.push("Contact Last Name is required.");
        }
        // if (this.state.memoOrder.contactEmail) {
        //     warnings.push("Contact email is not valid.");
        // }
        if (!this.state.memoOrder.addressLine3) {
            warnings.push("Address is required.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onSubmitNewMemoOrderWithOrderLine(orderLine) {
        // window.scroll(0,0);
        if (!this.isFormValid()) return;
        let orderLines = [];
        orderLines.push(orderLine);
        const payload = {
                Id: this.state.memoOrder.id,
                CustomerId: this.state.memoOrder.customer.value,
                Sidemark: this.state.memoOrder.sidemark ,
                ContactFirstName: this.state.memoOrder.contactFirstName,
                ContactLastName: this.state.memoOrder.contactLastName,
                ContactEmail: this.state.memoOrder.contactEmail ? this.state.memoOrder.contactEmail : null,
                AddressLine1: this.state.memoOrder.addressLine1,
                AddressLine2: this.state.memoOrder.addressLine2,
                AddressLine3: this.state.memoOrder.addressLine3,
                AddressLine4: this.state.memoOrder.addressLine4,
                City: this.state.memoOrder.city,
                State: this.state.memoOrder.state,
                Zip: this.state.memoOrder.zip,
                Phone: this.state.memoOrder.phone,
                MemoShipMethodId: this.state.memoOrder.memoShipMethod ? this.state.memoOrder.memoShipMethod.value : null,
                TrackingNumber: this.state.memoOrder.trackingNumber,
                Comment: this.state.memoOrder.comment,
                MemoOrderLines: orderLines
        };

        api.post(`MemoOrder/SaveNewMemoOrderWithOrderLine/${false}`, payload)
        .then(response => {
            if(response.data.success) {
                let memoOrderId = parseInt(response.data.message);
                this.setState({
                    messageFlavor: "success",
                    message: "New memo order was saved!",
                    savingNewMemoOrderWithOrderLine: false
                }, () => {
                    this.onEdit(memoOrderId);
                });                
            } else {
                this.setState({
                    messageFlavor: "danger",
                    message: response.data.message
                });
            } 
        })
        .catch(helpers.catchHandler);
    }

    onSubmit() {
        window.scroll(0,0);
        if (!this.isFormValid()) return;
        const payload = {
                Id: this.state.memoOrder.id,
                CustomerId: this.state.memoOrder.customer.value,
                Sidemark: this.state.memoOrder.sidemark ,
                ContactFirstName: this.state.memoOrder.contactFirstName,
                ContactLastName: this.state.memoOrder.contactLastName,
                ContactEmail: this.state.memoOrder.contactEmail ? this.state.memoOrder.contactEmail : null,
                AddressLine1: this.state.memoOrder.addressLine1,
                AddressLine2: this.state.memoOrder.addressLine2,
                AddressLine3: this.state.memoOrder.addressLine3,
                AddressLine4: this.state.memoOrder.addressLine4,
                City: this.state.memoOrder.city,
                State: this.state.memoOrder.state,
                Zip: this.state.memoOrder.zip,
                Phone: this.state.memoOrder.phone,
                MemoShipMethodId: this.state.memoOrder.memoShipMethod ? this.state.memoOrder.memoShipMethod.value : null,
                TrackingNumber: this.state.memoOrder.trackingNumber,
                Comment: this.state.memoOrder.comment
        };

        api.post("MemoOrder/UpdateMemoOrder", payload)
        .then(response => {
            if(response.data.success) {
                    this.setState({
                        memoOrder: {
                            ...emptyMemoOrder,
                            createdByUser: getCurrentUser()
                        },
                        showEdit: false
                    }, () => this.refreshList());               
            } else {
                this.setState({
                    messageFlavor: "danger",
                    message: response.data.message,
                });
            } 
        })
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {
        api.delete(`MemoOrder/DeleteMemoOrder/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleUnDelete(id) {
        api.delete(`MemoOrder/ReviveMemoOrder/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    saveNewMemoOrderWithOrderLine(orderLine) {
        this.setState({
            savingNewMemoOrderWithOrderLine: true
        }, () => this.onSubmitNewMemoOrderWithOrderLine(orderLine))
    }

    moveSelectedMemoOrderLinesToNewOrder(orderLines) {
        this.setState({
            savingNewMemoOrderWithOrderLine: true
        }, () => {
            window.scroll(0,0);
            if (!this.isFormValid()) return;
            const payload = {
                    Id: this.state.memoOrder.id,
                    CustomerId: this.state.memoOrder.customer.value,
                    Sidemark: this.state.memoOrder.sidemark ,
                    ContactFirstName: this.state.memoOrder.contactFirstName,
                    ContactLastName: this.state.memoOrder.contactLastName,
                    ContactEmail: this.state.memoOrder.contactEmail ? this.state.memoOrder.contactEmail : null,
                    AddressLine1: this.state.memoOrder.addressLine1,
                    AddressLine2: this.state.memoOrder.addressLine2,
                    AddressLine3: this.state.memoOrder.addressLine3,
                    AddressLine4: this.state.memoOrder.addressLine4,
                    City: this.state.memoOrder.city,
                    State: this.state.memoOrder.state,
                    Zip: this.state.memoOrder.zip,
                    Phone: this.state.memoOrder.phone,
                    MemoShipMethodId: this.state.memoOrder.memoShipMethod ? this.state.memoOrder.memoShipMethod.value : null,
                    TrackingNumber: this.state.memoOrder.trackingNumber,
                    Comment: this.state.memoOrder.comment,
                    MemoOrderLines: orderLines
            };
    
            api.post(`MemoOrder/SaveNewMemoOrderWithOrderLine/${true}`, payload)
            .then(response => {
                if(response.data.success) {
                    let memoOrderId = parseInt(response.data.message);
                    this.setState({
                        messageFlavor: "success",
                        message: `Selected order lines were successfully moved to newly created order #${memoOrderId}`,
                        savingNewMemoOrderWithOrderLine: false
                    }, () => {
                        this.onEdit(this.state.memoOrder.id);   // refresh current order
                    });                
                } else {
                    this.setState({
                        messageFlavor: "danger",
                        message: response.data.message
                    });
                } 
            })
            .catch(helpers.catchHandler);
        })
    }

    onToggleCustomerSearchModal() {
        this.setState({showCustomerSearchModal: !this.state.showCustomerSearchModal})
    }

    onCustomerSelectedFromSearchModal(customerId, customerName) {
        let memoOrderCopy = Object.assign({}, this.state.memoOrder);
        if (!this.state.memoOrder.customer || this.state.memoOrder.customer.value !== customerId ) {
            memoOrderCopy = {
                ...memoOrderCopy,
                contactFirstName: '',
                contactLastName: '',
                contactEmail: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
            }
        }
        this.setState({
            showCustomerSearchModal: false,
            memoOrder: {
                ...memoOrderCopy,
                customer: helpers.resolveValue(null, customerId, customerName)
            }
        }, () => this.getCustomer(customerId));
    }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.showEdit ?
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        {this.state.message !== null && (
                                            <Row className="mb-2">
                                                <Col>
                                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="row-spacing">
                                            <Col>
                                                <h4>{!this.state.memoOrder.id ? 'Add New' : null} Memo Order {this.state.memoOrder.id ? '# ' + this.state.memoOrder.id : null}</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col >
                                                <Form>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <Button
                                                                className="m-0 p-0"
                                                                color="link"
                                                                style={{textDecoration: "underline"}}
                                                                onClick={this.onToggleCustomerSearchModal}
                                                            >
                                                                Account {helpers.requiredStar()}
                                                            </Button>
                                                            <CustomerTypeahead
                                                                onChange={(e) => this.handleTypeaheadSelectionChange("customer", e)}
                                                                isMulti={false}
                                                                customer={this.state.memoOrder.customer}
                                                            />
                                                        </Col>
                                                        <Col sm="2">Account #
                                                            <Input
                                                                name="customerNumber"
                                                                value={this.state.customerInfo ? this.state.customerInfo.number : ''}
                                                                readOnly
                                                            />
                                                        </Col>
                                                        <Col sm="2">Date Entered
                                                            <DatePicker
                                                                id="createdAt"
                                                                selected={this.state.memoOrder.createdAt ? this.state.memoOrder.createdAt : ''}
                                                                onChange={this.handleCreatedAtInputChange}
                                                                className="form-control"
                                                                readOnly
                                                            />
                                                        </Col>
                                                        <Col sm="2">Entered By
                                                            <Input
                                                                name="createdByUserId"
                                                                value={this.state.memoOrder.createdByUser ? this.state.memoOrder.createdByUser.label : ''}
                                                                readOnly
                                                            />
                                                        </Col>
                                                        <Col sm="2">Memo Billed Sample Form Received?
                                                            <div>
                                                                <Switch
                                                                    className="ml-2 p-0 m-0"
                                                                    onChange={() => {}}
                                                                    checked={this.state.customerInfo ? this.state.customerInfo.hasMemoBilledSampleForm : false }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col>Select Ship To Contact
                                                            <Select
                                                                options={this.state.customerContacts}
                                                                value={null}
                                                                onChange={this.setSelectedShippingContact}
                                                            />
                                                        </Col>
                                                        <Col>Select Ship To Address
                                                            <Select
                                                                options={this.state.shippingAddresses || this.state.customerShipToAddresses}
                                                                value={null}
                                                                onChange={this.setSelectedShippingAddress}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Row>
                                                                <Col>Contact First Name
                                                                    <Input
                                                                        type="text"
                                                                        name="contactFirstName"
                                                                        value={this.state.memoOrder.contactFirstName}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="25"
                                                                    />
                                                                </Col>
                                                                <Col>Contact Last Name
                                                                    <Input
                                                                        type="text"
                                                                        name="contactLastName"
                                                                        value={this.state.memoOrder.contactLastName}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="25"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Contact Phone Number
                                                                    <Input
                                                                        type="text"
                                                                        name="phone"
                                                                        value={this.state.memoOrder.phone}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="20"
                                                                    />
                                                                </Col>
                                                                <Col>Contact Email
                                                                    <Input
                                                                        type="text"
                                                                        name="contactEmail"
                                                                        value={this.state.memoOrder.contactEmail}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Ship Method
                                                                    <Select
                                                                        closeMenuOnSelect
                                                                        value={this.state.memoOrder.memoShipMethod}
                                                                        options={this.state.memoShipMethodsList}
                                                                        onChange={(option) => this.setSelectedMemoShipMethod(option)}
                                                                        isClearable
                                                                    />
                                                                </Col>
                                                                <Col>Tracking Number
                                                                    <Input
                                                                        type="text"
                                                                        name="trackingNumber"
                                                                        value={this.state.memoOrder.trackingNumber}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Comment
                                                                    <Input
                                                                        type="textarea"
                                                                        name="comment"
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        value={this.state.memoOrder.comment}
                                                                        placeholder="Enter A Comment"
                                                                        maxLength="300"
                                                                        style={{height: "160px"}}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col>Ship To Name
                                                                    <Input
                                                                        type="text"
                                                                        name="addressLine1"
                                                                        value={this.state.memoOrder.addressLine1}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Additional Name &#40;Optional&#41;
                                                                    <Input
                                                                        type="text"
                                                                        name="addressLine2"
                                                                        value={this.state.memoOrder.addressLine2}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Shipping Address
                                                                    <Input
                                                                        type="text"
                                                                        name="addressLine3"
                                                                        value={this.state.memoOrder.addressLine3}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Address Line 2
                                                                    <Input
                                                                        type="text"
                                                                        name="addressLine4"
                                                                        value={this.state.memoOrder.addressLine4}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="150"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>City
                                                                    <Input
                                                                        type="text"
                                                                        name="city"
                                                                        value={this.state.memoOrder.city}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="50"
                                                                    />
                                                                </Col>
                                                                <Col>State
                                                                    <Input
                                                                        type="text"
                                                                        name="state"
                                                                        value={this.state.memoOrder.state}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="25"
                                                                    />
                                                                </Col>
                                                                <Col>Zip
                                                                    <Input
                                                                        type="text"
                                                                        name="zip"
                                                                        value={this.state.memoOrder.zip}
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        maxLength="20"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Sidemark
                                                                    <Input
                                                                        name="sidemark"
                                                                        onChange={(event) => this.handleMemoOrderStateChange(
                                                                            event.target.name,
                                                                            event.target.value
                                                                        )}
                                                                        value={this.state.memoOrder.sidemark}
                                                                        maxLength="50"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>  
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <MemoOrderLines
                                            memoOrderId={this.state.memoOrder.id}
                                            memoOrderLines={this.state.memoOrder.memoOrderLines}
                                            saveNewMemoOrderWithOrderLine={this.saveNewMemoOrderWithOrderLine}
                                            customerId={this.state.memoOrder.customer ? this.state.memoOrder.customer.value : null}
                                            moveSelectedMemoOrderLinesToNewOrder ={this.moveSelectedMemoOrderLinesToNewOrder}
                                        />
                                        {/* Per Tracy's request, below removed on 5/3/2024 as they no longer memo bill customers for samples*/}
                                        {/* <Row className="row-spacing">
                                            <Col>
                                                <h5>Customer's Open Memo Billed Orders</h5>
                                                {this.state.relatedMemoBilledOrders.map((ro, index) => (
                                                    <div key={`relatedOrder-${index}`} className="data-row">
                                                        <Link 
                                                            to={{
                                                                pathname: '/OrderEntry',
                                                                state: { activeOrderId: ro.orderId },
                                                                push: true
                                                            }}
                                                        >
                                                            {ro.orderId}:
                                                        </Link> {ro.orderDsc}
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row> */}
                                        <Row className="row-spacing">
                                            <Col>
                                                <ButtonGroup className="float-right">
                                                    <Button
                                                        className="btn primary"
                                                        size="sm"
                                                        onClick={this.onSubmit}
                                                    >
                                                        <FontAwesomeIcon icon="save" /> Save Memo Order
                                                    </Button>
                                                    <Button
                                                        className="btn secondary"
                                                        size="sm"
                                                        onClick={this.onCancel}
                                                    >
                                                        <FontAwesomeIcon icon="reply" /> Back
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Modal
                                    isOpen={this.state.showCustomerSearchModal}
                                    toggle={() => this.setState({showCustomerSearchModal: false})}
                                    size="lg"
                                >
                                    <ModalBody>
                                        <CustomerAdmin 
                                            usedByCustomerSearchModal={true}
                                            onCustomerSelected={this.onCustomerSelectedFromSearchModal}
                                        />
                                    </ModalBody>
                                </Modal>
                            </React.Fragment>
                            :
                            <Row>
                                <Col>
                                    {this.state.message !== null && (
                                        <Row className="mb-2">
                                            <Col>
                                                <Alert className={this.state.messageFlavor}>
                                                    {this.state.message}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <h3 className="pull-left page-title">Memo Orders</h3>
                                        </Col>
                                    </Row>
                                    <Row className="expand-md mt-2 mb-2 pb-0">
                                        <Col sm="12">
                                            <ButtonGroup className="float-right">
                                                <Button
                                                    className="btn-sm success"
                                                    onClick={this.showAdd}
                                                >
                                                    <FontAwesomeIcon icon="plus" /> Add Memo Order
                                                </Button>
                                            </ButtonGroup>
                                            <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                                <Row>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="StyleName"
                                                            displayName="Style Name"
                                                            value={this.currentFilterValue('StyleName')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="StyleNumber"
                                                            displayName="Style Number"
                                                            value={this.currentFilterValue('StyleNumber')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="ColorName"
                                                            displayName="Color Name"
                                                            value={this.currentFilterValue('ColorName')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="ColorNumber"
                                                            displayName="Color Number"
                                                            value={this.currentFilterValue('ColorNumber')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="ContactName"
                                                            displayName="Contact Name"
                                                            value={this.currentFilterValue('ContactName')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="CustomerName"
                                                            displayName="Account Name"
                                                            value={this.currentFilterValue('CustomerName')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="AssociatedAddress"
                                                            displayName="Associated Address"
                                                            value={this.currentFilterValue('AssociatedAddress')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="City"
                                                            displayName="City"
                                                            value={this.currentFilterValue('City')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="State"
                                                            displayName="State"
                                                            value={this.currentFilterValue('State')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="Zip"
                                                            displayName="Zip"
                                                            value={this.currentFilterValue('Zip')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="AccountNumber"
                                                            displayName="Account Number"
                                                            value={this.currentFilterValue('AccountNumber')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterText
                                                            filterName="AssociatedPhone"
                                                            displayName="Associated Phone"
                                                            value={this.currentFilterValue('AssociatedPhone')}
                                                            onChangeCallback={this.filterChange}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterBoolean
                                                            filterName="ActiveOnly"
                                                            displayName="Active Only"
                                                            yesOnly={true}
                                                            value={this.currentFilterValue('ActiveOnly')}
                                                            onChangeCallback={this.filterChange}
                                                            clearValue={this.state.clearValue} />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FilterSelect
                                                            filterName="OrderLineStatusId"
                                                            displayName="Orderline Status"
                                                            options={addAnyOption(this.state.memoOrderLineStatusList)}
                                                            value={this.currentFilterValue('OrderLineStatusId')}
                                                            onChangeCallback={this.filterChange}
                                                            clearValue={this.state.clearValue}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FilterSet>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table striped hover size="sm">
                                                <thead>
                                                    <tr>
                                                      <th>#</th>
                                                        <th style={{width: "17%"}}>
                                                            <SortHeader
                                                                displayName="Account Name"
                                                                field="CustomerName"
                                                                sortDir={this.state.sortDir}
                                                                sorted={this.state.sortField === 'CustomerName'}
                                                                callBack={this.doSort}
                                                            />
                                                        </th>
                                                        <th style={{width: "10%"}}>
                                                            <SortHeader
                                                                displayName="Placed On"
                                                                field="CreatedAt"
                                                                sortDir={this.state.sortDir}
                                                                sorted={this.state.sortField === 'CreatedAt'}
                                                                callBack={this.doSort}
                                                            />
                                                        </th>
                                                        <th style={{width: "25%"}}>
                                                            <SortHeader
                                                                displayName="Style"
                                                                field="SupplierName"
                                                                sortDir={this.state.sortDir}
                                                                sorted={this.state.sortField === 'SupplierName'}
                                                                callBack={this.doSort}
                                                            />
                                                        </th>
                                                        <th style={{width: "15%"}}>
                                                            <SortHeader
                                                                displayName="Color"
                                                                field="ProductColor"
                                                                sortDir={this.state.sortDir}
                                                                sorted={this.state.sortField === 'ProductColor'}
                                                                callBack={this.doSort}
                                                            />
                                                        </th>
                                                        <th>
                                                            <SortHeader
                                                                displayName="Sample"
                                                                field="Sample"
                                                                sortDir={this.state.sortDir}
                                                                sorted={this.state.sortField === 'Sample'}
                                                                callBack={this.doSort}
                                                            />
                                                        </th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.pagedList.list.map(orderLine => (
                                                        <tr key={`orderLine-${orderLine.id}`} className="data-row">
                                                          <td>{orderLine.memoOrderId}</td>
                                                            <td>{orderLine.customerName}</td>
                                                            <td>{date_helpers.formatDateToShortDate(orderLine.createdAt)}</td>
                                                            <td>
                                                                {orderLine.supplierName
                                                                    ? <React.Fragment>
                                                                        <div>
                                                                            <strong>Supplier: </strong>{orderLine.supplierName}
                                                                        </div>
                                                                        <div>
                                                                            <strong>DC: </strong>{orderLine.dcName}
                                                                        </div>
                                                                      </React.Fragment>
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                              {orderLine.productColorName ? orderLine.productColorName : null}
                                                              {orderLine.productColorNumber ? ` ${orderLine.productColorNumber}` : null}
                                                            </td>
                                                            <td>{orderLine.sampleBookName ? orderLine.sampleBookName : null}</td>
                                                            <td className="text-right">
                                                                <ButtonGroup>
                                                                    {orderLine.deactivatedAt === null
                                                                        ? (
                                                                            <React.Fragment>
                                                                                <Button
                                                                                    className="primary btn-outline-secondary"
                                                                                    onClick={this.onEdit.bind(this, orderLine.memoOrderId)}
                                                                                    size="sm"
                                                                                >
                                                                                    <FontAwesomeIcon icon="edit" />
                                                                                </Button>
                                                                                <Button
                                                                                    className="danger btn-outline-secondary"
                                                                                    size="sm"
                                                                                    onClick={this.handleDelete.bind(this, orderLine.memoOrderId)}
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" />
                                                                                </Button>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <Button
                                                                                className="info" size="sm"
                                                                                onClick={this.handleUnDelete.bind(this, orderLine.memoOrderId)}
                                                                            >
                                                                                <FontAwesomeIcon icon="recycle" /> Revive
                                                                            </Button>
                                                                        )}
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Pager {...this.state.pagedList} callBack={this.setPage} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
