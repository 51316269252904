import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Table,
    Card,
    Row,
    Col,
    CardBody
} from 'reactstrap';
import {
    api,
    helpers,
    date_helpers
} from '../utils';
import MemoStockOrders from './MemoStockOrders';
import _ from "lodash";

export default class ShippingConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          pendingMemoOrders: [],
          backorderedMemoOrders: [],
          pendingMemoOrderLines: []
      };
      this.toggleCollapse = this.toggleCollapse.bind(this);
      this.getPendingMemoOrderLines = this.getPendingMemoOrderLines.bind(this);
    }

    componentDidMount() {
        api.fetch(`MemoOrder/GetPendingAndBackOrderedMemoOrders`)
        .then((response) => {
            this.setState({
              pendingMemoOrders: _.map(response.data.pendingMemoOrders, pmo => { pmo.collapse = false; pmo.pendingMemoOrderLines = []; return pmo;}),
              backorderedMemoOrders: response.data.backorderedMemoOrderLines
            });
        }).catch(helpers.catchHandler);
    }

  getPendingMemoOrderLines(memoOrderId) {
    let list = this.state.pendingMemoOrders.slice();
    let index = _.findIndex(list, o => { return o.id === memoOrderId });
    list[index].collapse = !list[index].collapse;

    if (list[index].collapse) {
      api.fetch(`MemoOrder/GetPendingMemoOrderLines/${memoOrderId}`)
        .then((response) => {
          list[index].pendingMemoOrderLines = response.data;
          this.setState({
            pendingMemoOrders: list,
          });
        }).catch(helpers.catchHandler);

    } else {
      list[index].pendingMemoOrderLines = [];
      this.setState({
        pendingMemoOrders: list
      })
    }
    }

    toggleCollapse(memoOrderId) {
      let list = this.state.pendingMemoOrders.slice();
      let index = _.findIndex(list, o => { return o.id === memoOrderId });
      list[index].collapse = !list[index].collapse;
      this.setState({
        pendingMemoOrders: list
      })
    }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="pull-left page-title">Shipping Console</h3>
                            </Col>
                        </Row>
                        <Card className="mb-2">
                          <CardBody>
                            <Row className="expand-md mt-2 mb-0 pb-0">
                                <Col>
                                    <h4>Memo Orders Pending Shipment</h4>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Table striped hover size="sm">
                                        <thead>
                                          <tr>
                                            <th>Memo Order #</th>
                                            <th>Account #</th>
                                            <th>Account Name</th>
                                            <th>Entered Date</th>
                                            <th>Entered By</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.pendingMemoOrders.map(pmo => (
                                            <Fragment>
                                              <tr key={`${pmo.id}-pmo`}>
                                                <td>
                                                  <Link 
                                                    to={{
                                                        pathname: `/memoorders/`,
                                                        state: {
                                                          memoOrderId: pmo.id
                                                        }
                                                    }}
                                                  >
                                                    {pmo.id}
                                                  </Link>
                                                </td>
                                                <td>{pmo.customerNumber}</td>
                                                <td>{pmo.customerName}</td>
                                                <td>{date_helpers.formatDateToShortDate(pmo.createdAt)}</td>
                                                <td>{pmo.createdByUserName}</td>
                                                <td>
                                                  <FontAwesomeIcon
                                                    icon={pmo.collapse ? "compress" : "bars"}
                                                    className="text-primary"
                                                    title="Click to Collapes"
                                                    onClick={()=>this.getPendingMemoOrderLines(pmo.id)}
                                                  />
                                                </td>
                                              </tr>
                                              {pmo.collapse ? (
                                                <tr key={`pmo-${pmo.id}-pmol`}>
                                                  <td colSpan="6">
                                                    <Card>
                                                      <table>
                                                        <thead>
                                                          <tr>
                                                            <th>Size</th>
                                                            <th>Shelf #</th>
                                                            <th>Supplier Product</th>
                                                            <th>DC Product</th>
                                                            <th>Color</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {pmo.pendingMemoOrderLines && pmo.pendingMemoOrderLines.length ?
                                                            pmo.pendingMemoOrderLines.map((pmol, index) => (
                                                              <tr key={`pmol-${index}`}>
                                                                <td>{pmol.memoSizeName}</td>
                                                                <td>{pmol.productMemoShelfNumber}</td>
                                                                <td>{pmol.supplierName}</td>
                                                                <td>{pmol.dcName}</td>
                                                                <td>
                                                                  {pmol.productColorName}
                                                                  {" "}
                                                                  {pmol.productColorNumber}
                                                                </td>
                                                              </tr>
                                                            )) : null}
                                                        </tbody>
                                                      </table>
                                                    </Card>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </Fragment>
                                          ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <MemoStockOrders />
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <Row className="expand-md mt-2 mb-0 pb-0">
                                <Col>
                                    <h4>Backordered Memo Orders</h4>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Memo Order #</th>
                                                <th>Placed On</th>
                                                <th>Size</th>
                                                <th>Shelf #</th>
                                                <th>Supplier Product</th>
                                                <th>DC Product</th>
                                                <th>Color</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.backorderedMemoOrders.map(bmo => (
                                                <tr key={bmo.id}>
                                                    <td>
                                                        <Link 
                                                            to={{
                                                                pathname: `/memoorders/`,
                                                                state: {
                                                                    memoOrderId: bmo.memoOrderId
                                                                }
                                                            }}
                                                        >
                                                            {bmo.memoOrderId}
                                                        </Link>
                                                    </td>
                                                    <td>{date_helpers.formatDateToShortDate(bmo.createdAt)}</td>
                                                    <td>{bmo.memoSizeName}</td>
                                                    <td>{bmo.productMemoShelfNumber}</td>
                                                    <td>{bmo.supplierName}</td>
                                                    <td>{bmo.dcName}</td>
                                                    <td>
                                                      {bmo.productColorName}
                                                      {" "}
                                                      {bmo.productColorNumber}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}