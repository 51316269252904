import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import _ from 'lodash';

class FilterMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      options: props.options,
      values: props.values,
      onChangeCallback: props.onChangeCallback
    };
    this.onChange = this.onChange.bind(this);
    this.getValues = this.getValues.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values !== this.props.values) {
      const appliedValues = this.props.values === "" ? null : this.props.values;
      this.setState({values: appliedValues});
    }
    if (prevProps.options !== this.props.options) {
      this.setState({options: this.props.options});
    }
  }

  onChange(selections) {

    if(!selections){
      this.state.onChangeCallback({
        filterName: this.state.filterName,
        values: [],
        labels: [],
        filteredDisplay: ""
      });
      return;
    }

    const values = selections.map(x => x.value);
    const labels = selections.map(x => x.label);
    let valuesDisplay = labels.join(', ');
    const filteredDisplay =
      valuesDisplay
        ? `${this.state.displayName}: ${valuesDisplay}`
        : '';

    this.state.onChangeCallback({
      filterName: this.state.filterName,
      labels: labels,
      filteredDisplay: filteredDisplay,
      value: values
    });
  }

  getValues() {
    if (!this.state.values || this.state.values.length === 0 ) {
      return null;
    }
    const vals = this.state.values;
    const selectedOptions = _.filter(this.state.options, function(x) {
      return _.includes(vals, x.value);
    });
    return selectedOptions;
  }

  render() {
    if (!this.props.options) {
      return null;
    }
    const selectedValues = this.getValues();
    return (
      <span className="filter-definition-caption">
        {this.state.displayName}:
        {selectedValues === null // working around a deficiency in this select control for setting no items selected on a multiselect
        ? (<Select
            closeMenuOnSelect={true}
            isMulti
            components={makeAnimated()}
            options={this.state.options}
            onChange={this.onChange}
            values={null}
            value={null}
            classNamePrefix="react-select"
          />)
        : (<Select
            closeMenuOnSelect={true}
            isMulti
            components={makeAnimated()}
            options={this.state.options}
            onChange={this.onChange}
            values={selectedValues}
            classNamePrefix="react-select"
          />)
        }
      </span>
    );
  }
}

export default FilterMultiSelect;
