import React, { Component } from 'react';
import {
    Alert, Button, ButtonGroup, Card, Col, Container, Input, Label,
    Modal, ModalHeader, ModalBody, ModalFooter, Row, Table, CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
// import helpers from '../utils/helpers';
import filterHelpers from '../utils/filter_helpers';
// import api from '../utils/api';
import { api, constants, helpers } from '../utils';
import { FilterBoolean, FilterMultiSelect, FilterSet, FilterText, Pager, SortHeader } from '../components';

const apiUrl = 'Admin/';

const emptyUser = {
    id: 0,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    mobileNumber: '',
    agent: {},
    roles: [],
    isOfficeStaff: false,
    RoleSelection: []
};

const inputMaxLengths = {
    // change these numbers to match the length of the fields in the database
    firstName: 25,
    lastName: 25,
    username: 150,
    email: 150,
    mobileNumber: 15
};

const getUserRoleNameCSV = (roleIdList) => {
    if (!roleIdList) return "";
    return _.map(_.filter(constants.USER_ROLES, role => {
        return _.some(roleIdList, rid => { return role.id === rid; });
    }), role => role.name).join(', ');
};

// const getRoleJson = (roleList) => {
//   let array = _.map(roleList, x => {
//     return {Role: x, Selected: true};
//   });
//   return array;
// };

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagedList: {
                pageSize: 100, //setting all lists to 100
            },
            message: null,
            messageFlavor: 'danger',
            editItem: props.editItem || emptyUser,
            agentList: props.agentList
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleIsOfficeChange = this.handleIsOfficeChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.checkboxHandler = this.checkboxHandler.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    // componentDidUpdate(prevProps, prevState) {
    //   if (prevProps.editingItem !== this.props.editingItem) {
    //     this.setState({user: this.props.editingItem});
    //   }  
    // }

    handleFormInputChange(event) {
        this.setState({ editItem: { ...this.state.editItem, [event.target.name]: event.target.value } });
    }

    handleIsOfficeChange(event) {
        this.setState({ editItem: { ...this.state.editItem, isOfficeStaff: event.target.checked } });
    }

    onSelectChange(event) {
        this.setState({
            editItem: {
                ...this.state.editItem,
                agent: event,
                agentId: event.value,
                agentName: event.label
            }
        })
    }

    checkboxHandler(e) {
        let userRoles = this.state.editItem.roles.slice();
        //makes sure e.target.value is an integer
        let value = +e.target.value;

        if (e.target.checked) {
            userRoles.push(value);
        } else {
            userRoles.splice(_.indexOf(userRoles, value), 1);
        }
        const sorted = _.sortBy(userRoles, x => x);
        this.setState({ editItem: { ...this.state.editItem, roles: sorted } });
    }

    isChecked(id, userRoles) {
        return _.findIndex(userRoles, x => x === id) !== -1;
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.editItem.firstName || this.state.editItem.firstName.length < 2) {
            warnings.push('Please provide a valid first name.')
        }
        if (!this.state.editItem.lastName || this.state.editItem.lastName.length < 2) {
            warnings.push('Please provide a valid last name.')
        }
        if (!this.state.editItem.username) {
            warnings.push('Please provide a username.')
        }
        if ((!this.state.editItem.password || this.state.editItem.password.length < 8) && this.state.editItem.id === 0) {
            warnings.push('Please provide a password of at least 8 characters.')
        }
        if (!this.state.editItem.email || this.state.editItem.email.length < 2|| !helpers.emailIsValid(this.state.editItem.email)) {
            warnings.push('Please provide a valid email.')
        }
        // if (!this.state.editItem.mobileNumber || this.state.editItem.email.length < 2) {
        //     warnings.push('Please provide a valid mobile number.')
        // }
        if (!this.state.editItem.roles || this.state.editItem.roles.length < 1) {
            warnings.push('Please select a role for the user.')
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(' '),
                messageFlavor: 'danger'
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onSubmit() {
        window.scroll(0, 0);
        if (!this.isFormValid()) return;
        const payload = {
            ...this.state.editItem,
            initialPassword: this.state.editItem.password,
            agentId: this.state.editItem.agent.value || null
        }
        if (!payload.id) { delete payload.id; }
        if (!this.isChecked(5, this.state.editItem.roles)) { delete payload.agentId; } // if agent isn't one of the roles, don't set agent on user
        
        api.post(apiUrl + "SaveUser", payload)
        .then(response => {
            if (response.data.success) {
                // this.setState({ messageFlavor: "success", message: "The user record was updated." });
                this.props.onCancel();
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }
        })
        .catch(helpers.catchHandler);
    }

    onCancel() {
        this.setState({ editItem: emptyUser }, () => this.props.onCancel());
    }

    render() {
        return (
            <Modal size="lg" isOpen={true}>
                <ModalHeader toggle={this.props.onCancel}>
                    <div className="page-title">{this.state.editItem.id ? 'Edit' : 'Add'} Staff</div>
                </ModalHeader>
                <ModalBody>
                    {this.state.message ? <Alert className={this.state.messageFlavor}>{this.state.message}</Alert> : null}
                    <Row className="mb-2">
                        <Col sm="6">First Name {helpers.requiredStar()}
                            <Input 
                                type="text" 
                                name="firstName" 
                                id="firstName"
                                onChange={this.handleFormInputChange} 
                                value={this.state.editItem.firstName || ""}
                                maxLength={inputMaxLengths.firstName}
                            />
                        </Col>
                        <Col sm="6">Last Name {helpers.requiredStar()}
                            <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                onChange={this.handleFormInputChange}
                                value={this.state.editItem.lastName || ""}
                                maxLength={inputMaxLengths.lastName}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">Username {helpers.requiredStar()}
                            {this.state.editItem.id === 0
                                ? <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={this.handleFormInputChange}
                                    value={this.state.editItem.username || ""}
                                    maxLength={inputMaxLengths.username}
                                />
                                : <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={this.handleFormInputChange}
                                    value={this.state.editItem.username || ""}
                                    readOnly
                                />
                            }
                        </Col>
                        {this.state.editItem.id === 0
                            ? <Col sm="6">Password {helpers.requiredStar()}
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={this.handleFormInputChange}
                                    value={this.state.editItem.password || ""}
                                />
                            </Col>
                            : <Col sm="6"></Col>
                        }
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">E-Mail {helpers.requiredStar()}
                            <Input
                                type="text"
                                name="email"
                                id="email"
                                onChange={this.handleFormInputChange}
                                value={this.state.editItem.email || ""}
                                maxLength={inputMaxLengths.email}
                            />
                        </Col>
                        <Col sm="6">Mobile Number
                            <Input
                                type="text"
                                name="mobileNumber"
                                id="mobileNumber"
                                onChange={this.handleFormInputChange}
                                value={this.state.editItem.mobileNumber || ""}
                                maxLength={inputMaxLengths.mobileNumber}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="2">
                            <Label>Office Staff?</Label>
                        </Col>
                        <Col sm="3">
                            <input
                                type="checkbox"
                                value="isOffice"
                                onChange={this.handleIsOfficeChange}
                                checked={this.state.editItem.isOfficeStaff}>
                            </input>
                        </Col>
                    </Row>
                    <Row className="form-group mb-0 mt-2">
                        <Col>
                            <Label className="font-weight-bold">Staff Roles:</Label>
                        </Col>
                    </Row>
                    <Row>
                        {constants.USER_ROLES.map(r =>
                            <Col sm="4" key={`r-${r.id}`} className="text-left">
                                <label className="checkbox-inline">
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        value={r.id}
                                        onChange={this.checkboxHandler}
                                        checked={this.isChecked(r.id, this.state.editItem.roles)} 
                                    />{r.name}
                                </label>
                            </Col>
                        )}
                    </Row>
                    {this.isChecked(5, this.state.editItem.roles) &&
                        <Row>
                            <Col>
                                <Label for="agent" className="mb-1">Associated Agent:</Label>
                                <Select
                                    closeMenuOnSelect
                                    id="agent"
                                    value={this.state.editItem.agent}
                                    isMulti={false}
                                    components={makeAnimated()}
                                    options={this.state.agentList}
                                    onChange={this.onSelectChange}
                                />
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup className="float-right">
                        <Button
                            className="primary"
                            size="sm"
                            onClick={this.onSubmit}
                        >
                            <FontAwesomeIcon icon="save" /> Save
                        </Button>
                        <Button
                            className="secondary"
                            size="sm"
                            onClick={this.onCancel}
                        >
                            <FontAwesomeIcon icon="times-circle" /> Cancel
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        );
    }
}

export default class UserAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagedList: {
                list: [],
                pageNumber: 1
            },
            messageFlavor: 'danger',
            message: null,
            editItem: null,
            sortField: 'LastName',
            sortDir: 'asc',
            filters: [],
            clearValue: false
        };

        this.refreshList = this.refreshList.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.setPage = this.setPage.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.renderEditButtons = this.renderEditButtons.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.toggleActivation = this.toggleActivation.bind(this);
    }

    componentDidMount() {
        const getAgents = api.fetch('Reference/GetAgentList/')
            .then((response) => {
                return { agentList: response.data };
            })
            .catch(helpers.catchHandler);

        Promise.all([getAgents])
            .then((aggregateResults) => {
                const newStatus = {};
                aggregateResults.forEach(r => Object.assign(newStatus, r));
                this.setState(newStatus, () =>
                    this.refreshList());
            })
            .catch(helpers.catchHandler);
    }

    refreshList(sortField, sortDirection, filters) {
        let filterList = filters || this.state.filters;
        let payload = {
            page: this.state.pagedList.pageNumber,
            pageSize: this.state.pagedList.pageSize,
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir
        }

        // change it into the format C# is expecting and add to payload
        _.each(filterList, filter => payload[filter.filterName] = filter.value);
        api.post(apiUrl + 'GetList', payload)
            .then((response) => {
                this.setState({
                    pagedList:
                    {
                        ...response.data,
                        list: _.map(response.data.list, user => {
                            // prepare for react-select
                            return {
                                ...user,
                                agent: { label: user.agentName, value: user.agentId },
                            }
                        })
                    }
                })
            })
            .catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filterHelpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, () => this.refreshList());
    }

    toggleActivation(id) {  // Handle Delete and Revive
        api.post(apiUrl + `ToggleUserActive/${id}`)
        .then(response => {
            if (response.data.success) {
                this.setState({ message: null });
                
            } else {
                this.setState({ message: response.data.message });
            }
            this.refreshList();
        })
        .catch(error => error);
    }

    onAdd() {
        this.setState({ editItem: emptyUser });
    }

    onEdit(user) {
        this.setState({ editItem: user })
    }

    onCancel() {
        this.setState({ editItem: null });
        this.refreshList();
    }

    renderEditButtons(user) {
        if (user.id !== 0) {
            return (
                <ButtonGroup>
                    {user.isActive ?
                        <React.Fragment>
                            <Button
                                className="primary btn-outline-secondary"
                                onClick={() => this.onEdit(user)} size="sm"
                            >
                                <FontAwesomeIcon icon="edit" />
                            </Button>
                            <Button
                                className="danger btn-outline-secondary"
                                size="sm"
                                onClick={this.toggleActivation.bind(this, user.id)}
                            >
                                <FontAwesomeIcon icon="trash" />
                            </Button>
                        </React.Fragment>
                    :
                        <Button
                            className="info"
                            size="sm"
                            onClick={this.toggleActivation.bind(this, user.id)}
                        >
                            <FontAwesomeIcon icon="recycle" /> Revive
                        </Button>
                    }
                </ButtonGroup>
            );
        }
    }

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.message !== null && (
                            <Row className="mb-2">
                                <Col>
                                    <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                </Col>
                            </Row>
                        )}
                        
                        <Row className="row-spacing">
                            <Col>
                                <h3 className="pull-left page-title">Staff Administration</h3>
                            </Col>
                        </Row>
                        <Row className="expand-md mt-2 mb-0 pb-0">
                            <Col>
                                <ButtonGroup className="float-right">
                                    <Button
                                        className="success"
                                        size="sm"
                                        onClick={this.onAdd}
                                    >
                                        <FontAwesomeIcon icon="plus" /> Add Staff
                                    </Button>
                                </ButtonGroup>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterText filterName="Name" displayName="Name"
                                                value={this.currentFilterValue('Name')} onChangeCallback={this.filterChange} />
                                        </Col>
                                        <Col xs="3">
                                            <FilterMultiSelect
                                                filterName="MemberOfRoles"
                                                displayName="Roles"
                                                values={this.currentFilterValue('MemberOfRoles')}
                                                onChangeCallback={this.filterChange}
                                                options={constants.USER_ROLES}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean filterName="ActiveOnly" displayName="Active Only" yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')} onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SortHeader displayName="First Name" field="FirstName" sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'FirstName'} callBack={this.doSort} />
                                            </th>
                                            <th>
                                                <SortHeader displayName="Last Name" field="LastName" sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'LastName'} callBack={this.doSort} />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Username"
                                                    field="Username"
                                                    sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'Username'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="E-Mail"
                                                    field="Email"
                                                    sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'Email'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Mobile Phone"
                                                    field="MobileNumber"
                                                    sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'MobileNumber'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th>
                                                Agent
                                                </th>
                                            <th>
                                                Staff Roles
                                                </th>
                                            <th>
                                                <SortHeader
                                                    displayName="Office Staff"
                                                    field="OfficeStaff"
                                                    sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'OfficeStaff'}
                                                    callBack={this.doSort}
                                                />
                                            </th>
                                            <th width="15%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(this.state.pagedList.list, u =>
                                            <tr key={u.id} className="data-row">
                                                <td>{u.firstName}</td>
                                                <td>{u.lastName}</td>
                                                <td>{u.username}</td>
                                                <td>{u.email}</td>
                                                <td>{u.mobileNumber}</td>
                                                <td>{u.agent.value}</td>
                                                <td>{getUserRoleNameCSV(u.roles)}</td>
                                                <td>{u.isOfficeStaff ? <FontAwesomeIcon icon='check' /> : null}</td>
                                                <td className="text-right">
                                                    {this.renderEditButtons(u)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <Pager {...this.state.pagedList} callBack={this.setPage} />
                                {this.state.editItem
                                    ? <EditUser
                                        editItem={this.state.editItem}
                                        onCancel={this.onCancel}
                                        onSuccess={this.refreshList}
                                        agentList={this.state.agentList}
                                    />
                                    : null
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
