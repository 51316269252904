import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

class FilterText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      debounceTimeout: props.debounceTimeout || 500,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChange(value) {
    const filteredDisplay = value
      ? `${this.props.displayName} ${this.props.descriptionPrefix ? this.props.descriptionPrefix : "contains"} ${value}`
      : null;

    this.props.onChangeCallback({
      filterName: this.props.filterName,
      value,
      filteredDisplay,
    });
  }

  render() {
    return (
      <span className="filter-definition-caption">
        {this.props.displayName ? this.props.displayName : null}
        <DebounceInput
          className="form-control"
          minLength={2}
          value={this.state.value}
          debounceTimeout={this.state.debounceTimeout}
          onChange={event => this.onChange(event.target.value)}
          placeholder={this.props.placeholder ? this.props.placeholder : null}
        />
      </span>
    );
  }
}

export default FilterText;
