import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Container,
    Card,
    CardBody,
    Col,
    Row,
    Table,
    Alert,
    Collapse,
    Input,
    Modal,
    ModalBody
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { CustomerTypeahead } from '../components';
import CustomerAdmin from './CustomerAdmin';
import {
    api,
    helpers,
    date_helpers
} from '../utils';

const emptyMillReserve = {
    supplier: null,
    reserveNumber: '',
    customer: null,
    product: null, 
    productColor: null,
    width: null,
    feet: '',
    fob: '',
    comment: ''
};

class MillReserves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            millReserves: [],
            message: null,
            messageFlavor: null,
            // customerList: [],
            supplierList: [],
            millReserveToAddEdit: emptyMillReserve,
            showMillReserveForm: false,
            showCustomerSearchModal: false,
            productsList: [],
            productColorsList: [],
            widthsList: []
        };
        this.refreshList = this.refreshList.bind(this);
        this.onToggleCustomerSearchModal = this.onToggleCustomerSearchModal.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.setSelectedProduct = this.setSelectedProduct.bind(this);
        this.fetchProductWidths = this.fetchProductWidths.bind(this);
        this.fetchListsForMillReserveToEdit = this.fetchListsForMillReserveToEdit.bind(this);
        this.onToggleCustomerSearchModal = this.onToggleCustomerSearchModal.bind(this);
        this.onCustomerSelectedFromSearchModal = this.onCustomerSelectedFromSearchModal.bind(this);
    }

    componentDidMount() {
    const getSupplier = api.fetch('Reference/GetSupplierList')
      .then((result) => {
        return { supplierList: result.data };
      })
      .catch(helpers.catchHandler);

    Promise.all([
      getSupplier
    ])
      .then((aggregateResults) => {
          const newStatus = {};
          aggregateResults.forEach(r => Object.assign(newStatus, r));
          this.setState(newStatus, () =>
              this.refreshList());
      })
      .catch(helpers.catchHandler);
    }

    refreshList() {
        api.post(`MillReserve/GetList`)
        .then(response => {
          this.setState({
            millReserves: _.map(response.data, mr => {
              return {
                id: mr.id,
                supplier: helpers.resolveValue(null, mr.supplierId, mr.supplierName),
                supplierName: helpers.nullableString(mr.supplierName),
                supplierAccountNumber: helpers.nullableString(mr.supplierAccountNumber),
                millAgentPhoneNumber: helpers.nullableString(mr.millAgentPhoneNumber),  //should be SupplierPhoneNo (not in new database)
                reserveNumber: helpers.nullableString(mr.reserveNumber),
                product: helpers.resolveValue(null, mr.productId, mr.productDCName),
                productDCName: helpers.nullableString(mr.productDCName),
                productDCNumber: helpers.nullableString(mr.productDCNumber),
                productSupplierName: helpers.nullableString(mr.productSupplierName),
                productSupplierNumber: helpers.nullableString(mr.productSupplierNumber),
                productColor: helpers.resolveValue(null, mr.productColorId, mr.productColorName),
                productColorName: helpers.nullableString(mr.productColorName),
                feet: mr.feet ? _.round(mr.feet, 2).toFixed(2) : '0',
                createdAt: date_helpers.formatDateToShortDate(mr.createdAt),
                lastReservedAt: date_helpers.formatDateToShortDate(mr.lastReservedAt),
                fob: helpers.nullableString(mr.fob),
                customer: helpers.resolveValue(null, mr.customerId, mr.customerName),
                customerName: mr.customerName,
                customerNumber: mr.customerNumber,
                customerContact: `${mr.customerContactFirstName} ${mr.customerContactLastName}`,
                customerPhone: mr.customerPhone,
                comment: helpers.nullableString(mr.comment),
              }
            })
          });
        })
        .catch(helpers.catchHandler);
    }   

    toggleAddMillReserveCollapse() {
        this.setState({
            showMillReserveForm: !this.state.showMillReserveForm,
            millReserveToAddEdit: emptyMillReserve,
            message: null,
            messageFlavor: null,
            productsList: [],
            productColorsList: [],
            widthsList: []     
        });
    }

    onEdit(millReserve) {
      this.setState({
        showMillReserveForm: true,
        millReserveToAddEdit: millReserve,
        }, () => this.fetchListsForMillReserveToEdit(millReserve.supplier.value, millReserve.product.value));
    }

    async fetchListsForMillReserveToEdit(supplierId, productId) {
        let productsList;
        let productColorsList;
        let widthsList;

        await api.fetch(`Product/GetActiveProductsBySupplier/${supplierId}`)
        .then(response => {
              productsList = response.data;
        })
        .catch(helpers.catchHandler);

        await api.fetch(`Order/GetActiveProductColors/${productId}`)
        .then(response => {
            productColorsList = response.data;
        })
        .catch(helpers.catchHandler);

        await api.fetch(`Product/GetProductWidths/${productId}`)
        .then(response => {
            const widths = _.map(response.data, w => {
                return {
                    value: w.widthId,
                    label: w.widthName, 
                    widthNumericFeet: w.widthNumericFeet
                }  
            });
                widthsList = widths; 
        })
        .catch(helpers.catchHandler);

        this.setState({
            productsList: productsList,
            productColorsList: productColorsList,
            widthsList: widthsList
        });
    }

    handleTypeaheadSelectionChange(fieldName, selection) {
      this.setState({ 
        millReserveToAddEdit: {
            ...this.state.millReserveToAddEdit, 
            [fieldName]: selection,
            errorMessage: null
        }
      });
    }

    setSelectedSupplier(option) {
      api.fetch(`Product/GetActiveProductsBySupplier/${option.value}`)
      .then(response => {
          this.setState({
            productsList: response.data,
            millReserveToAddEdit: {
                ...this.state.millReserveToAddEdit,
                supplier: option, 
                product: response.data.length > 0 ? response.data[0] : null
            }
          }, () => {
            if (this.state.millReserveToAddEdit.product) {
              this.setSelectedProduct(this.state.millReserveToAddEdit.product);
            }
          });
      })
      .catch(helpers.catchHandler);
    }


    handleMillReserveStateChange(fieldName, value) {
      this.setState({
        millReserveToAddEdit: {
              ...this.state.millReserveToAddEdit,
              [fieldName]: value
          }
      });
    }

    setSelectedProduct(option) {
      api.fetch(`Order/GetActiveProductColors/${option.value}`)
      .then(response => {
          this.setState({
              productColorsList: response.data,
              millReserveToAddEdit: {
                  ...this.state.millReserveToAddEdit,
                  product: option, 
                  productColor: response.data.length > 0 ? response.data[0] : null
              }
          }, () => this.fetchProductWidths(option));
      })
      .catch(helpers.catchHandler);
    }

    fetchProductWidths(option) {
      api.fetch(`Product/GetProductWidths/${option.value}`)
      .then(response => {
          const widths = _.map(response.data, w => {
              return {
                  value: w.widthId,
                  label: w.widthName, 
                  widthNumericFeet: w.widthNumericFeet
              }  
          });
          this.setState({
              widthsList: widths,
              millReserveToAddEdit: {
                  ...this.state.millReserveToAddEdit,
                  width: widths.length > 0 ? widths[0] : null                }
          });
      })
      .catch(helpers.catchHandler);
    }

    setSelectedProductColor(option) {
      this.setState(prevState => ({
          ...prevState,
          millReserveToAddEdit: {
              ...prevState.millReserveToAddEdit,
              productColor: option
          }
      }));
    }

    setSelectedWidth(option) {
      this.setState(prevState => ({
          ...prevState,
          millReserveToAddEdit: {
              ...prevState.millReserveToAddEdit,
              width: option
          }
      }));
    }

    isFormValid(item) {
      let warnings = [];
      if(!item.supplier) {
        warnings.push("Supplier is required.");
      }
      if(!item.customer) {
        warnings.push("Customer is required.");
      }
      if (!item.product) {
        warnings.push("Product is required.");
      }
      if(!item.productColor) {
        warnings.push("Product color is required.");
      }
      if(!item.feet) {
          warnings.push("Linear Feet is required.");
      }
      if (warnings.length) {
        this.setState({
          message: warnings.join(' '),
          messageFlavor: "danger"
        });
      } else {
        this.setState({ message: null });
      }
      return warnings.length === 0;
    }

    saveNewMillReserve() {
        if (!this.isFormValid(this.state.millReserveToAddEdit)) return;
        const millReserveToAddEdit = Object.assign({}, this.state.millReserveToAddEdit);
        const data = {
                Id: 0,
                SupplierId: millReserveToAddEdit.supplier.value,
                ReserveNumber: millReserveToAddEdit.reserveNumber,
                CustomerId: millReserveToAddEdit.customer.value,
                ProductId: millReserveToAddEdit.product.value,
                ProductColorId: millReserveToAddEdit.productColor.value,
                Width: millReserveToAddEdit.width ? millReserveToAddEdit.width.widthNumericFeet : null,  // new database table still has option to save numeric feet and text (should just save widthId)
                WidthText: millReserveToAddEdit.width ? millReserveToAddEdit.width.label : null,  // new database table still has option to save numeric feet and text (should just save widthId)
                // WidthId: millReserveToAddEdit.width ? millReserveToAddEdit.width.value : null,  // not currently in database
                Feet: Number(millReserveToAddEdit.feet),
                FOB: millReserveToAddEdit.fob,
                Comment: millReserveToAddEdit.comment
        };

        api.post("MillReserve/SaveMillReserve", data)
        .then(response => {
            if (response.data.success) {
                this.setState({
                    showMillReserveForm: false,
                    millReserveToAddEdit: emptyMillReserve
                }, () => this.refreshList());
            } else {
                this.setState({
                    messageFlavor: "danger",
                    message: response.data.message
                });
            } 
        })
        .catch(helpers.catchHandler);
    }

    updateMillReserve(millReserve, rereserving = false) {
        if (!this.isFormValid(millReserve)) return;
        const data = {
          Id: millReserve.id,
          SupplierId: millReserve.supplier.value,
          ReserveNumber: millReserve.reserveNumber,
          CustomerId: millReserve.customer.value,
          ProductId: millReserve.product.value,
          ProductColorId: millReserve.productColor.value,
          Width: millReserve.width ? millReserve.width.widthNumericFeet : null,  // new database table still has option to save numeric feet and text (should just save widthId)
          WidthText: millReserve.width ? millReserve.width.label : null,  // new database table still has option to save numeric feet and text (should just save widthId)
          // WidthId: millReserve.width ? millReserve.width.value : null,  // not currently in database
          Feet: Number(millReserve.feet),
          FOB: millReserve.fob,
          Comment: millReserve.comment
        };

        api.post("MillReserve/SaveMillReserve", data)
        .then(response => {
            if (response.data.success) { 
              if (rereserving) {
                this.setState({
                  showMillReserveForm: false,
                  millReserveToAddEdit: emptyMillReserve,
                  messageFlavor: "success",
                  message: 'Re-Reserve Successful'
                }, () => this.refreshList());
              } else {
                this.setState({
                  showMillReserveForm: false,
                  millReserveToAddEdit: emptyMillReserve
              }, () => this.refreshList());
              }
            } else {
                this.setState({ 
                    messageFlavor: "danger",
                    message: response.data.message
                });
            }   
        })
        .catch(helpers.catchHandler);
    }

    handleDelete(id) {  // hard delete
        api.post(`MillReserve/HardDeleteMillReserve/${id}`)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    // handleDelete(id) {
    //     api.delete(`MillReserve/${id}`)
    //     .then(response => {
    //         if (response.data.success) { 
    //             this.refreshList()
    //         } else {
    //             this.setState({ messageFlavor: "danger", message: response.data.message });
    //         }  
    //     }) 
    //     .catch(helpers.catchHandler);
    // }

    // handleUnDelete(id) {
    //     api.post(`MillReserve/Undelete/${id}`)
    //     .then(response => {
    //         if (response.data.success) { 
    //             this.refreshList()
    //         } else {
    //             this.setState({ messageFlavor: "danger", message: response.data.message });
    //         }  
    //     }) 
    //     .catch(helpers.catchHandler);
    // }

    onToggleCustomerSearchModal() {
      this.setState({showCustomerSearchModal: !this.state.showCustomerSearchModal})
    }

    onCustomerSelectedFromSearchModal(customerId, customerName) {
      this.setState({
          showCustomerSearchModal: false,
          millReserveToAddEdit: {
              ...this.state.millReserveToAddEdit,
              customer: helpers.resolveValue(null, customerId, customerName)
          }
      });
    }

    render() {
        return (
          <Container fluid>
          <Card style={{ borderStyle: "none" }}>
            <CardBody>

              {this.state.message && (
                  <Row className="mb-2">
                      <Col>
                          <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                      </Col>
                  </Row>
              )}
              <Row className="row-spacing">
                  <Col>
                      <h4>Mill Reserves</h4>
                  </Col>
              </Row>
              <Row className="expand-md mt-2 mb-0 pb-0">
                  <Col sm="12">
                      {this.state.showMillReserveForm
                          ? null
                          :   <ButtonGroup className="float-right">
                                  <Button
                                      size="sm"
                                      className="success"
                                      onClick={() => this.toggleAddMillReserveCollapse()}
                                      title="Add New OrderLine"
                                  >
                                      <FontAwesomeIcon icon="plus" /> Add Reserve
                                  </Button>
                              </ButtonGroup>
                      }
                  </Col>
              </Row>
              <Collapse isOpen={this.state.showMillReserveForm} className="row-spacing">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col>Supplier {helpers.requiredStar()}
                        <Select
                          closeMenuOnSelect
                          value={this.state.millReserveToAddEdit.supplier}
                          options={this.state.supplierList}
                          onChange={(option) => this.setSelectedSupplier(option)}
                        />
                      </Col>
                      <Col>Reserve #
                        <Input
                          type="text"
                          name="reserveNumber"
                          value={this.state.millReserveToAddEdit.reserveNumber}
                          onChange={(event) => this.handleMillReserveStateChange(
                              event.target.name,
                              event.target.value
                          )}
                          maxLength="20"
                        />
                      </Col>
                      <Col sm="6">
                        <Button
                          className="m-0 p-0"
                          color="link"
                          style={{textDecoration: "underline"}}
                          onClick={this.onToggleCustomerSearchModal}
                        >
                          Customer {helpers.requiredStar()}
                        </Button>
                        <CustomerTypeahead
                          onChange={(e) => this.handleTypeaheadSelectionChange("customer", e)}
                          isMulti={false}
                          customer={this.state.millReserveToAddEdit.customer}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Product {helpers.requiredStar()}
                        <Select
                            closeMenuOnSelect
                            value={this.state.millReserveToAddEdit.product}
                            options={this.state.productsList}
                            onChange={(option) => this.setSelectedProduct(option)}
                        />
                      </Col>
                      <Col>Color {helpers.requiredStar()}
                        <Select
                            closeMenuOnSelect
                            value={this.state.millReserveToAddEdit.productColor}
                            options={this.state.productColorsList}
                            onChange={(option) => this.setSelectedProductColor(option)}
                        />
                      </Col>
                      <Col>Width
                        <Select
                            closeMenuOnSelect
                            value={this.state.millReserveToAddEdit.width}
                            options={this.state.widthsList}
                            onChange={(option) => this.setSelectedWidth(option)}
                        />
                      </Col>
                      <Col>Linear Feet {helpers.requiredStar()}
                        <Input
                            type="number"
                            step="0.01"
                            name="feet"
                            onChange={(event) => this.handleMillReserveStateChange(
                                event.target.name,
                                event.target.value
                            )}
                            value={this.state.millReserveToAddEdit.feet}
                            maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">Comment
                          <Input
                              type="textarea"
                              name="comment"
                              onChange={(event) => this.handleMillReserveStateChange(
                                  event.target.name,
                                  event.target.value
                              )}
                              value={this.state.millReserveToAddEdit.comment}
                              placeholder="Enter A Comment"
                              maxLength="1000"
                              style={{height: "120px"}}
                          />
                      </Col>
                      <Col>FOB
                        <Input
                            name="fob"
                            onChange={(event) => this.handleMillReserveStateChange(
                                event.target.name,
                                event.target.value
                            )}
                            value={this.state.millReserveToAddEdit.fob}
                            maxLength="50"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ButtonGroup className="btn-group btn-access float-right">
                            <Button
                              size="sm"
                              className="btn primary"
                              onClick={() => this.state.millReserveToAddEdit.id
                                ?   this.updateMillReserve(this.state.millReserveToAddEdit)
                                :   this.saveNewMillReserve() }
                            >
                              <FontAwesomeIcon icon="save" /> Save
                            </Button>
                            <Button
                              size="sm"
                              className="btn secondary"
                              onClick={() => this.toggleAddMillReserveCollapse()}
                            >
                              <FontAwesomeIcon icon="times-circle" /> Cancel
                            </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={this.state.showCustomerSearchModal}
                      toggle={() => this.setState({showCustomerSearchModal: false})}
                      size="lg"
                    >
                      <ModalBody>
                        <CustomerAdmin 
                          usedByCustomerSearchModal={true}
                          onCustomerSelected={this.onCustomerSelectedFromSearchModal}
                        />
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Collapse>
              <Row className="row-spacing">
                  <Col>
                      <Table striped hover size="sm">
                          <thead>
                              <tr>
                                <th style={{width:"15%"}}>Supplier</th>
                                <th style={{width:"5%"}}>Reserve #</th>
                                <th style={{width:"20%"}}>Style & Color</th>
                                <th style={{width:"5%"}}>Amt (Ft)</th>
                                <th style={{width:"7%"}}>Dates</th>
                                <th style={{width:"5%"}}>FOB</th>
                                <th style={{width:"10%"}}>Customer</th>
                                <th style={{width:"20%"}}>Notes</th>
                                <th width="15%" />
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.millReserves.map(mr => (
                                <tr key={mr.id} className="data-row">
                                    <td>
                                      <div>
                                        {`${mr.supplierName}, #`}<span className="bold-title">{mr.supplierAccountNumber}</span>
                                      </div>
                                      <div>
                                        Ph:{mr.millAgentPhoneNumber}
                                      </div>
                                    </td>
                                    <td>
                                      {mr.reserveNumber}
                                    </td>
                                    <td>
                                      <div>
                                        DC: <span className="bold-title">{`${mr.productDCName}, ${mr.productDCNumber}`}</span>
                                      </div>
                                      <div>
                                        Mill: <span className="bold-title">{`${mr.productSupplierName}, ${mr.productSupplierNumber}`}</span>
                                      </div>
                                      <div>
                                        Color: <span className="bold-title">{mr.productColorName}</span>
                                      </div>
                                    </td>
                                    <td>{mr.feet}</td>
                                    <td>
                                      <div>
                                        Entered:
                                      </div>
                                      <div>
                                        {mr.createdAt}
                                      </div>
                                      <div>
                                        Latest:
                                      </div>
                                      <div>
                                        {mr.lastReservedAt}
                                      </div>
                                    </td>
                                    <td>
                                      {mr.fob}
                                    </td>
                                    <td>
                                      <div>
                                        {mr.customerName}
                                      </div>
                                      <div>
                                        <span className="bold-title">{`#${mr.customerNumber}`}</span>
                                      </div>
                                    </td>
                                    <td>{mr.comment}</td>  
                                    <td className="text-right">
                                        <ButtonGroup>
                                          <div>
                                              {/* {mr.deactivatedAt === null ? ( */}
                                                  <React.Fragment>
                                                      <Button
                                                          color="link"
                                                          onClick={() => this.updateMillReserve(mr, true)}
                                                          size="sm"
                                                      >
                                                          Re-Reserve
                                                      </Button>
                                                      <Button
                                                          className="primary btn-outline-secondary"
                                                          onClick={() => this.onEdit(mr)}
                                                          size="sm"
                                                          disabled={this.state.showMillReserveForm}
                                                          title="Edit"
                                                      >
                                                          <FontAwesomeIcon icon="edit" />
                                                      </Button>
                                                      <Button
                                                          className="danger btn-outline-secondary"
                                                          size="sm"
                                                          onClick={() => this.handleDelete(mr.id)}
                                                          title="Delete"
                                                      >
                                                          <FontAwesomeIcon icon="trash" />
                                                      </Button>
                                                  </React.Fragment>
                                              {/* ) : (
                                                  <Button
                                                      className="info"
                                                      size="sm"
                                                      onClick={() => this.handleUnDelete(mr.id)}
                                                  >
                                                      <FontAwesomeIcon icon="recycle" /> Revive
                                                  </Button>
                                              )} */}
                                          </div>
                                        </ButtonGroup>
                                    </td>                                 
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                  </Col>
              </Row>
              </CardBody>
                </Card>
            </Container>
        );
    }
}

export default MillReserves;
