import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Alert, Table, Input, ButtonGroup, Button, InputGroup, InputGroupAddon   } from 'reactstrap';
import Select from "react-select";
import _ from "lodash";
import { api, helpers, date_helpers, constants } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class SalesTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      selectedArea: null,
      selectedAgent: null,
      areaList: [],
      agentList: []
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.onExecute = this.onExecute.bind(this);
  }

  componentDidMount() {
    const getAgents = () => api.fetch('Reference/GetAgentList').then(result => ({ agentList: result.data }));
    const getAreas = () => api.fetch('Reference/GetAreaList').then(result => ({ areaList: result.data }));
    Promise.all([getAgents(), getAreas()]).then(aggResult => {
      let newStatus = {};
      aggResult.forEach(r => Object.assign(newStatus, r));
      this.setState(newStatus);
    }).catch(e => console.error(e));
  }

  onExecute() {
    if (!this.state.selectedAgent || !this.state.selectedArea) {
      this.setState({message: 'Select both an area and an agent in order to transfer.', messageFlavor: 'warning'});
      return;
    }
    api.post(`Admin/SalesTransfer/${this.state.selectedArea.value}/${this.state.selectedAgent.value}`, {}).then((result) => {
      if (result.data.success) {
        this.setState({message: `All sales from area ${this.state.selectedArea.label} have now been transferred to ${this.state.selectedAgent.label}`, messageFlavor: 'success'});
      } else {
        this.setState({message: 'An unexpected error occurred: ' + result.data.message, messageFlavor: 'danger'});
      }
    }).catch(helpers.catchHandler);
  }

  onDismiss() {
    this.setState({
      message: null,
      messageFlavor: null
    });
  }

  render() {
    const { message, messageFlavor, areaList, selectedArea, agentList, selectedAgent } = this.state;
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {message && (
              <Row className="p-2 mb-2">
                <Col>
                  <Alert className={messageFlavor} isOpen={true} toggle={this.onDismiss}>{message}</Alert>
                </Col>
              </Row>
            )}
            <Row className="row-spacing mb-2">
              <Col>
                <h3 className="pull-left page-title">Sales Transfer</h3>
              </Col>
            </Row>
            <Row className="row-spacing mb-2">
              <Col xs="4">
                <Select
                  closeMenuOnSelect={true}
                  value={selectedArea}
                  options={areaList}
                  onChange={(a) => this.setState({selectedArea: a})}
                  placeholder="Select Area"
                />
              </Col>
              <Col xs="1" className="text-center">
                <FontAwesomeIcon icon='arrow-circle-right' size='2x' />
              </Col>
              <Col xs="4">
                <Select
                  closeMenuOnSelect={true}
                  value={selectedAgent}
                  options={agentList}
                  onChange={(a) => this.setState({selectedAgent: a})}
                  placeholder="Select Agent"
                />
              </Col>
              <Col xs="2">
                <Button
                  color="primary" 
                  onClick={this.onExecute}
                  disabled={!this.state.selectedAgent || !this.state.selectedArea}>Transfer Now</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}