import React, { Component, Fragment } from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  Label,
  Alert,
  Row,
  Col,
  Input,
  Collapse,
} from "reactstrap";
import { api, helpers, date_helpers, filter_helpers } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import {
  FilterSet,
  SortHeader,
  FilterBoolean,
  FilterText,
} from "../components";

const emptyPile = {
  name: "",
  seq: 0,
};

export default class Pile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      newPile: emptyPile,
      message: null,
      messageFlavor: null,
      fiberGroupList: [],
      addNewPile: false,
      filters: [
        {
          filterName: "ActiveOnly",
          value: true,
          filteredDisplay: "Active Only: Active",
        },
      ],
      sort_field: "Name",
      sort_dir: "asc",
    };

    this.addNewPile = this.addNewPile.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.currentFilterValue = this.currentFilterValue.bind(this);
  }

  componentDidMount() {
    const getFiberGroups = () => {
      return api
        .fetch("Reference/GetFiberGroupList")
        .then((result) => {
          return {
            fiberGroupList: result.data,
          };
        })
        .catch(helpers.catchHandler);
    };
    Promise.all([getFiberGroups()]).then((aggResults) => {
      const newStatus = {};
      aggResults.forEach((r) => Object.assign(newStatus, r));
      this.setState(newStatus, () => this.refreshList());
    });
  }

  refreshList(sortField, sortDirection, filters) {
    const filterList = filters || this.state.filters;
    const payload = {
      SortField: sortField || this.state.sort_field,
      SortDir: sortDirection || this.state.sort_dir,
    };

    _.each(filterList, (filter) => (payload[filter.filterName] = filter.value));

    api
      .post("Pile/GetList", payload)
      .then((response) => {
        let list = response.data;
        if (this.state.fiberGroupList && this.state.fiberGroupList.length > 0) {
          list = _.map(response.data, item => {
            item.fiberGroup = _.find(this.state.fiberGroupList, opt => { return opt.value === item.fiberGroupId })
            return item;
          });
        };
        this.setState({
          list: list,
        });
      })
      .catch(helpers.catchHandler);
  }

  addNewPile() {
    this.setState({
      addNewPile: !this.state.addNewPile,
      newPile: emptyPile,
      message: null,
      messageFlavor: null,
    });
  }

  handleNewPileStateChange(fieldName, value) {
    this.setState((prevState) => ({
      ...prevState,
      newPile: {
        ...prevState.newPile,
        [fieldName]: value,
      },
    }));
  }

  onBlurNewNumericFeetField(fieldName, value) {
    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        newPile: {
          ...prevState.newPile,
          [fieldName]: _.round(value, 4),
        },
      }));
    }
  }

  // getSelectedYarnBrand(yarnBrandId) {
  //     if (!yarnBrandId) {
  //         return null;
  //     }
  //     return _.find(this.state.yarnBrandList, x => x.id === yarnBrandId);
  // }

  // setSelectedYarnBrand(option) {
  //     this.setState(prevState => ({
  //         ...prevState,
  //         newPile: {
  //             ...prevState.newPile,
  //             yarnBrand: {
  //                 value: option ? option.value : null,
  //                 label: option ? option.label : null
  //             }
  //         }
  //     }));
  // }

  onEdit(pileId) {
    const indexToEdit = _.findIndex(this.state.list, { id: pileId });
    let tempList = this.state.list.slice();
    const snapshotItem = Object.assign({}, tempList[indexToEdit]);
    tempList[indexToEdit].isEditing = true;
    tempList[indexToEdit].pristine = snapshotItem;
    this.setState({
      fullList: tempList,
    });
  }

  onCancelEdit(pileId) {
    const indexToEdit = _.findIndex(this.state.list, { id: pileId });
    let tempList = this.state.list.slice();
    tempList[indexToEdit] = tempList[indexToEdit].pristine;
    this.setState({
      list: tempList,
      message: null,
      messageFlavor: null,
    });
  }

  updatePileState(fieldName, value, pileId) {
    const piles = this.state.list.slice();
    const pile = _.find(piles, (x) => x.id === pileId);
    pile[fieldName] = value;
    this.setState({
      list: piles,
    });
  }

  // editSelectedYarnBrand(option, pileId) {
  //     const piles = this.state.list.slice();
  //     const pile = _.find(piles, x => x.id === pileId);
  //     pile.yarnBrandId = option ? option.value : null;
  //     pile.yarnBrandName = option ? option.label : null;
  //     this.setState({
  //         list: piles
  //     });
  // }

  isFormValid(item) {
    const warnings = [];
    if (!item.Name || item.Name < 2) {
      warnings.push("Description is required.");
    }
    // if (!item.Seq) {
    //   warnings.push("Sequence is required.");
    // }
    if (warnings.length) {
      this.setState({
        message: warnings.join(" "),
        messageFlavor: "danger",
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  saveNewPile() {
    const pile = this.state.newPile;
    const data = {
      Id: 0,
      Name: pile.name,
      Seq: pile.seq === "" ? "" : Number(pile.seq),
      FiberGroupId: pile.fiberGroup ? pile.fiberGroup.value : null,
      CreatedByUserId: this.props.currentUser.id,
      CreatedAt: moment.utc().format(),
    };
    if (!this.isFormValid(data)) return;

    api
      .post("Pile/SaveNewPile", data)
      .then((response) => {
        if (response.data.success) {
          this.addNewPile();
          this.refreshList();
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  updatePile(pile) {
    const data = {
      Id: pile.id,
      Name: pile.name,
      Seq: pile.seq === "" ? "" : Number(pile.seq),
      FiberGroupId: pile.fiberGroup ? pile.fiberGroup.value : null,
      CreatedByUserId: this.props.currentUser.id,
      CreatedAt: date_helpers.formatDateForServer(moment()),
    };
    if (!this.isFormValid(data)) return;

    api
      .post("Pile/UpdatePile", data)
      .then((response) => {
        if (response.data.success) {
          this.refreshList();
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleDelete(id) {
    api
      .delete(`Pile/${id}`)
      .then((response) => {
        if (response.data.success) {
          this.refreshList();
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleUnDelete(id) {
    api
      .post(`Pile/Undelete/${id}`)
      .then((response) => {
        if (response.data.success) {
          this.refreshList();
        } else {
          this.setState({
            messageFlavor: "danger",
            message: response.data.message,
          });
        }
      })
      .catch(helpers.catchHandler);
  }

  doSort(field, direction) {
    this.setState({ sort_dir: direction, sort_field: field });
    this.refreshList(field, direction);
  }

  filterChange(changedFilter) {
    const filters = filter_helpers.get_filters(
      this.state.filters,
      changedFilter
    );
    this.setState({ filters: filters, clearValue: false });
    this.refreshList(null, null, filters);
  }

  clearFilters() {
    const filters = [];
    this.setState({ filters: filters, clearValue: true });
    this.refreshList(null, null, filters);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find((f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  render() {
    return (
      <Container fluid>
        <Card style={{ borderStyle: "none" }}>
          <CardBody>
            {this.state.message !== null && (
              <Row className="mb-2">
                <Col>
                  <Alert className={this.state.messageFlavor}>
                    {this.state.message}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="expand-md mt-2 mb-0 pb-0">
              <Col>
                <h3 className="pull-left page-title">Maintain Fiber</h3>
              </Col>
            </Row>
            <Row className="row-spacing">
              <Col>
                <Button
                  className="success float-right"
                  size="sm"
                  onClick={() => this.addNewPile()}
                >
                  <FontAwesomeIcon icon="plus" /> Add Fiber
                </Button>
                <FilterSet
                  filters={this.state.filters}
                  clearFilters={this.clearFilters}
                >
                  <Row>
                    <Col xs="6" md="6">
                      <FilterText
                        filterName="Name"
                        displayName="Description"
                        value={this.currentFilterValue("Name")}
                        onChangeCallback={this.filterChange}
                      />
                    </Col>
                    <Col xs="6" md="3">
                      <FilterBoolean
                        filterName="ActiveOnly"
                        displayName="Active Only"
                        yesOnly={true}
                        value={this.currentFilterValue("ActiveOnly")}
                        onChangeCallback={this.filterChange}
                        clearValue={this.state.clearValue}
                      />
                    </Col>
                  </Row>
                </FilterSet>
              </Col>
            </Row>
            <Collapse isOpen={this.state.addNewPile} className="row-spacing">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="page-title">Add New Fiber</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Description {helpers.requiredStar()}</Label>
                      <Input
                        type="text"
                        value={this.state.newPile.name}
                        onChange={(event) =>
                          this.handleNewPileStateChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                        name="name"
                        maxLength="50"
                      />
                    </Col>
                  </Row>
                  <Row className="row-spacing">
                    <Col>
                      <ButtonGroup className="float-right">
                        <Button
                          className="primary"
                          size="sm"
                          onClick={() => this.saveNewPile()}
                        >
                          <FontAwesomeIcon icon="save" /> Save
                        </Button>
                        <Button
                          className="secondary"
                          size="sm"
                          onClick={() => this.addNewPile()}
                        >
                          <FontAwesomeIcon icon="times-circle" /> Cancel
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Collapse>
            <Row className="row-spacing">
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>
                        <SortHeader
                          displayName="Description"
                          field="Name"
                          sortDir={this.state.sort_dir}
                          sorted={this.state.sort_field === "Name"}
                          callBack={this.doSort}
                        />
                      </th>
                      <th width="25%">Group</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list.map((p) => (
                      <tr key={p.id}>
                        <td>
                          {p.isEditing ? (
                            <Input
                              type="text"
                              value={p.name}
                              onChange={(event) =>
                                this.updatePileState(
                                  event.target.name,
                                  event.target.value,
                                  p.id
                                )
                              }
                              name="name"
                              maxLength="50"
                            />
                          ) : (
                            p.name
                          )}
                        </td>
                        <td>
                          {p.isEditing ? (
                            <Select
                            closeMenuOnSelect
                            value={p.fiberGroup}
                            isMulti={false}
                            options={this.state.fiberGroupList}
                            onChange={(selection) =>
                                this.updatePileState(
                                "fiberGroup",
                                selection,
                                p.id
                                )
                            }
                            />
                          ) : (
                            p.fiberGroup ? p.fiberGroup.label : null
                          )}
                        </td>
                        <td className="text-right">
                          <ButtonGroup className="float-right">
                            {p.isEditing ? (
                              <Fragment>
                                <Button
                                  className="primary"
                                  onClick={() => this.updatePile(p)}
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon="save" /> Save
                                </Button>
                                <Button
                                  className="secondary"
                                  onClick={() => this.onCancelEdit(p.id)}
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                              </Fragment>
                            ) : (
                              <Fragment>
                                {p.deactivatedAt === null ? (
                                  <React.Fragment>
                                    <Button
                                      className="primary btn-outline-secondary"
                                      onClick={() => this.onEdit(p.id)}
                                      size="sm"
                                    >
                                      <FontAwesomeIcon icon="edit" />
                                    </Button>
                                    <Button
                                      className="danger btn-outline-secondary"
                                      size="sm"
                                      onClick={this.handleDelete.bind(
                                        this,
                                        p.id
                                      )}
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <Button
                                    className="info"
                                    size="sm"
                                    onClick={this.handleUnDelete.bind(
                                      this,
                                      p.id
                                    )}
                                  >
                                    <FontAwesomeIcon icon="recycle" /> Revive
                                  </Button>
                                )}
                              </Fragment>
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
