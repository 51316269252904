import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Table,
    Container,
    Card,
    Alert as Alert2,
    CardBody,
} from 'reactstrap';
import api from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import helpers from '../utils/helpers';
import filterHelpers from '../utils/filter_helpers';
import date_helpers from '../utils/date_helpers';
import {
    FilterSet,
    FilterText,
    FilterBoolean,
		FilterDate,
		FilterSelect,
    SortHeader,
    Pager
} from '../components';

export default class Claims extends Component {
    constructor(props) {
        super(props);
        let lastClaimFilters = null;
        try {
          lastClaimFilters = localStorage.getItem('lastClaimFilters');
        } catch {}
        const defaultFilters = lastClaimFilters
          ? JSON.parse(lastClaimFilters)
          : [{filterName: "AciveOnly", value: true, filteredDisplay: "Active Only: Yes"}];
        
        this.state = {

              pagedList: {
                list: [],
                pageSize: 100,
                pageNumber: 1
            },
            claims: [],  
            messageFlavor: null,
            message: null,
            isMessageVisible: false,
            sortField: 'Id',
            sortDir: 'asc',
            filters: defaultFilters,
            clearValue: false,
						claimStatusList: []
        };

        this.refreshList = this.refreshList.bind(this);
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.setPage = this.setPage.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
    }

    componentDidMount() {
			api.fetch('Reference/GetClaimStatusList')
			.then(response => {
					this.setState({
							claimStatusList: response.data
					});
			})
			this.refreshList();
    }

  refreshList(sortField, sortDirection, filters) {
        const filterList = filters || this.state.filters;
        const payload = {
            Page: this.state.pagedList.pageNumber,
            PageSize: this.state.pagedList.pageSize,
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir,
        };

        _.each(filterList, filter => payload[filter.filterName] = filter.value);

    api.post('Claim/GetLightPaginatedList', payload)
            .then((response) => {
                this.setState({
                    claims: response.data.list
                });
            }).catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }





    filterChange(changedFilter) {
        const filters = filterHelpers.get_filters(this.state.filters, changedFilter);
        try {
            localStorage.setItem("lastClaimFilters", JSON.stringify(filters));
          } catch {}
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }






    clearFilters() {
        const filters = [];
        try {
            localStorage.setItem("lastClaimFilters", JSON.stringify(filters));
          } catch {}
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, () => this.refreshList());
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) return filterElement.value;
        return '';
    }

    handleDelete(id) {
        if (!window.confirm(`Are you sure you'd like to delete this claim?`)) return;
        api.delete(`Claim/DeleteClaim/${id}`)
        .then(response => {
          if (response.data.success) { 
            this.setState({ messageFlavor: "success", message: "Claim was deleted", isMessageVisible: true }, ()=>this.refreshList());       
            } else {
            this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleRevive(id) {
        api.post(`Claim/ReviveClaim/${id}`)
        .then(response => {
          if (response.data.success) { 
            this.setState({ messageFlavor: "success", message: "Claim was revived", isMessageVisible: true }, () => this.refreshList());      
            } else {
            this.setState({ messageFlavor: "danger", message: response.data.message, isMessageVisible: true });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

		onDismiss() {
			this.setState({
				isMessageVisible: !this.state.isMessageVisible,
				message: null,
				messageColor: null
			});
		}

    render() {
        return (
            <Container fluid className="p-2">
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                      <Row>
                          <Col>
                              {this.state.message !== null && (
                                  <Row className="mb-2">
                                      <Col>
                                        <Alert2 className={this.state.messageFlavor} isOpen={this.state.isMessageVisible} toggle={() => this.onDismiss()}>
                                          {this.state.message}
                                        </Alert2>
                                      </Col>
                                  </Row>
                              )}
                              <Row>
                                  <Col>
                                      <h3 className="pull-left page-title">Manage Claims</h3>
                                  </Col>
                              </Row>
                              <Row className="expand-md mt-2 mb-2 pb-0">
                                  <Col sm="12">
                                      <ButtonGroup className="float-right">
                                          <Button
                                              className="btn-sm success"
                                              tag={Link}
                                              to={`/editClaim/new`}
                                          >
                                              <FontAwesomeIcon icon="plus" /> Add Claim
                                          </Button>
                                      </ButtonGroup>
                                      <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                          <Row>
                                            <Col xs="3">
                                                <FilterText
                                                    filterName="Id"
                                                    displayName="Claim #"
                                                    value={this.currentFilterValue('Id')}
                                                    onChangeCallback={this.filterChange}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterDate
                                                        filterName="claimDate"
                                                        displayName="Claim Date"
                                                        value={this.currentFilterValue('claimDate')}
                                                        onChangeCallback={this.filterChange}
                                                        clearValue={this.state.clearValue} 
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterText
                                                    filterName="CustomerName"
                                                    displayName="Account Name"
                                                    value={this.currentFilterValue('CustomerName')}
                                                    onChangeCallback={this.filterChange}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterText
                                                    filterName="AccountNumber"
                                                    displayName="Account Number"
                                                    value={this.currentFilterValue('AccountNumber')}
                                                    onChangeCallback={this.filterChange}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterText
                                                    filterName="OriginalOrderId"
                                                    displayName="Original Order #"
                                                    value={this.currentFilterValue('OriginalOrderId')}
                                                    onChangeCallback={this.filterChange}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterSelect
                                                        filterName="ClaimStatusId"
                                                        displayName="Claim Status"
                                                        options={this.state.claimStatusList}
                                                        value={this.currentFilterValue('ClaimStatusId')}
                                                        onChangeCallback={this.filterChange}
                                                        clearValue={this.state.clearValue}
                                                        isClearable={true}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <FilterBoolean
                                                    filterName="ActiveOnly"
                                                    displayName="Active Only"
                                                    yesOnly={true}
                                                    value={this.currentFilterValue('ActiveOnly')}
                                                    onChangeCallback={this.filterChange}
                                                    clearValue={this.state.clearValue} />
                                            </Col>
                                          </Row>
                                      </FilterSet>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      <Table striped hover size="sm">
                                          <thead>
                                            <tr>
                                              <th>
                                                <SortHeader
                                                  displayName="Claim #"
                                                  field="Id"
                                                  sortDir={this.state.sortDir}
                                                  sorted={this.state.sortField === 'Id'}
                                                  callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                  displayName="Claim Date"
                                                  field="ClaimDate"
                                                  sortDir={this.state.sortDir}
                                                  sorted={this.state.sortField === 'ClaimDate'}
                                                  callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                  displayName="Account Name"
                                                  field="CustomerName"
                                                  sortDir={this.state.sortDir}
                                                  sorted={this.state.sortField === 'CustomerName'}
                                                  callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                  displayName="Original Order #"
                                                  field="OriginalOrderId"
                                                  sortDir={this.state.sortDir}
                                                  sorted={this.state.sortField === 'OriginalOrderId'}
                                                  callBack={this.doSort}
                                                />
                                              </th>
                                              <th>Recent Comment</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                              {this.state.claims.map(s => (
                                                  <tr key={s.id} className="data-row">
                                                      <td>{s.id}</td>
                                                      <td>{date_helpers.formatDateToShortDate(s.claimDate)}</td>
                                                      <td>{s.customerName}</td>
                                                      <td>{s.originalOrderId}</td>
                                                      <td>{s.mostRecentComment}</td>
                                                      <td className="text-right">
                                                          <ButtonGroup>
                                                              {s.deactivatedAt === null
                                                                  ? (
                                                                      <React.Fragment>
                                                                          <Button
                                                                              className="primary btn-outline-secondary"
                                                                              tag={Link}
                                                                              to={`/editClaim/${s.id}`}
                                                                              size="sm"
                                                                          >
                                                                              <FontAwesomeIcon icon="edit" />
                                                                          </Button>
                                                                          <Button
                                                                              className="danger btn-outline-secondary"
                                                                              size="sm"
                                                                              onClick={this.handleDelete.bind(this, s.id)}
                                                                          >
                                                                              <FontAwesomeIcon icon="trash" />
                                                                          </Button>
                                                                      </React.Fragment>
                                                                  ) : (
                                                                      <Button
                                                                          className="info" size="sm"
                                                                          onClick={this.handleRevive.bind(this, s.id)}
                                                                      >
                                                                          <FontAwesomeIcon icon="recycle" /> Revive
                                                                      </Button>
                                                                  )}
                                                          </ButtonGroup>
                                                      </td>
                                                  </tr>
                                              ))}
                                          </tbody>
                                      </Table>
                                      <Pager {...this.state.pagedList} callBack={this.setPage} />
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
