import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Row,
    Col,
    Table,
    Container,
    Spinner
} from 'reactstrap';
import moment from 'moment';
import {
    filter_helpers,
    api,
    helpers,
    date_helpers,
    constants
} from '../utils';
import {
    FilterSet,
    FilterSelect,
    FilterMultiSelect,
    FilterBoolean,
    FilterText,
    FilterDate,
    SortHeader,
    Pager
} from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const addAnyOption = (list) => {
    let newList = list.slice();
    newList.unshift({label: "(Any)", value: null});
    return newList;
};

export default class Orders extends Component {
    constructor(props) {
        super(props);
        let lastOrderFilters = null;
        try {
          lastOrderFilters = localStorage.getItem('lastOrderFilters');
        } catch {}
        const defaultFilters = lastOrderFilters
          ? JSON.parse(lastOrderFilters)
          : [{filterName: "createdAfter", value: moment().add(-90, 'days').valueOf(), filteredDisplay: "Created in the last 90 days"}];
        _.each(defaultFilters, f => {
          if ((f.filterName === 'createdAfter' || f.filterName === 'createdBefore' || f.filterName === 'createdAt')) {
            f.value = date_helpers.getMomentFromString(f.value).valueOf();
          }
        });
        this.state = {
            pagedList: null,
            sortColumn: 'CreatedAt',
            sortDirection: constants.SORT_DIRECTION.descending,
            filters: defaultFilters,
            errorMessage: "",
            ordersList: null,
            orderId: null,
            fetching: false,
            orderStatusList: [],
            deletingRevivingId: null // for loading indicator because this operation is slow with a lot of rows
        };
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.doSort = this.doSort.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    componentDidMount() {
        api.fetch('Reference/GetOrderStatusList')
        .then(response => {
            this.setState({
                orderStatusList: response.data
            });
        })
        this.refreshList();
    }

    refreshList() {
        let payload = {
            page: this.state.pagedList && this.state.pagedList.pageNumber 
              ? this.state.pagedList.pageNumber
              : 1,
            pageSize: this.state.pagedList && this.state.pagedList.pageSize
              ? this.state.pagedList.pageSize
              : 30,
            sortColumn: this.state.sortColumn,
            sortDirection: this.state.sortDirection,
            
        };
        _.each(this.state.filters, filter => {
          if (filter.value) {
            switch(filter.filterName) {
              case 'createdAfter':
              case 'createdBefore':
              case 'createdAt':
                payload[filter.filterName] = date_helpers.getMomentFromString(filter.value).format(date_helpers.YMD);
                // if (typeof filter.value === 'string') {
                //   payload[filter.filterName] = filter.value;
                // } else if (Number.isInteger(filter.value)) {
                //   payload[filter.filterName] = moment(filter.value).format();
                // } else {
                //   payload[filter.filterName] = filter.value;
                // }
                break;
              default:
                payload[filter.filterName] = filter.value;
                break;
            }
          }
        });
        this.setState({fetching: true}, () => {
          api.post('Order/GetPaginatedList', payload).then((response) => {
            let pagedList = response.data;
            pagedList.list = _.map(pagedList.list, i => ({
              ...i,
              totalPrice: i.totalPriceCurrency
            }));
            this.setState({ pagedList: pagedList, deletingRevivingId: null });
          })
          .catch(helpers.catchHandler)
          .finally(() => this.setState({fetching: false}));
        });
    }

    doSort(field, direction) {
      switch(direction) {
        case "desc":
          direction = constants.SORT_DIRECTION.descending;
          break;
        case "asc":
          direction = constants.SORT_DIRECTION.ascending;
          break;
      }
      this.setState({ sortDirection: direction, sortColumn: field }, this.refreshList);
    }

    filterChange(changedFilter) {
      const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
      try {
        localStorage.setItem("lastOrderFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, pagedList: {...this.state.pagedList, pageNumber: 1}, clearValue: false }, this.refreshList);
    }

    clearFilters() {
      const filters = [];
      try {
        localStorage.setItem("lastOrderFilters", JSON.stringify(filters));
      } catch {}
      this.setState({ filters: filters, pagedList: {...this.state.pagedList, pageNumber: 1}, clearValue: true }, this.refreshList);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    setPage(page) {
        const newPagedList = Object.assign({}, this.state.pagedList);
        newPagedList.pageNumber = page;
        this.setState({ pagedList: newPagedList }, this.refreshList);
    }

    handleDelete(orderId) {
        if (!window.confirm('This is a permanent delete.  Are you sure?')) return;
        this.setState({ deletingRevivingId: orderId });
        api
            .post(`Order/DeleteOrder/${orderId}`)
            .then(response => {
                this.refreshList();
            })
            .catch(helpers.catchHandler);
    }

    handleUnDelete(orderId) {
        this.setState({ deletingRevivingId: orderId });
        api
            .post(`Order/ReviveOrder/${orderId}`)
            .then(response => {
                this.refreshList();
            })
            .catch(helpers.catchHandler);
    }

    render() {
        return (
            <Container fluid>
                <Card style={{ borderStyle: "none" }}>
                    <CardBody>
                        {this.state.errorMessage && (
                            <Row>
                                <Col>
                                    <Alert className="danger">
                                        {this.state.errorMessage}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="row-spacing">
                            <Col>
                                <Button
                                    className="success float-right"
                                    tag={Link}
                                    size="sm"
                                    to="/OrderEntry"
                                >
                                    <FontAwesomeIcon icon="plus" /> New Order/Quote
                                </Button>
                                <h3 className="pull-left page-title">Orders</h3>
                            </Col>
                        </Row>
                        <Row className="row-spacing">
                            <Col>
                                <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                    <Row>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="OrderNumber"
                                                displayName="Order Number"
                                                value={this.currentFilterValue('OrderNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="AccountNumber"
                                                displayName="DC Number"
                                                value={this.currentFilterValue('AccountNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="CustomerName"
                                                displayName="Account Name"
                                                value={this.currentFilterValue('CustomerName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="AgentName"
                                                displayName="Agent Name"
                                                value={this.currentFilterValue('AgentName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        {/* <Col xs="3">
                                            <FilterSelect
                                                filterName="OrderStatusId"
                                                displayName="Order Status"
                                                options={addAnyOption(this.state.orderStatusList)}
                                                value={this.currentFilterValue('OrderStatusId')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col> */}
                                        <Col>
                                            <FilterMultiSelect
                                                filterName="OrderStatusList"
                                                displayName="Order Status"
                                                options={this.state.orderStatusList}
                                                values={this.currentFilterValue('OrderStatusList')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="CustomerPONumber"
                                                displayName="Customer PO"
                                                value={this.currentFilterValue('CustomerPONumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="ProductName"
                                                displayName="Product Name"
                                                value={this.currentFilterValue('ProductName')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean
                                                filterName="replacementOnly"
                                                displayName="Replacement Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('replacementOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterBoolean
                                                filterName="creditOnly"
                                                displayName="Credit Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('creditOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="MillRefNumber"
                                                displayName="Mill Ref #"
                                                value={this.currentFilterValue('MillRefNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="MillInvNumber"
                                                displayName="Mill Inv. #"
                                                value={this.currentFilterValue('MillInvNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterText
                                                filterName="BOLNumber"
                                                displayName="BOL #"
                                                value={this.currentFilterValue('BOLNumber')}
                                                onChangeCallback={this.filterChange}
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterDate
                                                filterName="createdAt"
                                                displayName="Created On"
                                                value={this.currentFilterValue('createdAt')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterDate
                                                filterName="createdBefore"
                                                displayName="Created Before"
                                                value={this.currentFilterValue('createdBefore')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                        <Col xs="3">
                                            <FilterDate
                                                filterName="createdAfter"
                                                displayName="Created After"
                                                value={this.currentFilterValue('createdAfter')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} 
                                            />
                                        </Col>
                                    </Row>
                                </FilterSet>
                            </Col>
                        </Row>
                        {this.state.fetching
                          ? (<div style={{ textAlign: "center", verticalAlign: "middle" }}>
                              <div style={{ margin: "auto", maxWidth: "5vw", borderRadius: 5 }}>
                                <Spinner color="primary" />
                              </div>
                            </div>) 
                          : null
                        }
                        <Row className="row-spacing">
                            <Col>
                                {this.state.pagedList
                                  ? (<Fragment>
                                    <Table striped hover responsive size="sm">
                                      <thead>
                                          <tr>
                                              <th>
                                                <SortHeader
                                                    displayName="Order #"
                                                    field="orderNumber"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'orderNumber'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Created"
                                                    field="createdAt"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'createdAt'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Status"
                                                    field="orderStatusName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'orderStatusName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Account"
                                                    field="customerName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'customerName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Entered By"
                                                    field="CreatedByUserName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'CreatedByUserName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>
                                                <SortHeader
                                                    displayName="Agent"
                                                    field="agentName"
                                                    sortDir={this.state.sortDirection}
                                                    sorted={this.state.sortColumn === 'agentName'}
                                                    callBack={this.doSort}
                                                />
                                              </th>
                                              <th>Total</th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {this.state.pagedList && this.state.pagedList.list && this.state.pagedList.list.map(orderItem => (
                                              <tr key={orderItem.id}>
                                                  <td>{orderItem.orderNumber}</td>
                                                  {/* <td>{date_helpers.dateTimeFormat(orderItem.createdAt, "MM/DD/YYYY hh:mm A")}</td> */}
                                                  <td>{moment(orderItem.createdAt).format(date_helpers.MDY)}</td> 
                                                  <td>{orderItem.orderStatusName}</td>
                                                  <td>{orderItem.customerName}</td>
                                                  <td>{orderItem.createdByUserName}</td>
                                                  <td>{orderItem.agentName}</td>
                                                  <td>{orderItem.totalPrice}</td>
                                                  <td className="text-right">
                                                      <ButtonGroup>
                                                          {!orderItem.deactivatedAt ? (
                                                              <React.Fragment>
                                                                  <Button
                                                                      tag={Link}
                                                                      to={{
                                                                          pathname: '/OrderEntry',
                                                                          state: { activeOrderId: orderItem.id },
                                                                          push: true
                                                                      }}
                                                                      className="primary btn-outline-secondary"
                                                                      size="sm"
                                                                  >
                                                                      <FontAwesomeIcon icon="edit" />
                                                                  </Button>
                                                                  <Button
                                                                      disabled={this.state.deletingRevivingId === orderItem.id ? true : false}
                                                                      className="danger btn-outline-secondary"
                                                                      size="sm"
                                                                      onClick={this.handleDelete.bind(this, orderItem.id)}
                                                                  >
                                                                      {this.state.deletingRevivingId === orderItem.id 
                                                                          ? "Deleting"
                                                                          : <FontAwesomeIcon icon="trash" />
                                                                      }
                                                                  </Button>
                                                              </React.Fragment>
                                                          ) : (
                                                              <Button
                                                                  disabled={this.state.deletingRevivingId === orderItem.id ? true : false}
                                                                  className="info"
                                                                  size="sm"
                                                                  onClick={this.handleUnDelete.bind(this, orderItem.id)}
                                                              >
                                                                  {this.state.deletingRevivingId === orderItem.id 
                                                                      ? "Reviving"
                                                                      : <Fragment><FontAwesomeIcon icon="recycle" /> Revive</Fragment>
                                                                  }
                                                              </Button>
                                                          )}
                                                      </ButtonGroup>
                                                  </td>
                                              </tr>
                                          ))}
                                      </tbody>
                                  </Table>
                                  <Pager {...this.state.pagedList} callBack={this.setPage} />
                                  </Fragment>
                                  )
                                  : null
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

