import React, { Component } from 'react';
import {
    Container,
    Table,
    ButtonGroup,
    Button,
    Col,
    Row,
    Alert,
    Card,
    CardBody, 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FilterSet, SortHeader, FilterBoolean, FilterText, Pager, FilterSelect, FilterDate } from '../components';
import {api, date_helpers, filter_helpers, helpers} from '../utils';
import { Link } from 'react-router-dom';
import moment from 'moment';

const addAnyOption = (list) => {
  let newList = list.slice();
  newList.unshift({ label: "(Any)", value: null });
  return newList;
};

export default class Expense extends Component {
  constructor(props) {
    super(props);
    let lastExpenseFilters = null;
    try {
      lastExpenseFilters = localStorage.getItem('lastExpenseFilters');
    } catch {}
    const defaultFilters = lastExpenseFilters
      ? JSON.parse(lastExpenseFilters)
      : [{
          filterName: "ActiveOnly",
          value: true,
          filteredDisplay: "Active Only: Active"
        },
        {
          filterName: "DateFrom",
          value: new Date(moment().subtract(90, "day")),
          filteredDisplay: "Created in the last 90 days"
        }];
    _.each(defaultFilters, f => {
      if ((f.filterName === 'DateFrom' || f.filterName === 'DateTo')) {
        f.value = date_helpers.getMomentFromString(f.value).valueOf();
      }
    });
    this.state = {
        message: null,
        messageFlavor: null,
        isMessageVisible: false,
        pagedList: {
            list: [],
            pageSize: 100, 
            pageNumber: 1
        },
      sortField: 'Month',
      sortDir: 'desc',
      filters: defaultFilters,
      showEdit: false,
      supplierList: [],
      payToList: [],
      agentList: [],
      expenseCategoryList: [],
      expenseTypeList: []
    };
    this.onMessageDismiss = this.onMessageDismiss.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setPage = this.setPage.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.currentFilterValue = this.currentFilterValue.bind(this);
  }

  componentDidMount() {
    const getAgents = api.fetch('Reference/GetAgentList')
      .then((result) => {
          return { agentList: result.data };
      })
      .catch(helpers.catchHandler);
    const getExpenseTypes = api.fetch('Reference/GetExpenseTypeList')
      .then((result) => {
          return { expenseTypeList: result.data };
      })
      .catch(helpers.catchHandler);
    const getBooks = api.fetch('Reference/GetSampleBookList')
      .then((result) => {
          return { bookList: result.data };
      })
      .catch(helpers.catchHandler);
    const getExpenseCategoriesForClerk = api.fetch('ExpenseCategory/GetExpenseCategoryListForClerk')
    .then((result) => {
        return { expenseCategoryList: result.data };
    })
    .catch(helpers.catchHandler);
    const getExpenseCategories = api.fetch('Reference/GetExpenseCategoryList')
      .then((result) => {
          return { expenseCategoryList: result.data };
      })
      .catch(helpers.catchHandler);
    const getSupplier = api.fetch('Reference/GetSupplierList')
      .then((result) => {
        return {
          supplierList: _.map(result.data, s => {
            s.id = s.value;
            s.value = s.label;
            return s;
          })
        };
      })
      .catch(helpers.catchHandler);
    const getExpensePayToList = api.fetch('Reference/GetExpensePayToList')
      .then((result) => {
        return { 
          payToList: _.map(result.data, s => {
            s.id = s.value;
            s.value = s.label;
            return s; 
          })
        };
      })
      .catch(helpers.catchHandler);

  let APIlist = [ 
    getAgents,
    getExpenseTypes,
    getBooks,
    getExpenseCategories,
    getSupplier,
    getExpensePayToList]
  //push the full list if admin, otherwise this is a finance clerk
  if(this.props.currentUser && this.props.currentUser.isAdmin) {
    APIlist.push(getExpenseCategories);
  } else {
    APIlist.push(getExpenseCategoriesForClerk);
  }

  Promise.all(APIlist)
    .then((aggregateResults) => {
        const newStatus = {};
        aggregateResults.forEach(r => Object.assign(newStatus, r));
        this.setState(newStatus, () =>
            this.refreshList());
    })
    .catch(helpers.catchHandler);
  }

  refreshList(sortField, sortDirection, filters) {
    let filterList = filters || this.state.filters;

    let payload = {
        page: this.state.pagedList.pageNumber,
        pageSize: this.state.pagedList.pageSize,
        SortField: sortField || this.state.sortField,
        SortDir: sortDirection || this.state.sortDir,
    }

    _.each(filterList, filter => {
      if (filter.filterName === 'DateFrom' || filter.filterName === 'DateTo') {
        payload[filter.filterName] = date_helpers.getMomentFromString(filter.value).format(date_helpers.YMD);
      } else {
        payload[filter.filterName] = filter.value
      }
    });
        
    api.post('Expense/GetPaginatedList', payload)
    .then((response) => {
        this.setState({
            pagedList: response.data
        })
    }).catch(helpers.catchHandler);
  }

  doSort(field, direction) {
    this.setState({ sortDir: direction, sortField: field });
    this.refreshList(field, direction);
  }

  filterChange(changedFilter) {
    const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
    try {
      localStorage.setItem("lastExpenseFilters", JSON.stringify(filters));
    } catch {}
    this.setState({ filters: filters, clearValue: false });
    this.refreshList(null, null, filters);
  }

  clearFilters() {
    const filters = [];
    try {
      localStorage.setItem("lastExpenseFilters", JSON.stringify(filters));
    } catch {}
    this.setState({ filters: filters, clearValue: true });
    this.refreshList(null, null, filters);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find(f => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
          return filterElement.value;
      }
      return filterElement.values;
    }
    return '';
  }

  setPage(page) {
    const newPagedList = Object.assign({}, this.state.pagedList);
    newPagedList.pageNumber = page;
    this.setState({ pagedList: newPagedList }, () => this.refreshList());
  }

  onMessageDismiss() {
    this.setState({ isMessageVisible: !this.state.isMessageVisible });
  }

  handleDelete(id) {
    api.delete('Expense/DeleteExpenseItem/' + id)
      .then(response => {
        if (response.data.success) {
          this.refreshList()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  handleUnDelete(id) {
    api.post('Expense/ReviveExpenseItem/' + id)
      .then(response => {
        if (response.data.success) {
          this.refreshList()
        } else {
          this.setState({ messageFlavor: "danger", message: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

    render() {
        return (
            <Container fluid className="p-2">
                <Row className="expand-md mt-2 mb-0 pb-0">
                    <Col sm="12">
                        <Card style={{ borderStyle: "none" }}>
                            <CardBody>
                              {this.state.message !== null && (
                                <Row className="mb-2">
                                  <Col>
                                    <Alert
                                      className={this.state.messageFlavor}
                                      isOpen={this.state.isMessageVisible}
                                      toggle={this.onMessageDismiss}
                                    >
                                        {this.state.message}
                                    </Alert>
                                  </Col>
                                </Row>
                              )}
                              <Row className="row-spacing">
                                <Col>
                                    <h3 className="pull-left page-title">Operating Expense Details</h3>
                                </Col>
                              </Row>
                              <Row className="row-spacing">
                                  <Col sm="12">
                                    <Button className="float-right success btn-sm" tag={Link} to={`/editExpense/new`}>
                                          <FontAwesomeIcon icon="plus" /> Add Expense Detail
                                      </Button>
                                      <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                          <Row>
                                            <Col xs="2">
                                              <FilterText 
                                                filterName="CheckNumber" 
                                                displayName="Check #" 
                                                value={this.currentFilterValue('CheckNumber')} 
                                                onChangeCallback={this.filterChange} 
                                                descriptionPrefix={"starts with"}
                                              />                                          
                                            </Col>
                                            <Col xs="2">
                                              <FilterText 
                                                filterName="InvoiceNumber" 
                                                displayName="Invoice #" 
                                                value={this.currentFilterValue('InvoiceNumber')}
                                                onChangeCallback={this.filterChange}
                                                descriptionPrefix={"starts with"}
                                              />                                          
                                            </Col>
                                            <Col xs="2">
                                              <FilterSelect
                                                filterName="PayToName"
                                                displayName="Pay To"
                                                options={addAnyOption(this.state.payToList)}
                                                value={this.currentFilterValue('PayToName')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                              />
                                            </Col>
                                            <Col xs="3">
                                              <FilterSelect
                                                filterName="ExpenseCategoryId"
                                                displayName="Expense Category"
                                                options={addAnyOption(this.state.expenseCategoryList)}
                                                value={this.currentFilterValue('ExpenseCategoryId')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                              />
                                            </Col>
                                            <Col xs="3">
                                              <FilterSelect
                                                filterName="ExpenseTypeId"
                                                displayName="Expense Type"
                                                options={addAnyOption(this.state.expenseTypeList)}
                                                value={this.currentFilterValue('ExpenseTypeId')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                              />
                                            </Col>
                                            {/*<Col xs="3">
                                              <FilterSelect
                                                filterName="AgentId"
                                                displayName="Agent"
                                                options={addAnyOption(this.state.agentList)}
                                                value={this.currentFilterValue('AgentId')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue}
                                              />
                                            </Col>*/}
                                            <Col xs="2">
                                              <FilterBoolean
                                                filterName="activeOnly"
                                                displayName="Active Only"
                                                yesOnly={true}
                                                value={this.currentFilterValue('ActiveOnly')}
                                                onChangeCallback={this.filterChange}
                                                clearValue={this.state.clearValue} />
                                            </Col>
                                            <Col xs="2">
                                              <FilterDate
                                                filterName="DateFrom"
                                                displayName="Month From"
                                                value={this.currentFilterValue('DateFrom')}
                                                onChangeCallback={this.filterChange}
                                                showMonthYearPicker={true}
                                                dateFormat="MM/yyyy"
                                                clearValue={this.state.clearValue} />
                                            </Col>
                                            <Col xs="2">
                                              <FilterDate
                                                filterName="DateTo"
                                                displayName="Month To"
                                                value={this.currentFilterValue('DateTo')}
                                                onChangeCallback={this.filterChange}
                                                showMonthYearPicker={true}
                                                dateFormat="MM/yyyy"
                                                fromDateToExpense={true}
                                                clearValue={this.state.clearValue} />
                                            </Col>
                                          </Row>
                                      </FilterSet>
                                  </Col>
                              </Row>
                              <Row className="row-spacing">
                                  <Col className="col-12">
                                      <Table striped hover size="sm">
                                          <thead>
                                              <tr>
                                                  <th>
                                                    <SortHeader displayName="Check #" field="CheckNumber" sortDir={this.state.sortDir}
                                                      sorted={this.state.sortField === 'CheckNumber'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                    <SortHeader displayName="Invoice #" field="InvoiceNumber" sortDir={this.state.sortDir}
                                                      sorted={this.state.sortField === 'InvoiceNumber'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                    <SortHeader displayName="Pay To" field="PayToName" sortDir={this.state.sortDir}
                                                      sorted={this.state.sortField === 'PayToName'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                      <SortHeader displayName="Month" field="Month" sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'Month'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                      <SortHeader displayName="Category" field="ExpenseCategory" sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'ExpenseCategory'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                      <SortHeader displayName="Amount" field="Amount" sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'Amount'} callBack={this.doSort} />
                                                  </th>
                                                  <th>
                                                      <SortHeader displayName="Type" field="ExpenseType" sortDir={this.state.sortDir}
                                                          sorted={this.state.sortField === 'ExpenseType'} callBack={this.doSort} />
                                                  </th>
                                                <th>
                                                  <SortHeader displayName="CreatedAt" field="CreatedAt" sortDir={this.state.sortDir}
                                                    sorted={this.state.sortField === 'CreatedAt'} callBack={this.doSort} />
                                                </th>
                                                  <th></th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {this.state.pagedList.list.map(b => (
                                                  <tr key={b.id} className="data-row">
                                                    <td>{b.checkNumber}</td>
                                                    <td>{b.invoiceNumber}</td>
                                                    <td>{b.payToName}</td>
                                                    <td>{b.monthName}</td>
                                                    <td>{b.expenseCategoryName}</td>
                                                    <td>{b.amountCurrency}</td>
                                                    <td>{b.expenseTypeName}</td>
                                                    <td>{date_helpers.dateTimeFormat(b.createdAt, date_helpers.MDY4)}</td>
                                                    <td className="text-right">
                                                        <ButtonGroup>
                                                              {b.deactivatedAt === null ? (
                                                                  <React.Fragment>
                                                                    <Button 
                                                                      className="primary btn-outline-secondary buttonGroupSpacing" 
                                                                      tag={Link}
                                                                      to={`/editExpense/${b.id}`}
                                                                      size="sm"
                                                                      title="Edit Expense"
                                                                    >
                                                                    <FontAwesomeIcon icon="edit" />
                                                                      </Button>
                                                                      <Button
                                                                          className="danger btn-outline-secondary"
                                                                          size="sm"
                                                                          onClick={this.handleDelete.bind(this, b.id)}
                                                                          title="Delete Expense Item"
                                                                      >
                                                                          <FontAwesomeIcon icon="trash" />
                                                                      </Button>
                                                                  </React.Fragment>
                                                              ) : (
                                                                  <Button
                                                                    className="info buttonGroupSpacing"
                                                                    size="sm"
                                                                    onClick={this.handleUnDelete.bind(this, b.id)}
                                                                  >
                                                                    <FontAwesomeIcon icon="recycle" /> Revive
                                                                  </Button>
                                                              )}
                                                          </ButtonGroup>
                                                      </td>
                                                  </tr>
                                              ))}
                                          </tbody>
                                      </Table>
                                    <Pager {...this.state.pagedList} callBack={this.setPage} />
                                  </Col>
                              </Row>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

