import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Navbar,
  Col,
  Row,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse
}
from 'reactstrap';
// import DCLogo from '../assets/img/dc-logo.jpg';
import DCLogo from '../assets/img/logo_with_rugs.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';

class Header extends Component {
  constructor(props) {
    const isMobile = helpers.isMobile(window);
    super(props);
    this.state = {
      isOpen: !isMobile,
      currentUser: this.props.currentUser
    };
    this.toggle = this.toggle.bind(this);
    this.Logout = this.Logout.bind(this);
    this.stopImpersonating = this.stopImpersonating.bind(this);
  }

  toggle() {
    this.setState(prevState => { return {isOpen: !prevState.isOpen }});
  }

  stopImpersonating() {
    this.props.clearImpersonation();
  }

  Logout() {
    // logout message, redirectPath
    this.props.logout(null, null);
    return <Redirect to="/" />;
  }

  render() {
    if (!this.props.currentUser) {
        return null;
    }
    if (this.props.squarespace) {
        return null;
    }
    if (helpers.mustChangePassword(this.props.currentUser)) {
        return (
            <Navbar expand="md" fluid='true' collapseonselect='true'>
                <a href='/'><img id="dc-logo" src={DCLogo} alt="DC Logo" /></a>
            </Navbar>
        );
    };
    const {
      isOpen
    } = this.state;
    const isMobile = helpers.isMobile(window);
    return (
      <Fragment>
        <Navbar expand="md" className="navbar-toggleable-md">
          <NavbarBrand tag={Link} to="/" className={isMobile ? "mr-1" : ""}>
            <img id="dc-logo" src={DCLogo} alt="DC Logo" />
          </NavbarBrand>
          <NavbarToggler
            className="navbar-toggler navbar-toggler-right px-1 pb-0"
            onClick={this.toggle}
          >
            <span className="navbar-toggler-icon pb-0"><FontAwesomeIcon icon="bars" /></span>
          </NavbarToggler>
          <Collapse isOpen={isMobile ? isOpen : true} navbar>
            <Nav className="ml-auto" navbar>
                <NavItem>
                <NavLink tag={Link} to="/">
                    <FontAwesomeIcon icon='home' /> Home
                </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Products
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/* <DropdownItem tag={Link} to="/collections">Collections</DropdownItem> */}
                        {/* <DropdownItem tag={Link} to="/memosizes">Memo Sizes</DropdownItem> */}
                        <DropdownItem tag={Link} to="/maintainbooks">Maintain Samples</DropdownItem>
                        <DropdownItem tag={Link} to="/maintainproduct">Maintain Products</DropdownItem>
                        <DropdownItem tag={Link} to="/maintainsamples">Maintain Customer Samples</DropdownItem>
                        {/*<DropdownItem tag={Link} to="/tradesize">Trade Sizes</DropdownItem>*/}
                        {/* <DropdownItem tag={Link} to="/width">Width</DropdownItem> */}
                        {/* <DropdownItem tag={Link} to="/pile">Fiber</DropdownItem> */}
                        {/* <DropdownItem tag={Link} to="/constructionTypeAdmin">Construction Type</DropdownItem> */}
                        {/* <DropdownItem tag={Link} to="/rugcomplexities">Rug Complexities</DropdownItem> */}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Accounts
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag={Link} to="/CustomerAdmin">Accounts</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Orders
                    </DropdownToggle>
                    <DropdownMenu right>
                  {
                    this.props.currentUser.isDataEntry && !this.props.currentUser.isAdmin &&
                    <>
                      <DropdownItem tag={Link} to="/agentadmin">Agents</DropdownItem>
                      <DropdownItem tag={Link} to="/suppliers">Suppliers</DropdownItem>
                    </>
                  }
                      <DropdownItem tag={Link} to="/orders">Orders</DropdownItem>
                      <DropdownItem tag={Link} to="/memoorders">Memo Orders</DropdownItem>
                      <DropdownItem tag={Link} to="/multiorderpayables">Multi-Order Payables</DropdownItem>
                      <DropdownItem tag={Link} to="/shippingconsole"> Shipping Console</DropdownItem>
                      <DropdownItem tag={Link} to="/millreserves">Mill Reserves</DropdownItem>
                      <DropdownItem tag={Link} to="/claims">Claims</DropdownItem>
                    </DropdownMenu>
              </UncontrolledDropdown>
              {this.props.currentUser.isReportingUser &&
                <NavItem>
                  <NavLink tag={Link} to="/reports">
                    Reports
                    </NavLink>
                </NavItem>
              }
              {this.props.currentUser.isFinanceClerk && !this.props.currentUser.isAdmin &&
                <NavItem>
                    <NavLink tag={Link} to="/expense">
                      Expenses
                    </NavLink>
                </NavItem>
              }
              {this.props.currentUser.isAdmin &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Administration
                    </DropdownToggle>
                  <DropdownMenu right>
                    {this.props.impersonating
                          ? null
                          : (<DropdownItem tag={Link} to="/impersonate">Impersonate</DropdownItem>)
                    }
                    <DropdownItem tag={Link} to="/agentadmin">Agents</DropdownItem>
                    <DropdownItem tag={Link} to="/areaadmin">Area</DropdownItem>
                    <DropdownItem tag={Link} to="/configurationsettingsadmin">Configuration Settings</DropdownItem>
                    <DropdownItem tag={Link} to="/expense">Expense</DropdownItem>
                    <DropdownItem tag={Link} to="/expensepaytoadmin">Expenses Pay To</DropdownItem>
                    <DropdownItem tag={Link} to="/expensecategory">Expense Category</DropdownItem>
                    <DropdownItem tag={Link} to="/freightcarriers">Freight Carriers</DropdownItem>
                    <DropdownItem tag={Link} to="/suppliers">Suppliers</DropdownItem>
                    <DropdownItem tag={Link} to="/useradmin">Staff</DropdownItem>
                    <DropdownItem tag={Link} to="/yarnsupplierbrand">Fiber Suppliers &amp; Descriptions</DropdownItem>
                    <DropdownItem tag={Link} to="/fibergroup">Fiber Group</DropdownItem>
                    {/* <DropdownItem tag={Link} to="/millreserves">Mill Reserves</DropdownItem> */}
                    <DropdownItem tag={Link} to="/salestransfer">Sales Transfer</DropdownItem>
                    <DropdownItem tag={Link} to="/memosizes">Memo Sizes</DropdownItem>
                    <DropdownItem tag={Link} to="/width">Width</DropdownItem>
                    <DropdownItem tag={Link} to="/pile">Fiber</DropdownItem>
                    <DropdownItem tag={Link} to="/constructionTypeAdmin">Construction Type</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        <FontAwesomeIcon icon='user' /> {this.props.currentUser.firstName}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag={Link} to="/profile">Profile</DropdownItem>
                        <DropdownItem tag={Link} to="/updatepassword">Update Password</DropdownItem>
                        <DropdownItem divider />
                        {this.props.impersonating
                          ? (<DropdownItem onClick={this.stopImpersonating}>Stop Impersonating</DropdownItem>)
                          : null
                        }
                        <DropdownItem onClick={this.Logout}>Logout</DropdownItem>
                    </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        <Row className="fixed-bottom">
          <Col>
            {this.props.children}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Header;
