import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
    FilterBoolean,
    FilterSet,
    FilterText,
    SortHeader,
} from '../components';
import { api, date_helpers, filter_helpers, helpers } from '../utils';

const apiUrl = 'Agent/';

export default class AgentAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            messageFlavor: 'danger',
            message: null,
            sortField: 'LastName',
            sortDir: 'asc',
            filters: [
              {
                filterName: "ActiveOnly",
                value: true,
                filteredDisplay: "Active Only: Active"
              }
            ],
            clearValue: false,
            areaList: props.areaList
        };
        this.doSort = this.doSort.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.currentFilterValue = this.currentFilterValue.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleRevive = this.handleRevive.bind(this);
    }

    componentDidMount() {
        const getAreas = api.fetch('Reference/GetAreaList/')
            .then((result) => {
                return { areaList: result.data };
            })
            .catch(helpers.catchHandler);

        Promise.all([getAreas])
            .then((aggregateResults) => {
                const newStatus = {};
                aggregateResults.forEach(r => Object.assign(newStatus, r));
                this.setState(newStatus, () =>
                    this.refreshList());
            })
            .catch(helpers.catchHandler);
    }

    refreshList(sortField, sortDirection, filters) {
        let filterList = filters || this.state.filters;
        let payload = {
            SortField: sortField || this.state.sortField,
            SortDir: sortDirection || this.state.sortDir
        }

        _.each(filterList, filter => payload[filter.filterName] = filter.value);
        
        api.post(apiUrl + 'GetList', payload)
            .then((response) => {
                this.setState({
                    agents: _.map(response.data, agent => {
                        // prepare for react-select
                        return {
                            ...agent,
                            homeArea: { label: agent.homeAreaName, value: agent.homeAreaId },
                            hiredOn: date_helpers.getMomentFromString(agent.hiredOn)
                        }
                    })
                })
            })
            .catch(helpers.catchHandler);
    }

    doSort(field, direction) {
        this.setState({ sortDir: direction, sortField: field });
        this.refreshList(field, direction);
    }

    filterChange(changedFilter) {
        const filters = filter_helpers.get_filters(this.state.filters, changedFilter);
        this.setState({ filters: filters, clearValue: false });
        this.refreshList(null, null, filters);
    }

    clearFilters() {
        const filters = [];
        this.setState({ filters: filters, clearValue: true });
        this.refreshList(null, null, filters);
    }

    currentFilterValue(name) {
        const filterElement = this.state.filters.find(f => f.filterName === name);
        if (filterElement) {
            if (filterElement.value) {
                return filterElement.value;
            }
            return filterElement.values;
        }
        return '';
    }

    handleDelete(id) {
        api.delete(apiUrl + id)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    handleRevive(id) {
        api.post(apiUrl + 'Revive/' + id)
        .then(response => {
            if (response.data.success) { 
                this.refreshList()
            } else {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            }  
        }) 
        .catch(helpers.catchHandler);
    }

    render() {
        return (
            <Container fluid>
                        <Card style={{ borderStyle: "none" }}>
                            <CardBody>
                                {this.state.message !== null && (
                                    <Row className="mb-2">
                                        <Col>
                                            <Alert className={this.state.messageFlavor}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="row-spacing">
                                    <Col>
                                        <h3 className="pull-left page-title">Agent Administration</h3>
                                    </Col>
                                </Row>
                                <Row className="row-spacing">
                                    <Col>
                                        <ButtonGroup className="float-right">
                                            <Button
                                                className="success"
                                                size="sm"
                                                tag={Link}
                                                to={{ pathname: '/editagent', state: { agentId: "new" }, push: true }}
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add Agent
                                    </Button>
                                        </ButtonGroup>
                                        <FilterSet filters={this.state.filters} clearFilters={this.clearFilters}>
                                            <Row>
                                                <Col xs="6" md="6">
                                                    <FilterText filterName="name" displayName="Name"
                                                        value={this.currentFilterValue('name')} onChangeCallback={this.filterChange} />
                                                </Col>
                                                <Col xs="6" md="3">
                                                    <FilterBoolean filterName="activeOnly" displayName="Active Only" yesOnly={true}
                                                        value={this.currentFilterValue('activeOnly')} onChangeCallback={this.filterChange}
                                                        clearValue={this.state.clearValue} />
                                                </Col>
                                            </Row>
                                        </FilterSet>
                                    </Col>
                                </Row>
                                <Row className="row-spacing">
                                    <Col>
                                        <Table striped hover responsive size="sm">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <SortHeader displayName="Agent Name" field="LastName" sortDir={
                                                            this.state.sortDir}
                                                            sorted={this.state.sortField === 'LastName'} callBack={
                                                                this.doSort} />
                                                    </th>
                                                    <th>Home Area</th>
                                                    <th>ID Tag</th>
                                                    <th>
                                                        <SortHeader displayName="Email" field="Email" sortDir={this
                                                            .state.sortDir}
                                                            sorted={this.state.sortField === 'Email'} callBack={this
                                                                .doSort} />
                                                    </th>
                                                    <th>Mobile Number</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.agents.map(
                                                    agent => (
                                                        <tr key={agent.id}>
                                                            <td>{agent.firstName} {agent.lastName}</td>
                                                            <td>{agent.homeAreaName}</td>
                                                            <td>{agent.idTag}</td>
                                                            <td>{agent.email}</td>
                                                            <td>{agent.mobileNumber}</td>
                                                            <td>
                                                                <ButtonGroup className="float-right">
                                                                    {agent.deactivatedAt === null ? (
                                                                        <React.Fragment>
                                                                            <Button
                                                                                className="primary btn-outline-secondary"
                                                                                tag={Link}
                                                                                size="sm"
                                                                                to={{pathname: '/editagent', state: {agentId: agent.id}, push: true}}
                                                                                //onClick={this.onEdit.bind(this, agent)}
                                                                            >
                                                                                <FontAwesomeIcon icon="edit" />
                                                                            </Button>
                                                                            <Button
                                                                                className="danger btn-outline-secondary"
                                                                                size="sm"
                                                                                onClick={this.handleDelete.bind(this, agent.id)}
                                                                            >
                                                                                <FontAwesomeIcon icon="trash" />
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <Button
                                                                            className="info"
                                                                            size="sm"
                                                                            onClick={this.handleRevive.bind(this, agent.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon="recycle" /> Revive
                                                                        </Button>
                                                                    )}
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
            </Container>
        );
    }
}