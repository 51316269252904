import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { date_helpers } from "../utils";
import moment from 'moment';

export default class FilterDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      value: props.value,
      onChangeCallback: props.onChangeCallback
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const stringFormat = this.props.showMonthYearPicker ? "MM/YYYY" : "MM/DD/YYYY";
    const dateDisplay = value
      ? `${this.state.displayName}: ${date_helpers.getSelectedDate(value, stringFormat)}`
      : null;
    this.state.onChangeCallback({
      value: value,
      filterName: this.state.filterName,
      filteredDisplay: dateDisplay
    });
  }

  render() {
    return (
      <div>
        <div
          className={
            this.props.customFilterClassName
              ? this.props.customFilterClassName
              : "filterCaption"
          }
        >
          {this.state.displayName}
        </div>
        <DatePicker
          selected={this.props.value}
          onChange={this.onChange}
          className="form-control"
          showMonthYearPicker={this.props.showMonthYearPicker}
          dateFormat={ this.props.dateFormat}
        />
      </div>
    );
  }
}
