import React, { Component } from 'react';

export default class Dashboard extends Component {

    render() {
        return (
            <div>
                <h1>Staff Dashboard</h1>
            </div>
        );
    }
}