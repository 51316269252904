import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Col, Row, Card, CardBody, Alert, Button, Label, Input, ButtonGroup } from 'reactstrap';
import helpers from '../utils/helpers';
import api from '../utils/api';

export default class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            messageFlavor: null,
            redirect: false,
            password: '',
            change_password: '',
            change_password_again: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.onUpdatePassword = this.onUpdatePassword.bind(this);
        this.onUpdatePasswordAgain = this.onUpdatePasswordAgain.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    handlePassword(event) {
        this.setState({ ...this.state.item, password: event.target.value });
    }

    onUpdatePassword(event) {
        this.setState({ ...this.state.item, change_password: event.target.value });
    }

    onUpdatePasswordAgain(event) {
        this.setState({ ...this.state.item, change_password_again: event.target.value });
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.password) {
            warnings.push("All password fields required.");
        }
        if (this.state.change_password !== this.state.change_password_again) {
            warnings.push("The new password & new password confirmation do not match.");
        }
        if (!this.state.change_password || this.state.change_password.length < 8) {
            warnings.push("New password is required to be at least 8 characters long.");
        }
        if (this.state.password === this.state.change_password) {
            warnings.push("The original & new passwords must be different.");
        }
        if (warnings.length) {
            this.setState({
                message: warnings.join(','),
                messageFlavor: "danger"
            });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onCancel() {
        setTimeout(() => {
            this.setState({ redirect: true });
        }, 1000);
    }

    handleSubmit() {
        if (!this.isFormValid()) return;
        api.post("Account/UpdatePassword", {
            OldPassword: this.state.password,
            NewPassword: this.state.change_password
        }).then((response) => {
            if (response.data.success === false) {
                this.setState({ messageFlavor: "danger", message: response.data.message });
            } else {
                this.setState({
                    messageFlavor: "success",
                    message: "You have successfully updated your password. We're now redirecting you to the home screen."
                });
                let user = this.props.currentUser;
                user.must_change_password = false;
                this.props.setCurrentUser(user);
                setTimeout(() => {
                    this.setState({ redirect: true });
                }, 3000);
            }
        }).catch(helpers.catchHandler);
    };

    render() {
        if (this.state.redirect === true) {
            return <Redirect to='/' />;
        }
        return (
            <Container className="mt-5">
              <h4>Update Profile</h4>
                <Row>
                    <Col className="ml-3">
                        <Card>
                            <CardBody>
                                <div>
                                    {this.state.message !== null
                                        ? <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
                                        : null
                                    }
                                </div>
                                <div>
                                    <form>
                                        <Row className="mb-2">
                                            <Col sm="8">
                                                <Label className="label"> Original Password </Label>
                                                <Input type="password"
                                                    value={this.state.password}
                                                    onChange={this.handlePassword}
                                                    placeholder="Original Password"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm="8">
                                                <Label className="label"> New Password </Label>
                                                <Input type="password"
                                                    value={this.state.change_password}
                                                    onChange={this.onUpdatePassword}
                                                    placeholder="Type New Password"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm="8">
                                                <Label className="label"> Confirm New Password </Label>
                                                <Input type="password"
                                                    value={this.state.change_password_again}
                                                    onChange={this.onUpdatePasswordAgain}
                                                    placeholder="Re-Type New Password"
                                                />
                                            </Col>
                                        </Row>
                                        <ButtonGroup className="float-right">
                                            <Button className="success" size="sm" onClick={this.handleSubmit}>
                                                Confirm Password Update
                                            </Button>
                                            <Button className="secondary" size="sm" onClick={this.onCancel}>
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}
