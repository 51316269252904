import React from "react";
import _ from "lodash";
import { api } from "../utils";

export default class SqPriceList extends React.Component {
  constructor(props) {
    super(props);
  }

  // https://distinctive-carpets.squarespace.com/s/Carpet-Price-List-070122
  componentDidMount() {
    let [
      _ignore, // ==> app.distinctiveccarpets.com
      _page, // ==> /sq
      _path, // ==> /priceList
      downloadType, // ==> /Carpet , /Rug, /Hard-Surface
    ] = _.split(this.props.location.pathname, "/");
    if (
      this.props.currentCustomer &&
      this.props.currentCustomer.typeOfPricing === 1
    ) {
      api.redirectToSquarespacePage(`/s/${downloadType}-Price-List`);
    } else {
      api.redirectToSquarespacePage(`/s/${downloadType}-Price-List-Alt`);
    }
  }

  render() {
    return null;
  }
}
